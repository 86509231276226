import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { BrancherDialog } from './BrancherDialog';
import {
  ESessionAttributes,
  getLocalStorageAttribute,
  setLocalStorageAttribute,
} from '../../utils/cookies';
import { AcceptButton, RejectButton } from '../InputFields/BrancherButton';
import { Text } from './Text';
import { BrancherLinkText } from './BrancherLinkText';
import { PDFLink } from '../../PostMatching/Training/Modules/helpers/LinkBuilder';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

export const AcceptCookies = () => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const acceptCookies = getLocalStorageAttribute(ESessionAttributes.ACCEPT_COOKIES);
  const [acceptedCookies, setAcceptedCookies] = React.useState<boolean>(
    acceptCookies !== null ? Boolean(acceptCookies) : undefined,
  );

  const setAcceptCookieClose = (cookieClose: boolean): void => {
    setLocalStorageAttribute(ESessionAttributes.ACCEPT_COOKIES, cookieClose.toString());
    setAcceptedCookies(cookieClose);
  };

  return (
    <BrancherDialog
      setClose={() => setAcceptCookieClose(false)}
      open={acceptedCookies === undefined || acceptedCookies === null}
      title="Brancher Cookie Policy"
      labelledBy="brancher-cookie-policy"
      noOverflow
      contentWidth={true}
      describedBy="brancher-cookie-policy-description"
      width={mobile ? 400 : 600}
      position="bottom"
    >
      <Grid container item xs={12}>
        <Box mb={4}>
          <Text variant="xs" display="inline">
            By clicking “Accept all cookies”, you agree that Brancher can store cookies on your
            device and disclose information in accordance with our{' '}
          </Text>
          <BrancherLinkText
            href={PDFLink.COOKIE_POLICY_LINK}
            variant="xs"
            target="_blank"
            display="inline"
          >
            Cookie Policy
          </BrancherLinkText>
          <Text variant="xs" display="inline">
            .
          </Text>
        </Box>
        <Grid
          container
          item
          justify={mobile ? 'center' : 'space-between'}
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={10} md={6}>
            <RejectButton id="reject-cookies" fullWidth onClick={() => setAcceptCookieClose(false)}>
              Accept necessary cookies
            </RejectButton>
          </Grid>
          <Grid item xs={10} md={6}>
            <AcceptButton id="accept-cookies" fullWidth onClick={() => setAcceptCookieClose(true)}>
              Accept all cookies
            </AcceptButton>
          </Grid>
        </Grid>
      </Grid>
    </BrancherDialog>
  );
};
