import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { GetRoute } from '../../../../../Components/Routing';
import { SaveMoDBeGreatMenteeData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const MoDBeGreatMenteeStepFive = () => {
  const stepFiveValue = useSelector(
    (state: IStoreTypes) => state.training.modBeGreatMentee?.StepFive,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepFiveCorrectVal = ['0', '1', '2', '3'];
    dispatch(
      SaveMoDBeGreatMenteeData({
        StepFive: {
          selected: val,
          correct:
            val?.includes(StepFiveCorrectVal[0]) &&
            val?.includes(StepFiveCorrectVal[1]) &&
            val?.includes(StepFiveCorrectVal[2]) &&
            val?.includes(StepFiveCorrectVal[3]),
        },
      }),
    );
  };

  const options: string[] = [
    'Review the Mentoring Guide.',
    "If you haven't already, start thinking about what you want to get out of the mentoring relationship.",
    'Be on time – first impressions count.',
    'Plan your agenda for your first meeting. Share the planned agenda with your mentor ahead of time.',
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center">
        <Text variant="lg" fontWeight={700}>
          First meeting
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          In preparation for your first mentoring meeting, what should you do?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="sm" marginBottom={-30}>
          You can select more than 1 option.
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepFiveValue"
          value={stepFiveValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(options)}
        />
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Complete your{' '}
        </Text>
        <BrancherLinkText
          underline="always"
          variant="md"
          href={GetRoute('mentoringAgreement').path}
          display="inline"
          target="_blank"
        >
          Mentoring Agreement
        </BrancherLinkText>
        <Text variant="md" display="inline">
          {' '}
          at the first meeting. You can fine-tune your expectations of each other in there.
        </Text>
      </Grid>
    </Grid>
  );
};
