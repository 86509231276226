import * as React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { NextButton, PrevButton } from '../../Components/InputFields/BrancherButton';
import { BrancherSlider } from '../../Components/InputFields/BrancherSlider';
import { SelectionControl } from '../../Form/FormFieldTypes/SelectionControl';
import { TextControl } from '../../Form/FormFieldTypes/TextControl';
import { MakeOptions } from '../../Form/MakeOptions';
import {
  BrancherDispatch,
  UtilGetSurveyConfiguration,
  UtilRemoveUserAction,
  UtilSaveSurvey,
} from '../../store/actions';
import { EComponentType } from '../../store/reducers/UserInfoReducer';
import { hasDefinedValue } from '../../utils/validators/helpers';
import { IDefaultUserActionProps } from './ActionsEngine';
import { Text } from '../../Components/General/Text';
import { IStoreTypes } from '../../store/storeTypes';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../consts/colors';
import { ESurveyStatus } from '../../store/actions/TrainingActions';

const useStyles = makeStyles({
  parentContainer: {
    marginBottom: 100,
  },
  fixedActionPane: {
    position: 'absolute',
    bottom: 0,
    height: 100,
    marginRight: 10,
    backgroundColor: Colors.backgroundLightPurple,
    zIndex: 100,
    boxShadow: `inset 0px 2px 2px -2px ${Colors.darkGrey};`,
  },
});

interface ISurveyBuilder {
  surveyConfig: any[];
  surveyValues: any;
  saveSurveyValues: (fieldName: string, fieldValue: string | string[] | number) => void;
}

const SurveyBuilder = ({ surveyConfig, surveyValues, saveSurveyValues }: ISurveyBuilder): any[] => {
  return surveyConfig?.map((f) => {
    let field = {
      ...f,
      value: surveyValues?.[f.name],
      updateValue: (a: any) => saveSurveyValues(f.name, a),
      question: f.question,
    };
    // For conditional text field - make it backwards compatible with the old accessibility field. eg; === '1'
    if (
      f.conditionalTextField &&
      (!!f?.conditionalTextFieldValue
        ? surveyValues?.[f.name] === f.conditionalTextFieldValue
        : surveyValues?.[f.name] === '1')
    ) {
      field.textProps = {
        ...field.textProps,
        value: surveyValues[field.textProps.name],
        updateValue: (a: any) => saveSurveyValues(field.textProps.name, a),
      };
    } else {
      field.conditionalTextField = false;
    }
    // Most complex logic to pay attention to - stateTerritory and state transformations
    if (f.resetsOtherField) {
      field.updateValue = (a: any) => {
        saveSurveyValues(f.resetField, '');
        saveSurveyValues(f.name, a);
      };
    } // Most complex logic to pay attention to - stateTerritory and state transformations
    if (field.options) {
      const opts =
        f.conditionalOptions && surveyValues?.[f.conditionalOptionsField]
          ? f.options?.[surveyValues?.[f.conditionalOptionsField]]
          : f.options;
      field.options = MakeOptions(opts);
    }
    return field;
  });
};

export const CustomSurvey = (props: IDefaultUserActionProps) => {
  const { action, closeDialog } = props;
  const [retrievedConfig, setRetrievedConfig] = React.useState<boolean>(false);
  const sessionRoleId = useSelector((state: IStoreTypes) => state.user.sessionRoleId);
  const [stepNumber, setStepNumber] = React.useState<number>(1);
  const [surveyConfig, setSurveyConfig] = React.useState<any[]>([]);
  const [surveyValues, setSurveyValues] = React.useState<any>({});
  const [branchingStepPrev, setBranchingStepPrev] = React.useState<boolean>(false);
  const [surveyIsClosed, setSurveyIsClosed] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const styles = useStyles();
  const surveyId = action.surveyId;

  React.useEffect(() => {
    BrancherDispatch(
      dispatch,
      UtilGetSurveyConfiguration(surveyId, (surveyData) => {
        if (surveyData?.success) {
          if (surveyData.data?.surveyStatus === ESurveyStatus.CLOSED) {
            BrancherDispatch(
              dispatch,
              UtilRemoveUserAction(action.userActionId, () => {
                setSurveyIsClosed(true);
              }),
            );
          } else {
            setSurveyConfig(surveyData.data.configuration);
            setRetrievedConfig(true);
          }
        }
      }),
    );
  }, []);

  const saveSurveyValues = (attribute: string, value: string | number) => {
    setSurveyValues({ ...surveyValues, [attribute]: value });
  };

  const saveSurvey = () => {
    BrancherDispatch(
      dispatch,
      UtilSaveSurvey(
        surveyId,
        { ...surveyValues, ...action.actionAttributes, roleId: action.roleId ?? sessionRoleId },
        action.userActionId,
        () => {
          closeDialog();
        },
      ),
    );
  };

  const validateValue = (): boolean => {
    const currValue = surveyValues?.[surveyConfig[stepNumber - 1]?.name];
    const currQuestionNotMandatory = !!surveyValues?.[surveyConfig[stepNumber - 1]?.notMandatory];
    return (
      currQuestionNotMandatory ||
      hasDefinedValue(
        currValue || surveyConfig[stepNumber - 1]?.componentType === EComponentType.INFORMATION,
      )
    );
  };

  const controlNextStep = () => {
    if (stepNumber === surveyConfig.length) {
      saveSurvey();
    } else {
      const currValue = surveyValues?.[surveyConfig[stepNumber - 1]?.name];
      if (
        surveyConfig[stepNumber - 1]?.hasBranchingLogic &&
        surveyConfig[stepNumber - 1].branchingOptionValue === currValue
      ) {
        const branchingStepIndex = surveyConfig.findIndex(
          (s) => s.name === surveyConfig[stepNumber - 1].branchingStepName,
        );
        setBranchingStepPrev(true);
        setStepNumber(branchingStepIndex + 1);
      } else if (surveyConfig[stepNumber - 1]?.nextStepName) {
        const branchingStepIndex = surveyConfig.findIndex(
          (s) => s.name === surveyConfig[stepNumber - 1]?.nextStepName,
        );
        setBranchingStepPrev(true);
        setStepNumber(branchingStepIndex + 1);
      } else {
        setBranchingStepPrev(false);
        setStepNumber(stepNumber + 1);
      }
    }
  };

  // TODO: For branching step, how do we manage state of branched step so you can't go back after going forward?
  const controlPrevStep = () => {
    setStepNumber(stepNumber - 1);
  };

  return retrievedConfig ? (
    <Grid container alignItems="center" justify="center" className={styles.parentContainer}>
      {stepNumber !== 1 && !branchingStepPrev && (
        <Grid item container xs={12} justify="flex-start">
          <Grid item xs={5}>
            <Box marginBottom={4}>
              <PrevButton fullWidth onClick={controlPrevStep}>
                Previous
              </PrevButton>
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid container justify="center" item xs={12} id="here">
        {SurveyBuilder({ surveyConfig, surveyValues, saveSurveyValues })?.map((s, i) => {
          if (i + 1 === stepNumber) {
            const { readableName, notMandatory, componentType, conditionalTextField, ...other } = s;
            if (componentType === EComponentType.TOGGLE) {
              return (
                <SelectionControl key={i} conditionalTextField={conditionalTextField} {...other} />
              );
            } else if (componentType === EComponentType.TEXT) {
              return <TextControl key={i} {...other} />;
            } else if (componentType === EComponentType.SLIDER) {
              return <BrancherSlider key={i} {...other} />;
            } else if (componentType === EComponentType.INFORMATION) {
              return (
                <Text key={i} marginBottom={20} variant="md">
                  {other.question}
                </Text>
              );
            }
          }
        })}
      </Grid>
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        item
        xs={12}
        className={styles.fixedActionPane}
      >
        <Grid item xs={5}>
          <NextButton fullWidth disabled={!validateValue()} onClick={controlNextStep}>
            {stepNumber === surveyConfig.length ? 'Submit survey' : 'Next'}
          </NextButton>
        </Grid>
      </Grid>
    </Grid>
  ) : surveyIsClosed ? (
    <Grid container justify="center">
      <Text variant="md">
        This survey has been closed, you can close this dialog to continue to the dashboard.
      </Text>
    </Grid>
  ) : (
    <Grid container direction="column" justify="center" alignItems="center" item xs={5}>
      <CircularProgress color="secondary" size={64} />
    </Grid>
  );
};
