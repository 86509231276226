import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import SwipeableDrawer, { SwipeableDrawerProps } from '@material-ui/core/SwipeableDrawer';
import { ActionsSection } from '../../PostMatching/Dashboard/ActionsSection';
import { Text } from './Text';

interface INotificationsDrawer extends Omit<SwipeableDrawerProps, 'onOpen' | 'onClose'> {
  setDrawerClose: () => void;
}

export const NotificationsDrawer: React.FC<INotificationsDrawer> = ({ setDrawerClose, open }) => {
  // @ts-ignore
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const toggleDrawer = (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerClose();
  };

  return (
    <SwipeableDrawer
      onClose={toggleDrawer}
      onOpen={() => {}}
      open={open}
      anchor="right"
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <Grid container>
        <Grid item xs={12}>
          <Text variant="md" color="purple" marginBottom={20} fontWeight={600}>
            Notifications
          </Text>
        </Grid>
      </Grid>
      <ActionsSection />
    </SwipeableDrawer>
  );
};
