import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveEzEnglishMenteeData } from '../../../../../store/actions';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const EasyEnglishMenteeStepThree = () => {
  const stepThreeValue = useSelector(
    (state: IStoreTypes) => state.training.easyEnglishMentee?.StepThree,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    dispatch(
      SaveEzEnglishMenteeData({
        StepThree: {
          selected: val,
          correct: val?.length === 5,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md">What do you do between meetings?</Text>
      </Grid>
      <Grid item>
        <Text variant="sm" marginBottom={-30}>
          You can tick more than 1 option.
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepThreeValue"
          value={stepThreeValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions([
            'Set goals',
            'Write down your ideas',
            'Complete the tasks your mentor set for you',
            'Complete the Brancher training',
            'Prepare for next meeting',
          ])}
        />
      </Grid>
    </Grid>
  );
};
