import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { Text } from '../../../../../Components/General/Text';
import { CheckAnswerSection } from '../../../../../Components/General/CheckAnswerSection';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const EasyEnglishMentorStepThreeCorrect = () => {
  const stepThreeValue = useSelector(
    (state: IStoreTypes) => state.training.easyEnglishMentor.StepThree,
  );

  return (
    <Grid container direction="column" spacing={4}>
      <CheckAnswerSection correct={stepThreeValue.correct}>
        <Text variant="sm" fontWeight={700} marginBottom={20}>
          {stepThreeValue.correct ? 'Correct.' : 'Incorrect. You should do something.'}.
        </Text>
        <Text variant="sm">If you haven't heard from your mentee you should:</Text>
        <BrancherList listItems={['Follow up via email or phone', 'Contact Brancher support']} />
      </CheckAnswerSection>
    </Grid>
  );
};
