import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { EMoDTrainingModules } from '../../../../store/reducerTypes/TrainingReducer.types';
import { TrainingIntroduction } from '../../TrainingIntroduction';
import {
  getTrainingModuleHeading,
  getTrainingModuleNumber,
} from '../../TrainingModuleDefaultConfig';
import { Text } from '../../../../Components/General/Text';
import { SegmentedControlGroup } from '../../../../Form/FormFieldTypes/SegmentedControl';
import { SaveMoDGoalSettingMenteeData } from '../../../../store/actions';
import { IStoreTypes } from '../../../../store/storeTypes';
import { BrancherQuestionMarker } from '../../../../Components/General/BrancherQuestionMarker';
import { CompetencePopover } from '../sharedComps/CompetencePopover';
import { CompetenceScale } from '../sharedComps/CompetenceScale';
import { ConfidenceScale } from '../sharedComps/ConfidenceScale';

export const MoDGoalSettingMenteeSplash = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const trainingModules = useSelector((state: IStoreTypes) => state.training.modules)[sesPos];
  const modName = EMoDTrainingModules.MOD_GOAL_SETTING_MENTEE;
  const goalSettingMenteeBaseline = useSelector((state: IStoreTypes) => state.training?.[modName]);
  const trainingConfig = useSelector((state: IStoreTypes) => state.training.moduleConfig)[sesPos];
  const dispatch = useDispatch();
  const [anchorEl, setAnchor] = React.useState(null);
  const open = Boolean(anchorEl);

  const controlValidator = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!Boolean(anchorEl)) {
      setAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(anchorEl)) {
      setAnchor(null);
    }
  };

  const updateBaseline = (val: string, attr: string) => {
    dispatch(SaveMoDGoalSettingMenteeData({ [attr]: val }));
  };

  return (
    <TrainingIntroduction
      title={`Training #${getTrainingModuleNumber(
        modName,
        trainingModules,
        trainingConfig,
      )}: ${getTrainingModuleHeading(modName, trainingConfig)}`}
      subHeader="Complete this training to learn more about how to set SMART goals."
      activityLearnings={[
        'Understand why goal setting is important',
        'Understand SMART goal setting framework',
        'Create a SMART goal',
      ]}
      modulePath={modName}
      validatedValue={
        goalSettingMenteeBaseline?.competenceBaseline != null &&
        goalSettingMenteeBaseline?.confidenceBaseline != null
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box marginTop={3} marginBottom={3}>
            <Text variant="sm" fontWeight={700} display="inline">
              To provide us with a baseline measure, please rate your current level of{' '}
            </Text>
            <Text variant="sm" fontWeight={700} display="inline" color="purple">
              competence and confidence
            </Text>
            <Text variant="sm" fontWeight={700} display="inline">
              {' '}
              to create and achieve a SMART goal.{' '}
            </Text>
            <BrancherQuestionMarker onClick={controlValidator} />
          </Box>
          <CompetencePopover
            open={open}
            setClose={closeValidator}
            anchor={anchorEl}
            labelledBy="Competence and confidence"
          />
          <Grid item>
            <Text variant="sm" fontWeight={700} marginBottom={10}>
              Please rate your competence:
            </Text>
          </Grid>
          <CompetenceScale />
          <SegmentedControlGroup
            value={goalSettingMenteeBaseline?.competenceBaseline}
            valueLength={7}
            updateValue={(v: string) => updateBaseline(v, 'competenceBaseline')}
            fullWidth
          />
          <Grid item>
            <Text variant="sm" fontWeight={700} marginBottom={10}>
              Please rate your confidence:
            </Text>
          </Grid>
          <ConfidenceScale />
          <SegmentedControlGroup
            value={goalSettingMenteeBaseline?.confidenceBaseline}
            valueLength={7}
            updateValue={(v: string) => updateBaseline(v, 'confidenceBaseline')}
            fullWidth
          />
        </Grid>
      </Grid>
    </TrainingIntroduction>
  );
};
