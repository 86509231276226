import { ProgramPositions } from '../../../../QualifyingForm/ProgramPositionOptions';

export const SurveyTwoConfiguration = (position: ProgramPositions) => {
  return position === ProgramPositions.mentee ? SurveyTwoMentee : SurveyTwoMentor;
};

const SurveyTwoMentee: Array<{
  question: string;
  options?: string[];
  name: string;
  type: string;
  mandatory?: boolean;
  length?: number;
}> = [
  {
    question: 'How many times have you met with your mentoring partner?',
    options: [
      '1 = Never',
      '2 = Rarely',
      '3 = Sometimes',
      '4 = Often monthly',
      '5 = Always monthly',
    ],
    name: 'frequency',
    type: 'selection',
  },
  {
    question: 'I was satisfied with the person I was paired with.',
    options: [
      '1 = Strongly disagree',
      '2 = Disagree',
      '3 = Neutral',
      '4 = Agree',
      '5 = Strongly agree',
    ],
    name: 'matchSatisfaction',
    type: 'selection',
  },
  {
    question: 'I have a similar personality to the person I was paired with.',
    options: [
      '1 = Strongly disagree',
      '2 = Disagree',
      '3 = Neutral',
      '4 = Agree',
      '5 = Strongly agree',
    ],
    name: 'personalitySimilarity',
    type: 'selection',
  },
  {
    question: 'I share similar values to the person I was paired with.',
    options: [
      '1 = Strongly disagree',
      '2 = Disagree',
      '3 = Neutral',
      '4 = Agree',
      '5 = Strongly agree',
    ],
    name: 'valuesSimilarity',
    type: 'selection',
  },
  {
    question: 'I share similar interests to the person I was paired with.',
    options: [
      '1 = Strongly disagree',
      '2 = Disagree',
      '3 = Neutral',
      '4 = Agree',
      '5 = Strongly agree',
    ],
    name: 'interestsSimilarity',
    type: 'selection',
  },
  {
    question:
      'I was provided with adequate training, support and resources to get the most out of the program.',
    options: [
      '1 = Strongly disagree',
      '2 = Disagree',
      '3 = Neutral',
      '4 = Agree',
      '5 = Strongly agree',
    ],
    name: 'trainingSupport',
    type: 'selection',
  },
  {
    question: 'I have developed new strategies to give and receive feedback in this program.',
    options: [
      '1 = Strongly disagree',
      '2 = Disagree',
      '3 = Neutral',
      '4 = Agree',
      '5 = Strongly agree',
    ],
    name: 'feedbackStrategies',
    type: 'selection',
  },
  {
    question:
      'My confidence in my career and career choices have increased as a result of this program.',
    options: [
      '1 = Strongly disagree',
      '2 = Disagree',
      '3 = Neutral',
      '4 = Agree',
      '5 = Strongly agree',
    ],
    name: 'confidence',
    type: 'selection',
  },
  {
    question: 'My resilience at work has increased as a result of this program.',
    options: [
      '1 = Strongly disagree',
      '2 = Disagree',
      '3 = Neutral',
      '4 = Agree',
      '5 = Strongly agree',
    ],
    name: 'resilience',
    type: 'selection',
  },
  {
    question:
      'My understanding of another business area/field of work has increased as a result of this program.',
    options: [
      '1 = Strongly disagree',
      '2 = Disagree',
      '3 = Neutral',
      '4 = Agree',
      '5 = Strongly agree',
    ],
    name: 'crossFunctionalAwareness',
    type: 'selection',
  },
  {
    question: 'My engagement at work has increased as a result of this program.',
    options: [
      '1 = Strongly disagree',
      '2 = Disagree',
      '3 = Neutral',
      '4 = Agree',
      '5 = Strongly agree',
    ],
    name: 'engagement',
    type: 'selection',
  },
  {
    question: 'My productivity or performance at work has increased as a result of this program.',
    options: [
      '1 = Strongly disagree',
      '2 = Disagree',
      '3 = Neutral',
      '4 = Agree',
      '5 = Strongly agree',
    ],
    name: 'productivityPerformance',
    type: 'selection',
  },
  {
    question: 'The Brancher platform improved my mentoring experience.',
    options: [
      '1 = Strongly disagree',
      '2 = Disagree',
      '3 = Neutral',
      '4 = Agree',
      '5 = Strongly agree',
    ],
    name: 'platform',
    type: 'selection',
  },
  {
    question: 'Which of the following best describes your circumstances?',
    options: [
      'I achieved a pay rise or promotion during this program',
      'I am expecting a pay rise or promotion within the next 12 months',
      'None of the above',
    ],
    name: 'promotionPayRise',
    type: 'selection',
  },
  {
    question: 'I achieved a personal or professional goal as a result of this program.',
    options: ['Yes', 'No'],
    name: 'goalAchievement',
    type: 'selection',
  },
  {
    question: 'What goal/s did you achieve?',
    name: 'goalsAchieved',
    type: 'text',
    mandatory: false,
  },
  {
    question: 'I developed a new skill during this program.',
    options: ['Yes', 'No'],
    name: 'skill',
    type: 'selection',
  },
  {
    question: 'What skill/s did you achieve?',
    name: 'skillsDeveloped',
    type: 'text',
    mandatory: false,
  },
  {
    question: 'Do you intend to continue your mentoring relationship?',
    options: ['Yes', 'No'],
    name: 'continueRelationshipIntent',
    type: 'selection',
  },
  {
    question:
      "How likely are you to recommend this mentoring program and Brancher's platform to a colleague? ",
    length: 11,
    name: 'nps',
    type: 'toggle',
  },
  {
    question: 'I want this mentoring program to be run again next year.',
    options: ['Yes', 'No'],
    name: 'advocacy',
    type: 'selection',
  },
  {
    question:
      'If this program was run again, I would participate in the next mentoring program (as either a mentee, or mentor, or both).',
    options: ['Yes', 'No'],
    name: 'participationIntent',
    type: 'selection',
  },
  {
    question: 'What did you like about this program?',
    name: 'strengths',
    type: 'text',
  },
  {
    question: 'How could we further improve this program?',
    name: 'opportunities',
    type: 'text',
  },
];

const SurveyTwoMentor: any[] = [
  SurveyTwoMentee[0],
  SurveyTwoMentee[1],
  SurveyTwoMentee[2],
  SurveyTwoMentee[3],
  SurveyTwoMentee[4],
  SurveyTwoMentee[5],
  SurveyTwoMentee[6],
  {
    question: "I have enjoyed 'paying it forward' and helping the next generation of leaders",
    options: [
      '1 = Strongly disagree',
      '2 = Disagree',
      '3 = Neutral',
      '4 = Agree',
      '5 = Strongly agree',
    ],
    name: 'mentorBenefits',
    type: 'selection',
  },
  SurveyTwoMentee[8],
  SurveyTwoMentee[9],
  SurveyTwoMentee[10],
  SurveyTwoMentee[11],
  SurveyTwoMentee[12],
  SurveyTwoMentee[13],
  {
    question: 'I helped my mentee achieve a personal or professional goal during this program',
    options: [
      '1 = Strongly disagree',
      '2 = Disagree',
      '3 = Neutral',
      '4 = Agree',
      '5 = Strongly agree',
    ],
    name: 'mentorGoalAssistance',
    type: 'selection',
  },
  {
    question: 'My mentoring skills have improved as a result of this program',
    options: [
      '1 = Strongly disagree',
      '2 = Disagree',
      '3 = Neutral',
      '4 = Agree',
      '5 = Strongly agree',
    ],
    name: 'mentoringSkills',
    type: 'selection',
  },
  {
    question: 'My coaching skills have improved as a result of this program',
    options: [
      '1 = Strongly disagree',
      '2 = Disagree',
      '3 = Neutral',
      '4 = Agree',
      '5 = Strongly agree',
    ],
    name: 'coachingSkills',
    type: 'selection',
  },
  {
    question: 'My leadership skills have improved as a result of this program',
    options: [
      '1 = Strongly disagree',
      '2 = Disagree',
      '3 = Neutral',
      '4 = Agree',
      '5 = Strongly agree',
    ],
    name: 'leadershipSkills',
    type: 'selection',
  },
  SurveyTwoMentee[18],
  SurveyTwoMentee[19],
  SurveyTwoMentee[20],
  SurveyTwoMentee[21],
  SurveyTwoMentee[22],
  SurveyTwoMentee[23],
];
