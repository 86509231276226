import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { TacticalRelationshipClosureMentor } from '../TacticalRelationshipClosureMentor';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { TacticalRelationshipClosureMentee } from '../../BeyondProgramMentee/TacticalRelationshipClosureMentee';

export const BeyondProgramMentorStepFourStop = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentee = position === ProgramPositions.mentee;

  return (
    <Grid container direction="column" spacing={4}>
      {isMentee ? (
        <>
          <Grid item>
            <Text variant="sm" fontWeight={700}>
              It looks like you’re ready to take on your professional development without your
              mentor. What’s important is effectively communicating this to your mentor.
            </Text>
          </Grid>
          <Grid item>
            <Text variant="sm" fontWeight={700}>
              Here are some Principles to Remember when having this conversation.
            </Text>
          </Grid>
          <Grid item>
            <TacticalRelationshipClosureMentee />
          </Grid>
        </>
      ) : (
        <>
          <Grid item>
            <Text variant="sm" fontWeight={700}>
              It looks like your preference is to bring the mentoring relationship to an end. What’s
              important now is communicating this appropriately to your mentee.
            </Text>
          </Grid>
          <Grid item>
            <TacticalRelationshipClosureMentor />
          </Grid>
        </>
      )}
    </Grid>
  );
};
