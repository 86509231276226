import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { AlexAvatar } from '../../avatars/Alex';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveIntroductionMentee } from '../../../../../store/actions';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const IntroductionMenteeStepThree = () => {
  const StepThreeValue = useSelector((state: IStoreTypes) => state.training.introductionMentee);
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepThreeCorrectVals = '1';
    dispatch(
      SaveIntroductionMentee({
        ...StepThreeValue,
        StepThree: {
          selected: val,
          correct: StepThreeCorrectVals === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <AlexAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Alex is not quite what you were expecting.
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          You're surprised at how different Alex is to you. Alex’s experience and skills doesn't
          appear to align with your goals. You’re wondering how Alex can help you. What do you do?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Choose one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="Contact your Mentoring Lead to discuss options."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="Meet with Alex and get to know each other (first as people and then as professionals). Ask Alex “Given my goals and what I’d like to achieve with this program, what would be a good focus for us to work together on?”"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="Meet with Alex and talk to them about your concerns. Tell Alex what you are hoping to achieve from the mentoring program."
        />
      </Grid>
      <Grid item container justify="center">
        <SelectionControl
          question=""
          noQuestion
          name="stepThreeValue"
          value={StepThreeValue.StepThree?.selected}
          updateValue={updateValue}
          thirdWidth
          exclusive
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
