import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForward from '@material-ui/icons/ArrowForward';
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { isPast, parse } from 'date-fns';
import { BrancherCard } from '../../Components/General/BrancherCard';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { Text } from '../../Components/General/Text';
import { GetRoute } from '../../Components/Routing';
import { Colors } from '../../consts/colors';
import { BrancherDispatch, UtilRemoveUserAction } from '../../store/actions';
import {
  ActionButtonTitles,
  EUserActions,
  IUserAction,
  IUserInfo,
  UserActionTitles,
} from '../../store/reducers/UserInfoReducer';
import { IStoreTypes } from '../../store/storeTypes';
import { CreateMeetingAction } from './CreateMeetingAction';
import { CustomSurvey } from './CustomSurvey';
import { WrapUpPartnerSurvey } from './WrapUpPartnerSurvey';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { MeetingSurvey } from '../Dashboard/MeetingSurvey';
import { BrancherButton, ErrorButton } from '../../Components/InputFields/BrancherButton';

export interface IDefaultUserActionProps {
  closeDialog: () => void;
  action: IUserAction;
}

interface IActionsEngine {
  actions: IUserAction[];
}

export const ActionsEngine: React.FC<IActionsEngine> = ({ actions }) => {
  const roleLabels = useSelector((state: IStoreTypes) => state.user.roleLabels);
  const sessionPosition = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const partnerRoleId = useSelector((state: IStoreTypes) => state.user?.sessionPair?.roleId);
  const [selectedAction, setSelectedAction] = React.useState<IUserAction>(null);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [redirect, setRedirect] = React.useState<boolean>(false);
  const [openChangePartnerDialog, setOpenChangePartnerDialog] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const isMentee = sessionPosition === ProgramPositions.mentee;
  const partnerPosition = isMentee
    ? roleLabels[ProgramPositions.mentor]
    : roleLabels[ProgramPositions.mentee];

  const dialogActionTypes: EUserActions[] = [
    EUserActions.WRAP_UP_SURVEY,
    EUserActions.MEETING_SURVEY,
    EUserActions.FINISHING_SURVEY,
    EUserActions.CHECK_IN_SURVEY,
    EUserActions.POST_WRAP_UP_MENTEE_SURVEY,
    EUserActions.POST_WRAP_UP_MENTOR_SURVEY,
    EUserActions.CREATE_MEETING,
    EUserActions.CUSTOM_SURVEY,
  ];

  const partnerActionTypes: EUserActions[] = [
    EUserActions.COMPLETE_MENTORING_AGREEMENT,
    EUserActions.CREATE_MEETING,
  ];

  const routeActionTypes: EUserActions[] = [
    EUserActions.COMPLETE_TRAINING,
    EUserActions.STAGNANT_GOALS,
    EUserActions.UPLOAD_PROFILE_PIC,
    EUserActions.FIND_MENTORING_PARTNER,
    EUserActions.COMPLETE_MENTORING_AGREEMENT,
  ];

  const actionRoutes: { [key in EUserActions]?: string } = {
    [EUserActions.COMPLETE_TRAINING]: GetRoute('training').path,
    [EUserActions.STAGNANT_GOALS]: GetRoute('goals').path,
    [EUserActions.UPLOAD_PROFILE_PIC]: GetRoute('settings').path,
    [EUserActions.FIND_MENTORING_PARTNER]: GetRoute('findMentoringPartner').path,
    [EUserActions.COMPLETE_MENTORING_AGREEMENT]: GetRoute('mentoringAgreement').path,
  };

  const chooseAction = (userAction: IUserAction) => {
    setSelectedAction(userAction);
    if (
      partnerActionTypes.includes(userAction.type) &&
      !!userAction?.actionAttributes?.partnerRoleId &&
      userAction?.actionAttributes?.partnerRoleId !== partnerRoleId
    ) {
      setOpenChangePartnerDialog(true);
    } else if (dialogActionTypes.includes(userAction.type)) {
      setOpenDialog(true);
    } else if (routeActionTypes.includes(userAction.type)) {
      updateUserAction(userAction.userActionId);
      setRedirect(true);
    }
  };

  const closeDialog = () => {
    setOpenDialog(false);
    setSelectedAction(null);
  };

  const updateUserAction = (actionId: string) => {
    BrancherDispatch(
      dispatch,
      UtilRemoveUserAction(actionId, () => {
        setRedirect(false);
        setSelectedAction(null);
      }),
    );
  };

  const sortedActions = partnerRoleId
    ? actions.sort((a, b) => (a.actionAttributes?.partnerRoleId === partnerRoleId ? -1 : 1))
    : actions;

  return (
    <Grid container spacing={2}>
      {redirect && <Redirect to={actionRoutes[selectedAction.type]} />}
      <BrancherDialog
        setClose={() => setOpenChangePartnerDialog(false)}
        labelledBy="change-partner-dialog"
        title="Change your mentoring partner to action this"
        open={openChangePartnerDialog}
        fitLargeScreen
      >
        <Grid container justify="center">
          <Text variant="sm">
            This action is for another {partnerPosition}, you can change to your other{' '}
            {partnerPosition} for this session by going to the top right and selecting "Switch{' '}
            {partnerPosition}"
          </Text>
        </Grid>
      </BrancherDialog>
      {selectedAction?.type === EUserActions.WRAP_UP_SURVEY ? (
        <BrancherDialog
          setClose={closeDialog}
          labelledBy="wrap-up-partner-survey"
          title={`Quick ${roleLabels.programStyle} relationship survey`}
          open={openDialog}
          fitLargeScreen
        >
          <WrapUpPartnerSurvey closeDialog={closeDialog} action={selectedAction} />
        </BrancherDialog>
      ) : selectedAction?.type === EUserActions.MEETING_SURVEY ? (
        <BrancherDialog
          setClose={closeDialog}
          labelledBy="meeting-survey"
          title={
            selectedAction.title ?? UserActionTitles[selectedAction.type](roleLabels.programStyle)
          }
          open={openDialog}
          fitLargeScreen
        >
          <MeetingSurvey closeDialog={closeDialog} action={selectedAction} />
        </BrancherDialog>
      ) : selectedAction?.type === EUserActions.POST_WRAP_UP_MENTEE_SURVEY ||
        selectedAction?.type === EUserActions.POST_WRAP_UP_MENTOR_SURVEY ? (
        <BrancherDialog
          setClose={closeDialog}
          labelledBy="wrap-up-survey"
          describedBy="wrap-up-survey"
          title={UserActionTitles[selectedAction.type](roleLabels.programStyle)}
          open={openDialog}
          fitLargeScreen
        >
          <CustomSurvey closeDialog={closeDialog} action={selectedAction} />
        </BrancherDialog>
      ) : selectedAction?.type === EUserActions.CREATE_MEETING ? (
        <BrancherDialog
          setClose={closeDialog}
          labelledBy="report-or-create-meeting"
          title={UserActionTitles[selectedAction.type](roleLabels.programStyle)}
          open={openDialog}
          fitLargeScreen
        >
          <CreateMeetingAction
            closeDialog={() => {
              updateUserAction(selectedAction.userActionId);
              closeDialog();
            }}
            action={selectedAction}
          />
        </BrancherDialog>
      ) : selectedAction?.type === EUserActions.CUSTOM_SURVEY ? (
        <BrancherDialog
          setClose={closeDialog}
          labelledBy="custom-survey"
          describedBy="custom-survey"
          title={
            selectedAction.title ?? UserActionTitles[selectedAction.type](roleLabels.programStyle)
          }
          open={openDialog}
          fitLargeScreen
        >
          <CustomSurvey closeDialog={closeDialog} action={selectedAction} />
        </BrancherDialog>
      ) : selectedAction?.type === EUserActions.FINISHING_SURVEY ? (
        <BrancherDialog
          setClose={closeDialog}
          labelledBy="finishing-survey"
          describedBy="finishing-survey"
          title={
            selectedAction.title ?? UserActionTitles[selectedAction.type](roleLabels.programStyle)
          }
          open={openDialog}
          fitLargeScreen
        >
          <CustomSurvey closeDialog={closeDialog} action={selectedAction} />
        </BrancherDialog>
      ) : (
        selectedAction?.type === EUserActions.CHECK_IN_SURVEY && (
          <BrancherDialog
            setClose={closeDialog}
            labelledBy="check-in-survey"
            describedBy="check-in-survey"
            title={
              selectedAction.title ?? UserActionTitles[selectedAction.type](roleLabels.programStyle)
            }
            open={openDialog}
            fitLargeScreen
          >
            <CustomSurvey closeDialog={closeDialog} action={selectedAction} />
          </BrancherDialog>
        )
      )}
      {sortedActions?.map((action, index) => (
        <Grid item key={index} onClick={() => chooseAction(action)} xs={12}>
          <ActionsCard action={action} partnerPosition={partnerPosition} roleLabels={roleLabels} />
        </Grid>
      ))}
    </Grid>
  );
};

const useStyles = makeStyles({
  actionCard: {
    width: '100%',
    height: 'fit-content',
    maxHeight: 'fit-content',
    minHeight: 'fit-content',
    border: (props: { isOverdue: boolean }) =>
      props.isOverdue ? `1px solid ${Colors.actionRed}` : `1px solid ${Colors.secondaryGrey}`,
    '&:hover': {
      border: (props: { isOverdue: boolean }) =>
        props.isOverdue ? `2px solid ${Colors.actionRed}` : `2px solid ${Colors.purple}`,
      background: Colors.backgroundLightPurple,
      cursor: 'pointer',
    },
  },
});

interface IActionsCard {
  action: IUserAction;
  partnerPosition: string;
  roleLabels: IUserInfo['roleLabels'];
}

const ActionsCard: React.FC<IActionsCard> = ({ action, partnerPosition, roleLabels }) => {
  const { type, actionAttributes, dueDate } = action;
  const isOverdue = isPast(parse(dueDate, 'dd/MM/yyyy', new Date()));
  const styles = useStyles({ isOverdue });

  return (
    <BrancherCard minHeight="40px" padding="8px" className={styles.actionCard}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <Text variant="xxs" fontWeight={600}>
            {type === EUserActions.WRAP_UP_SURVEY
              ? `${UserActionTitles[type](actionAttributes.partnerName, roleLabels.programStyle)}`
              : action.type === EUserActions.FIND_MENTORING_PARTNER
              ? `${UserActionTitles[type](partnerPosition)}`
              : action?.title ?? UserActionTitles[type](roleLabels.programStyle)}
          </Text>
        </Grid>
        {!!dueDate && (
          <Grid item xs={12} container justify="space-between" alignItems="center">
            <Grid item>
              <Text
                variant="xxs"
                fontWeight={600}
                color={isOverdue ? 'actionRed' : 'purple'}
                display="inline"
                align="right"
              >
                Due: {dueDate}
              </Text>
            </Grid>
            <Grid item>
              {isOverdue ? (
                <ErrorButton
                  size="small"
                  endIcon={
                    type === EUserActions.MEETING_SURVEY || type === EUserActions.CREATE_MEETING ? (
                      <CalendarTodayOutlined color="error" />
                    ) : (
                      <ArrowForward color="error" />
                    )
                  }
                >
                  {ActionButtonTitles[type]()}
                </ErrorButton>
              ) : (
                <BrancherButton
                  color="secondary"
                  size="small"
                  endIcon={
                    type === EUserActions.MEETING_SURVEY || type === EUserActions.CREATE_MEETING ? (
                      <CalendarTodayOutlined />
                    ) : (
                      <ArrowForward />
                    )
                  }
                >
                  {ActionButtonTitles[type]()}
                </BrancherButton>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </BrancherCard>
  );
};
