import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';

export const MoDGoalSettingMenteeStepSixCorrect = () => {
  const stepSixValue = useSelector(
    (state: IStoreTypes) => state.training.modGoalSettingMentee?.StepSix,
  );

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepSixValue.correct} incorrect={!stepSixValue.correct}>
          <Text variant="md" fontWeight={700}>
            The correct answer is none of the above.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="md">This goal meets almost none of the SMART principles.</Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          It is not specific or measurable, in that we don’t know what ‘good’ means. Because the
          goal is vague, we do not know how attainable or relevant this is. There is also no
          specific time, so we don’t know when the individual wants to achieve the goal by.
        </Text>
      </Grid>
    </Grid>
  );
};
