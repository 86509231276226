import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BrancherDialog } from '../../../../Components/General/BrancherDialog';
import { BrancherLinkText } from '../../../../Components/General/BrancherLinkText';
import { BrancherList } from '../../../../Components/General/BrancherList';
import { Text } from '../../../../Components/General/Text';
import { NextButton, PrevButton } from '../../../../Components/InputFields/BrancherButton';
import { GetRoute } from '../../../../Components/Routing';
import { ProgramPositions } from '../../../../QualifyingForm/ProgramPositionOptions';
import { EEasyEnglishTrainingModules } from '../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../store/storeTypes';
import { GetTrainingResourceLink } from '../../../../utils/LinkUtils';
import { getTrainingModuleHeading } from '../../TrainingModuleDefaultConfig';

const useStyles = makeStyles({
  width: {
    maxWidth: 660,
    minWidth: '100%',
  },
});

export const EasyEnglishMenteeSplash = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const trainingConfig = useSelector((state: IStoreTypes) => state.training.moduleConfig)[sesPos];
  const [openDialog, setDialog] = React.useState(false);
  const isMentee = sesPos === ProgramPositions.mentee;
  const modName: EEasyEnglishTrainingModules = isMentee
    ? EEasyEnglishTrainingModules.EASY_ENGLISH_MENTEE
    : EEasyEnglishTrainingModules.EASY_ENGLISH_MENTOR;
  const styles = useStyles();

  const EasyEnglishMenteeGuideLink = GetTrainingResourceLink(modName, 'easyEnglishMentee.pdf');
  const EasyEnglishMentorGuideLink = GetTrainingResourceLink(modName, 'easyEnglishMentor.pdf');
  const EasyEnglishMenteeGuideWithoutPicturesLink = GetTrainingResourceLink(
    modName,
    'easyEnglishWithoutPicturesMentee.pdf',
  );
  const EasyEnglishMentorGuideWithoutPicturesLink = GetTrainingResourceLink(
    modName,
    'easyEnglishWithoutPicturesMentor.pdf',
  );

  return (
    <Grid container direction="column" alignItems="center" className={styles.width}>
      <BrancherDialog
        setClose={() => setDialog(false)}
        labelledBy="easy-english-title"
        describedBy="easy-english-description"
        open={openDialog}
        title="What is Easy English?"
      >
        <Text variant="md">Easy English is: </Text>
        <BrancherList
          listItems={[
            'Writing in everyday words',
            'Using simple sentence structure',
            'Supporting the messages with meaningful and clear images',
            'Having functional consumer reviews of products and company information',
            'Being clear about marketing versus information messages',
          ]}
          variant="md"
        />
      </BrancherDialog>
      <Grid item xs={11} md={9}>
        <Box marginTop={5} marginBottom={3}>
          <Link to={GetRoute('training').path}>
            <PrevButton variant="text" color="primary">
              Back
            </PrevButton>
          </Link>
        </Box>
        <Grid item>
          <Text variant="xl" marginBottom={20}>
            {getTrainingModuleHeading(modName, trainingConfig)}
          </Text>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Text variant="sm" display="inline">
              Read this{' '}
            </Text>
            <BrancherLinkText
              onClick={() => setDialog(true)}
              display="inline"
              variant="sm"
              fontWeight={500}
            >
              Easy English
            </BrancherLinkText>
            <Text variant="sm" display="inline">
              {' '}
            </Text>
            <BrancherLinkText
              display="inline"
              underline="always"
              target="_blank"
              variant="sm"
              href={isMentee ? EasyEnglishMenteeGuideLink : EasyEnglishMentorGuideLink}
            >
              {isMentee ? 'Mentee' : 'Mentor'} Guide
            </BrancherLinkText>
            <Text variant="sm" display="inline">
              {' '}
              to learn about how to be a great {sesPos} (person who {isMentee ? 'gets' : 'gives'}{' '}
              help).
            </Text>
          </Grid>
          <Grid item>
            <Text variant="sm" display="inline">
              Do you have a screen reader? If so, please view{' '}
            </Text>
            <BrancherLinkText
              target="_blank"
              href={
                isMentee
                  ? EasyEnglishMenteeGuideWithoutPicturesLink
                  : EasyEnglishMentorGuideWithoutPicturesLink
              }
              display="inline"
              underline="always"
              variant="sm"
            >
              this version of the Guide
            </BrancherLinkText>
            <Text variant="sm" display="inline">
              {' '}
              instead (without pictures).
            </Text>
          </Grid>
          <Grid item>
            <Text variant="sm" marginTop={10}>
              Click next after you have read the guide.
            </Text>
          </Grid>
        </Grid>
        <Box
          marginTop={4}
          marginBottom={4}
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Link to={GetRoute(modName).path} style={{ width: '100%' }}>
            <NextButton fullWidth>Next</NextButton>
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};
