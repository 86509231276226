import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EmailIcon from '@material-ui/icons/Email';
import Twitter from '@material-ui/icons/Twitter';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Instagram from '@material-ui/icons/Instagram';
import Facebook from '@material-ui/icons/Facebook';
import { BrancherDivider } from '../Components/General/BrancherDivider';
import { BrancherLinkText } from '../Components/General/BrancherLinkText';
import { Colors } from '../consts/colors';
import { GetPoliciesLink } from '../utils/LinkUtils';

const useStyles = makeStyles({
  footer: {
    background: Colors.backgroundBlack,
    paddingTop: 30,
  },
});

export const Footer = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'));
  const styles = useStyles();

  const EndUserAgreementLink = GetPoliciesLink('End-User-License-Agreement.pdf');
  const PrivacyPolicyLink = GetPoliciesLink('Privacy-Policy.pdf');

  return (
    <Grid container className={styles.footer} justify="center">
      <Grid item container xs={12}>
        <Grid item container justify={isMobile ? 'center' : 'space-between'} alignItems="center">
          <Grid item>
            <Grid item container alignItems="center">
              <BrancherLinkText
                marginRight={8}
                variant="xxs"
                fontWeight={400}
                aria-label="help-brancher"
                href="mailto:hello@brancher.com.au?subject=Help with platform"
              >
                Email us
              </BrancherLinkText>
              <EmailIcon fontSize="small" />
            </Grid>
          </Grid>
          <Hidden xsDown>
            <Grid item>
              <Grid item container spacing={2}>
                <Grid item>
                  <a
                    target="_blank"
                    href="https://twitter.com/Branche95503633"
                    rel="noopener noreferrer"
                    aria-label="brancher-twitter"
                  >
                    <Twitter fontSize="small" color="secondary" />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/Brancher/"
                    rel="noopener noreferrer"
                    aria-label="brancher-facebook"
                  >
                    <Facebook fontSize="small" color="secondary" />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/branchermentoring/"
                    rel="noopener noreferrer"
                    aria-label="brancher-linkedin"
                  >
                    <LinkedIn fontSize="small" color="secondary" />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/branchermentoring/"
                    rel="noopener noreferrer"
                    aria-label="brancher-instagram"
                  >
                    <Instagram fontSize="small" color="secondary" />
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        <Grid item xs={12}>
          <BrancherDivider width="100%" marginBottom={25} marginTop={5} />
        </Grid>
        <Grid item container direction="column" alignItems="center" xs={12}>
          <Grid item container justify="center" alignItems="center" spacing={4}>
            <BrancherLinkText
              href={PrivacyPolicyLink}
              variant="xxs"
              target="_blank"
              fontWeight={400}
              aria-label="privacy-policy"
            >
              Privacy
            </BrancherLinkText>
            <BrancherLinkText
              marginLeft={20}
              href={EndUserAgreementLink}
              variant="xxs"
              target="_blank"
              fontWeight={400}
              aria-label="end-user-agreement"
            >
              Terms of use
            </BrancherLinkText>
          </Grid>
        </Grid>
        {/*<Grid item container justify="center">*/}
        {/*  <Text variant="sm" color="secondaryGrey" marginTop={20} opacity={75}>*/}
        {/*    {new Date().getFullYear()} &copy; Brancher All Rights Reserved*/}
        {/*  </Text>*/}
        {/*</Grid>*/}
      </Grid>
    </Grid>
  );
};
