import * as React from 'react';
import { Location } from 'history';
import { Redirect } from 'react-router';
import { Prompt } from 'react-router-dom';
import { ConfirmationNavigationDialog } from './ConfirmNavigationDialog';

interface IRouteLeavingGuard {
  when: boolean | undefined;
}

export const RouteLeavingGuard: React.FC<IRouteLeavingGuard> = (props) => {
  const { when } = props;
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [lastLocation, setLastLocation] = React.useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = React.useState<boolean>(false);

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation) {
      setDialogOpen(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setDialogOpen(false);
    setConfirmedNavigation(true);
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {confirmedNavigation && <Redirect to={lastLocation.pathname} />}
      <ConfirmationNavigationDialog
        handleAccept={handleConfirmNavigationClick}
        handleNotYet={() => setDialogOpen(false)}
        open={dialogOpen}
      />
    </>
  );
};
