import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Info from '@material-ui/icons/Info';
import { MentoringGuideLink } from '../../../../../Components/General/MentoringGuideLink';
import { Text } from '../../../../../Components/General/Text';
import { SuggestionCard } from '../../SuggestionCard';

export const MoDBeGreatMenteeStepOne = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={600}>
          This training has been designed for you.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={400}>
          This training will help you realize the benefits of mentoring like increased job
          satisfaction and performance, improved career outcomes and even higher salaries.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={400}>
          We expect this module will take you approximately 15 minutes to complete.
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard custom>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={3} md={2} container justify="center">
              <Info fontSize="large" />
            </Grid>
            <Grid item xs={9} md={10}>
              <Text variant="md" display="inline" fontWeight={600}>
                All the critical information for the program is in the{' '}
              </Text>
              <MentoringGuideLink variant="md" />
              <Text variant="md" display="inline">
                .
              </Text>
              <Text variant="md" display="inline" fontWeight={400}>
                This module will test you on your knowledge of what's in the Mentoring Guide, so if
                you haven't already reviewed it, do it now. Otherwise, get started.
              </Text>
            </Grid>
          </Grid>
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
