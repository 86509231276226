import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';
import { SuggestionCard } from '../../SuggestionCard';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { GetTrainingResourceLink } from '../../../../../utils/LinkUtils';

export const BeyondProgramMentorStepOne = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentee = position === ProgramPositions.mentee;

  const commonDifficulties: string[] = [
    'Avoiding discussions of ending the relationship due to feeling uncomfortable.',
    'Ending the mentoring relationship abruptly without adequate reflection or celebration.',
    'Gradually losing contact or not returning phone calls, resulting in unanswered questions and a lack of closure.',
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Ending a relationship can be difficult and sometimes awkward.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Here are some of the most common difficulties {isMentee ? 'mentees' : 'mentors'} face when
          ending a mentoring relationship:
        </Text>
      </Grid>
      {commonDifficulties.map((difficulty, index) => (
        <Grid item key={index}>
          <SuggestionCard neutral custom>
            <Box marginLeft={4}>
              <TrainingSelectOptions
                fontWeight={700}
                optionsFontWeight={600}
                variant="sm"
                color="purple"
                optionHeader={`${index + 1}.`}
                optionText={difficulty}
              />
            </Box>
          </SuggestionCard>
        </Grid>
      ))}
      <Grid item container justify="center">
        <BrancherTrainingImage
          src={GetTrainingResourceLink(
            EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR,
            'communication-sea.png',
          )}
          alt="sea-of-communication"
        />
      </Grid>
      {!isMentee && (
        <Grid item>
          <Text variant="md">
            Although, well intentioned, these actions can heighten feelings of anxiety, loss and
            uncertainty for both parties, especially the mentees.
          </Text>
        </Grid>
      )}
      <Grid item>
        <Text variant="md">
          The following activities have been developed to help you avoid these difficulties and make
          the most of your final mentoring meeting.
        </Text>
      </Grid>
    </Grid>
  );
};
