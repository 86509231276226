import { IReducerType } from './ReducerType';

export const GOALS_CLEAR_DATA = 'GOALS_CLEAR_DATA';
export const GOALS_DATA = 'GOALS_DATA';
export const SAVE_PARTNERS_GOALS = 'SAVE_PARTNERS_GOALS';

export const GoalsReducer = (state = goalsReducerInitialState, action: IReducerType) => {
  switch (action.type) {
    case GOALS_CLEAR_DATA:
      return goalsReducerInitialState;
    case GOALS_DATA:
      return { ...state, ...action.payload };
    case SAVE_PARTNERS_GOALS:
      return { ...state, partnerGoals: action.payload };
    default:
      return state;
  }
};

export enum EGoalsBoardColumns {
  BACKLOG = 'backlog',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
}

export enum EGoalsBoardColumnsHeadings {
  BACKLOG = 'Backlog',
  IN_PROGRESS = 'In progress',
  COMPLETED = 'Completed',
}

export interface IGoal {
  id: string;
  goal: string;
  lastModified?: number;
}

export interface IGoalsReducer {
  [EGoalsBoardColumns.BACKLOG]?: IGoal[];
  [EGoalsBoardColumns.IN_PROGRESS]?: IGoal[];
  [EGoalsBoardColumns.COMPLETED]?: IGoal[];
  partnerGoals?: IGoalsReducer;
}

export const goalsReducerInitialState: IGoalsReducer = {
  [EGoalsBoardColumns.BACKLOG]: [],
  [EGoalsBoardColumns.IN_PROGRESS]: [],
  [EGoalsBoardColumns.COMPLETED]: [],
};
