import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { Text } from '../../../../../Components/General/Text';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { GetTrainingResourceLink } from '../../../../../utils/LinkUtils';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';

export const MoDGoalSettingMentorStepEight = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md">How to guide your mentee’s goals</Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          While some mentees come to a mentoring relationship with well-defined SMART goals, many do
          not. Most mentees will come with a general idea about what they want to work on and it's
          your role as their mentor to them work through the goal setting process. It may take
          several conversations to take a broad idea to a SMART goal.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="lg" fontWeight={600}>
          As a mentor, you can help with:
        </Text>
        <BrancherList
          listItems={[
            'Explain the power of SMART goals - what they are and their benefits',
            'Help your mentee get clear on what they want to focus on and move one step closer to setting SMART goals',
            'Check in on their goals and support them to make progress',
          ]}
        />
      </Grid>
      <Grid item>
        <BrancherLinkText
          underline="always"
          fontWeight={600}
          variant="md"
          href={GetTrainingResourceLink(
            EMoDTrainingModules.MOD_GOAL_SETTING_MENTOR,
            'SMART_Goals_Infographic.pdf',
          )}
          display="inline"
          target="_blank"
        >
          Click here
        </BrancherLinkText>
        <Text variant="md" display="inline">
          {' '}
          to download a cheat sheet on SMART goals.
        </Text>
      </Grid>
    </Grid>
  );
};
