import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { CharlieAvatar } from '../../avatars/Charlie';

export const BeGreatMentorStepFourteenCorrect = () => {
  const stepFourteenValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentor.StepFourteen,
  );
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard
          custom
          success={stepFourteenValue.correct}
          incorrect={!stepFourteenValue.correct}
        >
          <Text variant="md" fontWeight={700}>
            {stepFourteenValue.correct ? 'A and D are both correct' : 'Not quite'}.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          {stepFourteenValue.correct ? (
            <Grid item>
              <Text variant="sm">
                Correct. While people need time to vent and process the problem, the aim here is to
                develop insight into the problem and use that understanding to problem-solve. Don't
                fall into the trap of over-empathising and fixating on the problem.
              </Text>
              <Text variant="sm" marginTop={20}>
                It is useful to guide the conversation by paraphrasing their words to validate their
                experiences and acknowledging they have been heard, as a cue to move onto
                problem-solving. It can also help to remind the mentee of the expectations set in
                the agenda - to clarify if they want someone to listen, or help.
              </Text>
            </Grid>
          ) : (
            <Grid item>
              <Text variant="sm">
                While people need time to vent and process the problem, the aim here is to develop
                insight into the problem and use that understanding to problem-solve. Don't fall
                into the trap of over-empathising and fixating on the problem.
              </Text>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
