import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SonalAvatar } from '../../avatars/Sonal';
import { SuggestionCard } from '../../SuggestionCard';

export const GivingReceivingFeedbackMenteeStepThreeCorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard success custom>
          <Text variant="md" display="inline">
            Nice! You got the{' '}
          </Text>
          <Text variant="md" display="inline" fontWeight={700} color="purple">
            'M'
          </Text>
          <Text variant="md" display="inline">
            {' '}
            in{' '}
          </Text>
          <Text variant="md" display="inline" fontWeight={700}>
            MASTER
          </Text>
          <Text variant="md" display="inline">
            {' '}
            down pat.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={2}>
        <Grid item xs={8} md={4} container justify="center">
          <SonalAvatar />
        </Grid>
        <Grid item xs={12} md={8}>
          <Text variant="md" display="inline">
            The option you selected is the best choice because it{' '}
          </Text>
          <Text variant="md" display="inline">
            provides a motivation
          </Text>
          <Text variant="md" fontWeight={700} display="inline">
            {' '}
            to change behaviour,{' '}
          </Text>
          <Text variant="md" fontWeight={700} display="inline">
            states what you enjoy
          </Text>
          <Text variant="md" display="inline">
            {' '}
            about the relationship and{' '}
          </Text>
          <Text variant="md" fontWeight={700} display="inline">
            asks a question
          </Text>
          <Text variant="md" display="inline">
            {' '}
            to get buy-in.
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
};
