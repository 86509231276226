import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';

export const IntroductionMenteeStepFourCorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          That's correct. The best options are{' '}
        </Text>
        <Text variant="md" fontWeight={700} color="purple" display="inline">
          A
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          {' '}
          and{' '}
        </Text>
        <Text variant="md" fontWeight={700} color="purple" display="inline">
          C
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Because you’re working with Alex, but not breaching the Mentoring Guidelines. Remember, as
          your mentor, Alex is responsible for helping you grow personally and professionally.
          Asking your mentor for introductions can put them in an uncomfortable position.
        </Text>
      </Grid>
    </Grid>
  );
};
