import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { CheckAnswerSection } from '../../../../../Components/General/CheckAnswerSection';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const EasyEnglishCCStepOneCorrect = () => {
  const stepOneValue = useSelector((state: IStoreTypes) => state.training.easyEnglishCC?.StepOne);
  return (
    <Grid container direction="column" spacing={4}>
      <CheckAnswerSection correct={stepOneValue.correct}>
        <Text variant="sm" fontWeight={700} marginBottom={20}>
          {stepOneValue.correct ? 'Correct' : 'Not quite'}.
        </Text>
        <Text variant="sm">
          If you get help from someone, you are a mentee. If you help a person, you are a mentor.
        </Text>
      </CheckAnswerSection>
    </Grid>
  );
};
