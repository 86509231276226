import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { CharlieAvatar } from '../../avatars/Charlie';

export const BeGreatMentorStepSevenCorrect = () => {
  const stepSevenValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentor.StepSeven,
  );
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepSevenValue.correct} incorrect={!stepSevenValue.correct}>
          <Text variant="md" fontWeight={700}>
            {stepSevenValue.correct ? 'Correct' : 'Not quite'}.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          {stepSevenValue.selected === '0' ? (
            <Grid item>
              <Text variant="sm" display="inline">
                This is jumping ahead to{' '}
              </Text>
              <Text variant="sm" display="inline" color="purple" fontWeight={700}>
                Tactics
              </Text>
              <Text variant="sm" display="inline">
                {' '}
                as you are asking a question about 'how' they will do further. study rather than
                seeking to understand more about the{' '}
              </Text>
              <Text variant="sm" display="inline" color="purple" fontWeight={700}>
                Goal
              </Text>
              <Text variant="sm" display="inline">
                {' '}
                itself.
              </Text>
            </Grid>
          ) : stepSevenValue.selected === '1' ? (
            <Grid item>
              <Text variant="sm" display="inline">
                Seeking to understand their motivation and{' '}
              </Text>
              <Text variant="sm" display="inline" color="purple" fontWeight={700}>
                Goal
              </Text>
              <Text variant="sm" display="inline">
                {' '}
                is usually the first step in the GROWTH coaching framework.
              </Text>
            </Grid>
          ) : (
            <Grid item>
              <Text variant="sm" display="inline">
                Whilst income is important, this question could be sensitive. You are best to start
                by asking further questions to understand their{' '}
              </Text>
              <Text variant="sm" display="inline" color="purple" fontWeight={700}>
                Goal
              </Text>
              <Text variant="sm" display="inline">
                {' '}
                and motivations for pursuing further study.
              </Text>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
