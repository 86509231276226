import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SonalAvatar } from '../../avatars/Sonal';
import { SuggestionCard } from '../../SuggestionCard';

export const GivingReceivingFeedbackMenteeStepFourCorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={true}>
          <Text variant="md" fontWeight={700}>
            Great choice.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <SonalAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          <Grid item>
            <Text variant="md" display="inline">
              The option you selected is{' '}
            </Text>
            <Text variant="md" display="inline">
              achievable and specific{' '}
            </Text>
            <Text variant="md" fontWeight={700} display="inline">
              for Sonal because it acknowledges that sometimes we have work priorities and we need
              to be on call, whilst demonstrating a commitment to keep your own phone away too.
            </Text>
          </Grid>
          <Grid item>
            <Text variant="md" display="inline">
              You have also provided the{' '}
            </Text>
            <Text variant="md" display="inline" fontWeight={700}>
              relevance
            </Text>
            <Text variant="md" display="inline">
              {' '}
              and{' '}
            </Text>
            <Text variant="md" display="inline" fontWeight={700}>
              emotional hook{' '}
            </Text>
            <Text variant="md" display="inline">
              but explaining that "this will enable us to make the most of our time together".
            </Text>
          </Grid>
          <Grid item>
            <Text variant="md" fontWeight={700} marginBottom={15}>
              Sonal says:
            </Text>
            <SuggestionCard neutral>"Sure, I can do that."</SuggestionCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
