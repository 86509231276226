import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeyondProgramMenteeData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const BeyondProgramMenteeStepFiveStop = () => {
  const stepFiveValue = useSelector((state: IStoreTypes) => state.training?.beyondProgramMentee);
  const dispatch = useDispatch();
  const sessionSaveFunction = SaveBeyondProgramMenteeData;

  const updateValue = (val: string) => {
    const StepFiveCorrectVal = '2';
    dispatch(
      sessionSaveFunction({
        StepFive: {
          selected: val,
          correct: StepFiveCorrectVal === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Quiz:
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Which scenario is most appropriate for ending a mentoring relationship?
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="My current workload is overwhelming so I'm not sure I can continue with the monthly meetings. I’m thinking this could be our last meeting, if you’re comfortable with that?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="I’m not learning as much from you anymore, so it may be best for us to end our relationship in line with the program end date."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="I have learnt a lot from you, thank you for being a great mentor. My goals are evolving so I am keen to find a new mentor in the digital space. Are you OK if we wrap things up next month?"
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepFiveValue"
          value={stepFiveValue?.StepFive?.selected}
          updateValue={updateValue}
          noQuestion
          thirdWidth
          exclusive
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
