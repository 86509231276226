import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SaveEZEnglishMentorData } from '../../../../../store/actions/';

export const EasyEnglishMentorStepTwo = () => {
  const stepTwoValue = useSelector(
    (state: IStoreTypes) => state.training.easyEnglishMentor?.StepTwo,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepTwoCorrectVal = '1';
    dispatch(
      SaveEZEnglishMentorData({
        StepTwo: {
          selected: val,
          correct: val === StepTwoCorrectVal,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md">You only need to go to Brancher once.</Text>
      </Grid>
      <Grid item>
        <Text variant="sm" marginBottom={-30}>
          Choose one option.
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepTwoValue"
          value={stepTwoValue?.selected}
          updateValue={updateValue}
          noQuestion
          exclusive
          options={MakeOptions(['True', 'False'])}
        />
      </Grid>
    </Grid>
  );
};
