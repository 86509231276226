import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { CreateMentoringGuideLink } from '../../helpers/LinkBuilder';

export const IntroductionMenteeDescriptionOne = () => {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" display="inline">
          This training has been{' '}
        </Text>
        <Text variant="md" display="inline" color="purple" fontWeight={700}>
          designed for you.{' '}
        </Text>
        <Text variant="md" display="inline">
          This training will help you realise the benefits of mentoring{' '}
        </Text>
        <Text variant="md" display="inline" color="purple" fontWeight={700}>
          like increased job satisfaction and performance, improved career outcomes{' '}
        </Text>
        <Text variant="md" display="inline">
          and{' '}
        </Text>
        <Text variant="md" display="inline" color="purple" fontWeight={700}>
          even higher salaries.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          We expect this module will take you approximately 15 minutes to complete.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          All the critical information for the program is in the{' '}
        </Text>
        <BrancherLinkText
          underline="always"
          fontWeight={700}
          variant="md"
          href={CreateMentoringGuideLink()}
          display="inline"
          target="_blank"
        >
          Mentoring Guide
        </BrancherLinkText>
        <Text variant="md" display="inline">
          . This module will test you on your knowledge of what's in the Mentoring Guide, so if you
          haven't already reviewed it, do it now.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Otherwise, get started.
        </Text>
      </Grid>
    </Grid>
  );
};
