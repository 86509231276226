import { Colors } from '../consts/colors';

export const MuiTabs = {
  root: {
    boxShadow: '0px 6px 5px -5px rgba(0,0,0,0.14)',
  },
  indicator: {
    height: 4,
    backgroundColor: Colors.purple,
  },
};
