import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import { PageLayout } from '../../Components/General/PageLayout';
import { Text } from '../../Components/General/Text';
import { BrancherDispatch, UtilGetUserGroups } from '../../store/actions';
import { GetRoute } from '../../Components/Routing';
import { addPlural } from '../../utils/TextUtils';
import { Colors } from '../../consts/colors';

const useStyles = makeStyles((theme) => ({
  group: {
    padding: 15,
    borderRadius: 12,
    width: '100%',
    minWidth: 140,
    border: `2px solid ${Colors.blue}`,
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    minHeight: 150,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      cursor: 'pointer',
      '& .MuiTypography-root': {
        fontWeight: 600,
      },
    },
  },
}));

export interface IGroupMember {
  roleId: string;
  name: string;
  joined: number;
}

export interface IGroup {
  groupId: string;
  programId: string;
  owner: string; // userId
  ownerName: string;
  title: string;
  created: number;
  updated?: number;
  mentees?: IGroupMember[];
  mentors?: IGroupMember[];
  peers?: IGroupMember[];
}

export const Groups: React.FC = () => {
  const [groups, setGroups] = React.useState<IGroup[]>([]);
  const [gettingGroups, setGettingGroups] = React.useState<boolean>(true);
  const dispatch = useDispatch();
  const styles = useStyles();

  React.useEffect(() => {
    getGroups();
  }, []);

  const getGroups = () => {
    BrancherDispatch(
      dispatch,
      UtilGetUserGroups((groupRes) => {
        if (groupRes.success) {
          setGroups(groupRes.data);
        }
        setGettingGroups(false);
      }),
    );
  };

  return (
    <PageLayout pageTitle="Groups" hasFeaturePageLayout>
      {!gettingGroups ? (
        <Grid container justify="center">
          <Grid container item xs={12} alignItems="center" spacing={2}>
            {groups?.length > 0 ? (
              groups?.map((group) => (
                <Grid item xs={6} md={4} key={group.groupId}>
                  <Box
                    className={styles.group}
                    onClick={() =>
                      window.location.assign(`${GetRoute('group').path}?g=${group.groupId}`)
                    }
                  >
                    <Grid container direction="column">
                      <Text variant="md" color="purple" fontWeight={500}>
                        {group.title}
                      </Text>
                      <Text variant="xs" fontWeight={600} marginTop={10} align="right">
                        {group.mentors.length} mentor{addPlural(group.mentors.length)}
                      </Text>
                      <Text variant="xs" fontWeight={600} marginTop={10} align="right">
                        {group.mentees.length} mentee{addPlural(group.mentees.length)}
                      </Text>
                    </Grid>
                  </Box>
                </Grid>
              ))
            ) : (
              <Grid container justify="center">
                <Text variant="md" color="purple" fontWeight={500}>
                  You are not part of any groups yet.
                </Text>
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <CircularProgress color="secondary" size={64} />
      )}
    </PageLayout>
  );
};
