import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherSnackbar } from '../../../Components/General/BrancherSnackbar';
import { Text } from '../../../Components/General/Text';
import { CreateButton } from '../../../Components/InputFields/BrancherButton';
import { BrancherTextField } from '../../../Components/InputFields/BrancherTextField';
import { BrancherDispatch, UtilUpdateUserEmail } from '../../../store/actions';
import { IStoreTypes } from '../../../store/storeTypes';

export const AccountSettings = () => {
  const previousEmail = useSelector((state: IStoreTypes) => state.user.email);
  const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [newEmail, setNewEmail] = React.useState<string>('');
  const [verifyNewEmail, setVerifyNewEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [apiError, setApiError] = React.useState<string>('');
  const dispatch = useDispatch();

  const saveNewEmail = () => {
    setApiError('');
    setLoading(true);
    BrancherDispatch(
      dispatch,
      UtilUpdateUserEmail(newEmail, password, (res) => {
        setLoading(false);
        if (res.success) {
          setOpenSnackbar(true);
        } else {
          setApiError(res.message);
        }
      }),
    );
  };

  const validateEmailField = (): boolean => {
    return (
      !!newEmail &&
      !!verifyNewEmail &&
      !!password &&
      newEmail !== previousEmail &&
      newEmail === verifyNewEmail
    );
  };

  return (
    <Grid container justify="space-between" spacing={1}>
      <BrancherSnackbar
        controlClose={() => setOpenSnackbar(false)}
        open={openSnackbar}
        message="Email successfully updated!"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      />
      <Grid item xs={12}>
        <Text variant="md" fontWeight={600}>
          Change email
        </Text>
      </Grid>
      <Grid item xs={12} md={5} component="form">
        <BrancherTextField
          fullWidth
          value={newEmail}
          updateValue={setNewEmail}
          label="New Email"
          name="new-email"
          placeholder="Enter email"
          autoComplete="off"
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <BrancherTextField
          fullWidth
          value={verifyNewEmail}
          updateValue={setVerifyNewEmail}
          label="Verify New Email"
          name="verify-new-email"
          placeholder="Enter email again"
          autoComplete="off"
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <BrancherTextField
          fullWidth
          name="password"
          type="password"
          value={password}
          updateValue={setPassword}
          placeholder="Enter Password"
          label="Password"
        />
      </Grid>
      {apiError && (
        <Grid item xs={12}>
          <Text variant="sm" fontWeight={600} color="red">
            {apiError}
          </Text>
        </Grid>
      )}
      <Grid item container justify="flex-end">
        <CreateButton loading={loading} onClick={saveNewEmail} disabled={!validateEmailField()}>
          Update email
        </CreateButton>
      </Grid>
    </Grid>
  );
};
