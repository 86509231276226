import * as React from 'react';
import { useSelector } from 'react-redux';
import { IModuleMapper } from '../ModuleMapper';
import { BeyondProgramMentorStepOne } from './Steps/BeyondProgramMentorStepOne';
import { BeyondProgramMentorStepTwo } from './Steps/BeyondProgramMentorStepTwo';
import { BeyondProgramMentorStepThree } from './Steps/BeyondProgramMentorStepThree';
import { BeyondProgramMentorStepFourContinue } from './Steps/BeyondProgramMentorStepFourContinue';
import { BeyondProgramMentorStepFourStop } from './Steps/BeyondProgramMentorStepFourStop';
import { IStoreTypes } from '../../../../store/storeTypes';
import { BeyondProgramMentorStepFiveContinue } from './Steps/BeyondProgramMentorStepFiveContinue';
import { BeyondProgramMentorStepSixContinueIncorrect } from './Steps/BeyondProgramMentorStepSixContinueIncorrect';
import { BeyondProgramMentorStepSixContinueCorrect } from './Steps/BeyondProgramMentorStepSixContinueCorrect';
import { BeyondProgramMentorStepSeven } from './Steps/BeyondProgramMentorStepSeven';
import { BeyondProgramMentorStepEight } from './Steps/BeyondProgramMentorStepEight';
import { BeyondProgramMentorStepFiveStop } from './Steps/BeyondProgramMentorStepFiveStop';
import { BeyondProgramMentorStepFiveStopCorrect } from './Steps/BeyondProgramMentorStepFiveStopCorrect';
import { BeyondProgramMentorStepFiveStopIncorrect } from './Steps/BeyondProgramMentorStepFiveStopIncorrect';
import { BeyondProgramMentorStepSixStop } from './Steps/BeyondProgramMentorStepSixStop';
import { BeyondProgramMentorStepSixStopCorrect } from './Steps/BeyondProgramMentorStepSixStopCorrect';
import { BeyondProgramMentorStepSixStopIncorrect } from './Steps/BeyondProgramMentorStepSixStopIncorrect';
import { BeyondProgramMentorStepNine } from './Steps/BeyondProgramMentorStepNine';
import { BeyondProgramMentorStepTen } from './Steps/BeyondProgramMentorStepTen';
import { BeyondProgramMentorStepEleven } from './Steps/BeyondProgramMentorStepEleven';
import { BeyondProgramMentorStepTwelve } from './Steps/BeyondProgramMentorStepTwelve';
import { BeyondProgramMentorStepFourteen } from './Steps/BeyondProgramMentorStepFourteen';
import { BeyondProgramMentorStepThirteen } from './Steps/BeyondProgramMentorStepThirteen';

const BaseBeyondProgramMentorModuleMapper: IModuleMapper[] = [
  {
    name: 'BeyondProgramMentorStepOne',
    component: <BeyondProgramMentorStepOne />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    nextComponent: 'BeyondProgramMentorStepTwo',
  },
  {
    name: 'BeyondProgramMentorStepTwo',
    component: <BeyondProgramMentorStepTwo />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'BeyondProgramMentorStepOne',
    nextComponent: 'BeyondProgramMentorStepThree',
  },
  {
    name: 'BeyondProgramMentorStepThree',
    component: <BeyondProgramMentorStepThree />,
    iterateStepper: true,
    hasQuestion: true,
    changeFlowStep: true,
    previousComponent: 'BeyondProgramMentorStepTwo',
    correctComponent: 'BeyondProgramMentorStepFourContinue',
    incorrectComponent: 'BeyondProgramMentorStepFourStop',
  },
];

const EndBeyondProgramMentorModuleWrapper: IModuleMapper[] = [
  {
    name: 'BeyondProgramMentorStepSeven',
    component: <BeyondProgramMentorStepSeven />,
    iterateStepper: true,
    contentScreen: true,
    previousComponent: 'BeyondProgramMentorStepSixCorrect',
    nextComponent: 'BeyondProgramMentorStepEight',
  },
  {
    name: 'BeyondProgramMentorStepEight',
    component: <BeyondProgramMentorStepEight />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'BeyondProgramMentorStepSeven',
    nextComponent: 'BeyondProgramMentorStepNine',
  },
  {
    name: 'BeyondProgramMentorStepNine',
    component: <BeyondProgramMentorStepNine />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'BeyondProgramMentorStepEight',
    nextComponent: 'BeyondProgramMentorStepTen',
  },
  {
    name: 'BeyondProgramMentorStepTen',
    component: <BeyondProgramMentorStepTen />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'BeyondProgramMentorStepNine',
    nextComponent: 'BeyondProgramMentorStepEleven',
  },
  {
    name: 'BeyondProgramMentorStepEleven',
    component: <BeyondProgramMentorStepEleven />,
    iterateStepper: true,
    contentScreen: true,
    previousComponent: 'BeyondProgramMentorStepTen',
    nextComponent: 'BeyondProgramMentorStepTwelve',
  },
  {
    name: 'BeyondProgramMentorStepTwelve',
    component: <BeyondProgramMentorStepTwelve />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'BeyondProgramMentorStepEleven',
    nextComponent: 'BeyondProgramMentorStepThirteen',
  },
  {
    name: 'BeyondProgramMentorStepThirteen',
    component: <BeyondProgramMentorStepThirteen />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'BeyondProgramMentorStepTwelve',
    nextComponent: 'BeyondProgramMentorStepFourteen',
  },
  {
    name: 'BeyondProgramMentorStepFourteen',
    component: <BeyondProgramMentorStepFourteen />,
    iterateStepper: false,
    noValidation: true,
    submit: true,
    previousComponent: 'BeyondProgramMentorStepThirteen',
  },
];

const StopBeyondProgramMentorModuleMapper: IModuleMapper[] = [
  ...BaseBeyondProgramMentorModuleMapper,
  {
    name: 'BeyondProgramMentorStepFourStop',
    component: <BeyondProgramMentorStepFourStop />,
    iterateStepper: true,
    contentScreen: true,
    previousComponent: 'BeyondProgramMentorStepThree',
    nextComponent: 'BeyondProgramMentorStepFiveStop',
  },
  {
    name: 'BeyondProgramMentorStepFiveStop',
    component: <BeyondProgramMentorStepFiveStop />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'BeyondProgramMentorStepFourStop',
    correctComponent: 'BeyondProgramMentorStepFiveStopCorrect',
    incorrectComponent: 'BeyondProgramMentorStepFiveStopIncorrect',
  },
  {
    name: 'BeyondProgramMentorStepFiveStopCorrect',
    component: <BeyondProgramMentorStepFiveStopCorrect />,
    iterateStepper: true,
    previousComponent: 'BeyondProgramMentorStepFiveStop',
    nextComponent: 'BeyondProgramMentorStepSixStop',
  },
  {
    name: 'BeyondProgramMentorStepFiveStopIncorrect',
    component: <BeyondProgramMentorStepFiveStopIncorrect />,
    iterateStepper: true,
    previousComponent: 'BeyondProgramMentorStepFiveStop',
    nextComponent: 'BeyondProgramMentorStepSixStop',
  },
  {
    name: 'BeyondProgramMentorStepSixStop',
    component: <BeyondProgramMentorStepSixStop />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'BeyondProgramMentorStepFiveStop',
    correctComponent: 'BeyondProgramMentorStepSixCorrect',
    incorrectComponent: 'BeyondProgramMentorStepSixStopIncorrect',
  },
  {
    name: 'BeyondProgramMentorStepSixCorrect',
    component: <BeyondProgramMentorStepSixStopCorrect />,
    iterateStepper: true,
    contentScreen: true,
    previousComponent: 'BeyondProgramMentorStepSixStop',
    nextComponent: 'BeyondProgramMentorStepSeven',
  },
  {
    name: 'BeyondProgramMentorStepSixStopIncorrect',
    component: <BeyondProgramMentorStepSixStopIncorrect />,
    iterateStepper: true,
    previousComponent: 'BeyondProgramMentorStepSixStop',
    nextComponent: 'BeyondProgramMentorStepSeven',
  },
  ...EndBeyondProgramMentorModuleWrapper,
];

const ContinueBeyondProgramMentorModuleMapper: IModuleMapper[] = [
  ...BaseBeyondProgramMentorModuleMapper,
  {
    name: 'BeyondProgramMentorStepFourContinue',
    component: <BeyondProgramMentorStepFourContinue />,
    iterateStepper: true,
    contentScreen: true,
    previousComponent: 'BeyondProgramMentorStepThree',
    nextComponent: 'BeyondProgramMentorStepFiveContinue',
  },
  {
    name: 'BeyondProgramMentorStepFiveContinue',
    component: <BeyondProgramMentorStepFiveContinue />,
    iterateStepper: true,
    needsInteraction: true,
    hasQuestion: true,
    previousComponent: 'BeyondProgramMentorStepFourContinue',
    correctComponent: 'BeyondProgramMentorStepSixContinueCorrect',
    incorrectComponent: 'BeyondProgramMentorStepSixContinueIncorrect',
  },
  {
    name: 'BeyondProgramMentorStepSixContinueIncorrect',
    component: <BeyondProgramMentorStepSixContinueIncorrect />,
    iterateStepper: false,
    contentScreen: true,
    previousComponent: 'BeyondProgramMentorStepFiveContinue',
    nextComponent: 'BeyondProgramMentorStepSixContinueCorrect',
  },
  {
    name: 'BeyondProgramMentorStepSixContinueCorrect',
    component: <BeyondProgramMentorStepSixContinueCorrect />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'BeyondProgramMentorStepFiveContinue',
    nextComponent: 'BeyondProgramMentorStepSeven',
  },
  ...EndBeyondProgramMentorModuleWrapper,
];

export const BeyondProgramMentorModuleMapper = (): IModuleMapper[] => {
  const StepThreeFilterValue = useSelector(
    (state: IStoreTypes) => state.training?.beyondProgramMentor?.StepThree,
  );
  return StepThreeFilterValue?.correct === undefined
    ? ContinueBeyondProgramMentorModuleMapper
    : StepThreeFilterValue?.correct
    ? ContinueBeyondProgramMentorModuleMapper
    : StopBeyondProgramMentorModuleMapper;
};
