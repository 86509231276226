import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { ScaleText } from './ScaleText';

export const LikertScaleText = () => {
  return (
    <Grid container direction="column" item>
      <ScaleText scale={1} description="Strongly disagree" />
      <ScaleText scale={2} description="Disagree" />
      <ScaleText scale={3} description="Neutral" />
      <ScaleText scale={4} description="Agree" />
      <ScaleText scale={5} description="Strongly agree" />
    </Grid>
  );
};
