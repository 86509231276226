import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveIntroductionMentor } from '../../../../../store/actions';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';
import { AlexAvatar } from '../../avatars/Alex';

export const IntroductionMentorStepSix = () => {
  const stepSixValue = useSelector((state: IStoreTypes) => state.training.introductionMentor);
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepSixCorrectVal = ['0', '1'];
    dispatch(
      SaveIntroductionMentor({
        ...stepSixValue,
        StepSix: {
          selected: val,
          correct: StepSixCorrectVal.includes(val[0]) && StepSixCorrectVal.includes(val[1]),
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <AlexAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Jordan arrives 10 minutes late in your first meeting, what do you do?
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You can choose more than one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="Accept Jordan’s apology, check that everything is OK and whether they would like to proceed with the meeting."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="Have a clear discussion on meeting etiquette and your expectations. If appropriate, acknowledge that you know today there were exceptional circumstances and that's OK."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="Initiate a discussion around mentoring boundaries"
        />
      </Grid>
      <Grid item container justify="center">
        <SelectionControl
          question=""
          name="stepSixValue"
          value={stepSixValue.StepSix?.selected}
          updateValue={updateValue}
          thirdWidth
          noQuestion
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
