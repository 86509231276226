import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { SuggestionCard } from '../../SuggestionCard';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { GetTrainingResourceLink } from '../../../../../utils/LinkUtils';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';

export const BeyondProgramMentorStepFourteen = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentee = position === ProgramPositions.mentee;
  const module: EStructuredTrainingModules = isMentee
    ? EStructuredTrainingModules.BEYOND_PROGRAM_MENTEE
    : EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR;

  const endRelationshipLink = isMentee
    ? `ending_relationship_mentee.pdf`
    : `ending_relationship_mentor.pdf`;
  const continueRelationshipLink = isMentee
    ? `continuing_relationship_mentee.pdf`
    : `continuing_relationship_mentor.pdf`;

  const sessionSpecificNotes = isMentee
    ? [
        "Review the notes you’ve prepared in this module (saved in the 'Notes' section of Brancher)",
        'Schedule your final meeting with your mentor, if you haven’t already done so',
      ]
    : [
        'Book in your final meeting with your mentee, if you haven’t already done so',
        'Prepare some professional and personal development suggestions for your mentee to pursue outside of this program (i.e., referrals, subscriptions, conferences, events)',
      ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item xs={12}>
        <Text variant="sm" fontWeight={700}>
          Well done on completing the 'wrap up' training module!
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="sm" fontWeight={700}>
          Next steps:
        </Text>
        <BrancherList
          listItems={[
            <>
              <Text variant="sm" fontWeight={600} marginBottom={10}>
                Save and review:
              </Text>
              <BrancherList
                listItems={[
                  ...sessionSpecificNotes,
                  <BrancherLinkText
                    marginBottom={10}
                    marginTop={10}
                    variant="sm"
                    underline="always"
                    target="_blank"
                    fontWeight={600}
                    href={GetTrainingResourceLink(module, endRelationshipLink)}
                  >
                    What to consider when ending the mentoring relationship
                  </BrancherLinkText>,
                  <BrancherLinkText
                    marginBottom={10}
                    variant="sm"
                    underline="always"
                    target="_blank"
                    fontWeight={600}
                    href={GetTrainingResourceLink(module, continueRelationshipLink)}
                  >
                    What to consider if you want to continue the mentoring relationship
                  </BrancherLinkText>,
                ]}
              />
            </>,
            'Continue working on the new SMART goal you have set for yourself. ',
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="sm" fontWeight={700}>
          Your development continues beyond this relationship.
        </Text>
      </Grid>
      <Grid item xs={12} container justify="center">
        <BrancherTrainingImage
          src={GetTrainingResourceLink(
            EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR,
            'smart.png',
          )}
          alt="smart-goal"
        />
      </Grid>
      <Grid item xs={12}>
        <SuggestionCard>
          Make sure you click the "✓" button to complete this training.
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
