import {
  GENERAL_FORM_DATA,
  GENERAL_CLEAR_FORM_DATA,
  GENERAL_CLEAR_USER_RESPONSE_DATA,
} from '../reducers/GeneralFormReducer';

export const SaveGeneralFormData = (userData: object) => {
  return {
    type: GENERAL_FORM_DATA,
    payload: userData,
  };
};

export const ClearGeneralFormData = () => {
  return {
    type: GENERAL_CLEAR_FORM_DATA,
  };
};

export const ClearGeneralUserResponseData = () => {
  return {
    type: GENERAL_CLEAR_USER_RESPONSE_DATA,
  };
};
