import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { CheckAnswerSection } from '../../../../../Components/General/CheckAnswerSection';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const EasyEnglishMenteeStepTwoCorrect = () => {
  const stepTwoValue = useSelector(
    (state: IStoreTypes) => state.training.easyEnglishMentee.StepTwo,
  );
  return (
    <Grid container direction="column" spacing={4}>
      <CheckAnswerSection correct={stepTwoValue.correct}>
        <Text variant="sm" fontWeight={700} marginBottom={20}>
          {stepTwoValue.correct ? 'Correct' : 'Not quite'}.
        </Text>
        <Text variant="sm">
          It is your role as the mentee (person who gets help) to take the lead.
          <br />
          Talk about your work goals.
          <br />
          Talk about what help you need.
          <br />
          Say what you want to talk about in your meetings.
        </Text>
      </CheckAnswerSection>
    </Grid>
  );
};
