import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { useSelector } from 'react-redux';
import { Text } from '../../Components/General/Text';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { AddCalendar } from '../Meetings/AddCalendar';
import { GetRoute } from '../../Components/Routing';
import { Colors } from '../../consts/colors';
import { IStoreTypes } from '../../store/storeTypes';
import { Subscription } from '../../types/SubscriptionTypes';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { BrancherDivider } from '../../Components/General/BrancherDivider';

const useStyles = makeStyles({
  progressBar: {
    marginBottom: 10,
    marginTop: -5,
  },
  stepCard: {
    border: (props: { completed?: boolean }) =>
      props.completed ? `2px solid ${Colors.green}` : `2px solid ${Colors.cardBorder}`,
    background: Colors.backgroundGrey,
    borderRadius: 10,
    paddingTop: 6,
    paddingBottom: 6,
    marginBottom: 12,
    padding: 8,
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

enum EOnboardingSteps {
  CONNECT_CALENDAR = 'CONNECT_CALENDAR',
  COMPLETE_FIRST_TRAINING = 'COMPLETE_FIRST_TRAINING',
  SEARCH_COHORT = 'SEARCH_COHORT',
  SEND_MENTORING_REQUEST = 'SEND_MENTORING_REQUEST',
}

interface IInitialOnboardingSteps {
  stepType: EOnboardingSteps;
  title: string;
  route?: string; // for route based next step
  action?: () => void; // for function based next step
}

export const OnboardingSteps: React.FC = () => {
  const [showCalendarOnboard, setShowCalendarOnboard] = React.useState<boolean>(false);
  const subscriptions = useSelector((state: IStoreTypes) => state.user?.subscription);
  const sessionPosition = useSelector((state: IStoreTypes) => state.user?.sessionPosition);
  const userEmail = useSelector((state: IStoreTypes) => state.user?.email);
  const menteeGetStartedProgress = useSelector(
    (state: IStoreTypes) => state.user?.menteeGetStartedProgress,
  );
  const mentorGetStartedProgress = useSelector(
    (state: IStoreTypes) => state.user?.mentorGetStartedProgress,
  );
  const currentProgress =
    sessionPosition === ProgramPositions.mentee
      ? menteeGetStartedProgress
      : mentorGetStartedProgress;
  const isSSO = useSelector((state: IStoreTypes) => state.user?.isSSO);
  const hasGmailAccount = userEmail.indexOf('gmail.com') !== -1;
  const hasOutlookAccount = userEmail.indexOf('outlook.com') !== -1;
  const allowsOutlookIntegration = isSSO
    ? subscriptions.includes(Subscription.MEETINGS_OUTLOOK)
    : true;
  const isOnDemand = subscriptions.includes(Subscription.ADHOC_PAIRING);
  const hasTraining = subscriptions.includes(Subscription.TRAINING);
  const hasCohort = subscriptions.includes(Subscription.COHORT);
  const hasAssociatedCalendarAccount = hasGmailAccount || hasOutlookAccount;
  const addConnectCalendar = allowsOutlookIntegration || isSSO || hasAssociatedCalendarAccount;
  const completedSteps: { [x in EOnboardingSteps]?: boolean } = {
    [EOnboardingSteps.CONNECT_CALENDAR]: currentProgress?.hasConnectedCalendar,
    [EOnboardingSteps.COMPLETE_FIRST_TRAINING]: currentProgress?.hasCompletedTraining,
    [EOnboardingSteps.SEARCH_COHORT]: currentProgress?.hasExploredCohort,
    [EOnboardingSteps.SEND_MENTORING_REQUEST]: currentProgress?.hasSentMentoringRequest,
  };
  const styles = useStyles();

  const initialSteps: IInitialOnboardingSteps[] = [];
  if (isOnDemand) {
    initialSteps.push({
      stepType: EOnboardingSteps.SEND_MENTORING_REQUEST,
      title: 'Send a mentoring request',
      route: GetRoute('findMentoringPartner').path,
    });
  }
  if (addConnectCalendar) {
    initialSteps.push({
      stepType: EOnboardingSteps.CONNECT_CALENDAR,
      title: 'Connect calendar',
      action: () => setShowCalendarOnboard(true),
    });
  }
  if (hasTraining) {
    initialSteps.push({
      stepType: EOnboardingSteps.COMPLETE_FIRST_TRAINING,
      title: 'Complete your first training',
      route: GetRoute('training').path,
    });
  }
  if (hasCohort) {
    initialSteps.push({
      stepType: EOnboardingSteps.SEARCH_COHORT,
      title: 'Connect with others on the cohort page',
      route: GetRoute('contacts').path,
    });
  }

  const stepsAmount = initialSteps.length;
  const allStepsCompletedStatus = Object.values(completedSteps).filter((comp) => !!comp)?.length;
  const stepsCompleted =
    allStepsCompletedStatus > stepsAmount ? stepsAmount : allStepsCompletedStatus;
  const completedAllSteps = stepsCompleted === stepsAmount;

  return !completedAllSteps ? (
    <Grid container>
      <Grid item>
        <Text variant="sm" fontWeight={700} color="purple" marginBottom={10}>
          Getting started with Brancher
        </Text>
      </Grid>
      <Grid container alignItems="center" className={styles.progressBar} justify="space-between">
        <Grid item xs={9}>
          <LinearProgress
            value={stepsCompleted * (100 / stepsAmount)}
            valueBuffer={100}
            color="primary"
            variant="determinate"
          />
        </Grid>
        <Grid item xs={2}>
          <Text variant="xxs" marginLeft={8} fontWeight={500}>
            {stepsCompleted} / {stepsAmount}
          </Text>
        </Grid>
      </Grid>
      {showCalendarOnboard && (
        <BrancherDialog
          contentWidth
          setClose={() => setShowCalendarOnboard(false)}
          title="Add Calendar"
          labelledBy="calendar-dialog-add"
          open={showCalendarOnboard}
        >
          <AddCalendar redirectPath="dashboard" getEvents={() => setShowCalendarOnboard(false)} />
        </BrancherDialog>
      )}
      {initialSteps.map((step) => (
        <OnboardingStep
          completed={!!completedSteps?.[step.stepType]}
          key={step.stepType}
          {...step}
        />
      ))}
      <Grid item>
        <BrancherDivider marginBottom={30} marginTop={10} height={1} />
      </Grid>
    </Grid>
  ) : null;
};

interface IOnboardingStep extends IInitialOnboardingSteps {
  completed: boolean;
}

const OnboardingStep: React.FC<IOnboardingStep> = ({
  completed,
  stepType,
  route,
  title,
  action,
}) => {
  const styles = useStyles({ completed });

  return (
    <Box onClick={route ? () => window.location.assign(route) : action} className={styles.stepCard}>
      <Grid container justify="space-between" item alignItems="center" xs={12}>
        <Grid item container alignItems="center">
          {completed ? <CheckBox color="disabled" /> : <CheckBoxOutlineBlank />}
          <Text
            variant="xxs"
            fontWeight={600}
            color={completed ? 'green' : 'purple'}
            display="inline"
            marginLeft={8}
          >
            {title}
          </Text>
        </Grid>
      </Grid>
    </Box>
  );
};
