import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { PowerOfReflectionMenteeStepOne } from '../MoDPowerOfReflectionMentee/Steps/PowerOfReflectionMenteeStepOne';
import { PowerOfReflectionMenteeStepTwo } from '../MoDPowerOfReflectionMentee/Steps/PowerOfReflectionMenteeStepTwo';
import { PowerOfReflectionMenteeStepThree } from '../MoDPowerOfReflectionMentee/Steps/PowerOfReflectionMenteeStepThree';

export const MoDGrowthCoachingFrameworkMentorModuleMapper: IModuleMapper[] = [
  {
    name: 'PowerOfReflectionMentorStepOne',
    component: <PowerOfReflectionMenteeStepOne />,
    iterateStepper: true,
    needsInteraction: true,
    nextComponent: 'PowerOfReflectionMentorStepTwo',
  },
  {
    name: 'PowerOfReflectionMentorStepTwo',
    component: <PowerOfReflectionMenteeStepTwo />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'PowerOfReflectionMentorStepOne',
    nextComponent: 'PowerOfReflectionMentorStepThree',
  },
  {
    name: 'PowerOfReflectionMentorStepThree',
    component: <PowerOfReflectionMenteeStepThree />,
    iterateStepper: true,
    needsInteraction: true,
    submit: true,
    previousComponent: 'PowerOfReflectionMentorStepTwo',
  },
];
