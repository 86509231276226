import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SuggestionCard } from '../../SuggestionCard';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';

export const IntroductionMentorStepThreeIncorrect = () => {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          That is not the best option. The best option was{' '}
        </Text>
        <Text variant="md" fontWeight={700} color="purple" display="inline">
          B
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Do you have a preference to be paired with someone similar to you? You’re not alone. As
          human beings, we prefer people who are more like ourselves. Researchers and psychologists
          have found this is due to bias. Learn more about biases{' '}
        </Text>
        <BrancherLinkText
          underline="always"
          fontWeight={700}
          variant="md"
          href="https://media.brancher.com.au/training/introductionMentee/cognitive_bias.pdf"
          display="inline"
          target="_blank"
        >
          here
        </BrancherLinkText>
        <Text variant="md" display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Being matched with someone ‘different’ can be a positive thing. Brancher creates
          complementary matches because we recognise the value of diversity and will match you with
          someone who will stretch and challenge you so that you grow as a person and a
          professional.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Your Mentoring Lead is available for support but before reaching out to them, meet with
          Jordan to get to know each other and explore why the relationship might work (rather than
          why it might not work).
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Ask what Jordan wants to achieve from the program and share what skills, advice and
          perspective you can offer them.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Ask them questions like:
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard>"What are you hoping to get out of the mentoring program?"</SuggestionCard>
      </Grid>
      <Grid item>
        <SuggestionCard>
          "What experiences can I share with you that might be helpful?"
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="md">
          If the relationship is unproductive, inform your Mentoring Lead so they can ensure you
          take appropriate action to end the relationship professionally.
        </Text>
      </Grid>
    </Grid>
  );
};
