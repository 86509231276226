import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Text } from '../../Components/General/Text';
import { GetIllustrationLink } from '../../utils/LinkUtils';

const useStyles = makeStyles({
  resources: {
    maxHeight: 70,
    maxWidth: 70,
  },
});

export const ResourcesSection: React.FC = () => {
  const styles = useStyles();

  return (
    <Grid container alignItems="center" justify="space-between">
      <Grid item xs={3}>
        <img
          src={GetIllustrationLink('resources.png')}
          alt="resources"
          className={styles.resources}
        />
      </Grid>
      <Grid item xs={8}>
        <Text variant="sm" fontWeight={600} color="secondaryGrey" display="inline">
          Resources related to the training modules.
        </Text>
      </Grid>
    </Grid>
  );
};
