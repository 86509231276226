import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Text } from '../../Components/General/Text';

interface IQuestionHeader {
  children: React.ReactNode;
  stage?: string;
}

export const QuestionHeader = (props: IQuestionHeader) => {
  const { children, stage } = props;
  return (
    <Box marginTop={4} marginBottom={1}>
      <Grid container alignItems="center" justify="center">
        <Text variant="xl" noPreWrap>
          {children}
        </Text>
        {stage && (
          <Text variant="md" marginLeft={8}>
            {stage}
          </Text>
        )}
      </Grid>
    </Box>
  );
};
