import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../Components/General/Text';
import { GoogleCalendar } from './GoogleCalendar';
import { AzureCalendar } from './AzureCalendar';
import { ECalendars } from './Scheduler';
import { IStoreTypes } from '../../store/storeTypes';

export interface IAddCalendar {
  isGroupOwner?: boolean;
  getEvents?: (calendarType: ECalendars) => void;
  redirectPath?: string;
}

export const AddCalendar: React.FC<IAddCalendar> = ({ getEvents, redirectPath, isGroupOwner }) => {
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const sessionPosition = useSelector((state: IStoreTypes) => state.user?.sessionPosition);
  const partnerRoleTitle = roleLabels[sessionPosition];
  const [confirmCalendarAdded, setConfirmCalendarAdded] = React.useState<boolean>(false);

  const isGroupPath = redirectPath.indexOf('group') !== -1;

  const getUserEvents = (calendarType: ECalendars) => {
    if (redirectPath === 'dashboard' || (isGroupPath && !isGroupOwner)) {
      setConfirmCalendarAdded(true);
    } else {
      getEvents(calendarType);
    }
  };

  return (
    <Grid container justify="space-between">
      {!confirmCalendarAdded ? (
        <>
          <Grid item xs={12}>
            <Text variant="md" marginBottom={30} fontWeight={500}>
              Add one of your calendars by clicking either the Google or Outlook calendar buttons
              below and start scheduling meetings.
            </Text>
          </Grid>
          <Grid item>
            <GoogleCalendar getEvents={getUserEvents} redirectPath={redirectPath} />
          </Grid>
          <Grid item>
            <AzureCalendar getEvents={getUserEvents} redirectPath={redirectPath} />
          </Grid>
          <Grid item xs={12}>
            <Text variant="md" marginTop={35} marginBottom={35} fontWeight={500}>
              By adding a calendar your {!isGroupPath ? partnerRoleTitle : 'group leader'} will be
              able to see when you are “busy”, however will not be able to see other meeting
              details.
            </Text>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Text variant="md" fontWeight={500} marginTop={20} marginBottom={10}>
            You have successfully added your calendar. Your current and future mentoring
            groups/partners can now schedule meetings with you.
          </Text>
          <Text variant="md" fontWeight={500} marginBottom={30}>
            Once your {!isGroupPath ? partnerRoleTitle : 'group owner'} has also added their
            calendar they will be able to see when you are “busy”, however will not be able to see
            other meeting details.
          </Text>
          {!isGroupPath && (
            <Text variant="sm" fontWeight={500}>
              Close this popup and schedule your next meeting.
            </Text>
          )}
        </Grid>
      )}
    </Grid>
  );
};
