import * as React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

interface IDefaultStatePlugin {
  noteId?: string;
  contents?: any;
}

export const DefaultStatePlugin: React.FC<IDefaultStatePlugin> = ({ contents, noteId }) => {
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    const state = editor.parseEditorState(contents);
    editor.setEditorState(state);
  }, [noteId]);

  React.useEffect(() => {
    if (!!contents) {
      const state = editor.parseEditorState(contents);
      editor.setEditorState(state);
    }
  }, [editor]);

  return null;
};
