import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import makeStyles from '@material-ui/styles/makeStyles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { useDispatch, useSelector } from 'react-redux';
import { SaveTrainingData } from '../../../store/actions';
import { navSlideTrainingHeight } from '../../../consts/navHeights';
import { IStoreTypes } from '../../../store/storeTypes';

const useStyles = makeStyles({
  slideBar: {
    height: navSlideTrainingHeight,
    top: 'auto',
    bottom: 0,
  },
  fullWidth: {
    width: '100vw',
  },
});

interface INavSlide {
  children: React.ReactNode;
}

export const NavSlide = ({ children }: INavSlide) => {
  const openTrainingNav = useSelector((state: IStoreTypes) => state.training.openTrainingNav);
  const dispatch = useDispatch();
  const styles = useStyles();

  const openNav = () => {
    dispatch(SaveTrainingData({ openTrainingNav: true }));
    setTimeout(closeNav, 3000);
  };

  const closeNav = () => {
    dispatch(SaveTrainingData({ openTrainingNav: false }));
  };
  return (
    <>
      {!openTrainingNav && (
        <AppBar className={styles.slideBar} position="fixed">
          <Grid container justify="center" alignItems="center" className={styles.slideBar}>
            <IconButton onClick={openNav} size="small" className={styles.fullWidth}>
              <ExpandLess color="action" fontSize="small" />
            </IconButton>
          </Grid>
        </AppBar>
      )}
      <Drawer
        anchor="bottom"
        open={openTrainingNav}
        onClose={closeNav}
        ModalProps={{ hideBackdrop: true }}
        className={styles.slideBar}
      >
        {children}
      </Drawer>
    </>
  );
};
