import { EMentoringAgreementFields } from '../../PostMatching/Training/Modules/MentoringAgreement';
import { IContent } from './PrivateNotesReducer';
import { IReducerType } from './ReducerType';

export const SHARED_NOTES_CLEAR_DATA = 'SHARED_NOTES_CLEAR_DATA';
export const SHARED_NOTES_ADD_ALL = 'SHARED_NOTES_ADD_ALL';
export const SHARED_NOTES_ADD = 'SHARED_NOTES_ADD';
export const MENTORING_AGREEMENT_SAVE = 'MENTORING_AGREEMENT_SAVE';

export const SharedNotesReducer = (state = SharedNotesInitialState, action: IReducerType) => {
  switch (action.type) {
    case SHARED_NOTES_CLEAR_DATA:
      return SharedNotesInitialState;
    case SHARED_NOTES_ADD_ALL:
      return { ...state, sharedNotes: action.payload };
    case MENTORING_AGREEMENT_SAVE:
      return { ...state, mentoringAgreement: action.payload };
    case SHARED_NOTES_ADD:
      return { ...state, sharedNotes: [...state.sharedNotes, action.payload] };
    default:
      return state;
  }
};
export interface IMentoringAgreement {
  [EMentoringAgreementFields.MEET_OFTEN]?: string;
  [EMentoringAgreementFields.CONFIRM_MEETINGS]?: string;
  [EMentoringAgreementFields.BETWEEN_MEETINGS]?: string;
  [EMentoringAgreementFields.BOUNDARIES]?: string;
  [EMentoringAgreementFields.CHANGING_CIRCUMSTANCES]?: string;
  [EMentoringAgreementFields.OWN_MEETING_AGENDA]?: string;
  [EMentoringAgreementFields.EXPECTATIONS]?: string;
  [EMentoringAgreementFields.GIVE_RECEIVE_FEEDBACK]?: string;
  [EMentoringAgreementFields.MENTEES_GOALS]?: string;
  [EMentoringAgreementFields.MENTORS_GOALS]?: string;
  [EMentoringAgreementFields.ACCOUNTABLE_FOR_GOALS]?: string;
  completed?: boolean;
}

export interface ISharedNote {
  contents: any; // lexical's domain
  title: string;
  lastModified?: number; // epoch date
  noteId?: string;
}

export interface ISharedNotesReducer {
  sharedNotes?: ISharedNote[];
  mentoringAgreement: IMentoringAgreement;
}

export const SharedNotesInitialState: ISharedNotesReducer = {
  sharedNotes: [],
  mentoringAgreement: {},
};
