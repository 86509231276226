import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { Text } from '../../../../../Components/General/Text';
import { CheckAnswerSection } from '../../../../../Components/General/CheckAnswerSection';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const EasyEnglishMentorStepTwoCorrect = () => {
  const stepTwoValue = useSelector(
    (state: IStoreTypes) => state.training.easyEnglishMentor.StepTwo,
  );
  return (
    <Grid container direction="column" spacing={4}>
      <CheckAnswerSection correct={stepTwoValue.correct}>
        <Text variant="sm" fontWeight={700} marginBottom={20}>
          {stepTwoValue.correct ? 'Correct' : 'Not quite'}.
        </Text>
        <Text variant="sm">
          You go to the Brancher platform each month.
          <br />
          You can:
        </Text>
        <BrancherList
          listItems={[
            'Check the goals',
            'Plan the next meeting',
            'Do training. It is on our website',
          ]}
        />
      </CheckAnswerSection>
    </Grid>
  );
};
