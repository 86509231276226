import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';

export const BeGreatMentorStepEleven = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Well done. You've now learnt about the GROWTH Coaching model.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Whilst this example took a linear approach to applying the model (i.e. each step was
          completed in order), it is important to note that:
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700} color="purple">
          You can adapt the order in which the steps are completed, based on the conversation and
          situation.
        </Text>
      </Grid>
      <Grid item container justify="center" xs={10} sm={11}>
        <BrancherTrainingImage
          src="https://media.brancher.com.au/training/beGreatMentor/growth_graphic.png"
          alt="growth"
        />
      </Grid>
    </Grid>
  );
};
