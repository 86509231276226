import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { NextButton, PrevButton } from '../Components/InputFields/BrancherButton';
import { BrancherDivider, BrancherThickDivider } from '../Components/General/BrancherDivider';
import { Text } from '../Components/General/Text';
import { IQualifyingFormState } from './QualifyingForm';
import { ClearGeneralUserResponseData } from '../store/actions';

export const AboutApplication = (props: IQualifyingFormState) => {
  const { step, setStep } = props;
  const dispatch = useDispatch();

  const controlNextStep = () => {
    dispatch(ClearGeneralUserResponseData());
    setStep(step + 1);
  };

  return (
    <Grid container direction="column" justify="center">
      <Grid>
        <PrevButton variant="text" color="primary" onClick={() => setStep(step - 1)}>
          Back
        </PrevButton>
      </Grid>
      <BrancherDivider />
      <BrancherThickDivider marginTop={40} marginBottom={20} />
      <Text variant="xl" marginBottom={20}>
        About this application
      </Text>
      <Text variant="xs" marginBottom={20}>
        Please complete this form for pairing purposes.
      </Text>
      <Text variant="xs" marginBottom={40}>
        This form is expected to take you approximately 5 minutes. There are no right or wrong
        answers.
        <br />
        <br />
        The data collected in this survey will be used for pairing and research purposes. All
        results are confidential and will be stored safely and securely.
      </Text>
      <Grid item>
        <NextButton onClick={controlNextStep} fullWidth>
          Next
        </NextButton>
      </Grid>
    </Grid>
  );
};
