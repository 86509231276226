import * as React from 'react';
import Link, { LinkProps } from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { IText, Text } from './Text';
import { Colors } from '../../consts/colors';

interface IBrancherLinkText
  extends Omit<LinkProps, 'variant' | 'display' | 'color'>,
    Pick<
      IText,
      | 'variant'
      | 'display'
      | 'fontWeight'
      | 'marginBottom'
      | 'marginLeft'
      | 'marginRight'
      | 'noPreWrap'
      | 'marginTop'
      | 'wordWrap'
      | 'wordBreak'
      | 'color'
    > {}

const useStyles = makeStyles({
  root: {
    color: Colors.purple,
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export const BrancherLinkText = (props: IBrancherLinkText) => {
  const styles = useStyles(props);
  const {
    children,
    variant,
    display,
    fontWeight,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    noPreWrap,
    wordWrap,
    wordBreak,
    color = 'purple',
    ...other
  } = props;
  return (
    <Link className={styles.root} {...other}>
      <Text
        variant={variant}
        display={display}
        fontWeight={fontWeight}
        marginTop={marginTop}
        noPreWrap={noPreWrap}
        wordBreak={wordBreak}
        wordWrap={wordWrap}
        marginRight={marginRight}
        marginLeft={marginLeft}
        color={color}
        marginBottom={marginBottom}
      >
        {children}
      </Text>
    </Link>
  );
};
