import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { CreateMentoringGuideLink } from '../../helpers/LinkBuilder';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';

export const IntroductionMenteeStepFiveCorrect = () => {
  const mentoringGuideLink = CreateMentoringGuideLink();
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          The next part of the training focuses on creating a successful first meeting.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          You'll be noticing a trend by now... All the info you need is in the{' '}
        </Text>
        <BrancherLinkText
          underline="always"
          fontWeight={700}
          variant="md"
          href={mentoringGuideLink}
          display="inline"
          target="_blank"
        >
          Mentoring Guide
        </BrancherLinkText>
        <Text variant="md" display="inline">
          . Have a look in the Guide for helpful tips.
        </Text>
      </Grid>
    </Grid>
  );
};
