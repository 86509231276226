import * as React from 'react';
import { ModuleWrapper } from '../ModuleWrapper';
import { EasyEnglishMenteeModuleMapper } from './EasyEnglishMenteeModuleMapper';

export const EasyEnglishMentee = () => (
  <ModuleWrapper
    moduleMap={EasyEnglishMenteeModuleMapper}
    moduleName="easyEnglishMentee"
    title="Easy English Mentee Guide"
  />
);
