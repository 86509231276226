import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import capitalize from '@material-ui/core/utils/capitalize';
import { useDispatch, useSelector } from 'react-redux';
import {
  SaveMoDPowerOfReflectionMenteeData,
  SaveMoDPowerOfReflectionMentorData,
} from '../../../../../store/actions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingIntroduction } from '../../../TrainingIntroduction';
import {
  getTrainingModuleHeading,
  getTrainingModuleNumber,
} from '../../../TrainingModuleDefaultConfig';
import { Text } from '../../../../../Components/General/Text';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { CompetencePopover } from '../../sharedComps/CompetencePopover';
import { CompetenceScale } from '../../sharedComps/CompetenceScale';
import { BrancherQuestionMarker } from '../../../../../Components/General/BrancherQuestionMarker';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { ConfidenceScale } from '../../sharedComps/ConfidenceScale';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { GetTrainingResourceLink } from '../../../../../utils/LinkUtils';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';

export const PowerOfReflectionSplash: React.FC = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const trainingModules = useSelector((state: IStoreTypes) => state.training.modules)[sesPos];
  const trainingConfig = useSelector((state: IStoreTypes) => state.training.moduleConfig)[sesPos];
  const isMentee = sesPos === ProgramPositions.mentee;
  const modulePathPos = isMentee ? ProgramPositions.mentee : ProgramPositions.mentor;
  const modName = `modPowerOfReflection${capitalize(modulePathPos)}`;
  const powerOfReflectionBaseline = useSelector((state: IStoreTypes) => state.training?.[modName]);
  const dispatch = useDispatch();
  const [anchorEl, setAnchor] = React.useState(null);
  const open = Boolean(anchorEl);

  const controlValidator = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!Boolean(anchorEl)) {
      setAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(anchorEl)) {
      setAnchor(null);
    }
  };

  const updateBaseline = (val: string, attr: string) => {
    const saveFunc = isMentee
      ? SaveMoDPowerOfReflectionMenteeData
      : SaveMoDPowerOfReflectionMentorData;
    dispatch(saveFunc({ [attr]: val }));
  };

  return (
    <TrainingIntroduction
      title={`Training #${getTrainingModuleNumber(
        modName,
        trainingModules,
        trainingConfig,
      )}: ${getTrainingModuleHeading(modName, trainingConfig)}`}
      subHeader="Complete this training to learn more about the power of reflection."
      activityLearnings={[
        'Understand why reflection is important',
        <BrancherLinkText
          marginBottom={10}
          variant="sm"
          target="_blank"
          underline="always"
          href={GetTrainingResourceLink(
            EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTEE,
            'Kolbs_Reflection_Model.pdf',
          )}
        >
          Learn about Kolb's adult learning model
        </BrancherLinkText>,
        "Apply Kolb's adult learning model to your last mentoring meeting",
      ]}
      modulePath={modName}
      validatedValue={
        powerOfReflectionBaseline?.competenceBaseline != null &&
        powerOfReflectionBaseline?.confidenceBaseline != null
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box marginTop={3} marginBottom={3}>
            <Text variant="sm" fontWeight={700} display="inline">
              To provide us with a baseline measure, please rate your current level of{' '}
            </Text>
            <Text variant="sm" fontWeight={700} display="inline" color="purple">
              competence and confidence
            </Text>
            <Text variant="sm" fontWeight={700} display="inline">
              {' '}
              to reflect using Kolb's adult learning model.{' '}
            </Text>
            <BrancherQuestionMarker onClick={controlValidator} />
          </Box>
          <CompetencePopover
            open={open}
            setClose={closeValidator}
            anchor={anchorEl}
            labelledBy="Confidence and competence"
          />
          <Grid item>
            <Text variant="sm" fontWeight={700} marginBottom={10}>
              Please rate your competence:
            </Text>
          </Grid>
          <CompetenceScale />
          <SegmentedControlGroup
            value={powerOfReflectionBaseline?.competenceBaseline}
            valueLength={7}
            updateValue={(v: string) => updateBaseline(v, 'competenceBaseline')}
            fullWidth
          />
          <Grid item>
            <Text variant="sm" fontWeight={700} marginBottom={10}>
              Please rate your confidence:
            </Text>
          </Grid>
          <ConfidenceScale />
          <SegmentedControlGroup
            value={powerOfReflectionBaseline?.confidenceBaseline}
            valueLength={7}
            updateValue={(v: string) => updateBaseline(v, 'confidenceBaseline')}
            fullWidth
          />
        </Grid>
      </Grid>
    </TrainingIntroduction>
  );
};
