import { ITrainingModule } from '../../store/reducerTypes/TrainingReducer.types';

export const getTrainingModuleNumber = (
  moduleName: string,
  modules: string[],
  trainingConfig: ITrainingModule[],
): number => {
  let trainingModuleNumber = 0;
  // Firstly get position of the module
  const moduleIndex = modules.findIndex((a) => a === moduleName);
  modules.forEach((n, l) => {
    const m = trainingConfig.find((a) => a.name === n);
    if (l <= moduleIndex) {
      if (m?.trainingModule) {
        trainingModuleNumber++;
      }
    }
  });
  return trainingModuleNumber;
};

export const getTrainingModuleHeading = (
  moduleName: string,
  trainingConfig: ITrainingModule[],
): string => {
  return trainingConfig.find((a) => a.name === moduleName)?.heading;
};

export const getTrainingModuleConfig = (
  moduleName: string,
  modules: string[],
  progress: any,
  trainingConfig: ITrainingModule[],
) => {
  const t = trainingConfig.find((a) => a.name === moduleName);
  const completed = progress[moduleName] ? progress[moduleName].completed : false;
  if (t) {
    const heading = t.trainingModule
      ? `Training #${getTrainingModuleNumber(moduleName, modules, trainingConfig)}: ${t.heading}`
      : t.heading;
    return { ...t, completed, heading };
  } else {
    return null;
  }
};
