import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import {
  COHORT_CLEAR_DATA,
  COHORT_DATA,
  EMentoringRequestStatus,
  IMentoringRequest,
} from '../reducers/CohortReducer';
import { EProgressActivities, IProfileData } from '../reducers/ProfileFormReducer';
import { EPairingTypes, IPairInfo, IUserMentoringRequest } from '../reducers/UserInfoReducer';
import { IAPIResponseObject } from './actionTypes/apiTypes';
import { SaveUserInfo } from './UserInfoActions';
import { BrancherAuthRequest, BrancherDispatch } from './utils/api-utils';
import { UtilUpdateRoleProfileActivities } from './ProfileFormActions';

export const SaveCohortData = (cohortData: object) => {
  return {
    type: COHORT_DATA,
    payload: cohortData,
  };
};

export const ClearCohortData = () => {
  return {
    type: COHORT_CLEAR_DATA,
  };
};

interface IUtilGetProgramCohortProfiles extends IAPIResponseObject {
  data: IProfileData[];
}

export const UtilGetProgramCohortProfiles = (cb: (a: IUtilGetProgramCohortProfiles) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().user.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/cohort',
        params: JSON.stringify({
          programId,
          publicProfiles: true,
          getDeactivated: false,
          getActivities: false,
        }),
      },
      getState(),
    )
      .then((response: any) => {
        const cohortData = response.data.data;
        // Remove the requesting user's profile?
        dispatch(SaveCohortData({ profiles: cohortData }));
        const isMentee = getState().user.sessionPosition === ProgramPositions.mentee;
        const progressAttribute = isMentee
          ? 'menteeGetStartedProgress'
          : 'mentorGetStartedProgress';
        dispatch(
          SaveUserInfo({
            [progressAttribute]: { ...getState().user[progressAttribute], hasExploredCohort: true },
          }),
        );
        BrancherDispatch(
          dispatch,
          UtilUpdateRoleProfileActivities(EProgressActivities.HAS_EXPLORED_COHORT),
        );
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export interface IDraftedPairs extends Pick<IPairInfo, 'pairingReasons'> {
  approved?: boolean;
  mentee?: string;
  menteeName?: string;
  mentor?: string;
  mentorName?: string;
  mentorPairAmount?: number;
  mentorApprovedPairs?: number;
  menteeAccessibilityDetails?: string;
  mentorAccessibilityDetails?: string;
  menteePairAmount?: number;
  menteeApprovedPairs?: number;
  percentageMatch?: number;
  personalityScoreTotal?: string;
  valuesScoreTotal?: string;
  pairingType?: EPairingTypes;
  partnerProfile?: IProfileData;
  response: any;
  isPeerMentor?: boolean;
  brancherRecommended?: boolean;
  atCapacity?: boolean;
  hasCurrentPairingRequest?: boolean;
}

interface IUtilGetAvailableMentoringPartners extends IAPIResponseObject {
  data: IDraftedPairs[];
}

export const UtilGetAvailableMentoringPartners = (
  filters: any = {},
  cb: (a: IUtilGetAvailableMentoringPartners) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().user.programId;
    const sessionRoleId = getState().user.sessionRoleId;
    const sessionPosition = getState().user.sessionPosition;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/findmentoringpartner',
        params: JSON.stringify({
          programId,
          targetUser: sessionRoleId,
          targetUserPosition: sessionPosition,
          filters,
        }),
      },
      getState(),
    )
      .then((response: any) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetMentoringRequests extends IAPIResponseObject {
  data: {
    receivedRequests: IMentoringRequest[];
    sentRequests: IMentoringRequest[];
  };
}

export const UtilGetMentoringRequests = (cb: (a: IUtilGetMentoringRequests) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().user.programId;
    const roleId = getState().user.sessionRoleId;
    const sessionPosition = getState().user.sessionPosition;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/mentoringrequest',
        params: JSON.stringify({
          programId,
          sessionPosition,
          roleId,
        }),
      },
      getState(),
    )
      .then((response: { data: IUtilGetMentoringRequests }) => {
        dispatch(SaveCohortData({ recheckMentoringRequests: false }));
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilCreateMentoringRequest extends IAPIResponseObject {
  data: IUserMentoringRequest[];
}

export const UtilCreateMentoringRequest = (
  { menteeRoleId, menteeName, mentorRoleId, mentorName, pairingData, requestMessage },
  cb: (a: IUtilCreateMentoringRequest) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().user.programId;
    const sessionPosition = getState().user.sessionPosition;
    const { partnerProfile, ...filteredPairingData } = pairingData;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/mentoringrequest',
        data: {
          programId,
          menteeRoleId,
          menteeName,
          mentorRoleId,
          mentorName,
          requestMessage,
          pairingData: filteredPairingData,
          requestInitiator: sessionPosition,
        },
      },
      getState(),
    )
      .then((response: any) => {
        const isMentee = getState().user.sessionPosition === ProgramPositions.mentee;
        const progressAttribute = isMentee
          ? 'menteeGetStartedProgress'
          : 'mentorGetStartedProgress';
        if (!getState().user?.[progressAttribute]?.hasSentMentoringRequest) {
          dispatch(
            SaveUserInfo({
              [progressAttribute]: {
                ...getState().user[progressAttribute],
                hasSentMentoringRequest: true,
              },
            }),
          );
        }
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilUpdateMentoringRequest = (
  mentoringRequestId: string,
  status: EMentoringRequestStatus,
  message: string = '',
  cb: (a: IUtilGetAvailableMentoringPartners) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().user.programId;
    const username = getState().user.username;
    const sessionPosition = getState().user.sessionPosition;
    const sessionRoleId = getState().user.sessionRoleId;
    const mentoringPartnerRequests = getState().user?.mentoringPartnerRequests;
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/mentoringrequest',
        data: {
          mentoringRequestId,
          programId,
          username,
          status,
          message,
        },
      },
      getState(),
    )
      .then((response: any) => {
        if (response.data.success) {
          if (status === EMentoringRequestStatus.ACCEPTED) {
            const sessionPair =
              sessionPosition === ProgramPositions.mentee
                ? response.data.data?.mentors?.[0]
                : response.data.data?.mentees?.[0];
            dispatch(
              SaveUserInfo({
                ...response.data.data,
                sessionPosition,
                sessionRoleId,
                sessionPair,
                mentoringPartnerRequests: mentoringPartnerRequests?.filter(
                  (f) => f.mentoringRequestId !== mentoringRequestId,
                ),
              }),
            );
          } else {
            dispatch(
              SaveUserInfo({
                mentoringPartnerRequests: mentoringPartnerRequests?.filter(
                  (f) => f.mentoringRequestId !== mentoringRequestId,
                ),
              }),
            );
          }
          dispatch(SaveCohortData({ recheckMentoringRequests: true }));
        }
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilWrapUpMentoringRelationship = (
  { commentToPartner, endingRelationshipExtraReasons, wrapUpReasons, matchSatisfaction },
  cb: (a: IUtilGetAvailableMentoringPartners) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().user.programId;
    const username = getState().user.username;
    const sessionPosition = getState().user.sessionPosition;
    const sessionRoleId = getState().user.sessionRoleId;
    const partnerRoleId = getState().user.sessionPair?.roleId;
    const menteeRoleId =
      sessionPosition === ProgramPositions.mentee ? sessionRoleId : partnerRoleId;
    const mentorRoleId =
      sessionPosition === ProgramPositions.mentor ? sessionRoleId : partnerRoleId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/wrapuprelationship',
        data: {
          username,
          programId,
          menteeRoleId,
          mentorRoleId,
          wrapUpReasons,
          endingRelationshipExtraReasons,
          commentToPartner,
          matchSatisfaction,
          initiatorPosition: sessionPosition,
        },
      },
      getState(),
    )
      .then((response: any) => {
        const sessionPair =
          sessionPosition === ProgramPositions.mentee
            ? response.data.data?.mentors?.[0]
            : response.data.data?.mentees?.[0];
        dispatch(
          SaveUserInfo({
            ...response.data.data,
            sessionPair: sessionPair ?? {},
            sessionPosition,
            sessionRoleId,
          }),
        );
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilWrapUpPartnerSurvey = (
  { partnerExtraComment, partnerMatchSatisfaction, menteeRoleId, mentorRoleId, userActionId },
  cb: (a: IUtilGetAvailableMentoringPartners) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().user.programId;
    const username = getState().user.username;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/wrapuppartnersurvey',
        data: {
          username,
          programId,
          menteeRoleId,
          mentorRoleId,
          partnerExtraComment,
          partnerMatchSatisfaction,
          userActionId,
        },
      },
      getState(),
    )
      .then((response: any) => {
        dispatch(SaveUserInfo({ actions: response.data.data }));
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};
