import { Colors } from '../consts/colors';

export const MuiSvgIcon = {
  root: {
    color: Colors.purple,
  },
  colorPrimary: {
    color: Colors.purple,
  },
  colorSecondary: {
    color: Colors.secondaryGrey,
  },
  colorAction: {
    color: Colors.white,
  },
  colorDisabled: {
    color: Colors.lightGrey,
  },
  colorError: {
    color: Colors.red,
  },
};
