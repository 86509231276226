import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext } from 'react-beautiful-dnd';
import { DraggableColumn } from './DraggableColumn';
import { Text } from './Text';
import { BrancherDivider } from './BrancherDivider';
import { OptionsColumnId } from '../../Form/Values/ValuesFormConfig';

const useStyles = makeStyles({
  board: {
    height: '100%',
  },
});

export interface IDraggableItem {
  id: string;
  readableName: string;
}

export enum EDropAnimation {
  LOVE = 'love',
  NEUTRAL = 'neutral',
  THINKING = 'thinking',
  DISLIKE = 'dislike',
}

export interface IDraggableColumn {
  id: string;
  heading: string;
  items: IDraggableItem[];
  dropAnimation: EDropAnimation;
  hasCompletedQuestion?: boolean;
  placeholder?: string;
}

export interface IDraggableSection {
  question: React.ReactNode;
  value: IDraggableColumn[]; // columns
  updateValue: (items: IDraggableColumn[]) => void;
  vertical?: boolean;
  optionsColumn?: string;
}

interface IDroppableSource {
  index: number;
  droppableId: string;
}

export const DraggableSection: React.FC<IDraggableSection> = ({
  value: columns,
  updateValue,
  question,
  vertical = false,
}) => {
  const styles = useStyles();

  // const controlSettingItems = (items: IDraggableItem[], board: IDraggableColumn['id']) => {
  //   updateValue({ ...columns, [board]: items });
  // };
  //
  // const reorder = (list: IDraggableItem[], startIndex: number, endIndex: number): IDraggableItem[] => {
  //   const result = Array.from(list);
  //   const [removed] = result.splice(startIndex, 1);
  //   result.splice(endIndex, 0, removed);
  //   return result;
  // };

  const hasCompletedQuestion =
    columns?.filter((c) => c.id !== OptionsColumnId).reduce((acc, f) => f?.items?.length + acc, 0) >
    1;

  const getList = (column: IDraggableColumn['id']): IDraggableItem[] =>
    columns.find((f) => f.id === column).items;

  const move = (
    source: IDraggableItem[],
    destination: IDraggableItem[],
    droppableSource: IDroppableSource,
    droppableDestination: IDroppableSource,
  ): any => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  // The main orchestrator for moving cards around
  const onDragEnd = (result: { source: IDroppableSource; destination: IDroppableSource }) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    // If it was to be the same column, then we would just re-order them, not needed for now
    if (source.droppableId !== destination.droppableId) {
      const joinedResult = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination,
      );
      const destinationModifiedList = joinedResult[destination.droppableId].map(
        (a, movedGoalIndex) => {
          if (movedGoalIndex === destination.index) {
            return a;
          } else {
            return a;
          }
        },
      );
      const updatedColumns = columns.map((c) => {
        if (c.id === source.droppableId) {
          return {
            id: source.droppableId,
            items: joinedResult[source.droppableId],
            heading: c.heading,
            dropAnimation: c.dropAnimation,
          };
        } else if (c.id === destination.droppableId) {
          return {
            id: destination.droppableId,
            items: destinationModifiedList,
            heading: c.heading,
            dropAnimation: c.dropAnimation,
          };
        } else {
          return c;
        }
      });
      updateValue(updatedColumns);
    }
  };

  return (
    <Grid
      container
      direction={vertical ? 'column' : 'row'}
      alignItems="center"
      justify="center"
      className={styles.board}
    >
      <Grid item>
        <Text variant="sm" fontWeight={700} marginTop={10}>
          {question}
        </Text>
      </Grid>
      <DragDropContext onDragEnd={onDragEnd}>
        <BrancherDivider width="90%" marginBottom={5} marginTop={15} />
        <Grid container spacing={3} direction={vertical ? 'column' : 'row'} alignItems="center">
          {!!columns &&
            columns?.length > 0 &&
            columns?.map((column, index) => (
              <Grid item container xs={12} key={index}>
                <DraggableColumn
                  hasCompletedQuestion={hasCompletedQuestion}
                  dropAnimation={column.dropAnimation}
                  items={column.items}
                  id={column.id}
                  heading={column.heading}
                  placeholder={column.placeholder}
                />
              </Grid>
            ))}
        </Grid>
      </DragDropContext>
    </Grid>
  );
};
