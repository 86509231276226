import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { SaveMoDBeGreatMenteeData, SaveMoDBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const MoDBeGreatMenteeStepTen = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentor = sesPos === ProgramPositions.mentor;
  const modName = isMentor
    ? EMoDTrainingModules.MOD_BE_GREAT_MENTOR
    : EMoDTrainingModules.MOD_BE_GREAT_MENTEE;
  const saveFunction = isMentor ? SaveMoDBeGreatMentorData : SaveMoDBeGreatMenteeData;
  const stepTenValue = useSelector((state: IStoreTypes) => state.training?.[modName]?.StepTen);
  const dispatch = useDispatch();

  const updateValue = (val: number) => {
    dispatch(
      saveFunction({
        StepTen: {
          selected: val,
          interacted: val !== null,
        },
      }),
    );
  };

  const learningSummaryList: string[] = [
    'Each pair has flexibility  and  choice  regarding  meeting  duration  and frequency',
    'Every relationship must come to a close and graduate from this system (once you start meeting less frequently than once per month) so you are both freed up to find other mentees/mentors.',
    'It is the mentee’s role to drive the relationship (set the meetings and meeting agendas) and their development',
    'A Mentoring Agreement should be completed during the first meeting',
    'There are certain behaviours you must adhere to in accordance with the Code of Conduct',
    'Preparation and reflection are key drivers to success',
  ];

  const trainingHelpList: string[] = [
    'Increased my knowledge regarding what to expect from this relationship',
    'Clarified questions I had about how this would work',
    'Made me feel comfortable to have a first meeting with my mentor',
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center">
        <Text variant="md" fontWeight={700}>
          Here is a summary of what you’ve just learnt:
        </Text>
        <BrancherList listItems={learningSummaryList} fontWeight={400} />
      </Grid>
      <Grid item>
        <Text variant="md">Using the scale below, to what extent has this training:</Text>
        <BrancherList listItems={trainingHelpList} fontWeight={400} />
        <SegmentedControlGroup
          fullWidth
          value={stepTenValue?.selected}
          valueLength={7}
          updateValue={updateValue}
        />
      </Grid>
    </Grid>
  );
};
