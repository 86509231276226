import * as React from 'react';
import { IRichTextNotes } from './RichTextNote';
import { TRANSFORMERS } from '@lexical/markdown';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { ToolbarPlugin } from './lexicalPlugins/LexicalToolbar';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import './lexical.css';

import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { IndentLevelPlugin } from './lexicalPlugins/IndentLevelPlugin';
import { CodeHighlightPlugin } from './lexicalPlugins/CodeHighlightPlugin';
import { EmojisPlugin } from './lexicalPlugins/EmojisPlugin';
import { EmojiPickerPlugin } from './lexicalPlugins/EmojiPickerPlugin';
import { Text } from '../../Components/General/Text';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { EmojiNode } from './lexicalPlugins/EmojiNode';
import { LexicalAutoLinkPlugin } from './lexicalPlugins/AutoLinkPlugin';
import { DefaultStatePlugin } from './lexicalPlugins/DefaultStatePlugin';
import { ControlledLexicalClickableLinkPlugin } from './lexicalPlugins/ControlledLexicalClickableLinkPlugin';
import { FloatingLinkEditorPlugin } from './lexicalPlugins/FloatingLinkEditorPlugin';

interface ILexicalNote extends Pick<IRichTextNotes, 'contents' | 'setContents'> {
  noteId: string;
}

const theme = {};

function Placeholder() {
  return (
    <Text variant="xs" className="editor-placeholder">
      Share some thoughts and learnings...
    </Text>
  );
}

function MyCustomAutoFocusPlugin() {
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    editor.focus();
  }, [editor]);

  return null;
}

export const emptyContent = {
  root: {
    children: [
      { children: [], direction: null, format: '', indent: 0, type: 'paragraph', version: 1 },
    ],
    direction: null,
    format: '',
    indent: 0,
    type: 'root',
    version: 1,
  },
};

export const LexicalNote: React.FC<ILexicalNote> = (props) => {
  const { contents, setContents, noteId } = props;
  const [isLinkEditMode, setIsLinkEditMode] = React.useState<boolean>(false);

  const editorConfig = {
    namespace: 'BrancherEditor',
    theme,
    onError(error) {
      throw error;
    },
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      EmojiNode,
    ],
  };

  const onChange = (editorState) => {
    editorState.read(() => {
      const richTextJSON = editorState.toJSON();
      setContents(richTextJSON);
    });
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      {contents !== emptyContent && (
        <DefaultStatePlugin contents={JSON.stringify(contents)} noteId={noteId} />
      )}
      <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />
      <RichTextPlugin
        contentEditable={<ContentEditable className="editor-input" />}
        placeholder={<Placeholder />}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <OnChangePlugin onChange={onChange} />
      <HistoryPlugin />
      <MyCustomAutoFocusPlugin />
      <CodeHighlightPlugin />
      <EmojisPlugin />
      <EmojiPickerPlugin />
      <IndentLevelPlugin maxDepth={7} />
      <ControlledLexicalClickableLinkPlugin />
      <ListPlugin />
      <LinkPlugin />
      <LexicalAutoLinkPlugin />
      <LexicalClickableLinkPlugin />
      <TablePlugin />
      <TabIndentationPlugin />
      <FloatingLinkEditorPlugin
        isLinkEditMode={isLinkEditMode}
        setIsLinkEditMode={setIsLinkEditMode}
      />
      <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
    </LexicalComposer>
  );
};
