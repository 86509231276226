import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import capitalize from '@material-ui/core/utils/capitalize';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { Text } from '../../Components/General/Text';
import {
  AcceptButton,
  BrancherButton,
  NextButton,
} from '../../Components/InputFields/BrancherButton';
import { BrancherSlider } from '../../Components/InputFields/BrancherSlider';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { BrancherToggleButton } from '../../Components/InputFields/ToggleButton/BrancherToggleButton';
import { MakeOptions } from '../../Form/MakeOptions';
import { BrancherDispatch, UtilWrapUpMentoringRelationship } from '../../store/actions';
import { IStoreTypes } from '../../store/storeTypes';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';

export const WrapUpRelationship: React.FC = () => {
  const roleLabels = useSelector((state: IStoreTypes) => state.user.roleLabels);
  const sessionPair = useSelector((state: IStoreTypes) => state.user.sessionPair);
  const sessionPosition = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [confirmEndRelationship, setConfirmEndRelationship] = React.useState<boolean>(false);
  const [wrappingUpRelationship, setWrappingUpRelationship] = React.useState<boolean>(false);
  const [wrapUpDecision, setWrapUpDecision] = React.useState<string>(null);
  const [extraComment, setExtraComment] = React.useState<string>('');
  const [endingRelationshipExtraReasons, setEndingRelationshipExtraReasons] = React.useState<
    string
  >('');
  const [endingRelationshipReasons, setEndingRelationshipReasons] = React.useState<string[]>([]);
  const [matchSatisfaction, setMatchSatisfaction] = React.useState<number | number[]>(3);
  const dispatch = useDispatch();
  const isMentee = sessionPosition === ProgramPositions.mentee;
  const partnerPosition = isMentee
    ? roleLabels[ProgramPositions.mentor]
    : roleLabels[ProgramPositions.mentee];

  const controlWrapUpModal = () => {
    setOpenModal(true);
  };

  const controlWrapUpRelationship = () => {
    if (wrapUpDecision === '0') {
      setConfirmEndRelationship(true);
    } else {
      setOpenModal(false);
    }
  };

  const endingReasons: string[] = [
    `Achieved ${roleLabels.programStyle} goals`,
    'Not the best fit',
    `Outgrown ${roleLabels[partnerPosition]}`,
    `Already know ${roleLabels[partnerPosition]}`,
    'Capacity restraints',
    'Other (please describe)',
    'N/A',
  ];

  const endRelationship = () => {
    const prettifiedEndingRelationshipReasons = [];
    endingRelationshipReasons.forEach((reason) => {
      prettifiedEndingRelationshipReasons.push(endingReasons[Number(reason)]);
    });
    const wrapUpReasons = prettifiedEndingRelationshipReasons.join(', ');
    setWrappingUpRelationship(true);
    BrancherDispatch(
      dispatch,
      UtilWrapUpMentoringRelationship(
        {
          commentToPartner: extraComment,
          endingRelationshipExtraReasons,
          wrapUpReasons,
          matchSatisfaction,
        },
        () => {
          setWrappingUpRelationship(false);
          setOpenModal(false);
        },
      ),
    );
  };

  return (
    <Grid container>
      <BrancherDialog
        setClose={() => setOpenModal(false)}
        labelledBy="wrap-up-relationship"
        title={`Wrap-Up ${capitalize(roleLabels.programStyle)} Relationship`}
        open={openModal}
        fullWidth
        fitLargeScreen
      >
        {!confirmEndRelationship ? (
          <>
            <Text variant="md" marginBottom={20} fontWeight={500}>
              Are you sure you want to wrap up this relationship?
            </Text>
            <BrancherToggleButton
              exclusive
              halfWidth
              name="wrap-up-decision"
              options={MakeOptions(['Yes', 'No'])}
              updateValue={(decision: string) => setWrapUpDecision(decision)}
              value={wrapUpDecision}
            />
            <Grid container justify="flex-end">
              <NextButton onClick={controlWrapUpRelationship} disabled={!wrapUpDecision}>
                Continue
              </NextButton>
            </Grid>
          </>
        ) : (
          <>
            <Text variant="sm" fontWeight={600} marginBottom={0}>
              Please tell us why (select 1 or more options)
            </Text>
            <BrancherToggleButton
              halfWidth
              name="ending-relationship-reasons"
              value={endingRelationshipReasons}
              options={MakeOptions(endingReasons)}
              updateValue={(reasons: string[]) => setEndingRelationshipReasons(reasons)}
            />
            {endingRelationshipReasons?.includes('5') && (
              <BrancherTextField
                fullWidth
                value={endingRelationshipExtraReasons}
                updateValue={setEndingRelationshipExtraReasons}
                label="Extra Information"
              />
            )}
            <Text variant="xs" marginBottom={30} fontWeight={600}>
              Your response will be privately shared with Brancher and your Program Lead.
            </Text>

            <Text variant="sm" marginTop={40} fontWeight={600}>
              Do you have anything else you'd like to say to {sessionPair.name}? You might like to
              thank them and talk about the impact they have had on you.
            </Text>
            <BrancherTextField
              value={extraComment}
              fullWidth
              updateValue={setExtraComment}
              label="Comments"
            />

            <Text variant="sm" marginTop={40} marginBottom={15} fontWeight={600}>
              How satisfied were you with your match?
            </Text>
            <BrancherSlider
              name="matchSatisfaction"
              value={matchSatisfaction}
              updateValue={setMatchSatisfaction}
              max={5}
              min={1}
            />

            <Text variant="sm" marginTop={40} marginBottom={15} fontWeight={600} align="center">
              By clicking confirm you will officially Wrap-Up and trigger an email to{' '}
              {sessionPair.name} to let them know.
            </Text>
            <AcceptButton
              fullWidth
              onClick={endRelationship}
              disabled={
                !matchSatisfaction ||
                endingRelationshipReasons?.length === 0 ||
                wrappingUpRelationship
              }
              loading={wrappingUpRelationship}
            >
              Confirm
            </AcceptButton>
          </>
        )}
      </BrancherDialog>
      <Grid container justify="flex-end">
        <Box mt={2}>
          <BrancherButton
            onClick={controlWrapUpModal}
            variant="outlined"
            color="secondary"
            size="small"
            aria-label="wrap-up"
          >
            Wrap-Up Relationship
          </BrancherButton>
        </Box>
      </Grid>
    </Grid>
  );
};
