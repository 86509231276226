import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';

export const IntroductionMenteeStepSevenIncorrect = () => {
  const blog = 'https://media.brancher.com.au/training/introductionMentee/Brancher_blog.pdf';
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          We can help you feel more confident than that.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Get some extra tips on creating a good first meeting by reading this{' '}
        </Text>
        <BrancherLinkText
          underline="always"
          fontWeight={700}
          variant="md"
          href={blog}
          display="inline"
          target="_blank"
        >
          blog
        </BrancherLinkText>
        <Text variant="md" display="inline">
          . If you'd like extra support after reading the blog contact your Mentoring Lead.
        </Text>
      </Grid>
    </Grid>
  );
};
