import { createMuiTheme } from '@material-ui/core/styles';

const breakpoints = createMuiTheme().breakpoints;

export const MuiDrawer = {
  paperAnchorRight: {
    maxWidth: 420,
    width: 420,
    [breakpoints.down('sm')]: {
      width: 350,
    },
    padding: 20,
  },
};
