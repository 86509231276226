import * as React from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PageLayout } from '../../Components/General/PageLayout';
import { Text } from '../../Components/General/Text';
import { TileSelector } from '../../Components/General/TileSelector';
import { PrevButton } from '../../Components/InputFields/BrancherButton';
import { GetRoute } from '../../Components/Routing';
import { Colors } from '../../consts/colors';
import { IStoreTypes } from '../../store/storeTypes';
import { EditProfile } from '../Profile/EditProfile';
import { AccountSettings } from './AccountSettings/AccountSettings';

const useStyles = makeStyles({
  sideBar: {
    display: 'inline-block',
    background: Colors.backgroundLightPurple,
    maxHeight: '100vh',
    minHeight: '100vh',
    paddingTop: 60,
    paddingLeft: 20,
    paddingRight: 20,
    position: 'fixed',
    overflowY: 'auto',
    '--webkit-overflow-scrolling': 'touch',
    '--ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
  },
  heading: {
    marginBottom: 20,
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
    },
    width: '-webkit-fill-available',
  },
  settings: {
    width: '100%',
    overflowX: 'hidden',
    minHeight: 'calc(100vh - 30px)',
    marginBottom: 40,
    alignItems: 'flex-start',
  },
  selectedSettings: {
    overflowY: 'auto',
    '--webkit-overflow-scrolling': 'touch',
    '--ms-overflow-style': 'none',
    marginLeft: (props: { tablet: boolean; mobile: boolean }) =>
      props.mobile
        ? `calc((((100% / 12) * 4) + 10px))`
        : props.tablet
        ? `calc((((100% / 12) * 3) + 60px))`
        : `calc(((100% / 12) * 3) + 0px)`,
  },
});

export enum ESettings {
  PROFILE = 'profile',
  ACCOUNT = 'account',
  NOTIFICATIONS = 'notifications',
}

export const Settings = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [selected, setSelected] = React.useState<ESettings>(ESettings.PROFILE);
  const ssoLoggedIn = useSelector((state: IStoreTypes) => state.user.isSSO);
  const styles = useStyles({ mobile, tablet });

  const SettingsConfig: Array<{
    title: string;
    settingsType: ESettings;
    component: React.ReactNode;
  }> = [
    {
      title: 'Edit profile',
      settingsType: ESettings.PROFILE,
      component: <EditProfile />,
    },
  ];

  if (!ssoLoggedIn) {
    SettingsConfig.push({
      title: 'Account settings',
      settingsType: ESettings.ACCOUNT,
      component: <AccountSettings />,
    });
  }

  return (
    <Grid container className={styles.settings}>
      <Grid item xs={4} md={3} container className={styles.sideBar} alignItems="flex-start">
        <Grid item>
          <Box marginBottom={3} display="flex">
            <Link to={GetRoute('dashboard').path}>
              <PrevButton variant="text" color="primary" transparent>
                Dashboard
              </PrevButton>
            </Link>
          </Box>
        </Grid>
        <Grid
          item
          container
          justify="space-between"
          xs={12}
          alignItems="center"
          className={styles.heading}
        >
          <Grid item>
            <Text variant="lg" fontWeight={600}>
              Settings
            </Text>
          </Grid>
        </Grid>
        {SettingsConfig.map((setting, i) => (
          <div onClick={() => setSelected(setting.settingsType)} className={styles.hover} key={i}>
            <TileSelector active={selected === setting.settingsType}>
              <Text
                variant="sm"
                fontWeight={600}
                color={selected === setting.settingsType ? 'purple' : 'black'}
              >
                {setting.title}
              </Text>
            </TileSelector>
          </div>
        ))}
      </Grid>
      <Grid item xs={8} md={9} container justify="center" className={styles.selectedSettings}>
        <PageLayout
          pageTitle={SettingsConfig.find((setting) => setting.settingsType === selected).title}
        >
          {SettingsConfig.find((setting) => setting.settingsType === selected).component}
        </PageLayout>
      </Grid>
    </Grid>
  );
};
