import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import Confetti from 'react-confetti';
import { useDispatch, useSelector } from 'react-redux';
import tweenFunctions from 'tween-functions';
import { PageLayout } from '../../Components/General/PageLayout';
import { Colors } from '../../consts/colors';
import { BrancherDispatch, SetUserCelebrate, UtilGetTrainingData } from '../../store/actions';
import { IStoreTypes } from '../../store/storeTypes';
import { Subscription } from '../../types/SubscriptionTypes';
import { TrainingModules } from './TrainingModules';
import { TrainingStepper } from './TrainingStepper';

const useStyles = makeStyles({
  trainingStepper: {
    display: 'inline-block',
    maxHeight: 'calc(100vh - 222px)',
    minHeight: 'calc(100vh - 212px)',
    position: 'fixed',
    zIndex: 5,
    overflowY: 'auto',
    '--webkit-overflow-scrolling': 'touch',
    '--ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      background: Colors.backgroundDarkPurple,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: Colors.purple,
      borderRadius: 6,
      border: `1px solid transparent`,
    },
  },
  sharedNotes: {
    zIndex: 10,
  },
  training: {
    width: '100%',
    overflowX: 'hidden',
    minHeight: '100vh',
  },
});

export const Training = () => {
  const subscription = useSelector((state: IStoreTypes) => state.user.subscription);
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const trainingModules = useSelector((state: IStoreTypes) => state.training.modules)?.[sesPos];
  const trainingConfig = useSelector((state: IStoreTypes) => state.training.moduleConfig);
  const progress = useSelector((state: IStoreTypes) => state.training.progress);
  const celebrate = useSelector((state: IStoreTypes) => state.user?.celebrate);
  const chosenSubscription = subscription;
  const [hasTrainingData, setHasTrainingData] = React.useState(false);
  const dispatch = useDispatch();
  const styles = useStyles();

  React.useEffect(() => {
    if (chosenSubscription.includes(Subscription.TRAINING)) {
      BrancherDispatch(
        dispatch,
        UtilGetTrainingData(() => {
          setHasTrainingData(true);
        }),
      );
    }
  }, []);

  React.useEffect(() => {
    if (celebrate) {
      setTimeout(() => dispatch(SetUserCelebrate(false)), 3850);
    }
  }, [celebrate]);

  const moduleSteps = () => {
    const mods = trainingModules.map((mod) => {
      const module = trainingConfig[sesPos].find((a) => a.name === mod);
      const completed = progress[mod] ? progress[mod]?.completed : false;
      return {
        overdue: false,
        active: true,
        completed,
        module: mod,
        deadlineDate: '',
        deadlineYear: '',
        heading: module.heading,
      };
    });
    return mods.filter((m) => m !== undefined);
  };

  const scrollToModule = (module: string) => {
    window.scrollTo({ behavior: 'smooth', top: document.getElementById(module).offsetTop });
  };

  return (
    <PageLayout pageTitle="Training" titleDivider={false}>
      {celebrate && (
        <Confetti
          tweenFunction={() => tweenFunctions.easeInOutQuad(1, 200, 2000, 1)}
          tweenDuration={4000}
        />
      )}
      {hasTrainingData ? (
        <Grid container className={styles.training}>
          <Hidden smDown>
            <Grid item md={3} container>
              <Grid item md={2} container className={styles.trainingStepper}>
                <TrainingStepper steps={moduleSteps()} scrollToModule={scrollToModule} />
              </Grid>
            </Grid>
          </Hidden>

          <Grid item xs={12} md={9} container justify="center" className={styles.sharedNotes}>
            <TrainingModules />
          </Grid>
        </Grid>
      ) : (
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12}>
            <CircularProgress color="secondary" size={64} />
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};
