import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { AlexAvatar } from '../../avatars/Alex';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveIntroductionMentor } from '../../../../../store/actions';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const IntroductionMentorStepFour = () => {
  const stepFourValue = useSelector((state: IStoreTypes) => state.training.introductionMentor);
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepFourCorrectVal = ['0', '1'];
    dispatch(
      SaveIntroductionMentor({
        ...stepFourValue,
        StepFour: {
          selected: val,
          correct:
            StepFourCorrectVal.includes(val[0]) ||
            StepFourCorrectVal.includes(val[1]) ||
            StepFourCorrectVal.includes(val[2]),
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <AlexAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            On the way to your scheduled meeting you received a text...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          On the way to your scheduled meeting with Jordan you receive a text saying “Hi, can you
          please come back to the office, something urgent has come up”. What do you do?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You can choose multiple answers.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="You meet with Jordan but tell them that you’ll need to keep your phone on and may need to answer and leave early if things escalate."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="You call Jordan to apologise and explain the situation and proactively propose new times to meet."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="Text Jordan and advise that you'll need to reschedule."
        />
      </Grid>
      <Grid item container justify="center">
        <SelectionControl
          question=""
          name="stepFourValue"
          value={stepFourValue.StepFour?.selected}
          updateValue={updateValue}
          thirdWidth
          noQuestion
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
