import { Colors } from '../consts/colors';

export const MuiCard = {
  root: {
    background: Colors.informational,
    padding: 15,
    marginBottom: 15,
    borderRadius: 12,
    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
};
