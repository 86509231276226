import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { QuestionHeader } from './QuestionHeader';
import { SegmentedControlHeader } from './SegmentedControlHeader';
import { BrancherSlider, IBrancherSlider } from '../../Components/InputFields/BrancherSlider';
import { Text } from '../../Components/General/Text';

export interface ISliderSegment extends IBrancherSlider {
  questionNumber: string;
}

interface ISliderGroup {
  question: React.ReactNode;
  options?: ISliderSegment[];
  stage?: string;
  description?: string;
}

export const SliderGroup = (props: ISliderGroup) => {
  const { question, description, stage, options } = props;
  return (
    <>
      <Grid item xs={12} md={10}>
        <QuestionHeader stage={stage}>{question}</QuestionHeader>
      </Grid>
      <Grid item xs={12} md={10}>
        {typeof description === 'string' ? <Text variant="sm">{description}</Text> : description}
      </Grid>
      <Grid item xs={12} md={10}>
        <BrancherDivider marginBottom={20} marginTop={10} />
      </Grid>
      <Grid item xs={12} md={10} container spacing={2}>
        {options.map((o, i) => (
          <Grid item container xs={12} key={`${o.name}-${i}`}>
            <SliderSegment key={i} {...o} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const SliderSegment = (props: ISliderSegment) => {
  const { title, questionNumber, ...other } = props;
  return (
    <Grid container direction="column" spacing={1} item>
      <Grid item container>
        <SegmentedControlHeader title={title} questionNumber={questionNumber} />
      </Grid>
      <Grid item>
        <BrancherSlider {...other} />
      </Grid>
    </Grid>
  );
};
