import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { EasyEnglishMenteeStepOne } from './Steps/EasyEnglishMenteeStepOne';
import { EasyEnglishMenteeStepOneCorrect } from './Steps/EasyEnglishMenteeStepOneCorrect';
import { EasyEnglishMenteeStepTwo } from './Steps/EasyEnglishMenteeStepTwo';
import { EasyEnglishMenteeStepTwoCorrect } from './Steps/EasyEnglishMenteeStepTwoCorrect';
import { EasyEnglishMenteeStepThree } from './Steps/EasyEnglishMenteeStepThree';
import { EasyEnglishMenteeStepThreeCorrect } from './Steps/EasyEnglishMenteeStepThreeCorrect';
import { EasyEnglishMenteeStepFour } from './Steps/EasyEnglishMenteeStepFour';

export const EasyEnglishMenteeModuleMapper: IModuleMapper[] = [
  {
    name: 'EasyEnglishMenteeStepOne',
    component: <EasyEnglishMenteeStepOne />,
    iterateStepper: false,
    hasQuestion: true,
    correctComponent: 'EasyEnglishMenteeStepOneCorrect',
    incorrectComponent: 'EasyEnglishMenteeStepOneCorrect',
  },
  {
    name: 'EasyEnglishMenteeStepOneCorrect',
    component: <EasyEnglishMenteeStepOneCorrect />,
    iterateStepper: true,
    previousComponent: 'EasyEnglishMenteeStepOne',
    nextComponent: 'EasyEnglishMenteeStepTwo',
  },
  {
    name: 'EasyEnglishMenteeStepTwo',
    component: <EasyEnglishMenteeStepTwo />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'EasyEnglishMenteeStepOne',
    correctComponent: 'EasyEnglishMenteeStepTwoCorrect',
    incorrectComponent: 'EasyEnglishMenteeStepTwoCorrect',
  },
  {
    name: 'EasyEnglishMenteeStepTwoCorrect',
    component: <EasyEnglishMenteeStepTwoCorrect />,
    iterateStepper: true,
    previousComponent: 'EasyEnglishMenteeStepTwo',
    nextComponent: 'EasyEnglishMenteeStepThree',
  },
  {
    name: 'EasyEnglishMenteeStepThree',
    component: <EasyEnglishMenteeStepThree />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'EasyEnglishMenteeStepTwo',
    correctComponent: 'EasyEnglishMenteeStepThreeCorrect',
    incorrectComponent: 'EasyEnglishMenteeStepThreeCorrect',
  },
  {
    name: 'EasyEnglishMenteeStepThreeCorrect',
    component: <EasyEnglishMenteeStepThreeCorrect />,
    iterateStepper: true,
    previousComponent: 'EasyEnglishMenteeStepThree',
    nextComponent: 'EasyEnglishMenteeStepFour',
  },
  {
    name: 'EasyEnglishMenteeStepFour',
    component: <EasyEnglishMenteeStepFour />,
    iterateStepper: true,
    needsInteraction: true,
    submit: true,
    previousComponent: 'EasyEnglishMenteeStepThree',
  },
];
