import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Text } from '../../../Components/General/Text';
import { IColors } from '../../../consts/colors';
import { TextVariantValues } from '../../../types/TextTypes';

interface ITrainingSelectOptions {
  optionHeader: string;
  optionText: string;
  color?: IColors;
  fontWeight?: number;
  optionsFontWeight?: number;
  variant?: TextVariantValues;
}

export const TrainingSelectOptions = (props: ITrainingSelectOptions) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    optionHeader,
    optionText,
    color = 'black',
    fontWeight = 600,
    optionsFontWeight = 500,
    variant = 'md',
  } = props;

  return (
    <Grid container>
      <Grid item xs="auto">
        <Text
          variant={variant}
          fontWeight={fontWeight}
          marginLeft={mobile ? -15 : -35}
          color={color}
        >
          {optionHeader}
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text
          variant={variant}
          color={color}
          fontWeight={optionsFontWeight}
          marginLeft={mobile ? 8 : 0}
        >
          {optionText}
        </Text>
      </Grid>
    </Grid>
  );
};
