import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { BrancherCard } from '../../Components/General/BrancherCard';
import { Text } from '../../Components/General/Text';
import { IStoreTypes } from '../../store/storeTypes';
import { CheckMentoringPartnerRequests } from './CheckMentoringPartnerRequests';

export const RequestsSection: React.FC = () => {
  const sesPosition = useSelector((state: IStoreTypes) => state.user?.sessionPosition);
  const programId = useSelector((state: IStoreTypes) => state.user?.programId);
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const mentoringPartnerRequests: string[] = useSelector(
    (state: IStoreTypes) => state.user?.mentoringPartnerRequests,
  )
    ?.filter((a) => a.initiatorPosition !== sesPosition && a.programId === programId)
    .map((b) => b.mentoringRequestId);

  return mentoringPartnerRequests?.length > 0 ? (
    <Box mt={2}>
      <BrancherCard minHeight="150px">
        <Grid container alignItems="center">
          <CheckMentoringPartnerRequests small={false} />
        </Grid>
      </BrancherCard>
    </Box>
  ) : (
    <Box mt={2}>
      <Text variant="xs" marginBottom={24}>
        You currently have no {roleLabels.programStyle} requests to respond to.
      </Text>
    </Box>
  );
};
