import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EMoDTrainingModules } from '../../../../store/reducerTypes/TrainingReducer.types';
import { TrainingIntroduction } from '../../TrainingIntroduction';
import {
  getTrainingModuleHeading,
  getTrainingModuleNumber,
} from '../../TrainingModuleDefaultConfig';
import { IStoreTypes } from '../../../../store/storeTypes';
import { SaveMoDBeGreatMentorData } from '../../../../store/actions';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Text } from '../../../../Components/General/Text';
import { BrancherQuestionMarker } from '../../../../Components/General/BrancherQuestionMarker';
import { CompetencePopover } from '../sharedComps/CompetencePopover';
import { CompetenceScale } from '../sharedComps/CompetenceScale';
import { SegmentedControlGroup } from '../../../../Form/FormFieldTypes/SegmentedControl';
import { ConfidenceScale } from '../sharedComps/ConfidenceScale';

export const MoDBeGreatMentorSplash = () => {
  const modName = EMoDTrainingModules.MOD_BE_GREAT_MENTOR;
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const trainingModules = useSelector((state: IStoreTypes) => state.training.modules)?.[sesPos];
  const beGreatMentorBaseline = useSelector((state: IStoreTypes) => state.training?.[modName]);
  const trainingConfig = useSelector((state: IStoreTypes) => state.training.moduleConfig)?.[sesPos];
  const dispatch = useDispatch();
  const [anchorEl, setAnchor] = React.useState(null);
  const open = Boolean(anchorEl);

  const controlValidator = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!Boolean(anchorEl)) {
      setAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(anchorEl)) {
      setAnchor(null);
    }
  };

  const updateBaseline = (val: string, attr: string) => {
    dispatch(SaveMoDBeGreatMentorData({ [attr]: val }));
  };

  return (
    <TrainingIntroduction
      title={`Training #${getTrainingModuleNumber(
        modName,
        trainingModules,
        trainingConfig,
      )}: ${getTrainingModuleHeading(modName, trainingConfig)}`}
      subHeader="Welcome! This training module will provide you with the information and tools you need to make your relationship a success."
      activityLearnings={[
        'Understand the Mentoring Guide',
        'Follow the mentoring program structure',
        'Understand effective mentoring behaviours',
        'Respect and adhere to mentoring boundaries',
        'Establish a Mentoring Agreement',
        'Facilitate a great first meeting',
      ]}
      modulePath={modName}
      validatedValue={
        beGreatMentorBaseline?.competenceBaseline != null &&
        beGreatMentorBaseline?.confidenceBaseline != null
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box marginTop={3} marginBottom={3}>
            <Text variant="sm" fontWeight={700} display="inline">
              To provide us with a baseline measure, please rate your current level of{' '}
            </Text>
            <Text variant="sm" fontWeight={700} display="inline" color="purple">
              competence and confidence to perform the behaviours above:{' '}
            </Text>
            <BrancherQuestionMarker onClick={controlValidator} />
          </Box>
          <CompetencePopover
            open={open}
            setClose={closeValidator}
            anchor={anchorEl}
            labelledBy="Competence and confidence"
          />
          <Grid item>
            <Text variant="sm" fontWeight={700} marginBottom={10}>
              Please rate your competence:
            </Text>
          </Grid>
          <CompetenceScale />
          <SegmentedControlGroup
            value={beGreatMentorBaseline?.competenceBaseline}
            valueLength={7}
            updateValue={(v: string) => updateBaseline(v, 'competenceBaseline')}
            fullWidth
          />
          <Grid item>
            <Text variant="sm" fontWeight={700} marginBottom={10}>
              Please rate your confidence:
            </Text>
          </Grid>
          <ConfidenceScale />
          <SegmentedControlGroup
            value={beGreatMentorBaseline?.confidenceBaseline}
            valueLength={7}
            updateValue={(v: string) => updateBaseline(v, 'confidenceBaseline')}
            fullWidth
          />
        </Grid>
      </Grid>
    </TrainingIntroduction>
  );
};
