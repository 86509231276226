import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { GetTrainingResourceLink } from '../../../../../utils/LinkUtils';
import { BrancherList } from '../../../../../Components/General/BrancherList';

export const BeyondProgramMentorStepEleven = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentee = position === ProgramPositions.mentee;
  const partnerPosition = isMentee ? ProgramPositions.mentor : ProgramPositions.mentee;
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          {isMentee
            ? 'You now know how to transition your mentoring relationship beyond this program and have a new/adapted SMART goal to continue accelerating your career through further self-driven professional development.'
            : 'You now know how to transition your mentoring relationship beyond this program and have a new/adapted SMART goal to continue improving your mentoring and coaching skills.'}
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={500}>
          The final stage of this module involves providing your {partnerPosition} with feedback so
          they can continue to improve their mentoring style and approach.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={500}>
          Your responses to these questions will be shared with your {partnerPosition} (so they can
          reflect on this feedback).
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={500}>
          Please remember to provide feedback that is:
        </Text>
        <BrancherList
          listItems={[
            'Motivating',
            'Achievable',
            'Specific',
            'Timely',
            'Emotionally intelligent',
            'Relevant',
          ]}
        />
      </Grid>
      <Grid item container justify="center">
        <BrancherTrainingImage
          src={GetTrainingResourceLink(
            EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR,
            'card-house.png',
          )}
          alt="card-house"
        />
      </Grid>
    </Grid>
  );
};
