import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { NextButton, PrevButton } from '../InputFields/BrancherButton';
import { GetRoute } from '../Routing';
import { IPageLayout } from './PageLayout';
import { Text } from './Text';
import { BrancherDivider } from './BrancherDivider';

export const FeaturePageLayout: React.FC<IPageLayout> = ({
  pageTitle,
  titleDivider = true,
  backButtonRoute,
  backButtonLabel = 'Back',
  nextButtonLabel = 'Next',
  nextButtonRoute,
  children,
}) => {
  return (
    <>
      {(backButtonRoute || nextButtonRoute) && (
        <Grid container item justify="space-between" alignItems="center" xs={11} lg={10}>
          {backButtonRoute && (
            <Grid item>
              <Text variant="lg" color="purple" fontWeight={600} marginBottom={20}>
                <Link to={GetRoute(backButtonRoute).path}>
                  <PrevButton variant="text" color="primary">
                    {backButtonLabel}
                  </PrevButton>
                </Link>
              </Text>
            </Grid>
          )}
          {nextButtonRoute && (
            <Grid item>
              <Text variant="lg" color="purple" fontWeight={600} marginBottom={20}>
                <Link to={GetRoute(nextButtonRoute).path}>
                  <NextButton variant="text" color="primary">
                    {nextButtonLabel}
                  </NextButton>
                </Link>
              </Text>
            </Grid>
          )}
        </Grid>
      )}

      <Grid item xs={11} lg={10}>
        <Text variant="lg" color="purple" fontWeight={700} marginBottom={!titleDivider && 40}>
          {pageTitle}
        </Text>
      </Grid>
      {titleDivider && (
        <Grid item xs={11} lg={10}>
          <BrancherDivider marginBottom={40} marginTop={6} />
        </Grid>
      )}
      <Grid container item xs={11} lg={10} justify="center">
        {children}
      </Grid>
    </>
  );
};
