import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { Text } from '../../Components/General/Text';
import { GetRoute } from '../../Components/Routing';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { UtilUpdatePassword } from '../../store/actions';
import { PasswordValidation } from '../../Components/General/PasswordValidation';
import { simpleValidatePassword } from '../../utils/validators/validatePassword';
import { BackSplashImagery } from '../Login/BackSplashImagery';
import { IStoreTypes } from '../../store/storeTypes';
import { BrancherDottedDivider } from '../../Components/General/BrancherDivider';
import { BrancherRouterLink } from '../../Components/General/BrancherRouterLink';

export const VerifyForgotPassword = () => {
  const forgotPasswordEmail = useSelector((state: IStoreTypes) => state.user?.forgotPasswordEmail);
  const passwordPolicy = useSelector((state: IStoreTypes) => state.user?.passwordPolicy);
  const [newPassword, setNewPassword] = React.useState('');
  const [verification, setVerification] = React.useState('');
  const [waiting, updateWaiting] = React.useState(false);
  const [updated, hasUpdated] = React.useState(false);
  const [validatorAnchor, setValidatorAnchor] = React.useState(null);
  const [fieldErrors, setFieldErrors] = React.useState([]);
  const [apiError, setApiError] = React.useState('');
  const dispatch = useDispatch();

  const updatePassword = () => {
    const errors = validateForgotPasswordFields();
    if (errors.length === 0) {
      updateWaiting(true);
      if (fieldErrors.length > 0) {
        setFieldErrors([]);
      }
      dispatch(
        UtilUpdatePassword(forgotPasswordEmail, newPassword, verification, (response) => {
          if (response.success) {
            hasUpdated(true);
          } else {
            updateWaiting(false);
            setApiError(response.message);
          }
        }),
      );
    } else {
      setFieldErrors(errors);
    }
  };

  const controlValidator = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!Boolean(validatorAnchor)) {
      setValidatorAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(validatorAnchor)) {
      setValidatorAnchor(null);
    }
  };

  // Checks that all the values are filled
  const validateForgotPasswordFields = (): string[] => {
    const errors: string[] = [];
    if (verification === '') {
      errors.push('verification');
    }
    if (newPassword === '' || !simpleValidatePassword(newPassword, passwordPolicy)) {
      setValidatorAnchor(document.getElementById('password'));
      errors.push('newPassword');
    }
    return errors;
  };

  return (
    <BackSplashImagery>
      {!forgotPasswordEmail && <Redirect to={GetRoute('forgotPassword').path} />}
      <Grid container direction="row" alignItems="center" justify="center" onClick={closeValidator}>
        <Grid item xs={12}>
          <Grid container direction="column" justify="center">
            <Text variant="xl" fontWeight={600}>
              Enter verification code
            </Text>
            <BrancherDottedDivider marginTop={40} marginBottom={40} />
            <Text variant="sm" marginBottom={10}>
              Please check your email for the verification code
            </Text>
            {apiError && (
              <Text variant="xs" color="red" marginTop={15} marginBottom={15}>
                {apiError}
              </Text>
            )}
            <Grid item>
              <BrancherTextField
                autoComplete="off"
                name="verification"
                value={verification}
                updateValue={setVerification}
                placeholder="Verification Code"
                label="Verification Code"
                error={fieldErrors.includes('verification')}
                helpText={
                  fieldErrors.includes('verification') &&
                  'Please enter the verification code sent to your email!'
                }
                fullWidth
              />
            </Grid>
            <Grid item>
              <BrancherTextField
                autoComplete="off"
                name="password"
                type="password"
                id="password"
                value={newPassword}
                updateValue={setNewPassword}
                placeholder="Your New Password"
                label="New password"
                onClick={controlValidator}
                error={fieldErrors.includes('newPassword')}
                helpText={
                  fieldErrors.includes('newPassword') && 'Please enter a stronger password!'
                }
                fullWidth
              />
            </Grid>
            <PasswordValidation
              anchorEl={validatorAnchor}
              password={newPassword}
              passwordValidationRules={passwordPolicy}
            />
            <Box marginBottom={4} />
            <BrancherButton color="primary" onClick={updatePassword}>
              {waiting && !updated ? (
                <CircularProgress color="primary" size={24} />
              ) : updated ? (
                <Redirect to={GetRoute('login').path} />
              ) : (
                'Update password'
              )}
            </BrancherButton>
            <Box marginTop={4} />
            <Box display="inline" textAlign="center">
              <Text variant="sm" display="inline">
                Didn't get a code? Please wait a few moments, check your spam folder, or get a
              </Text>
              <BrancherRouterLink to={GetRoute('forgotPassword').path}>
                <Text
                  variant="sm"
                  color="purple"
                  display="inline"
                  textDecoration="underline"
                  marginLeft={6}
                >
                  new code sent
                </Text>
              </BrancherRouterLink>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </BackSplashImagery>
  );
};
