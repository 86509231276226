import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GetIllustrationLink, GetLinkBase } from '../../utils/LinkUtils';

interface IProfilePicture {
  src?: string;
  alt?: string;
  mini?: boolean;
}

const useStyles = makeStyles({
  profilePic: {
    maxWidth: (props: { mobile: boolean; tablet: boolean; mini: boolean }) =>
      props.mini ? 50 : props.mobile ? 40 : props.tablet ? 55 : 70,
    minWidth: (props: { mobile: boolean; tablet: boolean; mini: boolean }) =>
      props.mini ? 50 : props.mobile ? 40 : props.tablet ? 55 : 70,
    maxHeight: (props: { mobile: boolean; tablet: boolean; mini: boolean }) =>
      props.mini ? 50 : props.mobile ? 40 : props.tablet ? 55 : 70,
    minHeight: (props: { mobile: boolean; tablet: boolean; mini: boolean }) =>
      props.mini ? 50 : props.mobile ? 40 : props.tablet ? 55 : 70,
    borderRadius: '50%',
  },
});

export const ProfilePicture: React.FC<IProfilePicture> = ({
  src,
  alt = 'profile-avatar',
  mini,
}) => {
  const styles = useStyles({ mini });
  return (
    <img
      src={src ? `${GetLinkBase()}${src}` : GetIllustrationLink('noAvatar.png')}
      className={styles.profilePic}
      alt={alt}
    />
  );
};
