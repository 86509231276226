import InputLabel from '@material-ui/core/InputLabel';
import * as React from 'react';
import { IText, Text } from '../General/Text';

interface IBrancherInputLabel extends IText {
  for: string;
  ariaLabel?: string;
}

export const BrancherInputLabel: React.FC<IBrancherInputLabel> = (props) => {
  const { children, for: For, ariaLabel, ...other } = props;
  return (
    <InputLabel htmlFor={For} aria-label={ariaLabel}>
      <Text {...other}>{children}</Text>
    </InputLabel>
  );
};
