import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { EContentType, IPrivateNote } from '../../../../../store/reducers/PrivateNotesReducer';
import { SuggestionCard } from '../../SuggestionCard';
import { IStoreTypes } from '../../../../../store/storeTypes';
import {
  BrancherDispatch,
  SaveBeyondProgramMenteeData,
  SaveBeyondProgramMentorData,
  UtilSaveUserSharedNotesData,
} from '../../../../../store/actions';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { SaveButton } from '../../../../../Components/InputFields/BrancherButton';
import { FormWithStepFieldLayout } from '../../../../../Components/InputFields/FormWithStepFieldLayout';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const BeyondProgramMentorStepTwelve = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentee = position === ProgramPositions.mentee;
  const sessionStore = isMentee ? 'beyondProgramMentee' : 'beyondProgramMentor';
  const sessionSaveFunction = isMentee ? SaveBeyondProgramMenteeData : SaveBeyondProgramMentorData;
  const StepTwelveValue = useSelector((state: IStoreTypes) => state.training[sessionStore]);
  const dispatch = useDispatch();

  const updateValue = (attributeName: string, val: string | boolean) => {
    const newStepTwelveVals = { ...StepTwelveValue.StepTwelve, [attributeName]: val };
    dispatch(
      sessionSaveFunction({
        StepTwelve: {
          ...StepTwelveValue?.StepTwelve,
          [attributeName]: val,
          interacted: Boolean(
            newStepTwelveVals?.enjoyMost &&
              newStepTwelveVals?.improveOn &&
              newStepTwelveVals?.savedNote,
          ),
        },
      }),
    );
  };

  const saveNote = () => {
    const noteData: IPrivateNote = {
      contents: [
        {
          content: [
            StepTwelveValue?.StepTwelve?.enjoyMost,
            StepTwelveValue?.StepTwelve?.improveOn,
          ] as string[] & string,
          type: EContentType.LIST,
        },
      ],
      title: 'Beyond the program - Feedback',
      lastModified: Date.now(),
    };
    BrancherDispatch(
      dispatch,
      UtilSaveUserSharedNotesData(noteData, () => {
        updateValue('savedNote', true);
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <FormWithStepFieldLayout
          step={1}
          question={
            isMentee
              ? 'What did you enjoy most about your mentor?'
              : 'What did your mentee do well in this program and what are their strengths?'
          }
        >
          <BrancherTextField
            value={StepTwelveValue?.StepTwelve?.enjoyMost}
            updateValue={(textValue: string) => updateValue('enjoyMost', textValue)}
            maxChars={400}
            placeholder="Enter here"
            multiline
            fullWidth
          />
        </FormWithStepFieldLayout>
      </Grid>
      <Grid item>
        <FormWithStepFieldLayout
          step={2}
          question={
            isMentee
              ? 'What could your mentor further improve?'
              : 'What could your mentee further improve?'
          }
        >
          <BrancherTextField
            value={StepTwelveValue?.StepTwelve?.improveOn}
            updateValue={(textValue: string) => updateValue('improveOn', textValue)}
            maxChars={400}
            placeholder="Enter here"
            multiline
            fullWidth
          />
        </FormWithStepFieldLayout>
      </Grid>
      <Grid item>
        <SuggestionCard>
          Please share this with your {isMentee ? 'mentor' : 'mentee'} by clicking the 'Share'
          button below (so they can reflect on this feedback).
        </SuggestionCard>
      </Grid>
      <Grid item>
        <SaveButton
          onClick={saveNote}
          disabled={
            StepTwelveValue?.StepTwelve?.savedNote ||
            !(StepTwelveValue?.StepTwelve?.enjoyMost && StepTwelveValue?.StepTwelve?.improveOn)
          }
        >
          Share
        </SaveButton>
      </Grid>
    </Grid>
  );
};
