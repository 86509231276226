import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';
import { SuggestionCard } from '../../SuggestionCard';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const BeyondProgramMentorStepTwo = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentee = position === ProgramPositions.mentee;
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          It’s important to start thinking about whether you want to continue the relationship you
          have built with your {isMentee ? 'mentor' : 'mentee'}. The following questions may help
          you in determining whether continuing the mentoring relationship is right for you.
        </Text>
      </Grid>
      <Grid item container justify="center">
        <BrancherTrainingImage
          src="https://media.brancher.com.au/training/beyondProgramMentor/plan.png"
          alt="plan"
        />
      </Grid>
      <Grid item>
        <SuggestionCard>
          These questions are confidential and will not be shared with your{' '}
          {isMentee ? 'mentor' : 'mentee'}.
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
