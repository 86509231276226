import * as React from 'react';
import LockOpen from '@material-ui/icons/LockOpen';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { SaveUserInfo, UtilSSOSAMLLogin, UtilSSOValidateSAML } from '../../store/actions';
import { IUtilLoginUserResponse } from '../../store/actions/UserInfoActions';
import { ESessionAttributes, setSessionAttribute } from '../../utils/cookies';

interface ISAMLSignIn {
  setApiError: (res: string) => void;
  email: string;
  programId: string;
  controlSSOUser: (res: IUtilLoginUserResponse) => void;
}

export const SAMLSignIn: React.FC<ISAMLSignIn> = ({
  setApiError,
  email,
  controlSSOUser,
  programId,
}) => {
  const locationHash = useLocation()?.hash?.substring(1);
  const params = new URLSearchParams(locationHash);
  const [gettingRedirectUri, setGettingRedirectUri] = React.useState<boolean>(false);
  const idToken = params?.get('id_token');
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!!idToken) {
      const originState = params?.get('state');
      const redirect = originState !== window.location.origin;
      if (redirect) {
        window.location.replace(`${originState}${window.location.pathname}#${locationHash}`);
      } else {
        dispatch(
          UtilSSOSAMLLogin(idToken, programId, (res) => {
            dispatch(SaveUserInfo({ IDToken: idToken }));
            controlSSOUser(res);
          }),
        );
      }
    }
  }, [idToken]);

  React.useEffect(() => {
    if (email && !idToken) {
      getSSORedirectUri();
    }
  }, []);

  const getSSORedirectUri = () => {
    setGettingRedirectUri(true);
    dispatch(
      UtilSSOValidateSAML(email, (samlRes) => {
        setGettingRedirectUri(false);
        if (samlRes.success) {
          setSessionAttribute(ESessionAttributes.HAS_SAML, 'true');
          setSessionAttribute(ESessionAttributes.USER_EMAIL, email);
          window.location.assign(samlRes.data?.redirectUri);
        } else if (!!samlRes?.message) {
          setSessionAttribute(ESessionAttributes.HAS_SAML, 'false');
          setApiError(samlRes?.message);
        }
      }),
    );
  };

  return (
    <BrancherButton
      startIcon={<LockOpen />}
      onClick={getSSORedirectUri}
      disabled={!email || gettingRedirectUri || !email?.includes('@')}
    >
      Single sign-on
    </BrancherButton>
  );
};
