import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';

export const MakeTheMostMenteeStepThree = () => {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md">
          Now that you have met with your mentor, it is time to start thinking about what you want
          to achieve in this program.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Why is goal setting important?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" marginBottom={30}>
          Goal setting isn’t just a trend you see in the media. Evidence has shown goal setting
          increases career satisfaction and enhances career success through promotions and salary
          increases.
        </Text>
      </Grid>
      <Grid item container justify="center">
        <img
          src="https://media.brancher.com.au/training/makeTheMostMentee/improve.png"
          width="60%"
          height="60%"
          alt="improve"
        />
      </Grid>
    </Grid>
  );
};
