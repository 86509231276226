import * as React from 'react';
import { Switch } from 'react-router';
import { routeConfig } from './RouteConfig';
import { RouteWithSubRoutes } from './RoutesWithSubRoutes';

export function Routes() {
  return (
    <Switch>
      {routeConfig.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  );
}
