import * as React from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { IText, Text } from '../../../Components/General/Text';
import { Colors } from '../../../consts/colors';

const useStyles = makeStyles({
  card: {
    '&.MuiCard-root': {
      padding: (props: { mobile: boolean }) => (props.mobile ? 20 : 30),
      background: (props: { success: boolean; incorrect: boolean; neutral: boolean }) =>
        props.success
          ? Colors.correct
          : props.incorrect
          ? Colors.incorrect
          : props.neutral
          ? Colors.backgroundGrey
          : Colors.informational,
    },
  },
});

interface ISuggestionCard {
  children: React.ReactNode;
  icon?: React.ReactNode;
  light?: boolean;
  custom?: boolean;
  success?: boolean;
  incorrect?: boolean;
  neutral?: boolean;
  textColor?: IText['color'];
  align?: 'center' | 'left' | 'right';
}

export const SuggestionCard = (props: ISuggestionCard) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const {
    children,
    light,
    custom,
    success,
    incorrect,
    neutral,
    icon,
    textColor,
    align = 'center',
  } = props;
  const styles = useStyles({ success, incorrect, neutral, mobile });

  return (
    <Card className={styles.card}>
      <Grid container item justify="center" alignItems="center">
        {icon && <Box mr={1}>{icon}</Box>}
        {custom ? (
          children
        ) : (
          <Text
            variant="sm"
            fontWeight={light ? 400 : 600}
            color={light ? 'black' : textColor ?? 'purple'}
            align={align}
          >
            {children}
          </Text>
        )}
      </Grid>
    </Card>
  );
};
