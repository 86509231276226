import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { SaveBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { SuggestionCard } from '../../SuggestionCard';

export const BeGreatMentorStepSix = () => {
  const dispatch = useDispatch();

  const updateValue = () => {
    dispatch(
      SaveBeGreatMentorData({
        StepSix: {
          interacted: true,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md">
          Taking a coaching approach to mentoring empowers your mentee to find real-life solutions
          to real-life problems.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          In its simplest form, coaching involves asking the right questions to empower your mentee
          to come to their own solution.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          So how do you know what questions to ask?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          It can help to think about a coaching 'model' during your mentoring conversations. In this
          module we'll share with you the GROWTH coaching model that you can add to your 'toolkit':
        </Text>
      </Grid>
      <Grid item container justify="center" xs={10} sm={11}>
        <BrancherTrainingImage
          src="https://media.brancher.com.au/training/beGreatMentor/growth_graphic.png"
          alt="growth"
        />
      </Grid>
      <Grid item>
        <BrancherLinkText
          variant="md"
          target="_blank"
          onClick={updateValue}
          underline="always"
          fontWeight={700}
          href="https://media.brancher.com.au/training/beGreatMentor/GROWTH_framework.pdf"
        >
          Click here to learn more about the GROWTH model.
        </BrancherLinkText>
      </Grid>
      <Grid item>
        <Text variant="md">
          The GROWTH model helps us to coach mentees to clarify and prioritise their goals, reflect
          and learn from their experiences, develop insight from their problems, and tactically plan
          and problem solve.
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard>The next few pages will show the GROWTH model in action.</SuggestionCard>
      </Grid>
    </Grid>
  );
};
