import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { AlexAvatar } from '../../avatars/Alex';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveIntroductionMentor } from '../../../../../store/actions';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const IntroductionMentorStepTwo = () => {
  const stepTwoValue = useSelector((state: IStoreTypes) => state.training.introductionMentor);
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepTwoCorrectVal = '1';
    dispatch(
      SaveIntroductionMentor({
        ...stepTwoValue,
        StepTwo: {
          selected: val,
          correct: val === StepTwoCorrectVal,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <AlexAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            You called Jordan...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          You called Jordan and there was a legitimate reason for why they had not scheduled a catch
          up. You’ve met a few times but you’re finding that you’re setting the meeting agenda and
          directing the conversation. What should you do?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Choose one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="Directly explain to Jordan that setting the meeting agenda is the mentee’s responsibility. Tell Jordan to have a go at creating the meeting agendas going forward."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="Take a coaching approach and ask Jordan some questions to unpack why they may not be setting the meeting agendas."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="Continue the way you are. Jordan is not the proactive type so it's easier for you to continue setting the agenda."
        />
      </Grid>
      <Grid item container justify="center">
        <SelectionControl
          question=""
          name="stepTwoValue"
          value={stepTwoValue.StepTwo?.selected}
          updateValue={updateValue}
          thirdWidth
          noQuestion
          exclusive
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
