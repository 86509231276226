import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-cropper';
import { CreateButton } from './BrancherButton';
import { Colors } from '../../consts/colors';
import { Text } from '../General/Text';
import { BrancherInputLabel } from './BrancherInputLabel';
import 'cropperjs/dist/cropper.css';

interface IBrancherFileUpload {
  value: string;
  name: string;
  updateValue: (e: string | string[] | number | number[]) => void;
  required?: boolean;
}

const useStyles = makeStyles({
  img: {
    width: '100%',
  },
  imgBox: {
    display: 'flex',
    alignSelf: 'flex-end',
    padding: '22px 14px',
    borderRadius: 10,
    width: 120,
    height: 120,
    border: `3px solid ${Colors.purple}`,
    boxShadow: '0px 5px 8px -3px rgba(0,0,0,0.14)',
  },
  acceptedFile: {
    maxWidth: 120,
    maxHeight: 120,
    borderRadius: '50%',
  },
});

export const BrancherFileUpload = (props: IBrancherFileUpload) => {
  const styles = useStyles();
  const [uploadedImage, setUploadedImage] = React.useState('');
  const [fileSizeError, setFileSizeError] = React.useState(false);
  const [fileTypeError, setFileTypeError] = React.useState(false);
  const [croppedImage, setCroppedImage] = React.useState('');
  const { updateValue, value } = props;

  const acceptedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];

  const onDropAccepted = (files: any[]) => {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const actualFileData = event.target.result.toString();
      setUploadedImage(actualFileData);
      updateValue(actualFileData);
      setCroppedImage(actualFileData);
      if (fileSizeError) {
        setFileSizeError(false);
      }
      if (fileTypeError) {
        setFileTypeError(false);
      }
    };
    reader.readAsDataURL(file);
    setIsCropping(true);
  };

  const onDropRejected = (files: Array<{ errors: Array<{ code: string }> }>) => {
    files[0].errors.forEach((err) => {
      if (err.code === 'file-invalid-type') {
        setFileTypeError(true);
      } else if (err.code === 'file-too-large') {
        setFileSizeError(true);
      }
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptedFileTypes,
    onDropAccepted,
    onDropRejected,
    maxSize: 1024000,
  });

  const cropperRef = React.useRef<HTMLImageElement>(null);
  const [isCropping, setIsCropping] = React.useState<boolean>(false);

  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    setCroppedImage(cropper.getCroppedCanvas().toDataURL());
  };

  const saveCrop = () => {
    setUploadedImage(croppedImage);
    updateValue(croppedImage);
    setIsCropping(false);
  };

  return (
    <>
      {isCropping && (
        <Grid container justify="center">
          <Cropper
            style={{ height: 200, width: '100%' }}
            src={uploadedImage}
            crop={onCrop}
            aspectRatio={1}
            ref={cropperRef}
          />
          <Box mt={3} mb={3}>
            <CreateButton onClick={saveCrop} aria-label="finish-crop">
              Finish cropping
            </CreateButton>
          </Box>
        </Grid>
      )}
      <div {...getRootProps()}>
        <input {...getInputProps()} multiple={false} />
        {isDragActive ? (
          <BrancherInputLabel variant="lg" for="label">
            Drop files here...
          </BrancherInputLabel>
        ) : (
          <Grid container alignItems="center">
            {uploadedImage && !isCropping ? (
              <Box display="inline" marginRight={3}>
                <img src={uploadedImage} className={styles.acceptedFile} alt="profile-pic" />
              </Box>
            ) : value ? (
              <Box display="inline" marginRight={3}>
                <img
                  src={`https://media.brancher.com.au/${value}`}
                  className={styles.acceptedFile}
                  alt="profile-pic"
                />
              </Box>
            ) : null}
            <Box className={styles.imgBox} display="inline-block" marginLeft={uploadedImage && 4}>
              <img
                src={`https://media.brancher.com.au/shared/upload.svg`}
                alt="upload"
                className={styles.img}
              />
            </Box>
          </Grid>
        )}
        {fileSizeError && (
          <Text variant="sm" color="red" key="file-size-error">
            File size must be under 1MB
          </Text>
        )}
        {fileTypeError && (
          <Text variant="sm" color="red" key="file-type-error">
            File type must be either a JPG, JPEG or PNG
          </Text>
        )}
      </div>
    </>
  );
};
