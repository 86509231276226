import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { AlexAvatar } from '../../avatars/Alex';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveIntroductionMentor } from '../../../../../store/actions';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const IntroductionMentorStepThree = () => {
  const stepThreeValue = useSelector((state: IStoreTypes) => state.training.introductionMentor);
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepThreeCorrectVal = '1';
    dispatch(
      SaveIntroductionMentor({
        ...stepThreeValue,
        StepThree: {
          selected: val,
          correct: val === StepThreeCorrectVal,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <AlexAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Jordan is not quite what you were expecting.
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          As far as an ideal mentee goes, Jordan wasn’t quite what you were expecting. Jordan’s
          career goals don't seem to align to your experience and you have very different
          personalities. You’re wondering how you can help them. What do you do?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Choose one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="Contact your Mentoring Lead to discuss options."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="Meet with Jordan and get to know each other (first as people and then as professionals). Tell Jordan what you think you can contribute and ask Jordan what they would like to achieve."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="Meet with Jordan and talk to them about your concerns. Ask them what they are hoping to achieve from the mentoring program."
        />
      </Grid>
      <Grid item container justify="center">
        <SelectionControl
          question=""
          name="stepThreeValue"
          value={stepThreeValue.StepThree?.selected}
          updateValue={updateValue}
          thirdWidth
          noQuestion
          exclusive
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
