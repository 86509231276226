import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveSurveyOneData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const SurveyStepThreeHaveNotMet = () => {
  const surveyOneValue = useSelector(
    (state: IStoreTypes) => state.training?.[EStructuredTrainingModules.SURVEY_ONE],
  );
  const dispatch = useDispatch();

  const updateValue = (planToMeetValue: string) => {
    dispatch(
      SaveSurveyOneData({
        StepThree: {
          interacted: !!planToMeetValue,
          planToMeet: planToMeetValue,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={600}>
          When do you plan on meeting with your mentoring partner?
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          noQuestion
          name="planToMeet"
          value={surveyOneValue?.StepThree?.planToMeet}
          updateValue={updateValue}
          exclusive
          options={MakeOptions([
            'This week',
            'Within the next fortnight',
            'Within the month',
            'Never',
            'Unsure',
          ])}
        />
      </Grid>
    </Grid>
  );
};
