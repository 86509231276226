import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { AlexAvatar } from '../../avatars/Alex';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveIntroductionMentee } from '../../../../../store/actions';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const IntroductionMenteeStepSix = () => {
  const StepSixValue = useSelector((state: IStoreTypes) => state.training.introductionMentee);
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepSixCorrectVals = ['0', '1', '2', '3'];
    dispatch(
      SaveIntroductionMentee({
        ...StepSixValue,
        StepSix: {
          selected: val,
          correct:
            StepSixCorrectVals.includes(val[0]) &&
            StepSixCorrectVals.includes(val[1]) &&
            StepSixCorrectVals.includes(val[2]) &&
            StepSixCorrectVals.includes(val[3]),
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <AlexAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Your first meeting with Alex is coming up, what do you do?
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You can choose more than one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions optionHeader="A." optionText="Review the Mentoring Guide." />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="If you haven't already, start thinking about what you want to get out of the mentoring program, and from your mentor."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="Be on time – first impressions count."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="D."
          optionText="Plan your agenda for your first meeting. Share the planned agenda with your mentor ahead of time."
        />
      </Grid>
      <Grid item container justify="center">
        <SelectionControl
          question=""
          noQuestion
          name="StepSixValue"
          value={StepSixValue.StepSix?.selected}
          updateValue={updateValue}
          thirdWidth
          options={MakeOptions(['A', 'B', 'C', 'D'])}
        />
      </Grid>
    </Grid>
  );
};
