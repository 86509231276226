import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { GetRoute } from '../../../../../Components/Routing';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { BrancherList } from '../../../../../Components/General/BrancherList';

export const BeyondProgramMenteeStepSixContinueCorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="sm" display="inline">
          If you and your mentor decide you'd like to continue meeting, ensure you refresh your{' '}
        </Text>
        <BrancherLinkText
          variant="sm"
          underline="always"
          fontWeight={600}
          display="inline"
          target="_blank"
          href={GetRoute('mentoringAgreement').path}
        >
          Mentoring Agreement
        </BrancherLinkText>
        <Text variant="sm" display="inline">
          ?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="sm" fontWeight={600}>
          Discuss these points with your mentor:
        </Text>
      </Grid>
      <Grid item>
        <BrancherList
          listItems={[
            'How often would you meet and for what purpose?',
            'Who would reach out and arrange these meetings?',
            'What are your expectations of each other?',
            'What boundaries do you both have?',
            'How will you both treat confidentiality?',
          ]}
        />
      </Grid>
    </Grid>
  );
};
