import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveSurveyOneData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { ProgramLeadContact } from '../../../../Support/ProgramLeadContactLink';

export const SurveyStepFiveHaveMet = () => {
  const surveyOneValue = useSelector(
    (state: IStoreTypes) => state.training?.[EStructuredTrainingModules.SURVEY_ONE],
  );
  const userEmail = useSelector((state: IStoreTypes) => state.user.email);
  const dispatch = useDispatch();

  const updateValue = (informLeadValue: string) => {
    dispatch(
      SaveSurveyOneData({
        ...surveyOneValue,
        leadSupportRequired: informLeadValue === '0',
        email: informLeadValue === '0' ? userEmail : '',
        StepFive: { interacted: !!informLeadValue, informLead: informLeadValue },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={600}>
          Would you like further support from your Mentoring Lead?
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          noQuestion
          name="informLead"
          value={surveyOneValue?.StepFive?.informLead}
          updateValue={(a: string) => updateValue(a)}
          exclusive
          halfWidth
          options={MakeOptions(['Yes', 'No'])}
        />
      </Grid>
      <Grid item>
        <Text variant="sm" display="inline" color="purple">
          If yes, your survey responses will no longer be anonymous.
        </Text>
        <Text variant="sm" display="inline">
          {' '}
          Alternatively, you can reach out to{' '}
        </Text>
        <ProgramLeadContact />
        <Text variant="sm" display="inline">
          {' '}
          directly.
        </Text>
      </Grid>
    </Grid>
  );
};
