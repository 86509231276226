import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { isPast, isToday } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../Components/General/Text';
import { Colors } from '../../consts/colors';
import { BrancherDispatch, UtilGetFocusAreas, UtilGetUserMeetings } from '../../store/actions';
import { IMeetingData } from '../../store/reducers/meetingsReducer';
import { IStoreTypes } from '../../store/storeTypes';
import { MeetingTile } from '../Meetings/MeetingTile';
import { MeetingMinutes } from '../Meetings/MeetingMinutes';

const useStyles = makeStyles({
  meeting: {
    height: 'fit-content',
    maxHeight: 'fit-content',
    minHeight: 'fit-content',
    marginBottom: 25,
  },
  scrollable: {
    paddingLeft: 5,
    paddingRight: 5,
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: `calc(100vh - 300px)`,
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      background: Colors.backgroundDarkPurple,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: Colors.purple,
      borderRadius: 6,
      border: `2px solid transparent`,
    },
  },
});

export const MeetingsSection: React.FC = () => {
  const meetings = useSelector((state: IStoreTypes) => state.meetings?.meetings);
  const [hasMeetingData, setHasMeetingData] = React.useState<boolean>(false);
  const [openMeetingMinutes, setOpenMeetingMinutes] = React.useState<boolean>(false);
  const [hasUpdatedMinutes, setHasUpdatedMinutes] = React.useState<boolean>(false);
  const hasActiveGroup = useSelector((state: IStoreTypes) => state.user?.groups)?.length > 0;
  const hasMentoringPartner = !!useSelector(
    (state: IStoreTypes) => state.user?.sessionPair?.roleId,
  );
  const onlyInGroup = hasActiveGroup && !hasMentoringPartner;
  const dispatch = useDispatch();
  const styles = useStyles();

  const getAllMeetings = () => {
    setHasMeetingData(false);
    BrancherDispatch(
      dispatch,
      UtilGetUserMeetings(() => {
        setHasMeetingData(true);
      }),
    );
  };

  React.useEffect(() => {
    getAllMeetings();
    BrancherDispatch(
      dispatch,
      UtilGetFocusAreas(() => {}),
    );
  }, []);

  const sortMeetings = (sortingMeetings: IMeetingData[], asc: boolean = true): IMeetingData[] => {
    if (asc) {
      return sortingMeetings.sort((a: IMeetingData, b: IMeetingData) => {
        return a.datetimeStart > b.datetimeStart ? 1 : b.datetimeStart > a.datetimeStart ? -1 : 0;
      });
    } else {
      return sortingMeetings.sort((a: IMeetingData, b: IMeetingData) => {
        return a.datetimeStart < b.datetimeStart ? 1 : b.datetimeStart < a.datetimeStart ? -1 : 0;
      });
    }
  };

  let futureMeetings: IMeetingData[] = [];
  let meetingIsToday: IMeetingData[] = [];
  if (hasMeetingData) {
    meetingIsToday.push(
      ...meetings?.filter(
        (m) => isToday(new Date(m.datetimeStart)) && !isPast(new Date(m.datetimeEnd)),
      ),
    );
    if (meetingIsToday?.length > 0 && !hasUpdatedMinutes && !openMeetingMinutes) {
      setOpenMeetingMinutes(true);
    } else {
      meetings.forEach((meet) => {
        const meetingTime = new Date(meet.datetimeEnd);
        if (!isPast(meetingTime)) {
          futureMeetings.push(meet);
        }
      });
    }
  }

  return (
    <Grid container className={styles.scrollable}>
      {hasMeetingData && openMeetingMinutes ? (
        <MeetingMinutes
          meetingData={meetingIsToday[0]}
          open={openMeetingMinutes}
          setClose={() => {
            setOpenMeetingMinutes(false);
            setHasUpdatedMinutes(true);
          }}
        />
      ) : hasMeetingData ? (
        futureMeetings.length > 0 ? (
          <Grid item xs={12}>
            {sortMeetings(futureMeetings)
              .slice(0, 4)
              .map((meeting) => (
                <MeetingTile key={meeting.meetingId} fullMeetingData={meeting} mini={true} />
              ))}
          </Grid>
        ) : (
          <Text variant="xs" fontWeight={500} marginTop={30} marginBottom={20}>
            {onlyInGroup
              ? `You have no future meetings`
              : `You have no future meetings, make one by clicking the 'Schedule a Meeting' button above!`}
          </Text>
        )
      ) : (
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item xs={5}>
            <CircularProgress color="secondary" size={64} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
