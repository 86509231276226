import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { SallyAvatar } from '../../avatars/Sally';

export const BeGreatMenteeStepSixCorrect = () => {
  const stepSixValue = useSelector((state: IStoreTypes) => state.training.beGreatMentee.StepSix);
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepSixValue.correct} incorrect={!stepSixValue.correct}>
          <Text variant="md" fontWeight={700}>
            {stepSixValue.correct ? 'Correct!' : 'Not quite.'}
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <SallyAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          {stepSixValue.correct ? (
            <Text variant="sm">
              This is displaying a growth mindset as you believe that your manager's behaviour can
              change. This is also a good example of a specific yet achievable goal.
            </Text>
          ) : stepSixValue.selected === '1' ? (
            <Text variant="sm">
              Whilst this is displaying a growth mindset, this is not an achievable or realistic ask
              of your manager.
            </Text>
          ) : (
            <Text variant="sm">
              Asking for a new manager is an example of displaying a fixed mindset rather than a
              growth mindset. With this response, you are indicating you believe your manager can't
              change.
            </Text>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
