import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import { SaveMakeTheMostMentee, SaveMakeTheMostMentor } from '../../../../../store/actions';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { SuggestionCard } from '../../SuggestionCard';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { CompetenceScale } from '../../sharedComps/CompetenceScale';
import { ConfidenceScale } from '../../sharedComps/ConfidenceScale';
import { NPSScale } from '../../sharedComps/NPSScale';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { BrancherDialog } from '../../../../../Components/General/BrancherDialog';

export const MakeTheMostMenteeStepThirteen = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const sessionStore =
    position === ProgramPositions.mentee ? 'makeTheMostMentee' : 'makeTheMostMentor';
  const StepThirteenValue = useSelector((state: IStoreTypes) => state.training[sessionStore]);
  const isMentee = position === ProgramPositions.mentee;
  const sessionSaveFunction = isMentee ? SaveMakeTheMostMentee : SaveMakeTheMostMentor;
  const stepNumber = isMentee ? 'StepThirteen' : 'StepTwelve';
  const dispatch = useDispatch();
  const [openDialog, setDialog] = React.useState(false);

  const updateCompetence = (val: number) => {
    dispatch(
      sessionSaveFunction({
        [stepNumber]: {
          ...StepThirteenValue[stepNumber],
          competenceFinal: val,
          interacted:
            StepThirteenValue?.rating != null &&
            StepThirteenValue?.confidenceFinal != null &&
            val != null,
        },
        competenceFinal: val,
      }),
    );
  };

  const updateConfidence = (val: number) => {
    dispatch(
      sessionSaveFunction({
        [stepNumber]: {
          ...StepThirteenValue[stepNumber],
          confidenceFinal: val,
          interacted:
            StepThirteenValue?.rating != null &&
            StepThirteenValue?.competenceFinal != null &&
            val != null,
        },
        confidenceFinal: val,
      }),
    );
  };

  const updateRating = (val: number) => {
    dispatch(
      sessionSaveFunction({
        [stepNumber]: {
          ...StepThirteenValue[stepNumber],
          interacted:
            StepThirteenValue?.competenceFinal != null &&
            StepThirteenValue?.confidenceFinal != null &&
            val != null,
        },
        rating: val,
      }),
    );
  };

  const updateFeedback = (val: string) => {
    dispatch(
      sessionSaveFunction({
        [stepNumber]: {
          ...StepThirteenValue[stepNumber],
          interacted:
            StepThirteenValue?.rating != null &&
            StepThirteenValue?.competenceFinal != null &&
            StepThirteenValue?.confidenceFinal != null,
        },
        feedback: val,
      }),
    );
  };

  let NextStepList = [
    'Considering the fourth stage of Kolb’s model, plan out how you can achieve your goal(s)',
    'Explore expected barriers to reaching this goal and how to overcome them',
  ];
  position === ProgramPositions.peer
    ? NextStepList.push(
        'Schedule another meeting with your peer to discuss your goal, they may be able to help you with strategies on how to achieve it',
      )
    : position === ProgramPositions.mentee
    ? NextStepList.push(
        'Schedule another meeting with your mentor to discuss your goal, they may be able to help you with strategies on how to achieve it',
      )
    : NextStepList.push(
        'Consider talking with your mentee about their goal and discuss ways you may be able to help guide them to achievement',
      );

  return (
    <Grid container direction="column" spacing={4}>
      <BrancherDialog
        setClose={() => setDialog(false)}
        open={openDialog}
        labelledBy="Making of most of mentoring attributes"
      >
        <BrancherList
          fontWeight={400}
          listItems={[
            'Practice reflection through the use of Kolb’s adult learning model',
            'Take practical steps to apply SMART goals framework to your mentoring journey',
          ]}
        />
      </BrancherDialog>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700}>
          Well done on completing this training!
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" display="inline">
          Prior to completing this training, you told us you were an{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          {StepThirteenValue.competenceBaseline + 1}
        </Text>
        <Text variant="md" display="inline">
          {' '}
          out of 7 regarding your level of competence and{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          {StepThirteenValue.confidenceBaseline + 1}
        </Text>
        <Text variant="md" display="inline">
          {' '}
          out of 7 regarding your level of confidence to perform{' '}
        </Text>
        <BrancherLinkText
          display="inline"
          variant="md"
          underline="always"
          onClick={() => setDialog(true)}
        >
          these behaviours
        </BrancherLinkText>
        <Text variant="sm" display="inline">
          .
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your competence rating now?
        </Text>
        <CompetenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepThirteenValue[stepNumber]?.competenceFinal}
          valueLength={7}
          updateValue={updateCompetence}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your confidence rating now?
        </Text>
        <ConfidenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepThirteenValue[stepNumber]?.confidenceFinal}
          valueLength={7}
          updateValue={updateConfidence}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          On a scale of 0 - 10, how likely are you to recommend this training to another colleague
          within this mentoring program?
        </Text>
        <NPSScale />
        <SegmentedControlGroup
          fullWidth
          value={StepThirteenValue?.rating}
          valueLength={11}
          updateValue={updateRating}
          startFromZero
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Do you have any other feedback for us?
        </Text>
        <BrancherTextField
          value={StepThirteenValue?.feedback}
          updateValue={(a: string) => updateFeedback(a)}
          placeholder="Enter here"
          maxChars={400}
          id="feedback"
          name="feedback"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700}>
          Next steps:
        </Text>
      </Grid>
      <Box marginLeft={2}>
        <BrancherList fontWeight={400} listItems={NextStepList} />
      </Box>
      <Grid item xs={12}>
        <SuggestionCard>
          Make sure you click the "✓" button to complete this training.
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
