import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import {
  SaveBeyondProgramMenteeData,
  SaveBeyondProgramMentorData,
} from '../../../../../store/actions';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import {
  EMoDTrainingModules,
  EStructuredTrainingModules,
} from '../../../../../store/reducerTypes/TrainingReducer.types';
import { ScaleText } from '../../../../../Components/General/ScaleText';
import { FormWithStepFieldLayout } from '../../../../../Components/InputFields/FormWithStepFieldLayout';
import { SuggestionCard } from '../../SuggestionCard';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';

export const BeyondProgramMentorStepNine = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentee = position === ProgramPositions.mentee;
  const partnerPosition = isMentee ? ProgramPositions.mentor : ProgramPositions.mentee;
  const sessionStore = isMentee
    ? EStructuredTrainingModules.BEYOND_PROGRAM_MENTEE
    : EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR;
  const goalSessionStore = isMentee
    ? EMoDTrainingModules.MOD_GOAL_SETTING_MENTEE
    : EMoDTrainingModules.MOD_GOAL_SETTING_MENTOR;
  const oldGoalSessionStore = isMentee
    ? EStructuredTrainingModules.MAKE_THE_MOST_MENTEE
    : EStructuredTrainingModules.MAKE_THE_MOST_MENTOR;
  const sessionSaveFunction = isMentee ? SaveBeyondProgramMenteeData : SaveBeyondProgramMentorData;
  const StepNineValue = useSelector((state: IStoreTypes) => state.training?.[sessionStore]);
  const oldSmartGoal = useSelector(
    (state: IStoreTypes) => state.training?.[oldGoalSessionStore]?.StepSeven?.newGoal,
  );
  const hasOldSmartGoalLevel = useSelector(
    (state: IStoreTypes) => state.training?.[oldGoalSessionStore]?.StepSeven?.newGoal,
  );
  const hasOldSmartGoalPreferredLevel = useSelector(
    (state: IStoreTypes) => state.training?.[oldGoalSessionStore]?.StepSeven?.newGoal,
  );
  const menteeSmartGoal =
    useSelector((state: IStoreTypes) => state.training?.[goalSessionStore]?.StepSeven?.newGoal) ??
    oldSmartGoal;
  const menteeSmartGoalLevel =
    useSelector(
      (state: IStoreTypes) => state.training?.[goalSessionStore]?.StepNine?.smartGoalLevel,
    ) ?? hasOldSmartGoalLevel;
  const menteeSmartGoalPreferredLevel =
    useSelector(
      (state: IStoreTypes) => state.training?.[goalSessionStore]?.StepNine?.preferredLevel,
    ) ?? hasOldSmartGoalPreferredLevel;

  // The goal setting module for mentors is flawed
  const mentorSmartGoal =
    useSelector((state: IStoreTypes) => state.training?.[goalSessionStore]?.StepTen?.newGoal) ??
    oldSmartGoal;
  const smartGoal = isMentee ? menteeSmartGoal : mentorSmartGoal;
  const hasCompletedModule = isMentee
    ? smartGoal && menteeSmartGoal && menteeSmartGoalPreferredLevel
    : smartGoal;

  const dispatch = useDispatch();

  const updateValue = (attributeName: string, val: string | boolean) => {
    const newStepNineVals = { ...StepNineValue.StepNine, [attributeName]: val };
    dispatch(
      sessionSaveFunction({
        StepNine: {
          ...StepNineValue?.StepNine,
          [attributeName]: val,
          interacted: Boolean(
            newStepNineVals?.gainKnowledge &&
              newStepNineVals?.achievedGoal &&
              newStepNineVals?.currentConfidence !== null,
          ),
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      {!hasCompletedModule ? (
        <Grid item>
          <Text variant="md" fontWeight={600}>
            To continue with this training you need to complete the 'SMART goals' training module.
          </Text>
          <Text variant="md" fontWeight={600}>
            Please 'Save' this module's progress below before proceeding to complete other training.
          </Text>
        </Grid>
      ) : (
        !!smartGoal && (
          <>
            <Grid item>
              <Text variant="sm" fontWeight={700}>
                As your {partnerPosition} fills out this module, if they opt in to share their
                reflections and feedback with you, we will share this via your shared notes
              </Text>
            </Grid>
            <Grid item>
              <Text variant="sm">
                In the meantime, here is your SMART goal from a previous training module.
              </Text>
            </Grid>
            <Grid item>
              <SuggestionCard neutral>{smartGoal}</SuggestionCard>
            </Grid>
            {menteeSmartGoalLevel && (
              <Grid item>
                <Text variant="sm" display="inline">
                  Previously you told us that you were a{' '}
                </Text>
                <Text variant="sm" display="inline" color="purple" fontWeight={700}>
                  {menteeSmartGoalLevel + 1}
                </Text>
                <Text variant="sm" display="inline">
                  {' '}
                  out of 10 in regards to your level of experience, skill and confidence in this
                  area.
                </Text>
              </Grid>
            )}
            {menteeSmartGoalPreferredLevel && (
              <Grid item>
                <Text variant="sm" display="inline">
                  You also told us that you would prefer to be a{' '}
                </Text>
                <Text variant="sm" display="inline" color="purple" fontWeight={700}>
                  {menteeSmartGoalPreferredLevel + 1}
                </Text>
                <Text variant="sm" display="inline">
                  {' '}
                  out of 10.
                </Text>
              </Grid>
            )}
            <Grid item>
              <FormWithStepFieldLayout
                step={1}
                question="Where you are now in regard to your level of experience, skill and confidence?"
              >
                <Box marginTop={2} marginBottom={2}>
                  <ScaleText scale={10} description="Excellent knowledge, skill and confidence" />
                  <ScaleText scale={1} description="No current knowledge, skill and confidence" />
                </Box>
                <SegmentedControlGroup
                  value={StepNineValue?.StepNine?.currentConfidence}
                  fullWidth
                  valueLength={10}
                  updateValue={(a: string) => updateValue('currentConfidence', a)}
                />
              </FormWithStepFieldLayout>
            </Grid>
            <Grid item>
              <FormWithStepFieldLayout
                step={2}
                question="Did you achieve this original goal with support from your mentor?"
              >
                <SelectionControl
                  noQuestion
                  question=""
                  name="currentConfidence"
                  value={StepNineValue?.StepNine?.achievedGoal}
                  exclusive
                  halfWidth
                  options={MakeOptions([
                    'Achieved',
                    'In progress',
                    'Not started',
                    'Original goal no longer relevant but a different goal was achieved.',
                  ])}
                  updateValue={(a: string) => updateValue('achievedGoal', a)}
                />
              </FormWithStepFieldLayout>
            </Grid>
            <Grid item>
              <FormWithStepFieldLayout
                step={3}
                question="Did you build any new skills and/or gain new knowledge from your mentor?"
              >
                <SelectionControl
                  noQuestion
                  question=""
                  name="gainKnowledge"
                  value={StepNineValue?.StepNine?.gainKnowledge}
                  exclusive
                  halfWidth
                  options={MakeOptions(['Yes', 'In progress', 'No'])}
                  updateValue={(a: string) => updateValue('gainKnowledge', a)}
                />
              </FormWithStepFieldLayout>
            </Grid>
          </>
        )
      )}
    </Grid>
  );
};
