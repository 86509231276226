import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { EMoDTrainingModules } from '../../../../store/reducerTypes/TrainingReducer.types';
import { TrainingIntroduction } from '../../TrainingIntroduction';
import {
  getTrainingModuleHeading,
  getTrainingModuleNumber,
} from '../../TrainingModuleDefaultConfig';
import { Text } from '../../../../Components/General/Text';
import { SegmentedControlGroup } from '../../../../Form/FormFieldTypes/SegmentedControl';
import { SaveMoDSituationalMentorshipMentorData } from '../../../../store/actions';
import { IStoreTypes } from '../../../../store/storeTypes';
import { BrancherQuestionMarker } from '../../../../Components/General/BrancherQuestionMarker';
import { CompetencePopover } from '../sharedComps/CompetencePopover';
import { CompetenceScale } from '../sharedComps/CompetenceScale';
import { ConfidenceScale } from '../sharedComps/ConfidenceScale';
import { BrancherList } from '../../../../Components/General/BrancherList';

export const MoDSituationalMentorshipMentorSplash = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const trainingModules = useSelector((state: IStoreTypes) => state.training.modules)[sesPos];
  const modName = EMoDTrainingModules.MOD_SITUATIONAL_MENTORSHIP_MENTOR;
  const situationalMentorshipMenteeBaseline = useSelector(
    (state: IStoreTypes) => state.training?.[modName],
  );
  const trainingConfig = useSelector((state: IStoreTypes) => state.training.moduleConfig)[sesPos];
  const dispatch = useDispatch();
  const [anchorEl, setAnchor] = React.useState(null);
  const open = Boolean(anchorEl);

  const controlValidator = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!Boolean(anchorEl)) {
      setAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(anchorEl)) {
      setAnchor(null);
    }
  };

  const updateBaseline = (val: string, attr: string) => {
    dispatch(SaveMoDSituationalMentorshipMentorData({ [attr]: val }));
  };

  return (
    <TrainingIntroduction
      title={`Training #${getTrainingModuleNumber(
        modName,
        trainingModules,
        trainingConfig,
      )}: ${getTrainingModuleHeading(modName, trainingConfig)}`}
      subHeader="Complete this training to learn more about situational mentorship."
      activityLearnings={[]}
      modulePath={modName}
      validatedValue={
        situationalMentorshipMenteeBaseline?.competenceBaseline != null &&
        situationalMentorshipMenteeBaseline?.confidenceBaseline != null
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box marginTop={3} marginBottom={3}>
            <Text variant="sm" fontWeight={700} display="inline">
              To provide us with a baseline measure, please rate your current level of{' '}
            </Text>
            <Text variant="sm" fontWeight={700} display="inline" color="purple">
              competence and confidence to:{' '}
            </Text>
            <BrancherQuestionMarker onClick={controlValidator} />
            <CompetencePopover
              open={open}
              setClose={closeValidator}
              anchor={anchorEl}
              labelledBy="Competence and confidence"
            />
            <BrancherList
              variant="sm"
              listItems={[
                'Know when to be directive, coach, support or challenge, depending on the situation',
                'Use conversation starters to help flex between different styles',
              ]}
            />
          </Box>
          <Grid item>
            <Text variant="sm" fontWeight={700} marginBottom={10}>
              Please rate your competence:
            </Text>
          </Grid>
          <CompetenceScale />
          <SegmentedControlGroup
            value={situationalMentorshipMenteeBaseline?.competenceBaseline}
            valueLength={7}
            updateValue={(v: string) => updateBaseline(v, 'competenceBaseline')}
            fullWidth
          />
          <Grid item>
            <Text variant="sm" fontWeight={700} marginBottom={10}>
              Please rate your confidence:
            </Text>
          </Grid>
          <ConfidenceScale />
          <SegmentedControlGroup
            value={situationalMentorshipMenteeBaseline?.confidenceBaseline}
            valueLength={7}
            updateValue={(v: string) => updateBaseline(v, 'confidenceBaseline')}
            fullWidth
          />
        </Grid>
      </Grid>
    </TrainingIntroduction>
  );
};
