import * as React from 'react';
import { Link } from 'react-router-dom';
import { BaseErrorPage } from './BaseErrorPage';
import { GetRoute } from './RouteConfig';
import { BrancherButton } from '../InputFields/BrancherButton';

export const PageNotPermitted = () => (
  <BaseErrorPage
    error="Oops"
    errorHeading="Unauthorised."
    errorDescription="Sorry, it seems you didn't pass our biometric scan."
    src="https://media.brancher.com.au/shared/unauthorised-page.png"
  >
    <Link to={GetRoute('login').path}>
      <BrancherButton color="primary">Back</BrancherButton>
    </Link>
  </BaseErrorPage>
);
