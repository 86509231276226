import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { BeGreatMentorStepOne } from './Steps/BeGreatMentorStepOne';
import { BeGreatMentorStepTwo } from './Steps/BeGreatMentorStepTwo';
import { BeGreatMentorStepThree } from './Steps/BeGreatMentorStepThree';
import { BeGreatMentorStepThreeResult } from './Steps/BeGreatMentorStepThreeResult';
import { BeGreatMentorStepFour } from './Steps/BeGreatMentorStepFour';
import { BeGreatMentorStepFive } from './Steps/BeGreatMentorStepFive';
import { BeGreatMentorStepFiveResult } from './Steps/BeGreatMentorStepFiveResult';
import { BeGreatMentorStepSix } from './Steps/BeGreatMentorStepSix';
import { BeGreatMentorStepSeven } from './Steps/BeGreatMentorStepSeven';
import { BeGreatMentorStepEight } from './Steps/BeGreatMentorStepEight';
import { BeGreatMentorStepNine } from './Steps/BeGreatMentorStepNine';
import { BeGreatMentorStepTen } from './Steps/BeGreatMentorStepTen';
import { BeGreatMentorStepEleven } from './Steps/BeGreatMentorStepEleven';
import { BeGreatMentorStepTwelve } from './Steps/BeGreatMentorStepTwelve';
import { BeGreatMentorStepThirteen } from './Steps/BeGreatMentorStepThirteen';
import { BeGreatMentorStepFourteen } from './Steps/BeGreatMentorStepFourteen';
import { BeGreatMentorStepFifteen } from './Steps/BeGreatMentorStepFifteen';
import { BeGreatMentorStepSixteen } from './Steps/BeGreatMentorStepSixteen';
import { BeGreatMentorStepSeventeen } from './Steps/BeGreatMentorStepSeventeen';
import { BeGreatMentorStepEighteen } from './Steps/BeGreatMentorStepEighteen';
import { BeGreatMentorStepNineteen } from './Steps/BeGreatMentorStepNineteen';
import { BeGreatMentorStepFourCorrect } from './Steps/BeGreatMentorStepFourCorrect';
import { BeGreatMentorStepSevenCorrect } from './Steps/BeGreatMentorStepSevenCorrect';
import { BeGreatMentorStepEightCorrect } from './Steps/BeGreatMentorStepEightCorrect';
import { BeGreatMentorStepNineCorrect } from './Steps/BeGreatMentorStepNineCorrect';
import { BeGreatMentorStepTenCorrect } from './Steps/BeGreatMentorStepTenCorrect';
import { BeGreatMentorStepThirteenCorrect } from './Steps/BeGreatMentorStepThirteenCorrect';
import { BeGreatMentorStepFourteenCorrect } from './Steps/BeGreatMentorStepFourteenCorrect';
import { BeGreatMentorStepFifteenCorrect } from './Steps/BeGreatMentorStepFifteenCorrect';
import { BeGreatMentorStepSixteenCorrect } from './Steps/BeGreatMentorStepSixteenCorrect';
import { BeGreatMentorStepSeventeenCorrect } from './Steps/BeGreatMentorStepSeventeenCorrect';

export const BeGreatMentorModuleMapper: IModuleMapper[] = [
  {
    name: 'BeGreatMentorStepOne',
    component: <BeGreatMentorStepOne />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    nextComponent: 'BeGreatMentorStepTwo',
  },
  {
    name: 'BeGreatMentorStepTwo',
    component: <BeGreatMentorStepTwo />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'BeGreatMentorStepOne',
    nextComponent: 'BeGreatMentorStepThree',
  },
  {
    name: 'BeGreatMentorStepThree',
    component: <BeGreatMentorStepThree />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'BeGreatMentorStepTwo',
    correctComponent: 'BeGreatMentorStepThreeCorrect',
    incorrectComponent: 'BeGreatMentorStepThreeIncorrect',
  },
  {
    name: 'BeGreatMentorStepThreeCorrect',
    component: <BeGreatMentorStepThreeResult correct />,
    iterateStepper: true,
    hasQuestion: false,
    previousComponent: 'BeGreatMentorStepThree',
    nextComponent: 'BeGreatMentorStepFour',
  },
  {
    name: 'BeGreatMentorStepThreeIncorrect',
    component: <BeGreatMentorStepThreeResult correct={false} />,
    iterateStepper: true,
    hasQuestion: false,
    previousComponent: 'BeGreatMentorStepThree',
    nextComponent: 'BeGreatMentorStepFour',
  },
  {
    name: 'BeGreatMentorStepFour',
    component: <BeGreatMentorStepFour />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'BeGreatMentorStepThree',
    correctComponent: 'BeGreatMentorStepFourCorrect',
    incorrectComponent: 'BeGreatMentorStepFourCorrect',
  },
  {
    name: 'BeGreatMentorStepFourCorrect',
    component: <BeGreatMentorStepFourCorrect />,
    iterateStepper: true,
    previousComponent: 'BeGreatMentorStepFour',
    nextComponent: 'BeGreatMentorStepFive',
  },
  {
    name: 'BeGreatMentorStepFive',
    component: <BeGreatMentorStepFive />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'BeGreatMentorStepFour',
    correctComponent: 'BeGreatMentorStepFiveCorrect',
    incorrectComponent: 'BeGreatMentorStepFiveIncorrect',
  },
  {
    name: 'BeGreatMentorStepFiveCorrect',
    component: <BeGreatMentorStepFiveResult correct />,
    iterateStepper: true,
    hasQuestion: false,
    previousComponent: 'BeGreatMentorStepFive',
    nextComponent: 'BeGreatMentorStepSix',
  },
  {
    name: 'BeGreatMentorStepFiveIncorrect',
    component: <BeGreatMentorStepFiveResult correct={false} />,
    iterateStepper: true,
    hasQuestion: false,
    previousComponent: 'BeGreatMentorStepFive',
    nextComponent: 'BeGreatMentorStepSix',
  },
  {
    name: 'BeGreatMentorStepSix',
    component: <BeGreatMentorStepSix />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'BeGreatMentorStepFive',
    nextComponent: 'BeGreatMentorStepSeven',
  },
  {
    name: 'BeGreatMentorStepSeven',
    component: <BeGreatMentorStepSeven />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'BeGreatMentorStepSix',
    correctComponent: 'BeGreatMentorStepSevenCorrect',
    incorrectComponent: 'BeGreatMentorStepSevenCorrect',
  },
  {
    name: 'BeGreatMentorStepSevenCorrect',
    component: <BeGreatMentorStepSevenCorrect />,
    iterateStepper: true,
    previousComponent: 'BeGreatMentorStepSeven',
    nextComponent: 'BeGreatMentorStepEight',
  },
  {
    name: 'BeGreatMentorStepEight',
    component: <BeGreatMentorStepEight />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'BeGreatMentorStepSeven',
    correctComponent: 'BeGreatMentorStepEightCorrect',
    incorrectComponent: 'BeGreatMentorStepEightCorrect',
  },
  {
    name: 'BeGreatMentorStepEightCorrect',
    component: <BeGreatMentorStepEightCorrect />,
    iterateStepper: true,
    previousComponent: 'BeGreatMentorStepEight',
    nextComponent: 'BeGreatMentorStepNine',
  },
  {
    name: 'BeGreatMentorStepNine',
    component: <BeGreatMentorStepNine />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'BeGreatMentorStepEight',
    correctComponent: 'BeGreatMentorStepNineCorrect',
    incorrectComponent: 'BeGreatMentorStepNineCorrect',
  },
  {
    name: 'BeGreatMentorStepNineCorrect',
    component: <BeGreatMentorStepNineCorrect />,
    iterateStepper: true,
    previousComponent: 'BeGreatMentorStepNine',
    nextComponent: 'BeGreatMentorStepTen',
  },
  {
    name: 'BeGreatMentorStepTen',
    component: <BeGreatMentorStepTen />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'BeGreatMentorStepNine',
    correctComponent: 'BeGreatMentorStepTenCorrect',
    incorrectComponent: 'BeGreatMentorStepTenCorrect',
  },
  {
    name: 'BeGreatMentorStepTenCorrect',
    component: <BeGreatMentorStepTenCorrect />,
    iterateStepper: true,
    previousComponent: 'BeGreatMentorStepTen',
    nextComponent: 'BeGreatMentorStepEleven',
  },
  {
    name: 'BeGreatMentorStepEleven',
    component: <BeGreatMentorStepEleven />,
    iterateStepper: true,
    contentScreen: true,
    previousComponent: 'BeGreatMentorStepTen',
    nextComponent: 'BeGreatMentorStepTwelve',
  },
  {
    name: 'BeGreatMentorStepTwelve',
    component: <BeGreatMentorStepTwelve />,
    iterateStepper: true,
    contentScreen: true,
    previousComponent: 'BeGreatMentorStepEleven',
    nextComponent: 'BeGreatMentorStepThirteen',
  },
  {
    name: 'BeGreatMentorStepThirteen',
    component: <BeGreatMentorStepThirteen />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'BeGreatMentorStepTwelve',
    correctComponent: 'BeGreatMentorStepThirteenCorrect',
    incorrectComponent: 'BeGreatMentorStepThirteenCorrect',
  },
  {
    name: 'BeGreatMentorStepThirteenCorrect',
    component: <BeGreatMentorStepThirteenCorrect />,
    iterateStepper: true,
    previousComponent: 'BeGreatMentorStepThirteen',
    nextComponent: 'BeGreatMentorStepFourteen',
  },
  {
    name: 'BeGreatMentorStepFourteen',
    component: <BeGreatMentorStepFourteen />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'BeGreatMentorStepThirteen',
    correctComponent: 'BeGreatMentorStepFourteenCorrect',
    incorrectComponent: 'BeGreatMentorStepFourteenCorrect',
  },
  {
    name: 'BeGreatMentorStepFourteenCorrect',
    component: <BeGreatMentorStepFourteenCorrect />,
    iterateStepper: true,
    previousComponent: 'BeGreatMentorStepFourteen',
    nextComponent: 'BeGreatMentorStepFifteen',
  },
  {
    name: 'BeGreatMentorStepFifteen',
    component: <BeGreatMentorStepFifteen />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'BeGreatMentorStepFourteen',
    correctComponent: 'BeGreatMentorStepFifteenCorrect',
    incorrectComponent: 'BeGreatMentorStepFifteenCorrect',
  },
  {
    name: 'BeGreatMentorStepFifteenCorrect',
    component: <BeGreatMentorStepFifteenCorrect />,
    iterateStepper: true,
    previousComponent: 'BeGreatMentorStepFifteen',
    nextComponent: 'BeGreatMentorStepSixteen',
  },
  {
    name: 'BeGreatMentorStepSixteen',
    component: <BeGreatMentorStepSixteen />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'BeGreatMentorStepFifteen',
    correctComponent: 'BeGreatMentorStepSixteenCorrect',
    incorrectComponent: 'BeGreatMentorStepSixteenCorrect',
  },
  {
    name: 'BeGreatMentorStepSixteenCorrect',
    component: <BeGreatMentorStepSixteenCorrect />,
    iterateStepper: true,
    previousComponent: 'BeGreatMentorStepSixteen',
    nextComponent: 'BeGreatMentorStepSeventeen',
  },
  {
    name: 'BeGreatMentorStepSeventeen',
    component: <BeGreatMentorStepSeventeen />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'BeGreatMentorStepSixteen',
    correctComponent: 'BeGreatMentorStepSeventeenCorrect',
    incorrectComponent: 'BeGreatMentorStepSeventeenCorrect',
  },
  {
    name: 'BeGreatMentorStepSeventeenCorrect',
    component: <BeGreatMentorStepSeventeenCorrect />,
    iterateStepper: true,
    previousComponent: 'BeGreatMentorStepSeventeen',
    nextComponent: 'BeGreatMentorStepEighteen',
  },
  {
    name: 'BeGreatMentorStepEighteen',
    component: <BeGreatMentorStepEighteen />,
    iterateStepper: true,
    contentScreen: true,
    previousComponent: 'BeGreatMentorStepSeventeen',
    nextComponent: 'BeGreatMentorStepNineteen',
  },
  {
    name: 'BeGreatMentorStepNineteen',
    component: <BeGreatMentorStepNineteen />,
    iterateStepper: true,
    needsInteraction: true,
    submit: true,
    previousComponent: 'BeGreatMentorStepEighteen',
  },
];
