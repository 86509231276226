import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { CreateMentoringGuideLink } from '../../helpers/LinkBuilder';
import { SuggestionCard } from '../../SuggestionCard';

export const IntroductionMenteeStepOneIncorrect = () => {
  const mentoringGuideLink = CreateMentoringGuideLink();
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          That’s not the best option.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          A mentor’s role focuses on listening, coaching and empowering the mentee to reach their
          own solution. As the mentee, you should be driving the mentoring relationship, including
          setting up meetings, setting up the agenda, keeping your mentor updated with your progress
          and how they can help you.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          The best options are B and C.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Reviewing the{' '}
        </Text>
        <BrancherLinkText
          underline="always"
          fontWeight={700}
          variant="md"
          href={mentoringGuideLink}
          display="inline"
          target="_blank"
        >
          Mentoring Guide
        </BrancherLinkText>
        <Text variant="md" display="inline">
          {' '}
          will provide you with clarity and confidence on your role as a mentee. Having an open
          discussion with Alex about how you are feeling will allow Alex to understand why you would
          like to take the lead, and ensure Alex gives you the time to do so.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You could say things like:
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard>
          “I’m keen to own my development, I’ll send through an invite for our next catch up and
          prepare an agenda.”
        </SuggestionCard>
        <SuggestionCard>
          “There are a few things I’d love to talk aloud with you and it would be great to take
          advantage of your listening skills.”
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="md">
          Ensure you promptly follow through with scheduling your next catch up and owning the next
          agenda so Alex is confident you’re following through.
        </Text>
      </Grid>
    </Grid>
  );
};
