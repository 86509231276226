import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';

export const MoDGoalSettingMenteeStepTenCorrect = () => {
  const stepTenValue = useSelector(
    (state: IStoreTypes) => state.training.modGoalSettingMentee?.StepTen,
  );
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepTenValue.correct} incorrect={!stepTenValue.correct}>
          <Text variant="md" fontWeight={700}>
            A and B are correct.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="md">
          You should ask your mentor to review your goal and action plan and provide feedback. You
          should also regularly discuss your goal progress during your mentoring meetings. This will
          enable you to stay on track and review your action plan or goal when necessary.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Do not hide or avoid discussing your goal with your mentor. If your goal is confidential,
          awkward or embarrassing, just mention that you'd like your mentor to keep it confidential.
          If you're feeling nervous, share that you're feeling that way with your mentor to ease
          into the conversation.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Mentoring is most powerful when it's anchored around goals.
        </Text>
      </Grid>
    </Grid>
  );
};
