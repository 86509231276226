import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SuggestionCard } from '../../SuggestionCard';

export const IntroductionMentorStepThreeCorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          Correct, the best option is{' '}
        </Text>
        <Text variant="md" fontWeight={700} color="purple" display="inline">
          B
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Your Mentoring Lead is available for support but before reaching out to them, meet with
          Jordan to get to know each other and explore why the relationship might work (rather than
          why it might not work).
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Ask what Jordan wants to achieve from the program and share what skills, advice and
          perspective you can offer them.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Ask them questions like:
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard>"What are you hoping to get out of the mentoring program?"</SuggestionCard>
      </Grid>
      <Grid item>
        <SuggestionCard>
          "What experiences can I share with you that might be helpful?"
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="md">
          If the relationship is unproductive, inform your Mentoring Lead so they can ensure you
          take appropriate action to end the relationship professionally.
        </Text>
      </Grid>
    </Grid>
  );
};
