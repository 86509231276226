import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import { BrancherCard } from '../../Components/General/BrancherCard';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { GetRoute } from '../../Components/Routing';
import { BrancherDispatch, UtilGetUserGroups } from '../../store/actions';
import { IGroup } from '../Groups/Groups';
import { Text } from '../../Components/General/Text';
import { MeetingsSection } from './MeetingsSection';

const useStyles = makeStyles({
  margin: {
    marginBottom: 30,
  },
});

export const GroupsSection: React.FC = () => {
  const [groups, setGroups] = React.useState<IGroup[]>([]);
  const [gettingGroups, setGettingGroups] = React.useState<boolean>(true);
  const dispatch = useDispatch();
  const styles = useStyles();

  React.useEffect(() => {
    getGroups();
  }, []);

  const getGroups = () => {
    BrancherDispatch(
      dispatch,
      UtilGetUserGroups((groupRes) => {
        if (groupRes.success) {
          setGroups(groupRes.data);
        }
        setGettingGroups(false);
      }),
    );
  };

  const getPluralGroupText = (): string => {
    const groupAmount = groups.length;
    return groupAmount > 1 ? `${groupAmount} groups` : `group`;
  };

  return (
    <>
      {!gettingGroups ? (
        <BrancherCard className={styles.margin}>
          <Grid container alignItems="center" justify="center" item xs={12}>
            <Text variant="sm" fontWeight={600} marginBottom={10}>
              You are in the following {getPluralGroupText()}
            </Text>
            <Grid item container spacing={2} alignItems="center" justify="center">
              {groups.map((group) => (
                <Grid
                  item
                  xs={8}
                  md={12}
                  lg={8}
                  xl={9}
                  container
                  direction="column"
                  key={group.groupId}
                >
                  <Grid item>
                    <BrancherButton
                      href={`${GetRoute('group').path}?g=${group.groupId}`}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      aria-label={group.title}
                    >
                      Go to {group.title}
                    </BrancherButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </BrancherCard>
      ) : (
        <CircularProgress color="secondary" size={64} />
      )}
      <MeetingsSection />
    </>
  );
};
