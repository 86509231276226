import * as React from 'react';
import { SuggestionCard } from '../SuggestionCard';
import { Text } from '../../../../Components/General/Text';
import { BrancherLinkText } from '../../../../Components/General/BrancherLinkText';

export const MasterModelCard = () => (
  <SuggestionCard custom>
    <BrancherLinkText
      variant="sm"
      underline="always"
      display="inline"
      target="_blank"
      href="https://media.brancher.com.au/training/givingReceivingFeedbackMentee/Master_feedback_model.pdf"
    >
      Click here {}
    </BrancherLinkText>
    <Text variant="sm" display="inline" noPreWrap>
      to have another look at the MASTER model.
    </Text>
  </SuggestionCard>
);
