import * as React from 'react';
import { format, getDate, getHours, getMinutes, getMonth, getYear } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '../../Components/General/DatePicker';
import { Text } from '../../Components/General/Text';
import { AddMeetingButton, NextButton } from '../../Components/InputFields/BrancherButton';
import { BrancherInputLabel } from '../../Components/InputFields/BrancherInputLabel';
import { BrancherToggleButton } from '../../Components/InputFields/ToggleButton/BrancherToggleButton';
import { GetRoute } from '../../Components/Routing';
import { MakeOptions } from '../../Form/MakeOptions';
import { BrancherDispatch, UtilSaveUserMeetingData } from '../../store/actions';
import { IStoreTypes } from '../../store/storeTypes';
import { IDefaultUserActionProps } from './ActionsEngine';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';

export const CreateMeetingAction = (props: IDefaultUserActionProps) => {
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const sessionPosition = useSelector((state: IStoreTypes) => state.user?.sessionPosition);
  const { closeDialog, action } = props;
  const [metWithPartner, setMetWithPartner] = React.useState<string>(null);
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const isMentee = sessionPosition === ProgramPositions.mentee;
  const partnerPosition = isMentee
    ? roleLabels[ProgramPositions.mentor]
    : roleLabels[ProgramPositions.mentee];
  const dispatch = useDispatch();

  const savePreviousMeeting = () => {
    const dateStart = format(
      new Date(
        getYear(startDate),
        getMonth(startDate),
        getDate(startDate),
        getHours(startDate),
        getMinutes(startDate),
      ),
      "yyyy-MM-dd'T'HH:mm:ssxxx",
    );
    const dateEnd = format(
      new Date(
        getYear(startDate),
        getMonth(startDate),
        getDate(startDate),
        getHours(startDate) + 1,
        getMinutes(startDate),
      ),
      "yyyy-MM-dd'T'HH:mm:ssxxx",
    );
    const newMeeting = {
      datetimeStart: dateStart,
      datetimeEnd: dateEnd,
      location: '',
      description: '',
      title: 'Reported meeting',
    };
    BrancherDispatch(
      dispatch,
      UtilSaveUserMeetingData(
        newMeeting,
        true,
        () => {
          closeDialog();
        },
        action.actionAttributes?.partnerRoleId ?? '',
      ),
    );
  };

  const setDate = (date: Date) => {
    setStartDate(date);
  };

  return (
    <Grid container justify="center" alignItems="center">
      {metWithPartner === null ? (
        <Grid container justify="center">
          <Text variant="md" marginBottom={20} fontWeight={500}>
            Have you met with your {partnerPosition}?
          </Text>
          <BrancherToggleButton
            exclusive
            halfWidth
            name="met-with-partner"
            options={MakeOptions(['Yes', 'No'])}
            updateValue={(decision: string) => setMetWithPartner(decision)}
            value={metWithPartner}
          />
        </Grid>
      ) : metWithPartner === '0' ? (
        <>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={3}>
              <BrancherInputLabel variant="sm" fontWeight={700} color="purple" for="date">
                When did you meet?
              </BrancherInputLabel>
            </Grid>
            <Grid item xs={8}>
              <DatePicker
                value={startDate}
                updateValue={setDate}
                maxDate={new Date()}
                id="date"
                name="meeting-date"
              />
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <NextButton onClick={savePreviousMeeting} disabled={!startDate}>
              Continue
            </NextButton>
          </Grid>
        </>
      ) : (
        <Grid container justify="center">
          <Grid item xs={8}>
            <Text variant="sm" fontWeight={500}>
              We understand that it can be difficult to catch up regularly, but we highly recommend
              that you try to meet with your {partnerPosition} at least once every 30 days.
            </Text>
          </Grid>
          <Grid item xs={8} container justify="flex-end">
            <AddMeetingButton
              onClick={closeDialog}
              href={GetRoute('meeting').path}
              aria-label="create-meeting-action"
            >
              Create Meeting
            </AddMeetingButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
