import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveSurveyOneData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const SurveyStepOne = () => {
  const surveyOneValue = useSelector(
    (state: IStoreTypes) => state.training?.[EStructuredTrainingModules.SURVEY_ONE],
  );
  const dispatch = useDispatch();

  const updateValue = (hasMetValue: string) => {
    dispatch(
      SaveSurveyOneData({
        StepOne: {
          interacted: !!hasMetValue,
          haveYouMet: hasMetValue,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={600}>
          Have you met with your mentoring partner?
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          noQuestion
          name="haveYouMet"
          value={surveyOneValue?.StepOne?.haveYouMet}
          updateValue={updateValue}
          exclusive
          halfWidth
          options={MakeOptions(['Yes', 'No'])}
        />
      </Grid>
    </Grid>
  );
};
