import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { MoDGoalSettingMenteeStepEight } from './Steps/MoDGoalSettingMenteeStepEight';
import { MoDGoalSettingMenteeStepFive } from './Steps/MoDGoalSettingMenteeStepFive';
import { MoDGoalSettingMenteeStepFiveCorrect } from './Steps/MoDGoalSettingMenteeStepFiveCorrect';
import { MoDGoalSettingMenteeStepFour } from './Steps/MoDGoalSettingMenteeStepFour';
import { MoDGoalSettingMenteeStepFourCorrect } from './Steps/MoDGoalSettingMenteeStepFourCorrect';
import { MoDGoalSettingMenteeStepNine } from './Steps/MoDGoalSettingMenteeStepNine';
import { MoDGoalSettingMenteeStepOne } from './Steps/MoDGoalSettingMenteeStepOne';
import { MoDGoalSettingMenteeStepSeven } from './Steps/MoDGoalSettingMenteeStepSeven';
import { MoDGoalSettingMenteeStepSix } from './Steps/MoDGoalSettingMenteeStepSix';
import { MoDGoalSettingMenteeStepSixCorrect } from './Steps/MoDGoalSettingMenteeStepSixCorrect';
import { MoDGoalSettingMenteeStepTen } from './Steps/MoDGoalSettingMenteeStepTen';
import { MoDGoalSettingMenteeStepTenCorrect } from './Steps/MoDGoalSettingMenteeStepTenCorrect';
import { MoDGoalSettingMenteeStepThree } from './Steps/MoDGoalSettingMenteeStepThree';
import { MoDGoalSettingMenteeStepTwo } from './Steps/MoDGoalSettingMenteeStepTwo';
import { MoDGoalSettingMenteeStepEleven } from './Steps/MoDGoalSettingMenteeStepEleven';

export const MoDGoalSettingMenteeModuleMapper: IModuleMapper[] = [
  {
    name: 'MoDGoalSettingMenteeStepOne',
    component: <MoDGoalSettingMenteeStepOne />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    nextComponent: 'MoDGoalSettingMenteeStepTwo',
  },
  {
    name: 'MoDGoalSettingMenteeStepTwo',
    component: <MoDGoalSettingMenteeStepTwo />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MoDGoalSettingMenteeStepOne',
    nextComponent: 'MoDGoalSettingMenteeStepThree',
  },
  {
    name: 'MoDGoalSettingMenteeStepThree',
    component: <MoDGoalSettingMenteeStepThree />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MoDGoalSettingMenteeStepTwo',
    nextComponent: 'MoDGoalSettingMenteeStepFour',
  },
  {
    name: 'MoDGoalSettingMenteeStepFour',
    component: <MoDGoalSettingMenteeStepFour />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'MoDGoalSettingMenteeStepThree',
    correctComponent: 'MoDGoalSettingMenteeStepFourCorrect',
    incorrectComponent: 'MoDGoalSettingMenteeStepFourCorrect',
  },
  {
    name: 'MoDGoalSettingMenteeStepFourCorrect',
    component: <MoDGoalSettingMenteeStepFourCorrect />,
    iterateStepper: true,
    previousComponent: 'MoDGoalSettingMenteeStepFour',
    nextComponent: 'MoDGoalSettingMenteeStepFive',
  },
  {
    name: 'MoDGoalSettingMenteeStepFive',
    component: <MoDGoalSettingMenteeStepFive />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'MoDGoalSettingMenteeStepFour',
    correctComponent: 'MoDGoalSettingMenteeStepFiveCorrect',
    incorrectComponent: 'MoDGoalSettingMenteeStepFiveCorrect',
  },
  {
    name: 'MoDGoalSettingMenteeStepFiveCorrect',
    component: <MoDGoalSettingMenteeStepFiveCorrect />,
    iterateStepper: true,
    previousComponent: 'MoDGoalSettingMenteeStepFive',
    nextComponent: 'MoDGoalSettingMenteeStepSix',
  },
  {
    name: 'MoDGoalSettingMenteeStepSix',
    component: <MoDGoalSettingMenteeStepSix />,
    iterateStepper: false,
    hasQuestion: true,
    needsInteraction: false,
    previousComponent: 'MoDGoalSettingMenteeStepFour',
    correctComponent: 'MoDGoalSettingMenteeStepSixCorrect',
    incorrectComponent: 'MoDGoalSettingMenteeStepSixCorrect',
  },
  {
    name: 'MoDGoalSettingMenteeStepSixCorrect',
    component: <MoDGoalSettingMenteeStepSixCorrect />,
    iterateStepper: true,
    previousComponent: 'MoDGoalSettingMenteeStepSix',
    nextComponent: 'MoDGoalSettingMenteeStepSeven',
  },
  {
    name: 'MoDGoalSettingMenteeStepSeven',
    component: <MoDGoalSettingMenteeStepSeven />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MoDGoalSettingMenteeStepSix',
    nextComponent: 'MoDGoalSettingMenteeStepEight',
  },
  {
    name: 'MoDGoalSettingMenteeStepEight',
    component: <MoDGoalSettingMenteeStepEight />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MoDGoalSettingMenteeStepSeven',
    nextComponent: 'MoDGoalSettingMenteeStepNine',
  },
  {
    name: 'MoDGoalSettingMenteeStepNine',
    component: <MoDGoalSettingMenteeStepNine />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MoDGoalSettingMenteeStepEight',
    nextComponent: 'MoDGoalSettingMenteeStepTen',
  },
  {
    name: 'MoDGoalSettingMenteeStepTen',
    component: <MoDGoalSettingMenteeStepTen />,
    iterateStepper: false,
    hasQuestion: true,
    needsInteraction: false,
    previousComponent: 'MoDGoalSettingMenteeStepNine',
    correctComponent: 'MoDGoalSettingMenteeStepTenCorrect',
    incorrectComponent: 'MoDGoalSettingMenteeStepTenCorrect',
  },
  {
    name: 'MoDGoalSettingMenteeStepTenCorrect',
    component: <MoDGoalSettingMenteeStepTenCorrect />,
    iterateStepper: true,
    previousComponent: 'MoDGoalSettingMenteeStepTen',
    nextComponent: 'MoDGoalSettingMenteeStepEleven',
  },
  {
    name: 'MoDGoalSettingMenteeStepEleven',
    component: <MoDGoalSettingMenteeStepEleven />,
    iterateStepper: true,
    needsInteraction: true,
    submit: true,
    previousComponent: 'MoDGoalSettingMenteeStepTen',
  },
];
