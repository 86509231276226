import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { isPast } from 'date-fns';
import { EProfileAttributes, IProfileData } from '../../store/reducers/ProfileFormReducer';
import { IGroup, IGroupMember } from './Groups';
import { BrancherDispatch, UtilGetProgramGroup } from '../../store/actions';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { Profile } from '../Profile/Profile';
import { PageLayout } from '../../Components/General/PageLayout';
import { Text } from '../../Components/General/Text';
import { ProfileCard } from '../Cohort/ProfileCard';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { IStoreTypes } from '../../store/storeTypes';
import { Scheduler } from '../Meetings/Scheduler';
import { MeetingTile } from '../Meetings/MeetingTile';
import { IMeetingData } from '../../store/reducers/MeetingsReducer';
import { sortMeetings } from '../Meetings/SortMeetings';
import { groupResources } from '../Resources/TrainingResourceConfiguration';
import { BrancherCard } from '../../Components/General/BrancherCard';
import { GetGroupResourceLink, GetSharedResourceLink } from '../../utils/LinkUtils';
import { AddCalendar } from '../Meetings/AddCalendar';
import { Subscription } from '../../types/SubscriptionTypes';

const useStyles = makeStyles({
  marginHeading: {
    marginBottom: 40,
    marginTop: 40,
  },
  resource: {
    minWidth: 280,
  },
});

interface IExtendedGroupMember extends IGroupMember {
  profile: IProfileData;
}

export interface IExtendedGroupData extends Omit<IGroup, 'mentees' | 'mentors' | 'peers'> {
  mentees: IExtendedGroupMember[];
  mentors: IExtendedGroupMember[];
  peers?: IExtendedGroupMember[];
}

export const Group: React.FC = () => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const userId = useSelector((state: IStoreTypes) => state.user?.id);
  const meetings = useSelector((state: IStoreTypes) => state.meetings.meetings);
  const isSSO = useSelector((state: IStoreTypes) => state.user?.isSSO);
  const subscriptions = useSelector((state: IStoreTypes) => state.user?.subscription);
  const allowsOutlookIntegration = isSSO
    ? subscriptions.includes(Subscription.MEETINGS_OUTLOOK)
    : true;
  const [gettingGroup, setGettingGroup] = React.useState<boolean>(true);
  const [groupData, setGroupData] = React.useState<IExtendedGroupData>(null);
  const groupId = new URLSearchParams(useLocation().search).get('g');
  const dispatch = useDispatch();
  const [profileOpen, setProfileOpen] = React.useState<boolean>(false);
  const [selectedUser, setSelectedUser] = React.useState<IProfileData | null>();
  const styles = useStyles();

  React.useEffect(() => {
    if (!!groupId) {
      getGroupData();
    }
  }, []);

  const getGroupData = () => {
    setGettingGroup(true);
    BrancherDispatch(
      dispatch,
      UtilGetProgramGroup(groupId, (groupRes) => {
        setGroupData(groupRes.data);
        setGettingGroup(false);
      }),
    );
  };

  const controlDialog = (control: boolean) => {
    setProfileOpen(control);
    if (!control) {
      setSelectedUser(null);
    }
  };

  const controlSelectedUser = (user?: IProfileData) => {
    if (!profileOpen) {
      setSelectedUser(user);
      controlDialog(true);
    }
  };

  const isGroupOwner = groupData?.owner === userId;

  const futureMeetings: IMeetingData[] = [];
  const pastMeetings: IMeetingData[] = [];

  meetings
    .filter((m) => m.groupId === groupId)
    .forEach((meet) => {
      if (isPast(new Date(meet.datetimeEnd))) {
        pastMeetings.push(meet);
      } else {
        futureMeetings.push(meet);
      }
    });

  return (
    <PageLayout pageTitle={groupData?.title ?? 'Group'} backButtonRoute="groups">
      {!gettingGroup && groupData?.title ? (
        <>
          {selectedUser && (
            <BrancherDialog
              open={profileOpen}
              setClose={() => controlDialog(false)}
              labelledBy={selectedUser[EProfileAttributes.FIRST_NAME]}
              fullScreen={mobile}
            >
              <Box right={0} position="absolute" width="10%" marginTop={2}>
                <CloseIcon
                  onClick={() => controlDialog(false)}
                  color="secondary"
                  fontSize="large"
                />
              </Box>
              <DialogContent>
                <Profile profileData={selectedUser} profileType={selectedUser.profileType} />
              </DialogContent>
            </BrancherDialog>
          )}
          <Grid container item xs={12}>
            <Grid container justify="center">
              <Grid container justify="center" item xs={10}>
                <Grid container>
                  <Grid item>
                    <Text variant="sm" display="inline" fontWeight={500}>
                      Group owner
                    </Text>
                    <Text variant="sm" display="inline" fontWeight={600}>
                      : {groupData.ownerName}
                    </Text>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid container justify="space-between">
                    <Grid item xs={11} lg={5} container direction="column">
                      <Grid item container justify="space-between" className={styles.marginHeading}>
                        <Grid item>
                          <Text variant="lg" fontWeight={600} display="inline">
                            Mentors
                          </Text>
                        </Grid>
                      </Grid>
                      <Grid item>
                        {groupData?.mentors?.map((mentor) => (
                          <Grid container alignItems="center" key={mentor.roleId}>
                            <Grid item xs={10}>
                              <ProfileCard
                                onSelectUser={() => controlSelectedUser(mentor.profile)}
                                firstName={mentor?.profile?.[EProfileAttributes.FIRST_NAME]}
                                lastName={mentor?.profile?.[EProfileAttributes.LAST_NAME]}
                                jobTitle={mentor?.profile?.[EProfileAttributes.JOB_TITLE]}
                                image={mentor?.profile?.[EProfileAttributes.IMAGE]}
                                profileType={ProgramPositions.mentor}
                              />
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item xs={11} lg={5} container direction="column">
                      <Grid item container justify="space-between" className={styles.marginHeading}>
                        <Grid item>
                          <Text variant="lg" fontWeight={600} display="inline">
                            Mentees
                          </Text>
                        </Grid>
                      </Grid>
                      <Grid item container>
                        {groupData?.mentees?.map((mentee) => (
                          <Grid container alignItems="center" key={mentee.roleId}>
                            <Grid item xs={10}>
                              <ProfileCard
                                onSelectUser={() => controlSelectedUser(mentee.profile)}
                                firstName={mentee?.profile?.[EProfileAttributes.FIRST_NAME]}
                                lastName={mentee?.profile?.[EProfileAttributes.LAST_NAME]}
                                jobTitle={mentee?.profile?.[EProfileAttributes.JOB_TITLE]}
                                image={mentee?.profile?.[EProfileAttributes.IMAGE]}
                                profileType={ProgramPositions.mentee}
                              />
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item>
                  <Grid container spacing={2} direction="column">
                    <Box width="100%">
                      <Text
                        variant="md"
                        fontWeight={500}
                        color="tertiaryGrey"
                        marginBottom={15}
                        marginTop={40}
                      >
                        Group resources
                      </Text>
                      <Grid container spacing={2}>
                        {groupResources.map((resource, index) => (
                          <Grid item xs={12} lg={6} xl={4} key={index}>
                            <a
                              href={
                                resource?.isGroupResource
                                  ? GetGroupResourceLink(resource.src)
                                  : GetSharedResourceLink(resource.src)
                              }
                              download
                              title={resource.title}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <BrancherCard minHeight="fit-content" className={styles.resource}>
                                <Text variant="sm" fontWeight={600}>
                                  {resource.title}
                                </Text>
                                <Grid container justify="flex-end" alignItems="flex-end">
                                  <CloudDownload />
                                </Grid>
                              </BrancherCard>
                            </a>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container item>
                  {futureMeetings.length > 0 ? (
                    <Grid item xs={12}>
                      {sortMeetings(futureMeetings).map((meeting) => (
                        <MeetingTile key={meeting.meetingId} fullMeetingData={meeting} group />
                      ))}
                    </Grid>
                  ) : (
                    <Grid item container justify="center">
                      <Text variant="md" fontWeight={600} marginTop={70} marginBottom={10}>
                        {groupData.title} has no future meetings scheduled
                      </Text>
                      <Text variant="sm" fontWeight={500} marginBottom={50}>
                        When {groupData.ownerName} creates group meetings they will appear here.
                      </Text>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {isGroupOwner ? (
              <Scheduler group={groupData} />
            ) : (
              allowsOutlookIntegration && <AddCalendar redirectPath={`group?g=${groupId}`} />
            )}
          </Grid>
        </>
      ) : (
        <CircularProgress color="secondary" size={64} />
      )}
    </PageLayout>
  );
};
