import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isPast } from 'date-fns';
import { PageLayout } from '../../Components/General/PageLayout';
import { Text } from '../../Components/General/Text';
import { BrancherDispatch, UtilGetUserMeetings } from '../../store/actions';
import { GetRoute } from '../../Components/Routing';
import { IMeetingData } from '../../store/reducers/MeetingsReducer';
import { IStoreTypes } from '../../store/storeTypes';
import { AddMeetingButton } from '../../Components/InputFields/BrancherButton';
import { MeetingTile } from './MeetingTile';
import { sortMeetings } from './SortMeetings';

export const Meetings = () => {
  const meetings = useSelector((state: IStoreTypes) => state.meetings?.meetings);
  const dispatch = useDispatch();
  const [hasMeetingData, setHasMeetingData] = React.useState<boolean>(false);
  const hasActiveGroup = useSelector((state: IStoreTypes) => state.user?.groups)?.length > 0;
  const hasMentoringPartner = !!useSelector(
    (state: IStoreTypes) => state.user?.sessionPair?.roleId,
  );
  const onlyInGroup = hasActiveGroup && !hasMentoringPartner;

  const getAllMeetings = () => {
    setHasMeetingData(false);
    BrancherDispatch(
      dispatch,
      UtilGetUserMeetings(() => {
        setHasMeetingData(true);
      }),
    );
  };

  React.useEffect(() => {
    getAllMeetings();
  }, []);

  const futureMeetings: IMeetingData[] = [];
  const pastMeetings: IMeetingData[] = [];

  meetings.forEach((meet) => {
    if (isPast(new Date(meet.datetimeEnd))) {
      pastMeetings.push(meet);
    } else {
      futureMeetings.push(meet);
    }
  });

  return (
    <PageLayout pageTitle="Meetings">
      {!onlyInGroup && (
        <Grid item xs={12}>
          <Link to={GetRoute('meeting').path}>
            <AddMeetingButton>Schedule a meeting</AddMeetingButton>
          </Link>
        </Grid>
      )}
      {hasMeetingData ? (
        meetings.length > 0 ? (
          <>
            <Grid item xs={12}>
              {sortMeetings(futureMeetings).map((meeting) => (
                <MeetingTile key={meeting.meetingId} fullMeetingData={meeting} />
              ))}
            </Grid>
            <Grid item xs={12}>
              <Text variant="sm" marginTop={30}>
                Past Meetings
              </Text>
              {sortMeetings(pastMeetings, false).map((meeting) => (
                <MeetingTile key={meeting.meetingId} fullMeetingData={meeting} />
              ))}
            </Grid>
          </>
        ) : (
          <Text variant="xs" fontWeight={500} marginTop={40}>
            You have no meetings past or future, make one by clicking the 'New Meeting' button
            above!
          </Text>
        )
      ) : (
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item xs={5}>
            <CircularProgress color="secondary" size={64} />
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};
