import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMenteeData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';
import { SallyAvatar } from '../../avatars/Sally';

export const BeGreatMenteeStepFive = () => {
  const stepFiveValue = useSelector((state: IStoreTypes) => state.training.beGreatMentee?.StepFive);
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepFiveCorrectVal = '2';
    dispatch(
      SaveBeGreatMenteeData({
        StepFive: {
          selected: val,
          correct: StepFiveCorrectVal === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <SallyAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700} display="inline">
            This is your mentor,{' '}
          </Text>
          <Text variant="md" fontWeight={700} display="inline" color="purple">
            "Sally"
          </Text>
          <Text variant="md" fontWeight={700} display="inline">
            .
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          You meet with Sally and tell her, "My manager just doesn't have the time for me. We rarely
          have 1:1s and I don't get the direction or feedback I need!"
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Sally responds, "That must be really frustrating. How does it make you feel?"
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Using a growth mindset, how do you respond?
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="It is frustrating, I think I need a new manager!"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="It is frustrating, I've tried everything and nothing has changed. They can't change."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="It's frustrating. I need to find a way to change this."
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepFiveValue"
          value={stepFiveValue?.selected}
          updateValue={updateValue}
          noQuestion
          exclusive
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
