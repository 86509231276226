import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { EContentType, IPrivateNote } from '../../../../../store/reducers/PrivateNotesReducer';
import { SuggestionCard } from '../../SuggestionCard';
import { IStoreTypes } from '../../../../../store/storeTypes';
import {
  BrancherDispatch,
  SaveBeyondProgramMenteeData,
  SaveBeyondProgramMentorData,
  UtilSaveUserPrivateNotesData,
  UtilSaveUserSharedNotesData,
} from '../../../../../store/actions';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveButton } from '../../../../../Components/InputFields/BrancherButton';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';

export const BeyondProgramMentorStepTen = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentee = position === ProgramPositions.mentee;
  const partnerRole = isMentee ? ProgramPositions.mentor : ProgramPositions.mentee;
  const sessionStore = isMentee
    ? EStructuredTrainingModules.BEYOND_PROGRAM_MENTEE
    : EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR;
  const sessionSaveFunction = isMentee ? SaveBeyondProgramMenteeData : SaveBeyondProgramMentorData;
  const StepTenValue = useSelector((state: IStoreTypes) => state.training[sessionStore]);
  const AchievedGoal = useSelector(
    (state: IStoreTypes) => state.training[sessionStore]?.StepNine?.achievedGoal === '0',
  );
  const dispatch = useDispatch();

  const updateValue = (attributeName: string, val: string | boolean) => {
    const newStepTwoVals = { ...StepTenValue.StepTen, [attributeName]: val };
    dispatch(
      sessionSaveFunction({
        StepTen: {
          ...StepTenValue?.StepTen,
          [attributeName]: val,
          interacted: Boolean(
            newStepTwoVals?.newGoal &&
              newStepTwoVals?.savedNote &&
              newStepTwoVals?.sharePublic !== null,
          ),
        },
      }),
    );
  };

  const saveNote = () => {
    const apiCall =
      StepTenValue?.StepTen?.sharePublic === '0'
        ? UtilSaveUserPrivateNotesData
        : UtilSaveUserSharedNotesData;
    const noteData: IPrivateNote = {
      contents: [
        {
          content: StepTenValue?.StepTen?.newGoal,
          type: EContentType.HEADING,
        },
      ],
      title: 'Beyond the program - NEW GOAL',
      lastModified: Date.now(),
    };
    BrancherDispatch(
      dispatch,
      apiCall(noteData, () => {
        updateValue('savedNote', true);
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      {AchievedGoal ? (
        <>
          <Grid item>
            <Text variant="sm" fontWeight={700}>
              Congratulations on achieving your goal!
            </Text>
          </Grid>
          <Grid item>
            <Text variant="sm">
              We want your development to continue beyond this formal mentoring relationship. Set a
              new or revised goal that you can continue working on.
            </Text>
          </Grid>
        </>
      ) : (
        <>
          <Grid item>
            <Text variant="sm" fontWeight={700}>
              That’s okay!
            </Text>
          </Grid>
          <Grid item>
            <Text variant="sm">
              You may want to review the SMART goal to be more realistic. Below, consider reshaping
              your SMART goal to take with you beyond the program.
            </Text>
          </Grid>
          <Grid item>
            <Text variant="sm">
              You may want to consider some of the obstacles you encountered. For example, do you
              need to adjust the timing to be more realistic?
            </Text>
          </Grid>
        </>
      )}
      <Grid item>
        <Text variant="sm" fontWeight={600}>
          New SMART Goal:
        </Text>
        <BrancherTextField
          value={StepTenValue?.StepTen?.newGoal}
          updateValue={(textValue: string) => updateValue('newGoal', textValue)}
          maxChars={400}
          placeholder="Enter here"
          multiline
          fullWidth
        />
      </Grid>
      <Grid item>
        <SuggestionCard>
          Would you like to keep this content private or share with your {partnerRole} in your
          shared notes?
        </SuggestionCard>
      </Grid>
      <Grid item>
        <SelectionControl
          noQuestion
          question=""
          name="sharePublic"
          value={StepTenValue?.StepTen?.sharePublic}
          exclusive
          halfWidth
          options={MakeOptions(['Private', 'Share'])}
          updateValue={(a: string) => updateValue('sharePublic', a)}
        />
        <SaveButton
          onClick={saveNote}
          disabled={
            !StepTenValue?.StepTen?.sharePublic ||
            StepTenValue?.StepTen?.savedNote ||
            !StepTenValue?.StepTen?.newGoal
          }
        >
          Create note
        </SaveButton>
      </Grid>
    </Grid>
  );
};
