import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { BeGreatMenteeStepOne } from './Steps/BeGreatMenteeStepOne';
import { BeGreatMenteeStepTwo } from './Steps/BeGreatMenteeStepTwo';
import { BeGreatMenteeStepThree } from './Steps/BeGreatMenteeStepThree';
import { BeGreatMenteeStepFour } from './Steps/BeGreatMenteeStepFour';
import { BeGreatMenteeStepFive } from './Steps/BeGreatMenteeStepFive';
import { BeGreatMenteeStepSix } from './Steps/BeGreatMenteeStepSix';
import { BeGreatMenteeStepSeven } from './Steps/BeGreatMenteeStepSeven';
import { BeGreatMenteeStepEight } from './Steps/BeGreatMenteeStepEight';
import { BeGreatMenteeStepNine } from './Steps/BeGreatMenteeStepNine';
import { BeGreatMenteeStepTen } from './Steps/BeGreatMenteeStepTen';
import { BeGreatMenteeStepEleven } from './Steps/BeGreatMenteeStepEleven';
import { BeGreatMenteeStepFourCorrect } from './Steps/BeGreatMenteeStepFourCorrect';
import { BeGreatMenteeStepFiveCorrect } from './Steps/BeGreatMenteeStepFiveCorrect';
import { BeGreatMenteeStepSixCorrect } from './Steps/BeGreatMenteeStepSixCorrect';
import { BeGreatMenteeStepSevenCorrect } from './Steps/BeGreatMenteeStepSevenCorrect';
import { BeGreatMenteeStepEightCorrect } from './Steps/BeGreatMenteeStepEightCorrect';
import { BeGreatMenteeStepTenCorrect } from './Steps/BeGreatMenteeStepTenCorrect';

export const BeGreatMenteeModuleMapper: IModuleMapper[] = [
  {
    name: 'BeGreatMenteeStepOne',
    component: <BeGreatMenteeStepOne />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    nextComponent: 'BeGreatMenteeStepTwo',
  },
  {
    name: 'BeGreatMenteeStepTwo',
    component: <BeGreatMenteeStepTwo />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'BeGreatMenteeStepOne',
    nextComponent: 'BeGreatMenteeStepThree',
  },
  {
    name: 'BeGreatMenteeStepThree',
    component: <BeGreatMenteeStepThree />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'BeGreatMenteeStepTwo',
    nextComponent: 'BeGreatMenteeStepFour',
  },
  {
    name: 'BeGreatMenteeStepFour',
    component: <BeGreatMenteeStepFour />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'BeGreatMenteeStepThree',
    correctComponent: 'BeGreatMenteeStepFourCorrect',
    incorrectComponent: 'BeGreatMenteeStepFourCorrect',
  },
  {
    name: 'BeGreatMenteeStepFourCorrect',
    component: <BeGreatMenteeStepFourCorrect />,
    iterateStepper: true,
    previousComponent: 'BeGreatMenteeStepFour',
    nextComponent: 'BeGreatMenteeStepFive',
  },
  {
    name: 'BeGreatMenteeStepFive',
    component: <BeGreatMenteeStepFive />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'BeGreatMenteeStepFour',
    correctComponent: 'BeGreatMenteeStepFiveCorrect',
    incorrectComponent: 'BeGreatMenteeStepFiveCorrect',
  },
  {
    name: 'BeGreatMenteeStepFiveCorrect',
    component: <BeGreatMenteeStepFiveCorrect />,
    iterateStepper: true,
    previousComponent: 'BeGreatMenteeStepFive',
    nextComponent: 'BeGreatMenteeStepSix',
  },
  {
    name: 'BeGreatMenteeStepSix',
    component: <BeGreatMenteeStepSix />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'BeGreatMenteeStepFive',
    correctComponent: 'BeGreatMenteeStepSixCorrect',
    incorrectComponent: 'BeGreatMenteeStepSixCorrect',
  },
  {
    name: 'BeGreatMenteeStepSixCorrect',
    component: <BeGreatMenteeStepSixCorrect />,
    iterateStepper: true,
    previousComponent: 'BeGreatMenteeStepSix',
    nextComponent: 'BeGreatMenteeStepSeven',
  },
  {
    name: 'BeGreatMenteeStepSeven',
    component: <BeGreatMenteeStepSeven />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'BeGreatMenteeStepSix',
    correctComponent: 'BeGreatMenteeStepSevenCorrect',
    incorrectComponent: 'BeGreatMenteeStepSevenCorrect',
  },
  {
    name: 'BeGreatMenteeStepSevenCorrect',
    component: <BeGreatMenteeStepSevenCorrect />,
    iterateStepper: true,
    previousComponent: 'BeGreatMenteeStepSeven',
    nextComponent: 'BeGreatMenteeStepEight',
  },
  {
    name: 'BeGreatMenteeStepEight',
    component: <BeGreatMenteeStepEight />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'BeGreatMenteeStepSeven',
    correctComponent: 'BeGreatMenteeStepEightCorrect',
    incorrectComponent: 'BeGreatMenteeStepEightCorrect',
  },
  {
    name: 'BeGreatMenteeStepEightCorrect',
    component: <BeGreatMenteeStepEightCorrect />,
    iterateStepper: true,
    previousComponent: 'BeGreatMenteeStepEight',
    nextComponent: 'BeGreatMenteeStepNine',
  },
  {
    name: 'BeGreatMenteeStepNine',
    component: <BeGreatMenteeStepNine />,
    contentScreen: true,
    iterateStepper: true,
    previousComponent: 'BeGreatMenteeStepEight',
    nextComponent: 'BeGreatMenteeStepTen',
  },
  {
    name: 'BeGreatMenteeStepTen',
    component: <BeGreatMenteeStepTen />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'BeGreatMenteeStepNine',
    correctComponent: 'BeGreatMenteeStepTenCorrect',
    incorrectComponent: 'BeGreatMenteeStepTenCorrect',
  },
  {
    name: 'BeGreatMenteeStepTenCorrect',
    component: <BeGreatMenteeStepTenCorrect />,
    iterateStepper: true,
    previousComponent: 'BeGreatMenteeStepTen',
    nextComponent: 'BeGreatMenteeStepEleven',
  },
  {
    name: 'BeGreatMenteeStepEleven',
    component: <BeGreatMenteeStepEleven />,
    iterateStepper: true,
    needsInteraction: true,
    submit: true,
    previousComponent: 'BeGreatMenteeStepTen',
  },
];
