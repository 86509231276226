import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { mobileTopNavHeight } from '../../consts/navHeights';
import { BrancherDivider } from './BrancherDivider';
import { FeaturePageLayout } from './FeaturePageLayout';
import { HeaderNav } from './HeaderNav';

export interface IPageLayout {
  pageTitle: string;
  backButtonRoute?: string;
  backButtonLabel?: string;
  nextButtonRoute?: string;
  nextButtonLabel?: string;
  titleDivider?: boolean;
  hasFeaturePageLayout?: boolean;
  alignVertical?: boolean;
}

const useStyles = makeStyles({
  layout: {
    minHeight: `calc(100vh - 50px)`,
    marginBottom: 30,
    alignContent: 'baseline',
    alignItems: 'center',
  },
  topNav: {
    maxHeight: mobileTopNavHeight,
    height: mobileTopNavHeight,
    paddingRight: 30,
    marginLeft: -10,
  },
});

// tslint:disable-next-line:max-line-length
export const PageLayout: React.FC<IPageLayout> = ({
  pageTitle,
  alignVertical = false,
  titleDivider = true,
  backButtonRoute,
  backButtonLabel,
  nextButtonRoute,
  nextButtonLabel,
  children,
  hasFeaturePageLayout = true,
}) => {
  const styles = useStyles();
  const [popoverAnchor, setPopoverAnchor] = React.useState<any>(null);

  const closeValidator = () => {
    if (Boolean(popoverAnchor)) {
      setPopoverAnchor(null);
    }
  };

  return (
    <Grid container justify="center" className={styles.layout} onClick={closeValidator}>
      <Grid item xs={12} container justify="flex-end" alignItems="center" className={styles.topNav}>
        <HeaderNav popoverAnchor={popoverAnchor} setPopoverAnchor={setPopoverAnchor} />
      </Grid>

      <Grid item xs={12}>
        <BrancherDivider marginBottom={50} />
      </Grid>

      {hasFeaturePageLayout ? (
        <FeaturePageLayout
          pageTitle={pageTitle}
          titleDivider={titleDivider}
          backButtonRoute={backButtonRoute}
          backButtonLabel={backButtonLabel}
          nextButtonRoute={nextButtonRoute}
          nextButtonLabel={nextButtonLabel}
        >
          {children}
        </FeaturePageLayout>
      ) : alignVertical ? (
        <Box position="absolute">
          <Grid container direction="column" alignItems="center" justify="center">
            {children}
          </Grid>
        </Box>
      ) : (
        children
      )}
    </Grid>
  );
};
