import * as React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { Text } from './Text';
import { Colors } from '../../consts/colors';

const useStyles = makeStyles({
  questionMarker: {
    borderRadius: '50%',
    border: `2px solid ${Colors.purple}`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

interface IBrancherQuestionMarker {
  onClick: (a?: any) => void;
}

export const BrancherQuestionMarker = (props: IBrancherQuestionMarker) => {
  const styles = useStyles();
  const { onClick } = props;

  return (
    <Box className={styles.questionMarker} onClick={onClick} display="inline-block">
      <Text variant="xs" color="purple" display="inline" fontWeight={800} padding={8}>
        ?
      </Text>
    </Box>
  );
};
