import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMenteeData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';
import { SallyAvatar } from '../../avatars/Sally';

export const BeGreatMenteeStepSeven = () => {
  const stepSevenValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentee.StepSeven,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepSevenCorrectVal = '0';
    dispatch(
      SaveBeGreatMenteeData({
        StepSeven: {
          selected: val,
          correct: StepSevenCorrectVal === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <SallyAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Sally responds...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          Sally responds, "I like it. That sounds like a specific and achievable ask of your
          manager. On a scale of 1 - 10, how close do you feel to achieving that goal right now? And
          how motivated are you to achieve it?"
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          You respond with, "I'm currently a 3 in regards to my progress but 9 in regards to my
          motivation."
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Sally responds with, "That's great. How can we use that motivation to further improve your
          progress? What would it take to move that 3 to a 4 or a 5?"
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          How do you respond?
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="I could have a conversation with my manager, explain how the lack of regular meetings are impacting me and propose a solution, asking if we could have a weekly 1:1."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="I could tell my manager how I feel and say if we don't have more regular 1:1s then I'll be at risk of leaving the company."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="I could talk to other colleagues in the office or my manager's manager about how I feel."
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepSevenValue"
          value={stepSevenValue?.selected}
          updateValue={updateValue}
          noQuestion
          exclusive
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
