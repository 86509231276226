import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../consts/colors';

const useStyles = makeStyles({
  fill: {
    fill: (props: { selected: boolean }) => (!props.selected ? Colors.white : Colors.purple),
  },
});

interface IMeetingsIcon {
  selected: boolean;
}

export const MeetingsIcon: React.FC<IMeetingsIcon> = ({ selected }) => {
  const styles = useStyles({ selected });
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 30">
      <g id="expanded">
        <path
          className={styles.fill}
          d="M21.2,30V25.27a8.53,8.53,0,0,1,.3-2.51,8.17,8.17,0,0,1,.75-2c.13-.22.3-.47.51-.76A15.5,15.5,0,0,0,25,15.67a10.56,10.56,0,0,0-.38-8.24A11.57,11.57,0,0,0,20.8,3.19a5.93,5.93,0,0,0-.71-.44,5.18,5.18,0,0,0-.82-.4A6.54,6.54,0,0,0,18.4,2a12.13,12.13,0,0,0-8.68.56c-1.3.6-4.39,2.27-5,4.95A7.14,7.14,0,0,0,4.56,9.3a7.63,7.63,0,0,1-.78,3.85c-.78,1.56-1.14,2.2-1.16,2.22-.23.4-.42.72-.59,1a4.72,4.72,0,0,0-.5.91c.06.1.53.44,1.93.57a.74.74,0,0,1,.68.66L4.48,22a2.72,2.72,0,0,0,.64,1c1.35,1.29,3.8.63,3.82.62a.79.79,0,0,1,.76.19c1.41,1.35,1.2,3.07,1,4.59A14.75,14.75,0,0,0,10.59,30H9.05a14.57,14.57,0,0,1,.14-1.75c.15-1.2.28-2.25-.31-3.08A5.55,5.55,0,0,1,4,24.05,4,4,0,0,1,3,22.4l0-.17-.29-3C1.21,19,.33,18.47.07,17.69s.1-1.2.64-2.07l.57-1S1.64,14,2.4,12.52A6.3,6.3,0,0,0,3,9.3,9.06,9.06,0,0,1,3.2,7.21C4,4,7.19,2.1,9.05,1.24A13.67,13.67,0,0,1,20.11,1.1a13.62,13.62,0,0,1,10.83.23c1.87.86,5.12,2.77,5.86,6A8.49,8.49,0,0,1,37,9.39a6.34,6.34,0,0,0,.62,3.21c.77,1.53,1.13,2.14,1.13,2.15.22.38.4.7.57,1,.53.87.85,1.4.63,2.07s-1.13,1.3-2.6,1.52l-.28,3a.85.85,0,0,1,0,.17,4.11,4.11,0,0,1-1,1.65,5.64,5.64,0,0,1-4.83,1.12c-.61.81-.47,1.88-.32,3.08A15.26,15.26,0,0,1,31,30"
        />
      </g>
    </svg>
  );
};
