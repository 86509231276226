import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { CharlieAvatar } from '../../avatars/Charlie';

export const BeGreatMentorStepFourCorrect = () => {
  const stepFourValue = useSelector((state: IStoreTypes) => state.training.beGreatMentor.StepFour);
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepFourValue.correct} incorrect={!stepFourValue.correct}>
          <Text variant="md" fontWeight={700}>
            {stepFourValue.correct ? 'Correct' : 'Not quite'}. The correct answer is A, C and D.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          <Text variant="sm">
            Depending on your style, any of A, C and D could be used. Explaining the reason behind
            your direction makes it easier for people to understand your perspective.
          </Text>
          <Text variant="sm" marginTop={20}>
            Why B is incorrect: Although Google is a great resource, when your mentee is feeling the
            pressure, they may be disappointed if you refer them to Google.
          </Text>
          <Text variant="sm" marginTop={20}>
            Why E is incorrect: This is a coaching question rather than a 'directive' question.
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
};
