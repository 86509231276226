import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { BrancherDialog } from '../General/BrancherDialog';
import { Text } from '../General/Text';
import { RejectButton, AcceptButton } from '../InputFields/BrancherButton';

interface IConfirmationNavigationDialog {
  handleAccept: () => void;
  handleNotYet: () => void;
  open: boolean;
}

export const ConfirmationNavigationDialog: React.FC<IConfirmationNavigationDialog> = (props) => {
  const { handleAccept, handleNotYet, open } = props;

  return (
    <BrancherDialog
      setClose={handleNotYet}
      title="Are you sure you want to leave editing?"
      labelledBy="close_without_saving"
      open={open}
      fitLargeScreen
    >
      <Grid container justify="center">
        <Grid item>
          <Text variant="sm" color="secondaryGrey" marginBottom={25}>
            You have unsaved changes.
          </Text>
        </Grid>
      </Grid>
      <Grid item container justify="space-between" xs={12}>
        <Grid item>
          <RejectButton onClick={handleNotYet}>Not yet</RejectButton>
        </Grid>
        <Grid item>
          <AcceptButton onClick={handleAccept}>Yes</AcceptButton>
        </Grid>
      </Grid>
    </BrancherDialog>
  );
};
