import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
  BrancherDispatch,
  UtilDeleteUserSharedNotes,
  UtilSaveUserPrivateNotesData,
  UtilSaveUserSharedNotesData,
} from '../../store/actions';
import { CreateButton, DeleteButton } from '../../Components/InputFields/BrancherButton';
import { INote } from '../../store/reducers/PrivateNotesReducer';
import { LexicalNote } from './LexicalNote';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';

const useStyles = makeStyles({
  note: {
    paddingLeft: 30,
    paddingRight: 30,
    overflowY: 'auto',
    minHeight: 'calc(100vh - 60px)',
    overflowX: 'hidden',
    width: '100%',
  },
  actions: {
    marginBottom: 30,
    marginTop: 40,
  },
});

export enum ENoteContexts {
  SHARED = 'shared',
  PRIVATE = 'private',
}

export interface IRichTextNotes {
  note: INote;
  resetNote: (note: Partial<INote>) => void;
  contents: any;
  title: string;
  setContents: (contents: any) => void;
  setTitle: (title: string) => void;
  context: 'shared' | 'private';
}

export const RichTextNote: React.FC<IRichTextNotes> = (props) => {
  const { note, resetNote, setContents, setTitle, title, contents, context } = props;
  const [updatingNote, setUpdatingNote] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const styles = useStyles();

  React.useEffect(() => {
    setContents(note.contents);
    setTitle(note.title);
  }, [note]);

  const deleteNote = () => {
    setUpdatingNote(true);
    const contextDelete =
      ENoteContexts.SHARED === context ? UtilDeleteUserSharedNotes : UtilDeleteUserSharedNotes;
    BrancherDispatch(
      dispatch,
      contextDelete(note.noteId, (resp) => {
        setUpdatingNote(false);
        resetNote({ noteId: '' });
        setContents('');
        setTitle('');
      }),
    );
  };

  const saveNote = () => {
    setUpdatingNote(true);
    const contextSave =
      ENoteContexts.SHARED === context ? UtilSaveUserSharedNotesData : UtilSaveUserPrivateNotesData;
    BrancherDispatch(
      dispatch,
      contextSave({ contents, title, lastModified: Date.now(), noteId: note?.noteId }, (resp) => {
        setUpdatingNote(false);
        resetNote(resp.data);
      }),
    );
  };

  return (
    <Grid container className={styles.note} direction="column" justify="space-between">
      <Grid container item>
        <Grid item xs={12}>
          <BrancherTextField
            value={title}
            updateValue={setTitle}
            maxChars={150}
            multiline
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <LexicalNote contents={note.contents} setContents={setContents} noteId={note.noteId} />
        </Grid>
      </Grid>
      <Grid item container justify="space-between" className={styles.actions}>
        <Grid item>
          {note?.noteId && (
            <DeleteButton onClick={deleteNote} disabled={updatingNote} loading={updatingNote} />
          )}
        </Grid>
        <Grid item>
          <CreateButton onClick={saveNote} disabled={updatingNote} loading={updatingNote} />
        </Grid>
      </Grid>
    </Grid>
  );
};
