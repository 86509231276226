import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeyondProgramMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { BrancherDialog } from '../../../../../Components/General/BrancherDialog';
import { TacticalRelationshipClosureMentor } from '../TacticalRelationshipClosureMentor';

export const BeyondProgramMentorStepFiveStop = () => {
  const stepFiveValue = useSelector((state: IStoreTypes) => state.training?.beyondProgramMentor);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const sessionSaveFunction = SaveBeyondProgramMentorData;

  const updateValue = (val: string) => {
    const StepFiveCorrectVal = '4';
    dispatch(
      sessionSaveFunction({
        StepFive: {
          selected: val,
          correct: StepFiveCorrectVal === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <BrancherDialog
        setClose={() => setDialogOpen(false)}
        open={dialogOpen}
        labelledBy="Tactical relationship closure"
      >
        <TacticalRelationshipClosureMentor />
      </BrancherDialog>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Quiz:
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Below are some scenarios for ending a mentoring relationship.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          Thinking about the{' '}
        </Text>
        <BrancherLinkText
          variant="md"
          underline="always"
          fontWeight={700}
          display="inline"
          onClick={() => setDialogOpen(true)}
        >
          ‘7 Tactics for Successful Relationship Closure'
        </BrancherLinkText>
        <Text variant="md" fontWeight={700} display="inline">
          , which of the following is not recommended?
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="Reflect on everything your mentee has achieved. Express gratitude to them. Talk about how you have enjoyed helping them grow personally and professionally."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="Acknowledge any relevant goals your mentee has achieved. Explain what you have learnt about yourself throughout this program."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="Explain how the program is ending and how you see the relationship evolving/ending and why."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="D."
          optionText="Suggest a focus for the remainder of your final meeting. Discuss next steps for your mentee’s career."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="E."
          optionText="Attempting to wrap up the relationship but then staying in the relationship and continuing to meet monthly out of obligation because they really need extra support."
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepFiveValue"
          value={stepFiveValue?.StepFive?.selected}
          updateValue={updateValue}
          noQuestion
          thirdWidth
          exclusive
          options={MakeOptions(['A', 'B', 'C', 'D', 'E'])}
        />
      </Grid>
    </Grid>
  );
};
