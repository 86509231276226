import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';

export const MoDGoalSettingMentorStepSixCorrect = () => {
  const stepSixValue = useSelector(
    (state: IStoreTypes) => state.training.modGoalSettingMentor?.StepSix,
  );

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepSixValue.correct} incorrect={!stepSixValue.correct}>
          <Text variant="md" fontWeight={700}>
            The correct answer is A, B, C, D and E.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="md">This goal provides a great example of the SMART principles.</Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          It is{' '}
        </Text>
        <Text variant="md" fontWeight={600} display="inline">
          specific
        </Text>
        <Text variant="md" display="inline">
          {' '}
          because it identifies exactly what the individual will submit (their CV and cover letter).
          It is{' '}
        </Text>
        <Text variant="md" fontWeight={600} display="inline">
          measurable
        </Text>
        <Text variant="md" display="inline">
          {' '}
          because the individual will know if they have submitted the application or not. Submitting
          an application is more{' '}
        </Text>
        <Text variant="md" fontWeight={600} display="inline">
          attainable
        </Text>
        <Text variant="md" display="inline">
          {' '}
          than relying on something such as a job interview (which we have little control over); and
          it is{' '}
        </Text>
        <Text variant="md" fontWeight={600} display="inline">
          timely
        </Text>
        <Text variant="md" display="inline">
          {' '}
          as there is a set timeline for this to be achieved. We can also assume this goal is{' '}
        </Text>
        <Text variant="md" fontWeight={600} display="inline">
          relevant
        </Text>
        <Text variant="md" display="inline">
          {' '}
          given their overall goal is to get a new job.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Another good thing about this goal is that it is focused on the ‘behaviour’ (i.e.
          submitting the application), rather than the ‘outcome’ (i.e. receiving a job offer). This
          reduces the likelihood of external factors impacting on the success of the goal.
        </Text>
      </Grid>
    </Grid>
  );
};
