import { Colors } from '../consts/colors';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const breakpoints = createMuiTheme().breakpoints;

export const MuiToggleButton = {
  root: {
    display: 'flex',
    padding: 20,
    minHeight: 48,
    '&.MuiButtonBase-root': {
      borderRadius: '0px !important',
      border: 'none',
      boxShadow: 'none',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    color: Colors.black,
    height: 'auto',
    margin: 0,
    backgroundColor: Colors.backgroundDarkGrey,
    boxShadow:
      '0px 2px 5px -1px rgba(0,0,0,0.07),0px 4px 5px 0px rgba(0,0,0,0.07),0px 1px 10px 0px rgba(0,0,0,0.07)',
    '&:hover': {
      backgroundColor: Colors.backgroundGrey,
    },
    '&$selected': {
      backgroundColor: Colors.backgroundDarkPurple,
      color: Colors.purple,
      '&:hover': {
        backgroundColor: Colors.backgroundDarkPurple,
        color: Colors.purple,
      },
    },
    [breakpoints.down('sm')]: {
      borderRadius: `10px !important`,
    },
    [breakpoints.up('md')]: {
      borderRadius: `20px !important`,
    },
  },
};
