import * as React from 'react';
import { BrancherLinkText } from './BrancherLinkText';
import { CreateMentoringGuideLink } from '../../PostMatching/Training/Modules/helpers/LinkBuilder';
import { IText } from './Text';

interface IMentoringGuideLink extends Pick<IText, 'variant'> {}

export const MentoringGuideLink = (props: IMentoringGuideLink) => {
  const mentoringGuideLink = CreateMentoringGuideLink();
  return (
    <BrancherLinkText
      underline="always"
      fontWeight={600}
      variant="md"
      href={mentoringGuideLink}
      display="inline"
      target="_blank"
      {...props}
    >
      Mentoring Guide
    </BrancherLinkText>
  );
};
