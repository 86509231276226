import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { SaveMoDSituationalMentorshipMentorData } from '../../../../../store/actions';
import { GetTrainingResourceLink } from '../../../../../utils/LinkUtils';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';

export const MoDSituationalMentorshipMentorStepFive = () => {
  const modName = EMoDTrainingModules.MOD_SITUATIONAL_MENTORSHIP_MENTOR;
  const dispatch = useDispatch();

  const updateValue = () => {
    dispatch(
      SaveMoDSituationalMentorshipMentorData({
        StepFive: {
          interacted: true,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" display="inline">
          Taking a{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700}>
          coaching approach
        </Text>
        <Text variant="md" display="inline">
          {' '}
          to mentoring empowers your mentee to find real-life solutions to real-life problems.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          In its simplest form, coaching involves{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700}>
          asking the right questions
        </Text>
        <Text variant="md" display="inline">
          {' '}
          to empower your mentee to come to their own solution. So how do you know what questions to
          ask?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          It can help to think about a coaching 'model' such as the{' '}
        </Text>
        <BrancherLinkText
          display="inline"
          variant="md"
          target="_blank"
          onClick={updateValue}
          underline="always"
          fontWeight={700}
          href={GetTrainingResourceLink(modName, 'GROWTH_framework.pdf')}
        >
          GROWTH framework
        </BrancherLinkText>
        <Text variant="md" display="inline">
          {' '}
          during your mentoring conversations.
        </Text>
      </Grid>
      <Grid item container justify="center" xs={11} sm={12}>
        <BrancherTrainingImage
          src={GetTrainingResourceLink(modName, 'growth_graphic.png')}
          alt="growth"
        />
      </Grid>
    </Grid>
  );
};
