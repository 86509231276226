import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { SuggestionCard } from '../../SuggestionCard';

export const BeGreatMentorStepFiveResult = (props: { correct: boolean }) => {
  const { correct } = props;
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          {correct ? 'Correct' : 'Not quite'}.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Being directive has its advantages however in this situation it is not preferred because:
        </Text>
      </Grid>
      <Grid item>
        <BrancherList
          variant="md"
          listItems={[
            'You have time',
            'There is little to no pressure',
            'The mentee is capable of solving the problem, they just need support',
          ]}
        />
      </Grid>
      <Grid item>
        <Text variant="md">
          The aim is to develop Charlie's problem solving skills so they can deal with similar
          situations autonomously in the future.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You could ask questions like:
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard>"That sounds frustrating, what have you tried so far?"</SuggestionCard>
      </Grid>
      <Grid item>
        <SuggestionCard>
          "So it sounds like you've already tried a few different approaches to resolving this
          yourself, what have you tried?"
        </SuggestionCard>
      </Grid>
      <Grid item>
        <SuggestionCard>"What's worked and what hasn't worked?</SuggestionCard>
      </Grid>
      <Grid item>
        <SuggestionCard>"Why do you think those approaches haven't worked?"</SuggestionCard>
      </Grid>
      <Grid item>
        <SuggestionCard>
          "What would motivate this person to be more engaged with you?"
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
