import * as React from 'react';
import { EMoDTrainingModules } from '../../../../store/reducerTypes/TrainingReducer.types';
import { ModuleWrapper } from '../ModuleWrapper';
import { MoDBeGreatMenteeModuleMapper } from './MoDBeGreatMenteeModuleMapper';

export const MoDBeGreatMentee = () => (
  <ModuleWrapper
    moduleMap={MoDBeGreatMenteeModuleMapper}
    moduleName={EMoDTrainingModules.MOD_BE_GREAT_MENTEE}
    title="How to be a great mentee"
  />
);
