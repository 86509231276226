import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMakeTheMostMentee, SaveMakeTheMostMentor } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { BrancherButton } from '../../../../../Components/InputFields/BrancherButton';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const MakeTheMostMenteeStepSix = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const sessionStore =
    position === ProgramPositions.mentee ? 'makeTheMostMentee' : 'makeTheMostMentor';
  const stepSixValue = useSelector((state: IStoreTypes) => state.training[sessionStore]?.StepSix);
  const dispatch = useDispatch();
  const [checkAnswer, setCheckAnswer] = React.useState(false);

  const toggleCheckAnswer = () => {
    setCheckAnswer(!checkAnswer);
  };

  const updateValue = (val: string[]) => {
    const sessionSaveFunction =
      position === ProgramPositions.mentee ? SaveMakeTheMostMentee : SaveMakeTheMostMentor;
    const correctAnswers = ['0', '1', '2', '3', '4'];
    dispatch(
      sessionSaveFunction({
        StepSix: {
          ...stepSixValue,
          goalTwo: val,
          correct:
            val.includes(correctAnswers[0]) &&
            val.includes(correctAnswers[1]) &&
            val.includes(correctAnswers[2]) &&
            val.includes(correctAnswers[3]) &&
            val.includes(correctAnswers[4]),
          interacted: val.length > 0,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Goal #2:
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          To help me get a new job, I will submit my CV and a cover letter for at least one job
          application in the next 2 weeks.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Is this goal...
        </Text>
      </Grid>
      <Grid item container direction="column">
        <Grid item>
          <Text variant="md" fontWeight={700} display="inline">
            A.{' '}
          </Text>
          <Text variant="md" display="inline">
            Specific
          </Text>
        </Grid>
        <Grid item>
          <Text variant="md" fontWeight={700} display="inline">
            B.{' '}
          </Text>
          <Text variant="md" display="inline">
            Measurable
          </Text>
        </Grid>
        <Grid item>
          <Text variant="md" fontWeight={700} display="inline">
            C.{' '}
          </Text>
          <Text variant="md" display="inline">
            Attainable
          </Text>
        </Grid>
        <Grid item>
          <Text variant="md" fontWeight={700} display="inline">
            D.{' '}
          </Text>
          <Text variant="md" display="inline">
            Relevant
          </Text>
        </Grid>
        <Grid item>
          <Text variant="md" fontWeight={700} display="inline">
            E.{' '}
          </Text>
          <Text variant="md" display="inline">
            Timely
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You can choose more than one option.
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          noQuestion
          question=""
          name="goalTwo"
          value={stepSixValue?.goalTwo}
          options={MakeOptions(['A', 'B', 'C', 'D', 'E'])}
          updateValue={(a: string[]) => updateValue(a)}
          thirdWidth
        />
      </Grid>
      <Box display="flex" alignSelf="flex-end" maxWidth="fit-content">
        <BrancherButton
          color="primary"
          onClick={toggleCheckAnswer}
          disabled={!(stepSixValue?.goalTwo?.length > 0)}
        >
          Check answer
        </BrancherButton>
      </Box>
      {checkAnswer && (
        <Grid item>
          <Text variant="sm" fontWeight={700} marginBottom={20}>
            The correct answer is A, B, C, D and E
          </Text>
          <Text variant="sm" display="inline">
            This goal provides a great example of the SMART principles. It is{' '}
          </Text>
          <Text variant="sm" fontWeight={700} display="inline" color="purple">
            specific
          </Text>
          <Text variant="sm" display="inline">
            , in that it identifies exactly what the individual will submit (their CV and cover
            letter). It is{' '}
          </Text>
          <Text variant="sm" fontWeight={700} display="inline" color="purple">
            measurable
          </Text>
          <Text variant="sm" display="inline">
            , in that the individual will know if they have submitted the application or not.
            Submitting an application is more{' '}
          </Text>
          <Text variant="sm" fontWeight={700} display="inline" color="purple">
            attainable
          </Text>
          <Text variant="sm" display="inline">
            {' '}
            than relying on something such as a job interview (which we have little control over);
            and it is{' '}
          </Text>
          <Text variant="sm" fontWeight={700} display="inline" color="purple">
            timely
          </Text>
          <Text variant="sm" display="inline">
            {' '}
            as there is a set timeline for this to be achieved. We can also assume this goal is{' '}
          </Text>
          <Text variant="sm" fontWeight={700} display="inline" color="purple">
            relevant
          </Text>
          <Text variant="sm" display="inline">
            {' '}
            given their overall goal is to get a new job.
          </Text>
          <Text variant="sm" marginTop={10} marginBottom={25}>
            Another good thing about this goal is that it is focused on the ‘behaviour’ (i.e.
            submitting the application), rather than the ‘outcome’ (i.e. receiving a job offer).
            This reduces the likelihood of external factors impacting on the success of the goal.
          </Text>
        </Grid>
      )}
    </Grid>
  );
};
