import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { CharlieAvatar } from '../../avatars/Charlie';

export const BeGreatMentorStepEightCorrect = () => {
  const stepEightValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentor.StepEight,
  );
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepEightValue.correct} incorrect={!stepEightValue.correct}>
          <Text variant="md" fontWeight={700}>
            {stepEightValue.correct ? 'Correct' : 'Not quite'}.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          {stepEightValue.correct ? (
            <Grid item>
              <Text variant="sm" display="inline">
                You are asking additional questions to understand their{' '}
              </Text>
              <Text variant="sm" display="inline" color="purple" fontWeight={700}>
                Options
              </Text>
              <Text variant="sm" display="inline">
                {' '}
                (the third step of the GROWTH model).
              </Text>
            </Grid>
          ) : (
            <Grid item>
              <Text variant="sm">
                You are jumping ahead to Tactics before you have explored different Options. As a
                result, you may be making an incorrect assumption. Charlie might not want to attend
                University to do this further intensive study, there may be another alternative
                option that you haven't explored yet.
              </Text>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
