import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { MakeTheMostMenteeStepOne } from './Steps/MakeTheMostMenteeStepOne';
import { MakeTheMostMenteeStepTwo } from './Steps/MakeTheMostMenteeStepTwo';
import { MakeTheMostMenteeStepThree } from './Steps/MakeTheMostMenteeStepThree';
import { MakeTheMostMenteeStepFour } from './Steps/MakeTheMostMenteeStepFour';
import { MakeTheMostMenteeStepFive } from './Steps/MakeTheMostMenteeStepFive';
import { MakeTheMostMenteeStepSix } from './Steps/MakeTheMostMenteeStepSix';
import { MakeTheMostMenteeStepSeven } from './Steps/MakeTheMostMenteeStepSeven';
import { MakeTheMostMenteeStepEight } from './Steps/MakeTheMostMenteeStepEight';
import { MakeTheMostMenteeStepNine } from './Steps/MakeTheMostMenteeStepNine';
import { MakeTheMostMenteeStepTen } from './Steps/MakeTheMostMenteeStepTen';
import { MakeTheMostMenteeStepEleven } from './Steps/MakeTheMostMenteeStepEleven';
import { MakeTheMostMenteeStepTwelve } from './Steps/MakeTheMostMenteeStepTwelve';
import { MakeTheMostMenteeStepThirteen } from './Steps/MakeTheMostMenteeStepThirteen';

export const MakeTheMostMenteeModuleMapper: IModuleMapper[] = [
  {
    name: 'MakeTheMostMenteeStepOne',
    component: <MakeTheMostMenteeStepOne />,
    iterateStepper: true,
    needsInteraction: true,
    nextComponent: 'MakeTheMostMenteeStepTwo',
  },
  {
    name: 'MakeTheMostMenteeStepTwo',
    component: <MakeTheMostMenteeStepTwo />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MakeTheMostMenteeStepOne',
    nextComponent: 'MakeTheMostMenteeStepThree',
  },
  {
    name: 'MakeTheMostMenteeStepThree',
    component: <MakeTheMostMenteeStepThree />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MakeTheMostMenteeStepTwo',
    nextComponent: 'MakeTheMostMenteeStepFour',
  },
  {
    name: 'MakeTheMostMenteeStepFour',
    component: <MakeTheMostMenteeStepFour />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MakeTheMostMenteeStepThree',
    nextComponent: 'MakeTheMostMenteeStepFive',
  },
  {
    name: 'MakeTheMostMenteeStepFive',
    component: <MakeTheMostMenteeStepFive />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MakeTheMostMenteeStepFour',
    nextComponent: 'MakeTheMostMenteeStepSix',
  },
  {
    name: 'MakeTheMostMenteeStepSix',
    component: <MakeTheMostMenteeStepSix />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MakeTheMostMenteeStepFive',
    nextComponent: 'MakeTheMostMenteeStepSeven',
  },
  {
    name: 'MakeTheMostMenteeStepSeven',
    component: <MakeTheMostMenteeStepSeven />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MakeTheMostMenteeStepSix',
    nextComponent: 'MakeTheMostMenteeStepEight',
  },
  {
    name: 'MakeTheMostMenteeStepEight',
    component: <MakeTheMostMenteeStepEight />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MakeTheMostMenteeStepSeven',
    nextComponent: 'MakeTheMostMenteeStepNine',
  },
  {
    name: 'MakeTheMostMenteeStepNine',
    component: <MakeTheMostMenteeStepNine />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MakeTheMostMenteeStepEight',
    nextComponent: 'MakeTheMostMenteeStepTen',
  },
  {
    name: 'MakeTheMostMenteeStepTen',
    component: <MakeTheMostMenteeStepTen />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MakeTheMostMenteeStepNine',
    nextComponent: 'MakeTheMostMenteeStepEleven',
  },
  {
    name: 'MakeTheMostMenteeStepEleven',
    component: <MakeTheMostMenteeStepEleven />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MakeTheMostMenteeStepTen',
    nextComponent: 'MakeTheMostMenteeStepTwelve',
  },
  {
    name: 'MakeTheMostMenteeStepTwelve',
    component: <MakeTheMostMenteeStepTwelve />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MakeTheMostMenteeStepEleven',
    nextComponent: 'MakeTheMostMenteeStepThirteen',
  },
  {
    name: 'MakeTheMostMenteeStepThirteen',
    component: <MakeTheMostMenteeStepThirteen />,
    iterateStepper: true,
    needsInteraction: true,
    submit: true,
    previousComponent: 'MakeTheMostMenteeStepTwelve',
  },
];
