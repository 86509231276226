import * as React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../Components/General/Text';
import { BrancherSegmentedControlGroup } from '../../Components/InputFields/BrancherSegmentedControl';
import { Colors } from '../../consts/colors';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { BrancherDispatch, UtilGetGoals, UtilGetPartnerGoals } from '../../store/actions';
import { EGoalsBoardColumns, EGoalsBoardColumnsHeadings } from '../../store/reducers/GoalsReducer';
import { IStoreTypes } from '../../store/storeTypes';
import { GoalsSectionStatusCard } from './GoalsSectionStatusCard';

const useStyles = makeStyles({
  goal: {
    height: 'fit-content',
    maxHeight: 'fit-content',
    minHeight: 'fit-content',
    marginBottom: 25,
  },
  scrollable: {
    paddingLeft: 5,
    paddingRight: 5,
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: `calc(100vh - 300px)`,
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      background: Colors.backgroundDarkPurple,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: Colors.purple,
      borderRadius: 6,
      border: `2px solid transparent`,
    },
  },
});

interface IGoalsSection {
  onlyInGroup?: boolean;
}

export const GoalsSection: React.FC<IGoalsSection> = ({ onlyInGroup }) => {
  const goalItems = useSelector((state: IStoreTypes) => state?.goals);
  const sessionPosition = useSelector((state: IStoreTypes) => state?.user.sessionPosition);
  const partnerData = useSelector((state: IStoreTypes) => state?.user.sessionPair);
  const partnerRoleId = partnerData?.roleId;
  const partnerName = partnerData?.name;
  const [hasGoals, setHasGoals] = React.useState<boolean>(false);
  const [goalView, setGoalView] = React.useState<ProgramPositions>(sessionPosition);
  const dispatch = useDispatch();
  const styles = useStyles();

  React.useEffect(() => {
    setHasGoals(false);
    BrancherDispatch(
      dispatch,
      UtilGetGoals(() => {
        getPartnerGoals();
        setHasGoals(true);
      }),
    );
  }, []);

  React.useEffect(() => {
    getPartnerGoals();
  }, [partnerRoleId]);

  const getPartnerGoals = () => {
    setHasGoals(false);
    BrancherDispatch(
      dispatch,
      UtilGetPartnerGoals(() => {
        setHasGoals(true);
      }),
    );
  };

  const columns: Array<{ id: EGoalsBoardColumns; title: EGoalsBoardColumnsHeadings }> = [
    {
      id: EGoalsBoardColumns.BACKLOG,
      title: EGoalsBoardColumnsHeadings.BACKLOG,
    },
    {
      id: EGoalsBoardColumns.IN_PROGRESS,
      title: EGoalsBoardColumnsHeadings.IN_PROGRESS,
    },
    {
      id: EGoalsBoardColumns.COMPLETED,
      title: EGoalsBoardColumnsHeadings.COMPLETED,
    },
  ];

  const getAllGoalItems = (): Array<{
    goal: string;
    id: string;
    title: EGoalsBoardColumnsHeadings;
  }> => {
    let goals = [];
    const selectedGoalItems = goalView === sessionPosition ? goalItems : goalItems?.partnerGoals;
    columns.forEach((column) =>
      selectedGoalItems?.[column.id].forEach((item) => {
        goals.push({
          goal: item.goal,
          title: column.title,
          id: item.id,
        });
      }),
    );
    return goals;
  };

  const allGoalItems = getAllGoalItems();
  const slicedGoalItems = allGoalItems.slice(0, 4);
  let goalControlOptions = [{ label: 'Your goals', value: sessionPosition }];
  if (!onlyInGroup) {
    goalControlOptions.push({
      label: `${partnerName}'s goals`,
      value:
        sessionPosition === ProgramPositions.mentor
          ? ProgramPositions.mentee
          : ProgramPositions.mentor,
    });
  }

  return (
    <Grid container className={styles.scrollable}>
      <BrancherSegmentedControlGroup
        updateValue={(a: ProgramPositions) => setGoalView(a)}
        value={goalView}
        options={goalControlOptions}
        fullWidth
      />
      {hasGoals ? (
        allGoalItems?.length > 0 ? (
          slicedGoalItems.map((item, index) => (
            <Box width="100%" key={item.id}>
              <GoalsSectionStatusCard status={item.title} goal={item.goal} />
              {index === slicedGoalItems.length - 1 && allGoalItems.length > 5 && (
                <Text variant="xs">See the rest of your goals by clicking `View all` above.</Text>
              )}
            </Box>
          ))
        ) : (
          <Text variant="sm" fontWeight={600} marginTop={30} marginLeft={10}>
            {goalView === sessionPosition ? 'You have' : `${partnerName} has`} no goals yet.
          </Text>
        )
      ) : (
        <CircularProgress color="secondary" size={64} />
      )}
    </Grid>
  );
};
