import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMakeTheMostMentee, SaveMakeTheMostMentor } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { LargeButton } from '../../../../../Components/InputFields/BrancherButton';
import { dialogValues, KolbModelInfo } from './KolbModelInfo';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const MakeTheMostMenteeStepOne = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const trainingGuideName =
    position === ProgramPositions.mentee ? 'makeTheMostMentee' : 'makeTheMostMentor';
  const KolbModel = `https://media.brancher.com.au/training/${trainingGuideName}/kolbModel.png`;
  const dispatch = useDispatch();
  const [currentOpenDialog, setCurrentOpenDialog] = React.useState('');
  const [seen, addSeen] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (
      seen.includes(dialogValues[0]) &&
      seen.includes(dialogValues[1]) &&
      seen.includes(dialogValues[2]) &&
      seen.includes(dialogValues[3])
    ) {
      updateValue();
    }
  }, [seen]);

  const setDialog = (component: string) => {
    setCurrentOpenDialog(component);
    addSeen([...seen, component]);
    setOpen(true);
  };

  const updateValue = () => {
    const sessionSaveFunction =
      position === ProgramPositions.mentee ? SaveMakeTheMostMentee : SaveMakeTheMostMentor;
    dispatch(
      sessionSaveFunction({
        StepOne: {
          interacted: true,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md">
          Research shows that reflection can make you happier, healthier and even improve your
          cognitive abilities.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" marginBottom={40}>
          To enhance your learning across the Brancher program, we will be using Kolb’s Model of
          Adult Learning.
        </Text>
      </Grid>
      <Grid item container justify="center">
        <img src={KolbModel} width="60%" height="60%" alt="kolb-model" />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700} marginTop={40} marginBottom={30} align="center">
          Click on each component of the model below to learn more about it!
        </Text>
      </Grid>
      <Grid item container justify="space-around" spacing={2}>
        <Grid item xs={5}>
          <LargeButton onClick={() => setDialog(dialogValues[0])} fullWidth>
            Experience
          </LargeButton>
        </Grid>
        <Grid item xs={5}>
          <LargeButton onClick={() => setDialog(dialogValues[1])} fullWidth>
            Reflect
          </LargeButton>
        </Grid>
        <Grid item xs={5}>
          <LargeButton onClick={() => setDialog(dialogValues[2])} fullWidth>
            Evaluate
          </LargeButton>
        </Grid>
        <Grid item xs={5}>
          <LargeButton onClick={() => setDialog(dialogValues[3])} fullWidth>
            Plan
          </LargeButton>
        </Grid>
      </Grid>
      <KolbModelInfo controlDialog={setOpen} open={open} component={currentOpenDialog} />
    </Grid>
  );
};
