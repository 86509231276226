import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { MoDGoalSettingMentorStepEight } from './Steps/MoDGoalSettingMentorStepEight';
import { MoDGoalSettingMentorStepEleven } from './Steps/MoDGoalSettingMentorStepEleven';
import { MoDGoalSettingMentorStepFive } from './Steps/MoDGoalSettingMentorStepFive';
import { MoDGoalSettingMentorStepFiveCorrect } from './Steps/MoDGoalSettingMentorStepFiveCorrect';
import { MoDGoalSettingMentorStepFour } from './Steps/MoDGoalSettingMentorStepFour';
import { MoDGoalSettingMentorStepNine } from './Steps/MoDGoalSettingMentorStepNine';
import { MoDGoalSettingMentorStepOne } from './Steps/MoDGoalSettingMentorStepOne';
import { MoDGoalSettingMentorStepSeven } from './Steps/MoDGoalSettingMentorStepSeven';
import { MoDGoalSettingMentorStepSevenCorrect } from './Steps/MoDGoalSettingMentorStepSevenCorrect';
import { MoDGoalSettingMentorStepSix } from './Steps/MoDGoalSettingMentorStepSix';
import { MoDGoalSettingMentorStepSixCorrect } from './Steps/MoDGoalSettingMentorStepSixCorrect';
import { MoDGoalSettingMentorStepTen } from './Steps/MoDGoalSettingMentorStepTen';
import { MoDGoalSettingMentorStepThree } from './Steps/MoDGoalSettingMentorStepThree';
import { MoDGoalSettingMentorStepTwo } from './Steps/MoDGoalSettingMentorStepTwo';
import { MoDGoalSettingMentorStepTwoCorrect } from './Steps/MoDGoalSettingMentorStepTwoCorrect';

export const MoDGoalSettingMentorModuleMapper: IModuleMapper[] = [
  {
    name: 'MoDGoalSettingMentorStepOne',
    component: <MoDGoalSettingMentorStepOne />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    nextComponent: 'MoDGoalSettingMentorStepTwo',
  },
  {
    name: 'MoDGoalSettingMentorStepTwo',
    component: <MoDGoalSettingMentorStepTwo />,
    iterateStepper: true,
    hasQuestion: true,
    changeFlowStep: true,
    previousComponent: 'MoDGoalSettingMentorStepOne',
    correctComponent: 'MoDGoalSettingMentorStepTwoCorrect',
    incorrectComponent: 'MoDGoalSettingMentorStepThree',
  },
  {
    name: 'MoDGoalSettingMentorStepTwoCorrect',
    component: <MoDGoalSettingMentorStepTwoCorrect />,
    iterateStepper: false,
    needsInteraction: false,
    noValidation: true,
    jumpToStep: 'MoDGoalSettingMentorStepEight',
    overrideAnsweredSteps: 7,
    previousComponent: 'MoDGoalSettingMentorStepTwo',
  },
  {
    name: 'MoDGoalSettingMentorStepThree',
    component: <MoDGoalSettingMentorStepThree />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MoDGoalSettingMentorStepTwo',
    nextComponent: 'MoDGoalSettingMentorStepFour',
  },
  {
    name: 'MoDGoalSettingMentorStepFour',
    component: <MoDGoalSettingMentorStepFour />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MoDGoalSettingMentorStepThree',
    nextComponent: 'MoDGoalSettingMentorStepFive',
  },
  {
    name: 'MoDGoalSettingMentorStepFive',
    component: <MoDGoalSettingMentorStepFive />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'MoDGoalSettingMentorStepFour',
    correctComponent: 'MoDGoalSettingMentorStepFiveCorrect',
    incorrectComponent: 'MoDGoalSettingMentorStepFiveCorrect',
  },
  {
    name: 'MoDGoalSettingMentorStepFiveCorrect',
    component: <MoDGoalSettingMentorStepFiveCorrect />,
    iterateStepper: true,
    previousComponent: 'MoDGoalSettingMentorStepFive',
    nextComponent: 'MoDGoalSettingMentorStepSix',
  },
  {
    name: 'MoDGoalSettingMentorStepSix',
    component: <MoDGoalSettingMentorStepSix />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'MoDGoalSettingMentorStepFive',
    correctComponent: 'MoDGoalSettingMentorStepSixCorrect',
    incorrectComponent: 'MoDGoalSettingMentorStepSixCorrect',
  },
  {
    name: 'MoDGoalSettingMentorStepSixCorrect',
    component: <MoDGoalSettingMentorStepSixCorrect />,
    iterateStepper: true,
    previousComponent: 'MoDGoalSettingMentorStepSix',
    nextComponent: 'MoDGoalSettingMentorStepSeven',
  },
  {
    name: 'MoDGoalSettingMentorStepSeven',
    component: <MoDGoalSettingMentorStepSeven />,
    iterateStepper: false,
    hasQuestion: true,
    needsInteraction: false,
    previousComponent: 'MoDGoalSettingMentorStepSix',
    correctComponent: 'MoDGoalSettingMentorStepSevenCorrect',
    incorrectComponent: 'MoDGoalSettingMentorStepSevenCorrect',
  },
  {
    name: 'MoDGoalSettingMentorStepSevenCorrect',
    component: <MoDGoalSettingMentorStepSevenCorrect />,
    iterateStepper: true,
    previousComponent: 'MoDGoalSettingMentorStepSeven',
    nextComponent: 'MoDGoalSettingMentorStepEight',
  },
  {
    name: 'MoDGoalSettingMentorStepEight',
    component: <MoDGoalSettingMentorStepEight />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MoDGoalSettingMentorStepSeven',
    nextComponent: 'MoDGoalSettingMentorStepNine',
  },
  {
    name: 'MoDGoalSettingMentorStepNine',
    component: <MoDGoalSettingMentorStepNine />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MoDGoalSettingMentorStepEight',
    nextComponent: 'MoDGoalSettingMentorStepTen',
  },
  {
    name: 'MoDGoalSettingMentorStepTen',
    component: <MoDGoalSettingMentorStepTen />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MoDGoalSettingMentorStepNine',
    nextComponent: 'MoDGoalSettingMentorStepEleven',
  },
  {
    name: 'MoDGoalSettingMentorStepEleven',
    component: <MoDGoalSettingMentorStepEleven />,
    iterateStepper: true,
    needsInteraction: true,
    submit: true,
    previousComponent: 'MoDGoalSettingMentorStepTen',
  },
];
