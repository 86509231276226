import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Text } from '../../../../Components/General/Text';
import { NextButton, PrevButton } from '../../../../Components/InputFields/BrancherButton';
import { GetRoute } from '../../../../Components/Routing';

const useStyles = makeStyles({
  width: {
    maxWidth: 660,
    minWidth: '100%',
  },
});

export const SurveyOneSplash = () => {
  const styles = useStyles();

  return (
    <Grid container direction="column" alignItems="center" className={styles.width}>
      <Grid item xs={11} md={9}>
        <Box marginTop={5} marginBottom={3}>
          <Link to={GetRoute('training').path}>
            <PrevButton variant="text" color="primary">
              Back
            </PrevButton>
          </Link>
        </Box>
        <Grid item>
          <Text variant="xl">Complete this short check-in survey</Text>
        </Grid>
        <Grid item>
          <Text variant="sm" fontWeight={600} color="purple" marginBottom={20} marginTop={30}>
            How are you and your mentoring partner going?
          </Text>
        </Grid>
        <Grid item>
          <Text variant="sm">
            We would like to hear how your mentoring relationship is going so far. Please complete
            this super short (6 question) survey and be open and honest. All responses are
            de-identified. Aggregated results will be used to further improve the program experience
            and may be used for research and/or marketing purposes.
          </Text>
        </Grid>
        <Box
          marginTop={6}
          marginBottom={4}
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Link to="surveyOne" style={{ width: '100%' }}>
            <NextButton fullWidth>Start</NextButton>
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};
