import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { MoDSituationalMentorshipMentorStepOne } from './Steps/MoDSituationalMentorshipMentorStepOne';
import { MoDSituationalMentorshipMentorStepTwo } from './Steps/MoDSituationalMentorshipMentorStepTwo';
import { MoDSituationalMentorshipMentorStepTwoResult } from './Steps/MoDSituationMentorshipMentorStepTwoResult';
import { MoDSituationalMentorshipMentorStepThree } from './Steps/MoDSituationalMentorshipMentorStepThree';
import { MoDSituationalMentorshipMentorStepThreeResult } from './Steps/MoDSituationalMentorshipMentorStepThreeResult';
import { MoDSituationalMentorshipMentorStepFourResult } from './Steps/MoDSituationalMentorshipMentorStepFourResult';
import { MoDSituationalMentorshipMentorStepFour } from './Steps/MoDSituationalMentorshipMentorStepFour';
import { MoDSituationalMentorshipMentorStepFive } from './Steps/MoDSituationalMentorshipMentorStepFive';
import { MoDSituationalMentorshipMentorStepSix } from './Steps/MoDSituationalMentorshipMentorStepSix';
import { MoDSituationalMentorshipMentorStepSeven } from './Steps/MoDSituationalMentorshipMentorStepSeven';

export const MoDSituationalMentorshipMentorModuleMapper: IModuleMapper[] = [
  {
    name: 'MoDSituationalMentorshipMentorStepOne',
    component: <MoDSituationalMentorshipMentorStepOne />,
    iterateStepper: true,
    contentScreen: true,
    nextComponent: 'MoDSituationalMentorshipMentorStepTwo',
  },
  {
    name: 'MoDSituationalMentorshipMentorStepTwo',
    component: <MoDSituationalMentorshipMentorStepTwo />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'MoDSituationalMentorshipMentorStepOne',
    incorrectComponent: 'MoDSituationalMentorshipMentorStepTwoResult',
    correctComponent: 'MoDSituationalMentorshipMentorStepTwoResult',
  },
  {
    name: 'MoDSituationalMentorshipMentorStepTwoResult',
    component: <MoDSituationalMentorshipMentorStepTwoResult />,
    iterateStepper: true,
    previousComponent: 'MoDSituationalMentorshipMentorStepTwo',
    nextComponent: 'MoDSituationalMentorshipMentorStepThree',
  },
  {
    name: 'MoDSituationalMentorshipMentorStepThree',
    component: <MoDSituationalMentorshipMentorStepThree />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'MoDSituationalMentorshipMentorStepTwo',
    incorrectComponent: 'MoDSituationalMentorshipMentorStepThreeResult',
    correctComponent: 'MoDSituationalMentorshipMentorStepThreeResult',
  },
  {
    name: 'MoDSituationalMentorshipMentorStepThreeResult',
    component: <MoDSituationalMentorshipMentorStepThreeResult />,
    iterateStepper: true,
    previousComponent: 'MoDSituationalMentorshipMentorStepThree',
    nextComponent: 'MoDSituationalMentorshipMentorStepFour',
  },
  {
    name: 'MoDSituationalMentorshipMentorStepFour',
    component: <MoDSituationalMentorshipMentorStepFour />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'MoDSituationalMentorshipMentorStepThree',
    incorrectComponent: 'MoDSituationalMentorshipMentorStepFourResult',
    correctComponent: 'MoDSituationalMentorshipMentorStepFourResult',
  },
  {
    name: 'MoDSituationalMentorshipMentorStepFourResult',
    component: <MoDSituationalMentorshipMentorStepFourResult />,
    iterateStepper: true,
    previousComponent: 'MoDSituationalMentorshipMentorStepFour',
    nextComponent: 'MoDSituationalMentorshipMentorStepFive',
  },
  {
    name: 'MoDSituationalMentorshipMentorStepFive',
    component: <MoDSituationalMentorshipMentorStepFive />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MoDSituationalMentorshipMentorStepFour',
    nextComponent: 'MoDSituationalMentorshipMentorStepSix',
  },
  {
    name: 'MoDSituationalMentorshipMentorStepSix',
    component: <MoDSituationalMentorshipMentorStepSix />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MoDSituationalMentorshipMentorStepFive',
    nextComponent: 'MoDSituationalMentorshipMentorStepSeven',
  },
  {
    name: 'MoDSituationalMentorshipMentorStepSeven',
    component: <MoDSituationalMentorshipMentorStepSeven />,
    iterateStepper: true,
    needsInteraction: true,
    submit: true,
    previousComponent: 'MoDSituationalMentorshipMentorStepSix',
  },
];
