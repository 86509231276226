import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import { SaveBeGreatMenteeData } from '../../../../../store/actions';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { SuggestionCard } from '../../SuggestionCard';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { BrancherDialog } from '../../../../../Components/General/BrancherDialog';
import { CompetenceScale } from '../../sharedComps/CompetenceScale';
import { ConfidenceScale } from '../../sharedComps/ConfidenceScale';
import { NPSScale } from '../../sharedComps/NPSScale';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';

export const BeGreatMenteeStepEleven = () => {
  const StepElevenValue = useSelector((state: IStoreTypes) => state.training.beGreatMentee);
  const dispatch = useDispatch();
  const [openDialog, setDialog] = React.useState(false);

  const updateCompetence = (val: number) => {
    dispatch(
      SaveBeGreatMenteeData({
        StepEleven: {
          ...StepElevenValue?.StepEleven,
          competenceFinal: val,
          interacted:
            StepElevenValue?.rating != null &&
            StepElevenValue?.confidenceFinal != null &&
            val != null,
        },
        competenceFinal: val,
      }),
    );
  };

  const updateConfidence = (val: number) => {
    dispatch(
      SaveBeGreatMenteeData({
        StepEleven: {
          ...StepElevenValue?.StepEleven,
          confidenceFinal: val,
          interacted:
            StepElevenValue?.rating != null &&
            StepElevenValue?.competenceFinal != null &&
            val != null,
        },
        confidenceFinal: val,
      }),
    );
  };

  const updateRating = (val: number) => {
    dispatch(
      SaveBeGreatMenteeData({
        StepEleven: {
          ...StepElevenValue?.StepEleven,
          interacted:
            StepElevenValue?.competenceFinal != null &&
            StepElevenValue?.confidenceFinal != null &&
            val != null,
        },
        rating: val,
      }),
    );
  };

  const updateFeedback = (val: string) => {
    dispatch(
      SaveBeGreatMenteeData({
        StepEleven: {
          ...StepElevenValue?.StepEleven,
          interacted:
            StepElevenValue?.rating != null &&
            StepElevenValue?.competenceFinal != null &&
            StepElevenValue?.confidenceFinal != null,
        },
        feedback: val,
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <BrancherDialog
        setClose={() => setDialog(false)}
        open={openDialog}
        labelledBy="Being great attributes"
      >
        <BrancherList
          fontWeight={400}
          listItems={[
            'Take responsibility for your own learning and for the success of the relationship',
            'More regularly demonstrate the behaviours of a great mentee',
            'Take a growth mindset throughout this mentoring journey',
          ]}
        />
      </BrancherDialog>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700}>
          This is the last question.
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" display="inline">
          Prior to completing this training, you told us you were an{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          {StepElevenValue.competenceBaseline + 1}
        </Text>
        <Text variant="md" display="inline">
          {' '}
          out of 7 regarding your level of competence and{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          {StepElevenValue.confidenceBaseline + 1}
        </Text>
        <Text variant="md" display="inline">
          {' '}
          out of 7 regarding your level of confidence to perform{' '}
        </Text>
        <BrancherLinkText
          display="inline"
          variant="md"
          underline="always"
          onClick={() => setDialog(true)}
        >
          these behaviours
        </BrancherLinkText>
        <Text variant="sm" display="inline">
          .
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your competence rating now?
        </Text>
        <CompetenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepElevenValue?.StepEleven?.competenceFinal}
          valueLength={7}
          updateValue={updateCompetence}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your confidence rating now?
        </Text>
        <ConfidenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepElevenValue?.StepEleven?.confidenceFinal}
          valueLength={7}
          updateValue={updateConfidence}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          On a scale of 0 - 10, how likely are you to recommend this training to another colleague
          within this mentoring program?
        </Text>
        <NPSScale />
        <SegmentedControlGroup
          fullWidth
          value={StepElevenValue?.rating}
          valueLength={11}
          updateValue={updateRating}
          startFromZero
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Do you have any other feedback for us?
        </Text>
        <BrancherTextField
          value={StepElevenValue?.feedback}
          updateValue={(a: string) => updateFeedback(a)}
          placeholder="Enter here"
          maxChars={400}
          id="feedback"
          name="feedback"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700}>
          The next steps are:
        </Text>
      </Grid>
      <Box marginLeft={2}>
        <BrancherList
          fontWeight={400}
          variant="md"
          listItems={[
            <>
              <Text variant="md" marginBottom={15} fontWeight={400}>
                Save a copy of these resources and refer to them for future use:
              </Text>
              <BrancherList
                variant="md"
                fontWeight={400}
                listStyleType="circle"
                listItems={[
                  <BrancherLinkText
                    fontWeight={700}
                    marginBottom={20}
                    target="_blank"
                    variant="md"
                    href="https://media.brancher.com.au/training/beGreatMentee/fixed_vs_growth_mindset.pdf"
                    underline="always"
                  >
                    GROWTH mindset
                  </BrancherLinkText>,
                ]}
              />
            </>,
            'Reflect on this module and what you learnt. Plan how you can apply these learnings in your next mentoring meeting.',
            'Start practising taking a growth mindset. Challenge yourself when you find yourself thinking that intelligence, abilities and attributes are fixed.',
          ]}
        />
      </Box>
      <Grid item xs={12}>
        <SuggestionCard>
          Make sure you click the "✓" button to complete this training.
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
