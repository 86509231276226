import * as React from 'react';
import { ModuleWrapper } from '../ModuleWrapper';
import {
  EMoDTrainingModules,
  ReadableTrainingModules,
} from '../../../../store/reducerTypes/TrainingReducer.types';
import { MoDGoalSettingMentorModuleMapper } from './MoDGoalSettingMentorModuleMapper';

export const MoDGoalSettingMentor = () => (
  <ModuleWrapper
    moduleMap={MoDGoalSettingMentorModuleMapper}
    moduleName={EMoDTrainingModules.MOD_GOAL_SETTING_MENTOR}
    title={ReadableTrainingModules[EMoDTrainingModules.MOD_GOAL_SETTING_MENTOR]}
  />
);
