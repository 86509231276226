import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Colors } from '../../../consts/colors';
import {
  desktopTrainingNavHeight,
  mobileMainNavWidth,
  mobileTrainingNavHeight,
  navSlideTrainingHeight,
} from '../../../consts/navHeights';
import { IStoreTypes } from '../../../store/storeTypes';

const useStyles = makeStyles({
  navBar: {
    height: (props: { mobile: boolean }) =>
      props.mobile ? mobileTrainingNavHeight : desktopTrainingNavHeight,
    width: (props: { mobile: boolean }) =>
      props.mobile ? '100%' : `calc(100% - ${mobileMainNavWidth})`,
    bottom: (props: { mobile: boolean; openNav: boolean }) =>
      props.mobile && props.openNav
        ? mobileTrainingNavHeight
        : props.mobile
        ? navSlideTrainingHeight
        : 0,
    backgroundColor: Colors.CTABanner,
    top: 'auto',
  },
});

interface ITrainingNav {
  children: React.ReactNode;
  mobile: boolean;
}

export const TrainingNav = ({ children, mobile }: ITrainingNav) => {
  const openNav = useSelector((state: IStoreTypes) => state.training?.openTrainingNav);
  const styles = useStyles({ mobile, openNav });

  return (
    <AppBar className={styles.navBar} position="fixed">
      <Grid container justify="center" alignItems="center" item xs={12}>
        <Grid container justify="center" item xs={12}>
          {children}
        </Grid>
      </Grid>
    </AppBar>
  );
};
