import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import capitalize from '@material-ui/core/utils/capitalize';
import Email from '@material-ui/icons/Email';
import LinkedIn from '@material-ui/icons/LinkedIn';
import PhoneEnabled from '@material-ui/icons/PhoneEnabled';
import { useSelector } from 'react-redux';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { Text } from '../../Components/General/Text';
import { Colors } from '../../consts/colors';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { EProfileAttributes, IProfileData } from '../../store/reducers/ProfileFormReducer';
import { GetIllustrationLink } from '../../utils/LinkUtils';
import { ProfilePicture } from './ProfilePicture';
import { IStoreTypes } from '../../store/storeTypes';

interface IProfile {
  profileData: IProfileData;
  profileType?: ProgramPositions;
}

const useStyles = makeStyles({
  preferredTimes: {
    marginTop: (props: { mobile: boolean }) => props.mobile && 30,
    padding: `15px 25px !important`,
    borderRadius: 20,
    background: Colors.backgroundLightPurple,
    height: 'fit-content',
  },
  profileImage: {
    marginRight: 15,
  },
});

export const Profile: React.FC<IProfile> = ({ profileData, profileType }) => {
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const styles = useStyles({ mobile: useTheme().breakpoints.down('sm') });

  return (
    <Grid container>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <Grid container alignItems="center">
            <Grid item className={styles.profileImage}>
              <ProfilePicture src={profileData?.[EProfileAttributes.IMAGE]} />
            </Grid>
            <Grid item xs={9} container direction="column">
              <Grid item>
                <Text variant="lg" color="purple" fontWeight={600}>
                  {profileData?.[EProfileAttributes.FIRST_NAME]}{' '}
                  {profileData?.[EProfileAttributes.LAST_NAME]}
                </Text>
              </Grid>
              <Grid item>
                <Text variant="xs" marginTop={4} marginBottom={4}>
                  {profileData?.[EProfileAttributes.JOB_TITLE]}
                </Text>
              </Grid>
              <Grid item container alignItems="center">
                {profileType && (
                  <>
                    <Text variant="xs" fontWeight={600} color="purple" display="inline">
                      {capitalize(roleLabels[profileType])}
                    </Text>
                    <Box
                      display="inline"
                      bgcolor={Colors.purple}
                      height={4}
                      width={4}
                      borderRadius="50%"
                      ml={1}
                      mr={1}
                    />
                  </>
                )}
                <Text variant="xs" color="tertiaryGrey" display="inline">
                  {profileData?.[EProfileAttributes.ORGANISATION]}
                </Text>
              </Grid>
              <Grid item>
                <Text variant="xs" marginTop={4}>
                  {profileData?.[EProfileAttributes.LOCATION]}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7} container direction="column" spacing={1}>
          <Grid item container alignItems="center">
            <Grid item xs={2} md={1}>
              <Email />
            </Grid>
            <Grid item xs={10}>
              <Text variant="sm" fontWeight={500} color="secondaryGrey">
                {profileData?.[EProfileAttributes.CONTACT_EMAIL]}
              </Text>
            </Grid>
          </Grid>
          <Grid item container alignItems="center">
            <Grid item xs={2} md={1}>
              <PhoneEnabled />
            </Grid>
            <Grid item xs={10}>
              <Text variant="sm" fontWeight={500} color="secondaryGrey">
                {profileData?.[EProfileAttributes.PHONE_NUMBER]}
              </Text>
            </Grid>
          </Grid>
          <Grid item container alignItems="center">
            <Grid item xs={2} md={1}>
              <LinkedIn />
            </Grid>
            <Grid item xs={10}>
              <BrancherLinkText
                target="_blank"
                variant="sm"
                color="secondaryGrey"
                wordBreak
                fontWeight={500}
                href={profileData?.[EProfileAttributes.LINKEDIN]}
              >
                {profileData?.[EProfileAttributes.LINKEDIN]?.split('/')[4]}
              </BrancherLinkText>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <BrancherDivider marginTop={40} marginBottom={40} />
      </Grid>
      <Grid item container spacing={2} xs={12} sm={9}>
        <Grid item container xs={12} spacing={2}>
          <Grid item>
            <img src={`${GetIllustrationLink('treeStory.png')}`} alt="career-story" />
          </Grid>
          <Grid item xs={12}>
            <Text variant="md" fontWeight={600} marginBottom={15}>
              About me: Career story, skills and work interests
            </Text>
            <Text variant="xs" color="secondaryGrey">
              {profileData?.[EProfileAttributes.CAREER_STORY] ??
                `${
                  profileData?.[EProfileAttributes.FIRST_NAME]
                } hasn't filled out their career story yet.`}
            </Text>
          </Grid>
        </Grid>
        {/*<Grid item container xs={12} spacing={2}>*/}
        {/*	<Grid item>*/}
        {/*		<img src={`${GetIllustrationLink('hobbiesInterests.png')}`} alt="hobbies-interests" />*/}
        {/*	</Grid>*/}
        {/*	<Grid item xs={9}>*/}
        {/*		<Text variant="md" fontWeight={600} marginBottom={15}>Interests and hobbies outside of work</Text>*/}
        {/*		<Text variant="xs" color="secondaryGrey">*/}
        {/*			{profileData?.[EProfileAttributes.INTERESTS] ?? `${profileData?.[EProfileAttributes.FIRST_NAME]} hasn't filled out their interests and hobbies yet.`}*/}
        {/*		</Text>*/}
        {/*	</Grid>*/}
        {/*</Grid>*/}
        {/*<Grid item container xs={12} spacing={2}>*/}
        {/*	<Grid item>*/}
        {/*		<img src={`${GetIllustrationLink('approachMentoring.png')}`} alt="mentoring-style" />*/}
        {/*	</Grid>*/}
        {/*	<Grid item xs={9}>*/}
        {/*		<Text variant="md" fontWeight={600} marginBottom={15}>My mentoring style and how I approach mentoring</Text>*/}
        {/*		<Text variant="xs" color="secondaryGrey">*/}
        {/*			{profileData?.[EProfileAttributes.MENTORING_STYLE] ?? `${profileData?.[EProfileAttributes.FIRST_NAME]} hasn't filled out how they approach mentoring yet.`}*/}
        {/*		</Text>*/}
        {/*	</Grid>*/}
        {/*</Grid>*/}
      </Grid>
      <Grid item container xs={12} sm={3} className={styles.preferredTimes}>
        <Grid item xs={12}>
          <Text variant="sm" fontWeight={600} marginBottom={15} color="purple">
            Best times/days to reach me
          </Text>
        </Grid>
        <Grid item xs={12}>
          <Text variant="xs" color="secondaryGrey">
            {profileData?.[EProfileAttributes.PREFERRED_MEETING_TIMES] ??
              `${
                profileData?.[EProfileAttributes.FIRST_NAME]
              } hasn't filled out their preferred meeting times yet.`}
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
};
