import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMoDBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { AlexAvatar } from '../../avatars/Alex';

export const MoDBeGreatMentorStepFive = () => {
  const stepFiveValue = useSelector(
    (state: IStoreTypes) => state.training.modBeGreatMentor?.StepFive,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepFiveCorrectVal = '1';
    dispatch(
      SaveMoDBeGreatMentorData({
        StepFive: {
          selected: val,
          correct: StepFiveCorrectVal === val,
        },
      }),
    );
  };

  const options: string[] = [
    'Nothing, the onus is on Alex to schedule catch ups.',
    'Give Alex a call and see if you can unpack why they are not taking the lead as much as you’d expected.',
    'Book a meeting in your calendars to get the ball rolling.',
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <AlexAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700} display="inline">
            This is your mentee,{' '}
          </Text>
          <Text variant="md" fontWeight={700} display="inline" color="purple">
            Alex
          </Text>
          <Text variant="md" fontWeight={700} display="inline">
            . You’ve met with Alex and agreed to monthly catch-ups but you haven’t heard from them
            in 5 weeks. What should you do?
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="sm" marginBottom={-30}>
          Choose one option.
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepFiveValue"
          value={stepFiveValue?.selected}
          updateValue={updateValue}
          noQuestion
          exclusive
          options={MakeOptions(options)}
        />
      </Grid>
    </Grid>
  );
};
