import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';
import { SaveFinalisingFormData } from '../../store/actions';
import { ValueTypes } from '../General/GeneralFormConfig';
import { ISelectionControl } from '../FormFieldTypes/SelectionControl';
import { ITextControl } from '../FormFieldTypes/TextControl';
import { MakeOptions } from '../MakeOptions';
import { EFormStepperSections } from '../FormStepper';
import { EComponentType } from '../../store/reducers/UserInfoReducer';
import { IDraggableSection } from '../../Components/General/DraggableSection';

export interface IFinalisingFormConfig extends ISelectionControl, ITextControl, IDraggableSection {
  componentType: EComponentType;
  value: any;
  updateValue: (a: ValueTypes) => void;
  readableName: string;
  section?: EFormStepperSections;
  notMandatory?: boolean;
}

export const FinalisingFormIndex = (): string[] => {
  const finalisingValues = useSelector((state: IStoreTypes) => state.finalising.configValues);
  return finalisingValues.map((a) => a?.key);
};

export function FinalisingFormQuestions(isMentee: boolean): IFinalisingFormConfig[] {
  const finalisingValues = useSelector((state: IStoreTypes) => state?.finalising);
  const config = useSelector((state: IStoreTypes) => state.user.formConfig?.finalising);
  const dispatch = useDispatch();
  return config.map((f) => {
    let field = {
      ...f,
      value: finalisingValues[f.name],
      updateValue: (a: any) => dispatch(SaveFinalisingFormData({ [f.name]: a })),
      question:
        isMentee && f.question.mentee
          ? f.question.mentee
          : !isMentee && f.question.mentor
          ? f.question.mentor
          : f.question,
    };
    // For conditional text field
    if (
      f.conditionalTextField &&
      (!!f?.conditionalTextFieldValue
        ? finalisingValues?.[f.name] === f.conditionalTextFieldValue
        : finalisingValues?.[f.name] === '1')
    ) {
      field.textProps = {
        ...field.textProps,
        value: finalisingValues[field.textProps.name],
        updateValue: (a: any) => dispatch(SaveFinalisingFormData({ [field.textProps.name]: a })),
      };
    } else {
      field.conditionalTextField = false;
    }
    // Most complex logic to pay attention to - stateTerritory and state transformations
    if (f.resetsOtherField) {
      field.updateValue = (a: any) => {
        dispatch(SaveFinalisingFormData({ [f.resetField]: '' }));
        dispatch(SaveFinalisingFormData({ [f.name]: a }));
      };
    }
    if (field?.options) {
      const opts = f?.conditionalOptions
        ? f.options?.[finalisingValues?.[f.conditionalOptionsField]] ?? []
        : f.options.mentee && isMentee
        ? f.options.mentee
        : f.options.mentor && !isMentee
        ? f.options.mentor
        : f.options;
      const secondaryOpts =
        f?.conditionalOptions && f?.conditionalOptionsSecondaryField
          ? opts[finalisingValues?.[f.conditionalOptionsSecondaryField]] ?? []
          : opts;
      field.options = MakeOptions(secondaryOpts);
    }
    return field;
  });
}
