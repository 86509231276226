import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import capitalize from '@material-ui/core/utils/capitalize';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Block from '@material-ui/icons/Block';
import EmojiPeople from '@material-ui/icons/EmojiPeople';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { BrancherSnackbar } from '../../Components/General/BrancherSnackbar';
import { Text } from '../../Components/General/Text';
import { AcceptButton, RejectButton } from '../../Components/InputFields/BrancherButton';
import { GetRoute } from '../../Components/Routing';
import { Colors } from '../../consts/colors';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import {
  BrancherDispatch,
  UtilCreateMentoringRequest,
  UtilUpdateMentoringRequest,
} from '../../store/actions';
import { IDraftedPairs } from '../../store/actions/CohortActions';
import { EMentoringRequestStatus, IMentoringRequest } from '../../store/reducers/CohortReducer';
import { EProfileAttributes } from '../../store/reducers/ProfileFormReducer';
import { ProgramRoleIds } from '../../store/reducers/UserInfoReducer';
import { IStoreTypes } from '../../store/storeTypes';
import { EProfileType } from '../Dashboard/PairSection';
import { IPairReasons, PairReasons } from '../Profile/PairReasons';
import { Profile } from '../Profile/Profile';
import { ConfirmMentoringRequestResponse } from './ConfirmMentoringRequestResponse';
import { ProfileCard } from './ProfileCard';
import { RequestMentoringPartnerForm } from './RequestMentoringPartnerForm';
import Tooltip from '@material-ui/core/Tooltip';

interface IAvailableMentoringPartner extends Pick<IPairReasons, 'availablePairingProfile'> {
  requestDetails?: IMentoringRequest;
  pairingData?: IDraftedPairs;
  runPairing?: () => void;
  cancellable?: boolean;
}

const useStyles = makeStyles({
  margin: {
    marginBottom: 30,
  },
  iconMargin: {
    marginRight: 8,
  },
  whyMatched: {
    marginTop: 20,
  },
  cursor: {
    cursor: 'pointer',
  },
  profileImage: {
    marginRight: 15,
  },
  button: {
    cursor: (props: { atCapacity: boolean }) => (!props.atCapacity ? 'pointer' : 'default'),
    background: (props: { atCapacity: boolean }) =>
      props.atCapacity ? Colors.disabledGrey : Colors.purple,
    borderTop: (props: { atCapacity: boolean }) =>
      props.atCapacity ? `2px solid ${Colors.disabledGrey}` : `2px solid ${Colors.purple}`,
    borderLeft: (props: { atCapacity: boolean }) =>
      props.atCapacity ? `2px solid ${Colors.disabledGrey}` : `2px solid ${Colors.purple}`,
    borderRadius: '20px 0 12px',
    width: '100%',
    display: 'flex',
    right: -20,
    bottom: -20,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: (props: { active: boolean; completed: boolean }) => (!props.active ? 0 : -1),
    maxWidth: (props: { mobile: boolean }) => (props.mobile ? 320 : '100%'),
    minWidth: (props: { mobile: boolean; phablet: boolean }) =>
      props.mobile ? 170 : props.phablet ? 250 : 270,
    minHeight: (props: { mobile: boolean }) => (props.mobile ? 50 : 80),
  },
  blockedIcon: {
    '&.MuiSvgIcon-colorSecondary': {
      color: Colors.darkGrey,
    },
  },
});

export const AvailableMentoringPartner: React.FC<IAvailableMentoringPartner> = (props) => {
  const currentPartnerRequests = useSelector(
    (state: IStoreTypes) => state.user?.mentoringPartnerRequests,
  );
  const sessionPosition = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const roleLabels = useSelector((state: IStoreTypes) => state.user.roleLabels);
  const userMentorRoleId = useSelector((state: IStoreTypes) => state.user?.[ProgramRoleIds.mentor]);
  const userFirstName = useSelector((state: IStoreTypes) => state.user?.firstName);
  const userLastName = useSelector((state: IStoreTypes) => state.user?.lastName);
  const userMenteeRoleId = useSelector((state: IStoreTypes) => state.user?.[ProgramRoleIds.mentee]);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [openSnackbarCreation, setOpenSnackbarCreation] = React.useState<boolean>(false);
  const [openSnackbarStatusResponse, setOpenSnackbarStatusResponse] = React.useState<boolean>(
    false,
  );
  const [snackbarIssue, setSnackbarIssue] = React.useState<string>('');
  const [openSnackbarIssue, setOpenSnackbarIssue] = React.useState<boolean>(false);
  const [openMentoringRequestDialog, setOpenMentoringRequestDialog] = React.useState<boolean>(
    false,
  );
  const [
    confirmMentoringRequestResponseDialog,
    setConfirmMentoringRequestResponseDialog,
  ] = React.useState<boolean>(false);
  const [creatingMentoringRequest, setCreatingMentoringRequest] = React.useState<boolean>(false);
  const [redirectToMentoringRequests, setRedirectToMentoringRequests] = React.useState<boolean>(
    false,
  );
  const [respondingToMentoringRequest, setRespondingToMentoringRequest] = React.useState<boolean>(
    false,
  );
  const [redirect, setRedirect] = React.useState<boolean>(false);
  const [mentoringRequestStatus, setMentoringRequestStatus] = React.useState<
    EMentoringRequestStatus
  >();
  const [profileDialogType, setProfileDialogType] = React.useState<EProfileType>();
  const { availablePairingProfile, pairingData, requestDetails, runPairing, cancellable } = props;
  const atCapacity = pairingData?.atCapacity;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const phablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const styles = useStyles({ atCapacity, mobile, phablet });
  const dispatch = useDispatch();

  const profileType: ProgramPositions =
    sessionPosition === ProgramPositions.mentor ? ProgramPositions.mentee : ProgramPositions.mentor;

  const submitMentoringRequest = (requestMessage: string) => {
    const menteeRoleId =
      sessionPosition === ProgramPositions.mentor
        ? availablePairingProfile.roleId
        : userMenteeRoleId;
    const menteeName =
      sessionPosition === ProgramPositions.mentor
        ? `${availablePairingProfile[EProfileAttributes.FIRST_NAME]} ${availablePairingProfile?.[
            EProfileAttributes.LAST_NAME
          ] ?? ''}`
        : `${userFirstName} ${userLastName}`;
    const mentorName =
      sessionPosition === ProgramPositions.mentor
        ? `${userFirstName} ${userLastName}`
        : `${availablePairingProfile[EProfileAttributes.FIRST_NAME]} ${availablePairingProfile?.[
            EProfileAttributes.LAST_NAME
          ] ?? ''}`;
    const mentorRoleId =
      sessionPosition === ProgramPositions.mentor
        ? userMentorRoleId
        : availablePairingProfile?.roleId ?? pairingData.mentor;
    setCreatingMentoringRequest(true);
    BrancherDispatch(
      dispatch,
      UtilCreateMentoringRequest(
        { menteeRoleId, mentorRoleId, requestMessage, menteeName, mentorName, pairingData },
        (res) => {
          setOpenMentoringRequestDialog(false);
          setCreatingMentoringRequest(false);
          if (res.success) {
            setRedirectToMentoringRequests(true);
          } else {
            setOpenSnackbarCreation(true);
            runPairing?.();
          }
        },
      ),
    );
  };

  const controlMentoringRequestResponseStatus = (status: EMentoringRequestStatus) => {
    setMentoringRequestStatus(status);
    setConfirmMentoringRequestResponseDialog(true);
  };

  const submitResponseMentoringRequest = (responseMessage: string) => {
    setRespondingToMentoringRequest(true);
    BrancherDispatch(
      dispatch,
      UtilUpdateMentoringRequest(
        requestDetails.mentoringRequestId,
        mentoringRequestStatus,
        responseMessage,
        (response) => {
          setConfirmMentoringRequestResponseDialog(false);
          setRespondingToMentoringRequest(false);
          if (response?.success) {
            setOpenSnackbarStatusResponse(true);
            if (mentoringRequestStatus === EMentoringRequestStatus.ACCEPTED) {
              setRedirect(true);
            }
          } else {
            setSnackbarIssue(response.message);
            setOpenSnackbarIssue(true);
          }
        },
      ),
    );
  };

  const hasOpenRequest =
    !!currentPartnerRequests?.find((f) => f.initiatorRoleId === availablePairingProfile.roleId) ||
    pairingData?.hasCurrentPairingRequest;

  const isCancelled: boolean = requestDetails?.status === EMentoringRequestStatus.CANCELLED;
  const isDeclined: boolean = requestDetails?.status === EMentoringRequestStatus.DECLINED;
  const isAccepted: boolean = requestDetails?.status === EMentoringRequestStatus.ACCEPTED;

  return (
    <>
      {redirect && <Redirect to={GetRoute('dashboard').path} />}
      {redirectToMentoringRequests && <Redirect to={GetRoute('mentoringPartnerRequests').path} />}
      <BrancherSnackbar
        open={openSnackbarCreation}
        controlClose={() => setOpenSnackbarCreation(false)}
        message={`${capitalize(roleLabels[profileType])} request sent!`}
      />
      <BrancherSnackbar
        open={openSnackbarStatusResponse}
        controlClose={() => setOpenSnackbarStatusResponse(false)}
        message={`${capitalize(roleLabels[profileType])} request ${mentoringRequestStatus}!`}
      />
      <BrancherSnackbar
        open={openSnackbarIssue}
        controlClose={() => setOpenSnackbarIssue(false)}
        message={snackbarIssue}
      />
      <BrancherDialog
        setClose={() => setOpenDialog(false)}
        open={openDialog}
        fullWidth
        labelledBy="Partner profile"
        fitLargeScreen
      >
        {profileDialogType === EProfileType.PAIR_PROFILE ? (
          <Profile profileData={availablePairingProfile} profileType={profileType} />
        ) : (
          pairingData && (
            <PairReasons
              availablePairingReasons={pairingData.pairingReasons}
              availablePairingProfile={availablePairingProfile}
            />
          )
        )}
      </BrancherDialog>
      <BrancherDialog
        setClose={() => setOpenMentoringRequestDialog(false)}
        open={openMentoringRequestDialog}
        labelledBy={`Request ${roleLabels[profileType]}`}
        title={`Make a request to ${
          availablePairingProfile[EProfileAttributes.FIRST_NAME]
        } to be your ${roleLabels[profileType]}`}
        noOverflow
        fitLargeScreen
      >
        <RequestMentoringPartnerForm
          submitMentoringRequestForm={submitMentoringRequest}
          creatingMentoringRequest={creatingMentoringRequest}
        />
      </BrancherDialog>
      <BrancherDialog
        setClose={() => setConfirmMentoringRequestResponseDialog(false)}
        open={confirmMentoringRequestResponseDialog}
        fullWidth
        labelledBy={`Confirm ${roleLabels[sessionPosition]} request response`}
        noOverflow
        fitLargeScreen
      >
        <ConfirmMentoringRequestResponse
          status={mentoringRequestStatus}
          submittingMentoringRequestStatusResponse={respondingToMentoringRequest}
          declineConfirmation={() => setConfirmMentoringRequestResponseDialog(false)}
          acceptConfirmation={(message: string) => submitResponseMentoringRequest(message)}
        />
      </BrancherDialog>
      <ProfileCard
        {...availablePairingProfile}
        profileType={profileType}
        onSelectUser={() => {
          setProfileDialogType(EProfileType.PAIR_PROFILE);
          setOpenDialog(true);
        }}
        brancherRecommended={pairingData?.brancherRecommended}
        isPeerMentor={pairingData?.isPeerMentor}
        customPadding="20px 0px 0px 20px"
        atCapacity={atCapacity}
      >
        {pairingData?.brancherRecommended && (
          <Grid item container justify="center" xs={12} className={styles.whyMatched}>
            <Grid item xs={2}>
              <HelpOutline fontSize="small" className={styles.iconMargin} />
            </Grid>
            <Grid item xs={10}>
              <BrancherLinkText
                variant="xs"
                display="inline"
                underline="always"
                fontWeight={500}
                onClick={() => {
                  setProfileDialogType(EProfileType.PAIR_REASONS);
                  setOpenDialog(true);
                }}
              >
                Why we think you're a good pair
              </BrancherLinkText>
            </Grid>
          </Grid>
        )}
        {!requestDetails ? (
          <Grid item container justify="flex-end">
            <Box display="flex" mt={2}>
              {!hasOpenRequest ? (
                <Tooltip
                  title={`Request ${pairingData.mentorName.split(' ')[0]} as a ${
                    roleLabels[profileType]
                  }`}
                >
                  <Box
                    onClick={() => !atCapacity && setOpenMentoringRequestDialog(true)}
                    className={styles.button}
                  >
                    <Grid item container justify="center" alignItems="center" xs={10}>
                      {!mobile &&
                        (atCapacity ? (
                          <Block
                            color="secondary"
                            fontSize="large"
                            className={styles.blockedIcon}
                          />
                        ) : (
                          <EmojiPeople fontSize="large" color="action" />
                        ))}
                      <Text
                        variant="sm"
                        color={atCapacity ? 'darkGrey' : 'white'}
                        fontWeight={500}
                        align="center"
                        marginLeft={10}
                      >
                        {atCapacity ? 'At Capacity' : `Request ${roleLabels[profileType]}`}
                      </Text>
                    </Grid>
                  </Box>
                </Tooltip>
              ) : (
                <Text
                  variant="xs"
                  fontWeight={600}
                  marginRight={33}
                  marginBottom={15}
                  marginTop={-5}
                >
                  Has open request
                </Text>
              )}
            </Box>
          </Grid>
        ) : isCancelled || isDeclined ? (
          <Grid item xs={11}>
            <Text
              variant="xs"
              fontWeight={600}
              marginBottom={15}
              color="purple"
              marginTop={10}
              align="right"
            >
              {capitalize(requestDetails.status)}
            </Text>
          </Grid>
        ) : (
          !isAccepted && (
            <Grid item container xs={12}>
              <Box display="flex" flexDirection="column" width="100%" mt={2} mr="20px" mb={2}>
                {requestDetails?.requestMessage && (
                  <Grid item container spacing={2}>
                    <Text variant="xs" display="inline">
                      {availablePairingProfile[EProfileAttributes.FIRST_NAME]}'s message:{' '}
                    </Text>
                    <Text variant="xs" fontWeight={500} marginBottom={20}>
                      "{requestDetails?.requestMessage}"
                    </Text>
                  </Grid>
                )}
                <Grid item container justify="space-between">
                  {cancellable ? (
                    <RejectButton
                      onClick={() =>
                        controlMentoringRequestResponseStatus(EMentoringRequestStatus.CANCELLED)
                      }
                      size="small"
                    >
                      Cancel
                    </RejectButton>
                  ) : (
                    <>
                      <RejectButton
                        onClick={() =>
                          controlMentoringRequestResponseStatus(EMentoringRequestStatus.DECLINED)
                        }
                        size="small"
                      >
                        Decline
                      </RejectButton>
                      <AcceptButton
                        onClick={() =>
                          controlMentoringRequestResponseStatus(EMentoringRequestStatus.ACCEPTED)
                        }
                      >
                        Accept
                      </AcceptButton>
                    </>
                  )}
                </Grid>
              </Box>
            </Grid>
          )
        )}
      </ProfileCard>
    </>
  );
};
