import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import LocationSearching from '@material-ui/icons/LocationSearching';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PageLayout } from '../../Components/General/PageLayout';
import { Text } from '../../Components/General/Text';
import { ActionButton } from '../../Components/InputFields/BrancherButton';
import { GetRoute } from '../../Components/Routing';
import { IStoreTypes } from '../../store/storeTypes';
import { Subscription } from '../../types/SubscriptionTypes';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';

export const GetMentoringPartnerView: React.FC = ({ children }) => {
  const sesPosition = useSelector((state: IStoreTypes) => state.user?.sessionPosition);
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const isMentee = sesPosition === ProgramPositions.mentee;
  const partnerPosition = isMentee
    ? roleLabels[ProgramPositions.mentor]
    : roleLabels[ProgramPositions.mentee];
  const hasCohortEnabled = useSelector((state: IStoreTypes) =>
    state.user?.subscription?.includes(Subscription.COHORT),
  );

  return (
    <PageLayout pageTitle="" titleDivider={false} hasFeaturePageLayout={false}>
      {hasCohortEnabled ? (
        <Grid container justify="center" spacing={2}>
          <Grid item xs={10} md={7}>
            <Text variant="sm" fontWeight={700} color="purple" align="center">
              Please search for a {partnerPosition} below to get full access to your dashboard!
            </Text>
          </Grid>
          <Grid item xs={10} md={7} container justify="center">
            <Link to={GetRoute('findMentoringPartner').path}>
              <ActionButton
                startIcon={<LocationSearching color="action" />}
                size="small"
                color="primary"
              >
                Search for a {partnerPosition} here
              </ActionButton>
            </Link>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid item xs={10} md={7}>
            <Text variant="lg" fontWeight={700} color="purple" align="center" marginBottom={40}>
              Please wait until finding a {partnerPosition} is available, in the interim you can:
            </Text>
          </Grid>
          <Grid item>
            <Link to={GetRoute('training').path}>
              <ActionButton startIcon={<LocationSearching color="action" />}>
                Complete some training
              </ActionButton>
            </Link>
          </Grid>
        </>
      )}
      {children && (
        <Grid item xs={10} md={7}>
          {children}
        </Grid>
      )}
    </PageLayout>
  );
};
