import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SonalAvatar } from '../../avatars/Sonal';
import { SuggestionCard } from '../../SuggestionCard';

export const GivingReceivingFeedbackMenteeStepNineCorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success>
          <Text variant="md" fontWeight={700}>
            Well done.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <SonalAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={3}>
          <Grid item>
            <Text variant="md">The answer you selected is the best option.</Text>
          </Grid>
          <Grid item>
            <Text variant="md" display="inline">
              This response is{' '}
            </Text>
            <Text variant="md" display="inline" fontWeight={700}>
              honest, reinforces the value you get from the other person
            </Text>
            <Text variant="md" display="inline">
              {' '}
              and{' '}
            </Text>
            <Text variant="md" display="inline" fontWeight={700}>
              clearly states your commitment to meeting again
            </Text>
            <Text variant="md" display="inline">
              .
            </Text>
          </Grid>
          <Grid item>
            <Text variant="md">The other responses included blame, attacking or exaggeration.</Text>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
