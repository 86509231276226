import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import {
  SaveGivingReceivingFeedbackMenteeData,
  SaveGivingReceivingFeedbackMentorData,
} from '../../../../../store/actions';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';
import { SonalAvatar } from '../../avatars/Sonal';
import { MasterModelCard } from '../../sharedComps/MasterModelCard';
import { SuggestionCard } from '../../SuggestionCard';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const GivingReceivingFeedbackMenteeStepNine = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const moduleName =
    position === ProgramPositions.mentee
      ? 'givingReceivingFeedbackMentee'
      : 'givingReceivingFeedbackMentor';
  const StepNineValue = useSelector((state: IStoreTypes) => state.training[moduleName]);
  const sessionSaveFunction =
    position === ProgramPositions.mentee
      ? SaveGivingReceivingFeedbackMenteeData
      : SaveGivingReceivingFeedbackMentorData;
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepNineCorrectVals = '3';
    dispatch(
      sessionSaveFunction({
        StepNine: {
          selected: val,
          correct: StepNineCorrectVals === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <SonalAvatar />
        </Grid>
        <Grid item xs={8} container direction="column" spacing={4}>
          <Grid item>
            <Text variant="md" fontWeight={700}>
              Sonal is giving you some feedback...
            </Text>
          </Grid>
          <Grid item>
            <SuggestionCard neutral>
              "You seem really busy at the moment and I wondered if you'd like to meet less often?"
            </SuggestionCard>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Of those options what's the most constructive way to respond?
        </Text>
      </Grid>
      <Grid item>
        <MasterModelCard />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="Thanks for noticing, I am really busy at the moment. The problem is work."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="I am sorry if I am making you feel like I'm not valuing our time together, however, I also feel like you are not paying attention when we meet."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="That's awful that you're feeling that way, I'm sorry for treating you poorly."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="D."
          optionText="I am quite busy at the moment but I do really value our time together and I'd like to keep meeting monthly."
        />
      </Grid>
      <Grid item container justify="center">
        <SelectionControl
          question=""
          noQuestion
          name="StepNineValue"
          value={StepNineValue.StepNine?.selected}
          updateValue={updateValue}
          thirdWidth
          exclusive
          options={MakeOptions(['A', 'B', 'C', 'D'])}
        />
      </Grid>
    </Grid>
  );
};
