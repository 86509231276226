import * as React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import capitalize from '@material-ui/core/utils/capitalize';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { PageLayout } from '../../Components/General/PageLayout';
import { Text } from '../../Components/General/Text';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { GetRoute } from '../../Components/Routing';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { BrancherDispatch, UtilGetAvailableMentoringPartners } from '../../store/actions';
import { IDraftedPairs } from '../../store/actions/CohortActions';
import { IStoreTypes } from '../../store/storeTypes';
import { AvailableMentoringPartner } from './AvailableMentoringPartner';
import { SearchablePartnerFilters } from './SearchablePartnerFilters';
import { BrancherAccordion } from '../../Components/General/BrancherAccordion';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import AccountCircle from '@material-ui/icons/AccountCircle';

export const FindMentoringPartner: React.FC = () => {
  const sessionPosition = useSelector((state: IStoreTypes) => state.user?.sessionPosition);
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const [cohortNameSearch, setCohortNameSearch] = React.useState<string>('');
  const [gettingAvailableMentoringPartners, setGettingAvailableMentoringPartners] = React.useState<
    boolean
  >(false);
  const [availableMentoringPartners, setAvailableMentoringPartners] = React.useState<
    IDraftedPairs[]
  >([]);
  const [demandNotOpen, setDemandNotOpen] = React.useState<boolean>(false);
  const [notEnoughApplicants, setNotEnoughApplicants] = React.useState<boolean>(false);
  const [updateMentoringCapacity, setUpdateMentoringCapacity] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const isMentee = sessionPosition === ProgramPositions.mentee;
  const otherPosition = isMentee ? ProgramPositions.mentor : ProgramPositions.mentee;
  const partnerPosition = roleLabels[otherPosition];

  React.useEffect(() => {
    getPotentialPartners();
  }, []);

  const getPotentialPartners = (filters?: any) => {
    setGettingAvailableMentoringPartners(true);
    BrancherDispatch(
      dispatch,
      UtilGetAvailableMentoringPartners(filters, (pairings) => {
        if (pairings?.message === 'ON_DEMAND_NOT_OPEN') {
          setDemandNotOpen(true);
        } else if (pairings?.message === 'NOT_ENOUGH_APPLICANTS') {
          setNotEnoughApplicants(true);
        } else if (pairings?.message === 'UPDATE_MENTORING_CAPACITY') {
          setUpdateMentoringCapacity(true);
        } else {
          setAvailableMentoringPartners(pairings?.data);
        }
        setGettingAvailableMentoringPartners(false);
      }),
    );
  };

  let filteredPairs: IDraftedPairs[] = [];
  if (cohortNameSearch !== '') {
    const regexp = new RegExp(cohortNameSearch, 'i');
    availableMentoringPartners?.forEach((o) => {
      if (isMentee && regexp.test(o.mentorName)) {
        filteredPairs.push(o);
      } else if (regexp.test(o.menteeName)) {
        filteredPairs.push(o);
      }
    });
  } else {
    filteredPairs = availableMentoringPartners;
  }

  let brancherRecommendedFilteredPairs = [];
  let additionalAvailableFilteredPairs = [];
  let atCapacityFilteredPairs = [];
  filteredPairs?.forEach((f) => {
    if (f.atCapacity) {
      atCapacityFilteredPairs.push(f);
    } else if (f.brancherRecommended) {
      brancherRecommendedFilteredPairs.push(f);
    } else {
      additionalAvailableFilteredPairs.push(f);
    }
  });

  return (
    <PageLayout
      pageTitle={`Find a ${capitalize(partnerPosition)}`}
      backButtonRoute="cohort"
      backButtonLabel="Cohort"
    >
      <Grid container spacing={3} direction="column" justify="center">
        <Grid item>
          <BrancherAccordion
            panels={[{
              name: 'how matching works',
              title: 'How it works?',
              content: <Grid container spacing={4} direction="column" justify="center">
                <Grid item>
                  <Text variant="sm" display="inline" fontWeight={600} color="purple">
                    1.{' '}
                  </Text>
                  <Text variant="sm" display="inline">
                    Brancher’s smart matching algorithm finds your ideal match based upon your application
                    form.
                  </Text>
                </Grid>
                <Grid item>
                  <Text variant="sm" display="inline" fontWeight={600} color="purple">
                    2.{' '}
                  </Text>
                  <Text variant="sm" display="inline">
                    Review the ‘Top Recommended’ matches and scroll down to see everyone else, in order of
                    compatibility. You can also filter your results by using the filter and search fields
                    below.
                  </Text>
                </Grid>
                <Grid item>
                  <Text variant="sm" display="inline" fontWeight={600} color="purple">
                    3.{' '}
                  </Text>
                  <Text variant="sm" display="inline">
                    Click on the profiles below to view information about them. Request a match by clicking
                    the ‘Request mentee/mentor’ button. This will let you send them an introductory message
                    as part of your request.
                  </Text>
                </Grid>
                <Grid item>
                  <Text variant="sm" display="inline" fontWeight={600} color="purple">
                    4.{' '}
                  </Text>
                  <Text variant="sm" display="inline">
                    Complete your introductory mentoring training while you wait for a response to your
                    mentoring request. You can view your received and sent mentoring requests from the
                    Dashboard.
                  </Text>
                </Grid>
              </Grid>
            }]}
          />
        </Grid>
      </Grid>

      <Box mt={8} mb={2}>
        <Grid container justify="space-between" spacing={4}>
          <Grid item xs={12} md={4}>
            {!gettingAvailableMentoringPartners && availableMentoringPartners?.length > 0 && (
              <>
                <Grid item>
                  <BrancherTextField
                    value={cohortNameSearch}
                    updateValue={setCohortNameSearch}
                    fullWidth
                    label={`Search ${partnerPosition}s`}
                  />
                </Grid>
                <Grid item>
                  <Box mt={1}>
                    <Text variant="sm">
                      Search for {partnerPosition}s by their first or last name
                    </Text>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          {!updateMentoringCapacity && (
            <Grid item xs={12} md={7} container>
              <>
                <Grid item>
                  <BrancherAccordion
                    panels={[
                      {
                        title: 'Advanced Filters',
                        name: 'filters',
                        content: (
                          <SearchablePartnerFilters updateSearchCriteria={getPotentialPartners} />
                        ),
                      },
                    ]}
                  />
                </Grid>
                <Grid item>
                  <Box mt={3}>
                    <Text variant="sm" align="center">
                      Update the filter section above to find a {partnerPosition} more specific to
                      your needs
                    </Text>
                  </Box>
                </Grid>
              </>
            </Grid>
          )}
        </Grid>
      </Box>

      {!gettingAvailableMentoringPartners ? (
        <>
          <Grid container spacing={2} justify="center" alignItems="center">
            {brancherRecommendedFilteredPairs?.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Text variant="xl" fontWeight={700} marginTop={40}>
                    Your Top Brancher recommended {partnerPosition}s
                  </Text>
                </Grid>
                <Grid item xs={12}>
                  <BrancherDivider marginBottom={30} marginTop={-10} />
                </Grid>
                {brancherRecommendedFilteredPairs?.map((pair, pairIndex) => (
                  <Grid
                    item
                    sm={6}
                    md="auto"
                    lg={6}
                    xl={4}
                    key={pairIndex}
                    container
                    justify="center"
                  >
                    <AvailableMentoringPartner
                      availablePairingProfile={pair.partnerProfile}
                      pairingData={pair}
                      runPairing={getPotentialPartners}
                    />
                  </Grid>
                ))}
              </>
            )}

            {additionalAvailableFilteredPairs?.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Text variant="xl" fontWeight={700} marginTop={40}>
                    More recommended {partnerPosition}s for you
                  </Text>
                </Grid>
                <Grid item xs={12}>
                  <BrancherDivider marginBottom={30} marginTop={-10} />
                </Grid>
                {additionalAvailableFilteredPairs?.map((pair, pairIndex) => (
                  <Grid
                    item
                    sm={6}
                    md="auto"
                    lg={6}
                    xl={4}
                    key={pairIndex}
                    container
                    justify="center"
                  >
                    <AvailableMentoringPartner
                      availablePairingProfile={pair.partnerProfile}
                      pairingData={pair}
                      runPairing={getPotentialPartners}
                    />
                  </Grid>
                ))}
              </>
            )}

            {atCapacityFilteredPairs?.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Text variant="xl" fontWeight={700} marginTop={40}>
                    {capitalize(partnerPosition)}s at capacity
                  </Text>
                </Grid>
                <Grid item xs={12}>
                  <BrancherDivider marginBottom={30} marginTop={-10} />
                </Grid>
                {atCapacityFilteredPairs?.map((pair, pairIndex) => (
                  <Grid
                    item
                    sm={6}
                    md="auto"
                    lg={6}
                    xl={4}
                    key={pairIndex}
                    container
                    justify="center"
                  >
                    <AvailableMentoringPartner
                      availablePairingProfile={pair.partnerProfile}
                      pairingData={pair}
                    />
                  </Grid>
                ))}
              </>
            )}

            {demandNotOpen ? (
              <Grid container justify="center" spacing={1}>
                <Grid item xs={10}>
                  <Text variant="sm" fontWeight={500} marginTop={40}>
                    Matching has not yet been turned on for your program, we'll let you know when we
                    release this soon. In the meantime, complete your training!
                  </Text>
                </Grid>
              </Grid>
            ) : notEnoughApplicants ? (
              <Grid container justify="center" spacing={1}>
                <Grid item>
                  <Text variant="md" marginTop={40}>
                    There are currently not enough applicants to find you an ideal {partnerPosition}
                    .
                  </Text>
                </Grid>
                <Grid item>
                  <Text variant="md" display="inline">
                    In the meantime, you can complete some{' '}
                  </Text>
                  <BrancherLinkText
                    underline="always"
                    href={GetRoute('training').path}
                    variant="md"
                    display="inline"
                  >
                    mentoring training modules
                  </BrancherLinkText>
                  <Text variant="md" display="inline">
                    .
                  </Text>
                </Grid>
              </Grid>
            ) : updateMentoringCapacity ? (
              <>
                <Grid item xs={10} md={12}>
                  <Text variant="md" fontWeight={500} marginTop={50}>
                    You are currently at your maximum {partnerPosition} pair amount.
                  </Text>
                </Grid>
                <Grid item xs={10} md={12} container alignItems="center">
                  <Text variant="md" fontWeight={500} display="inline">
                    You can update your maximum pairing amount in your{' '}
                  </Text>
                  <BrancherButton
                    color="primary"
                    href={GetRoute('settings').path}
                    size="small"
                    endIcon={<AccountCircle color="action" />}
                  >
                    profile settings
                  </BrancherButton>
                  <Text variant="md" fontWeight={500} display="inline">
                    {' '}and then come back here to find potential {partnerPosition}s.
                  </Text>
                </Grid>
              </>
            ) : (
              (availableMentoringPartners?.length === 0 || !availableMentoringPartners) && (
                <Grid item>
                  <Text variant="sm" fontWeight={500} color="purple" marginTop={20}>
                    There are currently no available {partnerPosition}s, please check back later.
                  </Text>
                </Grid>
              )
            )}
          </Grid>
        </>
      ) : (
        <Grid container direction="column" justify="center" alignItems="center" item xs={5}>
          <CircularProgress color="secondary" size={64} />
        </Grid>
      )}
    </PageLayout>
  );
};
