import * as React from 'react';
import { ModuleWrapper } from '../ModuleWrapper';
import { IntroductionMentorModuleMapper } from './IntroductionMentorModuleMapper';

export const IntroductionMentor = () => (
  <ModuleWrapper
    moduleMap={IntroductionMentorModuleMapper}
    moduleName="introductionMentor"
    title="Introduction to mentoring"
  />
);
