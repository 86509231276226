import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';
import {
  getTrainingModuleHeading,
  getTrainingModuleNumber,
} from '../../../TrainingModuleDefaultConfig';
import { GetRoute } from '../../../../../Components/Routing';
import { NextButton, PrevButton } from '../../../../../Components/InputFields/BrancherButton';
import { Text } from '../../../../../Components/General/Text';
import { PowerOfReflectionQualifyingOne } from './PowerOfReflectionQualifyingOne';
import {
  SaveMoDPowerOfReflectionMenteeData,
  SaveMoDPowerOfReflectionMentorData,
} from '../../../../../store/actions';
import { PowerOfReflectionQualifyingTwo } from './PowerOfReflectionQualifyingTwo';
import { PowerOfReflectionDisqualified } from './PowerOfReflectionDisqualified';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const PowerOfReflectionQualifying = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const trainingModules = useSelector((state: IStoreTypes) => state.training.modules)[sesPos];
  const trainingConfig = useSelector((state: IStoreTypes) => state.training.moduleConfig)[sesPos];
  const posModule =
    sesPos === ProgramPositions.mentee
      ? EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTEE
      : EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTOR;
  const saveFunc =
    sesPos === ProgramPositions.mentee
      ? SaveMoDPowerOfReflectionMenteeData
      : SaveMoDPowerOfReflectionMentorData;
  const haveMet = useSelector((state: IStoreTypes) => state.training[posModule])?.qualifying
    ?.haveMet;
  const [step, setStep] = React.useState(1);
  const [stepCorrect, setStepCorrect] = React.useState(true);

  const checkStep = () => {
    if (step === 1) {
      if (!stepCorrect) {
        setStepCorrect(false);
      } else {
        if (haveMet === '0') {
          setStepCorrect(true);
          setStep(2);
        } else {
          setStepCorrect(false);
        }
      }
    } else {
      setStep(3);
    }
  };

  const prevStep = () => {
    if (step === 1) {
      if (!stepCorrect) {
        setStep(1);
      }
    } else if (step === 2) {
      setStep(1);
    }
  };

  return (
    <Grid container justify="center">
      <Grid item xs={11} md={8} lg={7}>
        <Grid item>
          <Box marginTop={5} marginBottom={3}>
            {step === 1 ? (
              <Link to={GetRoute('training').path}>
                <PrevButton variant="text" color="primary">
                  Back
                </PrevButton>
              </Link>
            ) : (
              <PrevButton variant="text" color="primary" onClick={prevStep}>
                Back
              </PrevButton>
            )}
          </Box>
          <Grid item>
            <Text variant="xl">
              {`Training #${getTrainingModuleNumber(
                posModule,
                trainingModules,
                trainingConfig,
              )}: ${getTrainingModuleHeading(posModule, trainingConfig)}`}
            </Text>
          </Grid>
        </Grid>
        <Grid item>
          {step === 1 && stepCorrect ? (
            <PowerOfReflectionQualifyingOne save={saveFunc} module={posModule} />
          ) : step === 1 && !stepCorrect ? (
            <PowerOfReflectionDisqualified />
          ) : (
            <PowerOfReflectionQualifyingTwo />
          )}
        </Grid>
        {step === 2 && (
          <Grid item container>
            <Link to={GetRoute('modPowerOfReflectionSplash').path} style={{ width: '100%' }}>
              <NextButton fullWidth color="primary">
                Next
              </NextButton>
            </Link>
          </Grid>
        )}
        {stepCorrect && step !== 2 && (
          <Grid item container>
            <NextButton fullWidth onClick={checkStep} disabled={!haveMet}>
              Next
            </NextButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
