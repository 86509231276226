import * as React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const avatarURL = 'https://media.brancher.com.au/training/avatars/avatar_Charlie.svg';
const avatarLargeURL = 'https://media.brancher.com.au/training/avatars/avatar_Charlie_large.svg';

export const CharlieAvatar = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const avatar = mobile ? avatarURL : avatarLargeURL;
  return (
    <img
      src={avatar}
      alt="your-mentor-charlie"
      width={mobile ? '60%' : '50%'}
      height={mobile ? '100%' : '50%'}
    />
  );
};
