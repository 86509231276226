import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Colors } from '../../consts/colors';

const useStyles = makeStyles({
  tile: {
    background: (props: { active: boolean }) =>
      props.active ? Colors.backgroundDarkPurple : Colors.transparent,
    borderLeft: (props: { active: boolean }) =>
      props.active ? `3px solid ${Colors.purple}` : 'none',
    maxHeight: (props: { mobile: boolean }) => (props.mobile ? 44 : 60),
    padding: (props: { mobile: boolean }) => (props.mobile ? `6px 12px` : `8px 30px`),
    width: '100%',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
  },
});

interface ITileSelector {
  active: boolean;
}

export const TileSelector: React.FC<ITileSelector> = (props) => {
  const { children, active } = props;
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const styles = useStyles({ active, mobile });
  return (
    <Grid container className={styles.tile} alignItems="center">
      <Grid item>{children}</Grid>
    </Grid>
  );
};
