import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SuggestionCard } from '../../SuggestionCard';

export const IntroductionMenteeStepThreeCorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          Correct, the best option is{' '}
        </Text>
        <Text variant="md" fontWeight={700} color="purple" display="inline">
          B
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Your Mentoring Lead is available for support but before reaching out to them, firstly meet
          with Alex to get to know each other and explore why the relationship might work (rather
          than why it might not work).
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Discuss your goals and what you want from the program and explore how Alex can support you
          to achieve that.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You could say things like:
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard>"Given my interests I'd love to hear about [x, y and z]."</SuggestionCard>
        <SuggestionCard>
          "I'd love to hear advice you have for me as I'm working towards this goal [or navigating
          my way through this issue]."
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="md">
          If the relationship is unproductive, inform your Mentoring Lead who will assist you to end
          the relationship professionally.
        </Text>
      </Grid>
    </Grid>
  );
};
