import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Delete from '@material-ui/icons/Delete';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { Colors } from '../../consts/colors';
import { EContentType } from '../../store/reducers/PrivateNotesReducer';
import { BrancherButton, CancelButton, SaveButton } from '../InputFields/BrancherButton';
import { BrancherTextField } from '../InputFields/BrancherTextField';
import { BrancherDialog } from './BrancherDialog';
import { BrancherLinkText } from './BrancherLinkText';
import { BrancherList } from './BrancherList';
import { ContentCreatorBar } from './ContentCreatorBar';
import { Text } from './Text';

const useStyles = makeStyles({
  creator: {
    '& .MuiTextField-root .MuiInputBase-input': {
      color: Colors.black,
    },
  },
  buttons: {
    marginTop: 20,
  },
  emoji: {
    marginLeft: -22,
  },
  editor: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

interface IContent {
  type: EContentType;
  content: string & string[]; // Bit of a shortcut for now
  src?: string;
}

interface IContentCreator {
  contents?: IContent[];
}

export const ContentCreator: React.FC<IContentCreator> = ({ contents }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [edit, setEdit] = React.useState<boolean>(false);
  const [indices, setIndices] = React.useState<number | null>(null);
  const [contentType, setContentType] = React.useState<EContentType>();
  const [errorText, setErrorText] = React.useState<string>('');
  const [textContent, setTextContent] = React.useState<string>();
  const [listContent, setListContent] = React.useState<string[]>(['']);
  const [src, setSrc] = React.useState<string>('https://');
  const styles = useStyles();

  const controlDialog = (dialogOpen: boolean) => {
    setOpen(false);
    if (!dialogOpen) {
      resetState();
    }
  };

  const removeContentItem = () => {
    // setContents(contents.filter((a, i) => i !== indices));
    controlDialog(false);
  };

  const setPreviousContentState = (index: number) => {
    setIndices(index);
    const indexType = contents[index].type;
    setContentType(indexType);
    if (indexType === EContentType.LINK) {
      setTextContent(contents[index].content);
      setSrc(contents[index].src);
    } else if (indexType === EContentType.LIST) {
      setListContent(contents[index].content);
    } else {
      setTextContent(contents[index].content);
    }
    setEdit(true);
    controlDialog(true);
  };

  const setNewContentState = (type: EContentType, index: number) => {
    setIndices(index);
    setContentType(type);
    controlDialog(true);
  };

  const saveListContentText = (index: number, newValue: string) => {
    const newNotes = listContent.map((a, l) => {
      if (l === index) {
        return newValue;
      }
      return a;
    });
    setListContent(newNotes);
  };

  const removeNoteItem = (index: number) => {
    setListContent(listContent.filter((a, l) => l !== index));
  };

  const addNewContent = () => {
    if (contentType === EContentType.LINK && (!src || src === 'https://' || !textContent)) {
      setErrorText('Please enter all required fields');
    } else {
      const newContent =
        contentType === EContentType.LINK
          ? { type: contentType, content: textContent, src }
          : contentType === EContentType.LIST
          ? { type: contentType, content: listContent }
          : { type: contentType, content: textContent };
      let newContents = [];
      if (!edit) {
        newContents = contents
          ? [...contents?.slice(0, indices), newContent as IContent, ...contents?.slice(indices)]
          : [];
      } else {
        newContents = contents.map((c, i) => {
          if (i === indices) {
            return newContent;
          } else {
            return c;
          }
        });
      }
      // setContents(newContents);
      controlDialog(false);
    }
  };

  const resetState = () => {
    setEdit(false);
    setIndices(null);
    setContentType('' as EContentType);
    setTextContent('');
    setListContent(['']);
    setErrorText('');
    setSrc('https://');
  };

  return (
    <Grid container spacing={2} className={styles.creator}>
      {contents?.length === 0 && (
        <>
          <span aria-label="arrow" role="img" className={styles.emoji}>
            🎯
          </span>
          <Text variant="xs" color="purple" fontWeight={600} display="inline">
            {' '}
            Click me to add content!
          </Text>
        </>
      )}
      {/*<ContentCreatorBar setContents={setNewContentState} index={0} />*/}
      <div id="notes-creator">
        {contents?.map((c, i) => {
          if (c.type === EContentType.HEADING) {
            return (
              <Grid container key={i}>
                {/*<Tooltip title="Edit heading" aria-label="edit_heading" placement="top-start" arrow>*/}
                <Grid
                  item
                  xs={12}
                  onClick={() => setPreviousContentState(i)}
                  className={styles.editor}
                >
                  <Text variant="md" fontWeight={600} component="h2" wordWrap>
                    {c.content}
                  </Text>
                </Grid>
                {/*</Tooltip>*/}
                {/*<Grid item xs={12}>*/}
                {/*	<ContentCreatorBar setContents={setNewContentState} index={i + 1} />*/}
                {/*</Grid>*/}
              </Grid>
            );
          } else if (c.type === EContentType.PARAGRAPH) {
            return (
              <Grid container key={i}>
                {/*<Tooltip title="Edit paragraph" aria-label="edit_paragraph" placement="top-start" arrow>*/}
                <Grid
                  item
                  xs={12}
                  onClick={() => setPreviousContentState(i)}
                  className={styles.editor}
                >
                  <Text variant="sm" wordWrap>
                    {c.content}
                  </Text>
                </Grid>
                {/*</Tooltip>*/}
                {/*<Grid item xs={12}>*/}
                {/*	<ContentCreatorBar setContents={setNewContentState} index={i + 1} />*/}
                {/*</Grid>*/}
              </Grid>
            );
          } else if (c.type === EContentType.LIST) {
            return (
              <Grid container key={i}>
                {/*<Tooltip title="Edit list" aria-label="edit_list" placement="top-start" arrow>*/}
                <Grid
                  item
                  xs={12}
                  onClick={() => setPreviousContentState(i)}
                  className={styles.editor}
                >
                  <BrancherList listItems={c.content} wordWrap />
                </Grid>
                {/*</Tooltip>*/}
                {/*<Grid item xs={12}>*/}
                {/*	<ContentCreatorBar setContents={setNewContentState} index={i + 1} />*/}
                {/*</Grid>*/}
              </Grid>
            );
          } else if (c.type === EContentType.LINK) {
            return (
              <Grid container key={i}>
                {/*<Tooltip key={i} title="Edit link" aria-label="edit_link" placement="top-start">*/}
                <Grid
                  item
                  xs={12}
                  onClick={() => setPreviousContentState(i)}
                  className={styles.editor}
                >
                  <BrancherLinkText
                    variant="sm"
                    underline="always"
                    target="_blank"
                    href={c.src}
                    wordWrap
                  >
                    {c.content}
                  </BrancherLinkText>
                </Grid>
                {/*</Tooltip>*/}
                {/*<Grid item xs={12}>*/}
                {/*	<ContentCreatorBar setContents={setNewContentState} index={i + 1} />*/}
                {/*</Grid>*/}
              </Grid>
            );
          }
        })}
      </div>
      <BrancherDialog
        setClose={() => controlDialog(false)}
        open={open}
        title="Add content"
        labelledBy="Add content"
      >
        <Grid container item xs={12}>
          {contentType === EContentType.LINK ? (
            <Grid container spacing={2} item xs={12}>
              <Grid item xs={12}>
                <BrancherTextField
                  value={textContent}
                  updateValue={setTextContent}
                  label="Title"
                  fullWidth
                  error={Boolean(errorText && !textContent)}
                />
              </Grid>
              <Grid item xs={12}>
                <BrancherTextField
                  value={src}
                  updateValue={setSrc}
                  label="Link"
                  fullWidth
                  error={Boolean(errorText && (!src || src === 'https://'))}
                />
              </Grid>
              {errorText && (
                <Grid item xs={12}>
                  <Text variant="sm" color="red" fontWeight={500}>
                    {errorText}
                  </Text>
                </Grid>
              )}
            </Grid>
          ) : contentType === EContentType.LIST ? (
            <>
              {listContent.map((a, k) => (
                <Grid item xs={12} container alignItems="center" key={k}>
                  <Grid item xs={2}>
                    <IconButton onClick={() => removeNoteItem(k)}>
                      <Delete color="error" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <BrancherTextField
                      value={a}
                      label="List text"
                      updateValue={(f) => saveListContentText(k, f)}
                      fullWidth
                      multiline
                    />
                  </Grid>
                </Grid>
              ))}
              <Box marginTop={3} marginBottom={3}>
                <BrancherButton
                  color="primary"
                  onClick={() => setListContent(listContent.concat(''))}
                  size="small"
                >
                  Add
                </BrancherButton>
              </Box>
            </>
          ) : contentType === EContentType.HEADING ? (
            <Grid item xs={12}>
              <BrancherTextField
                value={textContent}
                updateValue={setTextContent}
                label="Heading"
                fullWidth
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <BrancherTextField
                value={textContent}
                updateValue={setTextContent}
                label="Paragraph"
                multiline
                fullWidth
              />
            </Grid>
          )}
          <Grid
            container
            justify="space-between"
            item
            xs={12}
            className={styles.buttons}
            alignItems="center"
          >
            <Grid item xs={3} md={4}>
              {edit && (
                <IconButton onClick={removeContentItem}>
                  <DeleteForever color="error" />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={8} md={6} container justify="space-between">
              <Grid item>
                <CancelButton onClick={() => controlDialog(false)} size="small" />
              </Grid>
              <Grid item>
                <SaveButton onClick={addNewContent} size="small" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BrancherDialog>
    </Grid>
  );
};
