import Box from '@material-ui/core/Box';
import capitalize from '@material-ui/core/utils/capitalize';
import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import HelpOutline from '@material-ui/icons/HelpOutline';
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { isBefore, subDays } from 'date-fns';
import { BrancherCard } from '../../Components/General/BrancherCard';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { Text } from '../../Components/General/Text';
import { ActionButton, BrancherButton } from '../../Components/InputFields/BrancherButton';
import { GetRoute } from '../../Components/Routing';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import {
  BrancherDispatch,
  UtilGetUserSharedNotes,
  UtilGetPreviousPairMeeting,
} from '../../store/actions';
import { EProfileAttributes } from '../../store/reducers/ProfileFormReducer';
import { IStoreTypes } from '../../store/storeTypes';
import { PairReasons } from '../Profile/PairReasons';
import { Profile } from '../Profile/Profile';
import { ProfilePicture } from '../Profile/ProfilePicture';
import { WrapUpRelationship } from './WrapUpRelationship';
import { AddMeetingSection } from './AddMeetingSection';

export enum EProfileType {
  PAIR_REASONS = 'pairReasons',
  PAIR_PROFILE = 'pairProfile',
}

const useStyles = makeStyles({
  margin: {
    marginBottom: 30,
    marginTop: 20,
  },
  iconMargin: {
    marginRight: 8,
  },
  whyMatched: {
    marginTop: 20,
  },
  cursor: {
    cursor: 'pointer',
  },
  profileImage: {
    marginRight: 15,
  },
});

interface IPairSection {
  hasAdhocPairing?: boolean;
}

export const PairSection: React.FC<IPairSection> = ({ hasAdhocPairing }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const partnerProfile = useSelector((state: IStoreTypes) => state.profileForm.pairProfile);
  const sesPosition = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const sessionPair = useSelector((state: IStoreTypes) => state.user.sessionPair);
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const completedMentoringAgreement = useSelector(
    (state: IStoreTypes) => state.sharedNotes?.mentoringAgreement?.completed,
  );
  const [open, setOpen] = React.useState<boolean>(false);
  const [hadMeetingInLastMonth, setHadMeetingInLastMonth] = React.useState<boolean>(false);
  const [hasPreviousMeeting, setHasPreviousMeeting] = React.useState<boolean>(false);
  const [gettingAgreement, setGettingAgreement] = React.useState<boolean>(false);
  const [gettingMeetingLoading, setGettingMeetingLoading] = React.useState<boolean>(false);
  const [profileType, setProfileType] = React.useState<EProfileType>();
  const styles = useStyles({ mobile, tablet });
  const dispatch = useDispatch();

  const getUserNotes = () => {
    setGettingAgreement(true);
    BrancherDispatch(
      dispatch,
      UtilGetUserSharedNotes(() => {
        setGettingAgreement(false);
      }),
    );
  };

  const getPreviousMeeting = () => {
    setGettingMeetingLoading(true);
    BrancherDispatch(
      dispatch,
      UtilGetPreviousPairMeeting((meetingRes) => {
        if (meetingRes.success && !!meetingRes.data?.meetingId) {
          const noMeetingInLastMonth = isBefore(
            new Date(meetingRes.data.datetimeEnd),
            subDays(new Date(), 30),
          );
          setHadMeetingInLastMonth(!noMeetingInLastMonth);
          setHasPreviousMeeting(true);
        }
        setGettingMeetingLoading(false);
      }),
    );
  };

  React.useEffect(() => {
    getUserNotes();
    getPreviousMeeting();
  }, [sessionPair]);

  return (
    <>
      <BrancherDialog
        setClose={() => setOpen(false)}
        open={open}
        fullWidth
        labelledBy="Partner profile"
        fitLargeScreen
      >
        {profileType === EProfileType.PAIR_PROFILE ? (
          <Profile
            profileData={partnerProfile}
            profileType={
              sesPosition === ProgramPositions.mentor
                ? ProgramPositions.mentee
                : ProgramPositions.mentor
            }
          />
        ) : (
          <PairReasons />
        )}
      </BrancherDialog>
      <BrancherCard className={styles.margin}>
        <Grid
          container
          alignItems="center"
          justify="center"
          className={styles.cursor}
          item
          xs={12}
          onClick={() => {
            setProfileType(EProfileType.PAIR_PROFILE);
            setOpen(true);
          }}
        >
          <Grid item className={styles.profileImage}>
            <ProfilePicture src={partnerProfile?.[EProfileAttributes.IMAGE]} />
          </Grid>
          <Grid item xs={8} md={12} lg={8} xl={9} container direction="column">
            <Grid item>
              <Text variant="sm" color="purple" fontWeight={600}>
                {partnerProfile?.[EProfileAttributes.FIRST_NAME]}{' '}
                {partnerProfile?.[EProfileAttributes.LAST_NAME]}
              </Text>
            </Grid>
            <Grid item>
              <Text variant="xs" marginTop={4} marginBottom={4}>
                {partnerProfile?.[EProfileAttributes.JOB_TITLE]}
              </Text>
            </Grid>
            <Grid item container alignItems="center">
              <Text variant="xs" color="tertiaryGrey" display="inline">
                {partnerProfile?.[EProfileAttributes.ORGANISATION]}
              </Text>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justify="center" xs={12} className={styles.whyMatched}>
          <Grid item xs={2}>
            <HelpOutline fontSize="small" className={styles.iconMargin} />
          </Grid>
          <Grid item xs={10}>
            <BrancherLinkText
              variant="xs"
              display="inline"
              underline="always"
              fontWeight={500}
              onClick={() => {
                setProfileType(EProfileType.PAIR_REASONS);
                setOpen(true);
              }}
            >
              Why you were matched
            </BrancherLinkText>
          </Grid>
          {!gettingMeetingLoading && (
            <Grid container justify="flex-end">
              <Box mt={2}>
                <ActionButton
                  color="primary"
                  href={GetRoute('meeting').path}
                  startIcon={<CalendarTodayOutlined color="action" />}
                  aria-label="create-meeting"
                  size="small"
                >
                  {hasPreviousMeeting && hadMeetingInLastMonth
                    ? 'Schedule a Meeting'
                    : hasPreviousMeeting && !hadMeetingInLastMonth
                    ? 'Miss a meeting? Schedule your next meeting'
                    : 'Create your first meeting!'}
                </ActionButton>
              </Box>
            </Grid>
          )}
          {!gettingAgreement && (
            <Grid container justify="flex-end">
              <Box mt={2}>
                <BrancherButton
                  href={GetRoute('mentoringAgreement').path}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  aria-label={`${roleLabels.programStyle}-agreement`}
                >
                  {completedMentoringAgreement ? 'Edit' : 'Complete'}{' '}
                  {capitalize(roleLabels.programStyle)} Agreement
                </BrancherButton>
              </Box>
            </Grid>
          )}
          {hasPreviousMeeting && hasAdhocPairing && <WrapUpRelationship />}
        </Grid>
      </BrancherCard>
      <AddMeetingSection />
    </>
  );
};
