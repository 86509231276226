import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveIntroductionMentee } from '../../../../../store/actions';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';
import { AlexAvatar } from '../../avatars/Alex';

export const IntroductionMenteeStepFour = () => {
  const StepFourValue = useSelector((state: IStoreTypes) => state.training.introductionMentee);
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepFourCorrectVals = ['0', '2'];
    dispatch(
      SaveIntroductionMentee({
        ...StepFourValue,
        StepFour: {
          selected: val,
          correct: StepFourCorrectVals.includes(val[0]) || StepFourCorrectVals.includes(val[1]),
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <AlexAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            What do you do?
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          You’ve just seen a job advertisement for your dream job. What do you do?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You can choose more than one response.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="Express your career aspirations to Alex and collaboratively come up with an action plan around what you can do to reach this goal."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="Ask Alex for an introduction to the decision maker (you see they are mutual friends on Linkedin)."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="Ask Alex to review your resume and cover letter, and provide you with feedback."
        />
      </Grid>
      <Grid item container justify="center">
        <SelectionControl
          question=""
          noQuestion
          name="StepFourValue"
          value={StepFourValue.StepFour?.selected}
          updateValue={updateValue}
          thirdWidth
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
