import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import capitalize from '@material-ui/core/utils/capitalize';
import { useDispatch, useSelector } from 'react-redux';
import { TrainingIntroduction } from '../../TrainingIntroduction';
import {
  getTrainingModuleHeading,
  getTrainingModuleNumber,
} from '../../TrainingModuleDefaultConfig';
import { IStoreTypes } from '../../../../store/storeTypes';
import { ProgramPositions } from '../../../../QualifyingForm/ProgramPositionOptions';
import { Text } from '../../../../Components/General/Text';
import { BrancherQuestionMarker } from '../../../../Components/General/BrancherQuestionMarker';
import { CompetencePopover } from '../sharedComps/CompetencePopover';
import { CompetenceScale } from '../sharedComps/CompetenceScale';
import { SegmentedControlGroup } from '../../../../Form/FormFieldTypes/SegmentedControl';
import {
  SaveBeyondProgramMenteeData,
  SaveBeyondProgramMentorData,
} from '../../../../store/actions';
import { ConfidenceScale } from '../sharedComps/ConfidenceScale';

export const BeyondProgramSplash = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const trainingModules = useSelector((state: IStoreTypes) => state.training.modules)[sesPos];
  const trainingConfig = useSelector((state: IStoreTypes) => state.training.moduleConfig)[sesPos];
  const isMentee = sesPos === ProgramPositions.mentee;
  const modulePathPos = isMentee ? ProgramPositions.mentee : ProgramPositions.mentor;
  const modName = `beyondProgram${capitalize(modulePathPos)}`;
  const beyondProgramFeedbackBaseline = useSelector(
    (state: IStoreTypes) => state.training[modName],
  );
  const dispatch = useDispatch();
  const [anchorEl, setAnchor] = React.useState(null);
  const open = Boolean(anchorEl);

  const controlValidator = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!Boolean(anchorEl)) {
      setAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(anchorEl)) {
      setAnchor(null);
    }
  };

  const updateBaseline = (val: string, attr: string) => {
    if (sesPos === ProgramPositions.mentee) {
      dispatch(SaveBeyondProgramMenteeData({ [attr]: val }));
    } else {
      dispatch(SaveBeyondProgramMentorData({ [attr]: val }));
    }
  };

  const activityLearnings: string[] = isMentee
    ? [
        'Learn how to professionally wrap up your mentoring relationship within this formal program',
        "Clearly discuss what 'beyond this mentoring program' looks like for you and your mentor",
        'Pursue your new/adapted SMART goal to take you beyond the mentoring program',
      ]
    : [
        'Learn how to professionally wrap up your mentoring relationship within this formal program',
        "Clearly discuss what 'beyond this mentoring program' looks like for you and your mentee",
        'Learn how to set your mentee up for success beyond this mentoring relationship',
      ];

  return (
    <TrainingIntroduction
      title={`Training #${getTrainingModuleNumber(
        modName,
        trainingModules,
        trainingConfig,
      )}: ${getTrainingModuleHeading(modName, trainingConfig)}`}
      subHeader="Complete this training to learn how to wrap up your mentoring relationship and make the most of your final formal meeting."
      activityLearnings={activityLearnings}
      modulePath={modName}
      validatedValue={
        beyondProgramFeedbackBaseline?.competenceBaseline != null &&
        beyondProgramFeedbackBaseline?.confidenceBaseline != null
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box marginTop={3} marginBottom={3}>
            <Text variant="sm" fontWeight={700} display="inline">
              To provide us with a baseline measure, please rate your current level of{' '}
            </Text>
            <Text variant="sm" fontWeight={700} display="inline" color="purple">
              competence and confidence
            </Text>
            <Text variant="sm" fontWeight={700} display="inline">
              {' '}
              to wrap up your relationship and have a great final (formal) meeting.{' '}
            </Text>
            <BrancherQuestionMarker onClick={controlValidator} />
          </Box>
          <CompetencePopover
            open={open}
            setClose={closeValidator}
            anchor={anchorEl}
            labelledBy="Confidence and competence"
          />
          <Grid item>
            <Text variant="sm" fontWeight={700} marginBottom={10}>
              Please rate your competence:
            </Text>
          </Grid>
          <CompetenceScale />
          <SegmentedControlGroup
            value={beyondProgramFeedbackBaseline?.competenceBaseline}
            valueLength={7}
            updateValue={(v: string) => updateBaseline(v, 'competenceBaseline')}
            fullWidth
          />
          <Grid item>
            <Text variant="sm" fontWeight={700} marginBottom={10}>
              Please rate your confidence:
            </Text>
          </Grid>
          <ConfidenceScale />
          <SegmentedControlGroup
            value={beyondProgramFeedbackBaseline?.confidenceBaseline}
            valueLength={7}
            updateValue={(v: string) => updateBaseline(v, 'confidenceBaseline')}
            fullWidth
          />
        </Grid>
      </Grid>
    </TrainingIntroduction>
  );
};
