import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import {
  SaveBeyondProgramMenteeData,
  SaveBeyondProgramMentorData,
} from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { FormWithStepFieldLayout } from '../../../../../Components/InputFields/FormWithStepFieldLayout';
import { SuggestionCard } from '../../SuggestionCard';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { BrancherSlider } from '../../../../../Components/InputFields/BrancherSlider';

export const BeyondProgramMentorStepThree = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentee = position === ProgramPositions.mentee;
  const sessionStore = isMentee
    ? EStructuredTrainingModules.BEYOND_PROGRAM_MENTEE
    : EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR;
  const sessionSaveFunction = isMentee ? SaveBeyondProgramMenteeData : SaveBeyondProgramMentorData;
  const StepThreeValue = useSelector((state: IStoreTypes) => state.training?.[sessionStore]);
  const dispatch = useDispatch();

  const updateSliderValue = (attributeName: string, value: string) => {
    const hasValues = StepThreeValue?.StepThree?.wantToContinue != null && value;
    dispatch(
      sessionSaveFunction({
        StepThree: {
          ...StepThreeValue?.StepThree,
          [attributeName]: value,
          interacted: hasValues,
        },
      }),
    );
  };

  const updateSelectionValue = (value: string) => {
    const hasValues = StepThreeValue?.StepThree?.partnerMatchSatisfaction && value != null;
    dispatch(
      sessionSaveFunction({
        StepThree: {
          ...StepThreeValue?.StepThree,
          wantToContinue: value,
          interacted: hasValues,
          correct: value === '0',
        },
      }),
    );
  };

  const reflectionQuestions: string[] = isMentee
    ? [
        'How valuable and enjoyable have you found your mentoring meetings? Why?',
        'Has your mentor made a positive impact to your personal/professional life?',
        'Do you have the capacity to keep in contact with your mentor?',
        'Do you feel confident to achieve professional development without the guidance of a mentor?',
      ]
    : [
        'How enjoyable have you found your mentoring meetings? Why?',
        'Has your mentee made a positive impact to your personal/professional life?',
        'Do you have the capacity to keep in contact with your mentee (consider if you plan to take on additional mentees)?',
        'Do you think you can continue supporting and developing your mentee?',
      ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Reflect on your mentoring journey so far.
        </Text>
      </Grid>
      <Grid item>
        <BrancherList listItems={reflectionQuestions} />
      </Grid>
      <Grid item>
        <BrancherSlider
          question={`Overall, how satisfied are you with your ${
            isMentee ? ProgramPositions.mentor : ProgramPositions.mentee
          }`}
          marks={[
            {
              label: 'Very Dissatisfied',
              value: 1,
            },
            {
              label: 'Dissatisfied',
              value: 2,
            },
            {
              label: 'Neutral',
              value: 3,
            },
            {
              label: 'Satisfied',
              value: 4,
            },
            {
              label: 'Very satisfied',
              value: 5,
            },
          ]}
          max={5}
          min={1}
          defaultValue={3}
          name="partnerMatchSatisfaction"
          value={StepThreeValue?.StepThree?.partnerMatchSatisfaction}
          updateValue={(e) => updateSliderValue('partnerMatchSatisfaction', e.toString())}
        />
      </Grid>
      <Grid item>
        <FormWithStepFieldLayout
          step={5}
          question={
            isMentee
              ? 'After reflecting on these questions, do you want to continue your mentoring relationship?'
              : 'Do you want to continue your mentoring relationship?'
          }
        >
          <Grid item>
            <Box marginTop={5}>
              <SuggestionCard>
                We will provide you with different training, depending on your response to this
                question.
              </SuggestionCard>
            </Box>
          </Grid>
          <SelectionControl
            question=""
            noQuestion
            name="StepThreeValue"
            value={StepThreeValue?.StepThree?.wantToContinue}
            updateValue={(e) => updateSelectionValue(e?.toString())}
            halfWidth
            exclusive
            options={MakeOptions(['Yes', 'No'])}
          />
        </FormWithStepFieldLayout>
      </Grid>
    </Grid>
  );
};
