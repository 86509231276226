import * as React from 'react';
import { ModuleWrapper } from '../ModuleWrapper';
import { GivingReceivingFeedbackMentorModuleMapper } from './GivingReceivingFeedbackMentorModuleMapper';

export const GivingReceivingFeedbackMentor = () => (
  <ModuleWrapper
    moduleMap={GivingReceivingFeedbackMentorModuleMapper}
    moduleName="givingReceivingFeedbackMentor"
    title="Effective feedback"
  />
);
