import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import {
  SaveGivingReceivingFeedbackMenteeData,
  SaveGivingReceivingFeedbackMentorData,
} from '../../../../../store/actions';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { CompetenceScale } from '../../sharedComps/CompetenceScale';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { BrancherDialog } from '../../../../../Components/General/BrancherDialog';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { NPSScale } from '../../sharedComps/NPSScale';
import { ConfidenceScale } from '../../sharedComps/ConfidenceScale';

export const GivingReceivingFeedbackMenteeStepTen = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const moduleName =
    position === ProgramPositions.mentee
      ? 'givingReceivingFeedbackMentee'
      : 'givingReceivingFeedbackMentor';
  const StepTenValue = useSelector((state: IStoreTypes) => state.training[moduleName]);
  const sessionSaveFunction =
    position === ProgramPositions.mentee
      ? SaveGivingReceivingFeedbackMenteeData
      : SaveGivingReceivingFeedbackMentorData;
  const dispatch = useDispatch();
  const [openDialog, setDialog] = React.useState(false);

  const updateCompetence = (val: number) => {
    dispatch(
      sessionSaveFunction({
        StepTen: {
          ...StepTenValue?.StepTen,
          competenceFinal: val,
          interacted:
            StepTenValue?.rating != null && StepTenValue?.confidenceFinal != null && val != null,
        },
        competenceFinal: val,
      }),
    );
  };

  const updateConfidence = (val: number) => {
    dispatch(
      sessionSaveFunction({
        StepTen: {
          ...StepTenValue?.StepTen,
          confidenceFinal: val,
          interacted:
            StepTenValue?.rating != null && StepTenValue?.competenceFinal != null && val != null,
        },
        confidenceFinal: val,
      }),
    );
  };

  const updateRating = (val: number) => {
    dispatch(
      sessionSaveFunction({
        StepTen: {
          ...StepTenValue?.StepTen,
          interacted:
            StepTenValue?.competenceFinal != null &&
            StepTenValue?.confidenceFinal != null &&
            val != null,
        },
        rating: val,
      }),
    );
  };

  const updateFeedback = (val: string) => {
    dispatch(
      sessionSaveFunction({
        StepTen: {
          ...StepTenValue?.StepTen,
          interacted:
            StepTenValue?.rating != null &&
            StepTenValue?.competenceFinal != null &&
            StepTenValue?.confidenceFinal != null,
        },
        feedback: val,
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <BrancherDialog
        setClose={() => setDialog(false)}
        open={openDialog}
        labelledBy="Giving effective feedback points"
      >
        <BrancherList
          fontWeight={400}
          listItems={[
            'Give effective feedback to your mentoring partner',
            'Receive feedback positively and effectively',
            'Adapt your communication style to positively impact feedback conversations',
          ]}
        />
      </BrancherDialog>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          This is the last question.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Prior to completing this training, you told us you were an{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          {StepTenValue.competenceBaseline + 1}
        </Text>
        <Text variant="md" display="inline">
          {' '}
          out of 7 regarding your level of competence and{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          {StepTenValue.confidenceBaseline + 1}
        </Text>
        <Text variant="md" display="inline">
          {' '}
          out of 7 regarding your level of confidence to perform{' '}
        </Text>
        <BrancherLinkText
          display="inline"
          variant="md"
          underline="always"
          onClick={() => setDialog(true)}
        >
          these behaviours
        </BrancherLinkText>
        <Text variant="sm" display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your competence rating now?
        </Text>
        <CompetenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepTenValue?.StepTen?.competenceFinal}
          valueLength={7}
          updateValue={updateCompetence}
        />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your confidence rating now?
        </Text>
        <ConfidenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepTenValue?.StepTen?.confidenceFinal}
          valueLength={7}
          updateValue={updateConfidence}
        />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          On a scale of 0 - 10, how likely are you to recommend this training to another colleague
          within this mentoring program?
        </Text>
        <NPSScale />
        <SegmentedControlGroup
          fullWidth
          value={StepTenValue?.rating}
          valueLength={11}
          updateValue={updateRating}
          startFromZero
        />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Do you have any other feedback for us?
        </Text>
        <BrancherTextField
          value={StepTenValue?.feedback}
          updateValue={(a: string) => updateFeedback(a)}
          placeholder="Enter here"
          maxChars={400}
          id="feedback"
          name="feedback"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
