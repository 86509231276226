import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import {
  SaveGivingReceivingFeedbackMenteeData,
  SaveGivingReceivingFeedbackMentorData,
} from '../../../../../store/actions';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { FormWithStepFieldLayout } from '../../../../../Components/InputFields/FormWithStepFieldLayout';
import { FeedbackStepsCard } from '../../sharedComps/FeedbackStepsCard';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const GivingReceivingFeedbackMenteeStepEight = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const moduleName =
    position === ProgramPositions.mentee
      ? 'givingReceivingFeedbackMentee'
      : 'givingReceivingFeedbackMentor';
  const StepEightValue = useSelector((state: IStoreTypes) => state.training[moduleName]);
  const sessionSaveFunction =
    position === ProgramPositions.mentee
      ? SaveGivingReceivingFeedbackMenteeData
      : SaveGivingReceivingFeedbackMentorData;
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    dispatch(
      sessionSaveFunction({
        StepEight: {
          val,
          interacted: val?.length > 0,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Consider the 9 steps to receiving feedback.
        </Text>
      </Grid>
      <Grid item container justify="center">
        <BrancherTrainingImage
          src="https://media.brancher.com.au/training/givingReceivingFeedbackMentee/master_lock_break.png"
          alt="master-lock-break"
        />
      </Grid>
      <Grid item container>
        <FormWithStepFieldLayout
          step={2}
          question="What can you do to receive feedback more positively?"
        >
          <BrancherTextField
            name="StepEightValue"
            value={StepEightValue.StepEight?.val}
            updateValue={updateValue}
            maxChars={400}
            fullWidth
          />
        </FormWithStepFieldLayout>
      </Grid>
      <Grid item>
        <FeedbackStepsCard />
      </Grid>
    </Grid>
  );
};
