import { createMuiTheme } from '@material-ui/core/styles';

const breakpoints = createMuiTheme().breakpoints;

export const MuiSpeedDial = {
  root: {
    bottom: 105,
    right: 20,
    position: 'fixed',
    [breakpoints.down('sm')]: {
      bottom: 100,
      right: 20,
    },
  },
};
