import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMoDBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const MoDBeGreatMentorStepSeven = () => {
  const stepSevenValue = useSelector(
    (state: IStoreTypes) => state.training.modBeGreatMentor?.StepSeven,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepSevenCorrectVal = '1';
    dispatch(
      SaveMoDBeGreatMentorData({
        StepSeven: {
          selected: val,
          correct: StepSevenCorrectVal === val,
        },
      }),
    );
  };

  const options: string[] = [
    'Contact your Mentoring Lead to discuss options.',
    'Meet with Alex and get to know each other. Ask Alex what they would like to achieve and tell them how you could contribute - explore the middle ground.',
    'Politely decline the relationship.',
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center">
        <Text variant="md" fontWeight={700}>
          You're concerned about whether you're the best mentor for Alex. Alex wasn't quite what you
          were expecting. Alex’s career goals don't seem to align to your experience and you have
          very different personalities. You’re wondering how you can help them. What do you do?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="sm" marginBottom={-30}>
          Choose one option.
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepSevenValue"
          value={stepSevenValue?.selected}
          updateValue={updateValue}
          noQuestion
          exclusive
          options={MakeOptions(options)}
        />
      </Grid>
    </Grid>
  );
};
