import * as React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { BrancherTrainingImage } from '../../../../Components/General/BrancherTrainingImage';

const avatarURL = 'https://media.brancher.com.au/training/avatars/avatar_Sally.svg';
const avatarLargeURL = 'https://media.brancher.com.au/training/avatars/avatar_Sally_large.svg';

export const SallyAvatar = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const avatar = mobile ? avatarURL : avatarLargeURL;
  return (
    <BrancherTrainingImage
      src={avatar}
      alt="your-mentor-sally"
      width={mobile ? '60%' : '50%'}
      height={mobile ? '100%' : '50%'}
    />
  );
};
