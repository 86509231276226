import * as React from 'react';
import { ModuleWrapper } from '../ModuleWrapper';
import { IntroductionMenteeModuleMapper } from './IntroductionMenteeModuleMapper';

export const IntroductionMentee = () => (
  <ModuleWrapper
    moduleMap={IntroductionMenteeModuleMapper}
    moduleName="introductionMentee"
    title="Introduction to mentoring"
  />
);
