import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { Text } from '../../../../../Components/General/Text';
import { SallyAvatar } from '../../avatars/Sally';

export const MoDBeGreatMenteeStepSixIncorrect = () => {
  const questionList: string[] = [
    'What is your background?',
    'How did you get to where you are today?',
    'What are some of the things you are most proud of?',
    'What are some of your biggest challenges and what have you learnt from these?',
    'What are your biggest strengths and how have you managed to leverage these?',
    'What are your biggest areas of weakness and what strategies have you implemented to manage these?',
    'What are your personal values (e.g. family, enjoyment in life, freedom, independence, work ethic, ethics, financial security/wealth) and how do these guide your career choices?',
    'What do you like about your current role and what do you not like?',
    'What are your current challenges and which of these are most important (impactful)?',
    'What are your immediate and long-term goals?',
    'What would you like to get out of this mentoring relationship',
    'What would an ideal mentoring relationship look like?',
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <SallyAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          <Text variant="sm" fontWeight={500}>
            That's OK. Most mentees don't know what to focus on initially.
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="sm">
          When preparing for your first mentoring meeting as a mentee, spend a few minutes thinking
          about yourself and your own career story. Consider the following questions:
        </Text>
        <BrancherList listItems={questionList} fontWeight={400} />
      </Grid>
    </Grid>
  );
};
