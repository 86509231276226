import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { INewEvent } from './Scheduler';
import { CalendarButton, CancelButton } from '../../Components/InputFields/BrancherButton';
import { Text } from '../../Components/General/Text';

interface IResizeCalendarEvent {
  updateEvent: () => void;
  cancelUpdateEvent: () => void;
  updatingEvent: boolean;
  meetingData: INewEvent;
}

export const ResizeCalendarEvent: React.FC<IResizeCalendarEvent> = ({
  updateEvent,
  updatingEvent,
  cancelUpdateEvent,
  meetingData,
}) => {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="sm" marginTop={10} marginBottom={10} fontWeight={500}>
          Are you sure you want to modify this event?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="sm">Start: {meetingData.start}</Text>
      </Grid>
      <Grid item>
        <Text variant="sm">End: {meetingData.end}</Text>
      </Grid>
      <Grid container item justify="space-between">
        <CancelButton onClick={cancelUpdateEvent}>No</CancelButton>
        <CalendarButton onClick={updateEvent} disabled={updatingEvent}>
          Yes, update
        </CalendarButton>
      </Grid>
    </Grid>
  );
};
