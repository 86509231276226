import * as React from 'react';
import {
  EMoDTrainingModules,
  ReadableTrainingModules,
} from '../../../../store/reducerTypes/TrainingReducer.types';
import { ModuleWrapper } from '../ModuleWrapper';
import { MoDGoalSettingMenteeModuleMapper } from './MoDGoalSettingMenteeModuleMapper';

export const MoDGoalSettingMentee = () => (
  <ModuleWrapper
    moduleMap={MoDGoalSettingMenteeModuleMapper}
    moduleName={EMoDTrainingModules.MOD_GOAL_SETTING_MENTEE}
    title={ReadableTrainingModules[EMoDTrainingModules.MOD_GOAL_SETTING_MENTEE]}
  />
);
