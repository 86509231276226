import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { BrancherCard } from '../../Components/General/BrancherCard';
import { Text } from '../../Components/General/Text';
import { Colors } from '../../consts/colors';
import { EGoalsBoardColumnsHeadings } from '../../store/reducers/GoalsReducer';

const useStyles = makeStyles({
  goal: {
    height: 'fit-content',
    maxHeight: 'fit-content',
    minHeight: 'fit-content',
    marginBottom: 25,
    borderRadius: `0 0 20px 20px`,
    borderTop: (props: { status: EGoalsBoardColumnsHeadings }) =>
      props.status === EGoalsBoardColumnsHeadings.BACKLOG
        ? `3px solid ${Colors.lightGrey}`
        : props.status === EGoalsBoardColumnsHeadings.IN_PROGRESS
        ? `3px solid ${Colors.purple}`
        : `3px solid ${Colors.green}`,
  },
  minWidthContent: {
    minWidth: 'fit-content',
  },
});

interface IGoalsSectionStatusCard {
  goal: string;
  status: EGoalsBoardColumnsHeadings;
}

export const GoalsSectionStatusCard: React.FC<IGoalsSectionStatusCard> = ({ goal, status }) => {
  const styles = useStyles({ status });

  return (
    <BrancherCard className={styles.goal}>
      <Grid container spacing={2} alignItems="center" justify="space-between">
        <Grid item xs={12} lg={8}>
          <Text variant="xs" fontWeight={600} color="secondaryGrey">
            {goal}
          </Text>
        </Grid>
        <Grid item xs={12} lg={4} className={styles.minWidthContent}>
          <Text
            fontWeight={400}
            variant="xxs"
            color={status === EGoalsBoardColumnsHeadings.COMPLETED ? 'green' : 'purple'}
            align="right"
          >
            {status}
          </Text>
        </Grid>
      </Grid>
    </BrancherCard>
  );
};
