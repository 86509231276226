import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  img: {
    maxWidth: '100%',
  },
});

interface IBrancherTrainingImage {
  src: string;
  alt: string;
  height?: string;
  width?: string;
}

export const BrancherTrainingImage = (props: IBrancherTrainingImage) => {
  const styles = useStyles();
  const { src, width, height, alt } = props;
  return <img src={src} className={styles.img} width={width} height={height} alt={alt} />;
};
