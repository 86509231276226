import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { addMonths, parse } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { EEventResponseStatus, IMeetingAgendas, INewEvent } from './Scheduler';
import { CreateButton } from '../../Components/InputFields/BrancherButton';
import { IStoreTypes } from '../../store/storeTypes';
import { EProfileAttributes } from '../../store/reducers/ProfileFormReducer';
import { calenderEventFormat, formatISOStyleTimeZone } from './CalendarEvents';
import { TimePicker } from '../../Components/General/TimePicker';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { BrancherSelect } from '../../Components/InputFields/BrancherSelect';
import { Colors } from '../../consts/colors';
import { IExtendedGroupData } from '../Groups/Group';
import { BrancherDispatch, UtilGetPreviousPairMeeting } from '../../store/actions';
import { BrancherCheckbox } from '../../Components/InputFields/BrancherCheckbox';
import { Text } from '../../Components/General/Text';
import { IMeetingAction } from '../../store/reducers/MeetingsReducer';
import { AddMeetingItem } from './UpdateCalendarEvent';
import { BrancherList } from '../../Components/General/BrancherList';
import { MeetingMinutesDescription } from './MeetingMinutesDescription';

export enum ERecurrenceOptions {
  WEEKLY = 'WEEKLY',
  FORTNIGHTLY = 'FORTNIGHTLY',
  FOUR_WEEKS = 'FOUR_WEEKS',
  MONTHLY = 'MONTHLY',
}

export const ERecurrenceOptionValues = {
  [ERecurrenceOptions.WEEKLY]: (untilDate: string) =>
    `RRULE:FREQ=WEEKLY;INTERVAL=1;UNTIL=${untilDate}`,
  [ERecurrenceOptions.FORTNIGHTLY]: (untilDate: string) =>
    `RRULE:FREQ=WEEKLY;INTERVAL=2;UNTIL=${untilDate}`,
  [ERecurrenceOptions.FOUR_WEEKS]: (untilDate: string) =>
    `RRULE:FREQ=WEEKLY;INTERVAL=4;UNTIL=${untilDate}`,
  [ERecurrenceOptions.MONTHLY]: (untilDate: string) =>
    `RRULE:FREQ=MONTHLY;INTERVAL=1;UNTIL=${untilDate}`,
};

export const recurrenceOptions: Array<{ label: string; value: ERecurrenceOptions }> = [
  {
    label: 'Weekly',
    value: ERecurrenceOptions.WEEKLY,
  },
  {
    label: 'Fortnightly',
    value: ERecurrenceOptions.FORTNIGHTLY,
  },
  {
    label: 'Every 4 weeks',
    value: ERecurrenceOptions.FOUR_WEEKS,
  },
  {
    label: 'Monthly',
    value: ERecurrenceOptions.MONTHLY,
  },
];

const useStyles = makeStyles({
  parentContainer: {
    marginBottom: 100,
  },
  fixedActionPane: {
    position: 'absolute',
    bottom: 0,
    height: 100,
    marginLeft: 30,
    backgroundColor: Colors.backgroundLightPurple,
    width: '100vw',
    zIndex: 100,
    boxShadow: `inset 0px 2px 2px -2px ${Colors.darkGrey};`,
  },
});

export interface ICreateCalendarEvent {
  createNewEvent: (event: INewEvent) => void;
  updatingEvent: boolean;
  selectedStartDate: string;
  selectedEndDate: string;
  group?: IExtendedGroupData;
}

export const CreateCalendarEvent: React.FC<ICreateCalendarEvent> = ({
  createNewEvent,
  selectedEndDate,
  selectedStartDate,
  updatingEvent,
  group,
}) => {
  const partnerEmail = useSelector(
    (state: IStoreTypes) => state.profileForm?.pairProfile?.[EProfileAttributes.CONTACT_EMAIL],
  );
  const partnerRoleId = useSelector(
    (state: IStoreTypes) => state?.profileForm?.pairProfile?.roleId,
  );
  const userFirstName = useSelector((state: IStoreTypes) => state.user.firstName);
  const sessionRoleId = useSelector((state: IStoreTypes) => state.user?.sessionRoleId);
  const sessionPair = useSelector((state: IStoreTypes) => state.user?.sessionPair);
  const userEmail = useSelector((state: IStoreTypes) => state.user?.email);
  const userId = useSelector((state: IStoreTypes) => state.user?.id);
  const focusAreas = useSelector((state: IStoreTypes) => state.meetings?.focusAreas);
  const [description, setDescription] = React.useState<string>('');
  const [location, setLocation] = React.useState<string>('');
  const [title, setTitle] = React.useState<string>(
    group?.groupId
      ? `Group mentoring with ${group?.title}`
      : `Mentoring with ${sessionPair?.name?.split(' ')[0]} and ${userFirstName}`,
  );
  const [startDate, setStartDate] = React.useState<Date>(
    parse(selectedStartDate, calenderEventFormat, new Date()),
  );
  const [endDate, setEndDate] = React.useState<Date>(
    parse(selectedEndDate, calenderEventFormat, new Date()),
  );
  const [previousMeetingId, setPreviousMeetingId] = React.useState<string>('');
  const [topicId, setTopicId] = React.useState<string>('');
  const [agendas, setAgendas] = React.useState<IMeetingAgendas[]>([]);
  const [actions, setActions] = React.useState<IMeetingAction[]>([]);
  const [agendaItem, setAgendaItem] = React.useState<string>('');
  // const [actionItem, setActionItem] = React.useState<string>('');

  // Recurring is turned off until we have dual syncing of integrated calendars
  const [recurring, setRecurring] = React.useState<boolean>(false);
  const [recurrence, setRecurrence] = React.useState<ERecurrenceOptions | string>('');
  const [recurrenceUntil, setRecurrenceUntil] = React.useState<Date>(addMonths(new Date(), 6));

  const styles = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    // Set as default topicId
    BrancherDispatch(
      dispatch,
      UtilGetPreviousPairMeeting((data) => {
        if (data.success && data.data?.meetingId) {
          setTopicId(data.data?.topicId ?? '');
          controlSetTitle(data.data?.topicId ?? '');
          setAgendas(data.data?.agendas ?? []);
          setActions(data.data?.actions ?? []);
          setPreviousMeetingId(data.data?.meetingId ?? '');
        } else {
          // First meeting focus area if they haven't had a meeting yet
          const firstMeetingTopicId = 'd25b65e6-e14c-4f5c-858a-e662ba7fa301';
          controlSelectAgenda(data.data?.topicId ?? firstMeetingTopicId);
        }
      }),
    );
  }, []);

  const createEvent = () => {
    const attendeesList = group?.groupId
      ? [...(group.mentors ?? []), ...(group?.mentees ?? [])]
          ?.filter((a) => !!a)
          ?.map((g) => ({
            email: g.profile[EProfileAttributes.CONTACT_EMAIL],
            organizer: group.owner === userId,
            responseStatus: group.owner === userId ? EEventResponseStatus.ACCEPTED : null,
            roleId: g?.roleId,
          }))
      : [
          {
            email: userEmail,
            organizer: true,
            responseStatus: EEventResponseStatus.ACCEPTED,
            roleId: sessionRoleId,
          },
          { email: partnerEmail, organizer: false, responseStatus: null, roleId: partnerRoleId },
        ];

    createNewEvent({
      attendees: attendeesList,
      start: formatISOStyleTimeZone(startDate),
      end: formatISOStyleTimeZone(endDate),
      title,
      description: CreateDescription(),
      location,
      recurrence: recurring ? [createRecurrenceRule()] : [],
      recurrenceUntil: recurring ? recurrenceUntil.toISOString() : '',
      groupId: group?.groupId ?? '',
      topicId,
      agendas,
      actions,
    });
  };

  const createRecurrenceRule = (): string => {
    if (recurring && recurrence !== '') {
      const formattedRecurrenceUntil = recurrenceUntil
        .toISOString()
        .replace(/-/g, '')
        .replace(/:/g, '')
        .split('.')[0];
      return ERecurrenceOptionValues[recurrence](`${formattedRecurrenceUntil}Z`);
    } else {
      return '';
    }
  };

  const isCreateDisabled = (): boolean => {
    return !(!!title && !!startDate && !!endDate);
  };

  const CreateDescription = () => {
    const notCompletedAgendaList = agendas?.filter((a) => !a.completed);
    const formattedAgendaList = notCompletedAgendaList?.map(
      (agenda) => `- ${agenda?.agendaTitle}\n`,
    );
    const topicTitle = focusAreas.find((fa) => fa.topicId === topicId)?.topicTitle;
    const topicForNextMeeting = !!topicTitle ? `Key topic to discuss: "${topicTitle}"\n\n` : ``;
    const agendaForNextMeeting =
      formattedAgendaList?.length > 0 ? `Talking points: \n${formattedAgendaList.join('')}\n` : ``;
    const formattedActionList = actions
      ?.filter((a) => !a.completed)
      ?.map((action) => `- ${action?.actionTitle}\n`);
    const actionList =
      formattedActionList?.length > 0
        ? `Outstanding actions: \n${formattedActionList.join('')}\n`
        : ``;
    const previousMeetingTalkingPointsList = agendas
      ?.filter((a) => a?.completed && a?.completedMeeting === previousMeetingId)
      ?.map((c) => `- ${c?.agendaTitle}\n`);
    const previousMeetingTalkingPoints =
      previousMeetingTalkingPointsList?.length > 0
        ? `A reminder on what you covered last meeting: \n${previousMeetingTalkingPointsList?.join(
            '',
          )}`
        : ``;
    return (
      topicForNextMeeting +
      agendaForNextMeeting +
      actionList +
      previousMeetingTalkingPoints +
      '\n' +
      description
    );
  };

  const controlSetTitle = (selectedTopic: string) => {
    const topic = focusAreas.find((fa) => fa?.topicId === selectedTopic);
    if (!!topic) {
      setTitle(
        group?.groupId
          ? `Group mentoring with ${group?.title} - ${topic?.topicTitle}`
          : `Mentoring with ${sessionPair?.name?.split(' ')[0]} & ${userFirstName} - ${
              topic?.topicTitle
            }`,
      );
    }
  };

  const controlSelectAgenda = (selectedTopic: string) => {
    setTopicId(selectedTopic);
    controlSetTitle(selectedTopic);
    const topic = focusAreas?.find((fa) => fa?.topicId === selectedTopic);
    setAgendas(topic?.agendas?.map((ta) => ({ ...ta, completed: false })));
  };

  const updateAgendaStatus = (agendaId: string, completedAgenda: boolean) => {
    const updatedAgendas = agendas?.map((agenda) => {
      if (agenda.agendaId === agendaId) {
        return { ...agenda, completed: completedAgenda, completedMeeting: previousMeetingId };
      } else {
        return agenda;
      }
    });
    setAgendas(updatedAgendas);
  };

  const updateActionStatus = (actionId: string, completedAction: boolean) => {
    const updatedActions = actions?.map((action) => {
      if (action.meetingActionId === actionId) {
        return { ...action, completed: completedAction, completedMeeting: previousMeetingId };
      } else {
        return action;
      }
    });
    setActions(updatedActions);
  };

  const setRemoveAction = (actionIndex: number) => {
    setActions(actions?.filter((m, i) => i !== actionIndex));
  };

  const setRemoveAgendaItem = (agendaIndex: number) => {
    setAgendas(agendas?.filter((m, i) => i !== agendaIndex));
  };

  // const controlAddActionItem = () => {
  //   setActions([...actions, { meetingActionId: uuidv4(), actionTitle: actionItem, completed: false }]);
  //   setActionItem('');
  // };

  const controlAddAgendaItem = () => {
    setAgendas([...agendas, { agendaId: uuidv4(), agendaTitle: agendaItem, completed: false }]);
    setAgendaItem('');
  };

  const previousMeetingActions: string[] = actions
    ?.filter((a) => a?.completedMeeting === previousMeetingId)
    ?.map((a) => a?.actionTitle);

  const futureMeetingActions: IMeetingAction[] = actions?.filter(
    (a) => a?.completedMeeting !== previousMeetingId && !a?.completed,
  );

  return (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      justify="center"
      spacing={3}
      className={styles.parentContainer}
    >
      <Grid item xs={12}>
        <TimePicker
          fullWidth
          value={startDate}
          updateValue={(newDate: Date) => setStartDate(newDate)}
          id="start-time-picker"
          name="start-time-picker"
          label="Start Time"
        />
      </Grid>
      <Grid item xs={12}>
        <TimePicker
          fullWidth
          value={endDate}
          updateValue={(newDate: Date) => setEndDate(newDate)}
          id="end-time-picker"
          name="end-time-picker"
          label="End Time"
        />
      </Grid>
      <Grid item xs={12}>
        <BrancherTextField
          fullWidth
          value={location}
          updateValue={setLocation}
          name="location"
          id="location"
          label="Location"
          rows={4}
        />
      </Grid>
      <Grid item xs={12}>
        <MeetingMinutesDescription creatingMeeting={true} />
      </Grid>
      <Grid item xs={12}>
        <BrancherSelect
          options={focusAreas.map((focusArea) => ({
            label: `${focusArea.topicTitle}`,
            value: focusArea.topicId,
          }))}
          value={topicId}
          inputLabel="Suggested Topics"
          name="agendaTopic"
          id="agendaTopic"
          fullWidth
          updateValue={(topic: string) => controlSelectAgenda(topic)}
          helpText="Choose a template for the meeting - this will provide a list of talking points for current and future meetings"
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="sm" fontWeight={600} marginBottom={15}>
          Talking points (remember to tick off any you've already completed)
        </Text>
        {agendas?.length > 0 ? (
          agendas?.map((agenda, agendaIndex) => (
            <Grid item key={agenda.agendaId}>
              <BrancherCheckbox
                name={agenda.agendaTitle}
                value={agenda.completed}
                updateValue={(comp: boolean) => updateAgendaStatus(agenda.agendaId, comp)}
                label={
                  <>
                    <Text variant="sm" display="inline" marginRight={15}>
                      {agenda.agendaTitle}
                    </Text>
                    <IconButton onClick={() => setRemoveAgendaItem(agendaIndex)}>
                      <DeleteForever color="error" />
                    </IconButton>
                  </>
                }
              />
            </Grid>
          ))
        ) : (
          <Text variant="sm">You don't have any talking points assigned to this meeting yet.</Text>
        )}
        <AddMeetingItem
          addValue={controlAddAgendaItem}
          setValue={setAgendaItem}
          canUpdate={true}
          value={agendaItem}
          label="Add new talking point"
        />
      </Grid>
      {!!previousMeetingId &&
        (futureMeetingActions?.length > 0 || previousMeetingActions?.length > 0) && (
          <Grid item xs={12}>
            {previousMeetingActions?.length > 0 && (
              <>
                <Text variant="sm" fontWeight={600} marginBottom={15}>
                  Action items from your last meeting
                </Text>
                <BrancherList listItems={previousMeetingActions} fontWeight={500} />
              </>
            )}
            {futureMeetingActions?.length > 0 && (
              <>
                <Text variant="sm" fontWeight={600} marginBottom={15} marginTop={30}>
                  Action items (remember to tick off any you've already completed)
                </Text>
                {futureMeetingActions?.map((action, actionIndex) => (
                  <Grid item key={action.meetingActionId}>
                    <BrancherCheckbox
                      name={action.actionTitle}
                      value={action.completed}
                      updateValue={(comp: boolean) =>
                        updateActionStatus(action.meetingActionId, comp)
                      }
                      label={
                        <>
                          <Text variant="sm" display="inline" marginRight={15}>
                            {action.actionTitle}
                          </Text>
                          <IconButton onClick={() => setRemoveAction(actionIndex)}>
                            <DeleteForever color="error" />
                          </IconButton>
                        </>
                      }
                    />
                  </Grid>
                ))}
              </>
            )}
            {/*<AddMeetingItem addValue={controlAddActionItem} setValue={setActionItem} canUpdate={true} value={actionItem} label="Add new action item" />*/}
          </Grid>
        )}
      <Grid item xs={12}>
        <BrancherTextField
          fullWidth
          value={title}
          updateValue={setTitle}
          name="title"
          id="title"
          label="Title"
          multiline
        />
      </Grid>
      <Grid item xs={12}>
        <BrancherTextField
          fullWidth
          value={description}
          updateValue={setDescription}
          name="description"
          id="description"
          label="Description"
          rows={12}
          multiline
        />
      </Grid>

      {/*<Grid item container xs={12} justify="space-between" alignItems="center">*/}
      {/*  <Text variant="sm" fontWeight={600} id="recurring">Make it recurring?</Text>*/}
      {/*  <BrancherSwitch*/}
      {/*    value={recurring}*/}
      {/*    updateValue={(recur) => {*/}
      {/*      setRecurring(recur);*/}
      {/*      if(!recur) {*/}
      {/*        setRecurrence('');*/}
      {/*      }*/}
      {/*    }}*/}
      {/*    name="recurring"*/}
      {/*    id="recurring"*/}
      {/*    ariaLabel="recurring"*/}
      {/*  />*/}
      {/*</Grid>*/}
      {/*{recurring && (*/}
      {/*  <>*/}
      {/*    <Grid item xs={12}>*/}
      {/*      <BrancherSelect*/}
      {/*        options={recurrenceOptions}*/}
      {/*        value={recurrence}*/}
      {/*        inputLabel="Recurrence"*/}
      {/*        name="recurrence"*/}
      {/*        id="recurrence"*/}
      {/*        fullWidth*/}
      {/*        updateValue={(a: ERecurrenceOptions) => setRecurrence(a)}*/}
      {/*        helpText="This is how frequent the meetings will be"*/}
      {/*      />*/}
      {/*    </Grid>*/}
      {/*    <Grid item xs={12}>*/}
      {/*      <DatePicker*/}
      {/*        fullWidth*/}
      {/*        value={recurrenceUntil}*/}
      {/*        updateValue={setRecurrenceUntil}*/}
      {/*        id="recurrenceUntil"*/}
      {/*        name="recurrenceUntil"*/}
      {/*        helpText="This is when the recurring meetings will end"*/}
      {/*      />*/}
      {/*    </Grid>*/}
      {/*  </>*/}
      {/*)}*/}

      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justify="flex-end"
        className={styles.fixedActionPane}
      >
        <CreateButton onClick={createEvent} disabled={isCreateDisabled()} loading={updatingEvent}>
          Create Meeting
        </CreateButton>
      </Grid>
    </Grid>
  );
};
