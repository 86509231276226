import { IUserInfo } from '../store/reducers/UserInfoReducer';
import capitalize from '@material-ui/core/utils/capitalize';

export enum ProgramPositions {
  mentee = 'mentee',
  mentor = 'mentor',
  peer = 'peer',
  catchups = 'catchups',
}

const ProgramPositionOptions = [
  { value: ProgramPositions.mentee, label: 'Mentee' },
  { value: ProgramPositions.mentor, label: 'Mentor' },
];

export interface ICreateProgramPositionOptions {
  value: ProgramPositions;
  label: string;
}

export const CreateProgramPositionOptions = (
  roleLabels?: IUserInfo['roleLabels'],
): ICreateProgramPositionOptions[] =>
  roleLabels
    ? [
        { value: ProgramPositions.mentee, label: capitalize(roleLabels[ProgramPositions.mentee]) },
        { value: ProgramPositions.mentor, label: capitalize(roleLabels[ProgramPositions.mentor]) },
      ]
    : ProgramPositionOptions;

export enum ProgramPairPositions {
  mentees = 'mentees',
  mentors = 'mentors',
  catchups = 'catchups',
}

export const ProgramPairOptions = [
  { value: ProgramPairPositions.mentees, label: 'Mentees' },
  { value: ProgramPairPositions.mentors, label: 'Mentors' },
];
