import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { AlexAvatar } from '../../avatars/Alex';
import { SuggestionCard } from '../../SuggestionCard';

export const MoDBeGreatMentorStepSevenCorrect = () => {
  const stepSevenValue = useSelector(
    (state: IStoreTypes) => state.training.modBeGreatMentor?.StepSeven,
  );

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepSevenValue.correct} incorrect={!stepSevenValue.correct}>
          <Text variant="md" fontWeight={700}>
            Correct, the best option is B.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <AlexAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          <Grid item>
            <Text variant="sm">
              Your Mentoring Lead is available for support but before reaching out to them, meet
              with Alex to get to know each other and explore why the relationship might work
              (rather than why it might not work).
            </Text>
          </Grid>
          <Grid item>
            <Text variant="sm">
              Ask what Alex wants to achieve from the program and share what skills, advice and
              perspective you can offer them.
            </Text>
          </Grid>
          <Grid item>
            <Text variant="sm" marginTop={20}>
              Ask them questions like:
            </Text>
          </Grid>
          <Grid item>
            <Text variant="md">"What are you hoping to get out of the mentoring program?"</Text>
            <Text variant="md">"What experiences can I share with you that might be helpful?"</Text>
          </Grid>
        </Grid>
        <Grid item>
          <Text variant="sm">
            If the relationship is unproductive, unpair yourselves in the platform.
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
};
