import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { ScaleText } from '../../../../../Components/General/ScaleText';

export const MakeTheMostRatingArrow = () => {
  return (
    <Grid container direction="column" item xs={12}>
      <ScaleText scale={10} description="Excellent knowledge, skill and confidence" />
      <ScaleText scale={5} description="Good knowledge, and skill but lack confidence" />
      <ScaleText scale={1} description="No current knowledge, skill and confidence" />
    </Grid>
  );
};
