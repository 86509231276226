import * as React from 'react';
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';

interface IBrancherSnackbar extends SnackbarProps {
  controlClose: (a: boolean) => void;
}

export const BrancherSnackbar = (props: IBrancherSnackbar) => {
  const { open, controlClose, message, ...other } = props;

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    controlClose(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={3500}
      onClose={handleClose}
      message={message}
      {...other}
    />
  );
};
