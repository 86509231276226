import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import capitalize from '@material-ui/core/utils/capitalize';
import { useDispatch, useSelector } from 'react-redux';
import { TrainingIntroduction } from '../../TrainingIntroduction';
import { IStoreTypes } from '../../../../store/storeTypes';
import {
  getTrainingModuleHeading,
  getTrainingModuleNumber,
} from '../../TrainingModuleDefaultConfig';
import { Text } from '../../../../Components/General/Text';
import { BrancherQuestionMarker } from '../../../../Components/General/BrancherQuestionMarker';
import { SegmentedControlGroup } from '../../../../Form/FormFieldTypes/SegmentedControl';
import { CompetencePopover } from '../sharedComps/CompetencePopover';
import {
  SaveGivingReceivingFeedbackMenteeData,
  SaveGivingReceivingFeedbackMentorData,
} from '../../../../store/actions';
import { CompetenceScale } from '../sharedComps/CompetenceScale';
import { ProgramPositions } from '../../../../QualifyingForm/ProgramPositionOptions';
import { ConfidenceScale } from '../sharedComps/ConfidenceScale';

export const GivingReceivingFeedbackSplash = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const trainingModules = useSelector((state: IStoreTypes) => state.training.modules)[sesPos];
  const trainingConfig = useSelector((state: IStoreTypes) => state.training.moduleConfig)[sesPos];
  const isMentee = sesPos === ProgramPositions.mentee;
  const modulePathPos = isMentee ? ProgramPositions.mentee : ProgramPositions.mentor;
  const modName = `givingReceivingFeedback${capitalize(modulePathPos)}`;
  const givingReceivingFeedbackBaseline = useSelector(
    (state: IStoreTypes) => state.training[modName],
  );
  const dispatch = useDispatch();
  const [anchorEl, setAnchor] = React.useState(null);
  const open = Boolean(anchorEl);

  const controlValidator = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!Boolean(anchorEl)) {
      setAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(anchorEl)) {
      setAnchor(null);
    }
  };

  const updateBaseline = (val: string, attr: string) => {
    if (sesPos === ProgramPositions.mentee) {
      dispatch(SaveGivingReceivingFeedbackMenteeData({ [attr]: val }));
    } else {
      dispatch(SaveGivingReceivingFeedbackMentorData({ [attr]: val }));
    }
  };

  return (
    <TrainingIntroduction
      title={`Training #${getTrainingModuleNumber(
        modName,
        trainingModules,
        trainingConfig,
      )}: ${getTrainingModuleHeading(modName, trainingConfig)}`}
      subHeader="Complete this training to learn more about how to give and receive effective feedback."
      activityLearnings={[
        `Give effective feedback to your ${
          isMentee ? ProgramPositions.mentor : ProgramPositions.mentee
        }`,
        'Receive feedback positively and effectively',
        'Adapt your communication style to positively impact feedback conversations',
      ]}
      modulePath={modName}
      validatedValue={
        givingReceivingFeedbackBaseline?.competenceBaseline != null &&
        givingReceivingFeedbackBaseline?.confidenceBaseline != null
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box marginTop={3} marginBottom={3}>
            <Text variant="sm" fontWeight={700} display="inline">
              To provide us with a baseline measure, please rate your current level of{' '}
            </Text>
            <Text variant="sm" fontWeight={700} display="inline" color="purple">
              competence and confidence
            </Text>
            <Text variant="sm" fontWeight={700} display="inline">
              {' '}
              to perform the behaviours above.{' '}
            </Text>
            <BrancherQuestionMarker onClick={controlValidator} />
          </Box>
          <CompetencePopover
            open={open}
            setClose={closeValidator}
            anchor={anchorEl}
            labelledBy="Confidence and competence"
          />
          <Grid item>
            <Text variant="sm" fontWeight={700} marginBottom={10}>
              Please rate your competence:
            </Text>
          </Grid>
          <CompetenceScale />
          <SegmentedControlGroup
            value={givingReceivingFeedbackBaseline?.competenceBaseline}
            valueLength={7}
            updateValue={(v: string) => updateBaseline(v, 'competenceBaseline')}
            fullWidth
          />
          <Grid item>
            <Text variant="sm" fontWeight={700} marginBottom={10}>
              Please rate your confidence:
            </Text>
          </Grid>
          <ConfidenceScale />
          <SegmentedControlGroup
            value={givingReceivingFeedbackBaseline?.confidenceBaseline}
            valueLength={7}
            updateValue={(v: string) => updateBaseline(v, 'confidenceBaseline')}
            fullWidth
          />
        </Grid>
      </Grid>
    </TrainingIntroduction>
  );
};
