const entityMap = {
  '<': '&lt;',
  '>': '&gt;',
};

export const sanitizeInput = (input: string): string => {
  return input.replace(/[<>]/g, (s) => {
    return entityMap[s];
  });
};

export const removeEmptyStringElements = (obj) => {
  for (const p in obj) {
    if (typeof obj[p] === 'object') {
      // dive deeper in
      removeEmptyStringElements(obj[p]);
    } else if (obj[p] === '') {
      // delete elements that are empty strings
      delete obj[p];
    }
  }
  return obj;
};
