export enum ESessionAttributes {
  SIGNUP_PROGRAM_ID = 'pId',
  USER_EMAIL = 'uemail',
  PREVIOUS_PROGRAM_ID = 'prevPId',
  HAS_SAML = 'hasSaml',
  ACCEPT_COOKIES = 'acceptCookies',
}

const hasSessionStorage: boolean = typeof sessionStorage !== 'undefined';

export const getSessionAttribute = (attributeName: ESessionAttributes): string =>
  hasSessionStorage ? sessionStorage.getItem(attributeName) : '';

export const setSessionAttribute = (attributeName: ESessionAttributes, attributeValue: string) =>
  hasSessionStorage ? sessionStorage.setItem(attributeName, attributeValue) : '';

export const getLocalStorageAttribute = (attributeName: ESessionAttributes): string =>
  hasSessionStorage ? localStorage.getItem(attributeName) : '';

export const setLocalStorageAttribute = (
  attributeName: ESessionAttributes,
  attributeValue: string,
) => (hasSessionStorage ? localStorage.setItem(attributeName, attributeValue) : '');
