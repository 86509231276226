import * as React from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { BrancherTheme } from './BrancherTheme/BrancherTheme';
import { Root } from './Root';

const App = () => {
  return (
    <ThemeProvider theme={createMuiTheme(BrancherTheme as any)}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Root />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
