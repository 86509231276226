import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Youtube } from '../../Components/General/YouTube';
import { GetRoute } from '../../Components/Routing';
import { PrevButton } from '../../Components/InputFields/BrancherButton';
import { Text } from '../../Components/General/Text';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { IStoreTypes } from '../../store/storeTypes';
import { UtilSaveModuleData } from '../../store/actions';
import { BrancherTrainingImage } from '../../Components/General/BrancherTrainingImage';
import { ICustomMod } from '../../store/reducerTypes/TrainingReducer.types';

const useStyles = makeStyles({
  width: {
    maxWidth: 900,
    minWidth: '100%',
  },
});

interface ICustomTrainingModule {
  module: string;
}

export const CustomTrainingModule = (props: ICustomTrainingModule) => {
  const { module } = props;
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const progress = useSelector((state: IStoreTypes) => state.training.progress);
  const customModule = useSelector((state: IStoreTypes) => state.training.customModules)[module];
  const dispatch = useDispatch();
  const styles = useStyles();

  const updateCustomModuleProgress = () => {
    if (progress[module] === undefined || !progress[module]?.completed) {
      // tslint:disable-next-line:no-empty
      dispatch(UtilSaveModuleData(module, () => {}, false, true));
    }
  };

  const createModuleSection = (mod: ICustomMod, index: number) => {
    if (customModule[index]) {
      const nextIndex = index + 1;
      const prevIndex = index - 1;
      if (mod.componentType === 'text' || mod.componentType === 'heading') {
        const textComp = (
          <Text
            variant={mod.componentType === 'heading' ? 'xl' : 'md'}
            display={mod.displayInline ? 'inline' : 'block'}
          >
            {mod.content}
          </Text>
        );
        return !mod.displayInline ||
          (mod.displayInline && index > 0 && !(customModule[prevIndex] as any)?.displayInline) ? (
          <Grid item key={index}>
            {textComp}
            {(customModule[nextIndex] as any)?.displayInline &&
              mod.displayInline &&
              index < customModule.length &&
              createModuleSection(customModule[nextIndex], nextIndex)}
          </Grid>
        ) : (
          <>
            {textComp}
            {(customModule[nextIndex] as any)?.displayInline &&
              mod.displayInline &&
              index < customModule.length &&
              createModuleSection(customModule[nextIndex], nextIndex)}
          </>
        );
      } else if (mod.componentType === 'link') {
        const linkComp = (
          <BrancherLinkText
            underline="always"
            fontWeight={600}
            variant="md"
            href={mod.href}
            display={mod.displayInline ? 'inline' : 'block'}
            target="_blank"
          >
            {mod.content}
          </BrancherLinkText>
        );
        return !mod.displayInline ||
          (mod.displayInline && index > 0 && !(customModule[prevIndex] as any)?.displayInline) ? (
          <Grid item key={index++}>
            {linkComp}
            {(customModule[nextIndex] as any)?.displayInline &&
              mod.displayInline &&
              index < customModule.length &&
              createModuleSection(customModule[nextIndex], nextIndex)}
          </Grid>
        ) : (
          <>
            {linkComp}
            {(customModule[nextIndex] as any)?.displayInline &&
              mod.displayInline &&
              index < customModule.length &&
              createModuleSection(customModule[nextIndex], nextIndex)}
          </>
        );
      } else if (mod.componentType === 'image') {
        return <BrancherTrainingImage src={mod.src} alt={`custom-training-${index}`} key={index} />;
      } else if (mod.componentType === 'video') {
        return <Youtube src={mod.src} height={isMobile ? 300 : 500} width="100%" key={index} />;
      }
    }
  };

  let dynamicMod: React.ReactNode[] = [];
  customModule.forEach((mod, index) => {
    const prevIndex = index - 1;
    if (
      (!(customModule[prevIndex] as any)?.displayInline && (mod as any).displayInline) ||
      !(mod as any).displayInline
    ) {
      dynamicMod.push(createModuleSection(mod, index));
    }
  });

  return (
    <Grid container direction="column" alignItems="center" className={styles.width}>
      <Grid item xs={11} container>
        <Box marginTop={5} marginBottom={3}>
          <Link to={GetRoute('training').path} onClick={updateCustomModuleProgress}>
            <PrevButton variant="text" color="primary">
              Back
            </PrevButton>
          </Link>
        </Box>
      </Grid>
      <Grid container direction="column" spacing={3} item xs={11}>
        {dynamicMod}
      </Grid>
    </Grid>
  );
};
