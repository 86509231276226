import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';
import { Text } from '../../../../../Components/General/Text';
import { GetIllustrationLink } from '../../../../../utils/LinkUtils';
import { SuggestionCard } from '../../../Modules/SuggestionCard';

export const SurveyStepSeven = () => {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={600}>
          Thank you for completing this survey!
        </Text>
      </Grid>
      <Grid item container justify="center">
        <BrancherTrainingImage src={GetIllustrationLink('connectBalloon.png')} alt="balloon" />
      </Grid>
      <Grid item>
        <SuggestionCard>
          Make sure you click the "✓" button to complete this training.
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
