import { Colors } from '../consts/colors';

export const MuiSwitch = () => ({
  switchBase: {
    color: Colors.lightPurple,
    '&$checked': {
      color: Colors.purple,
    },
  },
  colorPrimary: {
    '&.Mui-checked': {
      color: Colors.purple,
      '& + $track': {
        backgroundColor: Colors.purple,
      },
    },
  },
});
