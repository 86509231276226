import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { MoDBeGreatMenteeStepEightIncorrect } from './Steps/MoDBeGreatMenteeStepEightIncorrect';
import { MoDBeGreatMenteeStepOne } from './Steps/MoDBeGreatMenteeStepOne';
import { MoDBeGreatMenteeStepSixIncorrect } from './Steps/MoDBeGreatMenteeStepSixIncorrect';
import { MoDBeGreatMenteeStepTwo } from './Steps/MoDBeGreatMenteeStepTwo';
import { MoDBeGreatMenteeStepThree } from './Steps/MoDBeGreatMenteeStepThree';
import { MoDBeGreatMenteeStepFour } from './Steps/MoDBeGreatMenteeStepFour';
import { MoDBeGreatMenteeStepFive } from './Steps/MoDBeGreatMenteeStepFive';
import { MoDBeGreatMenteeStepSix } from './Steps/MoDBeGreatMenteeStepSix';
import { MoDBeGreatMenteeStepSeven } from './Steps/MoDBeGreatMenteeStepSeven';
import { MoDBeGreatMenteeStepEight } from './Steps/MoDBeGreatMenteeStepEight';
import { MoDBeGreatMenteeStepNine } from './Steps/MoDBeGreatMenteeStepNine';
import { MoDBeGreatMenteeStepTen } from './Steps/MoDBeGreatMenteeStepTen';
import { MoDBeGreatMenteeStepEleven } from './Steps/MoDBeGreatMenteeStepEleven';
import { MoDBeGreatMenteeStepFiveCorrect } from './Steps/MoDBeGreatMenteeStepFiveCorrect';
import { MoDBeGreatMenteeStepSixCorrect } from './Steps/MoDBeGreatMenteeStepSixCorrect';
import { MoDBeGreatMenteeStepEightCorrect } from './Steps/MoDBeGreatMenteeStepEightCorrect';

export const MoDBeGreatMenteeModuleMapper: IModuleMapper[] = [
  {
    name: 'MoDBeGreatMenteeStepOne',
    component: <MoDBeGreatMenteeStepOne />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    nextComponent: 'MoDBeGreatMenteeStepTwo',
  },
  {
    name: 'MoDBeGreatMenteeStepTwo',
    component: <MoDBeGreatMenteeStepTwo />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MoDBeGreatMenteeStepOne',
    nextComponent: 'MoDBeGreatMenteeStepThree',
  },
  {
    name: 'MoDBeGreatMenteeStepThree',
    component: <MoDBeGreatMenteeStepThree />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MoDBeGreatMenteeStepTwo',
    nextComponent: 'MoDBeGreatMenteeStepFour',
  },
  {
    name: 'MoDBeGreatMenteeStepFour',
    component: <MoDBeGreatMenteeStepFour />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MoDBeGreatMenteeStepThree',
    nextComponent: 'MoDBeGreatMenteeStepFive',
  },
  {
    name: 'MoDBeGreatMenteeStepFive',
    component: <MoDBeGreatMenteeStepFive />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'MoDBeGreatMenteeStepFour',
    correctComponent: 'MoDBeGreatMenteeStepFiveCorrect',
    incorrectComponent: 'MoDBeGreatMenteeStepFiveCorrect',
  },
  {
    name: 'MoDBeGreatMenteeStepFiveCorrect',
    component: <MoDBeGreatMenteeStepFiveCorrect />,
    iterateStepper: true,
    previousComponent: 'MoDBeGreatMenteeStepFive',
    nextComponent: 'MoDBeGreatMenteeStepSix',
  },
  {
    name: 'MoDBeGreatMenteeStepSix',
    component: <MoDBeGreatMenteeStepSix />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'MoDBeGreatMenteeStepFive',
    correctComponent: 'MoDBeGreatMenteeStepSixCorrect',
    incorrectComponent: 'MoDBeGreatMenteeStepSixIncorrect',
  },
  {
    name: 'MoDBeGreatMenteeStepSixCorrect',
    component: <MoDBeGreatMenteeStepSixCorrect />,
    iterateStepper: true,
    previousComponent: 'MoDBeGreatMenteeStepSix',
    nextComponent: 'MoDBeGreatMenteeStepSeven',
  },
  {
    name: 'MoDBeGreatMenteeStepSixIncorrect',
    component: <MoDBeGreatMenteeStepSixIncorrect />,
    iterateStepper: true,
    previousComponent: 'MoDBeGreatMenteeStepSix',
    nextComponent: 'MoDBeGreatMenteeStepSeven',
  },
  {
    name: 'MoDBeGreatMenteeStepSeven',
    component: <MoDBeGreatMenteeStepSeven />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MoDBeGreatMenteeStepSix',
    nextComponent: 'MoDBeGreatMenteeStepEight',
  },
  {
    name: 'MoDBeGreatMenteeStepEight',
    component: <MoDBeGreatMenteeStepEight />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'MoDBeGreatMenteeStepSeven',
    correctComponent: 'MoDBeGreatMenteeStepEightCorrect',
    incorrectComponent: 'MoDBeGreatMenteeStepEightIncorrect',
  },
  {
    name: 'MoDBeGreatMenteeStepEightCorrect',
    component: <MoDBeGreatMenteeStepEightCorrect />,
    iterateStepper: true,
    previousComponent: 'MoDBeGreatMenteeStepEight',
    nextComponent: 'MoDBeGreatMenteeStepNine',
  },
  {
    name: 'MoDBeGreatMenteeStepEightIncorrect',
    component: <MoDBeGreatMenteeStepEightIncorrect />,
    iterateStepper: true,
    previousComponent: 'MoDBeGreatMenteeStepEight',
    nextComponent: 'MoDBeGreatMenteeStepNine',
  },
  {
    name: 'MoDBeGreatMenteeStepNine',
    component: <MoDBeGreatMenteeStepNine />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MoDBeGreatMenteeStepEight',
    nextComponent: 'MoDBeGreatMenteeStepTen',
  },
  {
    name: 'MoDBeGreatMenteeStepTen',
    component: <MoDBeGreatMenteeStepTen />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MoDBeGreatMenteeStepNine',
    nextComponent: 'MoDBeGreatMenteeStepEleven',
  },
  {
    name: 'MoDBeGreatMenteeStepEleven',
    component: <MoDBeGreatMenteeStepEleven />,
    iterateStepper: true,
    needsInteraction: true,
    submit: true,
    previousComponent: 'MoDBeGreatMenteeStepTen',
  },
];
