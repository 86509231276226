import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../Components/General/Text';
import { AcceptButton, RejectButton } from '../../Components/InputFields/BrancherButton';
import { EMentoringRequestStatus } from '../../store/reducers/CohortReducer';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';

interface IConfirmMentoringRequestResponse {
  status: EMentoringRequestStatus;
  submittingMentoringRequestStatusResponse: boolean;
  declineConfirmation: () => void;
  acceptConfirmation: (message?: string) => void;
}

export const ConfirmMentoringRequestResponse: React.FC<IConfirmMentoringRequestResponse> = (
  props,
) => {
  const {
    status,
    submittingMentoringRequestStatusResponse,
    declineConfirmation,
    acceptConfirmation,
  } = props;
  const [responseMessage, setResponseMessage] = React.useState<string>('');

  return (
    <Grid container justify="center" spacing={3} alignItems="center">
      <Grid item xs={12} md={10}>
        <Text variant="md" fontWeight={600}>
          Are you sure you want to{' '}
          {status === EMentoringRequestStatus.ACCEPTED
            ? 'accept'
            : status === EMentoringRequestStatus.DECLINED
            ? 'decline'
            : 'cancel'}{' '}
          this request?
        </Text>
      </Grid>
      <Grid item xs={12} md={10}>
        <BrancherTextField
          label="Send response message"
          value={responseMessage}
          fullWidth
          updateValue={setResponseMessage}
        />
      </Grid>
      <Grid item xs={12} md={10} container justify="space-between">
        <RejectButton
          onClick={declineConfirmation}
          disabled={submittingMentoringRequestStatusResponse}
        >
          No
        </RejectButton>
        <AcceptButton
          onClick={() => acceptConfirmation(responseMessage)}
          disabled={submittingMentoringRequestStatusResponse}
        >
          Yes
        </AcceptButton>
      </Grid>
    </Grid>
  );
};
