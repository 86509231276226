import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import { SaveIntroductionMentor } from '../../../../../store/actions';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { ScaleText } from '../../../../../Components/General/ScaleText';

export const IntroductionMentorStepSeven = () => {
  const StepSevenValue = useSelector((state: IStoreTypes) => state.training.introductionMentor);
  const dispatch = useDispatch();

  const updateValue = (val: number) => {
    dispatch(
      SaveIntroductionMentor({
        ...StepSevenValue,
        StepSeven: {
          selected: val,
          correct: val >= 5,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" display="inline" fontWeight={700}>
          You have almost finished the training.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          How confident are you to facilitate a great first meeting?
        </Text>
      </Grid>
      <Grid item>
        <ScaleText scale={10} description="extremely confident" />
        <ScaleText scale={5} description="confident but still want some help" />
        <ScaleText scale={1} description="no confidence" />
        <SegmentedControlGroup
          fullWidth
          value={StepSevenValue.StepSeven?.selected}
          valueLength={10}
          updateValue={updateValue}
        />
      </Grid>
    </Grid>
  );
};
