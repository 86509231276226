import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Text } from '../General/Text';

interface IFormWithStepFieldLayout {
  step: number;
  question: string | React.ReactNode;
  children?: React.ReactNode;
  labelIcon?: React.ReactNode;
  subText?: string;
}

export const FormWithStepFieldLayout = (props: IFormWithStepFieldLayout) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { step, question, children, subText, labelIcon } = props;
  return (
    <>
      <Grid item xs={12} container direction="row">
        <Grid item>
          <Text variant="xs" color="purple" marginRight={8} borderBottom>
            {step < 10 ? `0${step}` : step}.
          </Text>
        </Grid>
        <Grid item xs={10}>
          <Grid item xs={12} container justify="space-between">
            <Grid item>
              {typeof question === 'string' ? (
                <Text variant="sm" fontWeight={700} display="inline">
                  {question}
                </Text>
              ) : (
                question
              )}
            </Grid>
            <Grid item>{labelIcon}</Grid>
          </Grid>
          {subText && (
            <Text variant="xs" marginTop={5} color="tertiaryGrey">
              {subText}
            </Text>
          )}
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <Box marginLeft={mobile ? 3 : 4}>{children}</Box>
      </Grid>
    </>
  );
};
