import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { MentoringGuideLink } from '../../../../../Components/General/MentoringGuideLink';
import { Text } from '../../../../../Components/General/Text';
import { GetRoute } from '../../../../../Components/Routing';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { SallyAvatar } from '../../avatars/Sally';

export const MoDBeGreatMenteeStepFiveCorrect = () => {
  const stepFiveValue = useSelector(
    (state: IStoreTypes) => state.training.modBeGreatMentee?.StepFive,
  );

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepFiveValue.correct} incorrect={!stepFiveValue.correct}>
          <Text variant="md" fontWeight={700}>
            {stepFiveValue.correct ? 'Correct!' : 'Not quite.'}
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <SallyAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          {stepFiveValue.correct ? (
            <Text variant="sm">
              It’s best to do all of those things to prepare for your first meeting.
            </Text>
          ) : (
            <Text variant="sm">The correct response was all of the options.</Text>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Make sure you’re familiar with the ‘Preparing for your first meeting’ page in the{' '}
        </Text>
        <MentoringGuideLink variant="md" />
        <Text variant="md" display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Remember to send through your proposed meeting agenda to your mentor ahead of time and be
          prepared to complete your{' '}
        </Text>
        <BrancherLinkText
          underline="always"
          fontWeight={600}
          variant="md"
          href={GetRoute('mentoringAgreement').path}
          display="inline"
          target="_blank"
        >
          Mentoring Agreement
        </BrancherLinkText>
        <Text variant="md" display="inline">
          {' '}
          during your first meeting.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          After the meeting, it’s best practice to send a summary email to your mentor addressing
          the topics you discussed and actions.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          This will help you with planning your next meeting agenda, strategies for achieving your
          goals and hold you accountable for the progression of goals in the program.
        </Text>
      </Grid>
    </Grid>
  );
};
