import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { SallyAvatar } from '../../avatars/Sally';

export const BeGreatMenteeStepSevenCorrect = () => {
  const stepSevenValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentee.StepSeven,
  );
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepSevenValue.correct} incorrect={!stepSevenValue.correct}>
          <Text variant="md" fontWeight={700}>
            {stepSevenValue.correct ? 'Correct!' : 'Not quite.'}
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <SallyAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          {stepSevenValue.correct ? (
            <Text variant="sm">
              This is displaying a growth mindset by assuming that your manager's behaviour can
              change. You are also being honest with how the lack of regular meetings are impacting
              you whilst displaying initiative, positivity and problem-solving by proposing a
              solution.
            </Text>
          ) : stepSevenValue.selected === '1' ? (
            <Text variant="sm">
              Threatening to leave the company is an unprofessional way of approaching the
              conversation. Whilst your manager's behaviour may change, your threat may have other
              negative unintended consequences.
            </Text>
          ) : (
            <Text variant="sm">
              Whilst it is good to seek support from others, talking to other colleagues or your
              manager's manager without talking to your manager first is disrespectful. You should
              try and resolve the situation with your manager first.
            </Text>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
