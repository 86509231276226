import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { CharlieAvatar } from '../../avatars/Charlie';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const BeGreatMentorStepFive = () => {
  const stepFiveValue = useSelector((state: IStoreTypes) => state.training.beGreatMentor.StepFive);
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepFiveCorrectVal = '1';
    dispatch(
      SaveBeGreatMentorData({
        StepFive: {
          selected: val,
          correct: StepFiveCorrectVal === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Charlie was very frustrated at a colleague...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          In another mentoring session, Charlie says, "I'm really frustrated at this colleague at
          work. They keep missing my deadlines and not responding to my emails. I don't understand
          why. I've tried following up with them. I've tried everything. What do you think I should
          do? What have you done in previous similar situations?"
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          What role should you play?
        </Text>
      </Grid>
      <Grid item container justify="center">
        <SelectionControl
          question=""
          name="stepFiveValue"
          value={stepFiveValue?.selected}
          updateValue={updateValue}
          noQuestion
          exclusive
          options={MakeOptions(['Director (i.e. telling)', 'Coach (i.e. asking)'])}
        />
      </Grid>
    </Grid>
  );
};
