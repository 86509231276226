import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Text } from '../../../../../Components/General/Text';
import { Youtube } from '../../../../../Components/General/YouTube';

export const IntroductionMenteeStepFiveIncorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          We can help you feel more confident than that.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Check out this video helpful tips for establishing a Mentoring Agreement.
        </Text>
      </Grid>
      <Box marginLeft={2} marginTop={4} marginBottom={10} height={300}>
        <Youtube src="https://youtu.be/yKU1iOxHGD8?cc_load_policy=1" />
      </Box>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          Now you're ready to move on to learning about creating a good first meeting. Select next.
        </Text>
      </Grid>
    </Grid>
  );
};
