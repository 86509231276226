import * as React from 'react';
import { ModuleWrapper } from '../ModuleWrapper';
import { EasyEnglishMentorModuleMapper } from './EasyEnglishMentorModuleMapper';

export const EasyEnglishMentor = () => (
  <ModuleWrapper
    moduleMap={EasyEnglishMentorModuleMapper}
    moduleName="easyEnglishMentor"
    title="Easy English Mentor Guide"
  />
);
