import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  backButton: {
    width: 'fit-content',
  },
});

export const BrancherRouterLink = (props: LinkProps) => {
  const styles = useStyles(props);
  return (
    <Link className={styles.backButton} {...props}>
      {props.children}
    </Link>
  );
};
