import * as React from 'react';
import { SuggestionCard } from '../SuggestionCard';
import { Text } from '../../../../Components/General/Text';
import { BrancherLinkText } from '../../../../Components/General/BrancherLinkText';

export const FeedbackStepsCard = () => (
  <SuggestionCard custom>
    <Text variant="sm" display="inline">
      Click{' '}
    </Text>
    <BrancherLinkText
      variant="sm"
      underline="always"
      display="inline"
      target="_blank"
      href="https://media.brancher.com.au/training/givingReceivingFeedbackMentee/receiving_feedback_effectively.pdf"
    >
      here
    </BrancherLinkText>
    <Text variant="sm" display="inline">
      {' '}
      to have another look at the 9 steps.
    </Text>
  </SuggestionCard>
);
