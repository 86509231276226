import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMoDGoalSettingMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const MoDGoalSettingMentorStepSix = () => {
  const stepSixValue = useSelector(
    (state: IStoreTypes) => state.training?.[EMoDTrainingModules.MOD_GOAL_SETTING_MENTOR]?.StepSix,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepSixCorrectVal = ['0', '1', '2', '3', '4'];
    dispatch(
      SaveMoDGoalSettingMentorData({
        StepSix: {
          selected: val,
          correct:
            val?.includes(StepSixCorrectVal[0]) &&
            val?.includes(StepSixCorrectVal[1]) &&
            val?.includes(StepSixCorrectVal[2]) &&
            val?.includes(StepSixCorrectVal[3]) &&
            val?.includes(StepSixCorrectVal[4]),
        },
      }),
    );
  };

  const options: string[] = [
    'A. Specific',
    'B. Measurable',
    'C. Attainable',
    'D. Relevant',
    'E. Timely',
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center">
        <Text variant="lg" fontWeight={700}>
          Goal #2
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          To help me get a new job, I will submit my CV and a cover letter for at least one job
          application in the next 2 weeks.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">Is this goal...</Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepSixValue"
          value={stepSixValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(options)}
        />
      </Grid>
      <Grid item>
        <Text variant="sm" marginBottom={-30}>
          You can select more than 1 option.
        </Text>
      </Grid>
    </Grid>
  );
};
