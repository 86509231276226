import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { NegativeButton, NextButton } from '../Components/InputFields/BrancherButton';
import { BrancherDivider, BrancherThickDivider } from '../Components/General/BrancherDivider';
import { Text } from '../Components/General/Text';
import { BrancherLinkText } from '../Components/General/BrancherLinkText';
import {
  CreateMentoringGuideLink,
  PDFLink,
} from '../PostMatching/Training/Modules/helpers/LinkBuilder';
import { IQualifyingFormState } from './QualifyingForm';
import { SaveQualifyFormData } from '../store/actions';
import { GetRoute } from '../Components/Routing';

export const TnCs = (props: IQualifyingFormState) => {
  const dispatch = useDispatch();
  const { step, setStep } = props;

  return (
    <Grid container direction="column" justify="center">
      <BrancherThickDivider marginTop={40} marginBottom={20} />
      <Grid item>
        <Text variant="xl" display="inline">
          Please agree to the{' '}
        </Text>
        <BrancherLinkText
          variant="xl"
          target="_blank"
          underline="always"
          href={PDFLink.END_USER_TERMS_LINK}
          display="inline"
        >
          Terms
        </BrancherLinkText>
        <Text variant="xl" display="inline">
          {' '}
          and{' '}
        </Text>
        <BrancherLinkText
          variant="xl"
          target="_blank"
          underline="always"
          href={PDFLink.PRIVACY_POLICY_LINK}
          display="inline"
        >
          Privacy Policy
        </BrancherLinkText>
        <Text variant="xl" display="inline">
          {' '}
          and{' '}
        </Text>
        <BrancherLinkText
          variant="xl"
          target="_blank"
          underline="always"
          href={CreateMentoringGuideLink()}
          display="inline"
        >
          Code of Conduct
        </BrancherLinkText>
        <Text variant="xl" display="inline">
          {' '}
          in the Mentoring Guide before proceeding.
        </Text>
      </Grid>
      <BrancherDivider marginTop={20} marginBottom={25} />
      <Grid item container direction="row" alignItems="center" justify="space-between">
        <Grid item xs={5}>
          <NegativeButton
            fullWidth
            onClick={() => {
              dispatch(SaveQualifyFormData({ tnc: false }));
              setStep(step - 1);
            }}
          >
            Not yet
          </NegativeButton>
        </Grid>
        <Grid item xs={5}>
          <Link to={GetRoute('home').path}>
            <NextButton
              fullWidth
              onClick={() => {
                dispatch(SaveQualifyFormData({ tnc: true, confirmResponsibility: true }));
                setStep(step + 1);
              }}
            >
              Agree
            </NextButton>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};
