import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SonalAvatar } from '../../avatars/Sonal';

export const GivingReceivingFeedbackMenteeStepFive = () => {
  return (
    <Grid container spacing={2} direction="column" alignItems="center">
      <Grid item>
        <Text variant="md" display="inline">
          We've learnt about{' '}
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          giving feedback
        </Text>
        <Text variant="md" display="inline">
          , go to the next page to learn about{' '}
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          receiving feedback
        </Text>
        <Text variant="md" display="inline">
          .
        </Text>
      </Grid>
      <Grid item container xs={7} justify="center">
        <SonalAvatar />
      </Grid>
    </Grid>
  );
};
