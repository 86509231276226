import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { Text } from '../../../../../Components/General/Text';
import { GetRoute } from '../../../../../Components/Routing';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';
import { GetTrainingResourceLink } from '../../../../../utils/LinkUtils';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const MoDBeGreatMenteeStepFour = () => {
  const isMentee =
    useSelector((state: IStoreTypes) => state.user.sessionPosition) === ProgramPositions.mentee;

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="lg" fontWeight={600}>
          Expectations and boundaries
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline" fontWeight={400}>
          It is critical for every mentoring relationship to have a{' '}
        </Text>
        <BrancherLinkText
          underline="always"
          variant="md"
          href={GetRoute('mentoringAgreement').path}
          display="inline"
          target="_blank"
        >
          Mentoring Agreement
        </BrancherLinkText>
        <Text variant="md" display="inline" fontWeight={400}>
          . Even if you have been in a mentoring relationship before, you must complete a new
          Mentoring Agreement for every new relationship.
        </Text>
      </Grid>
      <Grid item>
        <BrancherTrainingImage
          src={GetTrainingResourceLink(
            isMentee
              ? EMoDTrainingModules.MOD_BE_GREAT_MENTEE
              : EMoDTrainingModules.MOD_BE_GREAT_MENTOR,
            'mentoring-agreement-tick.png',
          )}
          alt="mentoring-agreement-tick"
        />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={400}>
          The purpose of this agreement is to clarify expectations and boundaries. This agreement
          helps to prevent any miscommunication and misunderstanding. The agreement should be
          discussed and completed during your first meeting. The mentee is responsible for
          completing the online agreement however the mentor can edit the shared document at any
          time.
        </Text>
      </Grid>
    </Grid>
  );
};
