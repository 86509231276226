import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SallyAvatar } from '../../avatars/Sally';

export const BeGreatMenteeStepNine = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <SallyAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            "Great. What else?"
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">Sally responds with, "Great. What else?"</Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          You say, "I suppose we also have a great relationship, despite my frustrations. So I could
          use that to my advantage."
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Sally asks, "That's great. So in summary, what are some specific action steps you can do
          that will help you achieve your goal of having more regular, ideally weekly, 1:1s with
          your manager?"
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          You explain, "I could send an email to my manager requesting we meet to discuss the
          importance of frequent meet-ups. I could prepare for the conversation by reflecting on my
          strengths and how I could leverage these during the conversation. I could also develop
          some talking points to ensure I'm prepared."
        </Text>
      </Grid>
    </Grid>
  );
};
