import * as React from 'react';
import { ModuleWrapper } from '../ModuleWrapper';
import { MakeTheMostMentorModuleMapper } from './MakeTheMostMentorModuleMapper';

export const MakeTheMostMentor = () => (
  <ModuleWrapper
    moduleMap={MakeTheMostMentorModuleMapper}
    moduleName="makeTheMostMentor"
    title="Making the most of mentoring"
  />
);
