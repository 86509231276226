import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';
import { Text } from '../../Components/General/Text';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { GetRoute } from '../../Components/Routing';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { MeetingsSection } from './MeetingsSection';

export const AddMeetingSection = () => {
  return (
    <>
      <Grid item container justify="space-between" alignItems="center">
        <Text variant="lg" fontWeight={700}>
          Meetings
        </Text>
        <BrancherButton
          variant="outlined"
          color="secondary"
          size="small"
          href={GetRoute('meeting').path}
          startIcon={<CalendarTodayOutlined />}
          aria-label="create-meeting-action"
        >
          Schedule a Meeting
        </BrancherButton>
      </Grid>
      <Grid item>
        <BrancherDivider marginBottom={20} marginTop={10} height={1} />
      </Grid>
      <MeetingsSection />
    </>
  );
};
