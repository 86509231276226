import { IReducerType } from './ReducerType';
import { EValues } from '../../Form/Values/ValuesFormConfig';

export const VALUES_FORM_DATA_V2 = 'VALUES_FORM_DATA_V2';
export const VALUES_FORM_DATA = 'VALUES_FORM_DATA';
export const VALUES_CLEAR_FORM_DATA = 'VALUES_CLEAR_FORM_DATA';

export const ValuesReducer = (state = initialState, action: IReducerType) => {
  switch (action.type) {
    case VALUES_FORM_DATA:
      return { ...state, ...action.payload };
    case VALUES_FORM_DATA_V2:
      const { index, ...newData } = action.payload;
      return {
        ...state,
        response: state.response?.map((a, i) => (i === index ? newData : a)),
      };
    case VALUES_CLEAR_FORM_DATA:
      return initialState;
    default:
      return state;
  }
};

export interface IValuesReducer {
  response?: Array<{ key: string; value: any }> &
    Array<{ most: EValues[]; options: EValues[]; least: EValues[] }>;
  configValues?: Array<{ key: string; value: any }> &
    Array<{ most: EValues[]; options: EValues[]; least: EValues[] }>;
}

const initialState: IValuesReducer = {
  response: [],
  configValues: [],
};
