import * as React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { Colors } from '../../consts/colors';

const useStyles = makeStyles({
  box: {
    backgroundColor: (props: { correct: boolean }) =>
      props.correct ? Colors.correct : Colors.incorrect,
  },
});

interface ICheckAnswerSection {
  correct: boolean;
  children: React.ReactNode;
}

export const CheckAnswerSection = (props: ICheckAnswerSection) => {
  const { correct, children } = props;
  const styles = useStyles({ correct });

  return (
    <Box className={styles.box} padding={4}>
      {children}
    </Box>
  );
};
