import * as React from 'react';
import { ModuleWrapper } from '../ModuleWrapper';
import { BeyondProgramMentorModuleMapper } from './BeyondProgramMentorModuleMapper';
import {
  EStructuredTrainingModules,
  ReadableTrainingModules,
} from '../../../../store/reducerTypes/TrainingReducer.types';

export const BeyondProgramMentor = () => (
  <ModuleWrapper
    moduleMap={BeyondProgramMentorModuleMapper()}
    moduleName={EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR}
    title={ReadableTrainingModules[EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR]}
  />
);
