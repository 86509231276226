import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../Components/General/Text';
import { BrancherAccordion } from '../../Components/General/BrancherAccordion';

interface IMeetingMinutesDescription {
  creatingMeeting: boolean;
}

export const MeetingMinutesDescription: React.FC<IMeetingMinutesDescription> = ({
  creatingMeeting,
}) => {
  const meetingMinutesExplained = creatingMeeting
    ? [
        'Choose a Suggested Topic (your main meeting focus)',
        'Talking Points (an agenda) will automatically be applied to your meeting invitations and reminders that you can tick off during/after your meeting. You can also add your own Talking Points.',
        "'Tick' off completed Actions and Talking Points (after you've done them) so you know what you’ve covered.",
      ]
    : [
        'Add Action items and assign ownership and due dates.',
        "'Tick' off completed Actions and Talking Points (after you've done them) so you know what you’ve covered.",
        'You can also add additional Talking Points as required (we understand not everything goes to plan and burning topics come up!)',
      ];

  return (
    <BrancherAccordion
      panels={[
        {
          name: 'meeting minutes explained',
          title: 'How to make the most of meeting minutes',
          content: (
            <Grid item xs={12} container spacing={3} direction="column" justify="center">
              {meetingMinutesExplained.map((minute, minuteIndex) => (
                <Grid item>
                  <Text variant="sm" display="inline" fontWeight={600} color="purple">
                    {minuteIndex + 1}.{' '}
                  </Text>
                  <Text variant="sm" display="inline">
                    {minute}
                  </Text>
                </Grid>
              ))}
              <Grid item>
                <Text variant="sm" display="inline">
                  Incomplete talking points and actions carry over from meeting to meeting.
                </Text>
              </Grid>
            </Grid>
          ),
        },
      ]}
    />
  );
};
