import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SuggestionCard } from '../../SuggestionCard';

export const BeyondProgramMenteeStepFiveStopIncorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom incorrect>
          <Text variant="md" fontWeight={700}>
            Not quite.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="sm" display="inline">
          Not quite. The correct answer was{' '}
        </Text>
        <Text variant="sm" display="inline" fontWeight={700}>
          C
        </Text>
        <Text variant="sm" display="inline">
          {' '}
          because this response expressed gratitude to your mentor, explained how your
          needs/capacity have changed and suggested a clear timeline to wrap things up.
        </Text>
      </Grid>
    </Grid>
  );
};
