import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SuggestionCard } from '../../SuggestionCard';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';

export const IntroductionMenteeStepThreeIncorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          That is not the best option. The best option was{' '}
        </Text>
        <Text variant="md" fontWeight={700} color="purple" display="inline">
          B
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Do you have a preference to be paired with someone similar to you? You’re not alone. As
          human beings, we prefer people who are more like ourselves. Researchers and psychologists
          have found this is due to bias. Learn more about biases{' '}
        </Text>
        <BrancherLinkText
          underline="always"
          fontWeight={700}
          variant="md"
          href="https://media.brancher.com.au/training/introductionMentee/cognitive_bias.pdf"
          display="inline"
          target="_blank"
        >
          here
        </BrancherLinkText>
        <Text variant="md" display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Being matched with someone ‘different’ can be a positive thing. Brancher creates
          complementary matches because we recognise the value of diversity and will match you with
          someone who will stretch and challenge you so that you grow as a person and a
          professional.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Your Mentoring Lead is available for support but before reaching out to them, meet with
          Alex to get to know each other and explore why the relationship might work (rather than
          why it might not work).
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Discuss what you want from the program and explore how Alex can support you to achieve
          that.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You could say things like:
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard>"Given my interests I'd love to hear about [x, y and z]."</SuggestionCard>
        <SuggestionCard>
          "I'd love to hear advice you have for me as I'm working towards this goal [or navigating
          my way through this issue]."
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="md">
          If the relationship is unproductive, inform your Mentoring Lead who will assist you to end
          the relationship professionally.
        </Text>
      </Grid>
    </Grid>
  );
};
