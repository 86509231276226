import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { CharlieAvatar } from '../../avatars/Charlie';

export const BeGreatMentorStepTenCorrect = () => {
  const stepTenValue = useSelector((state: IStoreTypes) => state.training.beGreatMentor.StepTen);
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepTenValue.correct} incorrect={!stepTenValue.correct}>
          <Text variant="md" fontWeight={700}>
            {stepTenValue.correct
              ? "That's right"
              : 'Almost there, remember you can select multiple answers'}
            .
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          <Grid item>
            <Text variant="sm" display="inline">
              Any of those responses are questions that explore{' '}
            </Text>
            <Text variant="sm" display="inline" color="purple" fontWeight={700}>
              Habits
            </Text>
            <Text variant="sm" display="inline">
              , the final step of the GROWTH model.
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
