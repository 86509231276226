import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { useDispatch, useSelector } from 'react-redux';
import {
  SaveGivingReceivingFeedbackMenteeData,
  SaveGivingReceivingFeedbackMentorData,
} from '../../../../../store/actions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const GivingReceivingFeedbackMenteeStepSix = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const sessionSaveFunction =
    position === ProgramPositions.mentee
      ? SaveGivingReceivingFeedbackMenteeData
      : SaveGivingReceivingFeedbackMentorData;
  const dispatch = useDispatch();

  const updateValue = () => {
    dispatch(
      sessionSaveFunction({
        StepSix: {
          interacted: true,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" display="inline">
          Receiving feedback can{' '}
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          increase personal growth
        </Text>
        <Text variant="md" display="inline">
          {' '}
          and{' '}
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          emotional intelligence
        </Text>
        <Text variant="md" display="inline">
          . It can also{' '}
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          improve your relationships
        </Text>
        <Text variant="md" display="inline">
          {' '}
          and help you{' '}
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          achieve higher performance
        </Text>
        <Text variant="md" display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          There are 9 steps to receiving feedback well, view them{' '}
        </Text>
        <BrancherLinkText
          onClick={updateValue}
          display="inline"
          variant="md"
          underline="always"
          target="_blank"
          href="https://media.brancher.com.au/training/givingReceivingFeedbackMentee/receiving_feedback_effectively.pdf"
        >
          here
        </BrancherLinkText>
        <Text variant="md" display="inline">
          .
        </Text>
      </Grid>
      <Grid item container justify="center">
        <BrancherTrainingImage
          src="https://media.brancher.com.au/training/givingReceivingFeedbackMentee/improve_stairs.png"
          alt="feedback-stairs"
          width="80%"
        />
      </Grid>
    </Grid>
  );
};
