import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { SaveBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { GetTrainingResourceLink } from '../../../../../utils/LinkUtils';

export const BeGreatMentorStepTwo = () => {
  const dispatch = useDispatch();

  const updateValue = () => {
    dispatch(
      SaveBeGreatMentorData({
        StepTwo: {
          interacted: true,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Being directive and coaching both have their benefits.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          There is no single best style of mentorship, it depends on the situation. There is a time
          and a place for both.{' '}
        </Text>
        <BrancherLinkText
          variant="md"
          target="_blank"
          display="inline"
          onClick={updateValue}
          underline="always"
          fontWeight={700}
          href={GetTrainingResourceLink(
            EStructuredTrainingModules.BE_GREAT_MENTOR,
            'Directive_vs_coaching.pdf',
          )}
        >
          Click here
        </BrancherLinkText>
        <Text variant="md" display="inline">
          {' '}
          to learn when to use these different styles and how they can benefit your mentee.
        </Text>
      </Grid>
    </Grid>
  );
};
