export const MuiOutlinedInput = {
  root: {
    border: 'none !important',
    borderRadius: 15,
    '&:hover': {
      border: 'none !important',
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
      },
    },
  },
};
