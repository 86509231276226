import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { EasyEnglishMentorStepOne } from './Steps/EasyEnglishMentorStepOne';
import { EasyEnglishMentorStepOneCorrect } from './Steps/EasyEnglishMentorStepOneCorrect';
import { EasyEnglishMentorStepTwo } from './Steps/EasyEnglishMentorStepTwo';
import { EasyEnglishMentorStepTwoCorrect } from './Steps/EasyEnglishMentorStepTwoCorrect';
import { EasyEnglishMentorStepThree } from './Steps/EasyEnglishMentorStepThree';
import { EasyEnglishMentorStepThreeCorrect } from './Steps/EasyEnglishMentorStepThreeCorrect';
import { EasyEnglishMentorStepFour } from './Steps/EasyEnglishMentorStepFour';

export const EasyEnglishMentorModuleMapper: IModuleMapper[] = [
  {
    name: 'EasyEnglishMentorStepOne',
    component: <EasyEnglishMentorStepOne />,
    iterateStepper: false,
    hasQuestion: true,
    correctComponent: 'EasyEnglishMentorStepOneCorrect',
    incorrectComponent: 'EasyEnglishMentorStepOneCorrect',
  },
  {
    name: 'EasyEnglishMentorStepOneCorrect',
    component: <EasyEnglishMentorStepOneCorrect />,
    iterateStepper: true,
    previousComponent: 'EasyEnglishMentorStepOne',
    nextComponent: 'EasyEnglishMentorStepTwo',
  },
  {
    name: 'EasyEnglishMentorStepTwo',
    component: <EasyEnglishMentorStepTwo />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'EasyEnglishMentorStepOne',
    correctComponent: 'EasyEnglishMentorStepTwoCorrect',
    incorrectComponent: 'EasyEnglishMentorStepTwoCorrect',
  },
  {
    name: 'EasyEnglishMentorStepTwoCorrect',
    component: <EasyEnglishMentorStepTwoCorrect />,
    iterateStepper: true,
    previousComponent: 'EasyEnglishMentorStepTwo',
    nextComponent: 'EasyEnglishMentorStepThree',
  },
  {
    name: 'EasyEnglishMentorStepThree',
    component: <EasyEnglishMentorStepThree />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'EasyEnglishMentorStepTwo',
    correctComponent: 'EasyEnglishMentorStepThreeCorrect',
    incorrectComponent: 'EasyEnglishMentorStepThreeCorrect',
  },
  {
    name: 'EasyEnglishMentorStepThreeCorrect',
    component: <EasyEnglishMentorStepThreeCorrect />,
    iterateStepper: true,
    previousComponent: 'EasyEnglishMentorStepThree',
    nextComponent: 'EasyEnglishMentorStepFour',
  },
  {
    name: 'EasyEnglishMentorStepFour',
    component: <EasyEnglishMentorStepFour />,
    iterateStepper: true,
    needsInteraction: true,
    submit: true,
    previousComponent: 'EasyEnglishMentorStepThree',
  },
];
