export const MobileFontSizes = {
  xxxs: 10,
  xxs: 13,
  xs: 13,
  sm: 15,
  md: 16,
  lg: 18,
  xl: 20,
  xxl: 24,
  xxxl: 48,
};

export const TabletDesktopFontSizes = {
  xxxs: 12,
  xxs: 15,
  xs: 18,
  sm: 20,
  md: 25,
  lg: 25,
  xl: 30,
  xxl: 48,
  xxxl: 120,
};
