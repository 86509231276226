import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { HeaderTile, IHeaderTile } from '../../Components/General/HeaderTile';
import { PageLayout } from '../../Components/General/PageLayout';
import { GetRoute } from '../../Components/Routing';
import { IStoreTypes } from '../../store/storeTypes';
import { GetIllustrationLink } from '../../utils/LinkUtils';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { Subscription } from '../../types/SubscriptionTypes';

export const Notes = () => {
  const roleLabels = useSelector((state: IStoreTypes) => state.user.roleLabels);
  const sessionPosition = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const mentoringPartner = useSelector((state: IStoreTypes) => state.user.sessionPair);
  const hasGroups = useSelector((state: IStoreTypes) =>
    state.user.subscription.includes(Subscription.GROUPS),
  );
  const isMentee = sessionPosition === ProgramPositions.mentee;
  const partnerPosition = isMentee
    ? roleLabels[ProgramPositions.mentor]
    : roleLabels[ProgramPositions.mentee];

  const noAccessToSharedNotes = hasGroups && !mentoringPartner?.roleId;

  let notesConfig: IHeaderTile[] = [
    {
      title: 'Private notes',
      imageSrc: GetIllustrationLink('privateNotes.png'),
      linkTo: GetRoute('privateNotes').path,
      description:
        'A place to write some personal and private notes. Reflect privately between meetings, draft future meeting agendas or goals, document future topics for discussion. Private training notes will appear here.',
    },
  ];

  if (!noAccessToSharedNotes) {
    notesConfig.push({
      title: 'Shared notes',
      imageSrc: GetIllustrationLink('sharedNotes.png'),
      linkTo: GetRoute('sharedNotes').path,
      description: `Notes shared with your ${roleLabels[partnerPosition]}. Share your ideas, discussion topics, meeting notes or an article. Shared training notes will appear here.`,
    });
  }

  return (
    <PageLayout pageTitle="General Notes">
      <Grid container item spacing={2}>
        {notesConfig.map((note, index) => (
          <Grid item xs={12} md={6} key={index}>
            <HeaderTile {...note} />
          </Grid>
        ))}
      </Grid>
    </PageLayout>
  );
};
