import * as React from 'react';
import {
  EMoDTrainingModules,
  ReadableTrainingModules,
} from '../../../../store/reducerTypes/TrainingReducer.types';
import { ModuleWrapper } from '../ModuleWrapper';
import { PowerOfReflectionMenteeModuleMapper } from './PowerOfReflectionMenteeModuleMapper';

export const PowerOfReflectionMentee = () => (
  <ModuleWrapper
    moduleMap={PowerOfReflectionMenteeModuleMapper}
    moduleName={EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTEE}
    title={ReadableTrainingModules[EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTEE]}
  />
);
