import * as React from 'react';
import { CardProps } from '@material-ui/core/Card/Card';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import cx from 'classnames';
import { Colors } from '../../consts/colors';

const useStyles = makeStyles({
  card: {
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    minHeight: (props: { mobile: boolean; minHeight: string; maxHeight: string }) =>
      props.minHeight ? props.minHeight : props.mobile ? 165 : 260,
    maxHeight: (props: { mobile: boolean; minHeight: string; maxHeight: string }) =>
      props.maxHeight,
    borderRadius: 15,
    padding: (props: { mobile: boolean; minHeight: string; maxHeight: string; padding: string }) =>
      props.padding ?? 20,
  },
});

interface IBrancherCard extends CardProps {
  minHeight?: string;
  maxHeight?: string;
  padding?: string;
}

export const BrancherCard: React.FC<IBrancherCard> = ({
  className,
  children,
  minHeight,
  maxHeight,
  padding,
}) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const styles = useStyles({ minHeight, maxHeight, mobile, padding });

  return (
    <Grid container className={cx(styles.card, className)}>
      {children}
    </Grid>
  );
};
