import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const PowerOfReflectionDisqualified = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const partnersRole =
    sesPos === ProgramPositions.mentee ? ProgramPositions.mentor : ProgramPositions.mentee;

  return (
    <Grid item xs={12}>
      <Text variant="md" fontWeight={600} marginTop={30}>
        You need to have met with your {partnersRole} before continuing.
      </Text>
    </Grid>
  );
};
