import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { PowerOfReflectionMenteeStepOne } from '../MoDPowerOfReflectionMentee/Steps/PowerOfReflectionMenteeStepOne';
import { PowerOfReflectionMenteeStepTwo } from '../MoDPowerOfReflectionMentee/Steps/PowerOfReflectionMenteeStepTwo';
import { PowerOfReflectionMenteeStepThree } from '../MoDPowerOfReflectionMentee/Steps/PowerOfReflectionMenteeStepThree';

export const MoDPowerOfReflectionMentorModuleMapper: IModuleMapper[] = [
  {
    name: 'MoDPowerOfReflectionMentorStepOne',
    component: <PowerOfReflectionMenteeStepOne />,
    iterateStepper: true,
    needsInteraction: true,
    nextComponent: 'MoDPowerOfReflectionMentorStepTwo',
  },
  {
    name: 'MoDPowerOfReflectionMentorStepTwo',
    component: <PowerOfReflectionMenteeStepTwo />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MoDPowerOfReflectionMentorStepOne',
    nextComponent: 'MoDPowerOfReflectionMentorStepThree',
  },
  {
    name: 'MoDPowerOfReflectionMentorStepThree',
    component: <PowerOfReflectionMenteeStepThree />,
    iterateStepper: true,
    needsInteraction: true,
    submit: true,
    previousComponent: 'MoDPowerOfReflectionMentorStepTwo',
  },
];
