import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { BrancherDialog } from '../../../../../Components/General/BrancherDialog';
import { Text } from '../../../../../Components/General/Text';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { MentoringGuideLink } from '../../../../../Components/General/MentoringGuideLink';
import { GetTrainingResourceLink } from '../../../../../utils/LinkUtils';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';

export const dialogValues: string[] = ['experience', 'reflect', 'evaluate', 'plan'];

interface IKolbModelInfo {
  controlDialog: (a: boolean) => void;
  open: boolean;
  component: string;
}

export const KolbModelInfo = (props: IKolbModelInfo) => {
  const { controlDialog, open, component } = props;
  return (
    <BrancherDialog open={open} setClose={() => controlDialog(false)} labelledBy="KOLB model">
      {component === dialogValues[0] ? (
        <Experience />
      ) : component === dialogValues[1] ? (
        <Reflect />
      ) : component === dialogValues[2] ? (
        <Evaluate />
      ) : (
        <Plan />
      )}
    </BrancherDialog>
  );
};

const Experience = () => (
  <Grid container direction="column">
    <Grid item>
      <Text variant="md" fontWeight={700}>
        Experience:
      </Text>
    </Grid>
    <Grid item>
      <BrancherList
        fontWeight={400}
        listItems={[
          <>
            <Text variant="sm" display="inline">
              The{' '}
            </Text>
            <Text variant="sm" fontWeight={700} color="purple" display="inline">
              Experience
            </Text>
            <Text variant="sm" display="inline">
              {' '}
              stage involves describing or explaining a situation or experience
            </Text>
          </>,
          'What was the experience or situation? What happened?',
          'In the context of mentoring, you might like to describe the first meeting you had with your mentor',
          <>
            <Text variant="sm" display="inline">
              You may want to go back and look at any notes you took down, or consider your{' '}
            </Text>
            <MentoringGuideLink variant="sm" />
          </>,
        ]}
      />
    </Grid>
    <Grid item container justify="center">
      <Box marginTop={3} />
      <img
        src={GetTrainingResourceLink(
          EStructuredTrainingModules.MAKE_THE_MOST_MENTEE,
          'experience.png',
        )}
        width="60%"
        height="60%"
        alt={dialogValues[0]}
      />
    </Grid>
  </Grid>
);

const Reflect = () => (
  <Grid container direction="column">
    <Grid item>
      <Text variant="md" fontWeight={700}>
        Reflect:
      </Text>
    </Grid>
    <Grid item>
      <BrancherList
        fontWeight={400}
        listItems={[
          <>
            <Text variant="sm" display="inline">
              The{' '}
            </Text>
            <Text variant="sm" fontWeight={700} color="purple" display="inline">
              Reflect
            </Text>
            <Text variant="sm" display="inline">
              {' '}
              stage involves reflecting on the situation or experience
            </Text>
          </>,
          'What went well? What may not have gone well? What thoughts or feelings did I have at the time?',
          'In the context of mentoring, you could reflect on your first meeting and what went well and not so well',
        ]}
      />
    </Grid>
    <Grid item container justify="center">
      <img
        src={GetTrainingResourceLink(
          EStructuredTrainingModules.MAKE_THE_MOST_MENTEE,
          'reflect.png',
        )}
        width="60%"
        height="60%"
        alt={dialogValues[1]}
      />
    </Grid>
  </Grid>
);

const Evaluate = () => (
  <Grid container direction="column">
    <Grid item>
      <Text variant="md" fontWeight={700}>
        Evaluate:
      </Text>
    </Grid>
    <Grid item>
      <BrancherList
        fontWeight={400}
        listItems={[
          <>
            <Text variant="sm" display="inline">
              The{' '}
            </Text>
            <Text variant="sm" fontWeight={700} color="purple" display="inline">
              Evaluate
            </Text>
            <Text variant="sm" display="inline">
              {' '}
              stage involves appraising the situation and your feelings
            </Text>
          </>,
          'Why did things go well?',
          'Why did things go not so well?',
          'In the context of mentoring, you could evaluate why the initial meeting was a success and what you learnt that you could take from that experience',
        ]}
      />
    </Grid>
    <Grid item container justify="center">
      <Box marginTop={3} />
      <img
        src={GetTrainingResourceLink(
          EStructuredTrainingModules.MAKE_THE_MOST_MENTEE,
          'evaluate.png',
        )}
        width="60%"
        height="60%"
        alt={dialogValues[2]}
      />
    </Grid>
  </Grid>
);

const Plan = () => (
  <Grid container direction="column">
    <Grid item>
      <Text variant="md" fontWeight={700}>
        Plan:
      </Text>
    </Grid>
    <Grid item>
      <BrancherList
        fontWeight={400}
        listItems={[
          <>
            <Text variant="sm" display="inline">
              The{' '}
            </Text>
            <Text variant="sm" fontWeight={700} color="purple" display="inline">
              Plan
            </Text>
            <Text variant="sm" display="inline">
              {' '}
              stage involves thinking about what learnings you have from this situation and what you
              will do with this information
            </Text>
          </>,
          'Consider what you will do differently in the future? What behaviours could help you achieve success in the future?',
          'In the context of mentoring, you could think about what you could do differently in your next meeting to make it even more of a success',
        ]}
      />
    </Grid>
    <Grid item container justify="center">
      <Box marginTop={3} />
      <img
        src={GetTrainingResourceLink(EStructuredTrainingModules.MAKE_THE_MOST_MENTEE, 'plan.png')}
        width="60%"
        height="60%"
        alt={dialogValues[3]}
      />
    </Grid>
  </Grid>
);
