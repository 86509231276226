import * as React from 'react';
import { SmartGoalsInfo } from '../../sharedComps/SmartGoalsInfo';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { Text } from '../../../../../Components/General/Text';
import { BrancherDialog } from '../../../../../Components/General/BrancherDialog';

export const MakeTheMostLearningObjectives = () => [
  <KolbModelObjective />,
  <SmartGoalsObjective />,
];

const SmartGoalsObjective = () => {
  const [openDialog, controlDialog] = React.useState(false);
  return (
    <>
      <Text variant="sm" display="inline">
        Take practical steps to apply{' '}
      </Text>
      <BrancherLinkText
        variant="sm"
        display="inline"
        underline="always"
        onClick={() => controlDialog(true)}
      >
        SMART goals framework
      </BrancherLinkText>
      <Text variant="sm" display="inline">
        {' '}
        to your mentoring journey
      </Text>
      <SmartGoalsInfo controlDialog={() => controlDialog(false)} open={openDialog} />
    </>
  );
};

const KolbModelObjective = () => {
  const [openDialog, controlDialog] = React.useState(false);
  return (
    <>
      <Text variant="sm" display="inline">
        Practice reflection through the use of{' '}
      </Text>
      <BrancherLinkText
        variant="sm"
        display="inline"
        underline="always"
        onClick={() => controlDialog(true)}
      >
        Kolb’s adult learning model
      </BrancherLinkText>
      <BrancherDialog
        setClose={() => controlDialog(false)}
        open={openDialog}
        labelledBy="Learning objectives"
      >
        <Text variant="sm" fontWeight={600} marginTop={30} marginBottom={15}>
          Kolb's adult learning model was first developed by David Kolb in 1984. The model outlines
          a never-ending learning cycle consisting of four stages: experience, reflect, evaluate and
          plan.
        </Text>
      </BrancherDialog>
    </>
  );
};
