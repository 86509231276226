import { IMeetingData } from '../../store/reducers/MeetingsReducer';

export const sortMeetings = (
  sortingMeetings: IMeetingData[],
  asc: boolean = true,
): IMeetingData[] => {
  if (asc) {
    return sortingMeetings.sort((a: IMeetingData, b: IMeetingData) => {
      return a.datetimeStart > b.datetimeStart ? 1 : b.datetimeStart > a.datetimeStart ? -1 : 0;
    });
  } else {
    return sortingMeetings.sort((a: IMeetingData, b: IMeetingData) => {
      return a.datetimeStart < b.datetimeStart ? 1 : b.datetimeStart < a.datetimeStart ? -1 : 0;
    });
  }
};
