import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SuggestionCard } from '../../SuggestionCard';

export const BeyondProgramMentorStepSixStopCorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success>
          <Text variant="md" fontWeight={700}>
            Correct!
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="sm" display="inline">
          The correct answer is{' '}
        </Text>
        <Text variant="sm" display="inline" fontWeight={700}>
          A
        </Text>
        <Text variant="sm" display="inline">
          . This answer focuses on the positive (clearly describing why you have enjoyed the
          relationship) whilst clearly stating that the relationship needs to end and why.
        </Text>
      </Grid>
    </Grid>
  );
};
