import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { CharlieAvatar } from '../../avatars/Charlie';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const BeGreatMentorStepTen = () => {
  const stepTenValue = useSelector((state: IStoreTypes) => state.training.beGreatMentor.StepTen);
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepTenCorrectVal = ['0', '1', '2'];
    dispatch(
      SaveBeGreatMentorData({
        StepTen: {
          selected: val,
          correct:
            val?.length === 3 &&
            StepTenCorrectVal.includes(val[0]) &&
            StepTenCorrectVal.includes(val[1]) &&
            StepTenCorrectVal.includes(val[2]),
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Charlie responds...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          Charlie responds, "I would need to tell my current manager and see whether I could balance
          work with further study. I'd also need to apply for the course and who knows if I'd get
          in. Assuming I did get in, I'd then need to commit to following through with it!"
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          How do you respond? Choose one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="If you do go ahead with this, how will you sustain your energy and motivation throughout the degree?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="What things will you need to commit to on a regular basis?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="How will you hold yourself to account?"
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepTenValue"
          value={stepTenValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
