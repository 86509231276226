import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import {
  SaveGivingReceivingFeedbackMenteeData,
  SaveGivingReceivingFeedbackMentorData,
} from '../../../../../store/actions';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';
import { SonalAvatar } from '../../avatars/Sonal';
import { MasterModelCard } from '../../sharedComps/MasterModelCard';
import { SuggestionCard } from '../../SuggestionCard';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const GivingReceivingFeedbackMenteeStepFour = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const moduleName =
    position === ProgramPositions.mentee
      ? 'givingReceivingFeedbackMentee'
      : 'givingReceivingFeedbackMentor';
  const StepFourValue = useSelector((state: IStoreTypes) => state.training[moduleName]);
  const sessionSaveFunction =
    position === ProgramPositions.mentee
      ? SaveGivingReceivingFeedbackMenteeData
      : SaveGivingReceivingFeedbackMentorData;
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepFourCorrectVals = '0';
    dispatch(
      sessionSaveFunction({
        StepFour: {
          selected: val,
          correct: StepFourCorrectVals === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" display="inline">
          Now for the{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          'A'
        </Text>
        <Text variant="md" display="inline" fontWeight={700}>
          {' '}
          for Achievable.
        </Text>
      </Grid>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <SonalAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Sonal says she needs her phone...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <SuggestionCard neutral>
          "I need my phone in case work contacts me with something urgent."
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          What will you say?
        </Text>
      </Grid>
      <Grid item>
        <MasterModelCard />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="Sure, that makes sense. Could we make a deal that we only respond to urgent calls? This will enable us to make the most of our time together."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="I'd like you to turn your phone off or on silent and away during our meetings. This will enable us to work together efficiently!"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="I'd appreciate you giving me your full attention though. This would mean so much to me."
        />
      </Grid>
      <Grid item container justify="center">
        <SelectionControl
          question=""
          noQuestion
          name="StepFourValue"
          value={StepFourValue.StepFour?.selected}
          updateValue={updateValue}
          thirdWidth
          exclusive
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
