import * as React from 'react';
import Box from '@material-ui/core/Box';
import Tabs, { TabsProps } from '@material-ui/core/Tabs';
import Tab, { TabProps } from '@material-ui/core/Tab';

interface IBrancherTab extends TabProps {
  component: React.ReactNode;
  label: string;
  value: string | number;
}

interface IBrancherTabs extends TabsProps {
  tabConfig: IBrancherTab[];
  value: string | number;
  updateValue: (a: string & number) => void;
}

export const BrancherTabs = (props: IBrancherTabs) => {
  const { value, updateValue, tabConfig } = props;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string & number) => {
    updateValue(newValue);
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange} textColor="primary" variant="fullWidth">
        {tabConfig.map((f, i) => (
          <Tab label={f.label} key={i} />
        ))}
      </Tabs>
      <Box marginTop={4}>{tabConfig.find((a) => a.value === value).component}</Box>
    </>
  );
};
