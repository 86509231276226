import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMakeTheMostMentee, SaveMakeTheMostMentor } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { SmartGoalsInfo } from '../../sharedComps/SmartGoalsInfo';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const MakeTheMostMenteeStepFour = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const controlDialog = (op: boolean) => {
    setOpen(op);
    updateValue();
  };

  const updateValue = () => {
    const sessionSaveFunction =
      position === ProgramPositions.mentee ? SaveMakeTheMostMentee : SaveMakeTheMostMentor;
    dispatch(
      sessionSaveFunction({
        StepFour: {
          interacted: true,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          SMART GOALS
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline" marginBottom={40}>
          A popular strategy for goal setting is the{' '}
        </Text>
        <BrancherLinkText
          variant="md"
          underline="always"
          display="inline"
          onClick={() => controlDialog(true)}
        >
          SMART goal principles
        </BrancherLinkText>
        <Text variant="md" display="inline" marginBottom={40}>
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          This means that the goal is:
        </Text>
        <BrancherList
          fontWeight={400}
          listItems={[
            'Specific (clear and detailed)',
            'Measurable (goal success can be clearly identified)',
            'Attainable (reaching the goal is possible)',
            'Relevant (the goal is relevant to you)',
            'Timely (there is a reasonable time limit)',
          ]}
        />
      </Grid>
      <Grid item container justify="center">
        <img
          src="https://media.brancher.com.au/training/makeTheMostMentee/smart.png"
          width="60%"
          height="60%"
          alt="smart-goals"
        />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700} marginTop={30} marginBottom={30}>
          The next few slides will present three separate goals. Identify whether or not the goal
          meets the SMART principles and select the principles that may apply to the example goal.
        </Text>
      </Grid>
      <SmartGoalsInfo controlDialog={setOpen} open={open} />
    </Grid>
  );
};
