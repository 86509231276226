import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { BrancherButton } from '../../../../../Components/InputFields/BrancherButton';
import { GetRoute } from '../../../../../Components/Routing';
import { Text } from '../../../../../Components/General/Text';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const MakeTheMostDisqualified = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const partnersRole =
    sesPos === ProgramPositions.peer
      ? ProgramPositions.peer
      : ProgramPositions.mentee
      ? ProgramPositions.mentor
      : ProgramPositions.mentee;

  return (
    <>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginTop={60}>
          We recommend you to reach out to your {partnersRole} now.
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Box marginTop={4} marginBottom={6}>
          <SuggestionCard>
            Please reach out to your mentoring partner to verify the meeting time.
          </SuggestionCard>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          marginTop={4}
          marginBottom={4}
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Link to={GetRoute('meetings').path} style={{ width: '100%' }}>
            <BrancherButton fullWidth color="primary">
              Go to meetings
            </BrancherButton>
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box marginBottom={5} marginTop={3}>
          <Text variant="md">
            If you can’t get in contact with your mentoring partner, please contact your mentoring
            lead.
          </Text>
        </Box>
      </Grid>
      <Grid item container justify="center" xs={12}>
        <img
          src="https://media.brancher.com.au/training/makeTheMostMentee/kolbModel.png"
          width="60%"
          height="auto"
          alt="kolb-model"
        />
      </Grid>
    </>
  );
};
