import { PERSONALITY_FORM_DATA, PERSONALITY_CLEAR_FORM_DATA } from '../reducers/PersonalityReducer';

export const SavePersonalityFormData = (userData: object) => {
  return {
    type: PERSONALITY_FORM_DATA,
    payload: userData,
  };
};

export const ClearPersonalityFormData = () => {
  return {
    type: PERSONALITY_CLEAR_FORM_DATA,
  };
};
