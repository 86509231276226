import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherQuestionMarker } from '../../../../../Components/General/BrancherQuestionMarker';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import {
  SaveMoDPowerOfReflectionMenteeData,
  SaveMoDPowerOfReflectionMentorData,
} from '../../../../../store/actions';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { CompetencePopover } from '../../sharedComps/CompetencePopover';
import { SuggestionCard } from '../../SuggestionCard';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { CompetenceScale } from '../../sharedComps/CompetenceScale';
import { ConfidenceScale } from '../../sharedComps/ConfidenceScale';
import { NPSScale } from '../../sharedComps/NPSScale';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';

export const PowerOfReflectionMenteeStepThree: React.FC = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const sessionStore =
    position === ProgramPositions.mentee
      ? EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTEE
      : EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTOR;
  const StepThreeValue = useSelector((state: IStoreTypes) => state.training?.[sessionStore]);
  const isMentee = position === ProgramPositions.mentee;
  const sessionSaveFunction = isMentee
    ? SaveMoDPowerOfReflectionMenteeData
    : SaveMoDPowerOfReflectionMentorData;
  const stepNumber = 'StepThree';
  const dispatch = useDispatch();
  const partnerRole =
    position === ProgramPositions.mentee ? ProgramPositions.mentor : ProgramPositions.mentee;

  const [anchorEl, setAnchor] = React.useState(null);
  const open = Boolean(anchorEl);

  const controlValidator = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!Boolean(anchorEl)) {
      setAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(anchorEl)) {
      setAnchor(null);
    }
  };

  const updateCompetence = (val: number) => {
    dispatch(
      sessionSaveFunction({
        [stepNumber]: {
          ...StepThreeValue[stepNumber],
          competenceFinal: val,
          interacted:
            StepThreeValue?.rating != null &&
            StepThreeValue?.confidenceFinal != null &&
            val != null,
        },
        competenceFinal: val,
      }),
    );
  };

  const updateConfidence = (val: number) => {
    dispatch(
      sessionSaveFunction({
        [stepNumber]: {
          ...StepThreeValue[stepNumber],
          confidenceFinal: val,
          interacted:
            StepThreeValue?.rating != null &&
            StepThreeValue?.competenceFinal != null &&
            val != null,
        },
        confidenceFinal: val,
      }),
    );
  };

  const updateRating = (val: number) => {
    dispatch(
      sessionSaveFunction({
        [stepNumber]: {
          ...StepThreeValue[stepNumber],
          interacted:
            StepThreeValue?.competenceFinal != null &&
            StepThreeValue?.confidenceFinal != null &&
            val != null,
        },
        rating: val,
      }),
    );
  };

  const updateFeedback = (val: string) => {
    dispatch(
      sessionSaveFunction({
        [stepNumber]: {
          ...StepThreeValue[stepNumber],
          interacted:
            StepThreeValue?.rating != null &&
            StepThreeValue?.competenceFinal != null &&
            StepThreeValue?.confidenceFinal != null,
        },
        feedback: val,
      }),
    );
  };

  const NextStepList: string[] = [
    'Implement your key reflections to improve your mentoring experience',
    'Reflect regularly between meetings',
    `Schedule another meeting with your ${partnerRole}`,
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700}>
          Well done on completing this training!
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="sm" fontWeight={600} display="inline">
          Now that you have completed this training, please rate your current level of competence
          and confidence to reflect using Kolb's adult learning model.{' '}
        </Text>
        <BrancherQuestionMarker onClick={controlValidator} />
      </Grid>
      <CompetencePopover
        open={open}
        setClose={closeValidator}
        anchor={anchorEl}
        labelledBy="Competence and confidence"
      />
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your competence rating now?
        </Text>
        <CompetenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepThreeValue[stepNumber]?.competenceFinal}
          valueLength={7}
          updateValue={updateCompetence}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your confidence rating now?
        </Text>
        <ConfidenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepThreeValue[stepNumber]?.confidenceFinal}
          valueLength={7}
          updateValue={updateConfidence}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          On a scale of 0 - 10, how likely are you to recommend this training to another colleague
          within this mentoring program?
        </Text>
        <NPSScale />
        <SegmentedControlGroup
          fullWidth
          value={StepThreeValue?.rating}
          valueLength={11}
          updateValue={updateRating}
          startFromZero
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Do you have any other feedback for us?
        </Text>
        <BrancherTextField
          value={StepThreeValue?.feedback}
          updateValue={(a: string) => updateFeedback(a)}
          placeholder="Enter here"
          maxChars={400}
          multiline
          rowsMax={6}
          id="feedback"
          name="feedback"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700}>
          Next steps:
        </Text>
      </Grid>
      <Box marginLeft={2}>
        <BrancherList fontWeight={400} listItems={NextStepList} />
      </Box>
      <Grid item xs={12}>
        <SuggestionCard>
          Make sure you click the "✓" button to complete this training.
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
