import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherDivider } from '../../../../../Components/General/BrancherDivider';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { MentoringGuideLink } from '../../../../../Components/General/MentoringGuideLink';
import { Text } from '../../../../../Components/General/Text';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { SaveMoDBeGreatMenteeData, SaveMoDBeGreatMentorData } from '../../../../../store/actions';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { NPSScale } from '../../sharedComps/NPSScale';
import { SuggestionCard } from '../../SuggestionCard';

export const MoDBeGreatMenteeStepEleven = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentor = sesPos === ProgramPositions.mentor;
  const modName = isMentor
    ? EMoDTrainingModules.MOD_BE_GREAT_MENTOR
    : EMoDTrainingModules.MOD_BE_GREAT_MENTEE;
  const saveFunction = isMentor ? SaveMoDBeGreatMentorData : SaveMoDBeGreatMenteeData;
  const StepElevenValue = useSelector((state: IStoreTypes) => state.training?.[modName]);
  const dispatch = useDispatch();

  const updateRating = (val: number) => {
    dispatch(
      saveFunction({
        StepEleven: {
          ...StepElevenValue?.StepEleven,
          interacted: val != null,
        },
        rating: val,
      }),
    );
  };

  const updateFeedback = (val: string) => {
    dispatch(
      saveFunction({
        StepEleven: {
          ...StepElevenValue?.StepEleven,
          interacted: StepElevenValue?.rating != null,
        },
        feedback: val,
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          On a scale of 0 - 10, how likely are you to recommend this training to another colleague
          within this mentoring program?
        </Text>
        <NPSScale />
        <SegmentedControlGroup
          fullWidth
          value={StepElevenValue?.rating}
          valueLength={11}
          updateValue={updateRating}
          startFromZero
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Do you have any other feedback for us?
        </Text>
        <BrancherTextField
          value={StepElevenValue?.feedback}
          updateValue={(a: string) => updateFeedback(a)}
          placeholder="Enter here"
          multiline
          maxChars={600}
          id="feedback"
          name="feedback"
          fullWidth
        />
      </Grid>
      <BrancherDivider marginBottom={40} marginTop={40} />
      <Grid item xs={12}>
        <Text variant="md">
          Thanks for taking the time to participate and best of luck getting started on your
          mentoring journey.
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={600} marginBottom={-15} marginTop={30}>
          The next steps are:
        </Text>
      </Grid>
      <Box marginLeft={2}>
        <BrancherList
          fontWeight={400}
          variant="md"
          listItems={[
            `Contact your ${
              isMentor ? ProgramPositions.mentee : ProgramPositions.mentor
            } to set your first meeting and propose a meeting agenda`,
            <>
              <Text variant="md" marginBottom={15} fontWeight={400} display="inline">
                Continue to use your{' '}
              </Text>
              <MentoringGuideLink variant="md" />
            </>,
            'Contact your Mentoring Lead if you have questions',
          ]}
        />
      </Box>
      <Grid item xs={12}>
        <SuggestionCard>
          Make sure you click the "✓" button to complete this training.
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
