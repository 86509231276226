import { Colors } from '../consts/colors';
import { TabletDesktopFontSizes } from '../consts/FontSizes';

export const MuiFormHelperText = {
  root: {
    fontSize: TabletDesktopFontSizes.xxs,
    marginTop: 2,
    color: Colors.black,
    fontWeight: 500,
    '&$error': {
      fontSize: 18,
      color: Colors.red,
      fontWeight: 700,
    },
  },
};
