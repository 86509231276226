import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BrancherLinkText } from '../../../../Components/General/BrancherLinkText';
import { Text } from '../../../../Components/General/Text';
import { SegmentedControlGroup } from '../../../../Form/FormFieldTypes/SegmentedControl';
import { EStructuredTrainingModules } from '../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../store/storeTypes';
import { SelectionControl } from '../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../Form/MakeOptions';
import { SaveSurveyTwoData, UtilSaveModuleData } from '../../../../store/actions';
import { PrevButton, SaveButton } from '../../../../Components/InputFields/BrancherButton';
import { BrancherTextField } from '../../../../Components/InputFields/BrancherTextField';
import { BrancherSnackbar } from '../../../../Components/General/BrancherSnackbar';
import { GetRoute } from '../../../../Components/Routing';
import { PDFLink } from '../../Modules/helpers/LinkBuilder';
import { SurveyTwoConfiguration } from './SurveyTwoConfiguration';
import { FormWithStepFieldLayout } from '../../../../Components/InputFields/FormWithStepFieldLayout';
import { ProgramPositions } from '../../../../QualifyingForm/ProgramPositionOptions';

export const SurveyTwo = () => {
  const surveyTwoValue = useSelector(
    (state: IStoreTypes) => state.training?.[EStructuredTrainingModules.SURVEY_TWO],
  );
  const surveyTwoProgress = useSelector(
    (state: IStoreTypes) => state.training?.progress?.[EStructuredTrainingModules.SURVEY_TWO],
  );
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const dispatch = useDispatch();
  const [open, controlSnackbar] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 200);
  }, []);

  const updateValue = (attributeName: string, val: string) => {
    dispatch(
      SaveSurveyTwoData({
        [attributeName]: val,
      }),
    );
  };

  const saveSurveyTwoData = () => {
    dispatch(
      UtilSaveModuleData(
        EStructuredTrainingModules.SURVEY_TWO,
        (resp) => {
          if (resp.success) {
            controlSnackbar(true);
          }
        },
        false,
        true,
      ),
    );
  };

  const validate = (): boolean => {
    return position === ProgramPositions.mentee
      ? Boolean(
          surveyTwoValue?.frequency &&
            surveyTwoValue?.matchSatisfaction &&
            surveyTwoValue?.personalitySimilarity &&
            surveyTwoValue?.valuesSimilarity &&
            surveyTwoValue?.interestsSimilarity &&
            surveyTwoValue?.trainingSupport &&
            surveyTwoValue?.feedbackStrategies &&
            surveyTwoValue?.confidence &&
            surveyTwoValue?.resilience &&
            surveyTwoValue?.crossFunctionalAwareness &&
            surveyTwoValue?.engagement &&
            surveyTwoValue?.productivityPerformance &&
            surveyTwoValue?.platform &&
            surveyTwoValue?.promotionPayRise &&
            surveyTwoValue?.goalAchievement &&
            surveyTwoValue?.skill &&
            surveyTwoValue?.continueRelationshipIntent &&
            surveyTwoValue?.nps !== null &&
            surveyTwoValue?.advocacy &&
            surveyTwoValue?.participationIntent &&
            surveyTwoValue?.strengths &&
            surveyTwoValue?.opportunities &&
            surveyTwoValue?.stayInTouch,
        )
      : Boolean(
          surveyTwoValue?.frequency &&
            surveyTwoValue?.matchSatisfaction &&
            surveyTwoValue?.personalitySimilarity &&
            surveyTwoValue?.valuesSimilarity &&
            surveyTwoValue?.interestsSimilarity &&
            surveyTwoValue?.trainingSupport &&
            surveyTwoValue?.feedbackStrategies &&
            surveyTwoValue?.mentorBenefits &&
            surveyTwoValue?.resilience &&
            surveyTwoValue?.crossFunctionalAwareness &&
            surveyTwoValue?.engagement &&
            surveyTwoValue?.productivityPerformance &&
            surveyTwoValue?.platform &&
            surveyTwoValue?.promotionPayRise &&
            surveyTwoValue?.mentorGoalAssistance &&
            surveyTwoValue?.mentoringSkills &&
            surveyTwoValue?.coachingSkills &&
            surveyTwoValue?.leadershipSkills &&
            surveyTwoValue?.continueRelationshipIntent &&
            surveyTwoValue?.nps !== null &&
            surveyTwoValue?.advocacy &&
            surveyTwoValue?.participationIntent &&
            surveyTwoValue?.strengths &&
            surveyTwoValue?.opportunities &&
            surveyTwoValue?.stayInTouch,
        );
  };

  const config = SurveyTwoConfiguration(position);

  return (
    <>
      <BrancherSnackbar
        open={open}
        controlClose={controlSnackbar}
        message="Survey response saved!"
      />
      <Grid container item xs={12} justify="center" spacing={1}>
        <Grid item xs={11} md={10}>
          <Box marginTop={5}>
            <Link to={GetRoute('training').path}>
              <PrevButton variant="text" color="primary">
                Back
              </PrevButton>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={11} md={10}>
          <Text variant="sm" fontWeight={600} marginBottom={30} marginTop={40}>
            We would like to hear about your mentoring program experiences via this 10 minute
            survey. Please be open and honest so we can further improve this program for next year.
            All responses are strictly confidential. De-identified responses may be used for
            reporting and marketing purposes.
          </Text>
        </Grid>
        {config.map((q, i) => {
          if (q.type === 'selection') {
            return (
              <Grid item xs={11} md={10}>
                <FormWithStepFieldLayout step={i + 1} question={q.question}>
                  <SelectionControl
                    question=""
                    noQuestion
                    name={q.name}
                    value={surveyTwoValue[q.name]}
                    updateValue={(a: string) => updateValue(q.name, a)}
                    exclusive
                    thirdWidth={q.options.length > 2}
                    halfWidth={q.options.length === 2}
                    options={MakeOptions(q.options)}
                  />
                </FormWithStepFieldLayout>
              </Grid>
            );
          } else if (q.type === 'text') {
            return (
              <Grid item xs={11} md={10}>
                <FormWithStepFieldLayout step={i + 1} question={q.question}>
                  <BrancherTextField
                    value={surveyTwoValue[q.name]}
                    placeholder="Enter here"
                    id={q.name}
                    name={q.name}
                    updateValue={(a: string) => updateValue(q.name, a)}
                    rowsMax={5}
                    fullWidth
                    multiline
                  />
                </FormWithStepFieldLayout>
              </Grid>
            );
          } else {
            return (
              <Grid item xs={11} md={10}>
                <FormWithStepFieldLayout step={i + 1} question={q.question}>
                  <SegmentedControlGroup
                    fullWidth
                    value={surveyTwoValue[q.name]}
                    valueLength={q.length}
                    startFromZero
                    updateValue={(a: string) => updateValue(q.name, a)}
                  />
                </FormWithStepFieldLayout>
              </Grid>
            );
          }
        })}
        <Grid item xs={11} md={10}>
          <FormWithStepFieldLayout
            step={config.length + 1}
            question={
              <>
                <Text variant="sm" fontWeight={600}>
                  Do you want to stay in touch? We’ll keep you updated on future mentoring
                  opportunities and share the occasional email with some career development tips and
                  tricks.
                </Text>
                <Text variant="sm" display="inline">
                  By clicking yes, you are agreeing to our{' '}
                </Text>
                <BrancherLinkText
                  variant="sm"
                  target="_blank"
                  href={PDFLink.PRIVACY_POLICY_LINK}
                  display="inline"
                >
                  Privacy Policy
                </BrancherLinkText>
              </>
            }
          >
            <SelectionControl
              question=""
              noQuestion
              name="stayInTouch"
              value={surveyTwoValue?.stayInTouch}
              updateValue={(a: string) => updateValue('stayInTouch', a)}
              exclusive
              halfWidth
              options={MakeOptions(['Yes', 'No'])}
            />
          </FormWithStepFieldLayout>
        </Grid>

        <Grid item xs={11} md={10}>
          <Box marginTop={6} marginBottom={6}>
            <SaveButton
              disabled={!validate() || surveyTwoProgress?.completed}
              fullWidth
              onClick={saveSurveyTwoData}
              color="primary"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
