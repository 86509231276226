import * as React from 'react';
import { useSelector } from 'react-redux';
import { IModuleMapper } from '../ModuleMapper';
import { BeyondProgramMentorStepOne } from '../BeyondProgramMentor/Steps/BeyondProgramMentorStepOne';
import { BeyondProgramMentorStepTwo } from '../BeyondProgramMentor/Steps/BeyondProgramMentorStepTwo';
import { BeyondProgramMentorStepThree } from '../BeyondProgramMentor/Steps/BeyondProgramMentorStepThree';
import { BeyondProgramMentorStepSeven } from '../BeyondProgramMentor/Steps/BeyondProgramMentorStepSeven';
import { BeyondProgramMentorStepEight } from '../BeyondProgramMentor/Steps/BeyondProgramMentorStepEight';
import { BeyondProgramMentorStepNine } from '../BeyondProgramMentor/Steps/BeyondProgramMentorStepNine';
import { BeyondProgramMentorStepTen } from '../BeyondProgramMentor/Steps/BeyondProgramMentorStepTen';
import { BeyondProgramMentorStepEleven } from '../BeyondProgramMentor/Steps/BeyondProgramMentorStepEleven';
import { BeyondProgramMentorStepTwelve } from '../BeyondProgramMentor/Steps/BeyondProgramMentorStepTwelve';
import { BeyondProgramMentorStepFourteen } from '../BeyondProgramMentor/Steps/BeyondProgramMentorStepFourteen';
import { BeyondProgramMentorStepFourStop } from '../BeyondProgramMentor/Steps/BeyondProgramMentorStepFourStop';
import { BeyondProgramMentorStepFourContinue } from '../BeyondProgramMentor/Steps/BeyondProgramMentorStepFourContinue';
import { BeyondProgramMentorStepFiveContinue } from '../BeyondProgramMentor/Steps/BeyondProgramMentorStepFiveContinue';
import { BeyondProgramMenteeStepFiveStop } from './Steps/BeyondProgramMenteeStepFiveStop';
import { BeyondProgramMenteeStepFiveStopCorrect } from './Steps/BeyondProgramMenteeStepFiveStopCorrect';
import { BeyondProgramMenteeStepFiveStopIncorrect } from './Steps/BeyondProgramMenteeStepFiveStopIncorrect';
import { BeyondProgramMenteeStepSixStop } from './Steps/BeyondProgramMenteeStepSixStop';
import { BeyondProgramMenteeStepSixStopCorrect } from './Steps/BeyondProgramMenteeStepSixStopCorrect';
import { BeyondProgramMenteeStepSixStopIncorrect } from './Steps/BeyondProgramMenteeStepSixStopIncorrect';
import { BeyondProgramMenteeStepSixContinueCorrect } from './Steps/BeyondProgramMenteeStepSixContinueCorrect';
import { BeyondProgramMenteeStepSixContinueIncorrect } from './Steps/BeyondProgramMenteeStepSixContinueIncorrect';
import { BeyondProgramMentorStepThirteen } from '../BeyondProgramMentor/Steps/BeyondProgramMentorStepThirteen';
import { IStoreTypes } from '../../../../store/storeTypes';

const BaseBeyondProgramMenteeModuleMapper: IModuleMapper[] = [
  {
    name: 'BeyondProgramMenteeStepOne',
    component: <BeyondProgramMentorStepOne />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    nextComponent: 'BeyondProgramMenteeStepTwo',
  },
  {
    name: 'BeyondProgramMenteeStepTwo',
    component: <BeyondProgramMentorStepTwo />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'BeyondProgramMenteeStepOne',
    nextComponent: 'BeyondProgramMenteeStepThree',
  },
  {
    name: 'BeyondProgramMenteeStepThree',
    component: <BeyondProgramMentorStepThree />,
    iterateStepper: true,
    hasQuestion: true,
    changeFlowStep: true,
    previousComponent: 'BeyondProgramMenteeStepTwo',
    correctComponent: 'BeyondProgramMenteeStepFourContinue',
    incorrectComponent: 'BeyondProgramMenteeStepFourStop',
  },
];

const EndBeyondProgramMenteeModuleWrapper: IModuleMapper[] = [
  {
    name: 'BeyondProgramMenteeStepSeven',
    component: <BeyondProgramMentorStepSeven />,
    iterateStepper: true,
    contentScreen: true,
    previousComponent: 'BeyondProgramMenteeStepSixCorrect',
    nextComponent: 'BeyondProgramMenteeStepEight',
  },
  {
    name: 'BeyondProgramMenteeStepEight',
    component: <BeyondProgramMentorStepEight />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'BeyondProgramMenteeStepSeven',
    nextComponent: 'BeyondProgramMenteeStepNine',
  },
  {
    name: 'BeyondProgramMenteeStepNine',
    component: <BeyondProgramMentorStepNine />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'BeyondProgramMenteeStepEight',
    nextComponent: 'BeyondProgramMenteeStepTen',
  },
  {
    name: 'BeyondProgramMenteeStepTen',
    component: <BeyondProgramMentorStepTen />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'BeyondProgramMenteeStepNine',
    nextComponent: 'BeyondProgramMenteeStepEleven',
  },
  {
    name: 'BeyondProgramMenteeStepEleven',
    component: <BeyondProgramMentorStepEleven />,
    iterateStepper: true,
    contentScreen: true,
    previousComponent: 'BeyondProgramMenteeStepTen',
    nextComponent: 'BeyondProgramMenteeStepTwelve',
  },
  {
    name: 'BeyondProgramMenteeStepTwelve',
    component: <BeyondProgramMentorStepTwelve />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'BeyondProgramMenteeStepEleven',
    nextComponent: 'BeyondProgramMenteeStepThirteen',
  },
  {
    name: 'BeyondProgramMenteeStepThirteen',
    component: <BeyondProgramMentorStepThirteen />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'BeyondProgramMenteeStepTwelve',
    nextComponent: 'BeyondProgramMenteeStepFourteen',
  },
  {
    name: 'BeyondProgramMenteeStepFourteen',
    component: <BeyondProgramMentorStepFourteen />,
    iterateStepper: false,
    noValidation: true,
    submit: true,
    previousComponent: 'BeyondProgramMenteeStepThirteen',
  },
];

const StopBeyondProgramMenteeModuleMapper: IModuleMapper[] = [
  ...BaseBeyondProgramMenteeModuleMapper,
  {
    name: 'BeyondProgramMenteeStepFourStop',
    component: <BeyondProgramMentorStepFourStop />,
    iterateStepper: true,
    contentScreen: true,
    previousComponent: 'BeyondProgramMenteeStepThree',
    nextComponent: 'BeyondProgramMenteeStepFiveStop',
  },
  {
    name: 'BeyondProgramMenteeStepFiveStop',
    component: <BeyondProgramMenteeStepFiveStop />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'BeyondProgramMenteeStepFourStop',
    correctComponent: 'BeyondProgramMenteeStepFiveStopCorrect',
    incorrectComponent: 'BeyondProgramMenteeStepFiveStopIncorrect',
  },
  {
    name: 'BeyondProgramMenteeStepFiveStopCorrect',
    component: <BeyondProgramMenteeStepFiveStopCorrect />,
    iterateStepper: true,
    previousComponent: 'BeyondProgramMenteeStepFiveStop',
    nextComponent: 'BeyondProgramMenteeStepSixStop',
  },
  {
    name: 'BeyondProgramMenteeStepFiveStopIncorrect',
    component: <BeyondProgramMenteeStepFiveStopIncorrect />,
    iterateStepper: true,
    previousComponent: 'BeyondProgramMenteeStepFiveStop',
    nextComponent: 'BeyondProgramMenteeStepSixStop',
  },
  {
    name: 'BeyondProgramMenteeStepSixStop',
    component: <BeyondProgramMenteeStepSixStop />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'BeyondProgramMenteeStepFiveStop',
    correctComponent: 'BeyondProgramMenteeStepSixCorrect',
    incorrectComponent: 'BeyondProgramMenteeStepSixStopIncorrect',
  },
  {
    name: 'BeyondProgramMenteeStepSixCorrect',
    component: <BeyondProgramMenteeStepSixStopCorrect />,
    iterateStepper: true,
    contentScreen: true,
    previousComponent: 'BeyondProgramMenteeStepSixStop',
    nextComponent: 'BeyondProgramMenteeStepSeven',
  },
  {
    name: 'BeyondProgramMenteeStepSixStopIncorrect',
    component: <BeyondProgramMenteeStepSixStopIncorrect />,
    iterateStepper: true,
    previousComponent: 'BeyondProgramMenteeStepSixStop',
    nextComponent: 'BeyondProgramMenteeStepSeven',
  },
  ...EndBeyondProgramMenteeModuleWrapper,
];

const ContinueBeyondProgramMenteeModuleMapper: IModuleMapper[] = [
  ...BaseBeyondProgramMenteeModuleMapper,
  {
    name: 'BeyondProgramMenteeStepFourContinue',
    component: <BeyondProgramMentorStepFourContinue />,
    iterateStepper: true,
    contentScreen: true,
    previousComponent: 'BeyondProgramMenteeStepThree',
    nextComponent: 'BeyondProgramMenteeStepFiveContinue',
  },
  {
    name: 'BeyondProgramMenteeStepFiveContinue',
    component: <BeyondProgramMentorStepFiveContinue />,
    iterateStepper: true,
    needsInteraction: true,
    hasQuestion: true,
    previousComponent: 'BeyondProgramMenteeStepFourContinue',
    correctComponent: 'BeyondProgramMenteeStepSixContinueCorrect',
    incorrectComponent: 'BeyondProgramMenteeStepSixContinueIncorrect',
  },
  {
    name: 'BeyondProgramMenteeStepSixContinueIncorrect',
    component: <BeyondProgramMenteeStepSixContinueIncorrect />,
    iterateStepper: false,
    contentScreen: true,
    previousComponent: 'BeyondProgramMenteeStepFiveContinue',
    nextComponent: 'BeyondProgramMenteeStepSixContinueCorrect',
  },
  {
    name: 'BeyondProgramMenteeStepSixContinueCorrect',
    component: <BeyondProgramMenteeStepSixContinueCorrect />,
    iterateStepper: true,
    contentScreen: true,
    needsInteraction: false,
    previousComponent: 'BeyondProgramMenteeStepFiveContinue',
    nextComponent: 'BeyondProgramMenteeStepSeven',
  },
  ...EndBeyondProgramMenteeModuleWrapper,
];

export const BeyondProgramMenteeModuleMapper = (): IModuleMapper[] => {
  const StepThreeFilterValue = useSelector(
    (state: IStoreTypes) => state.training?.beyondProgramMentee?.StepThree,
  );
  return StepThreeFilterValue?.correct === undefined
    ? ContinueBeyondProgramMenteeModuleMapper
    : StepThreeFilterValue?.correct
    ? ContinueBeyondProgramMenteeModuleMapper
    : StopBeyondProgramMenteeModuleMapper;
};
