import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { GoalsReducer } from './reducers/GoalsReducer';
import { PrivateNotesReducer } from './reducers/PrivateNotesReducer';
import { SharedNotesReducer } from './reducers/SharedNotesReducer';
import { USER_LOGOUT, UserInfoReducer } from './reducers/UserInfoReducer';
import { QualifyingReducer } from './reducers/QualifyingReducer';
import { GeneralFormReducer } from './reducers/GeneralFormReducer';
import { ValuesReducer } from './reducers/ValuesReducer';
import { PersonalityReducer } from './reducers/PersonalityReducer';
import { FinalisingReducer } from './reducers/FinalisingReducer';
import { ProfileFormReducer } from './reducers/ProfileFormReducer';
import { CohortReducer } from './reducers/CohortReducer';
import { TrainingReducer } from './reducers/TrainingReducer';
import { MeetingsReducer } from './reducers/MeetingsReducer';

const appReducer = combineReducers({
  user: UserInfoReducer,
  qualify: QualifyingReducer,
  general: GeneralFormReducer,
  values: ValuesReducer,
  personality: PersonalityReducer,
  finalising: FinalisingReducer,
  profileForm: ProfileFormReducer,
  cohort: CohortReducer,
  training: TrainingReducer,
  meetings: MeetingsReducer,
  privateNotes: PrivateNotesReducer,
  sharedNotes: SharedNotesReducer,
  goals: GoalsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

const composeMiddleware = compose;

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Make sure we don't include logging in production
let middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, logger];
}

const enhancer = composeMiddleware(applyMiddleware(...middleware));

export default () => {
  const store = createStore(persistedReducer, enhancer);
  const persistor = persistStore(store);
  return { store, persistor };
};
