import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';
import { ISegment } from '../FormFieldTypes/SegmentedControl';
import { SavePersonalityFormData } from '../../store/actions';
import { makePrettyQuestionNumber } from '../Values/ValuesFormConfig';
import { PersonalityScaleDescription } from './PersonalityScaleDescription';
import { EFormStepperSections } from '../FormStepper';

export interface IPersonalityFormConfig {
  question: string;
  title: string;
  stage: string;
  updateValue: (a: string | string[] | number | number[]) => void;
  value: string;
  name: string;
  description?: string;
  options: ISegment[];
  section?: EFormStepperSections;
}

export const IsPersonalityV2 = (): boolean => {
  const config = useSelector((state: IStoreTypes) => state.user.formConfig?.values);
  return !!config?.version && config?.version === 'v2';
};

export const PersonalityFormIndex = (): string[] => {
  const personalityValues = useSelector((state: IStoreTypes) => state.personality.configValues) ?? [];
  if (personalityValues[0]?.key) {
    return personalityValues.map((a) => a.key);
  } else {
    return new Array(personalityValues.length).fill(0);
  }
};

export function PersonalityFormQuestions(): IPersonalityFormConfig[] {
  const personalityValues = useSelector((state: IStoreTypes) => state.personality);
  const config = useSelector((state: IStoreTypes) => state.user.formConfig.personality);
  const dispatch = useDispatch();
  const isPersonalityV2 = IsPersonalityV2();

  return isPersonalityV2 ?
    config.options?.map((f) => {
      const personalityOptions = [
        {
          value: 0,
          title: 'Very inaccurate',
        },
        {
          value: 1,
          title: 'Moderately inaccurate',
        },
        {
          value: 2,
          title: 'Neither',
        },
        {
          value: 3,
          title: 'Moderately accurate'
        },
        {
          value: 4,
          title: 'Very accurate'
        },
      ];
      return {
        title: f.title,
        description: 'Select how accurately the following statement describes you',
        options: personalityOptions,
        section: EFormStepperSections.PERSONALITY,
        customOptions: true,
        name: f.name,
        value: personalityValues[f.name],
        updateValue: (a: any) => dispatch(SavePersonalityFormData({[f.name]: a})),
      };
    })
  : config.map((f, parentIndex) => {
    return {
      question: 'Behavioural Style Questions',
      description: <PersonalityScaleDescription />,
      stage: `${parentIndex + 1}/4`,
      options: f.options.map((o, i) => ({
          ...o,
          valueLength: 7,
          questionNumber: makePrettyQuestionNumber(`${parentIndex * 6 + i + 1}`),
          value: personalityValues[o.name],
          updateValue: (a: any) => dispatch(SavePersonalityFormData({ [o.name]: a })),
      }))
    };
  });
}
