import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { TileDescription } from '../../../../../Components/General/TileDescriptor';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const BeyondProgramMentorStepFourContinue = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentee = position === ProgramPositions.mentee;
  const keepInMind = isMentee
    ? [
        'Your mentor may not have capacity to continue meeting with you outside of this program',
        'If your mentor wants to end the relationship, don’t take it personally',
        'Your mentor may have capacity to continue meeting with you initially, however due to ever-changing circumstances, this could change at any point',
        'If you and your mentor decide to continue the relationship, you will need to re-visit the expectations and boundaries of this relationship',
        'The training and structure of this program will soon end, so it is up to you to re-define what this relationship will look like moving forward',
        'You can always seek out another mentor (or two!)',
      ]
    : [
        'Your mentee may feel differently e.g. their goals or needs may have changed.',
        'Your mentee may want to continue meeting initially, however due to ever-changing circumstances, this could change at any point.',
        'If you and your mentee decide to continue the relationship, you will need to re-visit the expectations and boundaries of this relationship.',
        'The training and structure of this program will soon end, so it is up to you to re-define what this relationship will look like moving forward.',
        'You may want to take on another mentee and this may impact your impact your capacity.',
      ];
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="sm" fontWeight={700}>
          It looks like in an ideal world, you want to continue your relationship with your{' '}
          {isMentee ? 'mentor' : 'mentee'}.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="sm" fontWeight={700}>
          We encourage you to express this desire to continue the relationship, however there are
          some important things to keep in mind.
        </Text>
      </Grid>
      {keepInMind.map((tactic, key) => (
        <Grid item key={key}>
          <TileDescription tileKey={key + 1} description={tactic} type="positive" />
        </Grid>
      ))}
    </Grid>
  );
};
