import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { GivingReceivingFeedbackMenteeStepOne } from '../GivingReceivingFeedbackMentee/Steps/GivingReceivingFeedbackMenteeStepOne';
import { GivingReceivingFeedbackMenteeStepTwo } from '../GivingReceivingFeedbackMentee/Steps/GivingReceivingFeedbackMenteeStepTwo';
import { GivingReceivingFeedbackMenteeStepThree } from '../GivingReceivingFeedbackMentee/Steps/GivingReceivingFeedbackMenteeStepThree';
import { GivingReceivingFeedbackMenteeStepThreeIncorrect } from '../GivingReceivingFeedbackMentee/Steps/GivingReceivingFeedbackMenteeStepThreeIncorrect';
import { GivingReceivingFeedbackMenteeStepThreeCorrect } from '../GivingReceivingFeedbackMentee/Steps/GivingReceivingFeedbackMenteeStepThreeCorrect';
import { GivingReceivingFeedbackMenteeStepFour } from '../GivingReceivingFeedbackMentee/Steps/GivingReceivingFeedbackMenteeStepFour';
import { GivingReceivingFeedbackMenteeStepFourIncorrect } from '../GivingReceivingFeedbackMentee/Steps/GivingReceivingFeedbackMenteeStepFourIncorrect';
import { GivingReceivingFeedbackMenteeStepFourCorrect } from '../GivingReceivingFeedbackMentee/Steps/GivingReceivingFeedbackMenteeStepFourCorrect';
import { GivingReceivingFeedbackMenteeStepFive } from '../GivingReceivingFeedbackMentee/Steps/GivingReceivingFeedbackMenteeStepFive';
import { GivingReceivingFeedbackMenteeStepSix } from '../GivingReceivingFeedbackMentee/Steps/GivingReceivingFeedbackMenteeStepSix';
import { GivingReceivingFeedbackMenteeStepSeven } from '../GivingReceivingFeedbackMentee/Steps/GivingReceivingFeedbackMenteeStepSeven';
import { GivingReceivingFeedbackMenteeStepEight } from '../GivingReceivingFeedbackMentee/Steps/GivingReceivingFeedbackMenteeStepEight';
import { GivingReceivingFeedbackMenteeStepNine } from '../GivingReceivingFeedbackMentee/Steps/GivingReceivingFeedbackMenteeStepNine';
import { GivingReceivingFeedbackMenteeStepNineIncorrect } from '../GivingReceivingFeedbackMentee/Steps/GivingReceivingFeedbackMenteeStepNineIncorrect';
import { GivingReceivingFeedbackMenteeStepNineCorrect } from '../GivingReceivingFeedbackMentee/Steps/GivingReceivingFeedbackMenteeStepNineCorrect';
import { GivingReceivingFeedbackMenteeStepTen } from '../GivingReceivingFeedbackMentee/Steps/GivingReceivingFeedbackMenteeStepTen';
import { GivingReceivingFeedbackMenteeStepEleven } from '../GivingReceivingFeedbackMentee/Steps/GivingReceivingFeedbackMenteeStepEleven';

export const GivingReceivingFeedbackMentorModuleMapper: IModuleMapper[] = [
  {
    name: 'GivingReceivingFeedbackMentorStepOne',
    component: <GivingReceivingFeedbackMenteeStepOne />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    nextComponent: 'GivingReceivingFeedbackMentorStepTwo',
  },
  {
    name: 'GivingReceivingFeedbackMentorStepTwo',
    component: <GivingReceivingFeedbackMenteeStepTwo />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'GivingReceivingFeedbackMentorStepOne',
    nextComponent: 'GivingReceivingFeedbackMentorStepThree',
  },
  {
    name: 'GivingReceivingFeedbackMentorStepThree',
    component: <GivingReceivingFeedbackMenteeStepThree />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'GivingReceivingFeedbackMentorStepTwo',
    correctComponent: 'GivingReceivingFeedbackMentorStepThreeCorrect',
    incorrectComponent: 'GivingReceivingFeedbackMentorStepThreeIncorrect',
  },
  {
    name: 'GivingReceivingFeedbackMentorStepThreeIncorrect',
    component: <GivingReceivingFeedbackMenteeStepThreeIncorrect />,
    iterateStepper: true,
    hasQuestion: false,
    previousComponent: 'GivingReceivingFeedbackMentorStepThree',
    nextComponent: 'GivingReceivingFeedbackMentorStepFour',
  },
  {
    name: 'GivingReceivingFeedbackMentorStepThreeCorrect',
    component: <GivingReceivingFeedbackMenteeStepThreeCorrect />,
    iterateStepper: true,
    hasQuestion: false,
    previousComponent: 'GivingReceivingFeedbackMentorStepThree',
    nextComponent: 'GivingReceivingFeedbackMentorStepFour',
  },
  {
    name: 'GivingReceivingFeedbackMentorStepFour',
    component: <GivingReceivingFeedbackMenteeStepFour />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'GivingReceivingFeedbackMentorStepThree',
    correctComponent: 'GivingReceivingFeedbackMentorStepFourCorrect',
    incorrectComponent: 'GivingReceivingFeedbackMentorStepFourIncorrect',
  },
  {
    name: 'GivingReceivingFeedbackMentorStepFourIncorrect',
    component: <GivingReceivingFeedbackMenteeStepFourIncorrect />,
    iterateStepper: true,
    hasQuestion: false,
    previousComponent: 'GivingReceivingFeedbackMentorStepFour',
    nextComponent: 'GivingReceivingFeedbackMentorStepFive',
  },
  {
    name: 'GivingReceivingFeedbackMentorStepFourCorrect',
    component: <GivingReceivingFeedbackMenteeStepFourCorrect />,
    iterateStepper: true,
    hasQuestion: false,
    previousComponent: 'GivingReceivingFeedbackMentorStepFour',
    nextComponent: 'GivingReceivingFeedbackMentorStepFive',
  },
  {
    name: 'GivingReceivingFeedbackMentorStepFive',
    component: <GivingReceivingFeedbackMenteeStepFive />,
    iterateStepper: true,
    contentScreen: true,
    previousComponent: 'GivingReceivingFeedbackMentorStepFour',
    nextComponent: 'GivingReceivingFeedbackMentorStepSix',
  },
  {
    name: 'GivingReceivingFeedbackMentorStepSix',
    component: <GivingReceivingFeedbackMenteeStepSix />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'GivingReceivingFeedbackMentorStepFive',
    nextComponent: 'GivingReceivingFeedbackMentorStepSeven',
  },
  {
    name: 'GivingReceivingFeedbackMentorStepSeven',
    component: <GivingReceivingFeedbackMenteeStepSeven />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'GivingReceivingFeedbackMentorStepSix',
    nextComponent: 'GivingReceivingFeedbackMentorStepEight',
  },
  {
    name: 'GivingReceivingFeedbackMentorStepEight',
    component: <GivingReceivingFeedbackMenteeStepEight />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'GivingReceivingFeedbackMentorStepSeven',
    nextComponent: 'GivingReceivingFeedbackMentorStepNine',
  },
  {
    name: 'GivingReceivingFeedbackMentorStepNine',
    component: <GivingReceivingFeedbackMenteeStepNine />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'GivingReceivingFeedbackMentorStepEight',
    correctComponent: 'GivingReceivingFeedbackMentorStepNineCorrect',
    incorrectComponent: 'GivingReceivingFeedbackMentorStepNineIncorrect',
  },
  {
    name: 'GivingReceivingFeedbackMentorStepNineIncorrect',
    component: <GivingReceivingFeedbackMenteeStepNineIncorrect />,
    iterateStepper: true,
    hasQuestion: false,
    previousComponent: 'GivingReceivingFeedbackMentorStepNine',
    nextComponent: 'GivingReceivingFeedbackMentorStepTen',
  },
  {
    name: 'GivingReceivingFeedbackMentorStepNineCorrect',
    component: <GivingReceivingFeedbackMenteeStepNineCorrect />,
    iterateStepper: true,
    hasQuestion: false,
    previousComponent: 'GivingReceivingFeedbackMentorStepNine',
    nextComponent: 'GivingReceivingFeedbackMentorStepTen',
  },
  {
    name: 'GivingReceivingFeedbackMentorStepTen',
    component: <GivingReceivingFeedbackMenteeStepTen />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'GivingReceivingFeedbackMentorStepNine',
    nextComponent: 'GivingReceivingFeedbackMentorStepEleven',
  },
  {
    name: 'GivingReceivingFeedbackMentorStepEleven',
    component: <GivingReceivingFeedbackMenteeStepEleven />,
    iterateStepper: true,
    contentScreen: true,
    needsInteraction: false,
    submit: true,
    previousComponent: 'GivingReceivingFeedbackMentorStepTen',
  },
];
