import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { ContentCreator } from '../../Components/General/ContentCreator';
import { DeleteButton } from '../../Components/InputFields/BrancherButton';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { BrancherDispatch, UtilDeleteUserPrivateNotes } from '../../store/actions';
import { IContent, IPrivateNote } from '../../store/reducers/PrivateNotesReducer';
import { GeneratePDF } from './GeneratePDF';

const useStyles = makeStyles({
  note: {
    paddingLeft: 30,
    paddingRight: 30,
    overflowY: 'auto',
    minHeight: 'calc(100vh - 60px)',
    overflowX: 'hidden',
    width: '100%',
  },
  actions: {
    marginBottom: 30,
    marginTop: 40,
  },
});

interface IPrivateNoteComp {
  privateNote: IPrivateNote;
  contents: IContent[];
  title: string;
  setTitle: (title: string) => void;
}

export const PrivateNote: React.FC<IPrivateNoteComp> = (props) => {
  const { privateNote, setTitle, title } = props;
  const [updatingNote, setUpdatingNote] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const styles = useStyles();
  const contents = privateNote.contents;

  React.useEffect(() => {
    setTitle(privateNote.title);
  }, [privateNote]);

  const deleteNote = () => {
    setUpdatingNote(true);
    BrancherDispatch(
      dispatch,
      UtilDeleteUserPrivateNotes(privateNote.noteId, (resp) => {
        setUpdatingNote(false);
        setTitle('');
      }),
    );
  };

  return (
    <Grid container className={styles.note} direction="column" justify="space-between">
      <Grid container item>
        <Grid item xs={12}>
          <BrancherTextField
            value={title}
            updateValue={setTitle}
            maxChars={100}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <ContentCreator contents={contents} />
        </Grid>
      </Grid>
      <Grid item container justify="space-between" className={styles.actions}>
        <Grid item>
          {privateNote?.noteId && (
            <DeleteButton onClick={deleteNote} disabled={updatingNote} loading={updatingNote} />
          )}
        </Grid>
        <Grid item>
          {privateNote?.noteId && <GeneratePDF elementId="notes-creator" fileName={title} />}
        </Grid>
      </Grid>
    </Grid>
  );
};
