import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMakeTheMostMentee, SaveMakeTheMostMentor } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { SmartGoalsInfo } from '../../sharedComps/SmartGoalsInfo';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const MakeTheMostMenteeStepEight = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const sessionStore =
    position === ProgramPositions.mentee ? 'makeTheMostMentee' : 'makeTheMostMentor';
  const stepEightValue = useSelector(
    (state: IStoreTypes) => state.training[sessionStore]?.StepEight,
  );
  const { careerStory } = useSelector((state: IStoreTypes) => state.profileForm.profile);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const updateValue = (attr: string, val: string | string[]) => {
    const sessionSaveFunction =
      position === ProgramPositions.mentee ? SaveMakeTheMostMentee : SaveMakeTheMostMentor;
    const newStepEightVal = { ...stepEightValue, [attr]: val };
    dispatch(
      sessionSaveFunction({
        StepEight: {
          ...newStepEightVal,
          interacted: newStepEightVal?.smartConsiderations?.length > 0 && newStepEightVal?.newGoal,
        },
      }),
    );
  };

  let TipList: string[] = ['Consider anything that’s changed since you told us that information'];
  position === ProgramPositions.peer
    ? TipList.push('Consider your peer’s key skills and areas of expertise')
    : position === ProgramPositions.mentee
    ? TipList.push('Consider your mentor’s key skills and areas of expertise')
    : TipList.push('Consider your mentee’s key skills and areas of expertise');

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Creating your own goal
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Now that you know more about SMART goals, it’s time for you to create your own. When
          signing up to the Brancher program, you told us that…
        </Text>
      </Grid>
      <Grid item>
        <Text variant="sm" fontWeight={700}>
          Your career story and skills are:
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" color="purple" fontWeight={500}>
          {careerStory}
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          Considering what you wanted to get out of the program, try to be specific and develop a
          goal using the{' '}
        </Text>
        <BrancherLinkText
          variant="md"
          display="inline"
          underline="always"
          onClick={() => setOpen(true)}
          fontWeight={700}
        >
          SMART principles
        </BrancherLinkText>
        <Text variant="md" fontWeight={700} display="inline">
          {' '}
          that we will review throughout the program.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Tip:
        </Text>
        <BrancherList fontWeight={500} listItems={TipList} />
      </Grid>
      <Grid item>
        <BrancherTextField
          value={stepEightValue?.newGoal}
          fullWidth
          updateValue={(a: string) => updateValue('newGoal', a)}
          maxChars={200}
          placeholder="Enter here"
        />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Before submitting, consider whether the goal you have created is. Select the boxes that
          apply to your goal
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          noQuestion
          question=""
          name="smartConsiderations"
          value={stepEightValue?.smartConsiderations}
          options={MakeOptions([
            'Specific (clear and detailed)',
            'Measurable (goal success can be clearly identified)',
            'Attainable (reaching the goal is possible)',
            'Relevant (the goal is relevant to you)',
            'Timely (there is a reasonable time limit)',
          ])}
          updateValue={(a: string[]) => updateValue('smartConsiderations', a)}
        />
      </Grid>
      <SmartGoalsInfo controlDialog={setOpen} open={open} />
    </Grid>
  );
};
