import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMoDBeGreatMenteeData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const MoDBeGreatMenteeStepSix = () => {
  const stepSixValue = useSelector(
    (state: IStoreTypes) => state.training.modBeGreatMentee?.StepSix,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepSixCorrectVal = '0';
    dispatch(
      SaveMoDBeGreatMenteeData({
        StepSix: {
          selected: val,
          correct: StepSixCorrectVal === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center">
        <Text variant="md" fontWeight={700}>
          Do you know what to focus on with your mentor?
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepSixValue"
          value={stepSixValue?.selected}
          updateValue={updateValue}
          noQuestion
          exclusive
          options={MakeOptions(['Yes', 'No'])}
        />
      </Grid>
    </Grid>
  );
};
