import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../consts/colors';

const useStyles = makeStyles({
  fill: {
    fill: (props: { selected: boolean }) => (!props.selected ? Colors.white : Colors.purple),
  },
});

interface ICohortIcon {
  selected: boolean;
}

export const CohortIcon: React.FC<ICohortIcon> = ({ selected }) => {
  const styles = useStyles({ selected });
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 30">
      <g id="expanded">
        <g id="椭圆_13" data-name="椭圆 13">
          <path
            className={styles.fill}
            d="M38.29,10.93a8.76,8.76,0,0,0-2.77-2.18,5.15,5.15,0,1,0-7.31,0,8.61,8.61,0,0,0-2.76,2.18A7.19,7.19,0,0,0,24,13.62c-.14-.08-.28-.16-.43-.23a5.14,5.14,0,1,0-7.17,0l-.42.22a7.4,7.4,0,0,0-1.45-2.68,8.72,8.72,0,0,0-2.76-2.18,3.54,3.54,0,0,1-.63.53,3.91,3.91,0,0,1-.8.49,5.11,5.11,0,0,1-4.45,0,3.84,3.84,0,0,1-.79-.49h0a3.87,3.87,0,0,1-.62-.53,8.5,8.5,0,0,0-2.76,2.18,7,7,0,0,0-1.61,5.8c1,5.47,3.92,8.6,8,8.6a6.75,6.75,0,0,0,4.45-1.58c1.32,4,4,6.25,7.42,6.25s6.09-2.24,7.42-6.24a6.78,6.78,0,0,0,4.44,1.57c4.1,0,7-3.13,8-8.6A7,7,0,0,0,38.29,10.93ZM31.86,1.5a3.63,3.63,0,0,1,2,6.64,3.46,3.46,0,0,1-1.17.53h0a3.5,3.5,0,0,1-1.74,0h0a3.66,3.66,0,0,1-1.17-.53A3.63,3.63,0,0,1,31.86,1.5ZM16.36,9.7a3.64,3.64,0,1,1,5.58,3.08,3.6,3.6,0,0,1-1.4.53h0a3.4,3.4,0,0,1-1,0h0a3.67,3.67,0,0,1-3.1-3.61ZM26.54,21.13c0,.14-.05.29-.09.45s-.14.62-.23,1c-.73,2.68-2.4,6-6.22,6s-5.5-3.29-6.23-6c-.1-.33-.17-.65-.23-1s-.06-.31-.09-.45a4.41,4.41,0,0,1-.08-.72,5.53,5.53,0,0,1,1.26-3.72,1.34,1.34,0,0,1,.11-.14,5.46,5.46,0,0,1,.76-.76,5,5,0,0,1,.75-.57,6.55,6.55,0,0,1,1.65-.83,5,5,0,0,0,4.2,0,6.84,6.84,0,0,1,1.64.83,5,5,0,0,1,.75.57,5.6,5.6,0,0,1,.77.77,1.34,1.34,0,0,1,.11.14,5.51,5.51,0,0,1,1.25,3.69A4.65,4.65,0,0,1,26.54,21.13Zm11.88-4.67c-.5,2.75-2,7.37-6.56,7.37a5.35,5.35,0,0,1-4-1.69c.06-.24.11-.49.16-.74a7,7,0,0,0-1.61-5.81,8.4,8.4,0,0,0-1.08-1.07,5.66,5.66,0,0,1,1.27-2.63,7.24,7.24,0,0,1,3-2.12,5.13,5.13,0,0,0,4.46,0,7.24,7.24,0,0,1,3,2.12A5.5,5.5,0,0,1,38.42,16.46Z"
          />
        </g>
        <g id="椭圆_13-2" data-name="椭圆 13">
          <path
            className={styles.fill}
            d="M11.77,5.14a3.61,3.61,0,0,1-1.59,3A3.46,3.46,0,0,1,9,8.67a3.58,3.58,0,0,1-1.76,0,3.46,3.46,0,0,1-1.17-.53,3.64,3.64,0,1,1,5.69-3Z"
          />
        </g>
      </g>
    </svg>
  );
};
