import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { CharlieAvatar } from '../../avatars/Charlie';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const BeGreatMentorStepThirteen = () => {
  const stepThirteenValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentor.StepThirteen,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepThirteenCorrectVal = '1';
    dispatch(
      SaveBeGreatMentorData({
        StepThirteen: {
          selected: val,
          correct: val && StepThirteenCorrectVal === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Charlie has been very quiet...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          It is your first time using the GROWTH model and Charlie has been very quiet, or provided
          one-word answers to the questions you have been asking. Charlie often says "I don't know"
          and seems content to let you do most of the talking.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          What should you do? Choose one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="Try and think of something else to say"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="Stay silent and wait for Charlie to respond in full."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="Move onto the next step of the GROWTH model and hope it works better."
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepThirteenValue"
          value={stepThirteenValue?.selected}
          updateValue={updateValue}
          noQuestion
          exclusive
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
