import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { IntroductionMenteeDescriptionOne } from '../IntroductionMentee/Steps/IntroductionMenteeDescriptionOne';
import { IntroductionMenteeDescriptionTwo } from '../IntroductionMentee/Steps/IntroductionMenteeDescriptionTwo';
import { IntroductionMentorStepOne } from './Steps/IntroductionMentorStepOne';
import { IntroductionMentorStepOneCorrect } from './Steps/IntroductionMentorStepOneCorrect';
import { IntroductionMentorStepOneIncorrect } from './Steps/IntroductionMentorStepOneIncorrect';
import { IntroductionMentorStepTwo } from './Steps/IntroductionMentorStepTwo';
import { IntroductionMentorStepTwoIncorrect } from './Steps/IntroductionMentorStepTwoIncorrect';
import { IntroductionMentorStepTwoCorrect } from './Steps/IntroductionMentorStepTwoCorrect';
import { IntroductionMentorStepThree } from './Steps/IntroductionMentorStepThree';
import { IntroductionMentorStepThreeCorrect } from './Steps/IntroductionMentorStepThreeCorrect';
import { IntroductionMentorStepThreeIncorrect } from './Steps/IntroductionMentorStepThreeIncorrect';
import { IntroductionMentorStepFour } from './Steps/IntroductionMentorStepFour';
import { IntroductionMentorStepFourCorrect } from './Steps/IntroductionMentorStepFourCorrect';
import { IntroductionMentorStepFourIncorrect } from './Steps/IntroductionMentorStepFourIncorrect';
import { IntroductionMentorStepFive } from './Steps/IntroductionMentorStepFive';
import { IntroductionMenteeStepFiveCorrect } from '../IntroductionMentee/Steps/IntroductionMenteeStepFiveCorrect';
import { IntroductionMenteeStepFiveIncorrect } from '../IntroductionMentee/Steps/IntroductionMenteeStepFiveIncorrect';
import { IntroductionMentorStepSix } from './Steps/IntroductionMentorStepSix';
import { IntroductionMentorStepSixCorrect } from './Steps/IntroductionMentorStepSixCorrect';
import { IntroductionMentorStepSixIncorrect } from './Steps/IntroductionMentorStepSixIncorrect';
import { IntroductionMentorStepSeven } from './Steps/IntroductionMentorStepSeven';
import { IntroductionMenteeStepSevenIncorrect } from '../IntroductionMentee/Steps/IntroductionMenteeStepSevenIncorrect';
import { IntroductionMentorStepEight } from './Steps/IntroductionMentorStepEight';

export const IntroductionMentorModuleMapper: IModuleMapper[] = [
  {
    name: 'IntroductionMentorDescriptionOne',
    component: <IntroductionMenteeDescriptionOne />,
    hasQuestion: false,
    iterateStepper: false,
    nextComponent: 'IntroductionMentorDescriptionTwo',
  },
  {
    name: 'IntroductionMentorDescriptionTwo',
    component: <IntroductionMenteeDescriptionTwo />,
    hasQuestion: false,
    iterateStepper: false,
    previousComponent: 'IntroductionMentorDescriptionOne',
    nextComponent: 'IntroductionMentorStepOne',
  },
  {
    name: 'IntroductionMentorStepOne',
    component: <IntroductionMentorStepOne />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'IntroductionMentorDescriptionTwo',
    correctComponent: 'IntroductionMentorStepOneCorrect',
    incorrectComponent: 'IntroductionMentorStepOneIncorrect',
  },
  {
    name: 'IntroductionMentorStepOneCorrect',
    component: <IntroductionMentorStepOneCorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMentorStepOne',
    nextComponent: 'IntroductionMentorStepTwo',
  },
  {
    name: 'IntroductionMentorStepOneIncorrect',
    component: <IntroductionMentorStepOneIncorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMentorStepOne',
    nextComponent: 'IntroductionMentorStepTwo',
  },
  {
    name: 'IntroductionMentorStepTwo',
    component: <IntroductionMentorStepTwo />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'IntroductionMentorStepOne',
    correctComponent: 'IntroductionMentorStepTwoCorrect',
    incorrectComponent: 'IntroductionMentorStepTwoIncorrect',
  },
  {
    name: 'IntroductionMentorStepTwoCorrect',
    component: <IntroductionMentorStepTwoCorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMentorStepTwo',
    nextComponent: 'IntroductionMentorStepThree',
  },
  {
    name: 'IntroductionMentorStepTwoIncorrect',
    component: <IntroductionMentorStepTwoIncorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMentorStepTwo',
    nextComponent: 'IntroductionMentorStepThree',
  },
  {
    name: 'IntroductionMentorStepThree',
    component: <IntroductionMentorStepThree />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'IntroductionMentorStepTwo',
    correctComponent: 'IntroductionMentorStepThreeCorrect',
    incorrectComponent: 'IntroductionMentorStepThreeIncorrect',
  },
  {
    name: 'IntroductionMentorStepThreeCorrect',
    component: <IntroductionMentorStepThreeCorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMentorStepThree',
    nextComponent: 'IntroductionMentorStepFour',
  },
  {
    name: 'IntroductionMentorStepThreeIncorrect',
    component: <IntroductionMentorStepThreeIncorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMentorStepThree',
    nextComponent: 'IntroductionMentorStepFour',
  },
  {
    name: 'IntroductionMentorStepFour',
    component: <IntroductionMentorStepFour />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'IntroductionMentorStepThree',
    correctComponent: 'IntroductionMentorStepFourCorrect',
    incorrectComponent: 'IntroductionMentorStepFourIncorrect',
  },
  {
    name: 'IntroductionMentorStepFourCorrect',
    component: <IntroductionMentorStepFourCorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMentorStepFour',
    nextComponent: 'IntroductionMentorStepFive',
  },
  {
    name: 'IntroductionMentorStepFourIncorrect',
    component: <IntroductionMentorStepFourIncorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMentorStepFour',
    nextComponent: 'IntroductionMentorStepFive',
  },
  {
    name: 'IntroductionMentorStepFive',
    component: <IntroductionMentorStepFive />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'IntroductionMentorStepFour',
    correctComponent: 'IntroductionMentorStepFiveCorrect',
    incorrectComponent: 'IntroductionMentorStepFiveIncorrect',
  },
  {
    name: 'IntroductionMentorStepFiveCorrect',
    component: <IntroductionMenteeStepFiveCorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMentorStepFive',
    nextComponent: 'IntroductionMentorStepSix',
  },
  {
    name: 'IntroductionMentorStepFiveIncorrect',
    component: <IntroductionMenteeStepFiveIncorrect />,
    hasQuestion: false,
    iterateStepper: false,
    previousComponent: 'IntroductionMentorStepFive',
    nextComponent: 'IntroductionMentorStepFiveCorrect',
  },
  {
    name: 'IntroductionMentorStepSix',
    component: <IntroductionMentorStepSix />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'IntroductionMentorStepFive',
    correctComponent: 'IntroductionMentorStepSixCorrect',
    incorrectComponent: 'IntroductionMentorStepSixIncorrect',
  },
  {
    name: 'IntroductionMentorStepSixCorrect',
    component: <IntroductionMentorStepSixCorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMentorStepSix',
    nextComponent: 'IntroductionMentorStepSeven',
  },
  {
    name: 'IntroductionMentorStepSixIncorrect',
    component: <IntroductionMentorStepSixIncorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMentorStepSix',
    nextComponent: 'IntroductionMentorStepSeven',
  },
  {
    name: 'IntroductionMentorStepSeven',
    component: <IntroductionMentorStepSeven />,
    hasQuestion: true,
    iterateStepper: true,
    previousComponent: 'IntroductionMentorStepSix',
    correctComponent: 'IntroductionMentorStepEight',
    incorrectComponent: 'IntroductionMentorStepSevenIncorrect',
  },
  {
    name: 'IntroductionMentorStepSevenIncorrect',
    component: <IntroductionMenteeStepSevenIncorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMentorStepSeven',
    nextComponent: 'IntroductionMentorStepEight',
  },
  {
    name: 'IntroductionMentorStepEight',
    component: <IntroductionMentorStepEight />,
    needsInteraction: true,
    iterateStepper: false,
    submit: true,
    previousComponent: 'IntroductionMentorStepSeven',
  },
];
