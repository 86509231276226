import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { BrancherLinkText } from '../Components/General/BrancherLinkText';
import { Text } from '../Components/General/Text';
import { LogoutButton, NextButton } from '../Components/InputFields/BrancherButton';
import { GetRoute } from '../Components/Routing';
import { Colors } from '../consts/colors';
import { BrancherDispatch, SaveUserInfo, UtilSignOut } from '../store/actions';
import { IStoreTypes } from '../store/storeTypes';
import { Subscription } from '../types/SubscriptionTypes';
import { ProgramPositions } from './ProgramPositionOptions';
import { IQualifyingFormState } from './QualifyingForm';
import { GetSharedResourceLink } from '../utils/LinkUtils';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  activeStep: {
    color: Colors.purple,
    fontSize: 15,
    marginLeft: 5,
  },
  notActive: {
    color: Colors.black,
    fontSize: 15,
    marginLeft: 5,
  },
  completed: {
    color: Colors.lightGrey,
    fontSize: 15,
    marginLeft: 5,
  },
});

interface IBrancherProgramStepper extends IQualifyingFormState {
  isMentee?: boolean;
  nextStep?: () => void;
}

export const BrancherProgramStepper = (props: IBrancherProgramStepper) => {
  const requiresApplicationApproval = useSelector(
    (state: IStoreTypes) => state.user?.requiresApplicationApproval,
  );
  const isMoD = useSelector((state: IStoreTypes) => state.user.subscription).includes(
    Subscription.ADHOC_PAIRING,
  );
  const completedProfile = useSelector(
    (state: IStoreTypes) => state.profileForm?.profile?.completedProfile,
  );
  const mentees = useSelector((state: IStoreTypes) => state.user?.mentees);
  const mentors = useSelector((state: IStoreTypes) => state.user?.mentors);
  const roleLabels = useSelector((state: IStoreTypes) => state.user.roleLabels);
  const menteeRoleId = useSelector((state: IStoreTypes) => state.user.menteeRoleId);
  const mentorRoleId = useSelector((state: IStoreTypes) => state.user.mentorRoleId);
  const [redirectToDashboard, setRedirectToDashboard] = React.useState<boolean>(false);
  const [autoRedirectToProfile, setAutoRedirectToProfile] = React.useState<boolean>();
  const dispatch = useDispatch();
  const { step, setStep, isMentee } = props;
  const activeStep = 1;
  const styles = useStyles();
  const partneringPositionText = isMentee
    ? roleLabels[ProgramPositions.mentor]
    : roleLabels[ProgramPositions.mentee];
  const showApplicationApprovalRequired = requiresApplicationApproval && !completedProfile;

  const StepperLabels = !isMoD
    ? [
        'Complete the application form',
        'Wait patiently while we review your application',
        `Meet your ideal ${partneringPositionText}`,
      ]
    : !showApplicationApprovalRequired
    ? [
        'Complete the application form',
        `Complete your profile and find your ideal ${partneringPositionText}`,
      ]
    : [
        'Complete the application form',
        'Wait patiently while we review your application',
        `Complete your profile and find your ideal ${partneringPositionText}`,
      ];

  const updateUserData = () => {
    dispatch(
      SaveUserInfo({
        sessionPosition: isMentee ? ProgramPositions.mentee : ProgramPositions.mentor,
        sessionRoleId: isMentee ? menteeRoleId : mentorRoleId,
        sessionPair:
          isMoD && isMentee && mentors?.length > 0
            ? mentors[0]
            : isMoD && mentees?.length > 0
            ? mentees[0]
            : undefined,
      }),
    );
  };

  const controlRedirectToDashboard = () => {
    updateUserData();
    setRedirectToDashboard(true);
  };

  const controlRedirectToProfile = () => {
    updateUserData();
    setAutoRedirectToProfile(true);
  };

  return (
    <div className={styles.root}>
      <LogoutButton onClick={() => BrancherDispatch(dispatch, UtilSignOut())} color="primary" />
      {redirectToDashboard && <Redirect to={GetRoute('dashboard').path} />}
      {autoRedirectToProfile && <Redirect to={GetRoute('settings').path} />}
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key={StepperLabels[0]}>
          <StepLabel
            active={false}
            icon={<RadioButtonChecked className={styles.completed} />}
            disabled={true}
          >
            {StepperLabels[0]}
          </StepLabel>
          <StepContent>
            <Text variant="sm" marginTop={15}>
              Research tells us that when paired correctly, sponsoring has a number of position
              benefits for both parties. To ensure we pair you with the ideal person, please take
              the time to complete this application form.
            </Text>
            <Box marginTop={3} marginBottom={2}>
              <NextButton onClick={() => setStep(step + 1)}>Complete the form</NextButton>
            </Box>
          </StepContent>
        </Step>
        <Step key={StepperLabels[1]}>
          <StepLabel
            active={activeStep === 1}
            icon={
              activeStep === 1 ? (
                <RadioButtonUnchecked className={styles.activeStep} />
              ) : activeStep === 2 || completedProfile ? (
                <RadioButtonChecked className={styles.completed} />
              ) : (
                <RadioButtonUnchecked className={styles.notActive} />
              )
            }
            disabled={activeStep > 1}
          >
            {StepperLabels[1]}
          </StepLabel>
          <StepContent>
            <>
              <Text variant="sm" display="inline">
                Thank you for submitting your application! If you also want to sign up to be a{' '}
                {partneringPositionText},{' '}
              </Text>
              <BrancherLinkText
                variant="sm"
                display="inline"
                fontWeight={500}
                href={GetRoute('qualifying').path}
                underline="always"
              >
                click here
              </BrancherLinkText>
              <Text variant="sm" display="inline">
                .{' '}
              </Text>
              <br />
              {!requiresApplicationApproval ? (
                isMoD ? (
                  <>
                    {!completedProfile && (
                      <>
                        <Text variant="sm" marginLeft={-4} marginTop={15}>
                          Before meeting your {partneringPositionText}, please finish setting up
                          your profile.
                          <br />
                          You will be asked to upload a profile picture of yourself, so please have
                          a photo available to submit.
                        </Text>
                        <Box marginTop={3} marginBottom={3}>
                          <Grid container justify="flex-start" item>
                            <NextButton fullWidth onClick={controlRedirectToProfile}>
                              Complete my profile
                            </NextButton>
                          </Grid>
                        </Box>
                      </>
                    )}
                    <Box mt={2} mb={2} display="flex">
                      <NextButton onClick={controlRedirectToDashboard}>
                        Access your dashboard
                      </NextButton>
                    </Box>
                  </>
                ) : (
                  <Text variant="sm" display="inline">
                    Please keep an eye on your email for updates.
                  </Text>
                )
              ) : (
                <Text variant="sm" display="inline">
                  Your application has been submitted and will be reviewed soon. Please keep an eye
                  on your email for updates.
                </Text>
              )}
            </>
            <Box marginTop={3}>
              <Grid container direction="row" justify="center" item>
                <img src={GetSharedResourceLink('waitPatiently.png')} alt="wait-patiently" />
              </Grid>
            </Box>
          </StepContent>
        </Step>
        {(showApplicationApprovalRequired || (!showApplicationApprovalRequired && !isMoD)) && (
          <Step key={StepperLabels[2]}>
            <StepLabel
              active={false}
              icon={<RadioButtonUnchecked className={styles.notActive} />}
              disabled={false}
            >
              {StepperLabels[2]}
            </StepLabel>
          </Step>
        )}
      </Stepper>
    </div>
  );
};
