import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';

export const MoDGoalSettingMentorStepTwoCorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={600}>
          Great, we'll skip you through this training.
        </Text>
      </Grid>
    </Grid>
  );
};
