import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';

export const BeGreatMentorStepThreeResult = (props: { correct: boolean }) => {
  const { correct } = props;
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          {correct ? 'Correct' : 'Not quite'}.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          While Charlie's skills in this area could be built on in the future through coaching, in
          this situation it is not preferred because:
        </Text>
      </Grid>
      <Grid item>
        <BrancherList
          variant="md"
          listItems={[
            'It is something that Charlie lacks basic competence in',
            'It is time-critical',
            'It is high-stakes',
          ]}
        />
      </Grid>
      <Grid item>
        <Text variant="md">
          You could direct Charlie to some useful resources, explain why you would do things in a
          particular way and break them down into small steps. Based on your expertise, you could
          also work out a timeline with her to see what is achievable and should be prioritised by
          the deadline.
        </Text>
      </Grid>
      <Grid item container justify="center" xs={10} sm={11}>
        <BrancherTrainingImage
          src="https://media.brancher.com.au/training/beGreatMentor/StepThreeCorrect.png"
          alt="sailing_time"
        />
      </Grid>
    </Grid>
  );
};
