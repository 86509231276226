import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { CharlieAvatar } from '../../avatars/Charlie';

export const BeGreatMentorStepThirteenCorrect = () => {
  const stepThirteenValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentor.StepThirteen,
  );
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard
          custom
          success={stepThirteenValue.correct}
          incorrect={!stepThirteenValue.correct}
        >
          <Text variant="md" fontWeight={700}>
            {stepThirteenValue.correct ? "That's correct" : 'Not quite'}.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          {stepThirteenValue.correct ? (
            <Text variant="sm">
              You don't need to feel like you have to jump in. Try to pause and count to 10.
              Coaching can be a new approach for both mentors and mentees to get used to, so giving
              them some time to think is very effective in helping them come up with the answers
              themselves.
            </Text>
          ) : (
            <Text variant="sm">
              Jumping in to break the silence or move quickly through the conversation can be
              tempting, but stick with it.
            </Text>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
