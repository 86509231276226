import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { PageLayout } from '../../Components/General/PageLayout';
import { Text } from '../../Components/General/Text';
import { FormWithStepFieldLayout } from '../../Components/InputFields/FormWithStepFieldLayout';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { ProgramLeadContact } from './ProgramLeadContactLink';

export const Support: React.FC = () => {
  return (
    <PageLayout pageTitle="Support">
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <FormWithStepFieldLayout
            step={1}
            question={
              <>
                <Text variant="sm" fontWeight={600} display="inline">
                  For all technical questions or issues, contact{' '}
                </Text>
                <BrancherLinkText
                  display="inline"
                  variant="sm"
                  underline="always"
                  fontWeight={600}
                  href="mailto:hello@brancher.com.au?subject=Support Question"
                >
                  hello@brancher.com.au
                </BrancherLinkText>
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormWithStepFieldLayout
            step={2}
            question={
              <>
                <Text variant="sm" fontWeight={600} display="inline">
                  For any program specific enquiries, reach out to{' '}
                </Text>
                <ProgramLeadContact />
              </>
            }
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
};
