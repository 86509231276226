import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';

export const MoDGoalSettingMentorStepOne = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md">
          It's time to start thinking about how you can support your mentee with goal setting. As a
          mentor, you should also set goals around developing your mentoring skills and driving the
          relationship forward.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={600} marginBottom={10}>
          Why is goal setting important?
        </Text>
        <Text variant="md">
          Goal setting isn’t just a trend, it's a core success lever. Research shows that goal
          setting increases career satisfaction and enhances career success through promotions and
          salary increases. Goals enable mentoring relationships to turn 'nice coffee chats' to
          'tangible action orientated' discussions.
        </Text>
      </Grid>
    </Grid>
  );
};
