import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import { SaveBeGreatMentorData } from '../../../../../store/actions';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { SuggestionCard } from '../../SuggestionCard';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { BrancherDialog } from '../../../../../Components/General/BrancherDialog';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { NPSScale } from '../../sharedComps/NPSScale';
import { CompetenceScale } from '../../sharedComps/CompetenceScale';
import { ConfidenceScale } from '../../sharedComps/ConfidenceScale';

export const BeGreatMentorStepNineteen = () => {
  const StepNineteenValue = useSelector((state: IStoreTypes) => state.training.beGreatMentor);
  const dispatch = useDispatch();
  const [openDialog, setDialog] = React.useState(false);

  const updateCompetence = (val: number) => {
    dispatch(
      SaveBeGreatMentorData({
        StepNineteen: {
          ...StepNineteenValue?.StepNineteen,
          competenceFinal: val,
          interacted:
            StepNineteenValue?.rating != null &&
            StepNineteenValue?.confidenceFinal != null &&
            val != null,
        },
        competenceFinal: val,
      }),
    );
  };

  const updateConfidence = (val: number) => {
    dispatch(
      SaveBeGreatMentorData({
        StepNineteen: {
          ...StepNineteenValue?.StepNineteen,
          confidenceFinal: val,
          interacted:
            StepNineteenValue?.rating != null &&
            StepNineteenValue?.competenceFinal != null &&
            val != null,
        },
        confidenceFinal: val,
      }),
    );
  };

  const updateRating = (val: number) => {
    dispatch(
      SaveBeGreatMentorData({
        StepNineteen: {
          ...StepNineteenValue?.StepNineteen,
          interacted:
            StepNineteenValue?.competenceFinal != null &&
            StepNineteenValue?.confidenceFinal != null &&
            val != null,
        },
        rating: val,
      }),
    );
  };

  const updateFeedback = (val: string) => {
    dispatch(
      SaveBeGreatMentorData({
        StepNineteen: {
          ...StepNineteenValue?.StepNineteen,
          interacted: Boolean(
            StepNineteenValue?.rating != null &&
              StepNineteenValue?.competenceFinal != null &&
              StepNineteenValue?.confidenceFinal != null,
          ),
        },
        feedback: val,
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <BrancherDialog
        setClose={() => setDialog(false)}
        open={openDialog}
        labelledBy="Being a great mentor attributes"
      >
        <BrancherList
          fontWeight={400}
          listItems={[
            'Know when to be directive or coach, depending on the situation',
            'Apply the GROWTH coaching model to help your mentee problem solve and set achievable goals',
            'Identify and circumvent common awkward scenarios experienced in coaching',
            'Know how to effectively adopt a directive style when required',
          ]}
        />
      </BrancherDialog>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700}>
          This is the last set of questions.
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" display="inline">
          Prior to completing this training, you told us you were an{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          {StepNineteenValue.competenceBaseline + 1}
        </Text>
        <Text variant="md" display="inline">
          {' '}
          out of 7 regarding your level of competence and{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          {StepNineteenValue.confidenceBaseline + 1}
        </Text>
        <Text variant="md" display="inline">
          {' '}
          out of 7 regarding your level of confidence to perform{' '}
        </Text>
        <BrancherLinkText
          display="inline"
          variant="md"
          underline="always"
          onClick={() => setDialog(true)}
        >
          these behaviours
        </BrancherLinkText>
        <Text variant="sm" display="inline">
          .
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your competence rating now?
        </Text>
        <CompetenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepNineteenValue?.StepNineteen?.competenceFinal}
          valueLength={7}
          updateValue={updateCompetence}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your confidence rating now?
        </Text>
        <ConfidenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepNineteenValue?.StepNineteen?.confidenceFinal}
          valueLength={7}
          updateValue={updateConfidence}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          On a scale of 0 - 10, how likely are you to recommend this training to another colleague
          within this mentoring program?
        </Text>
        <NPSScale />
        <SegmentedControlGroup
          fullWidth
          value={StepNineteenValue?.rating}
          valueLength={11}
          updateValue={updateRating}
          startFromZero
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Do you have any other feedback for us?
        </Text>
        <BrancherTextField
          value={StepNineteenValue?.feedback}
          updateValue={(a: string) => updateFeedback(a)}
          placeholder="Enter here"
          maxChars={400}
          id="feedback"
          name="feedback"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700}>
          The next steps are:
        </Text>
      </Grid>
      <Box marginLeft={2}>
        <BrancherList
          fontWeight={400}
          variant="md"
          listItems={[
            <>
              <Text variant="md" marginBottom={15}>
                Save a copy of these resources and refer to them for future use:
              </Text>
              <BrancherList
                variant="md"
                fontWeight={400}
                listStyleType="circle"
                listItems={['GROWTH coaching framework', 'When to be a director or coach']}
              />
            </>,
            'Start practising your coaching skills. Set a specific goal around applying the learnt coaching principles in your next mentoring meeting.',
          ]}
        />
      </Box>
      <Grid item xs={12}>
        <SuggestionCard>
          Make sure you click the "✓" button to complete this training.
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
