import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { parse } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import IconButton from '@material-ui/core/IconButton';
import DeleteForever from '@material-ui/icons/DeleteForever';
import {
  calenderEventFormat,
  EUpdateDeleteRecurrenceType,
  formatISOStyleTimeZone,
  getActualMeetingIdForOrganiser,
} from './CalendarEvents';
import { ECalendars, EEventResponseStatus, IMeetingAgendas, INewEvent } from './Scheduler';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { TimePicker } from '../../Components/General/TimePicker';
import {
  ActionButton,
  AddButton,
  CalendarButton,
  DeleteButton,
} from '../../Components/InputFields/BrancherButton';
import { IStoreTypes } from '../../store/storeTypes';
import { EProfileAttributes } from '../../store/reducers/ProfileFormReducer';
import { Text } from '../../Components/General/Text';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../consts/colors';
import { IExtendedGroupData } from '../Groups/Group';
import { IMeetingAction } from '../../store/reducers/MeetingsReducer';
import { BrancherCheckbox } from '../../Components/InputFields/BrancherCheckbox';
import { BrancherSelect } from '../../Components/InputFields/BrancherSelect';
import { MeetingMinutesDescription } from './MeetingMinutesDescription';

const useStyles = makeStyles({
  parentContainer: {
    marginBottom: (props: { canUpdate: boolean }) => (props.canUpdate ? 100 : 0),
  },
  fixedActionPane: {
    position: 'absolute',
    bottom: 0,
    height: 100,
    marginLeft: 30,
    backgroundColor: Colors.backgroundLightPurple,
    width: '100vw',
    zIndex: 100,
    boxShadow: `inset 0px 2px 2px -2px ${Colors.darkGrey};`,
  },
});

interface IUpdateCalendarEvent {
  updateEvent: (event: INewEvent) => void;
  deleteEvent: (deleteType?: EUpdateDeleteRecurrenceType) => void;
  updatingEvent: boolean;
  meetingData: INewEvent;
  group?: IExtendedGroupData;
}

// Google Docs: https://developers.google.com/calendar/api/guides/recurringevents#modifying_or_deleting_instances
export const UpdateCalendarEvent: React.FC<IUpdateCalendarEvent> = ({
  updateEvent,
  updatingEvent,
  meetingData,
  deleteEvent,
  group,
}) => {
  const partnerEmail = useSelector(
    (state: IStoreTypes) => state.profileForm?.pairProfile?.[EProfileAttributes.CONTACT_EMAIL],
  );
  const focusAreas = useSelector((state: IStoreTypes) => state.meetings?.focusAreas);
  const partnerRoleId = useSelector((state: IStoreTypes) => state.profileForm?.pairProfile?.roleId);
  const sessionRoleId = useSelector((state: IStoreTypes) => state.user?.sessionRoleId);
  const sessionPair = useSelector((state: IStoreTypes) => state.user?.sessionPair);
  const userFirstName = useSelector((state: IStoreTypes) => state.user?.firstName);
  const userEmail = useSelector((state: IStoreTypes) => state.user?.email);
  const userId = useSelector((state: IStoreTypes) => state.user?.id);
  const [description, setDescription] = React.useState<string>(meetingData.description);
  const [location, setLocation] = React.useState<string>(meetingData.location);
  const [title, setTitle] = React.useState<string>(meetingData.title);
  // Because the id passed in is obfuscated with an appended '--1' to allow the calendar to render multiple users
  const extendedMeetingData = useSelector((state: IStoreTypes) => state.meetings?.meetings)?.find(
    (m) => meetingData.id.indexOf(m.meetingId) !== -1,
  );
  const [topicId, setTopicId] = React.useState<string>(extendedMeetingData?.topicId ?? '');
  const [agendas, setAgendas] = React.useState<IMeetingAgendas[]>(
    extendedMeetingData?.agendas ?? [],
  );
  const [actions, setActions] = React.useState<IMeetingAction[]>(
    extendedMeetingData?.actions ?? [],
  );
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const [updateModalOpen, setUpdateModalOpen] = React.useState<boolean>(false);
  const [actionItem, setActionItem] = React.useState<string>('');
  const [agendaItem, setAgendaItem] = React.useState<string>('');
  const [startDate, setStartDate] = React.useState<Date>(
    parse(meetingData.start, calenderEventFormat, new Date()),
  );
  const [endDate, setEndDate] = React.useState<Date>(
    parse(meetingData.end, calenderEventFormat, new Date()),
  );

  const canUpdate: boolean = meetingData?.canUpdate;
  const isRecurringEvent: boolean = !!meetingData.recurringEventId;
  const styles = useStyles({ canUpdate });

  const rebuildDateWithSelectedEventDate = (time: Date, day: string): Date => {
    const eventDate = parse(day, calenderEventFormat, new Date())
      .toISOString()
      .split('T')[0];
    return new Date(`${eventDate}T${time.toISOString().split('T')[1]}`);
  };

  const updateNewEvent = (
    updateType: EUpdateDeleteRecurrenceType = EUpdateDeleteRecurrenceType.SINGLE,
  ) => {
    const attendeesList = group?.groupId
      ? [...group.mentors, ...group.mentees].map((g) => ({
          email: g.profile[EProfileAttributes.CONTACT_EMAIL],
          organizer: group.owner === userId,
          responseStatus:
            group.owner === userId
              ? EEventResponseStatus.ACCEPTED
              : meetingData.calendar === ECalendars.AZURE
              ? EEventResponseStatus.NOT_RESPONDED
              : EEventResponseStatus.NEEDS_ACTION,
          roleId: g.roleId,
        }))
      : [
          {
            email: userEmail,
            organizer: true,
            responseStatus: EEventResponseStatus.ACCEPTED,
            roleId: sessionRoleId,
          },
          {
            email: partnerEmail,
            organizer: false,
            responseStatus:
              meetingData.calendar === ECalendars.AZURE
                ? EEventResponseStatus.NOT_RESPONDED
                : EEventResponseStatus.NEEDS_ACTION,
            roleId: partnerRoleId,
          },
        ];
    updateEvent({
      id: getActualMeetingIdForOrganiser(meetingData.id),
      attendees: attendeesList,
      start: formatISOStyleTimeZone(rebuildDateWithSelectedEventDate(startDate, meetingData.start)),
      end: formatISOStyleTimeZone(rebuildDateWithSelectedEventDate(endDate, meetingData.end)),
      title,
      description,
      location,
      recurringEventId: meetingData?.recurringEventId,
      updateDeleteRecurrenceType: updateType,
      calendar: meetingData.calendar,
      groupId: group?.groupId ?? '',
      topicId,
      agendas,
      actions,
    });
  };

  const controlDeleteModal = () => {
    if (isRecurringEvent) {
      setDeleteModalOpen(true);
    } else {
      deleteEvent();
    }
  };

  const controlUpdateModal = () => {
    if (isRecurringEvent) {
      setUpdateModalOpen(true);
    } else {
      updateNewEvent();
    }
  };

  const isCreateDisabled = (): boolean => {
    return !(!!title && !!startDate && !!endDate);
  };

  const controlSetTitle = (selectedTopic: string) => {
    const topic = focusAreas.find((fa) => fa.topicId === selectedTopic);
    if (!!topic) {
      setTitle(
        group?.groupId
          ? `Group mentoring with ${group.title} - ${topic.topicTitle}`
          : `Mentoring with ${sessionPair?.name?.split(' ')[0]} & ${userFirstName} - ${
              topic.topicTitle
            }`,
      );
    }
  };

  const controlSelectAgenda = (selectedTopic: string) => {
    setTopicId(selectedTopic);
    controlSetTitle(selectedTopic);
    const topic = focusAreas.find((fa) => fa.topicId === selectedTopic);
    setAgendas(topic.agendas.map((ta) => ({ ...ta, completed: false })));
  };

  const updateAgendaStatus = (agendaId: string, completedAgenda: boolean) => {
    const updatedAgendas = agendas.map((agenda) => {
      if (agenda.agendaId === agendaId) {
        return { ...agenda, completed: completedAgenda };
      } else {
        return agenda;
      }
    });
    setAgendas(updatedAgendas);
  };

  const updateActionStatus = (actionId: string, completedAction: boolean) => {
    const updatedActions = actions.map((action) => {
      if (action.meetingActionId === actionId) {
        return { ...action, completed: completedAction };
      } else {
        return action;
      }
    });
    setActions(updatedActions);
  };

  const setRemoveAction = (actionIndex: number) => {
    setActions(actions.filter((m, i) => i !== actionIndex));
  };

  const setRemoveAgendaItem = (agendaIndex: number) => {
    setAgendas(agendas.filter((m, i) => i !== agendaIndex));
  };

  const controlAddActionItem = () => {
    setActions([
      ...actions,
      { meetingActionId: uuidv4(), actionTitle: actionItem, completed: false },
    ]);
    setActionItem('');
  };

  const controlAddAgendaItem = () => {
    setAgendas([...agendas, { agendaId: uuidv4(), agendaTitle: agendaItem, completed: false }]);
    setAgendaItem('');
  };

  return (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      justify="center"
      spacing={3}
      className={styles.parentContainer}
    >
      <BrancherDialog
        setClose={() => setDeleteModalOpen(false)}
        labelledBy="delete-event"
        title="You're deleting an event"
        open={deleteModalOpen}
        contentWidth
      >
        <Grid container item alignItems="center" justify="center">
          <Grid item>
            <Text variant="xs" marginBottom={30}>
              Do you want to delete this and all future occurrences of this event, or only the
              selected occurrence?
            </Text>
          </Grid>
          <Grid container direction="column" spacing={2} alignItems="center" justify="center">
            <Grid item xs={12}>
              <ActionButton onClick={() => deleteEvent(EUpdateDeleteRecurrenceType.SINGLE)}>
                Only this event
              </ActionButton>
            </Grid>
            <Grid item xs={12}>
              <ActionButton onClick={() => deleteEvent(EUpdateDeleteRecurrenceType.ALL_FUTURE)}>
                All future occurrences of this event
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
      </BrancherDialog>
      <BrancherDialog
        setClose={() => setUpdateModalOpen(false)}
        labelledBy="update-event"
        title="You're updating an event"
        open={updateModalOpen}
      >
        <Grid container item alignItems="center" justify="center">
          <Grid item>
            <Text variant="xs" marginBottom={30}>
              Do you want to update this and all future occurrences of this event, or only the
              selected occurrence?
            </Text>
          </Grid>
          <Grid container direction="column" spacing={2} alignItems="center" justify="center">
            <Grid item xs={12}>
              <ActionButton onClick={() => updateNewEvent(EUpdateDeleteRecurrenceType.SINGLE)}>
                Only this event
              </ActionButton>
            </Grid>
            <Grid item xs={12}>
              <ActionButton onClick={() => updateNewEvent(EUpdateDeleteRecurrenceType.ALL_FUTURE)}>
                All future occurrences of this event
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
      </BrancherDialog>
      <Grid item xs={12}>
        <TimePicker
          fullWidth
          value={startDate}
          updateValue={(newDate: Date) => setStartDate(newDate)}
          id="start-time-picker"
          name="start-time-picker"
          label="Start Time"
          disabled={!canUpdate}
        />
      </Grid>
      <Grid item xs={12}>
        <TimePicker
          fullWidth
          value={endDate}
          updateValue={(newDate: Date) => setEndDate(newDate)}
          id="end-time-picker"
          name="end-time-picker"
          label="End Time"
          disabled={!canUpdate}
        />
      </Grid>
      <Grid item xs={12}>
        <BrancherTextField
          fullWidth
          value={location}
          updateValue={setLocation}
          name="location"
          id="location"
          label="Location"
          rows={4}
          disabled={!canUpdate}
        />
      </Grid>
      <Grid item xs={12}>
        <MeetingMinutesDescription creatingMeeting={false} />
      </Grid>
      <Grid item xs={12}>
        <BrancherSelect
          options={focusAreas.map((focusArea) => ({
            label: `${focusArea.topicTitle}`,
            value: focusArea.topicId,
          }))}
          value={topicId}
          inputLabel="Suggested Topics"
          name="agendaTopic"
          id="agendaTopic"
          fullWidth
          updateValue={(topic: string) => controlSelectAgenda(topic)}
          helpText="Choose a template for the meeting - this will provide a list of talking points for current and future meetings"
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="sm" fontWeight={600} marginBottom={15}>
          Talking points (remember to tick off any you've already completed)
        </Text>
        {agendas?.length > 0 ? (
          agendas?.map((agenda, agendaIndex) => (
            <Grid item key={agenda.agendaId}>
              <BrancherCheckbox
                name={agenda.agendaTitle}
                value={agenda.completed}
                updateValue={(comp: boolean) => updateAgendaStatus(agenda.agendaId, comp)}
                label={
                  <>
                    <Text variant="sm" display="inline" marginRight={15}>
                      {agenda.agendaTitle}
                    </Text>
                    <IconButton onClick={() => setRemoveAgendaItem(agendaIndex)}>
                      <DeleteForever color="error" />
                    </IconButton>
                  </>
                }
              />
            </Grid>
          ))
        ) : (
          <Text variant="sm">You don't have any talking points assigned to this meeting yet.</Text>
        )}
        <AddMeetingItem
          addValue={controlAddAgendaItem}
          setValue={setAgendaItem}
          canUpdate={true}
          value={agendaItem}
          label="Add new talking point"
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="sm" fontWeight={600} marginBottom={15}>
          Action items (remember to tick off any you've already completed)
        </Text>
        {actions?.length > 0 ? (
          actions?.map((action, actionIndex) => (
            <Grid item key={action.meetingActionId}>
              <BrancherCheckbox
                name={action.actionTitle}
                value={action.completed}
                updateValue={(comp: boolean) => updateActionStatus(action.meetingActionId, comp)}
                label={
                  <>
                    <Text variant="sm" display="inline" marginRight={15}>
                      {action.actionTitle}
                    </Text>
                    <IconButton onClick={() => setRemoveAction(actionIndex)}>
                      <DeleteForever color="error" />
                    </IconButton>
                  </>
                }
              />
            </Grid>
          ))
        ) : (
          <Text variant="sm">You don't have any action items assigned to this meeting yet.</Text>
        )}
        <AddMeetingItem
          addValue={controlAddActionItem}
          setValue={setActionItem}
          canUpdate={true}
          value={actionItem}
          label="Add new action item"
        />
      </Grid>
      <Grid item xs={12}>
        <BrancherTextField
          fullWidth
          value={title}
          updateValue={setTitle}
          name="title"
          id="title"
          label="Title"
          disabled={!canUpdate}
          multiline
        />
      </Grid>
      <Grid item xs={12}>
        <BrancherTextField
          fullWidth
          value={description}
          updateValue={setDescription}
          name="description"
          id="description"
          label="Description"
          rows={12}
          multiline
          disabled={!canUpdate}
        />
      </Grid>
      {isRecurringEvent && (
        <Grid item xs={12} container justify="center">
          <Text variant="xs" marginTop={15} fontWeight={600}>
            This is a recurring event
          </Text>
        </Grid>
      )}

      {canUpdate && (
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justify="space-between"
          className={styles.fixedActionPane}
        >
          <Grid item>
            <DeleteButton onClick={controlDeleteModal} disabled={updatingEvent}>
              Delete Event
            </DeleteButton>
          </Grid>
          <Grid item>
            <CalendarButton
              onClick={controlUpdateModal}
              disabled={isCreateDisabled()}
              loading={updatingEvent}
            >
              Update Meeting
            </CalendarButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

interface IAddActionItem {
  setValue: (value: string) => void;
  addValue: () => void;
  value: string;
  label: string;
  canUpdate: boolean;
}

export const AddMeetingItem: React.FC<IAddActionItem> = ({
  setValue,
  value,
  addValue,
  canUpdate,
  label,
}) => {
  return (
    <Grid item xs={12} container alignItems="center" justify="space-between">
      <Grid item xs={8}>
        <BrancherTextField
          fullWidth
          value={value}
          updateValue={setValue}
          name="meetingItem"
          id="meetingItem"
          label={label}
          multiline
          disabled={!canUpdate}
        />
      </Grid>
      <Grid item xs={3} container spacing={1}>
        <AddButton onClick={addValue} />
      </Grid>
    </Grid>
  );
};
