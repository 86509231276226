import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { QuestionHeader } from '../FormFieldTypes/QuestionHeader';
import { Text } from '../../Components/General/Text';
import { BrancherList } from '../../Components/General/BrancherList';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { IStoreTypes } from '../../store/storeTypes';

export const ValuesInfo = () => {
  const sessionPosition = useSelector((state: IStoreTypes) => state.user?.sessionPosition);
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const isMentee = sessionPosition === ProgramPositions.mentee;
  const otherPosition = isMentee ? ProgramPositions.mentor : ProgramPositions.mentee;
  const partnerPosition = roleLabels[otherPosition];

  return (
    <>
      <Grid item xs={12}>
        <QuestionHeader>
          Next, we will be asking you a series of questions to measure your values.
        </QuestionHeader>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md">
          We ask about your values so we can take this into account when considering your ideal{' '}
          {partnerPosition}.
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Box mb={2} display="flex" width="100%">
          <Grid container>
            <Grid item xs={12}>
              <Text variant="md" fontWeight={600} marginTop={30}>
                Tips:
              </Text>
            </Grid>
            <BrancherList
              listItems={[
                "Answer honestly, even if you don't like the answer.",
                'There are no right or wrong answers.',
                'Describe your values as they are now, not as you wish to be in the future.',
                'Your spontaneous answer is usually the most accurate.',
              ]}
            />
          </Grid>
        </Box>
      </Grid>
    </>
  );
};
