import * as React from 'react';
import { ScaleText } from '../../../../Components/General/ScaleText';

export const CompetenceScale = () => {
  return (
    <>
      <ScaleText scale={7} description="Expert" />
      <ScaleText scale={6} description="Advanced" />
      <ScaleText scale={5} description="Competent" />
      <ScaleText scale={4} description="Developing" />
      <ScaleText scale={3} description="Under developed" />
      <ScaleText scale={2} description="Needs significant improvement" />
      <ScaleText scale={1} description="Cannot or never performed before" />
    </>
  );
};
