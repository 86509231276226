import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { AlexAvatar } from '../../avatars/Alex';
import { SuggestionCard } from '../../SuggestionCard';

export const MoDBeGreatMentorStepSixCorrect = () => {
  const stepSixValue = useSelector(
    (state: IStoreTypes) => state.training.modBeGreatMentor?.StepSix,
  );

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepSixValue.correct} incorrect={!stepSixValue.correct}>
          <Text variant="md" fontWeight={700}>
            {stepSixValue.correct
              ? 'Correct! The correct answer is A and B.'
              : 'Not quite. The correct answer is both A and B.'}
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <AlexAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          <Grid item>
            <Text variant="sm">
              As a mentor, there is a place to be both directive and take a coaching approach. In
              this situation, it is important to unpack why Alex is not being more proactive and
              then follow this up with your expectations.
            </Text>
          </Grid>
          <Grid item>
            <Text variant="sm">
              Take a coaching approach to unpack why Alex is not setting the meeting agendas:
            </Text>
            <Text variant="sm" fontWeight={600}>
              "Have you done the first online training activity yet?"
            </Text>
            <Text variant="sm" fontWeight={600}>
              "What is your understanding of a mentee’s role?"
            </Text>
            <Text variant="sm" fontWeight={600}>
              "How do you feel about creating the meeting agendas moving forward?"
            </Text>
            <Text variant="sm" fontWeight={600}>
              "Are you comfortable to do that?"
            </Text>
          </Grid>
        </Grid>
        <Grid item>
          <Text variant="sm" marginTop={20}>
            You could then summarise your expectations by saying:
          </Text>
        </Grid>
        <Grid item>
          <Text variant="md">
            "Great, so for our next meeting, you'll come prepared with an agenda to discuss."
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
};
