import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { SuggestionCard } from '../../SuggestionCard';
import { GetTrainingResourceLink } from '../../../../../utils/LinkUtils';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';

export const BeyondProgramMentorStepSixContinueIncorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="sm" fontWeight={700}>
          We can help you feel more confident than that.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="sm" display="inline">
          To help you have this conversation with your mentee, you might like to leverage Brancher’s{' '}
        </Text>
        <BrancherLinkText
          variant="sm"
          underline="always"
          fontWeight={600}
          display="inline"
          target="_blank"
          href={GetTrainingResourceLink(
            EStructuredTrainingModules.GIVING_RECEIVING_FEEDBACK_MENTOR,
            'Master_feedback_model.pdf',
          )}
        >
          ‘MASTER’ feedback model
        </BrancherLinkText>
        <Text variant="sm" display="inline">
          {' '}
          that you learnt in the training 'Giving and receiving effective feedback'.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="sm">
          Whilst this conversation is not a feedback conversation – the MASTER model is still
          relevant and provides a good framework for the conversation.
        </Text>
      </Grid>
      <Grid item container justify="center">
        <BrancherTrainingImage
          src={GetTrainingResourceLink(
            EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR,
            'master-model.png',
          )}
          alt="master-model"
        />
      </Grid>
      <Grid item>
        <Text variant="sm" fontWeight={700}>
          Here’s an example of how you could use the MASTER model to discuss the future of your
          mentoring relationship.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="lg" fontWeight={600} color="purple">
          M = Motivation
        </Text>
        <Text variant="sm" marginTop={20} marginBottom={30}>
          Set the scene/conversation and ask a question to get their buy-in.
        </Text>
        <SuggestionCard neutral custom>
          <Text variant="sm" fontWeight={600}>
            “Given that this program is coming to an end, should we reflect on our experience to
            date and then have a conversation about what our relationship will look like moving
            forward?”
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="lg" fontWeight={600} color="purple">
          A = Achievable.
        </Text>
        <Text variant="sm" marginTop={20} marginBottom={30}>
          If you want to continue the relationship, you need to have realistic expectations about
          what this would look like moving forward.
        </Text>
        <SuggestionCard neutral custom>
          <Text variant="sm" fontWeight={600}>
            “Unfortunately I do not have capacity to continue meeting monthly, however I’d love to
            stay in touch and meet a couple of times a year.”
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="lg" fontWeight={600} color="purple">
          S = Specific.
        </Text>
        <Text variant="sm" marginTop={20} marginBottom={30}>
          Be objective, provide behavioural examples.
        </Text>
        <SuggestionCard neutral custom>
          <Text variant="sm" fontWeight={600}>
            “I’ve really enjoyed coaching you through some interesting challenges. I was thinking if
            you had a specific challenge or career decision you’d like to talk through, you could
            always give me a call?”
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="lg" fontWeight={600} color="purple">
          T = Timely.
        </Text>
        <Text variant="sm" marginTop={20} marginBottom={20}>
          This conversation must occur prior to the program ending. No delays.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="lg" fontWeight={600} color="purple" marginBottom={30}>
          E = Emotional Hook.
        </Text>
        <SuggestionCard neutral custom>
          <Text variant="sm" fontWeight={600}>
            “I have really enjoyed working with you as you have been open, vulnerable and really
            open to listening to different perspective. Thank you.”
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="lg" fontWeight={600} color="purple">
          R = Relevant.
        </Text>
        <Text variant="sm" marginTop={20} marginBottom={30}>
          The conversation must be relevant for them, taking into account their perspective.
        </Text>
        <SuggestionCard neutral custom>
          <Text variant="sm" fontWeight={600}>
            “No hard feelings if you would like to end the relationship now, I understand you may
            want a different type of mentor to help you through the next year.”
          </Text>
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
