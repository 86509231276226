import * as React from 'react';
import {
  EMoDTrainingModules,
  ReadableTrainingModules,
} from '../../../../store/reducerTypes/TrainingReducer.types';
import { ModuleWrapper } from '../ModuleWrapper';
import { MoDSituationalMentorshipMentorModuleMapper } from './MoDSituationalMentorshipMentorModuleMapper';

export const MoDSituationalMentorshipMentor = () => (
  <ModuleWrapper
    moduleMap={MoDSituationalMentorshipMentorModuleMapper}
    moduleName={EMoDTrainingModules.MOD_SITUATIONAL_MENTORSHIP_MENTOR}
    title={ReadableTrainingModules[EMoDTrainingModules.MOD_SITUATIONAL_MENTORSHIP_MENTOR]}
  />
);
