import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { CharlieAvatar } from '../../avatars/Charlie';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const BeGreatMentorStepNine = () => {
  const stepNineValue = useSelector((state: IStoreTypes) => state.training.beGreatMentor.StepNine);
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepNineCorrectVal = '2';
    dispatch(
      SaveBeGreatMentorData({
        StepNine: {
          selected: val,
          correct: StepNineCorrectVal === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Charlie responds...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          Charlie responds, "I could either go back to University or do a short online course. My
          work also offers a leadership development program which could be an alternative option."
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          You respond with, "They all sound like great options. What are the pros and cons of each?
          Which options are you thinking of doing?
        </Text>
        <Text variant="md" display="inline" color="purple" fontWeight={700}>
          {' '}
          (will)
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Alex responds with, "I really like the idea of going back to University. While it takes
          longer to complete, I think I would learn more at Uni compared to the work program, so
          that is what I'm leaning towards."
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          How do you respond? Choose one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="I'm not sure that's the best option for you. Will that really achieve your goal?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="How will you sustain your success? How will you ensure you follow through with this?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="What steps would you need to take to go back to University? What might get in the way? How might you respond to that?"
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepNineValue"
          value={stepNineValue?.selected}
          updateValue={updateValue}
          noQuestion
          exclusive
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
