import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {
  BrancherTextField,
  IBrancherTextField,
} from '../../Components/InputFields/BrancherTextField';
import { QuestionHeader } from './QuestionHeader';

type text = Omit<IBrancherTextField, 'placeholder'>;

export interface ITextControl extends text {
  name: string;
  question: React.ReactNode;
  placeholder?: string;
}

export const TextControl = (props: ITextControl) => {
  const { value, name, updateValue, placeholder, question, ...other } = props;
  return (
    <>
      <Grid item xs={12}>
        <QuestionHeader>{question}</QuestionHeader>
      </Grid>
      <Grid item xs={12}>
        <BrancherTextField
          value={value}
          name={name}
          updateValue={updateValue}
          placeholder={placeholder}
          {...other}
        />
        <Box marginBottom={4} />
      </Grid>
    </>
  );
};
