import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { Text } from '../../../../../Components/General/Text';

export const MoDGoalSettingMentorStepThree = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="lg" fontWeight={600}>
          SMART Goals
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">A popular strategy for goal setting is the SMART goal principles.</Text>
      </Grid>
      <Grid item>
        <Text variant="md">This means that the goal is:</Text>
        <BrancherList
          listItems={[
            <Text variant="md">Specific (clear and detailed)</Text>,
            <Text variant="md">Measurable (goal success can be clearly identified)</Text>,
            <Text variant="md">Attainable (reaching the goal is possible)</Text>,
            <Text variant="md">Relevant (the goal is relevant to you)</Text>,
            <Text variant="md">Timely (there is a reasonable time limit)</Text>,
          ]}
        />
      </Grid>
    </Grid>
  );
};
