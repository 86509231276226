import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { MoDBeGreatMenteeStepEightIncorrect } from '../MoDBeGreatMentee/Steps/MoDBeGreatMenteeStepEightIncorrect';
import { MoDBeGreatMenteeStepOne } from '../MoDBeGreatMentee/Steps/MoDBeGreatMenteeStepOne';
import { MoDBeGreatMenteeStepTwo } from '../MoDBeGreatMentee/Steps/MoDBeGreatMenteeStepTwo';
import { MoDBeGreatMenteeStepThree } from '../MoDBeGreatMentee/Steps/MoDBeGreatMenteeStepThree';
import { MoDBeGreatMenteeStepFour } from '../MoDBeGreatMentee/Steps/MoDBeGreatMenteeStepFour';
import { MoDBeGreatMenteeStepEight } from '../MoDBeGreatMentee/Steps/MoDBeGreatMenteeStepEight';
import { MoDBeGreatMenteeStepNine } from '../MoDBeGreatMentee/Steps/MoDBeGreatMenteeStepNine';
import { MoDBeGreatMenteeStepTen } from '../MoDBeGreatMentee/Steps/MoDBeGreatMenteeStepTen';
import { MoDBeGreatMenteeStepEleven } from '../MoDBeGreatMentee/Steps/MoDBeGreatMenteeStepEleven';
import { MoDBeGreatMenteeStepEightCorrect } from '../MoDBeGreatMentee/Steps/MoDBeGreatMenteeStepEightCorrect';
import { MoDBeGreatMentorStepFive } from './Steps/MoDBeGreatMentorStepFive';
import { MoDBeGreatMentorStepFiveCorrect } from './Steps/MoDBeGreatMentorStepFiveCorrect';
import { MoDBeGreatMentorStepSeven } from './Steps/MoDBeGreatMentorStepSeven';
import { MoDBeGreatMentorStepSevenCorrect } from './Steps/MoDBeGreatMentorStepSevenCorrect';
import { MoDBeGreatMentorStepSevenIncorrect } from './Steps/MoDBeGreatMentorStepSevenIncorrect';
import { MoDBeGreatMentorStepSix } from './Steps/MoDBeGreatMentorStepSix';
import { MoDBeGreatMentorStepSixCorrect } from './Steps/MoDBeGreatMentorStepSixCorrect';

export const MoDBeGreatMentorModuleMapper: IModuleMapper[] = [
  {
    name: 'MoDBeGreatMentorStepOne',
    component: <MoDBeGreatMenteeStepOne />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    nextComponent: 'MoDBeGreatMentorStepTwo',
  },
  {
    name: 'MoDBeGreatMentorStepTwo',
    component: <MoDBeGreatMenteeStepTwo />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MoDBeGreatMentorStepOne',
    nextComponent: 'MoDBeGreatMentorStepThree',
  },
  {
    name: 'MoDBeGreatMentorStepThree',
    component: <MoDBeGreatMenteeStepThree />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MoDBeGreatMentorStepTwo',
    nextComponent: 'MoDBeGreatMentorStepFour',
  },
  {
    name: 'MoDBeGreatMentorStepFour',
    component: <MoDBeGreatMenteeStepFour />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MoDBeGreatMentorStepThree',
    nextComponent: 'MoDBeGreatMentorStepFive',
  },
  {
    name: 'MoDBeGreatMentorStepFive',
    component: <MoDBeGreatMentorStepFive />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'MoDBeGreatMentorStepFour',
    correctComponent: 'MoDBeGreatMentorStepFiveCorrect',
    incorrectComponent: 'MoDBeGreatMentorStepFiveCorrect',
  },
  {
    name: 'MoDBeGreatMentorStepFiveCorrect',
    component: <MoDBeGreatMentorStepFiveCorrect />,
    iterateStepper: true,
    previousComponent: 'MoDBeGreatMentorStepFive',
    nextComponent: 'MoDBeGreatMentorStepSix',
  },
  {
    name: 'MoDBeGreatMentorStepSix',
    component: <MoDBeGreatMentorStepSix />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'MoDBeGreatMentorStepFive',
    correctComponent: 'MoDBeGreatMentorStepSixCorrect',
    incorrectComponent: 'MoDBeGreatMentorStepSixCorrect',
  },
  {
    name: 'MoDBeGreatMentorStepSixCorrect',
    component: <MoDBeGreatMentorStepSixCorrect />,
    iterateStepper: true,
    previousComponent: 'MoDBeGreatMentorStepSix',
    nextComponent: 'MoDBeGreatMentorStepSeven',
  },
  {
    name: 'MoDBeGreatMentorStepSeven',
    component: <MoDBeGreatMentorStepSeven />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'MoDBeGreatMentorStepSix',
    correctComponent: 'MoDBeGreatMentorStepSevenCorrect',
    incorrectComponent: 'MoDBeGreatMentorStepSevenIncorrect',
  },
  {
    name: 'MoDBeGreatMentorStepSevenCorrect',
    component: <MoDBeGreatMentorStepSevenCorrect />,
    iterateStepper: true,
    previousComponent: 'MoDBeGreatMentorStepSeven',
    nextComponent: 'MoDBeGreatMentorStepEight',
  },
  {
    name: 'MoDBeGreatMentorStepSevenIncorrect',
    component: <MoDBeGreatMentorStepSevenIncorrect />,
    iterateStepper: true,
    previousComponent: 'MoDBeGreatMentorStepSeven',
    nextComponent: 'MoDBeGreatMentorStepEight',
  },
  {
    name: 'MoDBeGreatMentorStepEight',
    component: <MoDBeGreatMenteeStepEight />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'MoDBeGreatMentorStepSeven',
    correctComponent: 'MoDBeGreatMentorStepEightCorrect',
    incorrectComponent: 'MoDBeGreatMentorStepEightIncorrect',
  },
  {
    name: 'MoDBeGreatMentorStepEightCorrect',
    component: <MoDBeGreatMenteeStepEightCorrect />,
    iterateStepper: true,
    previousComponent: 'MoDBeGreatMentorStepEight',
    nextComponent: 'MoDBeGreatMentorStepNine',
  },
  {
    name: 'MoDBeGreatMentorStepEightIncorrect',
    component: <MoDBeGreatMenteeStepEightIncorrect />,
    iterateStepper: true,
    previousComponent: 'MoDBeGreatMentorStepEight',
    nextComponent: 'MoDBeGreatMentorStepNine',
  },
  {
    name: 'MoDBeGreatMentorStepNine',
    component: <MoDBeGreatMenteeStepNine />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MoDBeGreatMentorStepEight',
    nextComponent: 'MoDBeGreatMentorStepTen',
  },
  {
    name: 'MoDBeGreatMentorStepTen',
    component: <MoDBeGreatMenteeStepTen />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MoDBeGreatMentorStepNine',
    nextComponent: 'MoDBeGreatMentorStepEleven',
  },
  {
    name: 'MoDBeGreatMentorStepEleven',
    component: <MoDBeGreatMenteeStepEleven />,
    iterateStepper: true,
    needsInteraction: true,
    submit: true,
    previousComponent: 'MoDBeGreatMentorStepTen',
  },
];
