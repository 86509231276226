import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { BrancherDialog } from '../../../../Components/General/BrancherDialog';
import { Text } from '../../../../Components/General/Text';
import { BrancherList } from '../../../../Components/General/BrancherList';

interface ISmartGoalsInfo {
  controlDialog: (a: boolean) => void;
  open: boolean;
}

export const SmartGoalsInfo = (props: ISmartGoalsInfo) => {
  const { controlDialog, open } = props;
  return (
    <BrancherDialog open={open} setClose={() => controlDialog(false)} labelledBy="SMART Goals" fitLargeScreen>
      <Text variant="sm" fontWeight={600} marginBottom={30} marginTop={15}>
        A SMART goal is one that is specific, measurable, achievable, relevant and timely.
      </Text>
      <Specific />
      <Measurable />
      <Achievable />
      <Relevant />
      <Timely />
    </BrancherDialog>
  );
};

const Specific = () => (
  <Grid container direction="column">
    <Grid item>
      <Text variant="md" fontWeight={600} color="purple">
        S = Is it specific?
      </Text>
    </Grid>
    <Grid item>
      <BrancherList
        fontWeight={400}
        listItems={[
          'What will the goal accomplish? Think about how you will accomplish it and why you want to accomplish it',
          'Avoid generalities',
          'Focus on a particular aspect of your performance or on the task in question',
        ]}
      />
    </Grid>
  </Grid>
);

const Measurable = () => (
  <Grid container direction="column">
    <Grid item>
      <Text variant="md" fontWeight={600} color="purple">
        M = Is it measurable?
      </Text>
    </Grid>
    <Grid item>
      <BrancherList
        fontWeight={400}
        listItems={[
          'How will you measure success? Will there be a visible outcome/impact? Has the goal been achieved? (List at least two indicators)',
          'Consider quality, quantity, timeliness and cost',
        ]}
      />
    </Grid>
  </Grid>
);

const Achievable = () => (
  <Grid container direction="column">
    <Grid item>
      <Text variant="md" fontWeight={600} color="purple" noPreWrap>
        A = Is it achievable?
      </Text>
    </Grid>
    <Grid item>
      <BrancherList
        fontWeight={400}
        listItems={[
          'Is it possible? Have others done it successfully?',
          'Do you have the necessary knowledge, skills, abilities and resources to accomplish the goal?',
          'Will meeting the goal challenge without defeating?',
          'Do not assign too many goals',
        ]}
      />
    </Grid>
  </Grid>
);

const Relevant = () => (
  <Grid container direction="column">
    <Grid item>
      <Text variant="md" fontWeight={600} color="purple">
        R = Is it relevant?
      </Text>
    </Grid>
    <Grid item>
      <BrancherList
        fontWeight={400}
        listItems={[
          'What is the reason, purpose or benefit of accomplishing the goal?',
          "Is the goal you're setting aligned to your broader long-term goal?",
          'How will the goal help you?',
        ]}
      />
    </Grid>
  </Grid>
);

const Timely = () => (
  <Grid container direction="column">
    <Grid item>
      <Text variant="md" fontWeight={600} color="purple" noPreWrap>
        T = Is it timely?
      </Text>
    </Grid>
    <Grid item>
      <BrancherList
        fontWeight={400}
        listItems={[
          'What is the established completion date and does that completion date create a practical sense of urgency?',
        ]}
      />
    </Grid>
  </Grid>
);
