import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { SaveBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { MenteeMentorResponsibilities } from '../../sharedComps/MenteeMentorResponsibilities';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';

export const BeGreatMentorStepOne = () => {
  const dispatch = useDispatch();
  const [openDialog, setDialog] = React.useState(false);

  const updateValue = () => {
    dispatch(
      SaveBeGreatMentorData({
        StepOne: {
          interacted: true,
        },
      }),
    );
  };

  const controlDialog = () => {
    setDialog(true);
    updateValue();
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" display="inline">
          Being a great mentor involves doing{' '}
        </Text>
        <BrancherLinkText variant="md" display="inline" onClick={controlDialog} underline="always">
          these behaviours
        </BrancherLinkText>
        <Text variant="md" display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          You may recall these behaviours from the training,{' '}
        </Text>
        <Text variant="md" display="inline" color="purple" fontWeight={700}>
          Introduction to Mentoring
        </Text>
        <Text variant="md" display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" marginTop={40} marginBottom={30} display="inline">
          As a mentor, you need to wear two hats. One of those hats involves using your expertise to
          offer suggestions, advice and tips (i.e.{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700}>
          'being directive'
        </Text>
        <Text variant="md" display="inline">
          ). The other involves asking the right questions that help your mentee to arrive at their
          own answers to their problems, without jumping in to tell them what to do (i.e.{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700}>
          'coaching'
        </Text>
        <Text variant="md" display="inline">
          ).
        </Text>
      </Grid>
      <MenteeMentorResponsibilities open={openDialog} onClose={() => setDialog(false)} />
    </Grid>
  );
};
