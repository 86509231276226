import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ConfirmationNavigationDialog } from '../../Components/Routing/ConfirmNavigationDialog';
import { RouteLeavingGuard } from '../../Components/Routing/RouteLeavingGuard';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { Text } from '../../Components/General/Text';
import { TileSelector } from '../../Components/General/TileSelector';
import { AddButton, PrevButton } from '../../Components/InputFields/BrancherButton';
import { GetRoute } from '../../Components/Routing';
import { Colors } from '../../consts/colors';
import { INote, IPrivateNote } from '../../store/reducers/PrivateNotesReducer';
import { IStoreTypes } from '../../store/storeTypes';
import { BrancherDispatch, UtilGetUserPrivateNotes } from '../../store/actions/';
import { PrivateNote } from './PrivateNote';
import { ENoteContexts, RichTextNote } from './RichTextNote';
import { emptyContent } from './LexicalNote';

const useStyles = makeStyles({
  notesSideBar: {
    display: 'inline-block',
    background: Colors.backgroundLightPurple,
    maxHeight: '100vh',
    minHeight: '100vh',
    paddingTop: 60,
    paddingLeft: 20,
    paddingRight: 20,
    position: 'fixed',
    overflowY: 'auto',
    '--webkit-overflow-scrolling': 'touch',
    '--ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
  },
  notesButton: {
    marginBottom: 20,
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
    },
    width: '-webkit-fill-available',
  },
  notes: {
    width: '100%',
    overflowX: 'hidden',
    minHeight: '100vh',
    alignItems: 'flex-start',
  },
  privateNotes: {
    paddingTop: 60,
    overflowY: 'auto',
    '--webkit-overflow-scrolling': 'touch',
    '--ms-overflow-style': 'none',
    marginLeft: (props: { tablet: boolean }) =>
      props.tablet ? `calc(((100% / 12) * 4) + 80px)` : `calc(((100% / 12) * 4))`,
  },
});

const emptyPrivateNote = { title: 'Title', contents: emptyContent };

export const PrivateNotes = () => {
  const tablet = useMediaQuery(useTheme().breakpoints.between('sm', 'md'));
  const privateNoteId = new URLSearchParams(useLocation().search).get('n');
  const [hasPrivateNotes, setHasPrivateNotes] = React.useState<boolean>(false);
  const privateNotes = useSelector((state: IStoreTypes) => state.privateNotes?.privateNotes);
  const [currentNote, setCurrentNote] = React.useState<INote>(emptyPrivateNote);
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>('');
  const [contents, setContents] = React.useState<any>();
  const [noteToRedirectTo, setNoteToRedirectTo] = React.useState<string>('');
  const dispatch = useDispatch();
  const styles = useStyles({ tablet });

  const setNewSimpleNote = () => {
    setCurrentNote(emptyPrivateNote);
  };

  const checkSavedStatus = (note: string) => {
    if (formNotSaved()) {
      setNoteToRedirectTo(note);
      setOpenConfirmationDialog(true);
    } else {
      setPreviousNote(note);
    }
  };

  const setPreviousNote = (note: string) => {
    setNoteToRedirectTo('');
    setCurrentNote(privateNotes.find((m) => m.noteId === note));
  };

  React.useEffect(() => {
    BrancherDispatch(
      dispatch,
      UtilGetUserPrivateNotes(() => {
        setHasPrivateNotes(true);
        if (privateNoteId) {
          setCurrentNote(privateNotes.find((m) => m.noteId === privateNoteId));
        }
      }),
    );
  }, []);

  const formNotSaved = (): boolean => {
    if (JSON.stringify(contents) === JSON.stringify(emptyPrivateNote.contents)) {
      return false;
    } else {
      return currentNote?.title !== title || currentNote?.contents !== contents;
    }
  };

  return (
    <Grid container className={styles.notes}>
      <RouteLeavingGuard when={formNotSaved()} />
      <ConfirmationNavigationDialog
        handleAccept={() => {
          setOpenConfirmationDialog(false);
          setPreviousNote(noteToRedirectTo);
        }}
        handleNotYet={() => setOpenConfirmationDialog(false)}
        open={openConfirmationDialog}
      />
      <Grid item xs={4} lg={3} container className={styles.notesSideBar} alignItems="flex-start">
        <Grid item>
          <Box marginBottom={3} display="flex">
            <Link to={GetRoute('notes').path}>
              <PrevButton variant="text" color="primary" transparent>
                Notes
              </PrevButton>
            </Link>
          </Box>
        </Grid>
        <Grid
          item
          container
          justify="space-between"
          xs={12}
          alignItems="center"
          className={styles.notesButton}
        >
          <Grid item>
            <Text variant="lg" fontWeight={600}>
              Private Notes
            </Text>
          </Grid>
          <Grid item>
            <AddButton onClick={setNewSimpleNote} />
          </Grid>
        </Grid>
        {hasPrivateNotes ? (
          privateNotes?.length > 0 ? (
            privateNotes?.map((a, p) => (
              <div onClick={() => checkSavedStatus(a.noteId)} key={p} className={styles.hover}>
                <TileSelector active={a.noteId === currentNote.noteId}>
                  <Text variant="sm" fontWeight={600}>
                    {a.title}
                  </Text>
                </TileSelector>
              </div>
            ))
          ) : (
            <Grid item>
              <Text variant="sm" fontWeight={500} display="inline">
                You currently have no notes, you can make a new private note over there{' '}
              </Text>
              <span aria-label="arrow" role="img">
                🏹
              </span>
            </Grid>
          )
        ) : (
          <Grid container direction="column" alignItems="center" justify="center">
            <Grid item xs={5}>
              <CircularProgress color="secondary" size={64} />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={8} lg={9} container justify="center" className={styles.privateNotes}>
        {currentNote?.noteId === '' ? (
          <Grid container alignItems="center" justify="center">
            <Text variant="sm" color="purple" fontWeight={500} display="inline">
              Please select a note to view/edit, or{' '}
            </Text>
            <BrancherLinkText
              variant="sm"
              underline="always"
              fontWeight={500}
              display="inline"
              onClick={() => setCurrentNote(emptyPrivateNote)}
            >
              create a new one.
            </BrancherLinkText>
          </Grid>
        ) : (
          hasPrivateNotes &&
          (currentNote?.contents?.root ? (
            <RichTextNote
              setContents={setContents}
              setTitle={setTitle}
              title={title}
              contents={typeof contents === 'object' ? contents : ''}
              note={currentNote}
              resetNote={(note: IPrivateNote) => setCurrentNote({ ...emptyPrivateNote, ...note })}
              context={ENoteContexts.PRIVATE}
            />
          ) : (
            <PrivateNote
              setTitle={setTitle}
              title={title}
              contents={contents}
              privateNote={currentNote}
            />
          ))
        )}
      </Grid>
    </Grid>
  );
};
