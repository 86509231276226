import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { SaveBeGreatMenteeData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';

export const BeGreatMenteeStepThree = () => {
  const dispatch = useDispatch();

  const updateValue = () => {
    dispatch(
      SaveBeGreatMenteeData({
        StepThree: {
          interacted: true,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" display="inline" fontWeight={700}>
          One of the enablers to being a great mentee is having a{' '}
        </Text>
        <BrancherLinkText
          fontWeight={700}
          target="_blank"
          variant="md"
          display="inline"
          onClick={updateValue}
          href="https://media.brancher.com.au/training/beGreatMentee/fixed_vs_growth_mindset.pdf"
          underline="always"
        >
          growth mindset
        </BrancherLinkText>
        <Text variant="md" display="inline" fontWeight={700}>
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          A{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700}>
          growth mindset
        </Text>
        <Text variant="md" display="inline">
          {' '}
          is the understanding that intelligence, abilities and attributes can be{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700}>
          developed
        </Text>
        <Text variant="md" display="inline">
          {' '}
          as opposed to a{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700}>
          fixed mindset
        </Text>
        <Text variant="md" display="inline">
          {' '}
          which assumes that intelligence, abilities and attributes are{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700}>
          inherently fixed
        </Text>
        <Text variant="md" display="inline">
          {' '}
          and static.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Having a growth mindset enables you to:
        </Text>
        <BrancherList
          fontWeight={400}
          listItems={[
            'Be more mentally primed to approach and take on challenges',
            'Take advantage of feedback',
            'Be effortful and persistent in achieving goals',
            'Make the most of your mentoring relationship',
          ]}
        />
      </Grid>
      <Grid item container justify="center">
        <BrancherTrainingImage
          src="https://media.brancher.com.au/training/beGreatMentee/time.png"
          alt="be_great_time"
        />
      </Grid>
    </Grid>
  );
};
