import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { CharlieAvatar } from '../../avatars/Charlie';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const BeGreatMentorStepFifteen = () => {
  const stepFifteenValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentor.StepFifteen,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepFifteenCorrectVal = ['0', '2', '3'];
    dispatch(
      SaveBeGreatMentorData({
        StepFifteen: {
          selected: val,
          correct:
            val?.length === 3 &&
            StepFifteenCorrectVal.includes(val[0]) &&
            StepFifteenCorrectVal.includes(val[1]) &&
            StepFifteenCorrectVal.includes(val[2]),
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Charlie keeps changing their mind...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          Charlie keeps changing their mind on the goal and doesn't seem certain about what they
          expect as an outcome. This makes it difficult to understand what can be used to solve the
          goal.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          What should you do? You can choose more than one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="What is your main goal here? If it worked out in an ideal world, what would be different?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="Okay, let's focus on this one as it seems like the clearest one to work with."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="Is this goal aligned to your personal values? Is this something you truly want to achieve?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="D."
          optionText="Okay, which part of this goal do you think is under your control?"
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepFifteenValue"
          value={stepFifteenValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(['A', 'B', 'C', 'D'])}
        />
      </Grid>
    </Grid>
  );
};
