import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import capitalize from '@material-ui/core/utils/capitalize';
import { useDispatch, useSelector } from 'react-redux';
import { PageLayout } from '../../Components/General/PageLayout';
import { Text } from '../../Components/General/Text';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { BrancherDispatch, UtilGetMentoringRequests } from '../../store/actions';
import { IMentoringRequest } from '../../store/reducers/CohortReducer';
import { IStoreTypes } from '../../store/storeTypes';
import { MentoringPartnerRequest } from './MentoringPartnerRequest';

export const MentoringPartnerRequests: React.FC = () => {
  const desktop = useMediaQuery(useTheme().breakpoints.down('lg'));
  const [gettingMentoringPartnerRequests, setGettingMentoringPartnerRequests] = React.useState<
    boolean
  >(false);
  const [mentoringPartnerRequests, setMentoringPartnerRequests] = React.useState<
    IMentoringRequest[]
  >([]);
  const [sentPartnerRequests, setSentPartnerRequests] = React.useState<IMentoringRequest[]>([]);
  const recheckMentoringRequests = useSelector(
    (state: IStoreTypes) => state.cohort?.recheckMentoringRequests,
  );
  const sessionPosition = useSelector((state: IStoreTypes) => state.user?.sessionPosition);
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const isMentee = sessionPosition === ProgramPositions.mentee;
  const partnerPosition = isMentee
    ? roleLabels[ProgramPositions.mentor]
    : roleLabels[ProgramPositions.mentee];

  const dispatch = useDispatch();

  React.useEffect(() => {
    retrieveMentoringRequests();
  }, []);

  React.useEffect(() => {
    retrieveMentoringRequests();
  }, [recheckMentoringRequests]);

  const retrieveMentoringRequests = () => {
    setGettingMentoringPartnerRequests(true);
    BrancherDispatch(
      dispatch,
      UtilGetMentoringRequests((requests) => {
        if (requests.success) {
          setMentoringPartnerRequests(requests?.data?.receivedRequests);
          setSentPartnerRequests(requests?.data?.sentRequests);
        } else {
          setMentoringPartnerRequests([]);
        }
        setGettingMentoringPartnerRequests(false);
      }),
    );
  };

  const roleLabelPosition = roleLabels[sessionPosition];
  const partnerRoleLabelPosition = roleLabels[partnerPosition];

  return (
    <PageLayout
      pageTitle={`Your ${capitalize(roleLabelPosition)} Requests`}
      backButtonRoute="cohort"
      backButtonLabel="Cohort"
      nextButtonRoute="findMentoringPartner"
      nextButtonLabel={`Find a ${capitalize(partnerRoleLabelPosition)}`}
    >
      <Grid item container alignItems="center" direction="column">
        <Grid item>
          <Text variant="lg" color="purple" fontWeight={700} marginBottom={10}>
            Pending your response
          </Text>
        </Grid>
        <Grid item>
          <Text variant="xs" marginBottom={20} color="secondaryGrey">
            These are requests from {partnerRoleLabelPosition}s that you can either accept or reject
          </Text>
        </Grid>
      </Grid>
      {!gettingMentoringPartnerRequests ? (
        mentoringPartnerRequests?.length > 0 ? (
          <Grid container justify="center">
            <Grid
              container
              item
              xs={12}
              lg={11}
              alignItems="center"
              justify={!desktop ? 'center' : 'space-between'}
            >
              {mentoringPartnerRequests?.map((request, requestIndex) => (
                <Grid key={requestIndex} item xs={12} lg={6} container justify="center">
                  <Grid item>
                    <MentoringPartnerRequest
                      mentoringRequesterRoleId={
                        request.requestInitiator === ProgramPositions.mentor
                          ? request.mentorRoleId
                          : request.menteeRoleId
                      }
                      requestDetails={request}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          mentoringPartnerRequests?.length === 0 && (
            <Grid item container justify="center">
              <Text variant="sm" color="purple" marginBottom={40} fontWeight={500}>
                You don't have any requests from {partnerRoleLabelPosition}s right now.
              </Text>
            </Grid>
          )
        )
      ) : (
        <Grid container direction="column" justify="center" alignItems="center" item xs={5}>
          <CircularProgress color="secondary" size={64} />
        </Grid>
      )}
      <Grid item container alignItems="center" direction="column">
        <Grid item>
          <Text variant="lg" color="purple" fontWeight={700} marginTop={50} marginBottom={10}>
            Your requests
          </Text>
        </Grid>
        <Grid item>
          <Text variant="xs" marginBottom={40} color="secondaryGrey">
            These are requests you have sent to {partnerRoleLabelPosition}s
          </Text>
        </Grid>
      </Grid>
      {!gettingMentoringPartnerRequests ? (
        sentPartnerRequests?.length > 0 ? (
          <Grid container justify="center">
            <Grid
              container
              item
              xs={12}
              lg={11}
              alignItems="center"
              justify={!desktop ? 'center' : 'space-between'}
            >
              {sentPartnerRequests?.map((request, requestIndex) => (
                <Grid key={requestIndex} item xs={12} lg={6} container justify="center">
                  <Grid item>
                    <MentoringPartnerRequest
                      mentoringRequesterRoleId={
                        request.requestInitiator === ProgramPositions.mentor
                          ? request.mentorRoleId
                          : request.menteeRoleId
                      }
                      requestDetails={request}
                      cancellable
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          sentPartnerRequests?.length === 0 && (
            <Grid item container justify="center">
              <Text variant="sm" color="purple" fontWeight={500}>
                You haven't sent a request to a {partnerRoleLabelPosition} yet. Click the{' '}
                'Find a {capitalize(partnerRoleLabelPosition)}' button above to get started!
              </Text>
            </Grid>
          )
        )
      ) : (
        <Grid container direction="column" justify="center" alignItems="center" item xs={5}>
          <CircularProgress color="secondary" size={64} />
        </Grid>
      )}
    </PageLayout>
  );
};
