import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Text } from '../../Components/General/Text';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { GetRoute } from '../../Components/Routing';
import { IStoreTypes } from '../../store/storeTypes';
import { BrancherDispatch, UtilGetMentoringRequests } from '../../store/actions';
import { EMentoringRequestStatus, IMentoringRequest } from '../../store/reducers/CohortReducer';
import { addPlural } from '../../utils/TextUtils';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';

const useStyles = makeStyles({
  zIndexed: {
    zIndex: 10,
    display: 'inline',
  },
});

interface ICheckMentoringPartnerRequests {
  small?: boolean;
}

export const CheckMentoringPartnerRequests: React.FC<ICheckMentoringPartnerRequests> = ({
  small = true,
}) => {
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const sesPosition = useSelector((state: IStoreTypes) => state.user?.sessionPosition);
  const programId = useSelector((state: IStoreTypes) => state.user?.programId);
  const isMentee = sesPosition === ProgramPositions.mentee;
  const partnerRolePosition = isMentee ? ProgramPositions.mentor : ProgramPositions.mentee;
  const mentees = useSelector((state: IStoreTypes) => state.user?.mentees);
  const mentors = useSelector((state: IStoreTypes) => state.user?.mentors);
  const currentPartners = isMentee ? mentees : mentors;
  const mentoringPartnerRequests: string[] = useSelector(
    (state: IStoreTypes) => state.user?.mentoringPartnerRequests,
  )
    ?.filter((a) => a.initiatorPosition !== sesPosition && a.programId === programId)
    .map((b) => b.mentoringRequestId);
  const [gettingMentoringPartnerRequests, setGettingMentoringPartnerRequests] = React.useState<
    boolean
  >(false);
  const [sentMentoringPartnerRequests, setSentMentoringPartnerRequests] = React.useState<
    IMentoringRequest[]
  >([]);
  const dispatch = useDispatch();
  const styles = useStyles();

  React.useEffect(() => {
    retrieveMentoringRequests();
  }, []);

  const retrieveMentoringRequests = () => {
    setGettingMentoringPartnerRequests(true);
    BrancherDispatch(
      dispatch,
      UtilGetMentoringRequests((requests) => {
        if (requests.success) {
          setSentMentoringPartnerRequests(
            requests?.data?.sentRequests?.filter(
              (request) => request.status !== EMentoringRequestStatus.CANCELLED,
            ),
          );
        } else {
          setSentMentoringPartnerRequests([]);
        }
        setGettingMentoringPartnerRequests(false);
      }),
    );
  };

  return (
    <Grid container item spacing={2} alignItems="center">
      {mentoringPartnerRequests?.length > 0 && (
        <Grid item container justify="center" alignItems="center">
          <Grid item container justify="center" xs={10} alignItems="center" spacing={2}>
            <Text variant="sm" fontWeight={600} marginRight={20} align="center">
              You have {mentoringPartnerRequests?.length} {roleLabels.programStyle}{' '}
              {mentoringPartnerRequests?.length > 1 ? 'requests' : 'request'} to respond to from{' '}
              {mentoringPartnerRequests?.length === 1
                ? `a ${roleLabels[partnerRolePosition]}`
                : roleLabels[partnerRolePosition]}
            </Text>
            <Grid item>
              <Link to={GetRoute('mentoringPartnerRequests').path} className={styles.zIndexed}>
                <BrancherButton size={small ? 'small' : 'medium'} color="secondary">
                  Respond to request{addPlural(mentoringPartnerRequests?.length)}
                </BrancherButton>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!gettingMentoringPartnerRequests && sentMentoringPartnerRequests?.length > 0 && (
        <Grid item container justify="center">
          <Grid item container justify="center" xs={10} alignItems="center" spacing={2}>
            <Text variant="sm" marginRight={20} fontWeight={600} align="center">
              You have {sentMentoringPartnerRequests?.length} open{' '}
              {`request${addPlural(sentMentoringPartnerRequests?.length)}`} for a{' '}
              {roleLabels[partnerRolePosition]}
            </Text>
            <Grid item>
              <Link to={GetRoute('mentoringPartnerRequests').path} className={styles.zIndexed}>
                <BrancherButton size={small ? 'small' : 'medium'} color="secondary">
                  See {sentMentoringPartnerRequests?.length > 1 ? 'requests' : 'request'}
                </BrancherButton>
              </Link>
            </Grid>
          </Grid>
          {currentPartners?.length === 0 && (
            <Grid item xs={10} container justify="center">
              <Grid item>
                <Text variant="xs" align="center" marginTop={12}>
                  Your requested {roleLabels[partnerRolePosition]}
                  {addPlural(sentMentoringPartnerRequests?.length)} will soon respond to your
                  request
                  {addPlural(sentMentoringPartnerRequests?.length)}.
                </Text>
              </Grid>
              <Grid item>
                <Text variant="xs" display="inline" align="center">
                  In the interim, complete your actions below and initial{' '}
                </Text>
                <BrancherLinkText
                  href={GetRoute('training').path}
                  align="center"
                  underline="always"
                  variant="xs"
                  display="inline"
                >
                  training
                </BrancherLinkText>
                .
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};
