import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SuggestionCard } from '../../SuggestionCard';

export const IntroductionMentorStepSixIncorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          Not quite. The best option is both{' '}
        </Text>
        <Text variant="md" fontWeight={700} color="purple" display="inline">
          A
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          {' '}
          and{' '}
        </Text>
        <Text variant="md" fontWeight={700} color="purple" display="inline">
          B
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          It’s best to accept Jordan’s apology,{' '}
        </Text>
        <Text variant="md" fontWeight={700} color="purple" display="inline">
          check they are OK to proceed
        </Text>
        <Text variant="md" display="inline">
          , and then get started with the meeting.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Depending on the situation, you may be able to keep the meeting or you may need to call
          Jordan and propose a new time to meet.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Use phrases like:
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard>
          “Is everything OK? Are you OK to proceed with our meeting today? Would you prefer to
          reschedule? If you're sure you’re OK, let’s get started."
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="md">
          In the early stages of the relationship, it’s best to take a gentler approach because
          being late may be a once-off or out of character due to unforeseen circumstances.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          If Jordan continues to arrive late, you could initiate a discussion with questions like:
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard>"What's your perception of your time management skills"</SuggestionCard>
      </Grid>
      <Grid item>
        <SuggestionCard>
          "How do you think being late may impact other people's view of you?"
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
