import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { IntroductionMenteeDescriptionOne } from './Steps/IntroductionMenteeDescriptionOne';
import { IntroductionMenteeDescriptionTwo } from './Steps/IntroductionMenteeDescriptionTwo';
import { IntroductionMenteeStepOne } from './Steps/IntroductionMenteeStepOne';
import { IntroductionMenteeStepOneCorrect } from './Steps/IntroductionMenteeStepOneCorrect';
import { IntroductionMenteeStepOneIncorrect } from './Steps/IntroductionMenteeStepOneIncorrect';
import { IntroductionMenteeStepTwo } from './Steps/IntroductionMenteeStepTwo';
import { IntroductionMenteeStepTwoCorrect } from './Steps/IntroductionMenteeStepTwoCorrect';
import { IntroductionMenteeStepThree } from './Steps/IntroductionMenteeStepThree';
import { IntroductionMenteeStepThreeCorrect } from './Steps/IntroductionMenteeStepThreeCorrect';
import { IntroductionMenteeStepThreeIncorrect } from './Steps/IntroductionMenteeStepThreeIncorrect';
import { IntroductionMenteeStepFourCorrect } from './Steps/IntroductionMenteeStepFourCorrect';
import { IntroductionMenteeStepFourIncorrect } from './Steps/IntroductionMenteeStepFourIncorrect';
import { IntroductionMenteeStepFour } from './Steps/IntroductionMenteeStepFour';
import { IntroductionMenteeStepFive } from './Steps/IntroductionMenteeStepFive';
import { IntroductionMenteeStepFiveCorrect } from './Steps/IntroductionMenteeStepFiveCorrect';
import { IntroductionMenteeStepFiveIncorrect } from './Steps/IntroductionMenteeStepFiveIncorrect';
import { IntroductionMenteeStepSix } from './Steps/IntroductionMenteeStepSix';
import { IntroductionMenteeStepSixCorrect } from './Steps/IntroductionMenteeStepSixCorrect';
import { IntroductionMenteeStepSixIncorrect } from './Steps/IntroductionMenteeStepSixIncorrect';
import { IntroductionMenteeStepSeven } from './Steps/IntroductionMenteeStepSeven';
import { IntroductionMenteeStepSevenIncorrect } from './Steps/IntroductionMenteeStepSevenIncorrect';
import { IntroductionMenteeStepEight } from './Steps/IntroductionMenteeStepEight';

export const IntroductionMenteeModuleMapper: IModuleMapper[] = [
  {
    name: 'IntroductionMenteeDescriptionOne',
    component: <IntroductionMenteeDescriptionOne />,
    hasQuestion: false,
    iterateStepper: false,
    nextComponent: 'IntroductionMenteeDescriptionTwo',
  },
  {
    name: 'IntroductionMenteeDescriptionTwo',
    component: <IntroductionMenteeDescriptionTwo />,
    hasQuestion: false,
    iterateStepper: false,
    previousComponent: 'IntroductionMenteeDescriptionOne',
    nextComponent: 'IntroductionMenteeStepOne',
  },
  {
    name: 'IntroductionMenteeStepOne',
    component: <IntroductionMenteeStepOne />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'IntroductionMenteeDescriptionTwo',
    correctComponent: 'IntroductionMenteeStepOneCorrect',
    incorrectComponent: 'IntroductionMenteeStepOneIncorrect',
  },
  {
    name: 'IntroductionMenteeStepOneCorrect',
    component: <IntroductionMenteeStepOneCorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMenteeStepOne',
    nextComponent: 'IntroductionMenteeStepTwo',
  },
  {
    name: 'IntroductionMenteeStepOneIncorrect',
    component: <IntroductionMenteeStepOneIncorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMenteeStepOne',
    nextComponent: 'IntroductionMenteeStepTwo',
  },
  {
    name: 'IntroductionMenteeStepTwo',
    component: <IntroductionMenteeStepTwo />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'IntroductionMenteeStepOne',
    correctComponent: 'IntroductionMenteeStepTwoCorrect',
    incorrectComponent: 'IntroductionMenteeStepTwoCorrect',
  },
  {
    name: 'IntroductionMenteeStepTwoCorrect',
    component: <IntroductionMenteeStepTwoCorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMenteeStepTwo',
    nextComponent: 'IntroductionMenteeStepThree',
  },
  {
    name: 'IntroductionMenteeStepThree',
    component: <IntroductionMenteeStepThree />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'IntroductionMenteeStepTwo',
    correctComponent: 'IntroductionMenteeStepThreeCorrect',
    incorrectComponent: 'IntroductionMenteeStepThreeIncorrect',
  },
  {
    name: 'IntroductionMenteeStepThreeCorrect',
    component: <IntroductionMenteeStepThreeCorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMenteeStepThree',
    nextComponent: 'IntroductionMenteeStepFour',
  },
  {
    name: 'IntroductionMenteeStepThreeIncorrect',
    component: <IntroductionMenteeStepThreeIncorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMenteeStepThree',
    nextComponent: 'IntroductionMenteeStepFour',
  },
  {
    name: 'IntroductionMenteeStepFour',
    component: <IntroductionMenteeStepFour />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'IntroductionMenteeStepThree',
    correctComponent: 'IntroductionMenteeStepFourCorrect',
    incorrectComponent: 'IntroductionMenteeStepFourIncorrect',
  },
  {
    name: 'IntroductionMenteeStepFourCorrect',
    component: <IntroductionMenteeStepFourCorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMenteeStepFour',
    nextComponent: 'IntroductionMenteeStepFive',
  },
  {
    name: 'IntroductionMenteeStepFourIncorrect',
    component: <IntroductionMenteeStepFourIncorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMenteeStepFour',
    nextComponent: 'IntroductionMenteeStepFive',
  },
  {
    name: 'IntroductionMenteeStepFive',
    component: <IntroductionMenteeStepFive />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'IntroductionMenteeStepFour',
    correctComponent: 'IntroductionMenteeStepFiveCorrect',
    incorrectComponent: 'IntroductionMenteeStepFiveIncorrect',
  },
  {
    name: 'IntroductionMenteeStepFiveCorrect',
    component: <IntroductionMenteeStepFiveCorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMenteeStepFive',
    nextComponent: 'IntroductionMenteeStepSix',
  },
  {
    name: 'IntroductionMenteeStepFiveIncorrect',
    component: <IntroductionMenteeStepFiveIncorrect />,
    hasQuestion: false,
    iterateStepper: false,
    previousComponent: 'IntroductionMenteeStepFive',
    nextComponent: 'IntroductionMenteeStepFiveCorrect',
  },
  {
    name: 'IntroductionMenteeStepSix',
    component: <IntroductionMenteeStepSix />,
    hasQuestion: true,
    iterateStepper: false,
    previousComponent: 'IntroductionMenteeStepFive',
    correctComponent: 'IntroductionMenteeStepSixCorrect',
    incorrectComponent: 'IntroductionMenteeStepSixIncorrect',
  },
  {
    name: 'IntroductionMenteeStepSixCorrect',
    component: <IntroductionMenteeStepSixCorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMenteeStepSix',
    nextComponent: 'IntroductionMenteeStepSeven',
  },
  {
    name: 'IntroductionMenteeStepSixIncorrect',
    component: <IntroductionMenteeStepSixIncorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMenteeStepSix',
    nextComponent: 'IntroductionMenteeStepSeven',
  },
  {
    name: 'IntroductionMenteeStepSeven',
    component: <IntroductionMenteeStepSeven />,
    hasQuestion: true,
    iterateStepper: true,
    previousComponent: 'IntroductionMenteeStepSix',
    correctComponent: 'IntroductionMenteeStepEight',
    incorrectComponent: 'IntroductionMenteeStepSevenIncorrect',
  },
  {
    name: 'IntroductionMenteeStepSevenIncorrect',
    component: <IntroductionMenteeStepSevenIncorrect />,
    hasQuestion: false,
    iterateStepper: true,
    previousComponent: 'IntroductionMenteeStepSeven',
    nextComponent: 'IntroductionMenteeStepEight',
  },
  {
    name: 'IntroductionMenteeStepEight',
    component: <IntroductionMenteeStepEight />,
    needsInteraction: true,
    iterateStepper: false,
    submit: true,
    previousComponent: 'IntroductionMenteeStepSeven',
  },
];
