import * as React from 'react';
import People from '@material-ui/icons/People';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import capitalize from '@material-ui/core/utils/capitalize';
import { useSelector } from 'react-redux';
import { Text } from '../../Components/General/Text';
import { Colors } from '../../consts/colors';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { EProfileAttributes, IProfileData } from '../../store/reducers/ProfileFormReducer';
import { GetSharedResourceLink } from '../../utils/LinkUtils';
import { ProfilePicture } from '../Profile/ProfilePicture';
import { IStoreTypes } from '../../store/storeTypes';

const useStyles = makeStyles({
  card: {
    borderRadius: 20,
    padding: (props: {
      brancherRecommended: boolean;
      mobile: boolean;
      mobileXS: boolean;
      isPeerMentor: boolean;
      customPadding: string;
      atCapacity: boolean;
    }) => (!!props?.customPadding ? props?.customPadding : 20),
    marginBottom: 20,
    boxShadow: '0px 5px 8px -3px rgba(0,0,0,0.14)',
    border: (props: {
      brancherRecommended: boolean;
      mobile: boolean;
      mobileXS: boolean;
      isPeerMentor: boolean;
      customPadding: string;
      atCapacity: boolean;
    }) =>
      props.atCapacity
        ? `2px solid ${Colors.disabledGrey}`
        : props.brancherRecommended
        ? `2px solid ${Colors.purple}`
        : props.isPeerMentor
        ? `1px solid ${Colors.purple}`
        : 'none',
    minWidth: (props: { mobile: boolean; mobileXS: boolean }) => (props.mobile ? 280 : 380),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  img: {
    maxHeight: (props: { mobile: boolean; mobileXS: boolean }) => (props.mobile ? 80 : 135),
    maxWidth: (props: { mobile: boolean; mobileXS: boolean }) =>
      props.mobileXS ? 95 : props.mobile ? 130 : 160,
    borderRadius: 10,
  },
  logo: {
    maxHeight: 32,
    maxWidth: 32,
    borderRadius: '50%',
  },
});

// tslint:disable-next-line:max-line-length
interface IProfileCard
  extends Pick<
    IProfileData,
    | EProfileAttributes.IMAGE
    | EProfileAttributes.LAST_NAME
    | EProfileAttributes.JOB_TITLE
    | EProfileAttributes.FIRST_NAME
  > {
  profileType: ProgramPositions;
  onSelectUser: (o?: IProfileData) => void;
  brancherRecommended?: boolean;
  isPeerMentor?: boolean;
  atCapacity?: boolean;
  customPadding?: string;
}

export const ProfileCard: React.FC<IProfileCard> = (props) => {
  const {
    lastName,
    firstName,
    onSelectUser,
    image,
    jobTitle,
    profileType,
    brancherRecommended,
    isPeerMentor,
    children,
    customPadding = '',
    atCapacity = false,
  } = props;
  const roleLabels = useSelector((state: IStoreTypes) => state.user.roleLabels);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const mobileXS = useMediaQuery(theme.breakpoints.down('xs'));
  const styles = useStyles({
    mobile,
    mobileXS,
    brancherRecommended,
    isPeerMentor,
    customPadding,
    atCapacity,
  });

  const customRoleLabel = roleLabels[profileType];

  return (
    <Grid item xs={9} sm={7} md={5} className={styles.card}>
      {(brancherRecommended || isPeerMentor) && (
        <Box position="absolute" display="flex" mt={-2} ml={-2}>
          <Tooltip
            title={`${firstName} is a ${
              isPeerMentor
                ? `Peer ${capitalize(roleLabels[ProgramPositions.mentor])}`
                : `Your Brancher recommended ${customRoleLabel}`
            }`}
            arrow
            aria-label={`${firstName} is a ${
              isPeerMentor
                ? `Peer ${capitalize(roleLabels[ProgramPositions.mentor])}`
                : `Your Brancher recommended ${customRoleLabel}`
            }`}
          >
            {isPeerMentor ? (
              <People fontSize="large" />
            ) : (
              <img
                src={GetSharedResourceLink('logo.png')}
                alt="brancher-logo"
                className={styles.logo}
              />
            )}
          </Tooltip>
        </Box>
      )}
      <Grid container justify="space-between" onClick={() => onSelectUser()}>
        <Grid item xs={4}>
          <ProfilePicture src={image} />
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column" spacing={1}>
            <Text variant="sm" fontWeight={600} color="purple">
              {firstName} {lastName}
            </Text>
            <Text variant="xs" color="secondaryGrey" marginTop={8} marginBottom={8} marginRight={8}>
              {jobTitle}
            </Text>
            <Text variant="xs" fontWeight={600} color="tertiaryGrey">
              {profileType && capitalize(customRoleLabel)}
            </Text>
          </Grid>
        </Grid>
      </Grid>
      {children}
    </Grid>
  );
};
