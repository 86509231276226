import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SuggestionCard } from '../../SuggestionCard';
import { BrancherList } from '../../../../../Components/General/BrancherList';

export const BeyondProgramMenteeStepFiveStopCorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success>
          <Text variant="md" fontWeight={700}>
            Correct!
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="sm">
          The correct answer was C because this response expressed gratitude to your mentor,
          explained how your needs/capacity have changed and suggested a clear timeline to wrap
          things up.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="sm" fontWeight={700}>
          The additional principles that this response could have covered to make it complete
          include:
        </Text>
        <BrancherList
          listItems={[
            'Reflect on everything you have accomplished',
            'Suggest a focus for the remainder of your final meeting',
            'Discuss next steps for your career',
          ]}
        />
      </Grid>
    </Grid>
  );
};
