interface IImageModuleSection {
  componentType: 'image';
  src: string;
}

interface IVideoModuleSection {
  componentType: 'video';
  src: string;
}

interface ITextModuleSection {
  content: string;
  displayInline?: boolean;
}

interface IContentSection extends ITextModuleSection {
  componentType: 'text' | 'heading';
}

interface ILinkModuleSection extends ITextModuleSection {
  componentType: 'link';
  href: string;
}

export type ICustomMod =
  | IVideoModuleSection
  | IImageModuleSection
  | IContentSection
  | ILinkModuleSection;

export interface ICustomModules {
  [key: string]: ICustomMod[];
}

export interface ITrainingModule {
  buttonText: string;
  heading: string;
  description: string;
  route: string;
  name: string;
  trainingModule: boolean;
  customModule?: boolean;
  deadline?: string;
  alwaysOpen?: boolean;
  completeOnEndDate?: boolean;
  closeOnEndDate?: boolean;
}

export interface ITrainingReducer {
  mentoringAgreement?: any;
  introductionMentee?: any;
  introductionMentor?: any;
  moduleConfig?: { mentee: ITrainingModule[]; mentor: ITrainingModule[] };
  modules?: { mentee: ETrainingModules[]; mentor: ETrainingModules[]; peer: ETrainingModules[] };
  customModules?: ICustomModules;
  beGreatMentee?: any;
  beGreatMentor?: any;
  makeTheMostMentee?: any;
  makeTheMostMentor?: any;
  surveyOne?: any;
  surveyTwo?: any;
  surveyThree?: any;
  givingReceivingFeedbackMentor?: any;
  givingReceivingFeedbackMentee?: any;
  beyondProgramMentee?: any;
  beyondProgramMentor?: any;
  modBeGreatMentee?: any;
  modBeGreatMentor?: any;
  modGoalSettingMentee?: any;
  modGoalSettingMentor?: any;
  modSituationalMentorshipMentor?: any;
  modGrowthCoachingFrameworkMentor?: any;
  modPowerOfReflectionMentee?: any;
  modPowerOfReflectionMentor?: any;
  easyEnglishMentee?: any;
  easyEnglishMentor?: any;
  easyEnglishCC?: any;
  progress?: any;
  activeTrainingTab?: number;
  openTrainingNav?: boolean;
}

export enum EStructuredTrainingModules {
  WELCOME = 'welcome',
  INTRODUCTION_MENTEE = 'introductionMentee',
  INTRODUCTION_MENTOR = 'introductionMentor',
  MAKE_THE_MOST_MENTEE = 'makeTheMostMentee',
  MAKE_THE_MOST_MENTOR = 'makeTheMostMentor',
  BE_GREAT_MENTEE = 'beGreatMentee',
  BE_GREAT_MENTOR = 'beGreatMentor',
  GIVING_RECEIVING_FEEDBACK_MENTEE = 'givingReceivingFeedbackMentee',
  GIVING_RECEIVING_FEEDBACK_MENTOR = 'givingReceivingFeedbackMentor',
  BEYOND_PROGRAM_MENTEE = 'beyondProgramMentee',
  BEYOND_PROGRAM_MENTOR = 'beyondProgramMentor',
  SURVEY_ONE = 'surveyOne',
  SURVEY_TWO = 'surveyTwo',
}

export enum EMoDTrainingModules {
  WELCOME = 'welcome',
  MOD_BE_GREAT_MENTEE = 'modBeGreatMentee',
  MOD_BE_GREAT_MENTOR = 'modBeGreatMentor',
  MOD_GOAL_SETTING_MENTEE = 'modGoalSettingMentee',
  MOD_GOAL_SETTING_MENTOR = 'modGoalSettingMentor',
  MOD_POWER_OF_REFLECTION_MENTEE = 'modPowerOfReflectionMentee',
  MOD_POWER_OF_REFLECTION_MENTOR = 'modPowerOfReflectionMentor',
  MOD_SITUATIONAL_MENTORSHIP_MENTOR = 'modSituationalMentorshipMentor',
  MOD_GROWTH_COACHING_FRAMEWORK_MENTOR = 'modGrowthCoachingFrameworkMentor',
  GIVING_RECEIVING_FEEDBACK_MENTEE = 'givingReceivingFeedbackMentee',
  GIVING_RECEIVING_FEEDBACK_MENTOR = 'givingReceivingFeedbackMentor',
}

export enum EEasyEnglishTrainingModules {
  EASY_ENGLISH_MENTEE = 'easyEnglishMentee',
  EASY_ENGLISH_MENTOR = 'easyEnglishMentor',
  EASY_ENGLISH_CC = 'easyEnglishCC',
}

export type ETrainingModules =
  | EStructuredTrainingModules
  | EMoDTrainingModules
  | EEasyEnglishTrainingModules;

export const ReadableTrainingModules = {
  // Easy English
  [EEasyEnglishTrainingModules.EASY_ENGLISH_CC]: 'Easy English: Code of Conduct',
  [EEasyEnglishTrainingModules.EASY_ENGLISH_MENTEE]: 'Easy English: The Mentee Guide',
  [EEasyEnglishTrainingModules.EASY_ENGLISH_MENTOR]: 'Easy English: The Mentor Guide',
  // Shared for both MoD + structured
  [EMoDTrainingModules.MOD_BE_GREAT_MENTEE]: 'Be a great mentee',
  [EMoDTrainingModules.MOD_BE_GREAT_MENTOR]: 'Be a great mentor',
  [EMoDTrainingModules.MOD_GOAL_SETTING_MENTEE]: 'Goal setting',
  [EMoDTrainingModules.MOD_GOAL_SETTING_MENTOR]: 'Goal setting',
  [EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTEE]: 'The power of reflection',
  [EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTOR]: 'The power of reflection',
  [EMoDTrainingModules.MOD_SITUATIONAL_MENTORSHIP_MENTOR]: 'Situational mentorship',
  [EMoDTrainingModules.MOD_GROWTH_COACHING_FRAMEWORK_MENTOR]: 'Growth coaching framework',
  [EStructuredTrainingModules.GIVING_RECEIVING_FEEDBACK_MENTEE]: 'Effective feedback',
  [EStructuredTrainingModules.GIVING_RECEIVING_FEEDBACK_MENTOR]: 'Effective feedback',
  [EStructuredTrainingModules.BEYOND_PROGRAM_MENTEE]: 'Wrap up your relationship',
  [EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR]: 'Wrap up your relationship',
  // Structured
  [EStructuredTrainingModules.WELCOME]: 'Welcome',
  [EStructuredTrainingModules.INTRODUCTION_MENTEE]: 'Introduction to mentoring',
  [EStructuredTrainingModules.INTRODUCTION_MENTOR]: 'Introduction to mentoring',
  [EStructuredTrainingModules.MAKE_THE_MOST_MENTEE]: 'Make the most of mentoring',
  [EStructuredTrainingModules.MAKE_THE_MOST_MENTOR]: 'Make the most of mentoring',
  [EStructuredTrainingModules.BE_GREAT_MENTEE]: 'Be a great mentee',
  [EStructuredTrainingModules.BE_GREAT_MENTOR]: 'Be a great mentor',
  [EStructuredTrainingModules.SURVEY_ONE]: 'Baseline survey',
  [EStructuredTrainingModules.SURVEY_TWO]: 'Finishing-up survey',
};
