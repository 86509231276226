import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import capitalize from '@material-ui/core/utils/capitalize';
import { useDispatch, useSelector } from 'react-redux';
import { TrainingIntroduction } from '../../TrainingIntroduction';
import {
  getTrainingModuleHeading,
  getTrainingModuleNumber,
} from '../../TrainingModuleDefaultConfig';
import { Text } from '../../../../Components/General/Text';
import { SegmentedControlGroup } from '../../../../Form/FormFieldTypes/SegmentedControl';
import { SaveBeGreatMentorData } from '../../../../store/actions';
import { IStoreTypes } from '../../../../store/storeTypes';
import { BrancherQuestionMarker } from '../../../../Components/General/BrancherQuestionMarker';
import { CompetencePopover } from '../sharedComps/CompetencePopover';
import { ProgramPositions } from '../../../../QualifyingForm/ProgramPositionOptions';
import { CompetenceScale } from '../sharedComps/CompetenceScale';
import { ConfidenceScale } from '../sharedComps/ConfidenceScale';

export const BeGreatMentorSplash = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const modulePathPos = sesPos === ProgramPositions.peer ? ProgramPositions.mentor : sesPos;
  const trainingModules = useSelector((state: IStoreTypes) => state.training.modules)[sesPos];
  const modName = `beGreat${capitalize(modulePathPos)}`;
  const beGreatMentorBaseline = useSelector((state: IStoreTypes) => state.training.beGreatMentor);
  const trainingConfig = useSelector((state: IStoreTypes) => state.training.moduleConfig)[sesPos];
  const dispatch = useDispatch();
  const [anchorEl, setAnchor] = React.useState(null);
  const open = Boolean(anchorEl);

  const controlValidator = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!Boolean(anchorEl)) {
      setAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(anchorEl)) {
      setAnchor(null);
    }
  };

  const updateBaseline = (val: string, attr: string) => {
    dispatch(SaveBeGreatMentorData({ [attr]: val }));
  };

  return (
    <TrainingIntroduction
      title={`Training #${getTrainingModuleNumber(
        modName,
        trainingModules,
        trainingConfig,
      )}: ${getTrainingModuleHeading(modName, trainingConfig)}`}
      subHeader="Complete this training to learn more about how to be a great mentor through coaching."
      activityLearnings={[
        'Know when to be directive or coach, depending on the situation',
        'Apply the GROWTH coaching model to help your mentee problem solve and set achievable goals',
        'Identify and circumvent common awkward scenarios experienced in coaching',
        'Know how to effectively adopt a directive style when required',
      ]}
      modulePath={modName}
      validatedValue={
        beGreatMentorBaseline?.competenceBaseline != null &&
        beGreatMentorBaseline?.confidenceBaseline != null
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box marginTop={3} marginBottom={3}>
            <Text variant="sm" fontWeight={700} display="inline">
              To provide us with a baseline measure, please rate your current level of{' '}
            </Text>
            <Text variant="sm" fontWeight={700} display="inline" color="purple">
              competence and confidence
            </Text>
            <Text variant="sm" fontWeight={700} display="inline">
              {' '}
              to perform the behaviours above.{' '}
            </Text>
            <BrancherQuestionMarker onClick={controlValidator} />
          </Box>
          <CompetencePopover
            open={open}
            setClose={closeValidator}
            anchor={anchorEl}
            labelledBy="Confidence and competence"
          />
          <Grid item>
            <Text variant="sm" fontWeight={700} marginBottom={10}>
              Please rate your competence:
            </Text>
          </Grid>
          <CompetenceScale />
          <SegmentedControlGroup
            value={beGreatMentorBaseline?.competenceBaseline}
            valueLength={7}
            updateValue={(v: string) => updateBaseline(v, 'competenceBaseline')}
            fullWidth
          />
          <Grid item>
            <Text variant="sm" fontWeight={700} marginBottom={10}>
              Please rate your confidence:
            </Text>
          </Grid>
          <ConfidenceScale />
          <SegmentedControlGroup
            value={beGreatMentorBaseline?.confidenceBaseline}
            valueLength={7}
            updateValue={(v: string) => updateBaseline(v, 'confidenceBaseline')}
            fullWidth
          />
        </Grid>
      </Grid>
    </TrainingIntroduction>
  );
};
