import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMakeTheMostMentee, SaveMakeTheMostMentor } from '../../../../../store/actions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { MakeTheMostRatingArrow } from './MakeTheMostRatingArrow';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const MakeTheMostMenteeStepEleven = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const sessionStore =
    position === ProgramPositions.mentee ? 'makeTheMostMentee' : 'makeTheMostMentor';
  const stepElevenValue = useSelector(
    (state: IStoreTypes) => state.training[sessionStore]?.StepEleven,
  );
  const dispatch = useDispatch();

  const updateValue = (attr: string, val: string) => {
    const sessionSaveFunction =
      position === ProgramPositions.mentee ? SaveMakeTheMostMentee : SaveMakeTheMostMentor;
    const newStepValue = { ...stepElevenValue, [attr]: val };
    dispatch(
      sessionSaveFunction({
        StepEleven: {
          ...newStepValue,
          interacted:
            newStepValue.smartGoalLevel != null &&
            newStepValue.preferredLevel != null &&
            newStepValue.whyLowScore &&
            newStepValue.whyHighScore,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          To help you understand what success looks like, rate your current experience, knowledge,
          skill and confidence in this area.
        </Text>
      </Grid>
      <Grid item>
        <MakeTheMostRatingArrow />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Thinking about your SMART goal, where are you now in regards to your level of experience,
          knowledge, skill and confidence?
        </Text>
      </Grid>
      <Grid item>
        <SegmentedControlGroup
          fullWidth
          value={stepElevenValue?.smartGoalLevel}
          valueLength={10}
          updateValue={(a: string) => updateValue('smartGoalLevel', a)}
        />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Where would you prefer to be?
        </Text>
      </Grid>
      <Grid item>
        <SegmentedControlGroup
          fullWidth
          value={stepElevenValue?.preferredLevel}
          valueLength={10}
          updateValue={(a: string) => updateValue('preferredLevel', a)}
        />
      </Grid>
      {stepElevenValue?.smartGoalLevel != null && stepElevenValue?.preferredLevel != null && (
        <>
          <Grid item>
            <Text variant="md" fontWeight={700} display="inline">
              Why did you score yourself a{' '}
            </Text>
            <Text variant="md" fontWeight={700} display="inline" color="purple">
              {stepElevenValue?.smartGoalLevel + 1}
            </Text>
            <Text variant="md" fontWeight={700} display="inline">
              , rather than a 1?
            </Text>
            <Text variant="sm" marginTop={10}>
              This helps to give you an understanding of your motivating factors.
            </Text>
          </Grid>
          <Grid item>
            <BrancherTextField
              fullWidth
              name="whyLowScore"
              placeholder="Enter here"
              value={stepElevenValue?.whyLowScore}
              updateValue={(a: string) => updateValue('whyLowScore', a)}
              maxChars={200}
            />
          </Grid>
          <Grid item>
            <Text variant="md" fontWeight={700} display="inline">
              Why did you score yourself a{' '}
            </Text>
            <Text variant="md" fontWeight={700} display="inline" color="purple">
              {stepElevenValue?.smartGoalLevel + 1}
            </Text>
            <Text variant="md" fontWeight={700} display="inline">
              , rather than a 10?
            </Text>
            <Text variant="sm" marginTop={10}>
              This helps to give you an understanding of the perceived barriers.
            </Text>
          </Grid>
          <Grid item>
            <BrancherTextField
              fullWidth
              name="whyHighScore"
              placeholder="Enter here"
              value={stepElevenValue?.whyHighScore}
              updateValue={(a: string) => updateValue('whyHighScore', a)}
              maxChars={200}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
