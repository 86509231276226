import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { LikertScaleText } from '../../../../../Components/General/LikertScaleText';
import { BrancherSlider } from '../../../../../Components/InputFields/BrancherSlider';
import { Text } from '../../../../../Components/General/Text';
import { SaveSurveyOneData } from '../../../../../store/actions';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const SurveyStepThreeHaveMet = () => {
  const surveyOneValue = useSelector(
    (state: IStoreTypes) => state.training?.[EStructuredTrainingModules.SURVEY_ONE],
  );
  const dispatch = useDispatch();

  const updateValue = (adequateTrainingValue: number) => {
    dispatch(
      SaveSurveyOneData({
        StepThree: {
          interacted: adequateTrainingValue !== null,
          adequateTraining: adequateTrainingValue,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={600}>
          I have the adequate training, support and resources to get the most out of the program.
        </Text>
      </Grid>
      <Grid item container>
        <LikertScaleText />
      </Grid>
      <Grid item>
        <BrancherSlider
          id="adequateTraining"
          name="adequateTraining"
          value={surveyOneValue?.StepThree?.adequateTraining}
          updateValue={updateValue}
          max={5}
          min={1}
        />
      </Grid>
    </Grid>
  );
};
