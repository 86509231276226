import * as React from 'react';
import Box from '@material-ui/core/Box';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Colors } from '../../consts/colors';

interface ICheckboxProps extends CheckboxProps {
  updateValue: (a: boolean) => void;
  label: React.ReactNode;
  value: boolean;
}

export const BrancherCheckbox = (props: ICheckboxProps) => {
  const { label, updateValue, ...other } = props;
  return (
    <Box bgcolor={Colors.backgroundDarkPurple} pl={2} pr={1} pt={1} pb={1}>
      <FormControlLabel
        control={
          <Checkbox
            {...other}
            checked={props.value}
            onChange={(e) => updateValue(e.target.checked)}
          />
        }
        label={props.label}
      />
    </Box>
  );
};
