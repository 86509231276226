import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { Text } from '../../../../../Components/General/Text';

export const MoDBeGreatMenteeStepSeven = () => {
  const knowEachOtherList: string[] = [
    'What are your backgrounds/ career stories so far, hobbies outside of work, career goals, personal strengths, areas for development?',
    'What are you both hoping to get out of the mentoring relationship?',
    'For those who have been a mentor/had a mentor previously, what have the positives and negatives of those relationships been? What did you learn about yourself in the context of that relationship?',
    'What are your similarities and differences in regards to working styles (i.e. are you organised and detail orientated or strategic and a high level thinker?) and how can you be aware of these to foster trust and understanding?',
  ];

  const clarifyExpectationsList: string[] = [
    'Clarify expectations of each other and anything not covered in the Mentoring Agreement',
    'Extending on the Mentoring Agreement, explicitly discuss and agree upon the boundaries to confidentiality and ensure both parties are comfortable.',
    'Clarify areas of expertise. For example, discuss that if the mentee is asking for something beyond the expertise of the mentor, that the mentee will be referred to someone else.',
    'Set the agenda for the next meeting.',
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center">
        <Text variant="lg" fontWeight={700}>
          Here is a recommended agenda for your first meeting:
        </Text>
      </Grid>
      <Grid item>
        <Text variant="sm">
          Consider the following agenda (and how you might adapt this) for your first meeting.
          Remember this is a guide only.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={600} marginTop={20}>
          1. Get to know each other
        </Text>
        <BrancherList listItems={knowEachOtherList} fontWeight={400} />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={600} marginTop={30} marginBottom={15}>
          2. Complete your ‘Mentoring Agreement’
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={600} marginTop={30}>
          3. Clarify expectations
        </Text>
        <BrancherList listItems={clarifyExpectationsList} fontWeight={400} />
      </Grid>
    </Grid>
  );
};
