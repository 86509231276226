import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';
import { SaveGeneralFormData } from '../../store/actions';
import { ITextControl } from '../FormFieldTypes/TextControl';
import { ISelectionControl } from '../FormFieldTypes/SelectionControl';
import { MakeOptions } from '../MakeOptions';
import { EFormStepperSections } from '../FormStepper';
import { IDraggableColumn, IDraggableSection } from '../../Components/General/DraggableSection';
import { EComponentType } from '../../store/reducers/UserInfoReducer';

export type ValueTypes = string[] | string | number | number[] | boolean | IDraggableColumn[];

export interface IGeneralFormConfig
  extends Omit<ITextControl, 'question'>,
    ISelectionControl,
    IDraggableSection {
  value: any;
  updateValue: (a: ValueTypes) => void;
  componentType: EComponentType;
  section?: EFormStepperSections;
  notMandatory?: boolean;
  isMentor?: boolean; // Puts this in the mentor question list
  isMentee?: boolean; // Puts this in the mentee question list
  isMentorNotFilterable?: boolean; // For find mentoring partner filtering/algorithm
  isMenteeNotFilterable?: boolean; // For find mentoring partner filtering/algorithm
  isAdminFilterable?: boolean; // For admin in dashboard to filter
}

export const GeneralFormIndex = (isMentee: boolean): string[] => {
  const generalValues = useSelector((state: IStoreTypes) => state.general?.configValues);
  // For mentoring
  if (generalValues?.mentee !== undefined) {
    const menteeFields = generalValues.mentee.map((a) => a.key);
    const mentorFields = generalValues.mentor.map((a) => a.key);
    return !isMentee ? mentorFields : menteeFields;
  } else {
    // For catchups
    return generalValues?.map((a) => a.key);
  }
};

export function GeneralFormQuestions(isMentee: boolean): IGeneralFormConfig[] {
  const generalValues = useSelector((state: IStoreTypes) => state.general);
  const config = useSelector((state: IStoreTypes) => state.user.formConfig.general);
  const dispatch = useDispatch();
  return config
    .map((f) => {
      if ((f.isMentor && !isMentee) || (f.isMentee && isMentee)) {
        let field = {
          ...f,
          value: generalValues[f.name],
          updateValue: (a: any) => dispatch(SaveGeneralFormData({ [f.name]: a })),
          question:
            isMentee && f.question.mentee
              ? f.question.mentee
              : !isMentee && f.question.mentor
              ? f.question.mentor
              : f.question,
        };
        if (field?.minimumAmount) {
          const hasDifferentAmountLogicPerPosition: boolean =
            typeof field?.minimumAmount !== 'number';
          if (hasDifferentAmountLogicPerPosition) {
            if (isMentee) {
              field.minimumAmount = field.minimumAmount.mentee;
            } else {
              field.minimumAmount = field.minimumAmount.mentor;
            }
          }
        }
        if (field?.maximumAmount) {
          const hasDifferentAmountLogicPerPosition: boolean =
            typeof field?.maximumAmount !== 'number';
          if (hasDifferentAmountLogicPerPosition) {
            if (isMentee) {
              field.maximumAmount = field.maximumAmount.mentee;
            } else {
              field.maximumAmount = field.maximumAmount.mentor;
            }
          }
        }
        // For conditional text field
        if (
          f.conditionalTextField &&
          (!!f?.conditionalTextFieldValue
            ? generalValues?.[f.name] === f.conditionalTextFieldValue
            : generalValues?.[f.name] === '1')
        ) {
          field.textProps = {
            ...field.textProps,
            value: generalValues[field.textProps.name],
            updateValue: (a: any) => dispatch(SaveGeneralFormData({ [field.textProps.name]: a })),
          };
        } else {
          field.conditionalTextField = false;
        }
        if (field?.resetsOtherField) {
          field.updateValue = (a: any) => {
            dispatch(SaveGeneralFormData({ [f.resetField]: '' }));
            dispatch(SaveGeneralFormData({ [f.name]: a }));
          };
        }
        if (field?.options) {
          const opts = f?.conditionalOptions
            ? f.options?.[generalValues?.[f.conditionalOptionsField]] ?? []
            : f.options.mentee && isMentee
            ? f.options.mentee
            : f.options.mentor && !isMentee
            ? f.options.mentor
            : f.options;
          const secondaryOpts =
            f?.conditionalOptions && f?.conditionalOptionsSecondaryField
              ? opts[generalValues?.[f.conditionalOptionsSecondaryField]] ?? []
              : opts;
          field.options = MakeOptions(secondaryOpts);
        }
        return field;
      }
    })
    .filter((a) => a !== undefined);
}
