import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { EasyEnglishCCStepOne } from './Steps/EasyEnglishCCStepOne';
import { EasyEnglishCCStepOneCorrect } from './Steps/EasyEnglishCCStepOneCorrect';
import { EasyEnglishCCStepTwo } from './Steps/EasyEnglishCCStepTwo';
import { EasyEnglishCCStepTwoCorrect } from './Steps/EasyEnglishCCStepTwoCorrect';
import { EasyEnglishCCStepThree } from './Steps/EasyEnglishCCStepThree';
import { EasyEnglishCCStepThreeCorrect } from './Steps/EasyEnglishCCStepThreeCorrect';
import { EasyEnglishCCStepFour } from './Steps/EasyEnglishCCStepFour';

export const EasyEnglishCCModuleMapper: IModuleMapper[] = [
  {
    name: 'EasyEnglishCCStepOne',
    component: <EasyEnglishCCStepOne />,
    iterateStepper: false,
    hasQuestion: true,
    correctComponent: 'EasyEnglishCCStepOneCorrect',
    incorrectComponent: 'EasyEnglishCCStepOneCorrect',
  },
  {
    name: 'EasyEnglishCCStepOneCorrect',
    component: <EasyEnglishCCStepOneCorrect />,
    iterateStepper: true,
    previousComponent: 'EasyEnglishCCStepOne',
    nextComponent: 'EasyEnglishCCStepTwo',
  },
  {
    name: 'EasyEnglishCCStepTwo',
    component: <EasyEnglishCCStepTwo />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'EasyEnglishCCStepOne',
    correctComponent: 'EasyEnglishCCStepTwoCorrect',
    incorrectComponent: 'EasyEnglishCCStepTwoCorrect',
  },
  {
    name: 'EasyEnglishCCStepTwoCorrect',
    component: <EasyEnglishCCStepTwoCorrect />,
    iterateStepper: true,
    previousComponent: 'EasyEnglishCCStepTwo',
    nextComponent: 'EasyEnglishCCStepThree',
  },
  {
    name: 'EasyEnglishCCStepThree',
    component: <EasyEnglishCCStepThree />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'EasyEnglishCCStepTwo',
    correctComponent: 'EasyEnglishCCStepThreeCorrect',
    incorrectComponent: 'EasyEnglishCCStepThreeCorrect',
  },
  {
    name: 'EasyEnglishCCStepThreeCorrect',
    component: <EasyEnglishCCStepThreeCorrect />,
    iterateStepper: true,
    previousComponent: 'EasyEnglishCCStepThree',
    nextComponent: 'EasyEnglishCCStepFour',
  },
  {
    name: 'EasyEnglishCCStepFour',
    component: <EasyEnglishCCStepFour />,
    iterateStepper: true,
    needsInteraction: true,
    submit: true,
    previousComponent: 'EasyEnglishCCStepThree',
  },
];
