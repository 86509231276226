import * as React from 'react';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { AccessTime } from '@material-ui/icons';
import { format } from 'date-fns';

interface ITimePicker {
  value: Date;
  updateValue: (value: Date) => void;
  id: string;
  name: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  fullWidth?: boolean;
}

export const TimePicker: React.FC<ITimePicker> = ({
  value,
  updateValue,
  label,
  fullWidth,
  placeholder,
  disabled,
  id,
  name,
}) => {
  const handleTime = (time: Date) => {
    updateValue(time);
  };

  return (
    <KeyboardTimePicker
      disabled={disabled}
      id={id}
      fullWidth={fullWidth}
      aria-describedby={name}
      label={label}
      placeholder={placeholder ?? format(new Date(), 'h:mm a')}
      mask="__:__ _M"
      value={value}
      onChange={handleTime}
      keyboardIcon={<AccessTime />}
      KeyboardButtonProps={{ 'aria-label': `${name}-action` }}
    />
  );
};
