import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { CreateMentoringGuideLink } from '../../helpers/LinkBuilder';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { GetRoute } from '../../../../../Components/Routing';

export const IntroductionMenteeStepSixCorrect = () => {
  const mentoringGuideLink = CreateMentoringGuideLink();
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Correct! It’s best to do all of those things to prepare for your first meeting.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Make sure you’re familiar with the{' '}
        </Text>
        <Text variant="md" display="inline" color="purple" fontWeight={700}>
          ‘Preparing for your first meeting’{' '}
        </Text>
        <Text variant="md" display="inline">
          page in the{' '}
        </Text>
        <BrancherLinkText
          underline="always"
          fontWeight={700}
          variant="md"
          href={mentoringGuideLink}
          display="inline"
          target="_blank"
        >
          Mentoring Guide
        </BrancherLinkText>
        <Text variant="md" display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Remember to send through your proposed meeting agenda to your mentor ahead of time and be
          prepared to complete your{' '}
        </Text>
        <BrancherLinkText
          underline="always"
          fontWeight={700}
          variant="md"
          href={GetRoute('mentoringAgreement').path}
          display="inline"
        >
          Mentoring Agreement
        </BrancherLinkText>
        <Text variant="md" display="inline">
          {' '}
          during your first meeting.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          After the meeting, it’s best practice to send a summary email to your mentor addressing
          the topics you discussed and actions.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          This will help you with planning your next meeting agenda, strategies for achieving your
          goals and hold you accountable for the progression of goals in the program.
        </Text>
      </Grid>
    </Grid>
  );
};
