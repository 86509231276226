import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { BrancherList } from '../../../../../Components/General/BrancherList';

export const IntroductionMentorStepFourIncorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          Not quite. The correct answer was{' '}
        </Text>
        <Text variant="md" fontWeight={700} color="purple" display="inline">
          A
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          {' '}
          or{' '}
        </Text>
        <Text variant="md" fontWeight={700} color="purple" display="inline">
          B
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Whilst we understand that priorities change and that Jordan needs to be open to this, a
          text with little apology could make Jordan feel disheartened.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Depending on the situation, you may be able to keep the meeting or you may need to call
          Jordan and propose a new time to meet.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          This seems like common sense however our mentees’ biggest pain point from previous
          programs is that mentors will cancel meetings or turn up to meetings and answer phone
          calls. Where possible,{' '}
        </Text>
        <Text variant="md" color="purple" display="inline" fontWeight={700}>
          prioritise the needs of Jordan
        </Text>
        <Text variant="md" display="inline">
          {' '}
          and ensure they feel important. Basic meeting etiquette (for both parties) will strengthen
          your relationship. If you do need to cancel your meeting we recommend you:
        </Text>
      </Grid>
      <Grid item>
        <BrancherList
          listItems={[
            'Provide as much notice as possible to your mentee - at least 24 hours if possible',
            'Be respectful in the way you communicate your reason - a phone call is preferred rather than a text',
            'Be honest, authentic and respectful. Uses phrases like “I am really sorry, but I am going to need to reschedule our catch up due to...”',
            'Schedule another meeting as soon as possible by proposing some new dates and times',
          ]}
        />
      </Grid>
    </Grid>
  );
};
