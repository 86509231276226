import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SuggestionCard } from '../../SuggestionCard';

export const BeyondProgramMentorStepFiveStopCorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success>
          <Text variant="md" fontWeight={700}>
            Correct!
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="sm" display="inline">
          Answer{' '}
        </Text>
        <Text variant="sm" display="inline" fontWeight={700}>
          E
        </Text>
        <Text variant="sm" display="inline">
          {' '}
          is not recommended. Staying in the relationship out of obligation is unlikely to end well
          for either party.
        </Text>
      </Grid>
    </Grid>
  );
};
