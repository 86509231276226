import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveEzEnglishMenteeData } from '../../../../../store/actions';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const EasyEnglishMenteeStepOne = () => {
  const stepOneValue = useSelector(
    (state: IStoreTypes) => state.training.easyEnglishMentee?.StepOne,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepOneCorrectVal = '0';
    dispatch(
      SaveEzEnglishMenteeData({
        StepOne: {
          selected: val,
          correct: val === StepOneCorrectVal,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md">When do you meet?</Text>
      </Grid>
      <Grid item>
        <Text variant="sm" marginBottom={-30}>
          Choose one option.
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepOneValue"
          value={stepOneValue?.selected}
          updateValue={updateValue}
          noQuestion
          exclusive
          options={MakeOptions(['1 hour each month', '1 hour each week', '1 hour each day'])}
        />
      </Grid>
    </Grid>
  );
};
