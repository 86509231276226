import * as React from 'react';
import { ModuleWrapper } from '../ModuleWrapper';
import { BeGreatMenteeModuleMapper } from './BeGreatMenteeModuleMapper';

export const BeGreatMentee = () => (
  <ModuleWrapper
    moduleMap={BeGreatMenteeModuleMapper}
    moduleName="beGreatMentee"
    title="How to be a great mentee"
  />
);
