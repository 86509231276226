import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { AlexAvatar } from '../../avatars/Alex';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveIntroductionMentee } from '../../../../../store/actions';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const IntroductionMenteeStepOne = () => {
  const stepOneValue = useSelector((state: IStoreTypes) => state.training.introductionMentee);
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepOneCorrectVals = ['1', '2'];
    dispatch(
      SaveIntroductionMentee({
        ...stepOneValue,
        StepOne: {
          selected: val,
          correct: StepOneCorrectVals.includes(val[0]) && StepOneCorrectVals.includes(val[1]),
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <AlexAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700} display="inline">
            This is your mentor{' '}
          </Text>
          <Text variant="md" fontWeight={700} color="purple" display="inline">
            "Alex"
          </Text>
          <Text variant="md" fontWeight={700} display="inline">
            .
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          You’ve met with Alex a few times. Alex has really good intentions but you’re finding that
          Alex keeps jumping in and scheduling catch ups before you’ve had a chance. Alex is also
          doing most of the talking when you get together, which is preventing you from talking
          about what you’d like to focus on. What should you do?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You can choose more than one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="Let Alex take the lead. They are the mentor and therefore in a leadership role within your relationship."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="Have an open discussion about how you are feeling with Alex. Tell them you want to take the lead with this mentoring relationship, and that if it’s OK with them, you’ll schedule the next catch up and send through a proposed meeting agenda."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="Review the Mentoring Guide for a reminder on your roles and responsibilities as a mentee."
        />
      </Grid>
      <Grid item container justify="center">
        <SelectionControl
          question=""
          name="stepOneValue"
          value={stepOneValue.StepOne?.selected}
          updateValue={updateValue}
          thirdWidth
          noQuestion
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
