import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveMoDGoalSettingMentorData } from '../../../../../store/actions';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const MoDGoalSettingMentorStepTwo = () => {
  const stepTwoValue = useSelector(
    (state: IStoreTypes) => state.training?.[EMoDTrainingModules.MOD_GOAL_SETTING_MENTOR]?.StepTwo,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepTwoCorrectVal = '0';
    dispatch(
      SaveMoDGoalSettingMentorData({
        StepTwo: {
          selected: val,
          correct: val !== null && val === StepTwoCorrectVal,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md">
          Are you confident knowing and advising on SMART goals or would you like a refresher?
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          exclusive
          question=""
          name="stepTwoValue"
          value={stepTwoValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(['I am confident about SMART goals', 'Refresher please'])}
        />
      </Grid>
    </Grid>
  );
};
