import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeyondProgramMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const BeyondProgramMentorStepSixStop = () => {
  const stepSixValue = useSelector((state: IStoreTypes) => state.training?.beyondProgramMentor);
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepSixCorrectVal = '0';
    dispatch(
      SaveBeyondProgramMentorData({
        StepSix: {
          selected: val,
          correct: StepSixCorrectVal === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Quiz:
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Your mentee wants to keep meeting with you however you either do not have capacity and/or
          do not wish to continue the relationship. What is the best response?
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="I’ve really enjoyed getting to know you and seeing you grow however with the program ending soon, unfortunately I cannot commit to continuing to be your mentor because of my limited capacity' (or other reason)."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="Unfortunately I feel you have not been proactive enough in this relationship for me to continue being your mentor."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="Sure, that sounds great although I’m very busy at the moment so might not be able to meet for a few months."
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepSixValue"
          value={stepSixValue?.StepSix?.selected}
          updateValue={updateValue}
          noQuestion
          thirdWidth
          exclusive
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
