import { IReducerType } from './ReducerType';
import {
  EStructuredTrainingModules,
  ITrainingReducer,
} from '../reducerTypes/TrainingReducer.types';

export const TRAINING_DATA = 'TRAINING_DATA';
export const TRAINING_CLEAR_DATA = 'TRAINING_CLEAR_DATA';
export const TRAINING_INTRODUCTION_MENTEE = 'TRAINING_INTRODUCTION_MENTEE';
export const TRAINING_INTRODUCTION_MENTOR = 'TRAINING_INTRODUCTION_MENTOR';
export const TRAINING_MENTORING_AGREEMENT = 'TRAINING_MENTORING_AGREEMENT';
export const TRAINING_BE_GREAT_MENTEE = 'TRAINING_BE_GREAT_MENTEE';
export const TRAINING_BE_GREAT_MENTOR = 'TRAINING_BE_GREAT_MENTOR';
export const TRAINING_MAKE_THE_MOST_MENTOR = 'TRAINING_MAKE_THE_MOST_MENTOR';
export const TRAINING_MAKE_THE_MOST_MENTEE = 'TRAINING_MAKE_THE_MOST_MENTEE';
export const TRAINING_GIVING_RECEIVING_FEEDBACK_MENTOR =
  'TRAINING_GIVING_RECEIVING_FEEDBACK_MENTOR';
export const TRAINING_GIVING_RECEIVING_FEEDBACK_MENTEE =
  'TRAINING_GIVING_RECEIVING_FEEDBACK_MENTEE';
export const TRAINING_BEYOND_PROGRAM_MENTOR = 'TRAINING_BEYOND_PROGRAM_MENTOR';
export const TRAINING_BEYOND_PROGRAM_MENTEE = 'TRAINING_BEYOND_PROGRAM_MENTEE';
export const SURVEY_ONE = 'SURVEY_ONE';
export const SURVEY_TWO = 'SURVEY_TWO';
export const SURVEY_THREE = 'SURVEY_THREE';

// MoD
export const TRAINING_MOD_BE_GREAT_MENTEE = 'TRAINING_MOD_BE_GREAT_MENTEE';
export const TRAINING_MOD_BE_GREAT_MENTOR = 'TRAINING_MOD_BE_GREAT_MENTOR';
export const TRAINING_MOD_GOAL_SETTING_MENTEE = 'TRAINING_MOD_GOAL_SETTING_MENTEE';
export const TRAINING_MOD_GOAL_SETTING_MENTOR = 'TRAINING_MOD_GOAL_SETTING_MENTOR';
export const TRAINING_POWER_OF_REFLECTION_MENTOR = 'TRAINING_POWER_OF_REFLECTION_MENTOR';
export const TRAINING_POWER_OF_REFLECTION_MENTEE = 'TRAINING_POWER_OF_REFLECTION_MENTEE';
export const TRAINING_MOD_SITUATIONAL_MENTORSHIP_MENTOR =
  'TRAINING_MOD_SITUATIONAL_MENTORSHIP_MENTOR';
export const TRAINING_MOD_GROWTH_COACHING_FRAMEWORK_MENTOR =
  'TRAINING_MOD_GROWTH_COACHING_FRAMEWORK_MENTOR';

// Easy English
export const TRAINING_EASY_ENGLISH_MENTEE = 'TRAINING_EASY_ENGLISH_MENTEE';
export const TRAINING_EASY_ENGLISH_MENTOR = 'TRAINING_EASY_ENGLISH_MENTOR';
export const TRAINING_EASY_ENGLISH_CC = 'TRAINING_EASY_ENGLISH_CC';

export const TrainingReducer = (state = trainingInitialState, action: IReducerType) => {
  switch (action.type) {
    case TRAINING_CLEAR_DATA:
      return trainingInitialState;
    case TRAINING_DATA:
      return { ...state, ...action.payload };
    case TRAINING_INTRODUCTION_MENTEE:
      return { ...state, introductionMentee: { ...state.introductionMentee, ...action.payload } };
    case TRAINING_INTRODUCTION_MENTOR:
      return { ...state, introductionMentor: { ...state.introductionMentor, ...action.payload } };
    case TRAINING_MENTORING_AGREEMENT:
      return { ...state, mentoringAgreement: { ...state.mentoringAgreement, ...action.payload } };
    case TRAINING_BE_GREAT_MENTEE:
      return { ...state, beGreatMentee: { ...state.beGreatMentee, ...action.payload } };
    case TRAINING_BE_GREAT_MENTOR:
      return { ...state, beGreatMentor: { ...state.beGreatMentor, ...action.payload } };
    case SURVEY_ONE:
      return { ...state, surveyOne: { ...state.surveyOne, ...action.payload } };
    case SURVEY_TWO:
      return { ...state, surveyTwo: { ...state.surveyTwo, ...action.payload } };
    case SURVEY_THREE:
      return { ...state, surveyThree: { ...state.surveyThree, ...action.payload } };
    case TRAINING_MAKE_THE_MOST_MENTOR:
      return { ...state, makeTheMostMentor: { ...state.makeTheMostMentor, ...action.payload } };
    case TRAINING_MAKE_THE_MOST_MENTEE:
      return { ...state, makeTheMostMentee: { ...state.makeTheMostMentee, ...action.payload } };
    case TRAINING_GIVING_RECEIVING_FEEDBACK_MENTOR:
      return {
        ...state,
        givingReceivingFeedbackMentor: {
          ...state.givingReceivingFeedbackMentor,
          ...action.payload,
        },
      };
    case TRAINING_GIVING_RECEIVING_FEEDBACK_MENTEE:
      return {
        ...state,
        givingReceivingFeedbackMentee: {
          ...state.givingReceivingFeedbackMentee,
          ...action.payload,
        },
      };
    case TRAINING_BEYOND_PROGRAM_MENTEE:
      return {
        ...state,
        [EStructuredTrainingModules.BEYOND_PROGRAM_MENTEE]: {
          ...state?.[EStructuredTrainingModules.BEYOND_PROGRAM_MENTEE],
          ...action.payload,
        },
      };
    case TRAINING_BEYOND_PROGRAM_MENTOR:
      return {
        ...state,
        [EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR]: {
          ...state?.[EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR],
          ...action.payload,
        },
      };
    case TRAINING_MOD_BE_GREAT_MENTEE:
      return { ...state, modBeGreatMentee: { ...state.modBeGreatMentee, ...action.payload } };
    case TRAINING_MOD_BE_GREAT_MENTOR:
      return { ...state, modBeGreatMentor: { ...state.modBeGreatMentor, ...action.payload } };
    case TRAINING_MOD_GOAL_SETTING_MENTEE:
      return {
        ...state,
        modGoalSettingMentee: { ...state.modGoalSettingMentee, ...action.payload },
      };
    case TRAINING_MOD_GOAL_SETTING_MENTOR:
      return {
        ...state,
        modGoalSettingMentor: { ...state.modGoalSettingMentor, ...action.payload },
      };
    case TRAINING_POWER_OF_REFLECTION_MENTOR:
      return {
        ...state,
        modPowerOfReflectionMentor: { ...state.modPowerOfReflectionMentor, ...action.payload },
      };
    case TRAINING_POWER_OF_REFLECTION_MENTEE:
      return {
        ...state,
        modPowerOfReflectionMentee: { ...state.modPowerOfReflectionMentee, ...action.payload },
      };
    case TRAINING_MOD_SITUATIONAL_MENTORSHIP_MENTOR:
      return {
        ...state,
        modSituationalMentorshipMentor: {
          ...state.modSituationalMentorshipMentor,
          ...action.payload,
        },
      };
    case TRAINING_MOD_GROWTH_COACHING_FRAMEWORK_MENTOR:
      return {
        ...state,
        modGrowthCoachingFrameworkMentor: {
          ...state.modGrowthCoachingFrameworkMentor,
          ...action.payload,
        },
      };
    case TRAINING_EASY_ENGLISH_MENTEE:
      return { ...state, easyEnglishMentee: { ...state.easyEnglishMentee, ...action.payload } };
    case TRAINING_EASY_ENGLISH_MENTOR:
      return { ...state, easyEnglishMentor: { ...state.easyEnglishMentor, ...action.payload } };
    case TRAINING_EASY_ENGLISH_CC:
      return { ...state, easyEnglishCC: { ...state.easyEnglishCC, ...action.payload } };
    default:
      return state;
  }
};

export const trainingInitialState: ITrainingReducer = {
  mentoringAgreement: {},
  easyEnglishCC: {
    rating: null,
    feedback: '',
    StepOne: {},
    StepTwo: {},
    StepThree: {},
    StepFour: {},
  },
  easyEnglishMentee: {
    rating: null,
    feedback: '',
    StepOne: {},
    StepTwo: {},
    StepThree: {},
    StepFour: {},
  },
  easyEnglishMentor: {
    rating: null,
    feedback: '',
    StepOne: {},
    StepTwo: {},
    StepThree: {},
    StepFour: {},
  },
  modGoalSettingMentee: {
    rating: null,
    feedback: '',
    StepOne: {},
    StepTwo: {},
    StepThree: {},
    StepFour: {},
    StepFive: {},
    StepSix: {},
    StepSeven: { goalSaved: false, interacted: false, newGoal: '' },
    StepEight: {},
    StepNine: {
      interacted: false,
      preferredLevel: null,
      smartGoalLevel: null,
      whyHighScore: '',
      whyLowScore: '',
    },
    StepTen: {},
    StepEleven: {},
  },
  modGoalSettingMentor: {
    rating: null,
    feedback: '',
    StepOne: {},
    StepTwo: {},
  },
  modBeGreatMentee: {
    rating: null,
    feedback: '',
    StepOne: {},
    StepTwo: {},
    StepThree: {},
    StepFour: {},
    StepFive: {},
    StepSix: {},
    StepSeven: {},
    StepEight: {},
    StepNine: {},
    StepTen: {},
    StepEleven: {},
  },
  modBeGreatMentor: {
    rating: null,
    feedback: '',
    StepOne: {},
    StepTwo: {},
    StepThree: {},
    StepFour: {},
    StepFive: {},
    StepSix: {},
    StepSeven: {},
    StepEight: {},
    StepNine: {},
    StepTen: {},
    StepEleven: {},
    StepTwelve: {},
  },
  modPowerOfReflectionMentee: {
    rating: null,
    feedback: '',
    qualifying: { haveMet: null },
    StepOne: { interacted: false },
    StepTwo: {
      experience: '',
      reflect: '',
      evaluate: '',
      plan: '',
      sharePublic: null,
      savedNote: false,
    },
    StepThree: { interacted: false },
  },
  modPowerOfReflectionMentor: {
    rating: null,
    feedback: '',
    qualifying: { haveMet: null },
    StepOne: { interacted: false },
    StepTwo: {
      experience: '',
      reflect: '',
      evaluate: '',
      plan: '',
      sharePublic: null,
      savedNote: false,
    },
    StepThree: { interacted: false },
  },
  introductionMentee: {
    rating: null,
    feedback: '',
    StepOne: { correct: false, selected: [] },
    StepTwo: { correct: false, selected: [] },
    StepThree: { correct: false, selected: '' },
    StepFour: { correct: false, selected: [] },
    StepFive: { correct: false, selected: '' },
    StepSix: { correct: false, selected: [] },
    StepSeven: { correct: false, selected: '' },
    StepEight: { selected: '' },
  },
  introductionMentor: {
    rating: null,
    feedback: '',
    StepOne: { correct: false, selected: [] },
    StepTwo: { correct: false, selected: [] },
    StepThree: { correct: false, selected: '' },
    StepFour: { correct: false, selected: [] },
    StepFive: { correct: false, selected: '' },
    StepSix: { correct: false, selected: [] },
    StepSeven: { correct: false, selected: '' },
    StepEight: { selected: '' },
  },
  makeTheMostMentee: {
    rating: null,
    feedback: '',
    qualifying: { haveMet: null },
    firstMeetingDate: new Date(),
    StepOne: { interacted: false },
    StepTwo: {
      experience: '',
      reflect: '',
      evaluate: '',
      plan: '',
      sharePublic: null,
      savedNote: false,
    },
    StepThree: { interacted: true },
    StepFour: { interacted: false },
    StepFive: { interacted: false, correct: false, goalOne: '' },
    StepSix: { interacted: false, correct: false, goalTwo: '' },
    StepSeven: { interacted: false, correct: true, goalThree: '' },
    StepEight: { interacted: false, newGoal: '', smartConsiderations: null },
    StepNine: { sharePublic: null, savedNote: false },
    StepTen: { whyGoalImportant: '', interacted: false },
    StepEleven: {
      smartGoalLevel: '',
      preferredLevel: '',
      whyLowScore: '',
      whyHighScore: '',
      interacted: false,
    },
    StepTwelve: { quiz: '', interacted: false, correct: false },
    StepThirteen: { selected: '', interacted: false },
  },
  makeTheMostMentor: {
    rating: null,
    feedback: '',
    qualifying: { haveMet: null },
    firstMeetingDate: new Date(),
    StepOne: { interacted: false },
    StepTwo: {
      experience: '',
      reflect: '',
      evaluate: '',
      plan: '',
      sharePublic: null,
      savedNote: false,
    },
    StepThree: { interacted: true },
    StepFour: { interacted: false },
    StepFive: { interacted: false, correct: false, goalOne: '' },
    StepSix: { interacted: false, correct: false, goalTwo: '' },
    StepSeven: { interacted: false, correct: true, goalThree: '' },
    StepEight: { interacted: false, newGoal: '', smartConsiderations: null },
    StepNine: { sharePublic: null, savedNote: false },
    StepTen: { whyGoalImportant: '', interacted: false },
    StepEleven: {
      smartGoalLevel: '',
      preferredLevel: '',
      whyLowScore: '',
      whyHighScore: '',
      interacted: false,
    },
    StepTwelve: { selected: '', interacted: false },
  },
  beGreatMentee: {
    rating: null,
    feedback: '',
    StepOne: { interacted: false },
    StepTwo: {
      interacted: false,
      engageOpenly: null,
      helpMentorUnderstand: null,
      ownDevelopment: null,
      driveRelationship: null,
      growthMindset: null,
      authentic: null,
      stoppingYou: '',
      ensureRegular: '',
      sharePublic: null,
      savedNote: false,
    },
    StepThree: { interacted: false },
    StepFour: { correct: false, selected: '' },
    StepFive: { correct: false, selected: '' },
    StepSix: { correct: false, selected: '' },
    StepSeven: { correct: false, selected: '' },
    StepEight: { correct: false, selected: '' },
    StepNine: { interacted: true },
    StepTen: { correct: false, selected: '' },
    StepEleven: { correct: false, selected: '' },
  },
  beGreatMentor: {
    rating: null,
    feedback: '',
    StepOne: { interacted: false },
    StepTwo: { interacted: false },
    StepThree: { correct: false, selected: '' },
    StepFour: { correct: false, selected: '' },
    StepFive: { correct: false, selected: '' },
    StepSix: { correct: false, selected: '' },
    StepSeven: { correct: false, selected: '' },
    StepEight: { correct: false, selected: '' },
    StepNine: { correct: false, selected: '' },
    StepTen: { correct: false, selected: '' },
    StepEleven: { interacted: true },
    StepTwelve: { interacted: true },
    StepThirteen: { correct: false, selected: '' },
    StepFourteen: { correct: false, selected: '' },
    StepFifteen: { correct: false, selected: '' },
    StepSixteen: { correct: false, selected: '' },
    StepSeventeen: { correct: false, selected: '' },
    StepEighteen: { correct: false, selected: '' },
    StepNineteen: { correct: false, selected: '' },
  },
  givingReceivingFeedbackMentor: {
    rating: null,
    feedback: '',
    StepOne: {},
    StepTwo: {},
    StepThree: {},
    StepFour: {},
    StepFive: {},
    StepSix: {},
    StepSeven: {},
    StepEight: {},
    StepNine: {},
    StepTen: {},
    StepEleven: {},
  },
  givingReceivingFeedbackMentee: {
    rating: null,
    feedback: '',
    StepOne: {},
    StepTwo: {},
    StepThree: {},
    StepFour: {},
    StepFive: {},
    StepSix: {},
    StepSeven: {},
    StepEight: {},
    StepNine: {},
    StepTen: {},
    StepEleven: {},
  },
  beyondProgramMentee: {
    rating: null,
    feedback: '',
    StepOne: {},
    StepTwo: {},
    StepThree: { partnerMatchSatisfaction: '', wantToContinue: null },
    StepFour: {},
    StepFive: { continueConfidence: null, correct: false },
    StepSix: { meetingPurpose: '', sharePublic: null, savedNote: false },
    StepSeven: {},
    StepEight: {
      experience: '',
      reflect: '',
      evaluate: '',
      plan: '',
      savedNote: false,
      sharedPublic: null,
    },
    StepNine: { achievedGoal: null, currentConfidence: null, gainKnowledge: null },
    StepTen: { sharePublic: null, savedNote: false, newGoal: '' },
    StepEleven: {},
    StepTwelve: { enjoyMost: '', improveOn: '', savedNote: false },
    StepThirteen: {},
    StepFourteen: {},
  },
  beyondProgramMentor: {
    rating: null,
    feedback: '',
    StepOne: {},
    StepTwo: {},
    StepThree: { partnerMatchSatisfaction: '', wantToContinue: null },
    StepFour: {},
    StepFive: { continueConfidence: null, correct: false },
    StepSix: { meetingPurpose: '', sharePublic: null, savedNote: false },
    StepSeven: {},
    StepEight: {
      experience: '',
      reflect: '',
      evaluate: '',
      plan: '',
      savedNote: false,
      sharedPublic: null,
    },
    StepNine: { achievedGoal: null, currentConfidence: null, gainKnowledge: null },
    StepTen: { sharePublic: null, savedNote: false, newGoal: '' },
    StepEleven: {},
    StepTwelve: { enjoyMost: '', improveOn: '', savedNote: false },
    StepThirteen: {},
    StepFourteen: {},
  },
  surveyOne: {
    leadSupportRequired: false,
    email: '',
    StepOne: { haveYouMet: null },
    StepTwo: { hasStoppedYou: '', satisfied: null },
    StepThree: { planToMeet: null, adequateTraining: null },
    StepFive: { commitToInformLead: null, helpingMeAchieve: null },
    StepFour: { informLead: null },
    StepSix: { feedback: '' },
    StepSeven: {},
  },
  surveyTwo: {},
  surveyThree: {},
  progress: {},
  moduleConfig: { mentee: [], mentor: [] },
  modules: { mentee: [], mentor: [], peer: [] },
  customModules: {},
  activeTrainingTab: 0,
  openTrainingNav: false,
};
