import * as React from 'react';
import { ScaleText } from '../../../../Components/General/ScaleText';

export const ConfidenceScale = () => {
  return (
    <>
      <ScaleText scale={7} description="Extremely confident" />
      <ScaleText scale={6} description="Confident" />
      <ScaleText scale={5} description="Moderately confident" />
      <ScaleText scale={4} description="Neither confident nor unconfident" />
      <ScaleText scale={3} description="Moderately unconfident" />
      <ScaleText scale={2} description="Unconfident" />
      <ScaleText scale={1} description="Extremely unconfident" />
    </>
  );
};
