import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../consts/colors';
import { Text } from './Text';

const useStyles = makeStyles({
  tile: {
    background: (props: { type: 'positive' | 'negative' }) =>
      props.type === 'positive' ? Colors.purple : Colors.red,
    minWidth: (props: { mobile: boolean }) => (props.mobile ? 35 : 90),
    width: (props: { mobile: boolean }) => (props.mobile ? 35 : 90),
    minHeight: (props: { mobile: boolean }) => (props.mobile ? 35 : 90),
    height: (props: { mobile: boolean }) => (props.mobile ? 35 : 90),
    borderRadius: (props: { mobile: boolean }) => (props.mobile ? 10 : 15),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bordered: {
    backgroundImage: `linear-gradient(70deg, rgba(80, 0, 255,1) 33%, rgba(255,255,255,0) 0%)`,
    backgroundPosition: 'top',
    backgroundSize: '7px 2px',
    backgroundRepeat: 'repeat-x',
    height: 2,
  },
});

interface ITileDescriptor {
  tileKey: number;
  description: string;
  type: 'positive' | 'negative';
}

export const TileDescription = (props: ITileDescriptor) => {
  const { tileKey, description, type } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles({ mobile, type });
  return (
    <Grid container spacing={3}>
      <Grid item xs={2}>
        <Box className={styles.tile}>
          <Text variant="md" color="white" fontWeight={700}>
            {tileKey}
          </Text>
        </Box>
      </Grid>
      <Grid item xs={10}>
        <Box className={styles.bordered} />
        <Text variant="sm" marginTop={mobile ? 8 : 30}>
          {description}
        </Text>
      </Grid>
    </Grid>
  );
};
