import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SuggestionCard } from '../../SuggestionCard';

export const IntroductionMentorStepTwoIncorrect = () => {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          Not quite. The best option was{' '}
        </Text>
        <Text variant="md" fontWeight={700} color="purple" display="inline">
          B
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Answer B involves taking a coaching approach when mentoring (asking rather than telling).
          A coaching approach involves asking key questions to unpack why Jordan is not setting the
          meeting agendas.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You could start the conversation with questions like:
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard>"Have you done the first online training activity yet?"</SuggestionCard>
      </Grid>
      <Grid item>
        <SuggestionCard>"What is your understanding of a mentee’s role?"</SuggestionCard>
      </Grid>
      <Grid item>
        <SuggestionCard>
          "How do you feel about creating the meeting agendas moving forward?"
        </SuggestionCard>
      </Grid>
      <Grid item>
        <SuggestionCard>"Are you comfortable to do that?"</SuggestionCard>
      </Grid>
    </Grid>
  );
};
