import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { AlexAvatar } from '../../avatars/Alex';
import { SuggestionCard } from '../../SuggestionCard';

export const MoDBeGreatMentorStepFiveCorrect = () => {
  const stepFiveValue = useSelector(
    (state: IStoreTypes) => state.training.modBeGreatMentor?.StepFive,
  );

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepFiveValue.correct} incorrect={!stepFiveValue.correct}>
          <Text variant="md" fontWeight={700}>
            {stepFiveValue.correct
              ? 'Correct! The best option was B.'
              : 'Not quite. The best option was B.'}
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <AlexAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          <Grid item>
            <Text variant="md">
              Although the onus is on Alex to schedule the catch-ups as the mentee, if you haven't
              heard from them, give them a call. Try to unpack why the lead hasn't been taken as
              much as you’d expected.
            </Text>
          </Grid>
          <Grid item>
            <Text variant="sm" marginTop={20}>
              You could ask questions like:
            </Text>
          </Grid>
          <Grid item>
            <Text variant="md">“How are you finding the program so far?”</Text>
            <Text variant="md">“What would you like to do next?”</Text>
            <Text variant="md">“When would you like to catch up?”</Text>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
