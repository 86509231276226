import { createMuiTheme } from '@material-ui/core/styles';
import { Colors } from '../consts/colors';
import { MobileFontSizes, TabletDesktopFontSizes } from '../consts/FontSizes';

const breakpoints = createMuiTheme().breakpoints;

export const MuiSlider = {
  root: {
    marginTop: 30,
    height: 3,
  },
  track: {
    backgroundColor: Colors.purple,
    height: 3,
  },
  rail: {
    backgroundColor: Colors.lightGrey,
    height: 3,
  },
  mark: {
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  marked: {
    marginBottom: 12,
  },
  thumb: {
    width: 30,
    height: 30,
    marginTop: -13,
    marginLeft: -13,
    backgroundColor: Colors.purple,
  },
  markLabel: {
    [breakpoints.down('sm')]: {
      fontSize: MobileFontSizes.xxxs,
    },
    [breakpoints.up('md')]: {
      fontSize: TabletDesktopFontSizes.xxs,
    },
    lineHeight: 1.2,
    color: Colors.black,
    marginTop: -60,
    fontWeight: 600,
    whiteSpace: 'unset',
    wordWrap: 'break-word',
    maxWidth: '14ch',
  },
  markLabelActive: {
    color: Colors.purple,
  },
};
