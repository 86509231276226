import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { ScaleText } from '../../../../../Components/General/ScaleText';
import { SuggestionCard } from '../../SuggestionCard';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import {
  SaveBeyondProgramMenteeData,
  SaveBeyondProgramMentorData,
} from '../../../../../store/actions';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';

export const BeyondProgramMentorStepFiveContinue = () => {
  const dispatch = useDispatch();
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentee = position === ProgramPositions.mentee;
  const sessionStore = isMentee
    ? EStructuredTrainingModules.BEYOND_PROGRAM_MENTEE
    : EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR;
  const sessionSaveFunction = isMentee ? SaveBeyondProgramMenteeData : SaveBeyondProgramMentorData;
  const StepFiveValue = useSelector((state: IStoreTypes) => state.training?.[sessionStore]);

  const updateConfidence = (val: string | number) => {
    dispatch(
      sessionSaveFunction({
        StepFive: {
          continueConfidence: val,
          interacted: val !== null,
          correct: val !== null && val > 2,
        },
      }),
    );
  };
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="sm" fontWeight={700}>
          How confident are you to clearly and effectively talk to your{' '}
          {isMentee ? 'mentor' : 'mentee'} about your desire to continue the relationship?
        </Text>
      </Grid>
      <Grid item>
        <ScaleText scale={5} description="Extremely confident" />
        <ScaleText scale={1} description="Not at all confident" />
      </Grid>
      <Grid item>
        <SegmentedControlGroup
          value={StepFiveValue?.StepFive?.continueConfidence}
          valueLength={5}
          updateValue={updateConfidence}
          fullWidth
        />
      </Grid>
      <Grid item>
        <SuggestionCard>
          We will provide you with different training, depending on your response to this question.
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
