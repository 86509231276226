import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';

export const MoDGoalSettingMenteeStepFourCorrect = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const sessionStore =
    sesPos === ProgramPositions.mentee
      ? EMoDTrainingModules.MOD_GOAL_SETTING_MENTEE
      : EMoDTrainingModules.MOD_GOAL_SETTING_MENTOR;
  const stepFourValue = useSelector(
    (state: IStoreTypes) => state.training?.[sessionStore]?.StepFour,
  );

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepFourValue.correct} incorrect={!stepFourValue.correct}>
          <Text variant="md" fontWeight={700}>
            The correct answer is A, B and E.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="md">
          Whilst the goal may be specific, measurable and timely, the individual may want to
          consider whether the goal is actually attainable given their circumstances. There is not
          enough information about the situation to determine if the goal is relevant.
        </Text>
      </Grid>
    </Grid>
  );
};
