import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { CharlieAvatar } from '../../avatars/Charlie';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const BeGreatMentorStepThree = () => {
  const stepThreeValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentor.StepThree,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepThreeCorrectVal = '0';
    dispatch(
      SaveBeGreatMentorData({
        StepThree: {
          selected: val,
          correct: StepThreeCorrectVal === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700} display="inline">
            This is your mentee{' '}
          </Text>
          <Text variant="md" fontWeight={700} color="purple" display="inline">
            "Charlie"
          </Text>
          <Text variant="md" fontWeight={700} display="inline">
            .
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          Charlie has been given two days to create a presentation for a senior executive. This is
          Charlie's first time doing something like this. Unfortunately, Charlie's manager has had
          to go home sick so Charlie gives you a call to seek your advice. Charlie says, "I'm really
          panicking, this is a chance to prove myself but I feel out of my depth and I am running
          out of time ...”
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          What role should you play?
        </Text>
      </Grid>
      <Grid item container justify="center">
        <SelectionControl
          question=""
          name="stepThreeValue"
          value={stepThreeValue?.selected}
          updateValue={updateValue}
          noQuestion
          exclusive
          options={MakeOptions(['Director (i.e. telling)', 'Coach (i.e. asking)'])}
        />
      </Grid>
    </Grid>
  );
};
