import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SvgIcon from '@material-ui/core/SvgIcon';
import useTheme from '@material-ui/core/styles/useTheme';
import Badge from '@material-ui/core/Badge';
import GroupWork from '@material-ui/icons/GroupWork';
import StarBorder from '@material-ui/icons/StarBorder';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useReward } from 'react-rewards';
import { Text } from '../../Components/General/Text';
import { LogoutButton } from '../../Components/InputFields/BrancherButton';
import { Colors } from '../../consts/colors';
import { GetRoute } from '../../Components/Routing';
import { BrancherDispatch, SaveUserInfo, UtilSignOut } from '../../store/actions';
import { IStoreTypes } from '../../store/storeTypes';
import { mobileMainNavHeight, mobileMainNavWidth } from '../../consts/navHeights';
import { Subscription } from '../../types/SubscriptionTypes';
import { GetIllustrationLink, GetLinkBase } from '../../utils/LinkUtils';
import { CohortIcon } from './images/CohortIcon';
import { HomeIcon } from './images/HomeIcon';
import { MeetingsIcon } from './images/MeetingsIcon';
import { NotesIcon } from './images/NotesIcon';
import { TrainingIcon } from './images/TrainingIcon';
import { UpdatableCompanyInfo } from '../../store/reducers/UserInfoReducer';
import { EProfileAttributes } from '../../store/reducers/ProfileFormReducer';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
  navBar: {
    height: (props: { mobile: boolean }) => (props.mobile ? mobileMainNavHeight : '100vh'),
    width: (props: { mobile: boolean }) => (props.mobile ? '100vw' : mobileMainNavWidth),
    left: (props: { mobile: boolean }) => (props.mobile ? 'auto' : 0),
    bottom: (props: { mobile: boolean }) => (props.mobile ? 0 : 'auto'),
    top: (props: { mobile: boolean }) => props.mobile && 'auto',
    overflowY: 'auto',
    '--webkit-overflow-scrolling': 'touch',
    '--ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
    padding: `0px !important`,
  },
  selected: {
    marginLeft: 10,
  },
  unselected: {
    marginLeft: 10,
  },
  navLinkSection: {
    minHeight: (props: { mobile: boolean }) => (props.mobile ? 55 : 'auto'),
    height: (props: { mobile: boolean }) => (props.mobile ? 55 : 'auto'),
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  mobileNavLinkSection: {
    minHeight: mobileMainNavHeight,
    maxHeight: mobileMainNavHeight,
    width: '20%',
  },
  navSectionSelected: {
    background: Colors.white,
    borderBottomLeftRadius: 50,
    borderTopLeftRadius: 50,
  },
  mobileNavSectionSelected: {
    background: Colors.white,
    minHeight: mobileMainNavHeight,
    maxHeight: mobileMainNavHeight,
  },
  mobileNavSectionUnselected: {
    minHeight: mobileMainNavHeight,
    maxHeight: mobileMainNavHeight,
  },
  navSection: {
    minHeight: 72,
    maxHeight: 72,
  },
  profilePic: {
    maxWidth: 100,
    minWidth: 100,
    maxHeight: 100,
    minHeight: 100,
    borderRadius: '50%',
  },
  profilePicBacking: {
    marginTop: (props: { hasLogo: boolean }) => (props.hasLogo ? 30 : 100),
    borderRadius: '50%',
    maxWidth: 106,
    minWidth: 106,
    maxHeight: 106,
    minHeight: 106,
    background: Colors.white,
  },
  navHeader: {
    marginBottom: 30,
  },
  navIcon: {
    fontSize: 30,
  },
  logo: {
    marginTop: 40,
    maxHeight: 90,
    width: 90,
    height: 'auto',
  },
});

interface INavConfig {
  isCurrentRoute: boolean;
  isVisible: boolean;
  route: string;
  icon: React.ReactNode;
  title: string;
}

export const MainNav = () => {
  const selectedRoute = useLocation().pathname;
  const sessionRoleId = useSelector((state: IStoreTypes) => state.user.sessionRoleId);
  const activityScore = useSelector(
    (state: IStoreTypes) => state.profileForm?.profile?.activityScore,
  );
  const celebrateProgressActivity = useSelector(
    (state: IStoreTypes) => state.user?.celebrateProgressActivity,
  );
  const hasSessionRoleGroup =
    useSelector((state: IStoreTypes) => state.user?.groups)?.filter(
      (g) => g.roleId === sessionRoleId,
    )?.length > 0;
  const subscription = useSelector((state: IStoreTypes) => state.user.subscription);
  const companyLogo = useSelector(
    (state: IStoreTypes) => state.user?.[UpdatableCompanyInfo.CUSTOM_LOGO],
  );
  const companyName = useSelector(
    (state: IStoreTypes) => state.user?.[UpdatableCompanyInfo.COMPANY_NAME],
  );
  const hasMentoringPartner = !!useSelector(
    (state: IStoreTypes) => state.user?.sessionPair?.roleId,
  );
  const hasActiveGroup = useSelector((state: IStoreTypes) => state.user?.groups)?.length > 0;
  const profileImage = useSelector((state: IStoreTypes) => state?.profileForm?.profile?.image);
  const profileFirstName = useSelector(
    (state: IStoreTypes) => state?.profileForm?.profile?.[EProfileAttributes.FIRST_NAME],
  );
  const firstName = useSelector((state: IStoreTypes) => state.user.firstName);
  const chosenSubscription = subscription;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const hasLogo = !!companyLogo;
  const styles = useStyles({ mobile, hasLogo });
  const dispatch = useDispatch();
  // https://www.npmjs.com/package/react-rewards
  const { reward } = useReward('rewardId', 'confetti', { elementSize: 6, spread: 25 });

  React.useEffect(() => {
    if (celebrateProgressActivity) {
      reward();
      dispatch(SaveUserInfo({ celebrateProgressActivity: false }));
    }
  }, [celebrateProgressActivity]);

  const isTrainingRoute = (): boolean => {
    const split = selectedRoute.split('/');
    return `/${split[1]}` === GetRoute('training').path;
  };

  const isNotesRoute = (): boolean => {
    const split = selectedRoute.split('/');
    return `/${split[1]}` === GetRoute('notes').path;
  };

  const isCohortRoute = (): boolean => {
    const split = selectedRoute.split('/');
    return `/${split[1]}` === GetRoute('cohort').path;
  };

  const isMeetingsRoute = (): boolean => {
    const split = selectedRoute.split('/');
    return `/${split[1]}` === GetRoute('meetings').path;
  };

  const isGroupsRoute = (): boolean => {
    const split = selectedRoute.split('/');
    return `/${split[1]}` === GetRoute('groups').path || `/${split[1]}` === GetRoute('group').path;
  };

  const hasGroups = subscription.includes(Subscription.GROUPS);

  const isDashboardRoute = (): boolean => {
    const split = selectedRoute.split('/');
    return (
      `/${split[1]}` === GetRoute('settings').path ||
      `/${split[1]}` === GetRoute('dashboard').path ||
      selectedRoute === GetRoute('mentoringAgreement').path ||
      selectedRoute === GetRoute('goals').path ||
      selectedRoute === GetRoute('actions').path ||
      selectedRoute === GetRoute('resources').path
    );
  };

  const navConfig: INavConfig[] = [
    {
      route: GetRoute('dashboard').path,
      isCurrentRoute: isDashboardRoute(),
      icon: <HomeIcon selected={isDashboardRoute()} />,
      title: 'Home',
      isVisible: chosenSubscription.includes(Subscription.PROFILE),
    },
    {
      route: GetRoute('groups').path,
      isCurrentRoute: isGroupsRoute(),
      icon: <GroupWork color={isGroupsRoute() ? 'primary' : 'action'} />,
      title: 'Groups',
      isVisible: hasGroups && hasSessionRoleGroup,
    },
    {
      route: GetRoute('meetings').path,
      isCurrentRoute: isMeetingsRoute(),
      icon: <MeetingsIcon selected={isMeetingsRoute()} />,
      title: 'Meetings',
      isVisible:
        chosenSubscription.includes(Subscription.MEETINGS) &&
        (hasMentoringPartner || hasActiveGroup),
    },
    {
      route: GetRoute('cohort').path,
      isCurrentRoute: isCohortRoute(),
      icon: <CohortIcon selected={isCohortRoute()} />,
      title: 'Cohort',
      isVisible: chosenSubscription.includes(Subscription.COHORT),
    },
    {
      route: GetRoute('training').path,
      isCurrentRoute: isTrainingRoute(),
      icon: <TrainingIcon selected={isTrainingRoute()} />,
      title: 'Training',
      isVisible: chosenSubscription.includes(Subscription.TRAINING),
    },
    {
      route: GetRoute('notes').path,
      isCurrentRoute: isNotesRoute(),
      icon: <NotesIcon selected={isNotesRoute()} />,
      title: 'Notes',
      isVisible:
        (chosenSubscription.includes(Subscription.PRIVATE_NOTES) ||
          chosenSubscription.includes(Subscription.SHARED_NOTES)) &&
        (hasMentoringPartner || hasActiveGroup),
    },
  ];

  let config = [];
  if (!mobile) {
    navConfig.forEach((nav, index) => {
      if (nav.isVisible) {
        config.push(
          <Link to={nav.route} className={styles.navLinkSection} key={index}>
            <Grid item container xs={12} justify="space-between">
              <Grid item container xs={1} />
              <Grid
                item
                container
                xs={11}
                justify="center"
                alignItems="center"
                className={clsx(styles.navSection, {
                  [styles.navSectionSelected]: nav.isCurrentRoute,
                })}
              >
                <Grid item xs={4}>
                  <SvgIcon
                    className={clsx(
                      styles.navIcon,
                      nav.isCurrentRoute ? styles.selected : styles.unselected,
                    )}
                  >
                    {nav.icon}
                  </SvgIcon>
                </Grid>
                <Grid item xs={6}>
                  <Text
                    variant="xs"
                    fontWeight={nav.isCurrentRoute ? 600 : 500}
                    color={nav.isCurrentRoute ? 'purple' : 'white'}
                    noPreWrap
                  >
                    {nav.title}
                  </Text>
                </Grid>
              </Grid>
            </Grid>
          </Link>,
        );
      }
    });
  } else {
    navConfig.forEach((nav, index) => {
      if (nav.isVisible) {
        config.push(
          <Link to={nav.route} className={styles.mobileNavLinkSection} key={index}>
            <Grid
              item
              container
              xs={12}
              justify="center"
              alignItems="center"
              className={clsx(styles.mobileNavSectionUnselected, {
                [styles.mobileNavSectionSelected]: nav.isCurrentRoute,
              })}
            >
              <SvgIcon
                className={clsx(
                  styles.navIcon,
                  nav.isCurrentRoute ? styles.selected : styles.unselected,
                )}
              >
                {nav.icon}
              </SvgIcon>
            </Grid>
          </Link>,
        );
      }
    });
  }

  return (
    <AppBar className={styles.navBar} position="fixed">
      <Grid container alignItems="center" item>
        {!mobile && (
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            justify="center"
            className={styles.navHeader}
          >
            {hasLogo && (
              <Grid item container justify="center">
                <img
                  src={`${GetLinkBase()}${companyLogo}`}
                  alt={`${companyName}-logo`}
                  className={styles.logo}
                />
              </Grid>
            )}
            <Link to={GetRoute('settings').path} id="rewardId">
              <Grid
                item
                xs={12}
                className={styles.profilePicBacking}
                container
                justify="center"
                alignItems="center"
              >
                <Tooltip title={`You have ${activityScore} activity points`}>
                  <Badge
                    badgeContent={
                      <>
                        {activityScore}
                        <StarBorder fontSize="small" />
                      </>
                    }
                    max={1000000}
                    color="primary"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <img
                      src={
                        profileImage
                          ? `${GetLinkBase()}${profileImage}`
                          : GetIllustrationLink('noAvatar.png')
                      }
                      className={styles.profilePic}
                      alt="profile"
                    />
                  </Badge>
                </Tooltip>
              </Grid>
            </Link>
            <Grid item>
              <Text variant="xs" color="white" fontWeight={400} marginTop={20}>
                Welcome back,
              </Text>
              <Text variant="lg" fontWeight={600} color="white" align="center">
                {profileFirstName ?? firstName}
              </Text>
            </Grid>
            <Grid item container xs={8} justify="center">
              <LogoutButton onClick={() => BrancherDispatch(dispatch, UtilSignOut())} />
            </Grid>
          </Grid>
        )}
        <Grid
          container
          justify="center"
          direction={mobile ? 'row' : 'column'}
          alignItems="center"
          item
        >
          {config}
        </Grid>
      </Grid>
    </AppBar>
  );
};
