import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SuggestionCard } from '../../SuggestionCard';

export const IntroductionMentorStepOneIncorrect = () => {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          Not quite. The best option was{' '}
        </Text>
        <Text variant="md" fontWeight={700} color="purple" display="inline">
          B
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Although the onus is on Jordan to schedule the catch-ups as the mentee, if you haven't
          heard from them, it's good to give them a call. Try to unpack why the lead hasn’t been
          taken as much as you’d expected.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You could ask questions like:
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard>
          “How are you finding the program so far? What would you like to do next? When would you
          like to catch up?”
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
