import * as React from 'react';
import {
  EMoDTrainingModules,
  ReadableTrainingModules,
} from '../../../../store/reducerTypes/TrainingReducer.types';
import { ModuleWrapper } from '../ModuleWrapper';
import { MoDPowerOfReflectionMentorModuleMapper } from './MoDPowerOfReflectionMentorModuleMapper';

export const MoDPowerOfReflectionMentor = () => (
  <ModuleWrapper
    moduleMap={MoDPowerOfReflectionMentorModuleMapper}
    moduleName={EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTOR}
    title={ReadableTrainingModules[EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTOR]}
  />
);
