import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { SaveMoDBeGreatMenteeData, SaveMoDBeGreatMentorData } from '../../../../../store/actions';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const MoDBeGreatMenteeStepNine = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentor = sesPos === ProgramPositions.mentor;
  const modName = isMentor
    ? EMoDTrainingModules.MOD_BE_GREAT_MENTOR
    : EMoDTrainingModules.MOD_BE_GREAT_MENTEE;
  const saveFunction = isMentor ? SaveMoDBeGreatMentorData : SaveMoDBeGreatMenteeData;
  const stepNineValue = useSelector((state: IStoreTypes) => state.training?.[modName]?.StepNine);
  const dispatch = useDispatch();

  const checkboxOptions: string[] = [
    'I have the time, energy and drive to be an actively involved participant.',
    'I will maintain and respect confidentiality at all times.',
    'I will complete the Mentoring Agreement and respect agreed expectations and boundaries.',
    'I will maintain professional standards of behaviour and safety.',
    'I will treat others with courtesy, respect and non-judgement.',
    'I commit to fulfilling the mentoring expectations in this Guide.',
    'I will provide feedback, and be open to feedback.',
    'If I am travelling for extended periods of time (more than 4 weeks), I will advise my mentee/mentor.',
    'I recognise that all mentoring relationships will need to transition beyond this system and it is up to the mentor to establish clear boundaries as to what the relationship looks like (if anything) outside of the formal program.',
  ];

  const updateValue = (val: string[]) => {
    dispatch(
      saveFunction({
        StepNine: {
          selected: val,
          interacted: val?.length === checkboxOptions.length,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center">
        <Text variant="md" fontWeight={700}>
          Code of conduct
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Before proceeding further, please read through the following code of conduct which
          outlines what is expected of you whilst you are on this platform.
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepNineValue"
          value={stepNineValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(checkboxOptions)}
        />
      </Grid>
      <Grid item>
        <Text variant="md">By clicking next, you are agreeing to this code of conduct.</Text>
      </Grid>
    </Grid>
  );
};
