import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMakeTheMostMentee } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { BrancherButton } from '../../../../../Components/InputFields/BrancherButton';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { BrancherList } from '../../../../../Components/General/BrancherList';

export const MakeTheMostMenteeStepTwelve = () => {
  const stepTwelveValue = useSelector(
    (state: IStoreTypes) => state.training?.makeTheMostMentee?.StepTwelve,
  );
  const dispatch = useDispatch();
  const [checkAnswer, setCheckAnswer] = React.useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleCheckAnswer = () => {
    setCheckAnswer(!checkAnswer);
  };

  const updateValue = (val: string[]) => {
    const correctAnswers = ['0', '3'];
    dispatch(
      SaveMakeTheMostMentee({
        StepTwelve: {
          ...stepTwelveValue,
          quiz: val,
          correct: val?.includes(correctAnswers[0]) && val?.includes(correctAnswers[1]),
          interacted: val?.length > 0,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Quiz
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          How could your mentor support you to achieve your goal created on the previous page? You
          can choose more than one response.
        </Text>
      </Grid>
      <Grid item container direction="column" spacing={2}>
        <Grid item container xs={12}>
          <Grid item>
            <Text variant="md" fontWeight={700} display="inline">
              A.
            </Text>
          </Grid>
          <Grid item xs={11}>
            <Text variant="md" inlineBlock marginLeft={mobile ? 8 : 12}>
              My mentor has expertise with my goal, so I plan on developing an action plan with them
              and regularly checking in to monitor this goal progress
            </Text>
          </Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item container xs={12}>
            <Grid item>
              <Text variant="md" fontWeight={700} display="inline">
                B.
              </Text>
            </Grid>
            <Grid item xs={11}>
              <Text variant="md" inlineBlock marginLeft={mobile ? 8 : 12}>
                My mentor has expertise with my goal so I will ask them what I should do
              </Text>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item container xs={12}>
            <Grid item>
              <Text variant="md" fontWeight={700} display="inline">
                C.
              </Text>
            </Grid>
            <Grid item xs={11}>
              <Text variant="md" inlineBlock marginLeft={mobile ? 8 : 12}>
                My mentor has no expertise with my goal, so I plan on not sharing this goal with
                them
              </Text>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item container xs={12}>
            <Grid item>
              <Text variant="md" fontWeight={700} display="inline">
                D.
              </Text>
            </Grid>
            <Grid item xs={11}>
              <Text variant="md" inlineBlock marginLeft={mobile ? 8 : 12}>
                My mentor has no expertise with my goal however I will share my goal with them and
                still work on developing an action plan with their support
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You can choose more than one option.
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          noQuestion
          question=""
          name="quiz"
          value={stepTwelveValue?.quiz}
          options={MakeOptions(['A', 'B', 'C', 'D'])}
          updateValue={(a: string[]) => updateValue(a)}
          thirdWidth
        />
      </Grid>
      <Box display="flex" alignSelf="flex-end" maxWidth="fit-content">
        <BrancherButton
          color="primary"
          onClick={toggleCheckAnswer}
          disabled={!(stepTwelveValue?.quiz?.length > 0)}
        >
          Check answer
        </BrancherButton>
      </Box>
      {checkAnswer && (
        <Grid item>
          <Text variant="sm" fontWeight={700} marginBottom={20}>
            A and C are correct.
          </Text>
          <Text variant="sm" fontWeight={700} marginBottom={15}>
            If your mentor has expertise with what you want to work on...
          </Text>
          <BrancherList
            listItems={[
              <>
                <Text variant="sm" fontWeight={700} display="inline">
                  You should{' '}
                </Text>
                <Text variant="sm" display="inline">
                  develop an action plan with them and regularly check in on your goal to monitor
                  progress
                </Text>
              </>,
              <>
                <Text variant="sm" fontWeight={700} display="inline">
                  You should not{' '}
                </Text>
                <Text variant="sm" display="inline">
                  ask them to tell you what to do
                </Text>
              </>,
            ]}
          />
          <Text variant="sm" marginTop={10} marginBottom={15}>
            Remember, whilst it is valuable to hear your mentor's advice, you are in control of your
            own development. That means you should be collaboratively developing an action plan
            rather than just asking your mentor what to do.
          </Text>
          <Text variant="sm" fontWeight={700} marginBottom={15}>
            If you mentor does not have expertise with what you want to work on...
          </Text>
          <BrancherList
            listItems={[
              <>
                <Text variant="sm" fontWeight={700} display="inline">
                  You should{' '}
                </Text>
                <Text variant="sm" display="inline">
                  still share your goal with them and work on creating an action plan with their
                  support
                </Text>
              </>,
              <>
                <Text variant="sm" fontWeight={700} display="inline">
                  You should not{' '}
                </Text>
                <Text variant="sm" display="inline">
                  pursue your goal in isolation
                </Text>
              </>,
            ]}
          />
          <Text variant="sm" marginTop={10} marginBottom={25}>
            Even if your mentor does not have direct expertise with what you are working on, they
            are likely to still have a wealth of knowledge, experience and advice they can share to
            help you work towards this goal.
          </Text>
        </Grid>
      )}
    </Grid>
  );
};
