import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { MentoringGuideLink } from '../../../../../Components/General/MentoringGuideLink';
import { Text } from '../../../../../Components/General/Text';
import {
  EMoDTrainingModules,
  EStructuredTrainingModules,
} from '../../../../../store/reducerTypes/TrainingReducer.types';
import { GetTrainingResourceLink } from '../../../../../utils/LinkUtils';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const MoDBeGreatMenteeStepThree = () => {
  const isMentee =
    useSelector((state: IStoreTypes) => state.user.sessionPosition) === ProgramPositions.mentee;

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="lg" fontWeight={600}>
          Roles and responsibilities
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={400}>
          Mentors and mentees have some shared responsibilities, but there are also things that each
          is distinctly responsible for.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={400}>
          For example, the mentee is ultimately responsible for driving the relationship and their
          own development. The mentor is responsible for sharing expertise and advice, and
          supporting the mentee to problem solve and grow.
        </Text>
      </Grid>
      <Grid item>
        <BrancherLinkText
          underline="always"
          fontWeight={600}
          variant="md"
          href={GetTrainingResourceLink(
            EStructuredTrainingModules.INTRODUCTION_MENTEE,
            'mentor_vs_mentee_responsibilities.pdf',
          )}
          display="inline"
          target="_blank"
        >
          Click here
        </BrancherLinkText>
        <Text variant="md" display="inline" fontWeight={400}>
          {' '}
          for a quick comparison of responsibilities and read the 'what is expected of me' pages in
          the{' '}
        </Text>
        <MentoringGuideLink variant="md" />
        <Text variant="md" display="inline" fontWeight={400}>
          {' '}
          to understand the detail.
        </Text>
      </Grid>
      <Grid item>
        <BrancherTrainingImage
          src={GetTrainingResourceLink(
            isMentee
              ? EMoDTrainingModules.MOD_BE_GREAT_MENTEE
              : EMoDTrainingModules.MOD_BE_GREAT_MENTOR,
            'mentee-mentor-responsibilities-puzzle.png',
          )}
          alt="mentee-mentor-responsibilities-puzzle"
        />
      </Grid>
    </Grid>
  );
};
