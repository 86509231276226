import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMenteeData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';
import { SallyAvatar } from '../../avatars/Sally';

export const BeGreatMenteeStepEight = () => {
  const stepEightValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentee.StepEight,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepEightCorrectVal = '1';
    dispatch(
      SaveBeGreatMenteeData({
        StepEight: {
          selected: val,
          correct: StepEightCorrectVal === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <SallyAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Sally responds...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          Sally responds, "That's excellent. That's a really clear logical step that will pull you
          forward towards your goal. I wonder, have you seen someone else deal with this? What
          strategies have they used?"
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          You say, "There is someone else in my team but they don't seem to be frustrated with the
          lack of direction or feedback. Maybe they find other ways to seek this clarity. I suppose
          I could subtly find out what strategies they use to get the direction they need to
          succeed."
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Sally says, "That sounds like a good idea. In addition to that, what personal strengths do
          you have that could help you manage this situation. How could you use your personal
          strengths here?"
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          How do you respond?
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions optionHeader="A." optionText="Ahh... I'm not sure." />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="I'm quite direct, I could use that to clearly outline to my manager why I'd like to have more frequent catch ups."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="I have lots of strengths, I could use them all!"
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepEightValue"
          value={stepEightValue?.selected}
          updateValue={updateValue}
          noQuestion
          exclusive
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
