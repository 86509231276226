import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMoDGoalSettingMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const MoDGoalSettingMentorStepFive = () => {
  const stepFiveValue = useSelector(
    (state: IStoreTypes) => state.training?.[EMoDTrainingModules.MOD_GOAL_SETTING_MENTOR]?.StepFive,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepFiveCorrectVal = ['0', '1', '4'];
    dispatch(
      SaveMoDGoalSettingMentorData({
        StepFive: {
          selected: val,
          correct:
            val?.includes(StepFiveCorrectVal[0]) &&
            val?.includes(StepFiveCorrectVal[1]) &&
            val?.includes(StepFiveCorrectVal[2]),
        },
      }),
    );
  };

  const options: string[] = [
    'A. Specific',
    'B. Measurable',
    'C. Attainable',
    'D. Relevant',
    'E. Timely',
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center">
        <Text variant="lg" fontWeight={700}>
          Goal #1
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">Win a Nobel Prize in 6 months.</Text>
      </Grid>
      <Grid item>
        <Text variant="md">Is this goal...</Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepFiveValue"
          value={stepFiveValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(options)}
        />
      </Grid>
      <Grid item>
        <Text variant="sm" marginBottom={-30}>
          You can select more than 1 option.
        </Text>
      </Grid>
    </Grid>
  );
};
