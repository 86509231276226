import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import { SaveMoDSituationalMentorshipMentorData } from '../../../../../store/actions';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { SuggestionCard } from '../../SuggestionCard';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { NPSScale } from '../../sharedComps/NPSScale';
import { CompetenceScale } from '../../sharedComps/CompetenceScale';
import { ConfidenceScale } from '../../sharedComps/ConfidenceScale';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { CompetencePopover } from '../../sharedComps/CompetencePopover';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { GetTrainingResourceLink } from '../../../../../utils/LinkUtils';

export const MoDSituationalMentorshipMentorStepSeven = () => {
  const modName = EMoDTrainingModules.MOD_SITUATIONAL_MENTORSHIP_MENTOR;
  const StepSevenValue = useSelector((state: IStoreTypes) => state.training?.[modName]);
  const dispatch = useDispatch();
  const [anchorEl, setAnchor] = React.useState(null);
  const open = Boolean(anchorEl);

  const controlValidator = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!Boolean(anchorEl)) {
      setAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(anchorEl)) {
      setAnchor(null);
    }
  };

  const updateCompetence = (val: number) => {
    dispatch(
      SaveMoDSituationalMentorshipMentorData({
        StepSeven: {
          ...StepSevenValue?.StepSeven,
          competenceFinal: val,
          interacted:
            StepSevenValue?.rating != null &&
            StepSevenValue?.confidenceFinal != null &&
            val != null,
        },
        competenceFinal: val,
      }),
    );
  };

  const updateConfidence = (val: number) => {
    dispatch(
      SaveMoDSituationalMentorshipMentorData({
        StepSeven: {
          ...StepSevenValue?.StepSeven,
          confidenceFinal: val,
          interacted:
            StepSevenValue?.rating != null &&
            StepSevenValue?.competenceFinal != null &&
            val != null,
        },
        confidenceFinal: val,
      }),
    );
  };

  const updateRating = (val: number) => {
    dispatch(
      SaveMoDSituationalMentorshipMentorData({
        StepSeven: {
          ...StepSevenValue?.StepSeven,
          interacted:
            StepSevenValue?.competenceFinal != null &&
            StepSevenValue?.confidenceFinal != null &&
            val != null,
        },
        rating: val,
      }),
    );
  };

  const updateFeedback = (val: string) => {
    dispatch(
      SaveMoDSituationalMentorshipMentorData({
        StepSeven: {
          ...StepSevenValue?.StepSeven,
          interacted: Boolean(
            StepSevenValue?.rating != null &&
              StepSevenValue?.competenceFinal != null &&
              StepSevenValue?.confidenceFinal != null,
          ),
        },
        feedback: val,
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={600}>
          Well done on completing this training!
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" display="inline" fontWeight={600}>
          Now that you have completed this training, please rate your current level of{' '}
        </Text>
        <BrancherLinkText
          variant="md"
          underline="always"
          display="inline"
          onClick={controlValidator}
          href="#"
          fontWeight={600}
        >
          competence and confidence
        </BrancherLinkText>
        <Text variant="md" display="inline" fontWeight={600}>
          {' '}
          to provide situational mentorship:{' '}
        </Text>
        <CompetencePopover
          open={open}
          setClose={closeValidator}
          anchor={anchorEl}
          labelledBy="Competence and confidence"
        />
        <BrancherList
          variant="md"
          listItems={[
            'Know when to be directive, coach, support or challenge, depending on the situation',
            'Use conversation starters to help flex between different styles',
          ]}
          fontWeight={500}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={600} marginBottom={10}>
          Using the scale below, what is your competence rating now?
        </Text>
        <CompetenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepSevenValue?.StepSeven?.competenceFinal}
          valueLength={7}
          updateValue={updateCompetence}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={600} marginBottom={10}>
          Using the scale below, what is your confidence rating now?
        </Text>
        <ConfidenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepSevenValue?.StepSeven?.confidenceFinal}
          valueLength={7}
          updateValue={updateConfidence}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={600} marginBottom={10}>
          On a scale of 0 - 10, how likely are you to recommend this training to another colleague
          within this mentoring program?
        </Text>
        <NPSScale />
        <SegmentedControlGroup
          fullWidth
          value={StepSevenValue?.rating}
          valueLength={11}
          updateValue={updateRating}
          startFromZero
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={600}>
          Do you have any other feedback for us?
        </Text>
        <BrancherTextField
          value={StepSevenValue?.feedback}
          updateValue={(a: string) => updateFeedback(a)}
          placeholder="Enter here"
          maxChars={400}
          id="feedback"
          name="feedback"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={600}>
          Next steps:
        </Text>
      </Grid>
      <Box marginLeft={2}>
        <BrancherList
          fontWeight={400}
          variant="md"
          listItems={[
            <>
              <Text variant="md">
                Save a copy of these resources and refer to them for future use:
              </Text>
              <BrancherLinkText
                variant="md"
                target="_blank"
                underline="always"
                href={GetTrainingResourceLink(modName, 'GROWTH_framework.pdf')}
              >
                Coaching GROWTH framework
              </BrancherLinkText>
              <BrancherLinkText
                marginBottom={10}
                marginTop={10}
                variant="md"
                target="_blank"
                underline="always"
                href={GetTrainingResourceLink(modName, 'situational_mentorship_guide.pdf')}
              >
                Situational Mentorship Guide
              </BrancherLinkText>
            </>,
            <Text variant="md">
              Reflect on this module and what you learnt. Plan how you can apply these learnings in
              your next mentoring meeting
            </Text>,
          ]}
        />
      </Box>
      <Grid item xs={12}>
        <SuggestionCard>
          Make sure you click the "✓" button to complete this training.
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
