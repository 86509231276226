import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';

export const IntroductionMenteeStepFourIncorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          That's not the best answer.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Remember, as your mentor, Alex is responsible for helping you grow personally and
          professionally, not for finding you a job. Asking your mentor for introductions can put
          them in an uncomfortable position.
        </Text>
      </Grid>
    </Grid>
  );
};
