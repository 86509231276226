import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { EContentType, IPrivateNote } from '../../../../../store/reducers/PrivateNotesReducer';
import { IStoreTypes } from '../../../../../store/storeTypes';
import {
  BrancherDispatch,
  SaveMakeTheMostMentee,
  SaveMakeTheMostMentor,
  UtilSaveUserPrivateNotesData,
  UtilSaveUserSharedNotesData,
} from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { FormWithStepFieldLayout } from '../../../../../Components/InputFields/FormWithStepFieldLayout';
import { dialogValues, KolbModelInfo } from './KolbModelInfo';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveButton } from '../../../../../Components/InputFields/BrancherButton';
import { BrancherQuestionMarker } from '../../../../../Components/General/BrancherQuestionMarker';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { SuggestionCard } from '../../SuggestionCard';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';

export const MakeTheMostMenteeStepTwo = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentee = position === ProgramPositions.mentee;
  const partnerRole = isMentee ? ProgramPositions.mentor : ProgramPositions.mentee;
  const sessionStore = isMentee
    ? EStructuredTrainingModules.MAKE_THE_MOST_MENTEE
    : EStructuredTrainingModules.MAKE_THE_MOST_MENTOR;
  const stepTwoValue = useSelector((state: IStoreTypes) => state.training?.[sessionStore]?.StepTwo);
  const dispatch = useDispatch();
  const [currentOpenDialog, setCurrentOpenDialog] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const controlDialog = (section: string) => {
    setCurrentOpenDialog(section);
    setOpen(true);
  };

  const saveNote = () => {
    const apiCall =
      stepTwoValue.sharePublic === '0' ? UtilSaveUserPrivateNotesData : UtilSaveUserSharedNotesData;
    const noteData: IPrivateNote = {
      contents: [
        {
          content: [
            stepTwoValue[dialogValues[0]],
            stepTwoValue[dialogValues[1]],
            stepTwoValue[dialogValues[2]],
            stepTwoValue[dialogValues[3]],
          ] as string[] & string,
          type: EContentType.LIST,
        },
      ],
      title: 'Make the most KOLB Model',
      lastModified: Date.now(),
    };
    BrancherDispatch(
      dispatch,
      apiCall(noteData, () => {
        updateValue('savedNote', true);
      }),
    );
  };

  const updateValue = (attr: string, val: string | boolean) => {
    const sessionSaveFunction =
      position === ProgramPositions.mentee ? SaveMakeTheMostMentee : SaveMakeTheMostMentor;
    const newStepTwoVal = { ...stepTwoValue, [attr]: val };
    dispatch(
      sessionSaveFunction({
        StepTwo: {
          ...newStepTwoVal,
          interacted: Boolean(
            newStepTwoVal[dialogValues[0]] &&
              newStepTwoVal[dialogValues[1]] &&
              newStepTwoVal[dialogValues[2]] &&
              newStepTwoVal[dialogValues[3]] &&
              newStepTwoVal.savedNote,
          ),
        },
      }),
    );
  };

  const QuestionMarker = (section: string): React.ReactNode => (
    <BrancherQuestionMarker onClick={() => controlDialog(section)} />
  );

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md">
          Utilising the Kolb Model, reflect on the first meeting with your{' '}
          {position === ProgramPositions.peer
            ? ProgramPositions.peer
            : position === ProgramPositions.mentee
            ? ProgramPositions.mentor
            : ProgramPositions.mentee}
          :
        </Text>
      </Grid>
      <Grid item>
        <FormWithStepFieldLayout
          step={1}
          question="Experience"
          labelIcon={QuestionMarker(dialogValues[0])}
        >
          <BrancherTextField
            name="experience"
            placeholder="Enter here"
            value={stepTwoValue?.experience}
            updateValue={(f: string) => updateValue(dialogValues[0], f)}
            fullWidth
            maxChars={200}
          />
        </FormWithStepFieldLayout>
      </Grid>
      <Grid item>
        <FormWithStepFieldLayout
          step={2}
          question="Reflect"
          labelIcon={QuestionMarker(dialogValues[1])}
        >
          <BrancherTextField
            name="reflect"
            placeholder="Enter here"
            value={stepTwoValue?.reflect}
            updateValue={(f: string) => updateValue(dialogValues[1], f)}
            fullWidth
            maxChars={200}
          />
        </FormWithStepFieldLayout>
      </Grid>
      <Grid item>
        <FormWithStepFieldLayout
          step={3}
          question="Evaluate"
          labelIcon={QuestionMarker(dialogValues[2])}
        >
          <BrancherTextField
            name="evaluate"
            placeholder="Enter here"
            value={stepTwoValue?.evaluate}
            updateValue={(f: string) => updateValue(dialogValues[2], f)}
            fullWidth
            maxChars={200}
          />
        </FormWithStepFieldLayout>
      </Grid>
      <Grid item>
        <FormWithStepFieldLayout
          step={4}
          question="Plan"
          labelIcon={QuestionMarker(dialogValues[3])}
        >
          <BrancherTextField
            name="plan"
            placeholder="Enter here"
            value={stepTwoValue?.plan}
            updateValue={(f: string) => updateValue(dialogValues[3], f)}
            fullWidth
            maxChars={200}
          />
        </FormWithStepFieldLayout>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Would you like to keep this content private or share with your {partnerRole} in your
          shared notes?
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard>
          Whilst sharing this information is optional, we do encourage it. Sharing this information
          can help you and your mentoring partner to reflect, evaluate and plan.
        </SuggestionCard>
      </Grid>
      <Grid item>
        <SelectionControl
          noQuestion
          question=""
          name="sharePublic"
          value={stepTwoValue?.sharePublic}
          exclusive
          options={MakeOptions(['Private', 'Partner only'])}
          updateValue={(a: string) => updateValue('sharePublic', a)}
        />
        <SaveButton
          onClick={saveNote}
          disabled={!stepTwoValue?.sharePublic || stepTwoValue?.savedNote}
        >
          Create note
        </SaveButton>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Well done on completing your first reflection!
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" marginBottom={20}>
          It is good to stay in the habit of this. Consider writing a reflection for every 10 hours
          of professional development you engage in. You may also want to reflect using the Kolb
          Model after each meeting with your {position === 'mentee' ? 'mentor' : 'mentee'}.
        </Text>
      </Grid>
      <KolbModelInfo controlDialog={setOpen} open={open} component={currentOpenDialog} />
    </Grid>
  );
};
