import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { PowerOfReflectionMenteeStepOne } from './Steps/PowerOfReflectionMenteeStepOne';
import { PowerOfReflectionMenteeStepTwo } from './Steps/PowerOfReflectionMenteeStepTwo';
import { PowerOfReflectionMenteeStepThree } from './Steps/PowerOfReflectionMenteeStepThree';

export const PowerOfReflectionMenteeModuleMapper: IModuleMapper[] = [
  {
    name: 'MoDPowerOfReflectionMenteeStepOne',
    component: <PowerOfReflectionMenteeStepOne />,
    iterateStepper: true,
    needsInteraction: true,
    nextComponent: 'MoDPowerOfReflectionMenteeStepTwo',
  },
  {
    name: 'MoDPowerOfReflectionMenteeStepTwo',
    component: <PowerOfReflectionMenteeStepTwo />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MoDPowerOfReflectionMenteeStepOne',
    nextComponent: 'MoDPowerOfReflectionMenteeStepThree',
  },
  {
    name: 'MoDPowerOfReflectionMenteeStepThree',
    component: <PowerOfReflectionMenteeStepThree />,
    iterateStepper: true,
    needsInteraction: true,
    submit: true,
    previousComponent: 'MoDPowerOfReflectionMenteeStepTwo',
  },
];
