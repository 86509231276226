import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMakeTheMostMentee, SaveMakeTheMostMentor } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { BrancherButton } from '../../../../../Components/InputFields/BrancherButton';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const MakeTheMostMenteeStepFive = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const sessionStore =
    position === ProgramPositions.mentee ? 'makeTheMostMentee' : 'makeTheMostMentor';
  const stepFiveValue = useSelector((state: IStoreTypes) => state.training[sessionStore]?.StepFive);
  const dispatch = useDispatch();
  const [checkAnswer, setCheckAnswer] = React.useState(false);

  const toggleCheckAnswer = () => {
    setCheckAnswer(!checkAnswer);
  };

  const updateValue = (val: string[]) => {
    const sessionSaveFunction =
      position === ProgramPositions.mentee ? SaveMakeTheMostMentee : SaveMakeTheMostMentor;
    const correctAnswers = ['0', '1', '4'];
    dispatch(
      sessionSaveFunction({
        StepFive: {
          ...stepFiveValue,
          goalOne: val,
          correct:
            val.includes(correctAnswers[0]) &&
            val.includes(correctAnswers[1]) &&
            val.includes(correctAnswers[2]),
          interacted: val.length > 0,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Goal #1:
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">I am going to win a Nobel Prize in 6 months.</Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Is this goal...
        </Text>
      </Grid>
      <Grid item container direction="column">
        <Grid item>
          <Text variant="md" fontWeight={700} display="inline">
            A.{' '}
          </Text>
          <Text variant="md" display="inline">
            Specific
          </Text>
        </Grid>
        <Grid item>
          <Text variant="md" fontWeight={700} display="inline">
            B.{' '}
          </Text>
          <Text variant="md" display="inline">
            Measurable
          </Text>
        </Grid>
        <Grid item>
          <Text variant="md" fontWeight={700} display="inline">
            C.{' '}
          </Text>
          <Text variant="md" display="inline">
            Attainable
          </Text>
        </Grid>
        <Grid item>
          <Text variant="md" fontWeight={700} display="inline">
            D.{' '}
          </Text>
          <Text variant="md" display="inline">
            Relevant
          </Text>
        </Grid>
        <Grid item>
          <Text variant="md" fontWeight={700} display="inline">
            E.{' '}
          </Text>
          <Text variant="md" display="inline">
            Timely
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You can choose more than one option.
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          noQuestion
          question=""
          name="goalOne"
          value={stepFiveValue?.goalOne}
          options={MakeOptions(['A', 'B', 'C', 'D', 'E'])}
          updateValue={(a: string[]) => updateValue(a)}
          thirdWidth
        />
      </Grid>
      <Box display="flex" alignSelf="flex-end" maxWidth="fit-content">
        <BrancherButton
          color="primary"
          onClick={toggleCheckAnswer}
          disabled={!(stepFiveValue?.goalOne?.length > 0)}
        >
          Check answer
        </BrancherButton>
      </Box>
      {checkAnswer && (
        <Grid item>
          <Text variant="sm" fontWeight={700} marginBottom={20}>
            The correct answer is A, B and E
          </Text>
          <Text variant="sm" display="inline">
            Whilst the goal may be{' '}
          </Text>
          <Text variant="sm" fontWeight={700} display="inline" color="purple">
            specific
          </Text>
          <Text variant="sm" display="inline">
            ,{' '}
          </Text>
          <Text variant="sm" fontWeight={700} display="inline" color="purple">
            measurable
          </Text>
          <Text variant="sm" display="inline">
            {' '}
            and{' '}
          </Text>
          <Text variant="sm" fontWeight={700} display="inline" color="purple">
            timely
          </Text>
          <Text variant="sm" display="inline">
            , the individual may want to consider whether the goal is actually{' '}
          </Text>
          <Text variant="sm" fontWeight={700} display="inline" color="purple">
            attainable
          </Text>
          <Text variant="sm" display="inline">
            {' '}
            given their circumstances. There is not enough information about the situation to
            determine if the goal is{' '}
          </Text>
          <Text variant="sm" fontWeight={700} display="inline" color="purple">
            relevant
          </Text>
          <Text variant="sm" display="inline">
            .
          </Text>
        </Grid>
      )}
    </Grid>
  );
};
