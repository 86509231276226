import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { SaveButton } from '../../../../../Components/InputFields/BrancherButton';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import {
  BrancherDispatch,
  SaveMoDGoalSettingMentorData,
  UtilGetGoals,
  UtilSaveGoals,
} from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { EGoalsBoardColumns, IGoalsReducer } from '../../../../../store/reducers/GoalsReducer';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const MoDGoalSettingMentorStepTen = () => {
  const stepTenValue = useSelector(
    (state: IStoreTypes) => state.training.modGoalSettingMentor?.StepTen,
  );
  const userGoals = useSelector((state: IStoreTypes) => state?.goals);
  const dispatch = useDispatch();

  // pre-fetch the goals, so we can add one to the backlog
  // TODO: Should make the goals API more modular/partial
  React.useEffect(() => {
    BrancherDispatch(
      dispatch,
      UtilGetGoals(() => {}),
    );
  }, []);

  const updateValue = (attr: string, val: string | string[] | boolean) => {
    const newStepTenVal = { ...stepTenValue, [attr]: val };
    dispatch(
      SaveMoDGoalSettingMentorData({
        StepTen: {
          ...newStepTenVal,
          interacted:
            newStepTenVal?.smartConsiderations?.length > 0 &&
            !!newStepTenVal?.newGoal &&
            !!newStepTenVal?.goalSaved,
        },
      }),
    );
  };

  const saveGoal = () => {
    const goalsData: IGoalsReducer = {
      ...userGoals,
      [EGoalsBoardColumns.BACKLOG]: [
        {
          id: uuidv4(),
          goal: stepTenValue?.newGoal,
          lastModified: Date.now(),
        },
        ...userGoals?.[EGoalsBoardColumns.BACKLOG],
      ],
    };
    BrancherDispatch(
      dispatch,
      UtilSaveGoals(goalsData, () => {
        updateValue('goalSaved', true);
      }),
    );
  };

  const reflectionQuestions: string[] = [
    'Where are you in your career now and where do you want to be?',
    'What would need to change to further improve your quality of life?',
    'Are there any mentoring /coaching skills you want to develop or get better at?',
  ];

  const disableSaveGoals = (): boolean => {
    return (
      !stepTenValue?.newGoal ||
      stepTenValue?.smartConsiderations?.length === 0 ||
      !stepTenValue?.smartConsiderations ||
      stepTenValue?.goalSaved
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Creating your own goal.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          As a mentor, it’s important you set goals for yourself as well. We encourage you to share
          your goals with your mentee. This shows you’re human and builds trust.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Research shows you are{' '}
        </Text>
        <BrancherLinkText
          variant="md"
          underline="always"
          target="_blank"
          display="inline"
          href="http://www.goalband.co.uk/uploads/1/0/6/5/10653372/gail_matthews_research_summary.pdf"
        >
          42%
        </BrancherLinkText>
        <Text variant="md" display="inline">
          {' '}
          more likely to achieve your goal if you write it down.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Try to be specific and develop a goal using the SMART principles that we will review
          throughout the program.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={500}>
          Not sure where to start? Reflect on these questions:
        </Text>
        <BrancherList fontWeight={500} listItems={reflectionQuestions} />
      </Grid>
      <Grid item>
        <Text variant="sm">
          The goal you write here will be automatically visible to your mentoring partner.
        </Text>
      </Grid>
      <Grid item>
        <BrancherTextField
          value={stepTenValue?.newGoal}
          fullWidth
          multiline={true}
          rowsMax={8}
          placeholder="Your goal..."
          name="newGoal"
          updateValue={(a: string) => updateValue('newGoal', a)}
          maxChars={400}
        />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Before submitting, consider whether the goal you have created is:
        </Text>
        <Text variant="xs" fontWeight={400}>
          Select the boxes that apply to your goal
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          noQuestion
          question=""
          name="smartConsiderations"
          value={stepTenValue?.smartConsiderations}
          options={MakeOptions(['Specific', 'Measurable', 'Attainable', 'Relevant', 'Timely'])}
          updateValue={(a: string[]) => updateValue('smartConsiderations', a)}
        />
      </Grid>
      <Grid item>
        <SaveButton onClick={saveGoal} disabled={disableSaveGoals()}>
          Save goal
        </SaveButton>
      </Grid>
    </Grid>
  );
};
