import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMenteeData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';
import { SallyAvatar } from '../../avatars/Sally';

export const BeGreatMenteeStepTen = () => {
  const stepTenValue = useSelector((state: IStoreTypes) => state.training.beGreatMentee.StepTen);
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepTenCorrectVal = ['0', '1', '2'];
    dispatch(
      SaveBeGreatMenteeData({
        StepTen: {
          selected: val,
          correct:
            val.length === 3 &&
            StepTenCorrectVal.includes(val[0]) &&
            StepTenCorrectVal.includes(val[1]) &&
            StepTenCorrectVal.includes(val[2]),
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <SallyAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Sally asks a couple of final questions...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          "How confident are you that you can do all these action steps? How can you keep track of
          your progress over time?"
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          You say, "There is someone else in my team but they don't seem to be frustrated with the
          lack of direction or feedback. Maybe they find other ways to seek this clarity. I suppose
          I could subtly find out what strategies they use to get the direction they need to
          succeed."
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Sally says, "That sounds like a good idea. In addition to that, what personal strengths do
          you have that could help you manage this situation. How could you use your personal
          strengths here?"
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          How do you respond? <br />
          You can choose more than one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="I'd say I'm reasonably confident. To help me track my progress and stay accountable, I could talk to you about my progress next meeting?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="I feel pretty good about having the conversation. I can track my progress by reflecting on my levels of frustration with my manager and seeing if there's a difference!"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="I'd say I'm 90% confident, thank you for talking me through this. I could set some SMART goals to help me track my progress?"
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepTenValue"
          value={stepTenValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
