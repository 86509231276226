import * as React from 'react';
import { ModuleWrapper } from '../ModuleWrapper';
import { GivingReceivingFeedbackMenteeModuleMapper } from './GivingReceivingFeedbackMenteeModuleMapper';

export const GivingReceivingFeedbackMentee = () => (
  <ModuleWrapper
    moduleMap={GivingReceivingFeedbackMenteeModuleMapper}
    moduleName="givingReceivingFeedbackMentee"
    title="Effective feedback"
  />
);
