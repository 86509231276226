import RalewayRegular from './fonts/Raleway-Regular.ttf';
import RalewayMedium from './fonts/Raleway-Medium.ttf';
import RalewaySemiBold from './fonts/Raleway-SemiBold.ttf';
import RalewayBold from './fonts/Raleway-Bold.ttf';
import RalewayExtraBold from './fonts/Raleway-ExtraBold.ttf';

export const ralewayFontRegular = {
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${RalewayRegular}) format('truetype');
  `,
};

export const ralewayFontMedium = {
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    url(${RalewayMedium}) format('truetype');
  `,
};

export const ralewayFontSemiBold = {
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    url(${RalewaySemiBold}) format('truetype');
  `,
};

export const ralewayFontBold = {
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    url(${RalewayBold}) format('truetype');
  `,
};

export const ralewayFontExtraBold = {
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 800,
  src: `
    url(${RalewayExtraBold}) format('truetype');
  `,
};

export const MuiTypography = {
  fontFamily: ['Raleway', 'sans-serif'].join(','),
};
