import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { CharlieAvatar } from '../../avatars/Charlie';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';

export const MoDSituationalMentorshipMentorStepThreeResult = () => {
  const modName = EMoDTrainingModules.MOD_SITUATIONAL_MENTORSHIP_MENTOR;
  const correct = useSelector(
    (state: IStoreTypes) => state.training?.[modName]?.StepThree?.correct,
  );
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={correct} incorrect={!correct}>
          <Text variant="md" fontWeight={700}>
            {correct ? 'Correct' : 'Not quite'}. The correct answer is A and C.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          <Grid item>
            <Text variant="sm" fontWeight={700}>
              Depending on your style, either A or C could be used.
            </Text>
          </Grid>
          <Grid item>
            <Text variant="sm">
              Explaining the reason behind your direction makes it easier for people to understand
              your perspective.
            </Text>
          </Grid>
          <Grid item>
            <Text variant="sm" fontWeight={700} display="inline">
              Why B is incorrect:{' '}
            </Text>
            <Text variant="sm" display="inline">
              Although Google is a great resource, when your mentee is feeling the pressure, they
              may be disappointed if you refer them to Google.
            </Text>
          </Grid>
          <Grid item>
            <Text variant="sm" fontWeight={700} display="inline">
              Why D is incorrect:{' '}
            </Text>
            <Text variant="sm" display="inline">
              This is a coaching question rather than a 'directive' question.
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
