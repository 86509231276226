import * as React from 'react';
import { useLocation } from 'react-router';
import LockOpen from '@material-ui/icons/LockOpen';
import { useDispatch } from 'react-redux';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import {
  IUtilSignUpUserResponse,
  SaveUserInfo,
  UtilSSOSAMLSignUp,
} from '../../store/actions/UserInfoActions';
import { ESessionAttributes, getSessionAttribute } from '../../utils/cookies';

interface ISSOSignUp {
  controlSSOUser: (res: IUtilSignUpUserResponse) => void;
  samlRedirectUri: string;
}

export const SAMLSignUp: React.FC<ISSOSignUp> = ({ controlSSOUser, samlRedirectUri }) => {
  const programId = getSessionAttribute(ESessionAttributes.SIGNUP_PROGRAM_ID);
  const locationHash = useLocation()?.hash?.substring(1);
  const params = new URLSearchParams(locationHash);
  const [signingUpSAMLUser, setSigningUpSAMLUser] = React.useState<boolean>(false);
  const idToken = params?.get('id_token');
  const dispatch = useDispatch();

  const redirectToSAMLUri = () => {
    window.location.assign(samlRedirectUri);
  };

  React.useEffect(() => {
    if (!!idToken) {
      const originState = params?.get('state');
      const redirect = originState !== window.location.origin;
      if (redirect) {
        window.location.replace(`${originState}${window.location.pathname}#${locationHash}`);
      }
      setSigningUpSAMLUser(true);
      dispatch(
        UtilSSOSAMLSignUp(idToken, programId, (res) => {
          dispatch(SaveUserInfo({ IDToken: idToken }));
          controlSSOUser(res);
          setSigningUpSAMLUser(false);
          params.delete('id_token');
          params.delete('state');
        }),
      );
    }
  }, [idToken]);

  React.useEffect(() => {
    if (!idToken) {
      redirectToSAMLUri();
    }
  }, []);

  return (
    <BrancherButton
      startIcon={<LockOpen />}
      onClick={redirectToSAMLUri}
      disabled={!!idToken || signingUpSAMLUser}
    >
      Single sign-on
    </BrancherButton>
  );
};
