import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { Colors } from './consts/colors';
import { Routes } from './Components/Routing/';
import { AcceptCookies } from './Components/General/AcceptCookies';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    overflowX: 'hidden',
    background: Colors.white,
  },
  main: {
    minHeight: 'calc(100vh - 50px)',
  },
});

export const Root = () => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <BrowserRouter>
        <AcceptCookies />
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={styles.main}
        >
          <Routes />
        </Grid>
      </BrowserRouter>
    </div>
  );
};
