import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { Text } from '../../../../../Components/General/Text';
import { CheckAnswerSection } from '../../../../../Components/General/CheckAnswerSection';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const EasyEnglishMenteeStepThreeCorrect = () => {
  const stepThreeValue = useSelector(
    (state: IStoreTypes) => state.training.easyEnglishMentee.StepThree,
  );
  return (
    <Grid container direction="column" spacing={4}>
      <CheckAnswerSection correct={stepThreeValue.correct}>
        <Text variant="sm" fontWeight={700} marginBottom={20}>
          {stepThreeValue.correct
            ? 'Correct. All of those options are correct'
            : 'Not quite. All of the options were correct'}
          .
        </Text>
        <BrancherList
          listItems={[
            'Set goals',
            'Write down your ideas',
            'Complete the tasks your mentor set for you',
            'Complete the Brancher training',
            'Prepare for next meeting',
          ]}
        />
      </CheckAnswerSection>
    </Grid>
  );
};
