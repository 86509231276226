import { Colors } from '../consts/colors';

export const MuiCheckbox = {
  root: {
    color: Colors.lightGrey,
    '& .Mui-checked': {
      '& .MuiSvgIcon-root': {
        color: Colors.purple,
      },
    },
  },
};
