import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import DragIndicator from '@material-ui/icons/DragIndicator';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Colors } from '../../consts/colors';
import {
  EGoalsBoardColumns,
  EGoalsBoardColumnsHeadings,
  IGoal,
} from '../../store/reducers/GoalsReducer';
import { AddButton } from '../InputFields/BrancherButton';
import { BrancherDivider } from './BrancherDivider';
import { Text } from './Text';

interface IDraggableColumn {
  columnId: EGoalsBoardColumns;
  heading: EGoalsBoardColumnsHeadings;
  addGoal: (goalType: EGoalsBoardColumns) => void;
  editGoal: (goalType: EGoalsBoardColumns, goalId: string) => void;
  columnItems?: IGoal[];
}

export const DraggableGoalColumn: React.FC<IDraggableColumn> = (props) => {
  const { columnItems, columnId, heading, addGoal, editGoal } = props;

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    minHeight: 'fit-content',
    overflowY: 'auto',
    borderRadius: 15,
    padding: 20,
    userSelect: 'none',
    margin: `0 0 12px 0`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    boxShadow: `0px 3px 8px ${Colors.cardBorder}`,
    cursor: isDragging ? 'move' : 'pointer',
    background: isDragging ? Colors.backgroundLightPurple : Colors.white,
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? Colors.backgroundLightBlue : Colors.backgroundDarkerPurple,
    padding: 8,
    width: '100%',
    borderRadius: 15,
  });

  return (
    <Grid item container direction="column" xs={12} md={4}>
      <Grid item container justify="space-between" alignItems="center">
        <Text variant="sm" fontWeight={600} color="purple">
          {heading}
        </Text>
      </Grid>
      <Grid item>
        <BrancherDivider marginBottom={20} marginTop={10} height={1} />
      </Grid>

      <Droppable droppableId={columnId}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            {columnItems?.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(providedColumn, snapshotColumn) => (
                  <div
                    onClick={() => editGoal(columnId, item.id)}
                    ref={providedColumn.innerRef}
                    {...providedColumn.draggableProps}
                    {...providedColumn.dragHandleProps}
                    style={getItemStyle(
                      snapshotColumn.isDragging,
                      providedColumn.draggableProps.style,
                    )}
                  >
                    <Grid item>
                      <Text variant="sm" fontWeight={500} color="secondaryGrey">
                        {item.goal}
                      </Text>
                    </Grid>
                    <Grid item>
                      <DragIndicator color="secondary" />
                    </Grid>
                  </div>
                )}
              </Draggable>
            ))}
            <Tooltip
              title={`Add ${heading.toLowerCase()} goal`}
              arrow
              aria-label={`Add ${heading.toLowerCase()} goal`}
            >
              <Box mt={2} maxWidth="fit-content">
                <AddButton onClick={() => addGoal(columnId)} />
              </Box>
            </Tooltip>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Grid>
  );
};
