import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { QuestionHeader } from '../FormFieldTypes/QuestionHeader';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { Text } from '../../Components/General/Text';
import { BrancherList } from '../../Components/General/BrancherList';
import { PersonalityScaleDescription } from './PersonalityScaleDescription';
import { IStoreTypes } from '../../store/storeTypes';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';

export const PersonalityInfo = () => {
  const sessionPosition = useSelector((state: IStoreTypes) => state.user?.sessionPosition);
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const isMentee = sessionPosition === ProgramPositions.mentee;
  const otherPosition = isMentee ? ProgramPositions.mentor : ProgramPositions.mentee;
  const partnerPosition = roleLabels[otherPosition];

  return (
    <>
      <Grid item xs={12}>
        <QuestionHeader>
          Now we will be asking you questions to understand your behavioural preferences and style.
        </QuestionHeader>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md">
          We ask about your personality so we can take this into account when considering your ideal{' '}
          {partnerPosition}.
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={600} marginTop={30}>
          Tips:
        </Text>
        <BrancherList
          listItems={[
            "Answer honestly, even if you don't like the answer.",
            'There are no right or wrong answers.',
            'Describe your behavioural preferences as they are now, not as you wish to be in the future.',
            'Your spontaneous answer is usually the most accurate.',
          ]}
        />
        <BrancherDivider marginTop={30} marginBottom={40} />
      </Grid>
      <Box mb={5} width="100%">
        <Grid container>
          <PersonalityScaleDescription />
        </Grid>
      </Box>
    </>
  );
};
