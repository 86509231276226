import * as React from 'react';
import { useSelector } from 'react-redux';
import { EStructuredTrainingModules } from '../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../store/storeTypes';
import { IModuleMapper } from '../../Modules/ModuleMapper';
import { SurveyStepFiveHaveMet } from './Steps/SurveyStepFiveHaveMet';
import { SurveyStepFourHaveMet } from './Steps/SurveyStepFourHaveMet';
import { SurveyStepFourHaveNotMet } from './Steps/SurveyStepFourHaveNotMet';
import { SurveyStepOne } from './Steps/SurveyStepOne';
import { SurveyStepSeven } from './Steps/SurveyStepSeven';
import { SurveyStepSix } from './Steps/SurveyStepSix';
import { SurveyStepThreeHaveMet } from './Steps/SurveyStepThreeHaveMet';
import { SurveyStepThreeHaveNotMet } from './Steps/SurveyStepThreeHaveNotMet';
import { SurveyStepTwoHaveMet } from './Steps/SurveyStepTwoHaveMet';
import { SurveyStepTwoHaveNotMet } from './Steps/SurveyStepTwoHaveNotMet';

export const BaseSurveyOneModuleMapperStart: IModuleMapper[] = [
  {
    name: 'SurveyStepOne',
    component: <SurveyStepOne />,
    iterateStepper: true,
    needsInteraction: true,
    nextComponent: 'SurveyStepTwo',
  },
];

export const BaseSurveyOneModuleMapperEnd: IModuleMapper[] = [
  {
    name: 'SurveyStepFive',
    component: <SurveyStepFiveHaveMet />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'SurveyStepFour',
    nextComponent: 'SurveyStepSix',
  },
  {
    name: 'SurveyStepSix',
    component: <SurveyStepSix />,
    iterateStepper: true,
    contentScreen: true,
    previousComponent: 'SurveyStepFive',
    nextComponent: 'SurveyStepSeven',
  },
  {
    name: 'SurveyStepSeven',
    component: <SurveyStepSeven />,
    iterateStepper: true,
    contentScreen: true,
    submit: true,
    previousComponent: 'SurveyStepSix',
  },
];

export const BaseSurveyOneModuleMapperHaveMet: IModuleMapper[] = [
  ...BaseSurveyOneModuleMapperStart,
  {
    name: 'SurveyStepTwo',
    component: <SurveyStepTwoHaveMet />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'SurveyStepOne',
    nextComponent: 'SurveyStepThreeHaveMet',
  },
  {
    name: 'SurveyStepThreeHaveMet',
    component: <SurveyStepThreeHaveMet />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'SurveyStepTwo',
    nextComponent: 'SurveyStepFour',
  },
  {
    name: 'SurveyStepFour',
    component: <SurveyStepFourHaveMet />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'SurveyStepThreeHaveMet',
    nextComponent: 'SurveyStepFive',
  },
  ...BaseSurveyOneModuleMapperEnd,
];

export const BaseSurveyOneModuleMapperHaveNotMet: IModuleMapper[] = [
  ...BaseSurveyOneModuleMapperStart,
  {
    name: 'SurveyStepTwo',
    component: <SurveyStepTwoHaveNotMet />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'SurveyStepOne',
    nextComponent: 'SurveyStepThreeHaveNotMet',
  },
  {
    name: 'SurveyStepThreeHaveNotMet',
    component: <SurveyStepThreeHaveNotMet />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'SurveyStepTwo',
    nextComponent: 'SurveyStepFour',
  },
  {
    name: 'SurveyStepFour',
    component: <SurveyStepFourHaveNotMet />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'SurveyStepThreeHaveNotMet',
    nextComponent: 'SurveyStepFive',
  },
  ...BaseSurveyOneModuleMapperEnd,
];

export const SurveyOneModuleMapper = (): IModuleMapper[] => {
  const StepOneFilterValue = useSelector(
    (state: IStoreTypes) => state.training?.[EStructuredTrainingModules.SURVEY_ONE]?.StepOne,
  );
  return StepOneFilterValue?.haveYouMet === undefined || StepOneFilterValue?.haveYouMet === '0'
    ? BaseSurveyOneModuleMapperHaveMet
    : BaseSurveyOneModuleMapperHaveNotMet;
};
