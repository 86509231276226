import React, { ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import ExpansionPanel, { ExpansionPanelProps } from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Text } from './Text';

interface IBrancherAccordion extends ExpansionPanelProps {
  panels: Array<{
    title: string;
    name: string;
    content: ReactElement;
  }>;
}

export const BrancherAccordion: React.FC<IBrancherAccordion> = (props) => {
  const { panels, ...other } = props;
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box width="100%">
      {panels?.map((panel, panelIndex) => (
        <ExpansionPanel
          {...other}
          key={panelIndex}
          expanded={expanded === panel.name}
          onChange={handleChange(panel.name)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${panel.name}-content`}
            id={`${panel.name}-header`}
          >
            <Text variant="md" fontWeight={500}>
              {panel.title}
            </Text>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>{panel.content}</ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </Box>
  );
};
