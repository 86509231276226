import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RouteLeavingGuard } from '../../Components/Routing/RouteLeavingGuard';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { BrancherSnackbar } from '../../Components/General/BrancherSnackbar';
import { PageLayout } from '../../Components/General/PageLayout';
import { Text } from '../../Components/General/Text';
import { CreateButton, PrevButton } from '../../Components/InputFields/BrancherButton';
import { GetRoute } from '../../Components/Routing';
import { BrancherDispatch, UtilSaveGoals } from '../../store/actions';
import { IGoalsReducer } from '../../store/reducers/GoalsReducer';
import { IStoreTypes } from '../../store/storeTypes';
import { GetIllustrationLink } from '../../utils/LinkUtils';
import { SmartGoalsInfo } from '../Training/Modules/sharedComps/SmartGoalsInfo';
import { GoalsBoard } from './GoalsBoard';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';

const useStyles = makeStyles({
  img: {
    marginTop: 20,
    marginRight: 10,
    width: (props: { mobile: boolean }) => (props.mobile ? 70 : 100),
    height: (props: { mobile: boolean }) => (props.mobile ? 70 : 100),
  },
});

export const Goals: React.FC = (props) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [savingGoals, setSavingGoals] = React.useState<boolean>(false);
  const [openSnackBar, setOpenSnackbar] = React.useState<boolean>(false);
  const sessionPosition = useSelector((state: IStoreTypes) => state.user?.sessionPosition);
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const goalItems = useSelector((state: IStoreTypes) => state?.goals);
  const [boardItems, setBoardItems] = React.useState<IGoalsReducer>(goalItems);
  const styles = useStyles({ mobile });
  const dispatch = useDispatch();
  const isMentee = sessionPosition === ProgramPositions.mentee;
  const partnerPosition = isMentee
    ? roleLabels[ProgramPositions.mentor]
    : roleLabels[ProgramPositions.mentee];

  const saveGoals = () => {
    setSavingGoals(true);
    BrancherDispatch(
      dispatch,
      UtilSaveGoals(boardItems, () => {
        setSavingGoals(false);
        setOpenSnackbar(true);
      }),
    );
  };

  const checkSavedGoals = (): boolean => {
    return JSON.stringify(goalItems) !== JSON.stringify(boardItems);
  };

  return (
    <PageLayout pageTitle="" titleDivider={false} hasFeaturePageLayout={false}>
      <RouteLeavingGuard when={checkSavedGoals()} />
      <BrancherSnackbar
        controlClose={() => setOpenSnackbar(false)}
        open={openSnackBar}
        message="Saved goals!"
        aria-label="saved-goals"
      />
      <SmartGoalsInfo controlDialog={setOpenDialog} open={openDialog} />
      <Grid container item xs={11} lg={9} justify="center">
        <Grid item xs={12} container justify="space-between" alignItems="center">
          <Text variant="lg" color="purple" fontWeight={600}>
            <Link to={GetRoute('dashboard').path}>
              <PrevButton variant="text" color="primary" aria-label="back-to-dashboard">
                Dashboard
              </PrevButton>
            </Link>
          </Text>
          <CreateButton aria-label="save-goals" onClick={saveGoals} loading={savingGoals} />
        </Grid>
        <Grid item xs={12}>
          <BrancherDivider marginBottom={40} marginTop={6} />
        </Grid>
        <Grid item xs={12}>
          <Text variant="xl" fontWeight={600}>
            Goals
          </Text>
        </Grid>
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={12} sm={2} md={3}>
            <img src={GetIllustrationLink('goals.png')} alt="goals-target" className={styles.img} />
          </Grid>
          <Grid item xs={9}>
            <Text variant="sm" display="inline">
              All goals posted here will be automatically shared with your{' '}
              {roleLabels[partnerPosition]}. We encourage you to make{' '}
            </Text>
            <BrancherLinkText
              underline="always"
              onClick={() => setOpenDialog(true)}
              variant="sm"
              display="inline"
            >
              SMART
            </BrancherLinkText>
            <Text variant="sm" display="inline">
              {' '}
              goals.
            </Text>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <GoalsBoard setBoardItems={setBoardItems} boardItems={boardItems} />
        </Grid>
      </Grid>
    </PageLayout>
  );
};
