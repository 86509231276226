import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { Text } from '../../../../../Components/General/Text';

export const MoDGoalSettingMentorStepNine = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="lg" fontWeight={600}>
          Here are some conversation starters for guiding your mentee's goal setting:
        </Text>
        <BrancherList
          listItems={[
            'What do you want to learn?',
            'What questions do you want to be answered?',
            'What growth do you want to achieve?',
            'What are your short and long-term goals?',
            'What are your current biggest challenges?',
            'What are your core values and how can we anchor goals around those?',
          ]}
        />
      </Grid>
    </Grid>
  );
};
