import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import Info from '@material-ui/icons/Info';
import { SuggestionCard } from '../../SuggestionCard';

export const MoDBeGreatMenteeStepTwo = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="lg" fontWeight={600}>
          Mentoring structure
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" marginBottom={20} fontWeight={400}>
          This mentoring relationship is designed to be flexible. Both the mentee and mentor work
          together to decide when you will meet and for what duration. Some pairs might only meet a
          couple of times and others might meet several times over many months. It is up to each
          pair to decide what works for both parties.
        </Text>
      </Grid>
      <SuggestionCard custom>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={3} md={2} container justify="center">
            <Info fontSize="large" />
          </Grid>
          <Grid item xs={9} md={10}>
            <Text variant="md" display="inline" fontWeight={400}>
              The one condition is -{' '}
            </Text>
            <Text variant="md" display="inline" fontWeight={600}>
              you must meet at least once per month.{' '}
            </Text>
            <Text variant="md" display="inline" fontWeight={400}>
              If you are meeting less than this, we classify this an informal relationship that does
              not need to be supported through this system. If this happens to you, please unpair
              yourselves so that you're both freed up to meet other mentees/mentors!
            </Text>
          </Grid>
        </Grid>
      </SuggestionCard>
    </Grid>
  );
};
