import * as React from 'react';
import {
  EMoDTrainingModules,
  ReadableTrainingModules,
} from '../../../../store/reducerTypes/TrainingReducer.types';
import { ModuleWrapper } from '../ModuleWrapper';
import { MoDGrowthCoachingFrameworkMentorModuleMapper } from './MoDGrowthCoachingFrameworkMentorModuleMapper';

export const MoDGrowthCoachingFrameworkMentor = () => (
  <ModuleWrapper
    moduleMap={MoDGrowthCoachingFrameworkMentorModuleMapper}
    moduleName={EMoDTrainingModules.MOD_GROWTH_COACHING_FRAMEWORK_MENTOR}
    title={ReadableTrainingModules[EMoDTrainingModules.MOD_GROWTH_COACHING_FRAMEWORK_MENTOR]}
  />
);
