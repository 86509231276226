import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { SallyAvatar } from '../../avatars/Sally';

export const BeGreatMenteeStepFiveCorrect = () => {
  const stepFiveValue = useSelector((state: IStoreTypes) => state.training.beGreatMentee.StepFive);
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepFiveValue.correct} incorrect={!stepFiveValue.correct}>
          <Text variant="md" fontWeight={700}>
            {stepFiveValue.correct ? 'Correct!' : 'Not quite.'}
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <SallyAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          {stepFiveValue.correct ? (
            <Text variant="sm">
              At its core, having a growth mindset involves believing in learning and development
              and thinking everyone can grow.
            </Text>
          ) : (
            <Text variant="sm">
              Thinking that you need a new manager is displaying a fixed mindset as you're
              indicating that you believe their behaviour is static and can't change.
            </Text>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
