import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { Text } from '../../../../../Components/General/Text';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

interface IPowerOfReflectionQualifyingOne {
  save: (a: { qualifying: { haveMet: string } }) => void;
  module: string;
}

export const PowerOfReflectionQualifyingOne = (props: IPowerOfReflectionQualifyingOne) => {
  const { module, save } = props;
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const partnersRole =
    sesPos === ProgramPositions.mentee ? ProgramPositions.mentor : ProgramPositions.mentee;
  const haveMet = useSelector((state: IStoreTypes) => state.training[module])?.qualifying?.haveMet;
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    dispatch(save({ qualifying: { haveMet: val } }));
  };

  return (
    <>
      <Grid item xs={12}>
        <Box marginTop={4}>
          <SuggestionCard>
            In order to complete this module, you need to have met with your {partnersRole}.
          </SuggestionCard>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginTop={40} marginBottom={10}>
          {`Have you met with your ${partnersRole}?`}
        </Text>
      </Grid>
      <Grid item container justify="center" xs={12}>
        <SelectionControl
          name="haveMet"
          value={haveMet}
          updateValue={updateValue}
          halfWidth
          exclusive
          question=""
          noQuestion
          options={MakeOptions(['Yes', 'No'])}
        />
      </Grid>
    </>
  );
};
