import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IStoreTypes } from '../../store/storeTypes';
import { TrainingModuleButton } from './TrainingModuleButton';
import { getTrainingModuleConfig } from './TrainingModuleDefaultConfig';
import { Text } from '../../Components/General/Text';
import { GetRoute } from '../../Components/Routing';
import { NextButton } from '../../Components/InputFields/BrancherButton';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { ResourcesSection } from '../Dashboard/ResourcesSection';

export const TrainingModules = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const trainingModules = useSelector((state: IStoreTypes) => state.training.modules)[sesPos];
  const hasAdhocPairing = useSelector((state: IStoreTypes) => state.user?.hasAdhocPairing);
  const progress = useSelector((state: IStoreTypes) => state.training.progress);
  const trainingConfig = useSelector((state: IStoreTypes) => state.training.moduleConfig);

  return (
    <Grid item container justify="center" alignItems={'center'}>
      <Box mb={8} display="flex" width="100%">
        <Grid container alignItems="center" justify="flex-end">
          <Grid item container xs={12}>
            <Grid item container justify="space-between">
              <Text variant="lg" fontWeight={700}>
                Resources
              </Text>
              <Link to={GetRoute('resources').path}>
                <NextButton aria-label="view-all-resources" size="small" variant="text">
                  View all
                </NextButton>
              </Link>
            </Grid>
            <Grid item>
              <BrancherDivider marginBottom={20} marginTop={10} height={1} />
            </Grid>
            <ResourcesSection />
          </Grid>
        </Grid>
      </Box>
      {trainingModules.map((s, c) => (
        <Grid item xs={12} key={c} id={s}>
          <TrainingModuleButton
            {...getTrainingModuleConfig(s, trainingModules, progress, trainingConfig[sesPos])}
            hasAdhocPairing={hasAdhocPairing}
          />
        </Grid>
      ))}
    </Grid>
  );
};
