import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { CharlieAvatar } from '../../avatars/Charlie';

export const BeGreatMentorStepFifteenCorrect = () => {
  const stepFifteenValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentor.StepFifteen,
  );
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard
          custom
          success={stepFifteenValue.correct}
          incorrect={!stepFifteenValue.correct}
        >
          <Text variant="md" fontWeight={700}>
            {stepFifteenValue.correct ? "That's correct" : 'Not quite'}.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          {stepFifteenValue.correct ? (
            <Grid item>
              <Text variant="sm">
                Sometimes people are unclear as to what they want, or have more than one goal.
                Expect to spend some time fine-tuning the goal.
              </Text>
              <Text variant="sm" marginTop={20}>
                Trying different ways to help them articulate the goal can help. Asking them to
                imagine an ideal world where the problem is solved is a way of visually imagining
                the future outcome they desire.
              </Text>
            </Grid>
          ) : (
            <Grid item>
              <Text variant="sm">
                The mentee needs to be the one who chooses which goal they want to focus on so they
                are motivated to work towards it.
              </Text>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
