import { IReducerType } from './ReducerType';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';

export const QUALIFY_FORM_DATA = 'QUALIFY_FORM_DATA';
export const QUALIFY_CLEAR_FORM_DATA = 'QUALIFY_CLEAR_FORM_DATA';

export const QualifyingReducer = (state = initialState, action: IReducerType) => {
  switch (action.type) {
    case QUALIFY_CLEAR_FORM_DATA:
      return initialState;
    case QUALIFY_FORM_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export interface IQualifyingReducer {
  position?: ProgramPositions | '';
  step?: number;
}

const initialState: IQualifyingReducer = {
  position: '',
  step: 0,
};
