import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { EContentType, IPrivateNote } from '../../../../../store/reducers/PrivateNotesReducer';
import { SuggestionCard } from '../../SuggestionCard';
import { IStoreTypes } from '../../../../../store/storeTypes';
import {
  BrancherDispatch,
  SaveBeyondProgramMenteeData,
  SaveBeyondProgramMentorData,
  UtilSaveUserPrivateNotesData,
  UtilSaveUserSharedNotesData,
} from '../../../../../store/actions';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveButton } from '../../../../../Components/InputFields/BrancherButton';
import { FormWithStepFieldLayout } from '../../../../../Components/InputFields/FormWithStepFieldLayout';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';

export const BeyondProgramMentorStepEight = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentee = position === ProgramPositions.mentee;
  const sessionStore = isMentee
    ? EStructuredTrainingModules.BEYOND_PROGRAM_MENTEE
    : EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR;
  const partnerRole = isMentee ? ProgramPositions.mentor : ProgramPositions.mentee;
  const sessionSaveFunction = isMentee ? SaveBeyondProgramMenteeData : SaveBeyondProgramMentorData;
  const StepEightValue = useSelector((state: IStoreTypes) => state.training[sessionStore]);
  const dispatch = useDispatch();

  const updateValue = (attributeName: string, val: string | boolean) => {
    const newStepTwoVals = { ...StepEightValue?.StepEight, [attributeName]: val };
    dispatch(
      sessionSaveFunction({
        StepEight: {
          ...StepEightValue?.StepEight,
          [attributeName]: val,
          interacted: Boolean(
            newStepTwoVals?.experience &&
              newStepTwoVals?.reflect &&
              newStepTwoVals?.evaluate &&
              newStepTwoVals?.plan &&
              newStepTwoVals?.savedNote &&
              newStepTwoVals?.sharePublic !== null,
          ),
        },
      }),
    );
  };

  const saveNote = () => {
    const apiCall =
      StepEightValue?.StepEight?.sharePublic === '0'
        ? UtilSaveUserPrivateNotesData
        : UtilSaveUserSharedNotesData;
    const noteData: IPrivateNote = {
      contents: [
        {
          content: [
            StepEightValue?.StepEight?.experience,
            StepEightValue?.StepEight?.reflect,
            StepEightValue?.StepEight?.evaluate,
            StepEightValue?.StepEight?.plan,
          ] as string[] & string,
          type: EContentType.LIST,
        },
      ],
      title: 'Beyond the program - KOLB Model',
      lastModified: Date.now(),
    };
    BrancherDispatch(
      dispatch,
      apiCall(noteData, () => {
        updateValue('savedNote', true);
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="sm" fontWeight={700}>
          Think about some of the most important experiences from your mentoring relationship.
        </Text>
      </Grid>

      <FormWithStepFieldLayout step={1} question="Experience">
        <Text variant="sm" fontWeight={600} marginTop={-15} marginBottom={10}>
          What was a key experience for you on your mentoring journey?
        </Text>
        <Text variant="sm">
          {isMentee
            ? 'Consider potential advice given from your mentor, a goal you perhaps achieved or did not achieve.'
            : 'Consider a meaningful ‘coaching style’ conversation you had with your mentee.'}
        </Text>
        <BrancherTextField
          value={StepEightValue?.StepEight?.experience ?? ''}
          updateValue={(textValue: string) => updateValue('experience', textValue)}
          maxChars={400}
          placeholder="Enter here"
          multiline
          fullWidth
        />
      </FormWithStepFieldLayout>

      <FormWithStepFieldLayout step={2} question="Reflect">
        <Text variant="sm" fontWeight={600} marginTop={-15} marginBottom={10}>
          How did this experience impact you?
        </Text>
        <Text variant="sm">
          What went well? What did not go well? What were the consequences of the behaviour?
        </Text>
        <BrancherTextField
          value={StepEightValue?.StepEight?.reflect ?? ''}
          updateValue={(textValue: string) => updateValue('reflect', textValue)}
          maxChars={400}
          placeholder="Enter here"
          multiline
          fullWidth
        />
      </FormWithStepFieldLayout>

      <FormWithStepFieldLayout step={3} question="Evaluate">
        <Text variant="sm" fontWeight={600} marginTop={-15} marginBottom={10}>
          Why did things go well / not so well?
        </Text>
        <Text variant="sm">
          What did you learn from this experience? What would you have done differently?
        </Text>
        <BrancherTextField
          value={StepEightValue?.StepEight?.evaluate ?? ''}
          updateValue={(textValue: string) => updateValue('evaluate', textValue)}
          maxChars={400}
          placeholder="Enter here"
          multiline
          fullWidth
        />
      </FormWithStepFieldLayout>

      <FormWithStepFieldLayout step={4} question="Plan">
        <Text variant="sm" fontWeight={600} marginTop={-15} marginBottom={10}>
          What will you do next time you have a similar experience?
        </Text>
        <Text variant="sm">What behaviours could help you achieve success in the future?</Text>
        <BrancherTextField
          value={StepEightValue?.StepEight?.plan ?? ''}
          updateValue={(textValue: string) => updateValue('plan', textValue)}
          maxChars={400}
          placeholder="Enter here"
          multiline
          fullWidth
        />
      </FormWithStepFieldLayout>

      <Grid item>
        <SuggestionCard>
          Would you like to keep this content private or share with your {partnerRole} in your
          shared notes?
        </SuggestionCard>
      </Grid>
      <Grid item>
        <SelectionControl
          noQuestion
          question=""
          name="sharePublic"
          value={StepEightValue?.StepEight?.sharePublic}
          exclusive
          halfWidth
          options={MakeOptions(['Private', 'Share'])}
          updateValue={(a: string) => updateValue('sharePublic', a)}
        />
        <SaveButton
          onClick={saveNote}
          disabled={
            !StepEightValue?.StepEight?.sharePublic ||
            StepEightValue?.StepEight?.savedNote ||
            !(
              StepEightValue?.StepEight?.experience &&
              StepEightValue?.StepEight?.reflect &&
              StepEightValue?.StepEight?.evaluate &&
              StepEightValue?.StepEight?.plan
            )
          }
        >
          Create note
        </SaveButton>
      </Grid>
    </Grid>
  );
};
