import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import { SaveIntroductionMentee } from '../../../../../store/actions';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { TrainingIntroductionLearnings } from '../../TrainingIntroductionSplash';
import { SuggestionCard } from '../../SuggestionCard';
import { CreateMentoringGuideLink } from '../../helpers/LinkBuilder';
import { CompetenceScale } from '../../sharedComps/CompetenceScale';
import { NPSScale } from '../../sharedComps/NPSScale';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { ConfidenceScale } from '../../sharedComps/ConfidenceScale';
import { BrancherDialog } from '../../../../../Components/General/BrancherDialog';

export const IntroductionMenteeStepEight = () => {
  const StepEightValue = useSelector((state: IStoreTypes) => state.training.introductionMentee);
  const mentoringGuideLink = CreateMentoringGuideLink();
  const [open, setDialog] = React.useState(false);
  const dispatch = useDispatch();

  const updateCompetence = (val: number) => {
    dispatch(
      SaveIntroductionMentee({
        StepEight: {
          ...StepEightValue?.StepEight,
          competenceFinal: val,
          interacted:
            StepEightValue?.rating != null &&
            StepEightValue?.confidenceFinal != null &&
            val != null,
        },
        competenceFinal: val,
      }),
    );
  };

  const updateConfidence = (val: number) => {
    dispatch(
      SaveIntroductionMentee({
        StepEight: {
          ...StepEightValue?.StepEight,
          confidenceFinal: val,
          interacted:
            StepEightValue?.rating != null &&
            StepEightValue?.competenceFinal != null &&
            val != null,
        },
        confidenceFinal: val,
      }),
    );
  };

  const updateRating = (val: number) => {
    dispatch(
      SaveIntroductionMentee({
        StepEight: {
          ...StepEightValue?.StepEight,
          interacted:
            StepEightValue?.competenceFinal != null &&
            StepEightValue?.confidenceFinal != null &&
            val != null,
        },
        rating: val,
      }),
    );
  };

  const updateFeedback = (val: string) => {
    dispatch(
      SaveIntroductionMentee({
        StepEight: {
          ...StepEightValue?.StepEight,
          interacted:
            StepEightValue?.rating != null &&
            StepEightValue?.competenceFinal != null &&
            StepEightValue?.confidenceFinal != null,
        },
        feedback: val,
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <BrancherDialog
        open={open}
        setClose={() => setDialog(false)}
        labelledBy="training-introduction-learnings"
      >
        <BrancherList listItems={TrainingIntroductionLearnings} />
      </BrancherDialog>
      <Grid item xs={12}>
        <Text variant="md" display="inline" fontWeight={700}>
          This is the last question.
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" display="inline">
          Prior to completing this training, you told us you were an{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          {StepEightValue.competenceBaseline + 1}
        </Text>
        <Text variant="md" display="inline">
          {' '}
          out of 7 regarding your level of competence and{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          {StepEightValue.confidenceBaseline + 1}
        </Text>
        <Text variant="md" display="inline">
          {' '}
          out of 7 regarding your level of confidence to perform{' '}
        </Text>
        <BrancherLinkText
          display="inline"
          variant="md"
          underline="always"
          onClick={() => setDialog(true)}
        >
          these behaviours
        </BrancherLinkText>
        <Text variant="sm" display="inline">
          .
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your competence rating now?
        </Text>
        <CompetenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepEightValue?.StepEight?.competenceFinal}
          valueLength={7}
          updateValue={updateCompetence}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your confidence rating now?
        </Text>
        <ConfidenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepEightValue?.StepEight?.confidenceFinal}
          valueLength={7}
          updateValue={updateConfidence}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          On a scale of 0 - 10, how likely are you to recommend this training to another colleague
          within this mentoring program?
        </Text>
        <NPSScale />
        <SegmentedControlGroup
          fullWidth
          value={StepEightValue?.rating}
          valueLength={11}
          updateValue={updateRating}
          startFromZero
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Do you have any other feedback for us?
        </Text>
        <BrancherTextField
          value={StepEightValue?.feedback}
          updateValue={(a: string) => updateFeedback(a)}
          placeholder="Enter here"
          maxChars={400}
          id="feedback"
          name="feedback"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" display="inline" fontWeight={700}>
          Thanks for taking the time to participate and best of luck getting started on your
          mentoring journey.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline" fontWeight={700}>
          The next steps are:
        </Text>
      </Grid>
      <Box marginLeft={2}>
        <BrancherList
          listItems={[
            <Text variant="md" marginBottom={10}>
              Contact your mentor to set your first meeting and propose a meeting agenda
            </Text>,
            <>
              <Text variant="md" display="inline">
                Continue to use your{' '}
              </Text>
              <BrancherLinkText
                underline="always"
                fontWeight={700}
                variant="md"
                href={mentoringGuideLink}
                display="inline"
                target="_blank"
              >
                Mentoring Guide
              </BrancherLinkText>
            </>,
            <Text variant="md" marginBottom={10} marginTop={10}>
              Contact your Mentoring Lead if you have questions
            </Text>,
          ]}
        />
      </Box>
      <Grid item xs={12}>
        <SuggestionCard>
          Make sure you click the "✓" button to complete this training.
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
