import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Colors, IColors } from '../../consts/colors';
import { BrancherDispatch, UtilSubmitParticipantMeetingRating } from '../../store/actions';
import { NextButton } from '../../Components/InputFields/BrancherButton';
import { IDefaultUserActionProps } from '../Actions/ActionsEngine';
import { Text } from '../../Components/General/Text';
import { MakeOptions } from '../../Form/MakeOptions';
import { BrancherToggleButton } from '../../Components/InputFields/ToggleButton/BrancherToggleButton';

export const MeetingSurvey: React.FC<IDefaultUserActionProps> = ({ closeDialog, action }) => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [meetingHappened, setMeetingHappened] = React.useState<string>('');
  const [rating, setRating] = React.useState<number>();
  const dispatch = useDispatch();

  const submitMeetingRating = () => {
    setSubmitting(true);
    const meetingActuallyHappened = meetingHappened === '1';
    BrancherDispatch(
      dispatch,
      UtilSubmitParticipantMeetingRating(
        meetingActuallyHappened ? rating : null,
        action.actionAttributes.meetingId,
        action.userActionId,
        meetingActuallyHappened,
        () => {
          setSubmitting(false);
          closeDialog();
        },
      ),
    );
  };

  const CTADisabled = () => {
    if (submitting) {
      return true;
    }
    if (meetingHappened === '0') {
      return false;
    }
    return !rating;
  };

  return (
    <Grid container item xs={12}>
      <Grid container item justify="center">
        <Text variant="md" marginTop={10} fontWeight={500}>
          Did you meet?
        </Text>
        <BrancherToggleButton
          exclusive
          halfWidth
          name="meeting-happened"
          options={MakeOptions(['No', 'Yes'])}
          updateValue={(decision: string) => setMeetingHappened(decision)}
          value={meetingHappened}
        />
      </Grid>
      {meetingHappened === '1' && (
        <Grid container justify="center">
          <Box mt={5} mb={2} display="flex" width="100%">
            <Grid container item justify="center">
              <Text variant="md" marginBottom={20} marginTop={10} fontWeight={600}>
                How was your meeting?
              </Text>
            </Grid>
          </Box>
          <Grid container justify="space-between" alignItems="center" item xs={10} spacing={2}>
            <SurveyEmojiButton
              setRating={setRating}
              ratingIndex={1}
              selectedIndex={rating}
              color="darkRed"
            >
              &#128543;
            </SurveyEmojiButton>
            <SurveyEmojiButton
              setRating={setRating}
              ratingIndex={2}
              selectedIndex={rating}
              color="red"
            >
              &#128533;
            </SurveyEmojiButton>
            <SurveyEmojiButton
              setRating={setRating}
              ratingIndex={3}
              selectedIndex={rating}
              color="orange"
            >
              &#128528;
            </SurveyEmojiButton>
            <SurveyEmojiButton
              setRating={setRating}
              ratingIndex={4}
              selectedIndex={rating}
              color="lightGreen"
            >
              &#128512;
            </SurveyEmojiButton>
            <SurveyEmojiButton
              setRating={setRating}
              ratingIndex={5}
              selectedIndex={rating}
              color="green"
            >
              &#128525;
            </SurveyEmojiButton>
          </Grid>
          <Grid container justify="center">
            <Box
              display="flex"
              border={`2px solid ${Colors.black}`}
              borderRadius={8}
              mb={3}
              mt={4}
              p={4}
            >
              <Text variant="xs" fontWeight={600}>
                Your rating will not be shared with {action.actionAttributes.partnerName}
              </Text>
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid container justify="flex-end">
        <Box display="flex" mt={5}>
          <NextButton size="large" onClick={submitMeetingRating} disabled={CTADisabled()}>
            Continue to dashboard
          </NextButton>
        </Box>
      </Grid>
    </Grid>
  );
};

interface ISurveyEmojiButton {
  ratingIndex: number;
  selectedIndex: number;
  setRating: (rating: number) => void;
  color: IColors;
}

const useStyles = makeStyles({
  iconButton: {
    border: (props: { color: string; isSelected: boolean }) =>
      props.isSelected ? `3px solid ${Colors[props.color]}` : '',
    fontSize: (props: { color: string; isSelected: boolean }) => (props.isSelected ? 80 : 48),
    color: (props: { color: string; isSelected: boolean }) => Colors[props.color],
  },
});

const SurveyEmojiButton: React.FC<ISurveyEmojiButton> = ({
  ratingIndex,
  setRating,
  selectedIndex,
  children,
  color,
}) => {
  const isSelected = selectedIndex === ratingIndex;
  const styles = useStyles({ isSelected, color });

  return (
    <Button onClick={() => setRating(ratingIndex)} className={styles.iconButton}>
      {children}
    </Button>
  );
};
