import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMakeTheMostMentee, SaveMakeTheMostMentor } from '../../../../../store/actions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const MakeTheMostMenteeStepTen = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const sessionStore =
    position === ProgramPositions.mentee ? 'makeTheMostMentee' : 'makeTheMostMentor';
  const stepTenValue = useSelector((state: IStoreTypes) => state.training[sessionStore]?.StepTen);
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const sessionSaveFunction =
      position === ProgramPositions.mentee ? SaveMakeTheMostMentee : SaveMakeTheMostMentor;
    dispatch(
      sessionSaveFunction({
        StepTen: {
          whyGoalImportant: val,
          interacted: val.length > 0,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Let's explore your motivation behind this goal.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">Why is this goal important to you?</Text>
      </Grid>
      <Grid item>
        <BrancherTextField
          fullWidth
          name="whyGoalImportant"
          placeholder="Enter here"
          value={stepTenValue?.whyGoalImportant}
          updateValue={(a: string) => updateValue(a)}
          maxChars={200}
        />
      </Grid>
      <Grid item container justify="center">
        <img
          src="https://media.brancher.com.au/training/makeTheMostMentee/ideas.png"
          width="50%"
          height="50%"
          alt="smart-goals"
        />
      </Grid>
    </Grid>
  );
};
