import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMoDGoalSettingMenteeData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const MoDGoalSettingMenteeStepSix = () => {
  const stepSixValue = useSelector(
    (state: IStoreTypes) => state.training.modGoalSettingMentee?.StepSix,
  );
  const dispatch = useDispatch();

  const updateValue = (val?: string[]) => {
    const correctAnswer = '5';
    dispatch(
      SaveMoDGoalSettingMenteeData({
        StepSix: {
          selected: val,
          correct: val?.length === 1 && val[0] === correctAnswer,
          interacted: val?.length > 0,
        },
      }),
    );
  };

  const options: string[] = [
    'A. Specific',
    'B. Measurable',
    'C. Attainable',
    'D. Relevant',
    'E. Timely',
    'F. None of the above',
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center">
        <Text variant="lg" fontWeight={700}>
          Goal #3
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">I want to be good at my job.</Text>
      </Grid>
      <Grid item>
        <Text variant="md">Is this goal...</Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepSixValue"
          value={stepSixValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(options)}
        />
      </Grid>
      <Grid item>
        <Text variant="sm" marginBottom={-30}>
          You can select more than 1 option.
        </Text>
      </Grid>
    </Grid>
  );
};
