import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';

export const PowerOfReflectionQualifyingTwo = () => {
  return (
    <Grid item xs={12}>
      <Text variant="md" fontWeight={600} marginBottom={30} marginTop={40}>
        That's great, let's continue!
      </Text>
    </Grid>
  );
};
