import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { EContentType, IPrivateNote } from '../../../../../store/reducers/PrivateNotesReducer';
import { IStoreTypes } from '../../../../../store/storeTypes';
import {
  BrancherDispatch,
  SaveBeyondProgramMentorData,
  UtilSaveUserPrivateNotesData,
  UtilSaveUserSharedNotesData,
} from '../../../../../store/actions';
import { GetRoute } from '../../../../../Components/Routing';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { SuggestionCard } from '../../SuggestionCard';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { SaveButton } from '../../../../../Components/InputFields/BrancherButton';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';

export const BeyondProgramMentorStepSixContinueCorrect = () => {
  const StepSixValue = useSelector(
    (state: IStoreTypes) => state.training?.[EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR],
  );
  const dispatch = useDispatch();

  const updateValue = (attributeName: string, val: string | boolean) => {
    const newStepTwoVals = { ...StepSixValue.StepSix, [attributeName]: val };
    dispatch(
      SaveBeyondProgramMentorData({
        StepSix: {
          ...StepSixValue?.StepSix,
          [attributeName]: val,
          interacted: Boolean(
            newStepTwoVals?.meetingPurpose &&
              newStepTwoVals?.savedNote &&
              newStepTwoVals?.sharePublic !== null,
          ),
        },
      }),
    );
  };

  const saveNote = () => {
    const apiCall =
      StepSixValue?.StepSix?.sharePublic === '0'
        ? UtilSaveUserPrivateNotesData
        : UtilSaveUserSharedNotesData;
    const noteData: IPrivateNote = {
      contents: [
        {
          content: StepSixValue?.StepSix?.meetingPurpose,
          type: EContentType.HEADING,
        },
      ],
      title: 'Beyond the program - NEW GOAL',
      lastModified: Date.now(),
    };
    BrancherDispatch(
      dispatch,
      apiCall(noteData, () => {
        updateValue('savedNote', true);
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="sm" fontWeight={700}>
          Using the MASTER model:
        </Text>
      </Grid>
      <Grid item>
        <Text variant="sm">
          What are some talking points for how to discuss/propose what the future of your mentoring
          relationship could look like?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="sm" display="inline">
          Are there any updates or changes you would like to make to the previous expectations and
          boundaries set by you and your mentee as per your{' '}
        </Text>
        <BrancherLinkText
          variant="sm"
          underline="always"
          fontWeight={600}
          display="inline"
          target="_blank"
          href={GetRoute('mentoringAgreement').path}
        >
          Mentoring Agreement
        </BrancherLinkText>
        <Text variant="sm" display="inline">
          ?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="sm" fontWeight={600}>
          For example, how often would you meet and for what purpose? Who would reach out and
          arrange these meetings?
        </Text>
        <BrancherTextField
          maxChars={200}
          fullWidth
          value={StepSixValue?.StepSix?.meetingPurpose}
          updateValue={(a: string) => updateValue('meetingPurpose', a)}
        />
      </Grid>
      <Grid item>
        <SuggestionCard>
          Would you like to keep this content private or share with your mentee in your shared
          notes?
        </SuggestionCard>
      </Grid>
      <Grid item>
        <SelectionControl
          noQuestion
          question=""
          name="sharePublic"
          value={StepSixValue?.StepSix?.sharePublic}
          exclusive
          halfWidth
          options={MakeOptions(['Private', 'Share'])}
          updateValue={(a: string) => updateValue('sharePublic', a)}
        />
        <SaveButton
          onClick={saveNote}
          disabled={
            !StepSixValue?.StepSix?.sharePublic ||
            StepSixValue?.StepSix?.savedNote ||
            !StepSixValue?.StepSix?.meetingPurpose
          }
        >
          Create note
        </SaveButton>
      </Grid>
    </Grid>
  );
};
