import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router';
import { IStoreTypes } from '../../store/storeTypes';
import { CalendarEvents, EUpdateDeleteRecurrenceType } from './CalendarEvents';
import {
  BrancherDispatch,
  UtilCreateAzureCalendarEvent,
  UtilCreateGoogleCalendarEvent,
  UtilDeleteAzureCalendarEvent,
  UtilDeleteGoogleCalendarEvent,
  UtilGetCalendarEvents,
  UtilGetFocusAreas,
  UtilGetGroupCalendarEvents,
  UtilUpdateAzureCalendarEvent,
  UtilUpdateGoogleCalendarEvent,
} from '../../store/actions';
import { Meeting } from './Meeting';
import { Text } from '../../Components/General/Text';
import { BrancherSnackbar } from '../../Components/General/BrancherSnackbar';
import { FeaturePageLayout } from '../../Components/General/FeaturePageLayout';
import { GetRoute } from '../../Components/Routing';
import { AddCalendar } from './AddCalendar';
import { Subscription } from '../../types/SubscriptionTypes';
import { IAgenda, IMeetingAction } from '../../store/reducers/MeetingsReducer';
import { IExtendedGroupData } from '../Groups/Group';

const useStyles = makeStyles({
  layout: {
    marginTop: 50,
  },
});

export enum EEventResponseStatus {
  NEEDS_ACTION = 'needsAction',
  NOT_RESPONDED = 'notResponded', // azure
  DECLINED = 'declined',
  TENTATIVE = 'tentative',
  ACCEPTED = 'accepted',
}

export enum EEventStatus {
  CANCELLED = 'cancelled',
  CONFIRMED = 'confirmed',
}

export enum ECalendars {
  AZURE = 'azure',
  GOOGLE = 'google',
  BRANCHER = 'brancher',
}

export interface IMeetingAgendas extends IAgenda {
  completed: boolean;
  completedMeeting?: string;
}

export interface IEvent {
  start: string;
  end: string;
  title: string;
  calendar?: ECalendars;
  allDay?: boolean;
  canUpdate?: boolean;
  status?: EEventStatus;
  id?: string;
  timeZone?: string;
  isOrganizer?: boolean;
  // Only for recurring events
  recurringEventId?: string; // parent recurring/owning eventId
  // "RRULE:FREQ=WEEKLY;INTERVAL=2" = fortnightly - omitted by google after creation - only here for creation type
  recurrence?: string[];
  // focusAreas
  topicId?: string;
  agendas?: IMeetingAgendas[];
  actions?: IMeetingAction[];
  notes?: string;
  // groups
  groupId?: string;
}

export interface INewEvent extends IEvent {
  attendees?: Array<{
    email: string;
    organizer: boolean;
    responseStatus?: EEventResponseStatus;
    roleId?: string;
  }>;
  location?: string;
  description?: string;
  updateDeleteRecurrenceType?: EUpdateDeleteRecurrenceType;
  recurrenceUntil?: string; // for reporting purposes
  groupId?: string;
}

interface IScheduler {
  group?: IExtendedGroupData;
}

export const Scheduler: React.FC<IScheduler> = ({ group }) => {
  const partnerName = useSelector((state: IStoreTypes) => state.user?.sessionPair?.name);
  const userId = useSelector((state: IStoreTypes) => state.user?.id);
  const isGroupOwner = group?.owner === userId;
  const isGroup = !!group?.groupId;
  const displayTitle = isGroup ? group?.title : partnerName;

  const [events, setEvents] = React.useState<IEvent[]>([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const [retrievedEvents, setRetrievedEvents] = React.useState<boolean>(false);
  const [partnerHasCalendar, setPartnerHasCalendar] = React.useState<boolean>(false);
  const [creatingEvent, setCreatingEvent] = React.useState<boolean>(false);
  const [updatingEvent, setUpdatingEvent] = React.useState<boolean>(false);
  const [deletingEvent, setDeletingEvent] = React.useState<boolean>(false);
  const [checkingPartnerEvents, setCheckingPartnerEvents] = React.useState<boolean>(false);
  const [redirectToMeetings, setRedirectToMeetings] = React.useState<boolean>(false);
  const [calendarEventErrorMessage, setCalendarEventErrorMessage] = React.useState<string>();
  const [userCalendarType, setUserCalendarType] = React.useState<ECalendars>(null);
  const isSSO = useSelector((state: IStoreTypes) => state.user?.isSSO);
  const subscriptions = useSelector((state: IStoreTypes) => state.user?.subscription);
  const allowsOutlookIntegration = isSSO
    ? subscriptions.includes(Subscription.MEETINGS_OUTLOOK)
    : true;
  const dispatch = useDispatch();
  const styles = useStyles();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const currentViewedMeeting = params.get('m');
  const standardMeetingView = !!currentViewedMeeting;

  React.useEffect(() => {
    BrancherDispatch(
      dispatch,
      UtilGetFocusAreas(() => {}),
    );
  }, []);

  const getUserEvents = (calendarType: ECalendars) => {
    setCheckingPartnerEvents(true);
    if (!isGroup) {
      BrancherDispatch(
        dispatch,
        UtilGetCalendarEvents((res) => {
          setCheckingPartnerEvents(false);
          setUserCalendarType(calendarType);
          if (res.success) {
            setEvents(res.data?.events ?? []);
            setPartnerHasCalendar(true);
          } else {
            if (res.message === 'MISSING_CALENDAR') {
              setCalendarEventErrorMessage(
                // tslint:disable-next-line:max-line-length
                `${partnerName} hasn't added their calendar to Brancher yet. When you meet up, remind them to add their calendar so you can use our scheduling functionality.`,
              );
            } else {
              setCalendarEventErrorMessage(res.message);
            }
          }
          setRetrievedEvents(true);
        }),
      );
    } else {
      BrancherDispatch(
        dispatch,
        UtilGetGroupCalendarEvents(group?.groupId, (res) => {
          setCheckingPartnerEvents(false);
          setUserCalendarType(calendarType);
          if (res.success) {
            setEvents(res.data?.events ?? []);
            setPartnerHasCalendar(true);
          } else {
            setCalendarEventErrorMessage(res.message);
          }
          setRetrievedEvents(true);
        }),
      );
    }
  };

  const createEvent = (meetingData: INewEvent) => {
    setCreatingEvent(true);
    const isGoogleCalendar = userCalendarType === ECalendars.GOOGLE;
    // TODO: Pretty sure this is no longer needed, both calendars should send off the same shape
    const convertCalendarSpecificDateFormatStart = isGoogleCalendar
      ? new Date(meetingData.start).toISOString()
      : meetingData.start;
    const convertCalendarSpecificDateFormatEnd = isGoogleCalendar
      ? new Date(meetingData.end).toISOString()
      : meetingData.end;
    const CreateApi = isGoogleCalendar
      ? UtilCreateGoogleCalendarEvent
      : UtilCreateAzureCalendarEvent;
    BrancherDispatch(
      dispatch,
      CreateApi(
        {
          ...meetingData,
          start: convertCalendarSpecificDateFormatStart,
          end: convertCalendarSpecificDateFormatEnd,
        },
        (res) => {
          setCreatingEvent(false);
          if (res.success) {
            setEvents([...events, res.data.event]);
          } else {
            setSnackbarOpen(true);
          }
        },
      ),
    );
  };

  const updateEvent = (meetingData: INewEvent) => {
    setUpdatingEvent(true);
    const isGoogleCalendar = userCalendarType === ECalendars.GOOGLE;
    // TODO: Pretty sure this is no longer needed, both calendars should send off the same shape
    const convertCalendarSpecificDateFormatStart = isGoogleCalendar
      ? new Date(meetingData.start).toISOString()
      : meetingData.start;
    const convertCalendarSpecificDateFormatEnd = isGoogleCalendar
      ? new Date(meetingData.end).toISOString()
      : meetingData.end;
    const UpdateApi = isGoogleCalendar
      ? UtilUpdateGoogleCalendarEvent
      : UtilUpdateAzureCalendarEvent;
    BrancherDispatch(
      dispatch,
      UpdateApi(
        {
          ...meetingData,
          start: convertCalendarSpecificDateFormatStart,
          end: convertCalendarSpecificDateFormatEnd,
        },
        (res) => {
          setUpdatingEvent(false);
          if (res.success) {
            if (!meetingData.recurringEventId) {
              setEvents(
                events.map((event) => (event.id === res.data.event.id ? res.data.event : event)),
              );
            } else {
              getUserEvents(userCalendarType);
            }
          } else {
            setSnackbarOpen(true);
          }
        },
      ),
    );
  };

  const deleteEvent = (meetingData: INewEvent) => {
    setDeletingEvent(true);
    const DeleteApi =
      meetingData.calendar === ECalendars.GOOGLE
        ? UtilDeleteGoogleCalendarEvent
        : UtilDeleteAzureCalendarEvent;
    BrancherDispatch(
      dispatch,
      DeleteApi(meetingData, (res) => {
        setDeletingEvent(false);
        if (res.success) {
          if (currentViewedMeeting === meetingData.id) {
            setRedirectToMeetings(true);
          } else if (!meetingData.recurringEventId) {
            setEvents(events.filter((event) => event.id !== meetingData.id));
          } else {
            getUserEvents(userCalendarType);
          }
        } else {
          setSnackbarOpen(true);
        }
      }),
    );
  };

  const pageTitle = standardMeetingView
    ? `Scheduled meeting with ${displayTitle}`
    : `Schedule a meeting with ${displayTitle}`;

  return (
    <Grid container item justify="center" className={styles.layout}>
      <FeaturePageLayout
        pageTitle={pageTitle}
        backButtonRoute={isGroup ? '' : 'meetings'}
        backButtonLabel={isGroup ? '' : 'Meetings'}
      >
        <BrancherSnackbar
          open={snackbarOpen}
          controlClose={() => setSnackbarOpen(false)}
          message="There was an issue creating/updating this event, please try again in a few moments"
        />
        {redirectToMeetings && <Redirect to={GetRoute('meetings').path} />}
        {calendarEventErrorMessage && (
          <Text variant="sm" color="tertiaryGrey" marginBottom={20}>
            {calendarEventErrorMessage}
          </Text>
        )}
        {standardMeetingView && (
          <Box mb={5} display="flex">
            <Meeting hasFeaturePageLayout={false} isGroup={isGroup} />
          </Box>
        )}
        {allowsOutlookIntegration &&
          !retrievedEvents &&
          !partnerHasCalendar &&
          !checkingPartnerEvents && (
            <AddCalendar
              getEvents={getUserEvents}
              redirectPath={isGroup ? `group?g=${params.get('g')}` : ''}
              isGroupOwner={isGroupOwner}
            />
          )}
        {partnerHasCalendar ? (
          <Grid item container>
            {(creatingEvent || deletingEvent || updatingEvent) && (
              <Grid item container direction="column" spacing={4}>
                <Grid item>
                  <CircularProgress size={64} color="secondary" />
                </Grid>
                <Grid item container justify="center">
                  <Text variant="md" marginTop={20} marginBottom={20} fontWeight={600}>
                    {creatingEvent
                      ? 'Creating'
                      : deletingEvent
                      ? 'Deleting'
                      : updatingEvent && 'Updating'}{' '}
                    Event
                  </Text>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <CalendarEvents
                events={events}
                createNewEvent={createEvent}
                updateEvent={updateEvent}
                deleteEvent={deleteEvent}
                updatingEvent={creatingEvent || deletingEvent || updatingEvent}
                group={group}
              />
            </Grid>
          </Grid>
        ) : checkingPartnerEvents && !retrievedEvents ? (
          <CircularProgress color="secondary" size={64} />
        ) : (
          !currentViewedMeeting && <Meeting hasFeaturePageLayout={false} isGroup={isGroup} />
        )}
      </FeaturePageLayout>
    </Grid>
  );
};
