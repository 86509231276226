import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMakeTheMostMentee, SaveMakeTheMostMentor } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { BrancherButton } from '../../../../../Components/InputFields/BrancherButton';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const MakeTheMostMenteeStepSeven = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const sessionStore =
    position === ProgramPositions.mentee ? 'makeTheMostMentee' : 'makeTheMostMentor';
  const stepSevenValue = useSelector(
    (state: IStoreTypes) => state.training[sessionStore]?.StepSeven,
  );
  const dispatch = useDispatch();
  const [checkAnswer, setCheckAnswer] = React.useState(false);

  const toggleCheckAnswer = () => {
    const sessionSaveFunction =
      position === ProgramPositions.mentee ? SaveMakeTheMostMentee : SaveMakeTheMostMentor;
    setCheckAnswer(!checkAnswer);
    dispatch(
      sessionSaveFunction({
        StepSeven: {
          ...stepSevenValue,
          interacted: true,
        },
      }),
    );
  };

  const updateValue = (val: string[]) => {
    const sessionSaveFunction =
      position === ProgramPositions.mentee ? SaveMakeTheMostMentee : SaveMakeTheMostMentor;
    dispatch(
      sessionSaveFunction({
        StepSeven: {
          ...stepSevenValue,
          goalThree: val,
          correct: val.length === 0,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Goal #3:
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">I want to be good at my job.</Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Is this goal...
        </Text>
      </Grid>
      <Grid item container direction="column">
        <Grid item>
          <Text variant="md" fontWeight={700} display="inline">
            A.{' '}
          </Text>
          <Text variant="md" display="inline">
            Specific
          </Text>
        </Grid>
        <Grid item>
          <Text variant="md" fontWeight={700} display="inline">
            B.{' '}
          </Text>
          <Text variant="md" display="inline">
            Measurable
          </Text>
        </Grid>
        <Grid item>
          <Text variant="md" fontWeight={700} display="inline">
            C.{' '}
          </Text>
          <Text variant="md" display="inline">
            Attainable
          </Text>
        </Grid>
        <Grid item>
          <Text variant="md" fontWeight={700} display="inline">
            D.{' '}
          </Text>
          <Text variant="md" display="inline">
            Relevant
          </Text>
        </Grid>
        <Grid item>
          <Text variant="md" fontWeight={700} display="inline">
            E.{' '}
          </Text>
          <Text variant="md" display="inline">
            Timely
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You can choose more than one option.
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          noQuestion
          question=""
          name="goalThree"
          value={stepSevenValue?.goalThree}
          options={MakeOptions(['A', 'B', 'C', 'D', 'E'])}
          updateValue={(a: string[]) => updateValue(a)}
          thirdWidth
        />
      </Grid>
      <Box display="flex" alignSelf="flex-end" maxWidth="fit-content">
        <BrancherButton color="primary" onClick={toggleCheckAnswer}>
          Check answer
        </BrancherButton>
      </Box>
      {checkAnswer && (
        <Grid item>
          <Text variant="sm" fontWeight={700} marginBottom={20}>
            The correct answer is none of the above.
          </Text>
          <Text variant="sm" marginTop={10} marginBottom={25}>
            This goal meets almost none of the SMART principles. It is not specific or measurable,
            in that we don’t know what ‘good’ means. Because the goal is vague, we do not know how
            attainable or relevant this is. There is also no specific time, so we don’t know when
            the individual wants to achieve the goal by.
          </Text>
        </Grid>
      )}
    </Grid>
  );
};
