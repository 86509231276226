import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SuggestionCard } from '../../SuggestionCard';

export const IntroductionMenteeStepTwoCorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          Ideally it would be a combination of{' '}
        </Text>
        <Text variant="md" fontWeight={700} color="purple" display="inline">
          all three
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          This means you benefit from Alex’s experience but are also growing the skills to manage
          problems independently.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You could start the conversation with questions like:
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard>“I’d love to hear your thoughts on what’s causing this.”</SuggestionCard>
        <SuggestionCard>
          “I'm interested to hear your experiences on similar situations.”
        </SuggestionCard>
        <SuggestionCard>
          “Please challenge my thinking on this because I’m sure it won’t be the only time I have
          this issue.”
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
