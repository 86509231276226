import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import { SaveIntroductionMentee } from '../../../../../store/actions';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { CreateMentoringGuideLink } from '../../helpers/LinkBuilder';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';

export const IntroductionMenteeStepFive = () => {
  const StepFiveValue = useSelector((state: IStoreTypes) => state.training.introductionMentee);
  const mentoringGuideLink = CreateMentoringGuideLink();
  const dispatch = useDispatch();

  const updateValue = (val: number) => {
    dispatch(
      SaveIntroductionMentee({
        ...StepFiveValue,
        StepFive: {
          selected: val,
          correct: val >= 3,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" display="inline">
          Just to reiterate, please read the{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          'What is expected of me'{' '}
        </Text>
        <Text variant="md" display="inline">
          and{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          'Code of Conduct'{' '}
        </Text>
        <Text variant="md" display="inline">
          pages in the{' '}
        </Text>
        <BrancherLinkText
          underline="always"
          fontWeight={700}
          variant="md"
          href={mentoringGuideLink}
          display="inline"
          target="_blank"
        >
          Mentoring Guide
        </BrancherLinkText>
        <Text variant="md" display="inline">
          {' '}
          and be sure to establish a{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          Mentoring Agreement
        </Text>
        <Text variant="md" display="inline">
          {' '}
          during the first meeting.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          How confident are you about creating a Mentoring Agreement?
        </Text>
      </Grid>
      <Box marginLeft={2}>
        <Text display="inline" fontWeight={700} variant="md" color="purple">
          5{' '}
        </Text>
        <Text display="inline" variant="md" marginBottom={-10}>
          = completely
        </Text>
      </Box>
      <Box marginLeft={2}>
        <Text display="inline" fontWeight={700} variant="md" color="purple">
          1{' '}
        </Text>
        <Text display="inline" variant="md">
          = not at all
        </Text>
      </Box>
      <Grid item container justify="center">
        <SegmentedControlGroup
          fullWidth
          value={StepFiveValue.StepFive?.selected}
          valueLength={5}
          updateValue={updateValue}
        />
      </Grid>
    </Grid>
  );
};
