import * as React from 'react';
import domToPdf from 'dom-to-pdf';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import { ActionButton } from '../../Components/InputFields/BrancherButton';

interface IGeneratePDF {
  elementId: string;
  fileName: string;
}

export const GeneratePDF: React.FC<IGeneratePDF> = ({ elementId, fileName }) => {
  const [forPrint, setForPrint] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (forPrint) {
      generatePdf();
    }
  }, [forPrint]);

  const generatePdf = () => {
    const element = document.getElementById(elementId);

    const options = {
      filename: `${fileName}.pdf`,
    };

    return domToPdf(element, options, () => {
      setForPrint(false);
    });
  };

  return (
    <ActionButton
      color="primary"
      loading={forPrint}
      onClick={() => setForPrint(true)}
      startIcon={<PictureAsPdf color="action" />}
    >
      Export as PDF
    </ActionButton>
  );
};
