import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { Link, useLocation } from 'react-router-dom';
import { Text } from '../../Components/General/Text';
import { BrancherList } from '../../Components/General/BrancherList';
import { GetRoute } from '../../Components/Routing';
import { NextButton, PrevButton } from '../../Components/InputFields/BrancherButton';

const useStyles = makeStyles({
  width: {
    maxWidth: 660,
    minWidth: '100%',
  },
});

interface ITrainingIntroduction {
  title: string;
  subHeader: string;
  activityLearnings: Array<string | React.ReactElement>;
  modulePath: string;
  validatedValue: boolean;
  children?: React.ReactNode;
}

export const TrainingIntroduction = (props: ITrainingIntroduction) => {
  const { title, subHeader, activityLearnings, modulePath, validatedValue, children } = props;
  const styles = useStyles();
  const pName = useLocation().pathname;
  return (
    <Grid container direction="column" alignItems="center" className={styles.width}>
      <Grid item xs={11} md={9}>
        <Box marginTop={5} marginBottom={3}>
          <Link to={GetRoute('training').path}>
            <PrevButton variant="text" color="primary">
              Back
            </PrevButton>
          </Link>
        </Box>
        <Grid item>
          <Text variant="xl">{title}</Text>
        </Grid>
        <Grid item>
          <Text variant="sm" marginTop={30}>
            {subHeader}
          </Text>
        </Grid>
        {activityLearnings?.length > 0 && (
          <>
            <Grid item>
              <Text variant="sm" fontWeight={600} marginTop={20} marginBottom={10}>
                Learning outcomes:
              </Text>
            </Grid>
            <Grid item>
              <BrancherList listItems={activityLearnings} fontWeight={400} />
            </Grid>
          </>
        )}
        {children && <Grid item>{children}</Grid>}
        <Box
          marginTop={4}
          marginBottom={4}
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Link to={validatedValue ? GetRoute(modulePath).path : pName} style={{ width: '100%' }}>
            <NextButton fullWidth disabled={!validatedValue}>
              Next
            </NextButton>
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};
