import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import SmsOutlined from '@material-ui/icons/SmsOutlined';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { SuggestionCard } from '../../SuggestionCard';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const MoDSituationalMentorshipMentorStepFourResult = () => {
  const modName = EMoDTrainingModules.MOD_SITUATIONAL_MENTORSHIP_MENTOR;
  const correct = useSelector((state: IStoreTypes) => state.training?.[modName]?.StepFour?.correct);

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={correct} incorrect={!correct}>
          <Text variant="md" fontWeight={700}>
            {correct ? 'Correct' : 'Not quite'}.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Taking on a 'Coach (questioning)' style here is preferred because:
        </Text>
        <BrancherList
          variant="md"
          fontWeight={700}
          listItems={[
            'There is no time pressure',
            "There is a preference to develop the mentee's skills",
          ]}
        />
      </Grid>
      <Grid item>
        <Text variant="md">
          The mentee is capable of solving the problem, they just need to be prompted with
          questions. You could also show elements of support/encouragement and challenge/feedback,
          depending on the situation.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          The aim is to{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700}>
          develop Charlie's problem solving skills
        </Text>
        <Text variant="md" display="inline">
          {' '}
          so she can deal with similar situations autonomously in the future.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You could ask questions like:
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard neutral textColor="black" icon={<SmsOutlined />}>
          "That sounds frustrating, what have you tried so far?"
        </SuggestionCard>
      </Grid>
      <Grid item>
        <SuggestionCard neutral textColor="black" icon={<SmsOutlined />}>
          "So it sounds like you've already tried a few different approaches to resolve this
          yourself, what have you tried?"
        </SuggestionCard>
      </Grid>
      <Grid item>
        <SuggestionCard neutral textColor="black" icon={<SmsOutlined />}>
          "What's worked and what hasn't worked?
        </SuggestionCard>
      </Grid>
      <Grid item>
        <SuggestionCard neutral textColor="black" icon={<SmsOutlined />}>
          "Why do you think those approaches haven't worked?"
        </SuggestionCard>
      </Grid>
      <Grid item>
        <SuggestionCard neutral textColor="black" icon={<SmsOutlined />}>
          "What would motivate this person to be more engaged with you?"
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
