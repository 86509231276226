import {
  FOCUS_AREAS_ALL,
  IFocusArea,
  IMeetingData,
  IMeetingParticipant,
  IMeetingParticipantStatus,
  MEETINGS_ADD,
  MEETINGS_ADD_ALL,
  MEETINGS_CLEAR_DATA,
  MEETINGS_REMOVE_DATA,
} from '../reducers/MeetingsReducer';
import { IAPIResponseObject } from './actionTypes/apiTypes';
import { BrancherAuthRequest, BrancherDispatch } from './utils/api-utils';
import { ECalendars, IEvent, INewEvent } from '../../PostMatching/Meetings/Scheduler';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { SaveUserInfo, UtilRemoveUserAction } from './UserInfoActions';
import { UtilUpdateRoleProfileActivities } from './ProfileFormActions';
import { EProgressActivities } from '../reducers/ProfileFormReducer';
import { EUserActions } from '../reducers/UserInfoReducer';

export const SaveMeetingsData = (meetingsData: object) => {
  return {
    type: MEETINGS_ADD_ALL,
    payload: meetingsData,
  };
};

export const RemoveMeetingData = (meetingId: string) => {
  return {
    type: MEETINGS_REMOVE_DATA,
    payload: meetingId,
  };
};

export const SaveFocusAreasAllData = (focusAreas: IFocusArea[]) => {
  return {
    type: FOCUS_AREAS_ALL,
    payload: focusAreas,
  };
};

export const ClearMeetingAgendaData = () => {
  return {
    type: MEETINGS_CLEAR_DATA,
  };
};

export const SaveMeetingData = (meetingData: IMeetingData) => {
  return {
    type: MEETINGS_ADD,
    payload: meetingData,
  };
};

const ClearMeetingActionsAddProfileActivity = (
  fullMeetingData: INewEvent,
  partnerRoleId: string,
) => {
  return (dispatch: any, getState: any) => {
    const isGroupMeeting = !!fullMeetingData?.groupId;
    const createMeetingActions = getState().user.actions?.filter(
      (t) =>
        t.type === EUserActions.CREATE_MEETING &&
        t.action?.actionAttributes?.partnerRoleId === partnerRoleId,
    );
    if (createMeetingActions?.length > 0) {
      BrancherDispatch(
        dispatch,
        UtilRemoveUserAction(createMeetingActions[0].userActionId, () => {}),
      );
    }
    BrancherDispatch(
      dispatch,
      UtilUpdateRoleProfileActivities(EProgressActivities.HAS_CREATED_MEETING, {
        meetingId: fullMeetingData?.id ?? '',
        meetingStart: new Date(fullMeetingData?.start).getTime(),
        partnerRoleId: isGroupMeeting ? '' : partnerRoleId,
        groupId: fullMeetingData?.groupId ?? '',
      }),
    );
  };
};

export const UtilSaveUserMeetingData = (
  meeting,
  pastMeeting: boolean,
  cb: (a: IAPIResponseObject) => void,
  partnerRoleId: string = '',
) => {
  return (dispatch: any, getState: any) => {
    const roleId = getState().user.sessionRoleId;
    const participantRoleId =
      partnerRoleId !== '' ? partnerRoleId : getState().user?.sessionPair?.roleId ?? '';
    const participants: IMeetingParticipant[] = [
      {
        roleId,
        status: IMeetingParticipantStatus.ACCEPTED,
      },
      {
        roleId: participantRoleId,
        status: pastMeeting
          ? IMeetingParticipantStatus.ACCEPTED
          : IMeetingParticipantStatus.NO_RESPONSE,
      },
    ];
    const programId = getState().user.programId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/meeting',
        data: {
          roleId,
          programId,
          participants,
          ...meeting,
        },
      },
      getState(),
    )
      .then((resp: any) => {
        const fullMeetingData = resp.data.data;
        BrancherDispatch(
          dispatch,
          ClearMeetingActionsAddProfileActivity(fullMeetingData, partnerRoleId),
        );
        dispatch(SaveMeetingData(fullMeetingData));
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilGetUserMeetings = (cb: (a: IAPIResponseObject) => void) => {
  return (dispatch: any, getState: any) => {
    const roleId = getState().user.sessionRoleId;
    const programId = getState().user.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/meetings',
        params: JSON.stringify({
          roleId,
          programId,
        }),
      },
      getState(),
    )
      .then((resp: any) => {
        const meetings = resp.data.data;
        if (meetings.length > 0) {
          dispatch(SaveMeetingsData(meetings));
        } else {
          dispatch(SaveMeetingsData([]));
        }
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilDeleteMeeting = (meetingId: string, cb: (a?: IAPIResponseObject) => void) => {
  return (dispatch: any, getState: any) => {
    const meetings = getState().meetings?.meetings;
    const programId = getState().user.programId;
    BrancherAuthRequest(
      {
        method: 'delete',
        url: 'v2/meeting',
        data: {
          meetingId,
          programId,
        },
      },
      getState(),
    )
      .then(() => {
        dispatch(SaveMeetingsData(meetings.filter((m) => m.meetings !== meetingId)));
        cb();
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilUpdateMeetingStatusResponse = (
  meeting: IMeetingData,
  status: IMeetingParticipantStatus,
  cb: (a: IAPIResponseObject) => void,
) => {
  return (dispatch: any, getState: any) => {
    const roleId = getState().user.sessionRoleId;
    const programId = getState().user.programId;
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/meetingstatus',
        data: {
          roleId,
          programId,
          meetingId: meeting.meetingId,
          status,
        },
      },
      getState(),
    )
      .then((response: any) => {
        const otherMeetings = getState().meetings?.meetings.filter(
          (m) => m.meetings !== meeting.meetingId,
        );
        dispatch(SaveMeetingsData([...otherMeetings, response.data.data]));
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetGoogleCalendarScopeLink extends IAPIResponseObject {
  data: {
    events?: IEvent[];
    redirectAuthUrl?: string;
  };
}

export const UtilGetGoogleCalendarScopeLink = (
  redirectPath: string,
  cb: (a: IUtilGetGoogleCalendarScopeLink) => void,
) => {
  return (dispatch: any, getState: any) => {
    const username = getState().user.username;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/googlecalendarscopes',
        params: JSON.stringify({
          username,
          context: redirectPath,
        }),
      },
      getState(),
    )
      .then((resp: { data: IUtilGetGoogleCalendarScopeLink }) => {
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilGetGoogleCalendarToken = (
  code: string,
  redirectPath: string,
  cb: (a: IAPIResponseObject) => void,
) => {
  return (dispatch: any, getState: any) => {
    const username = getState().user.username;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/googlecalendartoken',
        data: {
          username,
          code,
          context: redirectPath,
        },
      },
      getState(),
    )
      .then((resp: { data: IAPIResponseObject }) => {
        dispatch(SaveUserInfo({ hasGoogleCalendar: true }));
        const positions = getState().user.positions;
        if (positions.includes(ProgramPositions.mentor)) {
          const progressAttribute = 'mentorGetStartedProgress';
          dispatch(
            SaveUserInfo({
              [progressAttribute]: { ...[progressAttribute], hasConnectedCalendar: true },
            }),
          );
          const mentorRoleId = getState().user.mentorRoleId;
          BrancherDispatch(
            dispatch,
            UtilUpdateRoleProfileActivities(
              EProgressActivities.HAS_CONNECTED_CALENDAR,
              { calendarType: ECalendars.GOOGLE },
              mentorRoleId,
            ),
          );
        }
        if (positions.includes(ProgramPositions.mentee)) {
          const progressAttribute = 'menteeGetStartedProgress';
          dispatch(
            SaveUserInfo({
              [progressAttribute]: { ...[progressAttribute], hasConnectedCalendar: true },
            }),
          );
          const menteeRoleId = getState().user.menteeRoleId;
          BrancherDispatch(
            dispatch,
            UtilUpdateRoleProfileActivities(
              EProgressActivities.HAS_CONNECTED_CALENDAR,
              { calendarType: ECalendars.GOOGLE },
              menteeRoleId,
            ),
          );
        }
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilCreateGoogleCalendarEvent extends IAPIResponseObject {
  data: {
    event: IEvent;
    saveEvent: IMeetingData;
  };
}

export const UtilCreateGoogleCalendarEvent = (
  meetingData: INewEvent,
  cb: (a: IUtilCreateGoogleCalendarEvent) => void,
) => {
  return (dispatch: any, getState: any) => {
    const username = getState().user.username;
    const programId = getState().user.programId;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/googlecalendarevents',
        data: {
          username,
          programId,
          meetingData: { ...meetingData, timeZone },
        },
      },
      getState(),
    )
      .then((resp: { data: IUtilCreateGoogleCalendarEvent }) => {
        const isGroupMeeting = !!meetingData.groupId;
        const partnerRoleId = getState().user.sessionPair?.roleId;
        const fullMeetingData = resp.data.data;
        BrancherDispatch(
          dispatch,
          ClearMeetingActionsAddProfileActivity(fullMeetingData.event, partnerRoleId),
        );
        dispatch(SaveMeetingData(fullMeetingData.saveEvent));
        if (isGroupMeeting) {
          BrancherDispatch(
            dispatch,
            UtilGetUserMeetings(() => {}),
          );
        }
        // Could put the returned meeting into redux as well... however the return data is for google, not for Brancher
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilDeleteGoogleCalendarEvent = (
  meetingData: INewEvent,
  cb: (a: IAPIResponseObject) => void,
) => {
  return (dispatch: any, getState: any) => {
    const username = getState().user.username;
    const programId = getState().user.programId;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    BrancherAuthRequest(
      {
        method: 'delete',
        url: 'v2/googlecalendarevents',
        data: {
          username,
          meetingData: { ...meetingData, timeZone },
          programId,
        },
      },
      getState(),
    )
      .then((resp: { data: IAPIResponseObject }) => {
        if (resp.data.success) {
          dispatch(RemoveMeetingData(meetingData.id));
        }
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilUpdateGoogleCalendarEvent extends IAPIResponseObject {
  data: {
    event: INewEvent;
    saveEvent: IMeetingData;
  };
}

export const UtilUpdateGoogleCalendarEvent = (
  meetingData: INewEvent,
  cb: (a: IUtilUpdateGoogleCalendarEvent) => void,
) => {
  return (dispatch: any, getState: any) => {
    const username = getState().user.username;
    const programId = getState().user.programId;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/googlecalendarevents',
        data: {
          username,
          programId,
          meetingData: { ...meetingData, timeZone },
        },
      },
      getState(),
    )
      .then((resp: { data: IUtilUpdateGoogleCalendarEvent }) => {
        const meetingToUpdate = getState().meetings.meetings.map((m) => {
          if (m.meetingId === resp.data.data.saveEvent.meetingId) {
            return resp.data.data.saveEvent;
          } else {
            return m;
          }
        });
        dispatch(SaveMeetingsData(meetingToUpdate));
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetGroupCalendarEvents extends IAPIResponseObject {
  data: {
    events: IEvent[];
  };
}

export const UtilGetGroupCalendarEvents = (
  groupId: string,
  cb: (a: IUtilGetGroupCalendarEvents) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().user.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/groupcalendarevents',
        params: JSON.stringify({
          groupId,
          programId,
        }),
      },
      getState(),
    )
      .then((resp: { data: IUtilGetGroupCalendarEvents }) => {
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetCalendarEvents extends IAPIResponseObject {
  data: {
    events: IEvent[];
  };
}

export const UtilGetCalendarEvents = (cb: (a: IUtilGetCalendarEvents) => void) => {
  return (dispatch: any, getState: any) => {
    const username = getState().user.username;
    const partnerPosition =
      getState().user.sessionPosition === ProgramPositions.mentee
        ? ProgramPositions.mentor
        : ProgramPositions.mentee;
    const partnerRoleId = getState().user.sessionPair?.roleId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/calendarevents',
        params: JSON.stringify({
          username,
          partnerPosition,
          partnerRoleId,
        }),
      },
      getState(),
    )
      .then((resp: { data: IUtilGetCalendarEvents }) => {
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetAzureCalendarScopeLink extends IAPIResponseObject {
  data: {
    events?: IEvent[];
    redirectAuthUrl?: string;
  };
}

export const UtilGetAzureCalendarScopeLink = (
  redirectPath: string,
  cb: (a: IUtilGetAzureCalendarScopeLink) => void,
) => {
  return (dispatch: any, getState: any) => {
    const username = getState().user.username;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/azurecalendarscopes',
        params: JSON.stringify({
          username,
          context: redirectPath,
        }),
      },
      getState(),
    )
      .then((resp: { data: IUtilGetAzureCalendarScopeLink }) => {
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilGetAzureCalendarToken = (
  code: string,
  redirectPath: string,
  cb: (a: IAPIResponseObject) => void,
) => {
  return (dispatch: any, getState: any) => {
    const username = getState().user.username;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/azurecalendartoken',
        data: {
          username,
          code,
          context: redirectPath,
        },
      },
      getState(),
    )
      .then((resp: { data: IAPIResponseObject }) => {
        dispatch(SaveUserInfo({ hasAzureCalendar: true }));
        const positions = getState().user.positions;
        if (positions.includes(ProgramPositions.mentor)) {
          const progressAttribute = 'mentorGetStartedProgress';
          dispatch(
            SaveUserInfo({
              [progressAttribute]: { ...[progressAttribute], hasConnectedCalendar: true },
            }),
          );
          const mentorRoleId = getState().user.mentorRoleId;
          BrancherDispatch(
            dispatch,
            UtilUpdateRoleProfileActivities(
              EProgressActivities.HAS_CONNECTED_CALENDAR,
              { calendarType: ECalendars.AZURE },
              mentorRoleId,
            ),
          );
        }
        if (positions.includes(ProgramPositions.mentee)) {
          const progressAttribute = 'menteeGetStartedProgress';
          dispatch(
            SaveUserInfo({
              [progressAttribute]: { ...[progressAttribute], hasConnectedCalendar: true },
            }),
          );
          const menteeRoleId = getState().user.menteeRoleId;
          BrancherDispatch(
            dispatch,
            UtilUpdateRoleProfileActivities(
              EProgressActivities.HAS_CONNECTED_CALENDAR,
              { calendarType: ECalendars.AZURE },
              menteeRoleId,
            ),
          );
        }
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilCreateAzureCalendarEvent extends IAPIResponseObject {
  data: {
    event: INewEvent;
    saveEvent: IMeetingData;
  };
}

export const UtilCreateAzureCalendarEvent = (
  meetingData: INewEvent,
  cb: (a: IUtilCreateAzureCalendarEvent) => void,
) => {
  return (dispatch: any, getState: any) => {
    const username = getState().user.username;
    const programId = getState().user.programId;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/azurecalendarevents',
        data: {
          username,
          programId,
          meetingData: {
            ...meetingData,
            timeZone,
          },
        },
      },
      getState(),
    )
      .then((resp: { data: IUtilCreateAzureCalendarEvent }) => {
        const isGroupMeeting = !!meetingData?.groupId;
        const partnerRoleId = getState().user.sessionPair?.roleId;
        const fullMeetingData = resp.data.data;
        BrancherDispatch(
          dispatch,
          ClearMeetingActionsAddProfileActivity(fullMeetingData.event, partnerRoleId),
        );
        dispatch(SaveMeetingData(fullMeetingData.saveEvent));
        if (isGroupMeeting) {
          BrancherDispatch(
            dispatch,
            UtilGetUserMeetings(() => {}),
          );
        }
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilUpdateAzureCalendarEvent extends IAPIResponseObject {
  data: {
    event: INewEvent;
    saveEvent: IMeetingData;
  };
}

export const UtilUpdateAzureCalendarEvent = (
  meetingData: INewEvent,
  cb: (a: IUtilUpdateAzureCalendarEvent) => void,
) => {
  return (dispatch: any, getState: any) => {
    const username = getState().user.username;
    const programId = getState().user.programId;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/azurecalendarevents',
        data: {
          username,
          programId,
          meetingData: { ...meetingData, timeZone },
        },
      },
      getState(),
    )
      .then((resp: { data: IUtilUpdateAzureCalendarEvent }) => {
        const meetingToUpdate = getState().meetings.meetings.map((m) => {
          if (m.meetingId === resp.data.data.saveEvent.meetingId) {
            return resp.data.data.saveEvent;
          } else {
            return m;
          }
        });
        dispatch(SaveMeetingsData(meetingToUpdate));
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilDeleteAzureCalendarEvent = (
  meetingData: INewEvent,
  cb: (a: IAPIResponseObject) => void,
) => {
  return (dispatch: any, getState: any) => {
    const username = getState().user.username;
    const programId = getState().user.programId;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    BrancherAuthRequest(
      {
        method: 'delete',
        url: 'v2/azurecalendarevents',
        data: {
          username,
          meetingData: { ...meetingData, timeZone },
          programId,
        },
      },
      getState(),
    )
      .then((resp: { data: IAPIResponseObject }) => {
        if (resp.data.success) {
          dispatch(RemoveMeetingData(meetingData.id));
        }
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilSubmitParticipantMeetingRating = (
  ratingVerified: number | null,
  meetingId: string,
  actionId: string,
  meetingHappened: boolean,
  cb: (a: IAPIResponseObject) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().user.programId;
    const roleId = getState().user.sessionRoleId;
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/meetingrating',
        data: {
          meetingId,
          programId,
          roleId,
          rating: ratingVerified,
          meetingHappened,
        },
      },
      getState(),
    )
      .then((resp: { data: IAPIResponseObject }) => {
        if (resp.data.success) {
          BrancherDispatch(
            dispatch,
            UtilRemoveUserAction(actionId, () => {}),
          );
        }
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetFocusAreas extends IAPIResponseObject {
  data: IFocusArea[];
}

export const UtilGetFocusAreas = (cb: (a: IUtilGetFocusAreas) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().user.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/focusareas',
        params: JSON.stringify({
          programId,
        }),
      },
      getState(),
    )
      .then((resp: { data: IUtilGetFocusAreas }) => {
        if (resp.data.success) {
          dispatch(SaveFocusAreasAllData(resp.data?.data));
        }
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetFocusAreaTopic extends IAPIResponseObject {
  data: IFocusArea;
}

export const UtilGetFocusAreaTopic = (topicId: string, cb: (a: IUtilGetFocusAreaTopic) => void) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/focusareatopic',
        params: JSON.stringify({
          topicId,
        }),
      },
      getState(),
    )
      .then((resp: { data: IUtilGetFocusAreaTopic }) => {
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetPreviousPairMeeting extends IAPIResponseObject {
  data: IMeetingData;
}

export const UtilGetPreviousPairMeeting = (cb: (a: IUtilGetPreviousPairMeeting) => void) => {
  return (dispatch: any, getState: any) => {
    const roleId = getState().user.sessionRoleId;
    const programId = getState().user.programId;
    const partnerRoleId = getState().user.sessionPair.roleId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/partnerpreviousmeeting',
        params: JSON.stringify({
          roleId,
          programId,
          partnerRoleId,
        }),
      },
      getState(),
    )
      .then((resp: { data: IUtilGetPreviousPairMeeting }) => {
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

type TMeetingMinutes = Pick<IMeetingData, 'notes' | 'actions' | 'topicId' | 'agendas'>;

interface IUtilUpdateMeetingMinutes extends IAPIResponseObject {
  data: TMeetingMinutes;
}

export const UtilUpdateMeetingMinutes = (
  meetingId: string,
  meetingMinutes: TMeetingMinutes,
  cb: (a: IUtilUpdateMeetingMinutes) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().user.programId;
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/meetingminutes',
        data: {
          meetingId,
          programId,
          meetingMinutes,
        },
      },
      getState(),
    )
      .then((resp: { data: IUtilUpdateMeetingMinutes }) => {
        if (resp.data.success) {
          const meetingToUpdate = getState().meetings.meetings.map((m) => {
            if (m.meetingId === meetingId) {
              return { ...m, ...resp.data.data };
            } else {
              return m;
            }
          });
          dispatch(SaveMeetingsData(meetingToUpdate));
        }
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};
