import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import {
  SaveBeyondProgramMenteeData,
  SaveBeyondProgramMentorData,
} from '../../../../../store/actions';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { CompetenceScale } from '../../sharedComps/CompetenceScale';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { BrancherDialog } from '../../../../../Components/General/BrancherDialog';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { NPSScale } from '../../sharedComps/NPSScale';
import { ConfidenceScale } from '../../sharedComps/ConfidenceScale';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';

export const BeyondProgramMentorStepThirteen = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentee = position === ProgramPositions.mentee;
  const moduleName = isMentee
    ? EStructuredTrainingModules.BEYOND_PROGRAM_MENTEE
    : EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR;
  const StepThirteenValue = useSelector((state: IStoreTypes) => state.training[moduleName]);
  const sessionSaveFunction = isMentee ? SaveBeyondProgramMenteeData : SaveBeyondProgramMentorData;
  const dispatch = useDispatch();
  const [openDialog, setDialog] = React.useState(false);

  const activityLearnings: string[] = isMentee
    ? [
        'Learn how to professionally wrap up your mentoring relationship within this formal program',
        "Clearly discuss what 'beyond this mentoring program' looks like for you and your mentor",
        'Pursue your new/adapted SMART goal to take you beyond the mentoring program',
      ]
    : [
        'Learn how to professionally wrap up your mentoring relationship within this formal program',
        "Clearly discuss what 'beyond this mentoring program' looks like for you and your mentee",
        'Learn how to set your mentee up for success beyond this mentoring relationship',
      ];

  const updateCompetence = (val: number) => {
    dispatch(
      sessionSaveFunction({
        StepThirteen: {
          ...StepThirteenValue?.StepThirteen,
          competenceFinal: val,
          interacted:
            StepThirteenValue?.rating != null &&
            StepThirteenValue?.confidenceFinal != null &&
            val != null,
        },
        competenceFinal: val,
      }),
    );
  };

  const updateConfidence = (val: number) => {
    dispatch(
      sessionSaveFunction({
        StepThirteen: {
          ...StepThirteenValue?.StepThirteen,
          confidenceFinal: val,
          interacted:
            StepThirteenValue?.rating != null &&
            StepThirteenValue?.competenceFinal != null &&
            val != null,
        },
        confidenceFinal: val,
      }),
    );
  };

  const updateRating = (val: number) => {
    dispatch(
      sessionSaveFunction({
        StepThirteen: {
          ...StepThirteenValue?.StepThirteen,
          interacted:
            StepThirteenValue?.competenceFinal != null &&
            StepThirteenValue?.confidenceFinal != null &&
            val != null,
        },
        rating: val,
      }),
    );
  };

  const updateFeedback = (val: string) => {
    dispatch(
      sessionSaveFunction({
        StepThirteen: {
          ...StepThirteenValue?.StepThirteen,
          interacted:
            StepThirteenValue?.rating != null &&
            StepThirteenValue?.competenceFinal != null &&
            StepThirteenValue?.confidenceFinal != null,
        },
        feedback: val,
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <BrancherDialog
        setClose={() => setDialog(false)}
        open={openDialog}
        labelledBy="Wrapping up the relationship"
      >
        <BrancherList fontWeight={400} listItems={activityLearnings} />
      </BrancherDialog>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          This is the last page.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Prior to completing this training, you told us you were an{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          {StepThirteenValue.competenceBaseline + 1}
        </Text>
        <Text variant="md" display="inline">
          {' '}
          out of 7 regarding your level of competence and{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          {StepThirteenValue.confidenceBaseline + 1}
        </Text>
        <Text variant="md" display="inline">
          {' '}
          out of 7 regarding your level of confidence to perform{' '}
        </Text>
        <BrancherLinkText
          display="inline"
          variant="md"
          underline="always"
          onClick={() => setDialog(true)}
        >
          these behaviours
        </BrancherLinkText>
        <Text variant="sm" display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your competence rating now?
        </Text>
        <CompetenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepThirteenValue?.StepThirteen?.competenceFinal}
          valueLength={7}
          updateValue={updateCompetence}
        />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your confidence rating now?
        </Text>
        <ConfidenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepThirteenValue?.StepThirteen?.confidenceFinal}
          valueLength={7}
          updateValue={updateConfidence}
        />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          On a scale of 0 - 10, how likely are you to recommend this training to another colleague
          within this mentoring program?
        </Text>
        <NPSScale />
        <SegmentedControlGroup
          fullWidth
          value={StepThirteenValue?.rating}
          valueLength={11}
          updateValue={updateRating}
          startFromZero
        />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Do you have any other feedback for us?
        </Text>
        <BrancherTextField
          value={StepThirteenValue?.feedback}
          updateValue={(a: string) => updateFeedback(a)}
          placeholder="Enter here"
          maxChars={400}
          id="feedback"
          name="feedback"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
