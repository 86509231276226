import * as React from 'react';
import { EEasyEnglishTrainingModules } from '../../../../store/reducerTypes/TrainingReducer.types';
import { ModuleWrapper } from '../ModuleWrapper';
import { EasyEnglishCCModuleMapper } from './EasyEnglishCCModuleMapper';

export const EasyEnglishCC = () => (
  <ModuleWrapper
    moduleMap={EasyEnglishCCModuleMapper}
    moduleName={EEasyEnglishTrainingModules.EASY_ENGLISH_CC}
    title="Easy English Code of Conduct Guide"
  />
);
