import * as React from 'react';
import { EMoDTrainingModules } from '../../../../store/reducerTypes/TrainingReducer.types';
import { ModuleWrapper } from '../ModuleWrapper';
import { MoDBeGreatMentorModuleMapper } from './MoDBeGreatMentorModuleMapper';

export const MoDBeGreatMentor = () => (
  <ModuleWrapper
    moduleMap={MoDBeGreatMentorModuleMapper}
    moduleName={EMoDTrainingModules.MOD_BE_GREAT_MENTOR}
    title="How to be a great mentor"
  />
);
