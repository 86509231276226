import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { Text } from '../../../../../Components/General/Text';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { GetTrainingResourceLink } from '../../../../../utils/LinkUtils';

export const MoDBeGreatMenteeStepEightIncorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center">
        <Text variant="md" fontWeight={700}>
          We can help you feel more confident than that.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Get some extra tips on creating a good first meeting by reading this{' '}
        </Text>
        <BrancherLinkText
          variant="md"
          display="inline"
          underline="always"
          target="_blank"
          href={GetTrainingResourceLink(
            EMoDTrainingModules.MOD_BE_GREAT_MENTEE,
            'tips_for_your_first_mentoring_meeting.pdf',
          )}
        >
          blog
        </BrancherLinkText>
        <Text variant="sm" display="inline">
          .
        </Text>
      </Grid>
    </Grid>
  );
};
