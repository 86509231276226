import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { Text } from '../../../../../Components/General/Text';
import { CheckAnswerSection } from '../../../../../Components/General/CheckAnswerSection';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const EasyEnglishMentorStepOneCorrect = () => {
  const stepOneValue = useSelector(
    (state: IStoreTypes) => state.training.easyEnglishMentor?.StepOne,
  );

  return (
    <Grid container direction="column" spacing={4}>
      <CheckAnswerSection correct={stepOneValue.correct}>
        <Text variant="sm" fontWeight={700} marginBottom={20}>
          {stepOneValue.correct
            ? 'Correct, you help your mentee with all of the below'
            : 'Not quite, you help your mentee with all of the below'}
          .
        </Text>
        <BrancherList listItems={['Listen', 'Ask questions', 'Talk about steps they can take']} />
      </CheckAnswerSection>
    </Grid>
  );
};
