import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { LikertScaleText } from '../../../../../Components/General/LikertScaleText';
import { BrancherSlider } from '../../../../../Components/InputFields/BrancherSlider';
import { Text } from '../../../../../Components/General/Text';
import { SaveSurveyOneData } from '../../../../../store/actions';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const SurveyStepFourHaveMet = () => {
  const surveyOneValue = useSelector(
    (state: IStoreTypes) => state.training?.[EStructuredTrainingModules.SURVEY_ONE],
  );
  const dispatch = useDispatch();

  const updateValue = (helpingMeAchieveValue: number) => {
    dispatch(
      SaveSurveyOneData({
        StepFour: {
          interacted: helpingMeAchieveValue !== null,
          helpingMeAchieve: helpingMeAchieveValue,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={600}>
          The program is helping me to work towards my career goals.
        </Text>
      </Grid>
      <Grid item container>
        <LikertScaleText />
      </Grid>
      <Grid item>
        <BrancherSlider
          id="helpingMeAchieve"
          name="helpingMeAchieve"
          value={surveyOneValue?.StepFour?.helpingMeAchieve}
          updateValue={updateValue}
          max={5}
          min={1}
        />
      </Grid>
    </Grid>
  );
};
