import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { CharlieAvatar } from '../../avatars/Charlie';

export const BeGreatMentorStepSeventeenCorrect = () => {
  const stepSeventeenValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentor.StepSeventeen,
  );
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard
          custom
          success={stepSeventeenValue.correct}
          incorrect={!stepSeventeenValue.correct}
        >
          <Text variant="md" fontWeight={700}>
            {stepSeventeenValue.correct ? "That's right" : 'Almost there'}.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          <Text variant="sm">
            The aim is to explore different ways to build up their confidence. One way to do so is
            reminding them of their past success - which people tend to dismiss. Providing genuine
            compliments is also a useful way to inject positivity and acknowledge their strengths.
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
};
