import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { SuggestionCard } from '../../SuggestionCard';

export const MoDSituationalMentorshipMentorStepTwoResult = () => {
  const modName = EMoDTrainingModules.MOD_SITUATIONAL_MENTORSHIP_MENTOR;
  const correct = useSelector((state: IStoreTypes) => state.training?.[modName]?.StepTwo?.correct);
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={correct} incorrect={!correct}>
          <Text variant="md" fontWeight={700}>
            {correct ? 'Correct' : 'Not quite'}.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Being 'directive (telling)' is preferred because the situation is:
        </Text>
        <BrancherList variant="md" listItems={['Time-critical', 'High-stakes']} />
      </Grid>
      <Grid item>
        <Text variant="md">
          You could direct Charlie to some useful resources, explain why you would do things in a
          particular way and break them down into small steps. Based on your expertise, you could
          also work out a timeline with her to see what is achievable and should be prioritised by
          the deadline.
        </Text>
      </Grid>
    </Grid>
  );
};
