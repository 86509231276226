import {
  GOALS_DATA,
  GOALS_CLEAR_DATA,
  IGoalsReducer,
  SAVE_PARTNERS_GOALS,
  EGoalsBoardColumns,
} from '../reducers/GoalsReducer';
import { IAPIResponseObject } from './actionTypes/apiTypes';
import { BrancherAuthRequest, BrancherDispatch } from './utils/api-utils';
import { UtilUpdateRoleProfileActivities } from './ProfileFormActions';
import { EProgressActivities } from '../reducers/ProfileFormReducer';

export const SaveGoalsData = (GoalsData: object) => {
  return {
    type: GOALS_DATA,
    payload: GoalsData,
  };
};

export const SavePartnerGoalsData = (PartnerGoalsData: object) => {
  return {
    type: SAVE_PARTNERS_GOALS,
    payload: PartnerGoalsData,
  };
};

export const ClearGoalsData = () => {
  return {
    type: GOALS_CLEAR_DATA,
  };
};

export const UtilGetGoals = (cb: (a: IAPIResponseObject) => void) => {
  return (dispatch: any, getState: any) => {
    const roleId = getState().user.sessionRoleId;
    dispatch(ClearGoalsData());
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/goals',
        params: JSON.stringify({
          roleId,
        }),
      },
      getState(),
    )
      .then((response: any) => {
        const goalsData = response.data?.data ?? [];
        dispatch(SaveGoalsData(goalsData));
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilGetPartnerGoals = (cb: (a: IAPIResponseObject) => void) => {
  return (dispatch: any, getState: any) => {
    const partnerRoleId = getState().user?.sessionPair?.roleId;
    if (partnerRoleId) {
      BrancherAuthRequest(
        {
          method: 'get',
          url: 'v2/goals',
          params: JSON.stringify({
            roleId: partnerRoleId,
          }),
        },
        getState(),
      )
        .then((response: any) => {
          const goalsData = response.data.data;
          dispatch(SavePartnerGoalsData(goalsData));
          cb(response.data);
        })
        .catch((error) => {
          cb(error);
        });
    } else {
      cb({ success: true });
    }
  };
};

export const UtilSaveGoals = (goals: IGoalsReducer, cb: (a: IAPIResponseObject) => void) => {
  return (dispatch: any, getState: any) => {
    const roleId = getState().user.sessionRoleId;
    const previousCompletedGoals = getState().goals?.[EGoalsBoardColumns.COMPLETED] ?? [];
    const previousCompletedGoalsLength = previousCompletedGoals?.length;
    const prevInProgressGoals = getState().goals?.[EGoalsBoardColumns.IN_PROGRESS] ?? [];
    const prevBacklogGoals = getState().goals?.[EGoalsBoardColumns.BACKLOG] ?? [];
    const previousGoals = [...previousCompletedGoals, ...prevInProgressGoals, ...prevBacklogGoals];
    const previousGoalsLength = previousGoals.length;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/goals',
        data: {
          roleId,
          ...goals,
        },
      },
      getState(),
    )
      .then((resp: any) => {
        const currentCompletedGoals = goals?.[EGoalsBoardColumns.COMPLETED] ?? [];
        const currentCompletedGoalsLength = currentCompletedGoals?.length;
        const inProgressGoals = goals?.[EGoalsBoardColumns.IN_PROGRESS] ?? [];
        const backlogGoals = goals?.[EGoalsBoardColumns.BACKLOG] ?? [];
        const currentGoals = [...currentCompletedGoals, ...inProgressGoals, ...backlogGoals];
        const currentGoalsLength = currentGoals.length;
        const hasCompletedGoals = previousCompletedGoalsLength < currentCompletedGoalsLength;
        if (previousGoalsLength < currentGoalsLength) {
          let newGoals = [];
          currentGoals.forEach((currGoal) => {
            if (previousGoals.map((a) => a.id).indexOf(currGoal.id) === -1) {
              newGoals.push(currGoal);
            }
          });
          newGoals?.forEach((newGoal) => {
            BrancherDispatch(
              dispatch,
              UtilUpdateRoleProfileActivities(EProgressActivities.HAS_CREATED_GOAL, {
                goalId: newGoal.id,
              }),
            );
          });
        } else if (previousGoalsLength === currentGoalsLength && !hasCompletedGoals) {
          BrancherDispatch(
            dispatch,
            UtilUpdateRoleProfileActivities(EProgressActivities.HAS_UPDATED_GOAL),
          );
        }
        if (hasCompletedGoals) {
          let newCompletedGoals = [];
          currentCompletedGoals.forEach((currGoal) => {
            if (previousCompletedGoals.map((a) => a.id).indexOf(currGoal.id) === -1) {
              newCompletedGoals.push(currGoal);
            }
          });
          newCompletedGoals?.forEach((completedGoal) => {
            BrancherDispatch(
              dispatch,
              UtilUpdateRoleProfileActivities(EProgressActivities.HAS_COMPLETED_GOAL, {
                goalId: completedGoal.id,
              }),
            );
          });
        }
        dispatch(SaveGoalsData(goals));
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};
