import {
  EProfileAttributes,
  EProgressActivities,
  IBaseProfileData,
  IProfileData,
  IRelatedActivityAttributes,
  PROFILE_CLEAR_FORM_DATA,
  PROFILE_FORM_DATA,
  PROFILE_PAIR_FORM_DATA,
} from '../reducers/ProfileFormReducer';
import { IAPIResponseObject } from './actionTypes/apiTypes';
import { BrancherAuthRequest } from './utils/api-utils';
import { SaveUserInfo } from './UserInfoActions';

export const SaveProfileFormData = (profileData: object) => {
  return {
    type: PROFILE_FORM_DATA,
    payload: profileData,
  };
};

export const SaveProfilePairFormData = (profileData: object) => {
  return {
    type: PROFILE_PAIR_FORM_DATA,
    payload: profileData,
  };
};

export const ClearProfileFormData = () => {
  return {
    type: PROFILE_CLEAR_FORM_DATA,
  };
};

interface IUtilGetUserRoleProfile extends IAPIResponseObject {
  data: {
    profileData: IProfileData;
    completedProfile: boolean;
    activityScore?: number;
  };
}

export const UtilGetUserRoleProfile = (cb: (a: IProfileData) => void, sessionPos?: string) => {
  return (dispatch: any, getState: any) => {
    const roleId = sessionPos ?? getState().user.sessionRoleId;
    dispatch(
      UtilGetPairRoleProfile(() => {
        BrancherAuthRequest(
          {
            method: 'get',
            url: 'v2/profile',
            params: JSON.stringify({
              roleId,
            }),
          },
          getState(),
        )
          .then((response: { data: IUtilGetUserRoleProfile }) => {
            const { profileData, completedProfile, activityScore } = response.data.data;
            const profile = { ...profileData, roleId, completedProfile, activityScore };
            dispatch(SaveProfileFormData(profile));
            cb(profile);
          })
          .catch((error) => {
            cb(error);
          });
      }),
    );
  };
};

export const UtilGetPairRoleProfile = (
  cb: (a: IAPIResponseObject & { data?: { completedProfile: boolean } }) => void,
  sessionPos?: string,
) => {
  return (dispatch: any, getState: any) => {
    const roleId = sessionPos ?? getState().user?.sessionPair?.roleId;
    if (!!roleId) {
      BrancherAuthRequest(
        {
          method: 'get',
          url: 'v2/profile',
          params: JSON.stringify({
            roleId,
          }),
        },
        getState(),
      )
        .then((response: any) => {
          const { profileData, completedProfile, activityScore } = response.data.data;
          dispatch(
            SaveProfilePairFormData({ ...profileData, completedProfile, activityScore, roleId }),
          );
          cb(response.data);
        })
        .catch((error) => {
          cb(error);
        });
    } else {
      cb({ success: true, data: { completedProfile: false } });
    }
  };
};

export const UtilGetRoleProfile = (roleId: string, cb: (a: IProfileData) => void) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/profile',
        params: JSON.stringify({
          roleId,
        }),
      },
      getState(),
    )
      .then((response: any) => {
        const { profileData } = response.data.data;
        cb(profileData);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilSaveUserRoleProfile extends IAPIResponseObject {
  data: {
    profileData: IBaseProfileData;
  };
}

export const UtilSaveUserRoleProfile = (
  profile: IProfileData,
  actualCompletedProfile: boolean,
  cb: (a: IUtilSaveUserRoleProfile) => void,
) => {
  return (dispatch: any, getState: any) => {
    const actualRoleId = getState().user.sessionRoleId;
    const programId = getState().user.programId;
    const position = getState().user.sessionPosition;
    const profilePic = getState().profileForm.profile.newProfilePic;
    const { newProfilePic, completedProfile, roleId, ...profileDataSave } = profile;
    const oldPairingAmount = getState().profileForm.profile?.[
      EProfileAttributes.MAXIMUM_PARTNER_AMOUNT
    ]?.[programId];
    const hasNewPairingAmount =
      profile?.[EProfileAttributes.MAXIMUM_PARTNER_AMOUNT]?.[programId] !== oldPairingAmount;
    const newPairingAmount = hasNewPairingAmount
      ? {
          programId,
          pairingAmount: profile?.[EProfileAttributes.MAXIMUM_PARTNER_AMOUNT]?.[programId],
          position,
        }
      : {};

    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/profile',
        data: {
          roleId: actualRoleId,
          completedProfile: actualCompletedProfile,
          profileData: profileDataSave,
          newProfilePic: profilePic,
          position,
          newPairingAmount,
        },
      },
      getState(),
    )
      .then((response: any) => {
        const actualProfileData = response.data.data.profileData;
        dispatch(
          SaveProfileFormData({
            ...actualProfileData,
            newProfilePic: '',
            completedProfile: actualCompletedProfile,
          }),
        );
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// NOTE: This is the proxy that controls all activity updates
export const UtilUpdateRoleProfileActivities = (
  activity: EProgressActivities,
  relatedAttributes?: IRelatedActivityAttributes,
  overrideRoleId?: string,
) => {
  return (dispatch: any, getState: any) => {
    const roleId = overrideRoleId ?? getState().user.sessionRoleId;
    const programId = getState().user.programId;
    const activities = {
      [activity]: [{ completedTime: new Date().getTime(), programId, ...relatedAttributes }],
    };
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/profileactivity',
        data: {
          roleId,
          activities,
          programId,
        },
      },
      getState(),
    )
      .then(
        (response: {
          data: {
            data: {
              profileData: IBaseProfileData;
              activityScore: number;
              progressActivity: IProfileData['progressActivity'];
            };
          };
        }) => {
          // flatten it all for the form - the badge that animates will just tie ito the `activityScore` updates
          if (!overrideRoleId) {
            const { profileData, progressActivity, ...other } = response.data?.data;
            dispatch(SaveProfileFormData({ ...profileData, ...other }));
          }
          dispatch(SaveUserInfo({ celebrateProgressActivity: true }));
        },
      )
      .catch(() => {});
  };
};
