import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../Components/General/Text';

export interface ISegmentedControlHeader {
  title: string;
  questionNumber?: string;
}

export const SegmentedControlHeader = (props: ISegmentedControlHeader) => {
  const { questionNumber, title } = props;
  return (
    <>
      {questionNumber && (
        <Grid item xs="auto">
          <Text variant="xs" color="purple" marginRight={8} borderBottom>
            {questionNumber}
          </Text>
        </Grid>
      )}
      <Grid item xs={11}>
        <Text variant="md" fontWeight={600}>
          {title}
        </Text>
      </Grid>
    </>
  );
};
