import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveEZEnglishMentorData } from '../../../../../store/actions';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const EasyEnglishMentorStepOne = () => {
  const stepOneValue = useSelector(
    (state: IStoreTypes) => state.training.easyEnglishMentor?.StepOne,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    dispatch(
      SaveEZEnglishMentorData({
        StepOne: {
          selected: val,
          correct: val?.length === 3,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md">How do you help your mentee?</Text>
      </Grid>
      <Grid item>
        <Text variant="sm" marginBottom={-30}>
          You can tick more than 1 option.
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepOneValue"
          value={stepOneValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(['Listen', 'Ask questions', 'Talk about steps they can take'])}
        />
      </Grid>
    </Grid>
  );
};
