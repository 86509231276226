import { IReducerType } from './ReducerType';

export const PRIVATE_NOTES_CLEAR_DATA = 'PRIVATE_NOTES_CLEAR_DATA';
export const PRIVATE_NOTES_ADD_ALL = 'PRIVATE_NOTES_ADD_ALL';
export const PRIVATE_NOTES_ADD = 'PRIVATE_NOTES_ADD';

export const PrivateNotesReducer = (state = PrivateNotesInitialState, action: IReducerType) => {
  switch (action.type) {
    case PRIVATE_NOTES_CLEAR_DATA:
      return PrivateNotesInitialState;
    case PRIVATE_NOTES_ADD_ALL:
      return { ...state, privateNotes: action.payload };
    case PRIVATE_NOTES_ADD:
      return { ...state, privateNotes: [...state.privateNotes, action.payload] };
    default:
      return state;
  }
};

export enum EContentType {
  PARAGRAPH = 'paragraph',
  HEADING = 'heading',
  LIST = 'list',
  LINK = 'link',
}

export interface IContent {
  type: EContentType;
  content: string & string[]; // Bit of a shortcut for now
  src?: string;
}

export interface INote {
  contents: any; // this is lexical's domain
  title: string;
  noteId?: string;
  lastModified?: number; // epoch date
}

export interface IPrivateNote {
  contents: IContent[];
  title: string;
  noteId?: string;
  lastModified?: number; // epoch date
}

export interface IPrivateNotesReducer {
  privateNotes?: IPrivateNote[];
}

export const PrivateNotesInitialState: IPrivateNotesReducer = {
  privateNotes: [],
};
