export enum Subscription {
  TRAINING = 'training',
  FORM = 'form',
  PROFILE = 'profile',
  GROUPS = 'groups',
  PRIVATE_NOTES = 'privateNotes',
  SHARED_NOTES = 'sharedNotes',
  MEETINGS = 'meetings',
  MEETINGS_OUTLOOK = 'meetingsOutlook',
  P2P = 'p2p',
  COHORT = 'cohort',
  ADHOC_PAIRING = 'adhocPairing',
}

export type ISubscriptions =
  | Subscription.TRAINING
  | Subscription.FORM
  | Subscription.PROFILE
  | Subscription.GROUPS
  | Subscription.PRIVATE_NOTES
  | Subscription.SHARED_NOTES
  | Subscription.MEETINGS
  | Subscription.MEETINGS_OUTLOOK
  | Subscription.P2P
  | Subscription.ADHOC_PAIRING
  | Subscription.COHORT;
