import * as React from 'react';
import { BrancherTabs } from '../../../../Components/General/BrancherTabs';
import { BrancherDialog } from '../../../../Components/General/BrancherDialog';
import { BrancherList } from '../../../../Components/General/BrancherList';

interface IMenteeMentorResponsibilities {
  open: boolean;
  onClose: () => void;
}

export const MenteeMentorResponsibilities = (props: IMenteeMentorResponsibilities) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const { open, onClose } = props;

  const tabConfig = [
    {
      label: 'Mentor',
      value: 0,
      component: (
        <BrancherList
          fontWeight={400}
          listItems={[
            'Adapt your style according to the needs and style of your mentee',
            "Use Brancher's structure to provide focus to the relationship",
            'Share your expertise and experience',
            'Coach your partner to think through issues and make their own decisions',
            'Use a coaching model such as GROWTH to guide the conversation',
          ]}
        />
      ),
    },
    {
      label: 'Mentee',
      value: 1,
      component: (
        <BrancherList
          fontWeight={400}
          listItems={[
            'Engage openly',
            'Help your mentor understand you, your work and your goals',
            'Own your development: establish and track your goals',
            'Drive the mentoring relationship; organise meetings and agendas',
            'Take a growth mindset',
            'Be authentic and grateful',
          ]}
        />
      ),
    },
    {
      label: 'Both',
      value: 2,
      component: (
        <BrancherList
          fontWeight={400}
          listItems={[
            'Establish expectations and boundaries',
            'Respect confidentiality',
            'Complete Mentoring Agreement and online training',
            'Maintain regular contact (monthly, at a minimum)',
            'Ensure meeting time and place is mutually acceptable',
            'Seek to understand your partner first as a person, then as a professional',
            'Provide and ask for feedback',
            'Share knowledge and resources in-between monthly catch-ups',
          ]}
        />
      ),
    },
  ];

  return (
    <BrancherDialog
      setClose={onClose}
      open={open}
      title="Mentor vs. Mentee Responsibilities"
      labelledBy="Mentor vs. Mentee Responsibilities"
    >
      <BrancherTabs tabConfig={tabConfig} value={selectedTab} updateValue={setSelectedTab} />
    </BrancherDialog>
  );
};
