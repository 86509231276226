export const MobileFontWeights = {
  xs: 300,
  sm: 500,
  md: 400,
  lg: 500,
  xl: 700,
};

export const TabletFontWeights = {
  xs: 300,
  sm: 500,
  md: 400,
  lg: 500,
  xl: 700,
};

export const DesktopFontWeights = {
  xs: 300,
  sm: 400,
  md: 500,
  lg: 400,
  xl: 700,
};
