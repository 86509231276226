import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { GetYouTubeResourceLink } from '../../../../../utils/LinkUtils';
import { YouTubeAdaptLeadershipStyleVideoId } from '../../../../../consts/YouTubeVideoIds';
import { Youtube } from '../../../../../Components/General/YouTube';

export const MoDSituationalMentorshipMentorStepOne = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md">
          There is no single best style of mentorship, it depends on the situation. There is a time
          and a place for: <strong>directive</strong> (telling), <strong>coach</strong>{' '}
          (questioning), <strong>support</strong> (encouraging) and <strong>challenge</strong>{' '}
          (feedback).
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">Watch the video below on situational mentorship.</Text>
      </Grid>
      <Grid item>
        <Youtube src={GetYouTubeResourceLink(YouTubeAdaptLeadershipStyleVideoId)} width="100%" />
      </Grid>
    </Grid>
  );
};
