import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { CharlieAvatar } from '../../avatars/Charlie';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const BeGreatMentorStepEight = () => {
  const stepEightValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentor.StepEight,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepEightCorrectVal = '0';
    dispatch(
      SaveBeGreatMentorData({
        StepEight: {
          selected: val,
          correct: StepEightCorrectVal === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Charlie responds...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          Charlie responds, "I'm wanting to learn more about business and management, as I'd like to
          become a better leader."
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          You respond with, "That's great. What have you tried so far to up-skill in this space?"
        </Text>
        <Text variant="md" display="inline" color="purple" fontWeight={700}>
          {' '}
          (reality)
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Alex replies saying "I've participated in a few short courses and it's really sparked my
          interest. I think I'd learn a lot by doing some further, more intense study."
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          How do you respond? Choose one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="What are your options for this? What could further more intense study look like?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="At what University will you do your study?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="What steps will you take to get there?"
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepEightValue"
          value={stepEightValue?.selected}
          updateValue={updateValue}
          noQuestion
          exclusive
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
