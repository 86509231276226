import { IReducerType } from './ReducerType';

export const FINALISING_FORM_DATA = 'FINALISING_FORM_DATA';
export const FINALISING_CLEAR_FORM_DATA = 'FINALISING_CLEAR_FORM_DATA';

export const FinalisingReducer = (state = initialState, action: IReducerType) => {
  switch (action.type) {
    case FINALISING_FORM_DATA:
      return { ...state, ...action.payload };
    case FINALISING_CLEAR_FORM_DATA:
      return initialState;
    default:
      return state;
  }
};

export interface IFinalisingReducer {
  configValues: any;
  submitted: boolean;
}

const initialState: IFinalisingReducer = {
  configValues: {},
  submitted: false,
};
