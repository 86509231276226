export const StepMapper = (answeredSteps: number, injectedStep?: number): string => {
  switch (injectedStep || answeredSteps) {
    case 0:
      return 'StepOne';
    case 1:
      return 'StepTwo';
    case 2:
      return 'StepThree';
    case 3:
      return 'StepFour';
    case 4:
      return 'StepFive';
    case 5:
      return 'StepSix';
    case 6:
      return 'StepSeven';
    case 7:
      return 'StepEight';
    case 8:
      return 'StepNine';
    case 9:
      return 'StepTen';
    case 10:
      return 'StepEleven';
    case 11:
      return 'StepTwelve';
    case 12:
      return 'StepThirteen';
    case 13:
      return 'StepFourteen';
    case 14:
      return 'StepFifteen';
    case 15:
      return 'StepSixteen';
    case 16:
      return 'StepSeventeen';
    case 17:
      return 'StepEighteen';
    case 18:
      return 'StepNineteen';
    case 19:
      return 'StepTwenty';
    default:
      return 'StepOne';
  }
};
