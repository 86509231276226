import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Text } from '../Components/General/Text';
import { NextButton } from '../Components/InputFields/BrancherButton';
import { BrancherToggleButton } from '../Components/InputFields/ToggleButton/BrancherToggleButton';
import { GetRoute } from '../Components/Routing';
import {
  CreateProgramPositionOptions,
  ICreateProgramPositionOptions,
  ProgramPositions,
} from '../QualifyingForm/ProgramPositionOptions';
import { SaveUserInfo } from '../store/actions';
import { IPairInfo } from '../store/reducers/UserInfoReducer';
import { IStoreTypes } from '../store/storeTypes';
import { Subscription } from '../types/SubscriptionTypes';

export const ChooseRole = () => {
  const { sessionPosition, subscription, positions, mentees, mentors } = useSelector(
    (state: IStoreTypes) => state.user,
  );
  const menteeRoleId = useSelector((state: IStoreTypes) => state.user?.menteeRoleId);
  const mentorRoleId = useSelector((state: IStoreTypes) => state.user?.mentorRoleId);
  const hasAdhocPairing = useSelector((state: IStoreTypes) => state.user?.hasAdhocPairing);
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const chosenSubscription = subscription;
  const dispatch = useDispatch();
  const [redirect, setRedirect] = React.useState(false);

  const filterRoles = (): ICreateProgramPositionOptions[] => {
    const programPositions = CreateProgramPositionOptions(roleLabels);
    programPositions.filter((a) => positions.includes(a.value));
    let positionsToChooseFrom: ICreateProgramPositionOptions[] = [];
    if (mentors?.length > 0) {
      positionsToChooseFrom.push(programPositions.find((a) => a.value === ProgramPositions.mentee));
    }
    if (mentees?.length > 0) {
      positionsToChooseFrom.push(programPositions.find((a) => a.value === ProgramPositions.mentor));
    }
    return hasAdhocPairing ? programPositions : positionsToChooseFrom;
  };

  const roles = filterRoles();

  const getSessionPair = (val: ProgramPositions): IPairInfo => {
    if (val === ProgramPositions.mentee) {
      return mentors?.[0];
    } else if (val === ProgramPositions.mentor) {
      return mentees?.[0];
    }
  };

  const updateSessionPositionCheckCompleted = (val: ProgramPositions) => {
    const currSessionPair = getSessionPair(val);
    dispatch(
      SaveUserInfo({
        sessionPosition: val,
        sessionPair: currSessionPair,
        sessionRoleId: val === ProgramPositions.mentee ? menteeRoleId : mentorRoleId,
      }),
    );
  };

  return (
    <Grid container justify="center">
      <Grid container direction="column" justify="center" item xs={10} sm={8} md={6} lg={5}>
        {redirect &&
          (!chosenSubscription.includes(Subscription.PROFILE) ? (
            <Redirect to={GetRoute('training').path} />
          ) : (
            <Redirect to={GetRoute('dashboard').path} />
          ))}
        <Grid item>
          <Text variant="xl" align="center" marginBottom={30}>
            Which role do you want to log into?
          </Text>
        </Grid>
        <Grid item>
          <BrancherToggleButton
            name="sessionPosition"
            value={sessionPosition}
            exclusive
            updateValue={(a: ProgramPositions) => updateSessionPositionCheckCompleted(a)}
            options={roles}
          />
        </Grid>
        <Grid item>
          <NextButton
            fullWidth
            onClick={() => {
              updateSessionPositionCheckCompleted(sessionPosition);
              setRedirect(true);
            }}
            disabled={!sessionPosition}
          >
            Next
          </NextButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
