import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';

export const MoDBeGreatMenteeStepEightCorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center">
        <Text variant="md" fontWeight={700}>
          Great! It sounds like you're almost ready to graduate this training and get started!
        </Text>
      </Grid>
    </Grid>
  );
};
