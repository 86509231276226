import {
  VALUES_FORM_DATA,
  VALUES_CLEAR_FORM_DATA,
  VALUES_FORM_DATA_V2,
} from '../reducers/ValuesReducer';

export const SaveValuesFormData = (userData: object) => {
  return {
    type: VALUES_FORM_DATA,
    payload: userData,
  };
};

export const SaveValuesFormDataV2 = (userData: object) => {
  return {
    type: VALUES_FORM_DATA_V2,
    payload: userData,
  };
};

export const ClearValuesFormData = () => {
  return {
    type: VALUES_CLEAR_FORM_DATA,
  };
};
