import * as React from 'react';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import Done from '@material-ui/icons/Done';
import { useSelector } from 'react-redux';
import { Text } from '../../Components/General/Text';
import { Colors } from '../../consts/colors';
import { IStoreTypes } from '../../store/storeTypes';

interface ITrainingStep {
  deadlineDate: string;
  deadlineYear: string;
  module: string;
  active: boolean;
  heading?: string;
  overdue?: boolean;
  completed?: boolean;
}

interface ITrainingStepper {
  steps: ITrainingStep[];
  scrollToModule: (mod: string) => void;
}

const useStyles = makeStyles({
  root: {
    '&.MuiStepper-root': {
      padding: 0,
      '& .MuiStepConnector-lineVertical': {
        minHeight: 50,
      },
    },
    '&.MuiStepper-root .MuiStepConnector-lineVertical': {
      borderLeft: `1px solid ${Colors.disabledGrey}`,
      backgroundImage: 'none',
    },
  },
  activeStep: {
    color: Colors.purple,
    fontSize: 15,
    marginLeft: 5,
  },
  overdueStep: {
    color: Colors.red,
    fontSize: 15,
    marginLeft: 5,
  },
  completed: {
    background: Colors.green,
    borderRadius: '50%',
    width: 10,
    height: 10,
    marginLeft: 8,
  },
  future: {
    background: Colors.lightGrey,
    borderRadius: '50%',
    width: 10,
    height: 10,
    marginLeft: 8,
  },
  doneIcon: {
    fontSize: 20,
    color: Colors.green,
  },
});

export const TrainingStepper: React.FC<ITrainingStepper> = (props) => {
  const hasAdhocPairing = useSelector((state: IStoreTypes) => state.user?.hasAdhocPairing);
  const { steps, scrollToModule } = props;
  const styles = useStyles();

  return (
    <Stepper orientation="vertical" className={styles.root}>
      {steps.map((step, index) => {
        return (
          <StepLabel
            onClick={() => scrollToModule(step.module)}
            key={index}
            active={step.active}
            icon={
              step.completed ? (
                <div className={styles.completed} />
              ) : step.active && !step.overdue ? (
                <RadioButtonChecked className={styles.activeStep} />
              ) : step?.overdue ? (
                <RadioButtonChecked className={styles.overdueStep} />
              ) : (
                <div className={styles.future} />
              )
            }
          >
            {step?.completed ? (
              <>
                <Text
                  variant="xs"
                  display="inline"
                  color="green"
                  marginRight={5}
                  fontWeight={600}
                  pointerOnHover
                >
                  Completed
                </Text>
                <Done className={styles.doneIcon} />
              </>
            ) : (
              <Text
                variant="xs"
                color={step?.overdue ? 'red' : 'purple'}
                noPreWrap
                fontWeight={600}
                pointerOnHover
              >
                {step?.deadlineDate ? (
                  <>
                    {step?.deadlineDate}
                    <br />
                    {step?.deadlineYear}
                  </>
                ) : step?.overdue ? (
                  'Overdue'
                ) : hasAdhocPairing ? (
                  step?.heading
                ) : (
                  'Complete now!'
                )}
              </Text>
            )}
          </StepLabel>
        );
      })}
    </Stepper>
  );
};
