import * as React from 'react';
import Popover from '@material-ui/core/Popover';
import { IBrancherDialog } from '../../../../Components/General/BrancherDialog';
import { Text } from '../../../../Components/General/Text';

interface ICompetencePopover extends IBrancherDialog {
  anchor: React.ElementRef<any>;
}

export const CompetencePopover = (props: ICompetencePopover) => {
  const { setClose, open, anchor, labelledBy } = props;
  return (
    <Popover
      open={open}
      onClose={setClose}
      id="competence-level-popover"
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      disableEnforceFocus
      disableAutoFocus
      aria-labelledby={labelledBy}
    >
      <>
        <Text variant="xs">
          Competence refers to your ability to successfully and effectively perform.
        </Text>
        <Text variant="xs">Confidence refers to your self belief that you can perform.</Text>
      </>
    </Popover>
  );
};
