import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import {
  IMentoringAgreement,
  ISharedNote,
  MENTORING_AGREEMENT_SAVE,
  SHARED_NOTES_ADD,
  SHARED_NOTES_ADD_ALL,
  SHARED_NOTES_CLEAR_DATA,
} from '../reducers/SharedNotesReducer';
import { IAPIResponseObject } from './actionTypes/apiTypes';
import { BrancherAuthRequest, BrancherDispatch } from './utils/api-utils';
import { UtilUpdateRoleProfileActivities } from './ProfileFormActions';
import { EProgressActivities } from '../reducers/ProfileFormReducer';
import { EUserActions } from '../reducers/UserInfoReducer';
import { UtilRemoveUserAction } from './UserInfoActions';

export const SaveSharedNotesData = (sharedNotesData: object) => {
  return {
    type: SHARED_NOTES_ADD_ALL,
    payload: sharedNotesData,
  };
};

export const ClearSharedNotesData = () => {
  return {
    type: SHARED_NOTES_CLEAR_DATA,
  };
};

export const SaveSharedNoteData = (sharedNotesData: ISharedNote) => {
  return {
    type: SHARED_NOTES_ADD,
    payload: sharedNotesData,
  };
};

export const SaveMentoringAgreementData = (mentoringAgreementData: IMentoringAgreement) => {
  return {
    type: MENTORING_AGREEMENT_SAVE,
    payload: mentoringAgreementData,
  };
};

export const UtilSaveUserSharedNotesData = (
  sharedNote: ISharedNote,
  cb: (a: IAPIResponseObject) => void,
) => {
  return (dispatch: any, getState: any) => {
    const sessionPosition = getState().user.sessionPosition;
    const partnerRoleId = getState().user?.sessionPair?.roleId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/sharednotes',
        data: {
          menteeRoleId:
            sessionPosition === ProgramPositions.mentee
              ? getState().user.menteeRoleId
              : partnerRoleId,
          mentorRoleId:
            sessionPosition === ProgramPositions.mentee
              ? partnerRoleId
              : getState().user.mentorRoleId,
          partnerRoleId,
          sharedNote,
        },
      },
      getState(),
    )
      .then((resp: any) => {
        const fullSharedNotesData = resp.data.data;
        const allSharedNotes = getState().sharedNotes?.sharedNotes;
        const newSharedNotes =
          allSharedNotes && !sharedNote?.noteId
            ? [fullSharedNotesData, ...allSharedNotes]
            : sharedNote?.noteId
            ? allSharedNotes.map((p) => {
                if (p.noteId === fullSharedNotesData.noteId) {
                  return fullSharedNotesData;
                } else {
                  return p;
                }
              })
            : [fullSharedNotesData];
        dispatch(SaveSharedNotesData(newSharedNotes));
        if (!sharedNote.noteId) {
          BrancherDispatch(
            dispatch,
            UtilUpdateRoleProfileActivities(EProgressActivities.HAS_CREATED_SHARED_NOTE, {
              partnerRoleId,
            }),
          );
        }
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilSaveUserMentoringAgreementData = (
  mentoringAgreement: IMentoringAgreement,
  cb: (a: IAPIResponseObject) => void,
) => {
  return (dispatch: any, getState: any) => {
    const sessionPosition = getState().user.sessionPosition;
    const partnerRoleId = getState().user?.sessionPair?.roleId;
    const isMentee = sessionPosition === ProgramPositions.mentee;
    const menteeRoleId = isMentee ? getState().user.menteeRoleId : partnerRoleId;
    const mentorRoleId = isMentee ? partnerRoleId : getState().user.mentorRoleId;
    const mentoringAgreementPreviouslyCompleted = getState().sharedNote?.mentoringAgreement
      ?.completed;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/sharednotes',
        data: {
          menteeRoleId,
          mentorRoleId,
          mentoringAgreement,
        },
      },
      getState(),
    )
      .then((resp: any) => {
        if (!mentoringAgreementPreviouslyCompleted && mentoringAgreement.completed) {
          const mentoringAgreementActions = getState().user.actions?.filter(
            (t) =>
              t.type === EUserActions.COMPLETE_MENTORING_AGREEMENT &&
              t.action?.actionAttributes?.partnerRoleId === partnerRoleId,
          );
          if (mentoringAgreementActions?.length > 0) {
            BrancherDispatch(
              dispatch,
              UtilRemoveUserAction(mentoringAgreementActions[0].userActionId, () => {}),
            );
          }
          BrancherDispatch(
            dispatch,
            UtilUpdateRoleProfileActivities(
              EProgressActivities.HAS_COMPLETED_MENTORING_AGREEMENT,
              { partnerRoleId: mentorRoleId },
              menteeRoleId,
            ),
          );
          BrancherDispatch(
            dispatch,
            UtilUpdateRoleProfileActivities(
              EProgressActivities.HAS_COMPLETED_MENTORING_AGREEMENT,
              { partnerRoleId: menteeRoleId },
              mentorRoleId,
            ),
          );
        }
        dispatch(SaveMentoringAgreementData(mentoringAgreement));
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilGetUserSharedNotes = (cb: (a: IAPIResponseObject) => void) => {
  return (dispatch: any, getState: any) => {
    const sessionPosition = getState().user.sessionPosition;
    const partnerRoleId = getState().user?.sessionPair?.roleId;
    dispatch(SaveSharedNotesData([]));
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/sharednotes',
        params: JSON.stringify({
          menteeRoleId:
            sessionPosition === ProgramPositions.mentee
              ? getState().user.menteeRoleId
              : partnerRoleId,
          mentorRoleId:
            sessionPosition === ProgramPositions.mentee
              ? partnerRoleId
              : getState().user.mentorRoleId,
        }),
      },
      getState(),
    )
      .then((resp: any) => {
        const shared = resp.data.data;
        if (shared?.sharedNotes?.length > 0) {
          dispatch(SaveSharedNotesData(shared.sharedNotes));
        }
        dispatch(SaveMentoringAgreementData(shared?.mentoringAgreement));
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilDeleteUserSharedNotes = (noteId: string, cb: (a?: IAPIResponseObject) => void) => {
  return (dispatch: any, getState: any) => {
    const sessionPosition = getState().user.sessionPosition;
    const partnerRoleId = getState().user?.sessionPair?.roleId;
    BrancherAuthRequest(
      {
        method: 'delete',
        url: 'v2/sharednotes',
        data: {
          noteId,
          menteeRoleId:
            sessionPosition === ProgramPositions.mentee
              ? getState().user.menteeRoleId
              : partnerRoleId,
          mentorRoleId:
            sessionPosition === ProgramPositions.mentee
              ? partnerRoleId
              : getState().user.mentorRoleId,
        },
      },
      getState(),
    )
      .then((resp) => {
        const sharedNotes = resp.data.data;
        dispatch(SaveSharedNotesData(sharedNotes));
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};
