import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { GetRoute } from '../../../../../Components/Routing';
import { CreateMentoringGuideLink } from '../../helpers/LinkBuilder';

export const IntroductionMenteeDescriptionTwo = () => {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" display="inline">
          Mentors and mentees have some shared responsibilities, but there are also things that each
          is distinctly responsible for. For example, the mentee is ultimately responsible for their
          own development and the mentor is a guide and coach, not a director.{' '}
        </Text>
        <BrancherLinkText
          underline="always"
          fontWeight={700}
          variant="md"
          href="https://media.brancher.com.au/training/introductionMentee/mentor_vs_mentee_responsibilities.pdf"
          display="inline"
          target="_blank"
        >
          Click here
        </BrancherLinkText>
        <Text variant="md" display="inline">
          {' '}
          for a quick comparison.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          The best thing you can do for the success of your relationship is to review the{' '}
        </Text>
        <Text variant="md" fontWeight={700} color="purple" display="inline">
          'what is expected of me'{' '}
        </Text>
        <Text variant="md" display="inline">
          pages in the{' '}
        </Text>
        <BrancherLinkText
          underline="always"
          fontWeight={700}
          variant="md"
          href={CreateMentoringGuideLink()}
          display="inline"
          target="_blank"
        >
          Mentoring Guide
        </BrancherLinkText>
        <Text variant="md" display="inline">
          {' '}
          and establish your{' '}
        </Text>
        <BrancherLinkText
          underline="always"
          fontWeight={700}
          variant="md"
          href={GetRoute('mentoringAgreement').path}
          display="inline"
          target="_blank"
        >
          Mentoring Agreement
        </BrancherLinkText>
        <Text variant="md" display="inline">
          {' '}
          at the first meeting. You can fine-tune your expectations of each other in there.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          The next few questions present scenarios you may experience with suggestions for working
          through them.
        </Text>
      </Grid>
    </Grid>
  );
};
