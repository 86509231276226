import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { CharlieAvatar } from '../../avatars/Charlie';

export const BeGreatMentorStepNineCorrect = () => {
  const stepNineValue = useSelector((state: IStoreTypes) => state.training.beGreatMentor.StepNine);
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepNineValue.correct} incorrect={!stepNineValue.correct}>
          <Text variant="md" fontWeight={700}>
            {stepNineValue.correct ? "That's correct" : 'Not quite'}.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          {stepNineValue.correct ? (
            <Grid item>
              <Text variant="sm" display="inline">
                You are exploring{' '}
              </Text>
              <Text variant="sm" display="inline" color="purple" fontWeight={700}>
                Tactics
              </Text>
              <Text variant="sm" display="inline">
                {' '}
                (the second last step in the GROWTH framework).
              </Text>
            </Grid>
          ) : (
            <Grid item>
              <Text variant="sm" display="inline">
                These questions skip ahead to exploring{' '}
              </Text>
              <Text variant="sm" display="inline" color="purple" fontWeight={700}>
                Habits
              </Text>
              <Text variant="sm" display="inline">
                {' '}
                before you have explored{' '}
              </Text>
              <Text variant="sm" display="inline" color="purple" fontWeight={700}>
                Tactics
              </Text>
              <Text variant="sm" display="inline">
                .
              </Text>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
