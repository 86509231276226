import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { AlexAvatar } from '../../avatars/Alex';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveIntroductionMentee } from '../../../../../store/actions';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const IntroductionMenteeStepTwo = () => {
  const stepTwoValue = useSelector((state: IStoreTypes) => state.training.introductionMentee);
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepTwoCorrectVals = ['0', '1', '2'];
    dispatch(
      SaveIntroductionMentee({
        ...stepTwoValue,
        StepTwo: {
          selected: val,
          correct:
            StepTwoCorrectVals.includes(val[0]) ||
            StepTwoCorrectVals.includes(val[1]) ||
            StepTwoCorrectVals.includes(val[2]),
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <AlexAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            You’ve been meeting with Alex and finding the conversations have improved.
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          Alex is allowing you to talk more. However, you are finding that if you present a problem,
          Alex jumps straight to solutions and isn't seeking to understand more about the situation,
          the problem or how you're feeling about it. What should you do?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You can choose more than one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="Let Alex know that you value their insights, and you’d love to hear why the problem might be occurring."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="Ask Alex to share their experiences with similar situations."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="Ask Alex to challenge you by asking you questions about the problem, because you’d really like the skills to be able to solve this problem and others that come up."
        />
      </Grid>
      <Grid item container justify="center">
        <SelectionControl
          question=""
          name="stepTwoValue"
          value={stepTwoValue.StepTwo?.selected}
          updateValue={updateValue}
          thirdWidth
          noQuestion
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
