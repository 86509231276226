import * as React from 'react';
import { ModuleWrapper } from '../ModuleWrapper';
import { BeGreatMentorModuleMapper } from './BeGreatMentorModuleMapper';

export const BeGreatMentor = () => (
  <ModuleWrapper
    moduleMap={BeGreatMentorModuleMapper}
    moduleName="beGreatMentor"
    title="How to be a great mentor"
  />
);
