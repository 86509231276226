import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMoDBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { AlexAvatar } from '../../avatars/Alex';

export const MoDBeGreatMentorStepSix = () => {
  const stepSixValue = useSelector(
    (state: IStoreTypes) => state.training.modBeGreatMentor?.StepSix,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepSixIncorrectVal = '2';
    dispatch(
      SaveMoDBeGreatMentorData({
        StepSix: {
          selected: val,
          correct: !val?.includes(StepSixIncorrectVal),
        },
      }),
    );
  };

  const options: string[] = [
    'Take a coaching approach and ask Alex some questions to unpack why they may not be setting the meeting agendas.',
    'Tell Alex to create the meeting agendas going forward.',
    "Continue the way you are. Alex is not the proactive type so it's easier for you to continue setting the agenda.",
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <AlexAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            You called Alex and there was a legitimate reason for why they had not scheduled a catch
            up. You’ve met a few times but you’re finding that you’re setting the meeting agenda and
            directing the conversation. What should you do?
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="sm" marginBottom={-30}>
          You can choose more than 1 option.
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepSixValue"
          value={stepSixValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(options)}
        />
      </Grid>
    </Grid>
  );
};
