import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { Text } from '../../../../../Components/General/Text';

interface IMakeTheMostQualifyingTwo {
  save: (a: object) => void;
  module: string;
}

export const MakeTheMostQualifyingTwo = (props: IMakeTheMostQualifyingTwo) => {
  const { module, save } = props;
  const date = useSelector((state: IStoreTypes) => state.training[module])?.firstMeetingDate;
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    dispatch(save({ firstMeetingDate: val }));
  };

  return (
    <>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={20} marginTop={50}>
          That's great, let's continue!
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="sm" marginBottom={30}>
          Please input the date of your first meeting
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Box marginBottom={4}>
          <BrancherTextField value={date} updateValue={updateValue} type="date" fullWidth />
        </Box>
      </Grid>
    </>
  );
};
