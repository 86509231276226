import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherDispatch, UtilGetRoleProfile } from '../../store/actions';
import { IMentoringRequest } from '../../store/reducers/CohortReducer';
import { IProfileData } from '../../store/reducers/ProfileFormReducer';
import { AvailableMentoringPartner } from './AvailableMentoringPartner';
import { IStoreTypes } from '../../store/storeTypes';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';

interface IMentoringPartnerRequest {
  mentoringRequesterRoleId: string;
  requestDetails: IMentoringRequest;
  cancellable?: boolean;
}

export const MentoringPartnerRequest: React.FC<IMentoringPartnerRequest> = ({
  mentoringRequesterRoleId,
  requestDetails,
  cancellable,
}) => {
  const [gettingProfileData, setGettingProfileData] = React.useState<boolean>(false);
  const sessionPosition = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isSentByCurrentUserRole: boolean = sessionPosition === requestDetails.requestInitiator;
  const [profileData, setProfileData] = React.useState<IProfileData>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    setGettingProfileData(true);
    let profileDisplayId = mentoringRequesterRoleId;
    if (isSentByCurrentUserRole) {
      profileDisplayId =
        sessionPosition === ProgramPositions.mentor
          ? requestDetails.menteeRoleId
          : requestDetails.mentorRoleId;
    }
    BrancherDispatch(
      dispatch,
      UtilGetRoleProfile(profileDisplayId, (userProfile) => {
        setProfileData(userProfile);
        setGettingProfileData(false);
      }),
    );
  }, []);

  return (
    <Grid container justify="space-between">
      {!gettingProfileData && profileData && (
        <AvailableMentoringPartner
          requestDetails={requestDetails}
          pairingData={requestDetails?.pairingData}
          availablePairingProfile={profileData}
          cancellable={cancellable}
        />
      )}
    </Grid>
  );
};
