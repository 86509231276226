import * as React from 'react';
import { ModuleWrapper } from '../ModuleWrapper';
import { BeyondProgramMenteeModuleMapper } from './BeyondProgramMenteeModuleMapper';
import { EStructuredTrainingModules } from '../../../../store/reducerTypes/TrainingReducer.types';

export const BeyondProgramMentee = () => (
  <ModuleWrapper
    moduleMap={BeyondProgramMenteeModuleMapper()}
    moduleName={EStructuredTrainingModules.BEYOND_PROGRAM_MENTEE}
    title="Wrap up your relationship"
  />
);
