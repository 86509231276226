import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { PageLayout } from '../../Components/General/PageLayout';
import { ActionsSection } from '../Dashboard/ActionsSection';
import { OnboardingSteps } from './OnboardingSteps';

export const Actions: React.FC = () => {
  return (
    <PageLayout
      pageTitle="Your Tasks"
      titleDivider={true}
      hasFeaturePageLayout={true}
      backButtonRoute="dashboard"
      backButtonLabel="Dashboard"
    >
      <Grid container item spacing={4} justify="space-between">
        <Grid item md={7}>
          <ActionsSection scrollable={false} />
        </Grid>
        <Grid item md={5}>
          <OnboardingSteps />
        </Grid>
      </Grid>
    </PageLayout>
  );
};
