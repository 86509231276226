import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Form } from '../../Form/Form';
import { Colors } from '../../consts/colors';

const useStyles = makeStyles({
  home: {
    background: Colors.backgroundGrey,
    minHeight: 'inherit',
  },
});

export const Home = () => {
  const styles = useStyles();

  return (
    <Grid container alignItems="center" justify="center" className={styles.home}>
      <Grid item xs={12}>
        <Form />
      </Grid>
    </Grid>
  );
};
