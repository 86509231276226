import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { ActionButton } from '../../Components/InputFields/BrancherButton';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';

interface IRequestMentoringPartnerForm {
  submitMentoringRequestForm: (message: string) => void;
  creatingMentoringRequest: boolean;
}

export const RequestMentoringPartnerForm: React.FC<IRequestMentoringPartnerForm> = (props) => {
  const [requestMessage, setRequestMessage] = React.useState<string>('');
  const { submitMentoringRequestForm, creatingMentoringRequest } = props;

  return (
    <Grid container justify="center" spacing={3} alignItems="center">
      <Grid item xs={12} md={11}>
        <BrancherTextField
          label="Send an introductory message"
          value={requestMessage}
          fullWidth
          updateValue={setRequestMessage}
        />
      </Grid>
      <Grid item xs={12} md={11} container justify="flex-end">
        <ActionButton
          onClick={() => submitMentoringRequestForm(requestMessage)}
          loading={creatingMentoringRequest}
        >
          Send mentoring request
        </ActionButton>
      </Grid>
    </Grid>
  );
};
