import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { MakeTheMostMenteeStepOne } from '../MakeTheMostMentee/Steps/MakeTheMostMenteeStepOne';
import { MakeTheMostMenteeStepTwo } from '../MakeTheMostMentee/Steps/MakeTheMostMenteeStepTwo';
import { MakeTheMostMenteeStepThree } from '../MakeTheMostMentee/Steps/MakeTheMostMenteeStepThree';
import { MakeTheMostMenteeStepFour } from '../MakeTheMostMentee/Steps/MakeTheMostMenteeStepFour';
import { MakeTheMostMenteeStepFive } from '../MakeTheMostMentee/Steps/MakeTheMostMenteeStepFive';
import { MakeTheMostMenteeStepSix } from '../MakeTheMostMentee/Steps/MakeTheMostMenteeStepSix';
import { MakeTheMostMenteeStepSeven } from '../MakeTheMostMentee/Steps/MakeTheMostMenteeStepSeven';
import { MakeTheMostMenteeStepEight } from '../MakeTheMostMentee/Steps/MakeTheMostMenteeStepEight';
import { MakeTheMostMenteeStepNine } from '../MakeTheMostMentee/Steps/MakeTheMostMenteeStepNine';
import { MakeTheMostMenteeStepTen } from '../MakeTheMostMentee/Steps/MakeTheMostMenteeStepTen';
import { MakeTheMostMenteeStepEleven } from '../MakeTheMostMentee/Steps/MakeTheMostMenteeStepEleven';
import { MakeTheMostMenteeStepThirteen } from '../MakeTheMostMentee/Steps/MakeTheMostMenteeStepThirteen';

export const MakeTheMostMentorModuleMapper: IModuleMapper[] = [
  {
    name: 'MakeTheMostMentorStepOne',
    component: <MakeTheMostMenteeStepOne />,
    iterateStepper: true,
    needsInteraction: true,
    nextComponent: 'MakeTheMostMentorStepTwo',
  },
  {
    name: 'MakeTheMostMentorStepTwo',
    component: <MakeTheMostMenteeStepTwo />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MakeTheMostMentorStepOne',
    nextComponent: 'MakeTheMostMentorStepThree',
  },
  {
    name: 'MakeTheMostMentorStepThree',
    component: <MakeTheMostMenteeStepThree />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MakeTheMostMentorStepTwo',
    nextComponent: 'MakeTheMostMentorStepFour',
  },
  {
    name: 'MakeTheMostMentorStepFour',
    component: <MakeTheMostMenteeStepFour />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'MakeTheMostMentorStepThree',
    nextComponent: 'MakeTheMostMentorStepFive',
  },
  {
    name: 'MakeTheMostMentorStepFive',
    component: <MakeTheMostMenteeStepFive />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MakeTheMostMentorStepFour',
    nextComponent: 'MakeTheMostMentorStepSix',
  },
  {
    name: 'MakeTheMostMentorStepSix',
    component: <MakeTheMostMenteeStepSix />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MakeTheMostMentorStepFive',
    nextComponent: 'MakeTheMostMentorStepSeven',
  },
  {
    name: 'MakeTheMostMentorStepSeven',
    component: <MakeTheMostMenteeStepSeven />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MakeTheMostMentorStepSix',
    nextComponent: 'MakeTheMostMentorStepEight',
  },
  {
    name: 'MakeTheMostMentorStepEight',
    component: <MakeTheMostMenteeStepEight />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MakeTheMostMentorStepSeven',
    nextComponent: 'MakeTheMostMentorStepNine',
  },
  {
    name: 'MakeTheMostMentorStepNine',
    component: <MakeTheMostMenteeStepNine />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MakeTheMostMentorStepEight',
    nextComponent: 'MakeTheMostMentorStepTen',
  },
  {
    name: 'MakeTheMostMentorStepTen',
    component: <MakeTheMostMenteeStepTen />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MakeTheMostMentorStepNine',
    nextComponent: 'MakeTheMostMentorStepEleven',
  },
  {
    name: 'MakeTheMostMentorStepEleven',
    component: <MakeTheMostMenteeStepEleven />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'MakeTheMostMentorStepTen',
    nextComponent: 'MakeTheMostMentorStepTwelve',
  },
  {
    name: 'MakeTheMostMentorStepTwelve',
    component: <MakeTheMostMenteeStepThirteen />,
    iterateStepper: true,
    needsInteraction: true,
    submit: true,
    previousComponent: 'MakeTheMostMentorStepEleven',
  },
];
