import * as React from 'react';
import { ModuleWrapper } from '../ModuleWrapper';
import { MakeTheMostMenteeModuleMapper } from './MakeTheMostMenteeModuleMapper';

export const MakeTheMostMentee = () => (
  <ModuleWrapper
    moduleMap={MakeTheMostMenteeModuleMapper}
    moduleName="makeTheMostMentee"
    title="Making the most of mentoring"
  />
);
