import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { GetSharedResourceLink } from '../../utils/LinkUtils';
import { Text } from '../../Components/General/Text';
import {
  BrancherDispatch,
  UtilGetAzureCalendarScopeLink,
  UtilGetAzureCalendarToken,
} from '../../store/actions';
import { ECalendars } from './Scheduler';
import { IAddCalendar } from './AddCalendar';

export const AzureCalendar: React.FC<IAddCalendar> = ({ getEvents, redirectPath }) => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [authUrl, setAuthUrl] = React.useState<string>('');
  const params = new URLSearchParams(search);
  const code = params.get('code');
  const azureState = params.get('state');

  React.useEffect(() => {
    if (azureState && code) {
      const stateValue = azureState.split(',');
      if (stateValue[0] === ECalendars.AZURE) {
        const origin = stateValue[1];
        const statefulOrigin = stateValue[2];
        if (origin !== window.location.origin) {
          window.location.replace(
            `${origin}${window.location.pathname}?code=${code}&state=${azureState}`,
          );
        }
        BrancherDispatch(
          dispatch,
          UtilGetAzureCalendarToken(code, redirectPath, (res) => {
            params.delete('code');
            if (statefulOrigin.indexOf('group') !== -1) {
              window.location.replace(`${origin}${statefulOrigin}`);
            }
            getEvents(ECalendars.AZURE);
          }),
        );
      }
    }
  }, [code]);

  React.useEffect(() => {
    BrancherDispatch(
      dispatch,
      UtilGetAzureCalendarScopeLink(redirectPath, (res) => {
        if (res.data?.redirectAuthUrl) {
          setAuthUrl(res.data?.redirectAuthUrl);
        } else {
          getEvents(ECalendars.AZURE);
        }
      }),
    );
  }, []);

  return (
    <Grid container direction="column" alignItems="center">
      {authUrl ? (
        <BrancherButton onClick={() => window.location.assign(authUrl)}>
          <img
            height={30}
            alt="azure-calendar-logo"
            src={GetSharedResourceLink('azureCalendarIcon.png')}
          />
          <Text variant="xs" color="tertiaryGrey" marginLeft={15} fontWeight={600}>
            Outlook Calendar
          </Text>
        </BrancherButton>
      ) : (
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12}>
            <CircularProgress color="secondary" size={64} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
