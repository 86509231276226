import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { BrancherDottedDivider } from '../../Components/General/BrancherDivider';
import { NextButton } from '../../Components/InputFields/BrancherButton';
import { Text } from '../../Components/General/Text';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { UtilInitialiseForgotPassword } from '../../store/actions';
import { useDispatch } from 'react-redux';
import { BackSplashImagery } from '../Login/BackSplashImagery';
import { Redirect } from 'react-router-dom';
import { GetRoute } from '../../Components/Routing';
import { BrancherRouterLink } from '../../Components/General/BrancherRouterLink';

export const ForgotPassword = () => {
  const [email, setEmail] = React.useState('');
  const [waiting, updateWaiting] = React.useState(false);
  const [initialised, hasInitialised] = React.useState(false);
  const [fieldErrors, setFieldErrors] = React.useState([]);
  const [apiError, setApiError] = React.useState('');
  const dispatch = useDispatch();

  const initialiseForgotPassword = () => {
    const errors = validateEmailField();
    if (errors.length === 0) {
      if (fieldErrors.length > 0) {
        setFieldErrors([]);
      }
      dispatch(
        UtilInitialiseForgotPassword(email, (response) => {
          if (response.success) {
            hasInitialised(true);
          } else {
            updateWaiting(false);
            setApiError(response.message);
          }
        }),
      );
    } else {
      setFieldErrors(errors);
    }
  };

  // Checks that all the values are filled
  const validateEmailField = (): string[] => {
    const errors: string[] = [];
    if (email === '') {
      errors.push('email');
    }
    return errors;
  };

  return (
    <BackSplashImagery>
      {initialised && <Redirect to={GetRoute('verifyForgotPassword').path} />}
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item xs={12}>
          <Grid container direction="column" justify="center">
            <Text variant="xl" fontWeight={600}>
              Forgot Password?
            </Text>
            <BrancherDottedDivider marginTop={40} marginBottom={40} />
            <Text variant="sm" marginBottom={10}>
              Enter your email address and we will send you a verification code to help reset your
              password
            </Text>
            {apiError && (
              <Text variant="xs" color="red" marginTop={15} marginBottom={15}>
                {apiError}
              </Text>
            )}
            <Grid item>
              <BrancherTextField
                name="email"
                value={email}
                updateValue={setEmail}
                placeholder="Email Address"
                label="Email Address"
                error={fieldErrors.includes('email')}
                helpText={fieldErrors.includes('email') && 'Please enter a valid email!'}
                fullWidth
              />
            </Grid>
            <Box marginBottom={4} />
            <NextButton fullWidth onClick={initialiseForgotPassword} disabled={waiting}>
              Send
            </NextButton>
            <Box marginTop={4} />
            <Box display="inline" textAlign="center">
              <Text variant="sm" display="inline">
                Remember your password?
              </Text>
              <BrancherRouterLink to={GetRoute('login').path}>
                <Text
                  variant="sm"
                  color="purple"
                  display="inline"
                  textDecoration="underline"
                  marginLeft={6}
                >
                  Login
                </Text>
              </BrancherRouterLink>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </BackSplashImagery>
  );
};
