import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import capitalize from '@material-ui/core/utils/capitalize';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SaveMakeTheMostMentee, SaveMakeTheMostMentor } from '../../../../../store/actions';
import { TrainingIntroduction } from '../../../TrainingIntroduction';
import {
  getTrainingModuleHeading,
  getTrainingModuleNumber,
} from '../../../TrainingModuleDefaultConfig';
import { Text } from '../../../../../Components/General/Text';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { CompetencePopover } from '../../sharedComps/CompetencePopover';
import { CompetenceScale } from '../../sharedComps/CompetenceScale';
import { BrancherQuestionMarker } from '../../../../../Components/General/BrancherQuestionMarker';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { ConfidenceScale } from '../../sharedComps/ConfidenceScale';
import { MakeTheMostLearningObjectives } from './MakeTheMostLearningObjectives';

export const MakeTheMostSplash = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const trainingModules = useSelector((state: IStoreTypes) => state.training.modules)[sesPos];
  const trainingConfig = useSelector((state: IStoreTypes) => state.training.moduleConfig)[sesPos];
  const modulePathPos =
    sesPos === ProgramPositions.mentee ? ProgramPositions.mentee : ProgramPositions.mentor;
  const modName = `makeTheMost${capitalize(modulePathPos)}`;
  const makeTheMostBaseline = useSelector((state: IStoreTypes) => state.training[modName]);
  const dispatch = useDispatch();
  const [anchorEl, setAnchor] = React.useState(null);
  const open = Boolean(anchorEl);

  const controlValidator = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!Boolean(anchorEl)) {
      setAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(anchorEl)) {
      setAnchor(null);
    }
  };

  const updateBaseline = (val: string, attr: string) => {
    if (sesPos === ProgramPositions.mentee) {
      dispatch(SaveMakeTheMostMentee({ [attr]: val }));
    } else {
      dispatch(SaveMakeTheMostMentor({ [attr]: val }));
    }
  };

  return (
    <TrainingIntroduction
      title={`Training #${getTrainingModuleNumber(
        modName,
        trainingModules,
        trainingConfig,
      )}: ${getTrainingModuleHeading(modName, trainingConfig)}`}
      subHeader="Complete this training to learn more about the power of reflection and how to set SMART goals."
      activityLearnings={MakeTheMostLearningObjectives()}
      modulePath={modName}
      validatedValue={
        makeTheMostBaseline?.competenceBaseline != null &&
        makeTheMostBaseline?.confidenceBaseline != null
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box marginTop={3} marginBottom={3}>
            <Text variant="sm" fontWeight={700} display="inline">
              To provide us with a baseline measure, please rate your current level of{' '}
            </Text>
            <Text variant="sm" fontWeight={700} display="inline" color="purple">
              competence and confidence
            </Text>
            <Text variant="sm" fontWeight={700} display="inline">
              {' '}
              to perform the behaviours above.{' '}
            </Text>
            <BrancherQuestionMarker onClick={controlValidator} />
          </Box>
          <CompetencePopover
            open={open}
            setClose={closeValidator}
            anchor={anchorEl}
            labelledBy="Confidence and competence"
          />
          <Grid item>
            <Text variant="sm" fontWeight={700} marginBottom={10}>
              Please rate your competence:
            </Text>
          </Grid>
          <CompetenceScale />
          <SegmentedControlGroup
            value={makeTheMostBaseline?.competenceBaseline}
            valueLength={7}
            updateValue={(v: string) => updateBaseline(v, 'competenceBaseline')}
            fullWidth
          />
          <Grid item>
            <Text variant="sm" fontWeight={700} marginBottom={10}>
              Please rate your confidence:
            </Text>
          </Grid>
          <ConfidenceScale />
          <SegmentedControlGroup
            value={makeTheMostBaseline?.confidenceBaseline}
            valueLength={7}
            updateValue={(v: string) => updateBaseline(v, 'confidenceBaseline')}
            fullWidth
          />
        </Grid>
      </Grid>
    </TrainingIntroduction>
  );
};
