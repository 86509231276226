import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Text } from '../../../../../Components/General/Text';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { SuggestionCard } from '../../SuggestionCard';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';
import { useDispatch, useSelector } from 'react-redux';
import {
  SaveGivingReceivingFeedbackMenteeData,
  SaveGivingReceivingFeedbackMentorData,
} from '../../../../../store/actions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const GivingReceivingFeedbackMenteeStepEleven = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const sessionSaveFunction =
    position === ProgramPositions.mentee
      ? SaveGivingReceivingFeedbackMenteeData
      : SaveGivingReceivingFeedbackMentorData;
  const dispatch = useDispatch();

  const updateValue = () => {
    dispatch(
      sessionSaveFunction({
        StepEleven: {
          interacted: true,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700}>
          Thanks for learning about giving and receiving feedback using the MASTER model.
        </Text>
      </Grid>
      <Grid item xs={12} container justify="center">
        <BrancherTrainingImage
          src="https://media.brancher.com.au/training/givingReceivingFeedbackMentee/master_ideas_thought.png"
          alt="master-ideas-and-thoughts"
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700}>
          The next steps are:
        </Text>
      </Grid>
      <Box marginLeft={2}>
        <BrancherList
          fontWeight={400}
          variant="md"
          listItems={[
            <>
              <Text variant="md" display="inline">
                Download the{' '}
              </Text>
              <BrancherLinkText
                onClick={updateValue}
                variant="md"
                display="inline"
                target="_blank"
                fontWeight={600}
                underline="always"
                href="https://media.brancher.com.au/training/givingReceivingFeedbackMentee/receiving_feedback_effectively.pdf"
              >
                Brancher receiving feedback pocketbook
              </BrancherLinkText>
              <Text variant="md" display="inline">
                {' '}
                and the{' '}
              </Text>
              <BrancherLinkText
                onClick={updateValue}
                variant="md"
                display="inline"
                fontWeight={600}
                target="_blank"
                underline="always"
                href="https://media.brancher.com.au/training/givingReceivingFeedbackMentee/giving_feedback.pdf"
              >
                Brancher giving feedback pocketbook
              </BrancherLinkText>
              <Text variant="md" display="inline">
                .
              </Text>
            </>,
            <>
              <Text variant="md" fontWeight={700} display="inline">
                Give and receive feedback
              </Text>
              <Text variant="md" display="inline">
                {' '}
                at least{' '}
              </Text>
              <Text variant="md" fontWeight={700} display="inline">
                every three to four months
              </Text>
              <Text variant="md" display="inline">
                {' '}
                during the mentoring program - set a calendar reminder if that's helpful
              </Text>
            </>,
            <>
              <Text variant="md" fontWeight={700} display="inline">
                Test out
              </Text>
              <Text variant="md" display="inline">
                {' '}
                the{' '}
              </Text>
              <Text variant="md" fontWeight={700} display="inline">
                MASTER
              </Text>
              <Text variant="md" display="inline">
                {' '}
                model to give feedback (for your mentoring partner and/or a friend or colleague)
              </Text>
            </>,
          ]}
        />
      </Box>
      <Grid item xs={12}>
        <SuggestionCard>
          Make sure you click the "✓" button to complete this training.
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
