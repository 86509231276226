import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SonalAvatar } from '../../avatars/Sonal';
import { SuggestionCard } from '../../SuggestionCard';
import { TryAgainCard } from '../../sharedComps/TryAgainCard';

export const GivingReceivingFeedbackMenteeStepThreeIncorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard incorrect custom>
          <Grid item>
            <Text variant="md" display="inline" fontWeight={700}>
              That's not the best option.
            </Text>
            <Text variant="md" display="inline">
              {' '}
              It does not state what you enjoy about the relationship or end with a question.
            </Text>
          </Grid>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="center" alignItems="center" spacing={2}>
        <Grid item xs={8} md={4} container justify="center">
          <SonalAvatar />
        </Grid>
        <Grid item xs={12} md={8}>
          <Text variant="md" display="inline">
            Tips:
          </Text>
          <Text variant="md" display="inline" fontWeight={700} color="purple">
            {' '}
            you should{' '}
          </Text>
          <Text variant="md" display="inline">
            provide a motivation
          </Text>
          <Text variant="md" fontWeight={700} display="inline">
            {' '}
            to change behaviour,{' '}
          </Text>
          <Text variant="md" fontWeight={700} display="inline">
            state what you enjoy
          </Text>
          <Text variant="md" display="inline">
            {' '}
            about the relationship and{' '}
          </Text>
          <Text variant="md" fontWeight={700} display="inline">
            ask a question
          </Text>
          <Text variant="md" display="inline">
            {' '}
            to get buy-in.
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <TryAgainCard />
      </Grid>
    </Grid>
  );
};
