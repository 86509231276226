import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { AboutApplication } from './AboutApplication';
import { TnCs } from './TnCs';
import { ProgramPosition } from './ProgramPosition';

export interface IQualifyingFormState {
  step: number;
  setStep?: (a: number) => void;
}

export const QualifyingForm = () => {
  const [step, setStep] = React.useState(0);

  return (
    <Grid container direction="row" alignItems="center" justify="center">
      <Grid item xs={10} md={8} lg={6}>
        {step === 0 ? (
          <ProgramPosition step={step} setStep={setStep} />
        ) : step === 1 ? (
          <AboutApplication step={step} setStep={setStep} />
        ) : (
          <TnCs step={step} setStep={setStep} />
        )}
      </Grid>
    </Grid>
  );
};
