import { IReducerType } from './ReducerType';

export const GENERAL_FORM_DATA = 'GENERAL_FORM_DATA';
export const GENERAL_CLEAR_FORM_DATA = 'GENERAL_CLEAR_FORM_DATA';
export const GENERAL_CLEAR_USER_RESPONSE_DATA = 'GENERAL_CLEAR_USER_RESPONSE_DATA';

export const GeneralFormReducer = (state = initialState, action: IReducerType) => {
  switch (action.type) {
    case GENERAL_CLEAR_FORM_DATA:
      return initialState;
    case GENERAL_CLEAR_USER_RESPONSE_DATA:
      return { configValues: state.configValues };
    case GENERAL_FORM_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export interface IGeneralFormReducer {
  configValues: any;
}

const initialState: IGeneralFormReducer = {
  configValues: {},
};
