import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { CharlieAvatar } from '../../avatars/Charlie';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const BeGreatMentorStepFour = () => {
  const stepFourValue = useSelector((state: IStoreTypes) => state.training.beGreatMentor.StepFour);
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepFourCorrectVal = ['0', '2', '3'];
    dispatch(
      SaveBeGreatMentorData({
        StepFour: {
          selected: val,
          correct:
            StepFourCorrectVal.includes(val[0]) &&
            StepFourCorrectVal.includes(val[1]) &&
            StepFourCorrectVal.includes(val[2]) &&
            val.length === 3,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            You respond to Charlie...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          Taking a directive role, you respond to Charlie saying, "It's OK, I'm here to help. How
          can I help you?"
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Charlie responds saying, "Within the presentation, I need to create a communications plan,
          and I have no idea how to do that - where do I start?"
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You can choose more than one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="I would start by talking to someone in your organisation who is likely to have developed a communication plan before. Asking them for a template and if they have any advice. That way you'll be delivering something that is aligned to the way your organisation usually presents communication plans."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions optionHeader="B." optionText="Have you tried Google?" />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="Is there anyone else who works closely with the senior executive you're delivering the presentation for who you could catch up with for some quick tips? They may have some good insights and could help clarify expectations for you."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="D."
          optionText="I'd be happy to look over what you have developed so far and provide feedback, if that would be helpful? I won't have the 'insider' expertise around how your organisation likes things to be done but I can provide feedback based on my experience."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="E."
          optionText="How do you think you should go about developing a communications plan?"
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepFourValue"
          value={stepFourValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(['A', 'B', 'C', 'D', 'E'])}
        />
      </Grid>
    </Grid>
  );
};
