import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';

export const MoDGoalSettingMenteeStepThree = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md">
          The next few slides will present three separate goals. Identify whether or not the goal
          meets the SMART principles and select the principles that may apply to the example goal.
        </Text>
      </Grid>
    </Grid>
  );
};
