import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { EContentType, IPrivateNote } from '../../../../../store/reducers/PrivateNotesReducer';
import { IStoreTypes } from '../../../../../store/storeTypes';
import {
  BrancherDispatch,
  SaveMakeTheMostMentee,
  SaveMakeTheMostMentor,
  UtilSaveUserPrivateNotesData,
  UtilSaveUserSharedNotesData,
} from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveButton } from '../../../../../Components/InputFields/BrancherButton';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { SuggestionCard } from '../../SuggestionCard';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';

export const MakeTheMostMenteeStepNine = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const sessionStore =
    position === ProgramPositions.mentee
      ? EStructuredTrainingModules.MAKE_THE_MOST_MENTEE
      : EStructuredTrainingModules.MAKE_THE_MOST_MENTOR;
  const stepNineValue = useSelector((state: IStoreTypes) => state.training[sessionStore]?.StepNine);
  const newGoal = useSelector(
    (state: IStoreTypes) => state.training[sessionStore]?.StepEight?.newGoal,
  );
  const isMentee = position === ProgramPositions.mentee;
  const partnerRole = isMentee ? ProgramPositions.mentor : ProgramPositions.mentee;

  const dispatch = useDispatch();

  const saveNote = () => {
    const apiCall =
      stepNineValue.sharePublic === '0'
        ? UtilSaveUserPrivateNotesData
        : UtilSaveUserSharedNotesData;
    const noteData: IPrivateNote = {
      contents: [
        {
          content: newGoal,
          type: EContentType.HEADING,
        },
      ],
      title: 'Make the most - NEW GOAL',
      lastModified: Date.now(),
    };
    BrancherDispatch(
      dispatch,
      apiCall(noteData, () => {
        updateValue('savedNote', true);
      }),
    );
  };

  const updateValue = (attr: string, val: string | boolean) => {
    const sessionSaveFunction = isMentee ? SaveMakeTheMostMentee : SaveMakeTheMostMentor;
    const newStepNineVal = { ...stepNineValue, [attr]: val };
    dispatch(
      sessionSaveFunction({
        StepNine: {
          ...newStepNineVal,
          interacted: newStepNineVal?.savedNote,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Great! Your goal has been saved.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Would you like to keep this content private or share with your {partnerRole} in your
          shared notes?
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard>
          Whilst sharing this information is optional, we do encourage it. Sharing this information
          can help you and your mentoring partner to reflect, evaluate and plan.
        </SuggestionCard>
      </Grid>
      <Grid item>
        <SelectionControl
          noQuestion
          question=""
          name="sharePublic"
          value={stepNineValue?.sharePublic}
          exclusive
          options={MakeOptions(['Private', 'Partner only'])}
          updateValue={(a: string) => updateValue('sharePublic', a)}
        />
        <SaveButton
          onClick={saveNote}
          disabled={!stepNineValue?.sharePublic || stepNineValue?.savedNote}
        >
          Create note
        </SaveButton>
      </Grid>
    </Grid>
  );
};
