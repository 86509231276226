import * as React from 'react';
import { EStructuredTrainingModules } from '../../../../store/reducerTypes/TrainingReducer.types';
import { ModuleWrapper } from '../../Modules/ModuleWrapper';
import { SurveyOneModuleMapper } from './SurveyOneModuleMapper';

export const SurveyOne = () => (
  <ModuleWrapper
    moduleMap={SurveyOneModuleMapper()}
    moduleName={EStructuredTrainingModules.SURVEY_ONE}
    title="Complete this short check-in survey"
  />
);
