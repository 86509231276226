import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { CheckAnswerSection } from '../../../../../Components/General/CheckAnswerSection';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const EasyEnglishCCStepThreeCorrect = () => {
  const stepThreeValue = useSelector(
    (state: IStoreTypes) => state.training.easyEnglishCC.StepThree,
  );
  return (
    <Grid container direction="column" spacing={4}>
      <CheckAnswerSection correct={stepThreeValue.correct}>
        <Text variant="sm" fontWeight={700} marginBottom={20}>
          {stepThreeValue.correct ? 'Correct' : 'Not quite'}.
        </Text>
        <Text variant="sm">You must tell your partner. Make a new time to meet.</Text>
      </CheckAnswerSection>
    </Grid>
  );
};
