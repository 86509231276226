import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMenteeData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const BeGreatMenteeStepFour = () => {
  const stepFourValue = useSelector((state: IStoreTypes) => state.training.beGreatMentee.StepFour);
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepFourCorrectVal = ['1', '3'];
    dispatch(
      SaveBeGreatMenteeData({
        StepFour: {
          selected: val,
          correct:
            val.length === 2 &&
            StepFourCorrectVal.includes(val[0]) &&
            StepFourCorrectVal.includes(val[1]),
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Quiz
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">Having a growth mindset involves:</Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You can choose more than one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions optionHeader="A." optionText="Being open minded" />
        <TrainingSelectOptions
          optionHeader="B."
          optionText="Believing in learning and development"
        />
        <TrainingSelectOptions optionHeader="C." optionText="Being positive" />
        <TrainingSelectOptions optionHeader="D." optionText="Thinking everyone can grow" />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepFourValue"
          value={stepFourValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(['A', 'B', 'C', 'D'])}
        />
      </Grid>
    </Grid>
  );
};
