import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';
import { GetTrainingResourceLink } from '../../../../../utils/LinkUtils';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';

export const BeyondProgramMentorStepSeven = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="sm" fontWeight={700}>
          Great. Now that you know how to continue or end the relationship, it’s time to reflect on
          your experience throughout this program.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="sm">
          It’s time to reflect on your mentoring relationship and what you've achieved together.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="sm">
          Research shows that reflection can make you happier, healthier and even improve your
          cognitive abilities. Reflection helps us learn and move forward. Kolb's model of
          reflection can be seen in the image below - Experience, Reflect, Evaluate, Plan.
        </Text>
      </Grid>
      <Grid item container justify="center">
        <BrancherTrainingImage
          src={GetTrainingResourceLink(
            EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR,
            'kolb-model.png',
          )}
          alt="kolb-model"
        />
      </Grid>
    </Grid>
  );
};
