import { AxiosResponse } from 'axios';
import {
  IPrivateNote,
  PRIVATE_NOTES_ADD,
  PRIVATE_NOTES_ADD_ALL,
  PRIVATE_NOTES_CLEAR_DATA,
} from '../reducers/PrivateNotesReducer';
import { IAPIResponseObject } from './actionTypes/apiTypes';
import { BrancherAuthRequest, BrancherDispatch } from './utils/api-utils';
import { UtilUpdateRoleProfileActivities } from './ProfileFormActions';
import { EProgressActivities } from '../reducers/ProfileFormReducer';

export const SaveAllPrivateNotesData = (privateNotesData: object) => {
  return {
    type: PRIVATE_NOTES_ADD_ALL,
    payload: privateNotesData,
  };
};

export const ClearPrivateNotesData = () => {
  return {
    type: PRIVATE_NOTES_CLEAR_DATA,
  };
};

export const SavePrivateNoteData = (privateNotesData: IPrivateNote) => {
  return {
    type: PRIVATE_NOTES_ADD,
    payload: privateNotesData,
  };
};

interface IUtilSaveUserPrivateNotesData extends IAPIResponseObject, Omit<AxiosResponse, 'data'> {
  data: {
    data: IPrivateNote;
  };
}

export const UtilSaveUserPrivateNotesData = (
  privateNote: IPrivateNote,
  cb: (a: IUtilSaveUserPrivateNotesData['data']) => void,
) => {
  return (dispatch: any, getState: any) => {
    const roleId = getState().user.sessionRoleId;
    const programId = getState().user.programId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/privatenotes',
        data: {
          roleId,
          programId,
          privateNote,
        },
      },
      getState(),
    )
      .then((resp: IUtilSaveUserPrivateNotesData) => {
        const newNote = resp.data.data;
        const allPrivateNotes = getState().privateNotes?.privateNotes;
        const newPrivateNotes =
          allPrivateNotes && !privateNote?.noteId
            ? [newNote, ...allPrivateNotes]
            : privateNote?.noteId
            ? allPrivateNotes.map((p) => {
                if (p.noteId === newNote.noteId) {
                  return newNote;
                } else {
                  return p;
                }
              })
            : [newNote];
        if (!privateNote.noteId) {
          BrancherDispatch(
            dispatch,
            UtilUpdateRoleProfileActivities(EProgressActivities.HAS_CREATED_PRIVATE_NOTE),
          );
        }
        dispatch(SaveAllPrivateNotesData(newPrivateNotes));
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilGetUserPrivateNotes = (cb: (a: IAPIResponseObject) => void) => {
  return (dispatch: any, getState: any) => {
    const roleId = getState().user.sessionRoleId;
    const programId = getState().user.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/privatenotes',
        params: JSON.stringify({
          roleId,
          programId,
        }),
      },
      getState(),
    )
      .then((resp: any) => {
        const privateNotes = resp.data.data;
        if (privateNotes?.privateNotes.length > 0) {
          dispatch(SaveAllPrivateNotesData(privateNotes.privateNotes));
        }
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilDeleteUserPrivateNotes = (
  noteId: string,
  cb: (a?: IAPIResponseObject) => void,
) => {
  return (dispatch: any, getState: any) => {
    const roleId = getState().user.sessionRoleId;
    const programId = getState().user.programId;
    BrancherAuthRequest(
      {
        method: 'delete',
        url: 'v2/privatenotes',
        data: {
          roleId,
          programId,
          noteId,
        },
      },
      getState(),
    )
      .then((resp) => {
        const newPrivateNotes = resp.data.data;
        dispatch(SaveAllPrivateNotesData(newPrivateNotes));
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};
