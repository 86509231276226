import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { CharlieAvatar } from '../../avatars/Charlie';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const BeGreatMentorStepSeven = () => {
  const stepSevenValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentor.StepSeven,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepSevenCorrectVal = '1';
    dispatch(
      SaveBeGreatMentorData({
        StepSeven: {
          selected: val,
          correct: StepSevenCorrectVal === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Charlie is considering doing some further study...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          Charlie tells you they are considering doing some further study. They want to learn more
          about how to be a great manager/leader. Thinking about the 'Goal' stage of the GROWTH
          model, what do you say?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Choose one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="That's great, where are you thinking about studying?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="What's motivating you to consider doing further study? Is there an end goal you're working towards?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="How exciting. Have you thought about how you will maintain an income if you're going back to study?"
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepSevenValue"
          value={stepSevenValue?.selected}
          updateValue={updateValue}
          noQuestion
          exclusive
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
