import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { PageLayout } from '../../Components/General/PageLayout';
import { BaseMeeting, IBaseMeeting } from './BaseMeeting';

export const Meeting: React.FC<IBaseMeeting> = ({
  hasFeaturePageLayout = true,
  isGroup = false,
}) => {
  if (hasFeaturePageLayout) {
    return (
      <PageLayout
        pageTitle="New Meeting"
        hasFeaturePageLayout={hasFeaturePageLayout}
        titleDivider
        backButtonRoute="meetings"
      >
        <BaseMeeting isGroup={isGroup} />
      </PageLayout>
    );
  } else {
    return (
      <Grid container item xs={12} justify="center">
        <BaseMeeting isGroup={isGroup} />
      </Grid>
    );
  }
};
