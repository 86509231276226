import { TabletDesktopFontSizes } from '../consts/FontSizes';
import { Colors } from '../consts/colors';

export const MuiBadge = {
  badge: {
    padding: `0 0 3px 0`,
    fontWeight: 700,
    fontSize: TabletDesktopFontSizes.xxs,
  },
  colorPrimary: {
    backgroundColor: Colors.backgroundLightBlue,
    color: Colors.purple,
    padding: `1px 3px`,
  },
};
