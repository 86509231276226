import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import capitalize from '@material-ui/core/utils/capitalize';
import { useSelector } from 'react-redux';
import { HeaderTile, IHeaderTile } from '../../Components/General/HeaderTile';
import { PageLayout } from '../../Components/General/PageLayout';
import { GetRoute } from '../../Components/Routing';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { IStoreTypes } from '../../store/storeTypes';
import { Subscription } from '../../types/SubscriptionTypes';
import { GetIllustrationLink } from '../../utils/LinkUtils';

export const Cohort = () => {
  const isAdhocPairing = useSelector((state: IStoreTypes) =>
    state.user.subscription?.includes(Subscription.ADHOC_PAIRING),
  );
  const sessionPosition = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const isMentee = sessionPosition === ProgramPositions.mentee;
  const partnerPosition = isMentee
    ? roleLabels[ProgramPositions.mentor]
    : roleLabels[ProgramPositions.mentee];

  const cohortConfig: IHeaderTile[] = [
    {
      title: 'Contacts',
      imageSrc: GetIllustrationLink('contacts.png'),
      linkTo: GetRoute('contacts').path,
      description: `Discover your fellow ${roleLabels[ProgramPositions.mentee]}s and ${
        roleLabels[ProgramPositions.mentor]
      }s`,
    },
  ];
  if (isAdhocPairing) {
    cohortConfig.push({
      title: `Find A ${capitalize(partnerPosition)}`,
      imageSrc: GetIllustrationLink('explore.png'),
      linkTo: GetRoute('findMentoringPartner').path,
      description: `Find your ideal ${partnerPosition} using Brancher's pairing algorithm`,
    });
    cohortConfig.push({
      title: `${capitalize(partnerPosition)} Requests`,
      imageSrc: GetIllustrationLink('treeStory.png'),
      linkTo: GetRoute('mentoringPartnerRequests').path,
      description: `Action your ${partnerPosition} requests`,
    });
  }

  return (
    <PageLayout pageTitle="Cohort">
      <Grid container item spacing={2}>
        {cohortConfig.map((cohortTile, index) => (
          <Grid item xs={12} md={6} key={index}>
            <HeaderTile {...cohortTile} />
          </Grid>
        ))}
      </Grid>
    </PageLayout>
  );
};
