import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { IDraftedPairs } from '../actions/CohortActions';
import { IReducerType } from './ReducerType';
import { IProfileData } from './ProfileFormReducer';

export const COHORT_DATA = 'COHORT_DATA';
export const COHORT_CLEAR_DATA = 'COHORT_CLEAR_DATA';

export const CohortReducer = (state = initialState, action: IReducerType) => {
  switch (action.type) {
    case COHORT_DATA:
      return { ...state, ...action.payload };
    case COHORT_CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
};

export enum EMentoringRequestStatus {
  SENT = 'sent',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  CANCELLED = 'cancelled',
}

export interface IMentoringRequest {
  mentoringRequestId: string;
  programId: string;
  menteeRoleId: string;
  menteeName: string;
  mentorRoleId: string;
  mentorName: string;
  pairingData: IDraftedPairs;
  requestDate: number;
  status: EMentoringRequestStatus;
  requestInitiator: ProgramPositions;
  requestMessage?: string;
}

export interface ICohortReducer {
  profiles: IProfileData[];
  recheckMentoringRequests?: boolean;
}

const initialState: ICohortReducer = {
  profiles: [],
};
