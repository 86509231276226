import { Colors } from '../consts/colors';

export const MuiPopover = {
  paper: {
    borderRadius: 30,
    border: `1px ${Colors.purple} solid`,
    boxShadow: '0px 5px 8px -3px rgba(0,0,0,0.14)',
    padding: 30,
  },
};
