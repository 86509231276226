export const MuiStepContent = {
  root: {
    border: 'none !important',
    marginTop: 5,
    backgroundImage: 'linear-gradient(black 33%, rgba(255,255,255,0) 0%)',
    backgroundPosition: 'left',
    backgroundSize: '1px 5px',
    backgroundRepeat: 'repeat-y',
  },
  transition: {
    marginLeft: 12,
  },
};
