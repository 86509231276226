import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { Text } from '../../../../../Components/General/Text';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { GetTrainingResourceLink } from '../../../../../utils/LinkUtils';
import { SuggestionCard } from '../../SuggestionCard';

export const MoDBeGreatMentorStepSevenIncorrect = () => {
  const stepSevenValue = useSelector(
    (state: IStoreTypes) => state.training.modBeGreatMentor?.StepSeven,
  );

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepSevenValue.correct} incorrect={!stepSevenValue.correct}>
          <Text variant="md" fontWeight={700}>
            That is not the best option. The best option was B.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item>
          <Text variant="sm" display="inline">
            Do you have a preference to be paired with someone similar to you? You’re not alone. As
            human beings, we prefer people who are more like ourselves. Researchers and
            psychologists have found this is due to bias. Learn more about biases{' '}
          </Text>
          <BrancherLinkText
            variant="sm"
            underline="always"
            display="inline"
            target="_blank"
            href={GetTrainingResourceLink(
              EStructuredTrainingModules.INTRODUCTION_MENTEE,
              'cognitive_bias.pdf',
            )}
          >
            here
          </BrancherLinkText>
          <Text variant="sm" display="inline">
            .
          </Text>
        </Grid>
        <Grid item>
          <Text variant="sm">
            Being matched with someone ‘different’ can be a positive thing. Brancher creates
            complementary matches because we recognise the value of diversity and will match you
            with someone who will stretch and challenge you.
          </Text>
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          <Grid item>
            <Text variant="sm">
              Your Mentoring Lead is available for support but before reaching out to them, meet
              with Alex to get to know each other and explore why the relationship might work
              (rather than why it might not work).
            </Text>
          </Grid>
          <Grid item>
            <Text variant="sm">
              Ask what Alex wants to achieve from the program and share what skills, advice and
              perspective you can offer them.
            </Text>
          </Grid>
          <Grid item>
            <Text variant="sm">Ask them questions like:</Text>
            <Text variant="md" fontWeight={500}>
              "What are you hoping to get out of the mentoring program?"
              <br />
              "What experiences can I share with you that might be helpful?"
            </Text>
          </Grid>
        </Grid>
        <Grid item>
          <Text variant="sm">
            If the relationship is unproductive, unpair yourselves in the platform.
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
};
