import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { AlexAvatar } from '../../avatars/Alex';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveIntroductionMentor } from '../../../../../store/actions';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const IntroductionMentorStepOne = () => {
  const stepOneValue = useSelector((state: IStoreTypes) => state.training.introductionMentor);
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepOneCorrectVal = '1';
    dispatch(
      SaveIntroductionMentor({
        ...stepOneValue,
        StepOne: {
          selected: val,
          correct: val === StepOneCorrectVal,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <AlexAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700} display="inline">
            This is your mentee{' '}
          </Text>
          <Text variant="md" fontWeight={700} color="purple" display="inline">
            "Jordan"
          </Text>
          <Text variant="md" fontWeight={700} display="inline">
            .
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          This is your mentee, Jordan. You’ve met with Jordan and agreed to monthly catch-ups but
          you haven’t heard from them in 6 weeks. What should you do?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Choose one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="Nothing, the onus is on Jordan to schedule catch ups."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="Give Jordan a call and see if you can unpack why they are not taking the lead as much as you’d expected."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="Book a meeting in your calendars to get the ball rolling."
        />
      </Grid>
      <Grid item container justify="center">
        <SelectionControl
          question=""
          name="stepOneValue"
          value={stepOneValue.StepOne?.selected}
          updateValue={updateValue}
          thirdWidth
          noQuestion
          exclusive
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
