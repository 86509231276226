import * as React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Text } from '../../Components/General/Text';
import { Colors } from '../../consts/colors';
import { BrancherDispatch, UtilGetRoleProfile } from '../../store/actions';
import {
  IMeetingParticipant,
  IMeetingParticipantStatus,
} from '../../store/reducers/MeetingsReducer';
import { EProfileAttributes, IProfileData } from '../../store/reducers/ProfileFormReducer';
import { ProfilePicture } from '../Profile/ProfilePicture';

const useStyles = makeStyles({
  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 15,
    backgroundColor: Colors.white,
    padding: '10px 15px',
    boxShadow: `0px 5px 10px ${Colors.cardBorder}`,
    border: (props: { status: IMeetingParticipantStatus }) =>
      props.status === IMeetingParticipantStatus.ACCEPTED
        ? `3px solid ${Colors.green}`
        : props.status === IMeetingParticipantStatus.DECLINED
        ? `3px solid ${Colors.red}`
        : 'none',
  },
});

export const MeetingParticipantCard: React.FC<IMeetingParticipant> = (props) => {
  const { roleId, status } = props;
  const [meetingParticipantProfile, setMeetingParticipantProfile] = React.useState<IProfileData>();
  const [gettingProfile, setGettingProfile] = React.useState<boolean>(true);
  const dispatch = useDispatch();
  const styles = useStyles({ status });

  React.useEffect(() => {
    BrancherDispatch(
      dispatch,
      UtilGetRoleProfile(roleId, (resp) => {
        setMeetingParticipantProfile(resp);
        setGettingProfile(false);
      }),
    );
  }, []);

  const userName = `${meetingParticipantProfile?.[EProfileAttributes.FIRST_NAME]} ${
    meetingParticipantProfile?.[EProfileAttributes.LAST_NAME]
  }`;

  return !gettingProfile ? (
    <Box className={styles.card}>
      <Grid item xs={3}>
        <ProfilePicture
          src={meetingParticipantProfile?.[EProfileAttributes.IMAGE]}
          mini
          alt={`${userName}-profile`}
        />
      </Grid>
      <Grid item xs={8}>
        <Text
          variant="xs"
          color={
            status === IMeetingParticipantStatus.ACCEPTED
              ? 'green'
              : props.status === IMeetingParticipantStatus.DECLINED
              ? 'red'
              : 'purple'
          }
          fontWeight={600}
          align="center"
        >
          {userName}
        </Text>
      </Grid>
    </Box>
  ) : (
    <CircularProgress size={48} />
  );
};
