import { Colors } from '../consts/colors';

export const MuiCircularProgress = {
  root: {
    color: Colors.purple,
  },
  colorPrimary: {
    color: Colors.white,
  },
  colorSecondary: {
    color: Colors.purple,
  },
};
