import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SonalAvatar } from '../../avatars/Sonal';
import { SuggestionCard } from '../../SuggestionCard';
import { TryAgainCard } from '../../sharedComps/TryAgainCard';

export const GivingReceivingFeedbackMenteeStepFourIncorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard incorrect custom>
          <Text variant="md" fontWeight={700}>
            Not the best option.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={2}>
        <Grid item xs={8} md={4} container justify="center">
          <SonalAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={3}>
          <Grid item>
            <Text variant="md" fontWeight={700} marginBottom={15}>
              Sonal responds:
            </Text>
            <SuggestionCard align="left">
              "I really must answer urgent work calls, but I appreciate your understanding with
              that."
            </SuggestionCard>
          </Grid>
          <Grid item>
            <Text variant="md" fontWeight={700} display="inline" color="purple">
              Tips:
            </Text>
            <Text variant="md" display="inline">
              {' '}
              You should try to make it{' '}
            </Text>
            <Text variant="md" display="inline" fontWeight={700}>
              achievable and specific{' '}
            </Text>
            <Text variant="md" display="inline">
              for Sonal. Acknowledge that sometimes we have work priorities and we need to be on
              call, whilst demonstrating a commitment to keep your own phone away too.
            </Text>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <TryAgainCard />
      </Grid>
    </Grid>
  );
};
