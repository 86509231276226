import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMoDGoalSettingMenteeData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const MoDGoalSettingMenteeStepTen = () => {
  const stepTenValue = useSelector(
    (state: IStoreTypes) => state.training.modGoalSettingMentee?.StepTen,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepTenCorrectVal = ['0', '1'];
    dispatch(
      SaveMoDGoalSettingMenteeData({
        StepTen: {
          selected: val,
          correct: val?.includes(StepTenCorrectVal[0]) && val?.includes(StepTenCorrectVal[1]),
        },
      }),
    );
  };

  const options: string[] = [
    'A. Ask my mentor to review my goal and action plan.',
    'B. Regularly discuss goal progress.',
    'C. Not engage with my mentor.',
    'D. Ask for advice.',
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center">
        <Text variant="lg" fontWeight={700}>
          We encourage you to share your goal with your mentor.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          You should not pursue your goal in isolation. Even if your mentor does not have direct
          expertise with what you are working on, they are likely to still have a wealth of
          knowledge, experience and advice they can share to help you work towards this goal.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">Quiz</Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          How will you seek support from your mentor to help you achieve your goal? You can select
          more than one option.
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepTenValue"
          value={stepTenValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(options)}
        />
      </Grid>
    </Grid>
  );
};
