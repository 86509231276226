import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../Components/General/Text';
import { TileDescription } from '../../../../Components/General/TileDescriptor';

export const TacticalRelationshipClosureMentee = () => {
  const positiveTactics = [
    'Reflect on everything you have accomplished.',
    'Express gratitude to your mentor.',
    'Talk about how your mentor has helped you.',
    'Explain how your currently view your career, your goals and the mentoring relationship.',
    'Explain how your situation or needs may have changed.',
    'Clearly state how you see the relationship evolving or ending.',
    'Suggest a focus for the remainder of your final meeting.',
    'Discuss next steps for your career.',
  ];
  const negativeTactics = [
    'Stay in the relationship out of obligation.',
    'Focus on the negative.',
    'Avoid the conversation.',
  ];
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="lg" color="purple" fontWeight={700} marginTop={30}>
          Do:
        </Text>
      </Grid>
      {positiveTactics.map((tactic, key) => (
        <Grid item key={key}>
          <TileDescription tileKey={key + 1} description={tactic} type="positive" />
        </Grid>
      ))}
      <Grid item>
        <Text variant="lg" color="red" fontWeight={700} marginTop={30}>
          Don't:
        </Text>
      </Grid>
      {negativeTactics.map((tactic, key) => (
        <Grid item key={key}>
          <TileDescription tileKey={key + 1} description={tactic} type="negative" />
        </Grid>
      ))}
    </Grid>
  );
};
