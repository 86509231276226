import * as React from 'react';
import { IModuleMapper } from '../ModuleMapper';
import { GivingReceivingFeedbackMenteeStepOne } from './Steps/GivingReceivingFeedbackMenteeStepOne';
import { GivingReceivingFeedbackMenteeStepTwo } from './Steps/GivingReceivingFeedbackMenteeStepTwo';
import { GivingReceivingFeedbackMenteeStepThree } from './Steps/GivingReceivingFeedbackMenteeStepThree';
import { GivingReceivingFeedbackMenteeStepThreeCorrect } from './Steps/GivingReceivingFeedbackMenteeStepThreeCorrect';
import { GivingReceivingFeedbackMenteeStepThreeIncorrect } from './Steps/GivingReceivingFeedbackMenteeStepThreeIncorrect';
import { GivingReceivingFeedbackMenteeStepFour } from './Steps/GivingReceivingFeedbackMenteeStepFour';
import { GivingReceivingFeedbackMenteeStepFourIncorrect } from './Steps/GivingReceivingFeedbackMenteeStepFourIncorrect';
import { GivingReceivingFeedbackMenteeStepFourCorrect } from './Steps/GivingReceivingFeedbackMenteeStepFourCorrect';
import { GivingReceivingFeedbackMenteeStepFive } from './Steps/GivingReceivingFeedbackMenteeStepFive';
import { GivingReceivingFeedbackMenteeStepSix } from './Steps/GivingReceivingFeedbackMenteeStepSix';
import { GivingReceivingFeedbackMenteeStepSeven } from './Steps/GivingReceivingFeedbackMenteeStepSeven';
import { GivingReceivingFeedbackMenteeStepEight } from './Steps/GivingReceivingFeedbackMenteeStepEight';
import { GivingReceivingFeedbackMenteeStepNine } from './Steps/GivingReceivingFeedbackMenteeStepNine';
import { GivingReceivingFeedbackMenteeStepNineCorrect } from './Steps/GivingReceivingFeedbackMenteeStepNineCorrect';
import { GivingReceivingFeedbackMenteeStepNineIncorrect } from './Steps/GivingReceivingFeedbackMenteeStepNineIncorrect';
import { GivingReceivingFeedbackMenteeStepTen } from './Steps/GivingReceivingFeedbackMenteeStepTen';
import { GivingReceivingFeedbackMenteeStepEleven } from './Steps/GivingReceivingFeedbackMenteeStepEleven';

export const GivingReceivingFeedbackMenteeModuleMapper: IModuleMapper[] = [
  {
    name: 'GivingReceivingFeedbackMenteeStepOne',
    component: <GivingReceivingFeedbackMenteeStepOne />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    nextComponent: 'GivingReceivingFeedbackMenteeStepTwo',
  },
  {
    name: 'GivingReceivingFeedbackMenteeStepTwo',
    component: <GivingReceivingFeedbackMenteeStepTwo />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'GivingReceivingFeedbackMenteeStepOne',
    nextComponent: 'GivingReceivingFeedbackMenteeStepThree',
  },
  {
    name: 'GivingReceivingFeedbackMenteeStepThree',
    component: <GivingReceivingFeedbackMenteeStepThree />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'GivingReceivingFeedbackMenteeStepTwo',
    correctComponent: 'GivingReceivingFeedbackMenteeStepThreeCorrect',
    incorrectComponent: 'GivingReceivingFeedbackMenteeStepThreeIncorrect',
  },
  {
    name: 'GivingReceivingFeedbackMenteeStepThreeIncorrect',
    component: <GivingReceivingFeedbackMenteeStepThreeIncorrect />,
    iterateStepper: true,
    hasQuestion: false,
    previousComponent: 'GivingReceivingFeedbackMenteeStepThree',
    nextComponent: 'GivingReceivingFeedbackMenteeStepFour',
  },
  {
    name: 'GivingReceivingFeedbackMenteeStepThreeCorrect',
    component: <GivingReceivingFeedbackMenteeStepThreeCorrect />,
    iterateStepper: true,
    hasQuestion: false,
    previousComponent: 'GivingReceivingFeedbackMenteeStepThree',
    nextComponent: 'GivingReceivingFeedbackMenteeStepFour',
  },
  {
    name: 'GivingReceivingFeedbackMenteeStepFour',
    component: <GivingReceivingFeedbackMenteeStepFour />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'GivingReceivingFeedbackMenteeStepThree',
    correctComponent: 'GivingReceivingFeedbackMenteeStepFourCorrect',
    incorrectComponent: 'GivingReceivingFeedbackMenteeStepFourIncorrect',
  },
  {
    name: 'GivingReceivingFeedbackMenteeStepFourIncorrect',
    component: <GivingReceivingFeedbackMenteeStepFourIncorrect />,
    iterateStepper: true,
    hasQuestion: false,
    previousComponent: 'GivingReceivingFeedbackMenteeStepFour',
    nextComponent: 'GivingReceivingFeedbackMenteeStepFive',
  },
  {
    name: 'GivingReceivingFeedbackMenteeStepFourCorrect',
    component: <GivingReceivingFeedbackMenteeStepFourCorrect />,
    iterateStepper: true,
    hasQuestion: false,
    previousComponent: 'GivingReceivingFeedbackMenteeStepFour',
    nextComponent: 'GivingReceivingFeedbackMenteeStepFive',
  },
  {
    name: 'GivingReceivingFeedbackMenteeStepFive',
    component: <GivingReceivingFeedbackMenteeStepFive />,
    iterateStepper: true,
    contentScreen: true,
    previousComponent: 'GivingReceivingFeedbackMenteeStepFour',
    nextComponent: 'GivingReceivingFeedbackMenteeStepSix',
  },
  {
    name: 'GivingReceivingFeedbackMenteeStepSix',
    component: <GivingReceivingFeedbackMenteeStepSix />,
    iterateStepper: true,
    needsInteraction: false,
    contentScreen: true,
    previousComponent: 'GivingReceivingFeedbackMenteeStepFive',
    nextComponent: 'GivingReceivingFeedbackMenteeStepSeven',
  },
  {
    name: 'GivingReceivingFeedbackMenteeStepSeven',
    component: <GivingReceivingFeedbackMenteeStepSeven />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'GivingReceivingFeedbackMenteeStepSix',
    nextComponent: 'GivingReceivingFeedbackMenteeStepEight',
  },
  {
    name: 'GivingReceivingFeedbackMenteeStepEight',
    component: <GivingReceivingFeedbackMenteeStepEight />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'GivingReceivingFeedbackMenteeStepSeven',
    nextComponent: 'GivingReceivingFeedbackMenteeStepNine',
  },
  {
    name: 'GivingReceivingFeedbackMenteeStepNine',
    component: <GivingReceivingFeedbackMenteeStepNine />,
    iterateStepper: false,
    hasQuestion: true,
    previousComponent: 'GivingReceivingFeedbackMenteeStepEight',
    correctComponent: 'GivingReceivingFeedbackMenteeStepNineCorrect',
    incorrectComponent: 'GivingReceivingFeedbackMenteeStepNineIncorrect',
  },
  {
    name: 'GivingReceivingFeedbackMenteeStepNineIncorrect',
    component: <GivingReceivingFeedbackMenteeStepNineIncorrect />,
    iterateStepper: true,
    hasQuestion: false,
    previousComponent: 'GivingReceivingFeedbackMenteeStepNine',
    nextComponent: 'GivingReceivingFeedbackMenteeStepTen',
  },
  {
    name: 'GivingReceivingFeedbackMenteeStepNineCorrect',
    component: <GivingReceivingFeedbackMenteeStepNineCorrect />,
    iterateStepper: true,
    hasQuestion: false,
    previousComponent: 'GivingReceivingFeedbackMenteeStepNine',
    nextComponent: 'GivingReceivingFeedbackMenteeStepTen',
  },
  {
    name: 'GivingReceivingFeedbackMenteeStepTen',
    component: <GivingReceivingFeedbackMenteeStepTen />,
    iterateStepper: true,
    needsInteraction: true,
    previousComponent: 'GivingReceivingFeedbackMenteeStepNine',
    nextComponent: 'GivingReceivingFeedbackMenteeStepEleven',
  },
  {
    name: 'GivingReceivingFeedbackMenteeStepEleven',
    component: <GivingReceivingFeedbackMenteeStepEleven />,
    iterateStepper: true,
    noValidation: true,
    contentScreen: true,
    submit: true,
    previousComponent: 'GivingReceivingFeedbackMenteeStepTen',
  },
];
