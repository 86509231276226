import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { Text } from '../../Components/General/Text';
import { AcceptButton } from '../../Components/InputFields/BrancherButton';
import { BrancherSlider } from '../../Components/InputFields/BrancherSlider';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { BrancherDispatch, UtilWrapUpPartnerSurvey } from '../../store/actions';
import { IDefaultUserActionProps } from './ActionsEngine';

export const WrapUpPartnerSurvey: React.FC<IDefaultUserActionProps> = ({ closeDialog, action }) => {
  const [wrappingUpRelationship, setWrappingUpRelationship] = React.useState<boolean>(false);
  const [partnerExtraComment, setPartnerExtraComment] = React.useState<string>('');
  const [partnerMatchSatisfaction, setPartnerMatchSatisfaction] = React.useState<number | number[]>(
    3,
  );
  const dispatch = useDispatch();

  const wrapUpPartnerSurveyRelationship = () => {
    setWrappingUpRelationship(true);
    BrancherDispatch(
      dispatch,
      UtilWrapUpPartnerSurvey(
        {
          userActionId: action.userActionId,
          partnerExtraComment,
          partnerMatchSatisfaction,
          menteeRoleId: action.actionAttributes.menteeRoleId,
          mentorRoleId: action.actionAttributes.mentorRoleId,
        },
        () => {
          setWrappingUpRelationship(false);
          closeDialog();
        },
      ),
    );
  };

  return (
    <Grid container>
      <Text variant="sm" marginTop={40} fontWeight={600}>
        Do you have any other comments you would like to share about this mentoring relationship?
      </Text>
      <BrancherTextField
        value={partnerExtraComment}
        fullWidth
        updateValue={setPartnerExtraComment}
        label="Comments"
      />

      <Text variant="sm" marginTop={40} marginBottom={15} fontWeight={600}>
        How satisfied were you with your match?
      </Text>
      <BrancherSlider
        name="partnerMatchSatisfaction"
        value={partnerMatchSatisfaction}
        updateValue={setPartnerMatchSatisfaction}
        max={5}
        min={1}
      />

      <AcceptButton
        fullWidth
        onClick={wrapUpPartnerSurveyRelationship}
        disabled={!partnerMatchSatisfaction || wrappingUpRelationship}
        loading={wrappingUpRelationship}
      >
        Send
      </AcceptButton>
    </Grid>
  );
};
