import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { MentoringGuideLink } from '../../../../../Components/General/MentoringGuideLink';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import { SaveIntroductionMentor } from '../../../../../store/actions';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { TrainingIntroductionLearnings } from '../../TrainingIntroductionSplash';
import { SuggestionCard } from '../../SuggestionCard';
import { CreateMentoringGuideLink } from '../../helpers/LinkBuilder';
import { CompetenceScale } from '../../sharedComps/CompetenceScale';
import { ConfidenceScale } from '../../sharedComps/ConfidenceScale';
import { NPSScale } from '../../sharedComps/NPSScale';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';

export const IntroductionMentorStepEight = () => {
  const StepEightValue = useSelector((state: IStoreTypes) => state.training.introductionMentor);
  const mentoringGuideLink = CreateMentoringGuideLink();
  const [open, setDialog] = React.useState(false);
  const dispatch = useDispatch();

  const updateCompetence = (val: number) => {
    dispatch(
      SaveIntroductionMentor({
        StepEight: {
          ...StepEightValue?.StepEight,
          competenceFinal: val,
          interacted:
            StepEightValue?.rating != null &&
            StepEightValue?.confidenceFinal != null &&
            val != null,
        },
        competenceFinal: val,
      }),
    );
  };

  const updateConfidence = (val: number) => {
    dispatch(
      SaveIntroductionMentor({
        StepEight: {
          ...StepEightValue?.StepEight,
          confidenceFinal: val,
          interacted:
            StepEightValue?.rating != null &&
            StepEightValue?.competenceFinal != null &&
            val != null,
        },
        confidenceFinal: val,
      }),
    );
  };

  const updateRating = (val: number) => {
    dispatch(
      SaveIntroductionMentor({
        StepEight: {
          ...StepEightValue?.StepEight,
          interacted:
            StepEightValue?.competenceFinal != null &&
            StepEightValue?.confidenceFinal != null &&
            val != null,
        },
        rating: val,
      }),
    );
  };

  const updateFeedback = (val: string) => {
    dispatch(
      SaveIntroductionMentor({
        StepEight: {
          ...StepEightValue?.StepEight,
          interacted:
            StepEightValue?.rating != null &&
            StepEightValue?.competenceFinal != null &&
            StepEightValue?.confidenceFinal != null,
        },
        feedback: val,
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Dialog open={open} onClose={() => setDialog(false)}>
        <Box right={0} position="absolute" width="10%" marginTop={2}>
          <CloseIcon onClick={() => setDialog(false)} color="secondary" fontSize="large" />
        </Box>
        <DialogContent>
          <BrancherList listItems={TrainingIntroductionLearnings} />
        </DialogContent>
      </Dialog>
      <Grid item xs={12}>
        <Text variant="md" display="inline" fontWeight={700}>
          This is the last question.
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" display="inline">
          Prior to completing this training, you told us you were an{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          {StepEightValue.competenceBaseline + 1}
        </Text>
        <Text variant="md" display="inline">
          {' '}
          out of 7 regarding your level of competence and{' '}
        </Text>
        <Text variant="md" display="inline" fontWeight={700} color="purple">
          {StepEightValue.confidenceBaseline + 1}
        </Text>
        <Text variant="md" display="inline">
          {' '}
          out of 7 regarding your level of confidence to do{' '}
        </Text>
        <BrancherLinkText
          display="inline"
          variant="md"
          underline="always"
          onClick={() => setDialog(true)}
        >
          these behaviours
        </BrancherLinkText>
        <Text variant="sm" display="inline">
          .
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your competence rating now?
        </Text>
        <CompetenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepEightValue?.StepEight?.competenceFinal}
          valueLength={7}
          updateValue={updateCompetence}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your confidence rating now?
        </Text>
        <ConfidenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepEightValue?.StepEight?.confidenceFinal}
          valueLength={7}
          updateValue={updateConfidence}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          On a scale of 0 - 10, how likely are you to recommend this training to another colleague
          within this mentoring program?
        </Text>
        <NPSScale />
        <SegmentedControlGroup
          fullWidth
          value={StepEightValue?.rating}
          valueLength={11}
          updateValue={updateRating}
          startFromZero
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Do you have any other feedback for us?
        </Text>
        <BrancherTextField
          value={StepEightValue?.feedback}
          updateValue={(a: string) => updateFeedback(a)}
          placeholder="Enter here"
          maxChars={400}
          id="feedback"
          name="feedback"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" display="inline" fontWeight={700}>
          Thanks for taking the time to participate and best of luck getting started on your
          mentoring journey.
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" display="inline" fontWeight={700}>
          The next steps are:
        </Text>
      </Grid>
      <Box marginLeft={2}>
        <BrancherList
          listItems={[
            <Text variant="md" marginBottom={10}>
              If you haven't heard from your mentee, contact them and encourage them to complete the
              first online training module before you meet
            </Text>,
            <>
              <Text variant="md" display="inline">
                Continue to use your{' '}
              </Text>
              <MentoringGuideLink variant="md" />
            </>,
            <Text variant="md" marginBottom={10} marginTop={10}>
              Contact your Mentoring Lead if you have questions
            </Text>,
          ]}
        />
      </Box>
      <Grid item xs={12}>
        <SuggestionCard>
          Make sure you click the "✓" button to complete this training.
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
