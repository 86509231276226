import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { SaveEZEnglishMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { NPSScale } from '../../sharedComps/NPSScale';
import { SuggestionCard } from '../../SuggestionCard';

export const EasyEnglishMentorStepFour = () => {
  const easyEnglishValue = useSelector((state: IStoreTypes) => state.training.easyEnglishMentor);
  const stepFourValue = easyEnglishValue?.StepFour;
  const dispatch = useDispatch();

  const updateRating = (val: number) => {
    dispatch(
      SaveEZEnglishMentorData({
        StepFour: {
          ...stepFourValue,
          interacted: val !== null,
        },
        rating: val,
      }),
    );
  };

  const updateFeedback = (val: string) => {
    dispatch(
      SaveEZEnglishMentorData({
        StepFour: {
          ...stepFourValue,
        },
        feedback: val,
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          On a scale of 0 - 10, how likely are you to recommend this training to another colleague
          within this mentoring program?
        </Text>
        <NPSScale />
        <SegmentedControlGroup
          fullWidth
          value={easyEnglishValue?.rating}
          valueLength={11}
          updateValue={updateRating}
          startFromZero
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Do you have any other feedback for us?
        </Text>
        <BrancherTextField
          value={easyEnglishValue?.feedback}
          updateValue={(a: string) => updateFeedback(a)}
          placeholder="Enter here"
          maxChars={400}
          multiline
          id="feedback"
          name="feedback"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <SuggestionCard>
          Make sure you click the "✓" button to complete this training.
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
