import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMoDSituationalMentorshipMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { CharlieAvatar } from '../../avatars/Charlie';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';

export const MoDSituationalMentorshipMentorStepThree = () => {
  const modName = EMoDTrainingModules.MOD_SITUATIONAL_MENTORSHIP_MENTOR;
  const stepThreeValue = useSelector((state: IStoreTypes) => state.training?.[modName]?.StepThree);
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepThreeCorrectVal = ['0', '2'];
    dispatch(
      SaveMoDSituationalMentorshipMentorData({
        StepThree: {
          selected: val,
          correct:
            StepThreeCorrectVal.includes(val[0]) &&
            StepThreeCorrectVal.includes(val[1]) &&
            val?.length === 2,
        },
      }),
    );
  };

  const options: string[] = [
    'A. Talk to someone in your team or department who has developed a communication plan before. Ask them for a template and if they have any advice.',
    'B. Have you tried Google?',
    'C. I’d be happy to look at what you have developed so far and provide feedback.',
    'D. How do you think you develop a communications plan?',
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Charlie then asks, "I need to create and present a communications plan in two days and I
            have no idea how to do that - where do I start?"
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Which is the best Directive (telling) response?
        </Text>
        <Text variant="md">You can choose more than one option.</Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepThreeValue"
          value={stepThreeValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(options)}
        />
      </Grid>
    </Grid>
  );
};
