import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import CloudDownload from '@material-ui/icons/CloudDownload';
import LinkIcon from '@material-ui/icons/Link';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherCard } from '../../Components/General/BrancherCard';
import { PageLayout } from '../../Components/General/PageLayout';
import { Text } from '../../Components/General/Text';
import { BrancherDispatch, UtilGetResources, UtilGetTrainingConfigData } from '../../store/actions';
import { ReadableTrainingModules } from '../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../store/storeTypes';
import {
  GetGroupResourceLink,
  GetSharedResourceLink,
  GetTrainingResourceLink,
  GetYouTubeResourceLink,
} from '../../utils/LinkUtils';
import {
  EResourceType,
  generalResources,
  groupResources,
  mentoringConcernResources,
  platformUsageResources,
  resources,
} from './TrainingResourceConfiguration';
import { UpdatableCompanyInfo } from '../../store/reducers/UserInfoReducer';
import { Subscription } from '../../types/SubscriptionTypes';

export interface IResource {
  src: string;
  resourceType: EResourceType;
  title: string;
  isSharedResource?: boolean;
  isGroupResource?: boolean;
  isYouTube?: boolean;
}

const useStyles = makeStyles({
  resource: {
    minWidth: 280,
  },
});

export const Resources: React.FC = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const companyName = useSelector(
    (state: IStoreTypes) => state.user[UpdatableCompanyInfo.COMPANY_NAME],
  );
  const hasGroups = useSelector((state: IStoreTypes) =>
    state.user?.subscription?.includes(Subscription.GROUPS),
  );
  const trainingModules = useSelector((state: IStoreTypes) => state.training?.modules?.[sesPos]);
  const [hasTrainingData, setHasTrainingData] = React.useState<boolean>(false);
  const [hasRetrievedCustomResources, setHasRetrievedCustomResources] = React.useState<boolean>(
    false,
  );
  const [customResources, setCustomResources] = React.useState<IResource[]>([]);
  const dispatch = useDispatch();
  const styles = useStyles();

  React.useEffect(() => {
    BrancherDispatch(
      dispatch,
      UtilGetResources((extraRes) => {
        if (extraRes.success && extraRes.data?.length > 0) {
          setCustomResources(extraRes.data);
          setHasRetrievedCustomResources(true);
        }
      }),
    );
  }, []);

  React.useEffect(() => {
    BrancherDispatch(
      dispatch,
      UtilGetTrainingConfigData(() => {
        setHasTrainingData(true);
      }),
    );
  }, []);

  return (
    <PageLayout
      pageTitle="Resources"
      titleDivider={true}
      hasFeaturePageLayout={true}
      backButtonRoute="dashboard"
      backButtonLabel="Dashboard"
    >
      <Grid container item xs={12} justify="center">
        <Grid container spacing={2}>
          {hasRetrievedCustomResources && customResources?.length > 0 && (
            <Box width="100%">
              <Text
                variant="md"
                fontWeight={500}
                color="tertiaryGrey"
                marginBottom={15}
                marginTop={20}
              >
                {companyName} resources
              </Text>
              {customResources.map((resource, index) => {
                if (resource.resourceType === EResourceType.LINK) {
                  return (
                    <Grid item xs={12} lg={6} xl={4} key={index}>
                      <a href={resource.src} target="_blank" rel="noopener noreferrer">
                        <BrancherCard minHeight="fit-content" className={styles.resource}>
                          <Text variant="sm" fontWeight={600}>
                            {resource.title}
                          </Text>
                          <Grid container justify="flex-end" alignItems="flex-end">
                            <LinkIcon />
                          </Grid>
                        </BrancherCard>
                      </a>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={12} lg={6} xl={4} key={index}>
                      <a href={resource.src} download target="_blank" rel="noopener noreferrer">
                        <BrancherCard minHeight="fit-content" className={styles.resource}>
                          <Text variant="sm" fontWeight={600}>
                            {resource.title}
                          </Text>
                          <Grid container justify="flex-end" alignItems="flex-end">
                            <CloudDownload />
                          </Grid>
                        </BrancherCard>
                      </a>
                    </Grid>
                  );
                }
              })}
            </Box>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Box width="100%">
            <Text
              variant="md"
              fontWeight={500}
              color="tertiaryGrey"
              marginBottom={15}
              marginTop={40}
            >
              General resources
            </Text>
            <Grid container spacing={2}>
              {generalResources.map((resource, index) => {
                if (resource.resourceType === EResourceType.LINK) {
                  return (
                    <Grid item xs={12} lg={6} xl={4} key={index}>
                      <a href={resource.src} target="_blank" rel="noopener noreferrer">
                        <BrancherCard minHeight="fit-content" className={styles.resource}>
                          <Text variant="sm" fontWeight={600}>
                            {resource.title}
                          </Text>
                          <Grid container justify="flex-end" alignItems="flex-end">
                            <LinkIcon />
                          </Grid>
                        </BrancherCard>
                      </a>
                    </Grid>
                  );
                } else if (resource.resourceType === EResourceType.FILE) {
                  return (
                    <Grid item xs={12} lg={6} xl={4} key={index}>
                      <a
                        href={GetSharedResourceLink(resource.src)}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <BrancherCard minHeight="fit-content" className={styles.resource}>
                          <Text variant="sm" fontWeight={600}>
                            {resource.title}
                          </Text>
                          <Grid container justify="flex-end" alignItems="flex-end">
                            <CloudDownload />
                          </Grid>
                        </BrancherCard>
                      </a>
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Box>
        </Grid>
        {hasGroups && (
          <Grid container spacing={2}>
            <Box width="100%">
              <Text
                variant="md"
                fontWeight={500}
                color="tertiaryGrey"
                marginBottom={15}
                marginTop={40}
              >
                Group resources
              </Text>
              <Grid container spacing={2}>
                {groupResources.map((resource, index) => {
                  if (resource.resourceType === EResourceType.LINK) {
                    return (
                      <Grid item xs={12} lg={6} xl={4} key={index}>
                        <a href={resource.src} target="_blank" rel="noopener noreferrer">
                          <BrancherCard minHeight="fit-content" className={styles.resource}>
                            <Text variant="sm" fontWeight={600}>
                              {resource.title}
                            </Text>
                            <Grid container justify="flex-end" alignItems="flex-end">
                              <LinkIcon />
                            </Grid>
                          </BrancherCard>
                        </a>
                      </Grid>
                    );
                  } else if (resource.resourceType === EResourceType.FILE) {
                    return (
                      <Grid item xs={12} lg={6} xl={4} key={index}>
                        <a
                          href={
                            resource?.isGroupResource
                              ? GetGroupResourceLink(resource.src)
                              : GetSharedResourceLink(resource.src)
                          }
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <BrancherCard minHeight="fit-content" className={styles.resource}>
                            <Text variant="sm" fontWeight={600}>
                              {resource.title}
                            </Text>
                            <Grid container justify="flex-end" alignItems="flex-end">
                              <CloudDownload />
                            </Grid>
                          </BrancherCard>
                        </a>
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </Box>
          </Grid>
        )}
        <Grid container spacing={2}>
          <Box width="100%" key="mentoring-concerns">
            <Text
              variant="md"
              fontWeight={500}
              color="tertiaryGrey"
              marginBottom={20}
              marginTop={40}
            >
              Mentoring pair concerns
            </Text>
            <Grid container spacing={2}>
              {mentoringConcernResources?.map((resource, index) => (
                <Grid item xs={12} lg={6} xl={4} key={index}>
                  <a
                    href={GetSharedResourceLink(resource.src)}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BrancherCard minHeight="fit-content" className={styles.resource}>
                      <Text variant="sm" fontWeight={600}>
                        {resource.title}
                      </Text>
                      <Grid container justify="flex-end" alignItems="flex-end">
                        <CloudDownload />
                      </Grid>
                    </BrancherCard>
                  </a>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item container alignItems="flex-start" direction="column">
          {hasTrainingData ? (
            trainingModules?.map((moduleName) => {
              if (resources?.[moduleName]?.length > 0) {
                return (
                  <Box width="100%" key={moduleName}>
                    <Text
                      variant="md"
                      fontWeight={500}
                      color="tertiaryGrey"
                      marginBottom={15}
                      marginTop={30}
                    >
                      {ReadableTrainingModules[moduleName]}
                    </Text>
                    <Grid container spacing={2}>
                      {resources?.[moduleName]?.map((resource, index) => (
                        <Grid item xs={12} lg={6} xl={4} key={index}>
                          <a
                            href={
                              resource?.isSharedResource
                                ? GetSharedResourceLink(resource.src)
                                : resource?.isYouTube
                                ? GetYouTubeResourceLink(resource.src)
                                : GetTrainingResourceLink(moduleName, resource.src)
                            }
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <BrancherCard minHeight="fit-content" className={styles.resource}>
                              <Text variant="sm" fontWeight={600}>
                                {resource.title}
                              </Text>
                              <Grid container justify="flex-end" alignItems="flex-end">
                                <CloudDownload />
                              </Grid>
                            </BrancherCard>
                          </a>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                );
              }
            })
          ) : (
            <CircularProgress color="secondary" size={64} />
          )}
        </Grid>
        <Grid container spacing={2}>
          <Box width="100%">
            <Text
              variant="md"
              fontWeight={500}
              color="tertiaryGrey"
              marginBottom={15}
              marginTop={40}
            >
              Get the most out of Brancher
            </Text>
            <Grid container spacing={2}>
              {platformUsageResources.map((resource, index) => {
                if (resource.resourceType === EResourceType.LINK) {
                  return (
                    <Grid item xs={12} lg={6} xl={4} key={index}>
                      <a href={resource.src} target="_blank" rel="noopener noreferrer">
                        <BrancherCard minHeight="fit-content" className={styles.resource}>
                          <Text variant="sm" fontWeight={600}>
                            {resource.title}
                          </Text>
                          <Grid container justify="flex-end" alignItems="flex-end">
                            <LinkIcon />
                          </Grid>
                        </BrancherCard>
                      </a>
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </PageLayout>
  );
};
