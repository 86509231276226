import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { PageLayout } from '../../Components/General/PageLayout';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { BrancherDispatch, UtilGetProgramCohortProfiles } from '../../store/actions';
import { EProfileAttributes, IProfileData } from '../../store/reducers/ProfileFormReducer';
import { IStoreTypes } from '../../store/storeTypes';
import { Profile } from '../Profile/Profile';
import { ProfileCard } from './ProfileCard';

export const Contacts = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const cohortProfiles = useSelector((state: IStoreTypes) => state.cohort?.profiles);
  const [hasCohortData, setHasCohortData] = React.useState<boolean>(false);
  const [cohortNameSearch, setCohortNameSearch] = React.useState<string>('');
  const [profileOpen, setProfileOpen] = React.useState<boolean>(false);
  const [selectedUser, setSelectedUser] = React.useState<IProfileData | null>();

  const sortedProfiles = () => {
    return cohortProfiles.sort((a: any, b: any) => {
      if (a?.[EProfileAttributes.FIRST_NAME] < b?.[EProfileAttributes.FIRST_NAME]) {
        return -1;
      }
      if (a?.[EProfileAttributes.FIRST_NAME] > b?.[EProfileAttributes.FIRST_NAME]) {
        return 1;
      }
      return 0;
    });
  };

  React.useEffect(() => {
    BrancherDispatch(
      dispatch,
      UtilGetProgramCohortProfiles(() => {
        setHasCohortData(true);
      }),
    );
  }, []);

  const controlDialog = (control: boolean) => {
    setProfileOpen(control);
    if (!control) {
      setSelectedUser(null);
    }
  };

  const controlSelectedUser = (user?: IProfileData) => {
    if (!profileOpen) {
      setSelectedUser(user);
      controlDialog(true);
    }
  };

  const profileButtons: React.ReactNode[] = [];
  if (cohortProfiles?.length > 0) {
    const regexp = new RegExp('^' + cohortNameSearch, 'i');
    sortedProfiles().forEach((o, i) => {
      if (
        regexp.test(o[EProfileAttributes.FIRST_NAME]) ||
        regexp.test(o[EProfileAttributes.LAST_NAME])
      ) {
        profileButtons.push(
          <ProfileCard
            key={i}
            onSelectUser={() => controlSelectedUser(o)}
            firstName={o?.[EProfileAttributes.FIRST_NAME]}
            lastName={o?.[EProfileAttributes.LAST_NAME]}
            jobTitle={o?.[EProfileAttributes.JOB_TITLE]}
            image={o?.[EProfileAttributes.IMAGE]}
            profileType={o?.profileType}
          />,
        );
      }
    });
  }

  return (
    <>
      <PageLayout pageTitle="Contacts" backButtonRoute="cohort" backButtonLabel="Cohort">
        {selectedUser && (
          <Dialog open={profileOpen} onClose={() => controlDialog(false)} fullScreen={mobile}>
            <Box right={0} position="absolute" width="10%" marginTop={2}>
              <CloseIcon onClick={() => controlDialog(false)} color="secondary" fontSize="large" />
            </Box>
            <DialogContent>
              <Profile profileData={selectedUser} profileType={selectedUser.profileType} />
            </DialogContent>
          </Dialog>
        )}
        <Grid item xs={12}>
          <Box mb={3}>
            <Grid container>
              <BrancherTextField
                value={cohortNameSearch}
                updateValue={setCohortNameSearch}
                fullWidth
                label="Search Cohort"
              />
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          {hasCohortData ? (
            <Grid container justify={mobile ? 'center' : 'space-between'} item xs={12}>
              {profileButtons}
            </Grid>
          ) : (
            <Grid container direction="column" justify="center" alignItems="center" item xs={5}>
              <CircularProgress color="secondary" size={64} />
            </Grid>
          )}
        </Grid>
      </PageLayout>
    </>
  );
};
