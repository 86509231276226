import { IReducerType } from './ReducerType';

export const PERSONALITY_FORM_DATA = 'PERSONALITY_FORM_DATA';
export const PERSONALITY_CLEAR_FORM_DATA = 'PERSONALITY_CLEAR_FORM_DATA';

export const PersonalityReducer = (state = initialState, action: IReducerType) => {
  switch (action.type) {
    case PERSONALITY_FORM_DATA:
      return { ...state, ...action.payload };
    case PERSONALITY_CLEAR_FORM_DATA:
      return initialState;
    default:
      return state;
  }
};

export interface IPersonalityReducer {
  configValues: any;
}

const initialState: IPersonalityReducer = {
  configValues: {},
};
