import { Colors } from '../consts/colors';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const breakpoints = createMuiTheme().breakpoints;

export const MuiTextField = {
  root: {
    // Fancy breakpoints for textfield TODO: Can I do this cleaner? More reusable?
    [breakpoints.down('sm')]: {
      '& .MuiInputBase-input': {
        fontSize: 15,
      },
      '& .MuiFormLabel-root': {
        fontSize: 15,
      },
    },
    [breakpoints.between('sm', 'md')]: {
      '& .MuiInputBase-input': {
        fontSize: 18,
      },
      '& .MuiFormLabel-root': {
        fontSize: 18,
      },
    },
    [breakpoints.up('lg')]: {
      '& .MuiInputBase-input': {
        fontSize: 20,
      },
      '& .MuiFormLabel-root': {
        fontSize: 20,
      },
    },
    marginTop: 10,
    marginBottom: 10,
    lineHeight: 1.2,
    '& .MuiInputLabel-root': {
      color: Colors.lightGrey,
    },
    '& .MuiInput-root': {
      '&.Mui-error': {
        background: Colors.lightRed,
      },
    },
    '& .MuiInputBase-input': {
      color: Colors.purple,
      fontWeight: 600,
      '&::placeholder': {
        color: Colors.placeholderGrey,
        fontWeight: 500,
      },
      '&:-webkit-autofill': {
        color: `${Colors.purple} !important`,
        backgroundColor: `${Colors.transparent} !important`,
        '-webkit-box-shadow': `0 0 0 50px ${Colors.white} inset`,
        '-webkit-text-fill-color': Colors.purple,
      },
      '&:-webkit-autofill:focus': {
        color: `${Colors.purple} !important`,
        backgroundColor: `${Colors.transparent} !important`,
        '-webkit-box-shadow': `0 0 0 50px ${Colors.white} inset`,
        '-webkit-text-fill-color': Colors.purple,
      },
    },
    '& .MuiFormLabel-root': {
      color: Colors.lightGrey,
      '&.MuiInputLabel-shrink': {
        color: Colors.black,
        fontWeight: 600,
        marginBottom: 6,
      },
    },
    '&.Mui-focused': {
      color: Colors.purple,
    },
    '& .MuiInput-underline': {
      '&:before': {
        borderBottomColor: Colors.purple,
        opacity: 0.42,
      },
      '&:after': {
        borderBottomColor: Colors.purple,
      },
      '&:hover': {
        '&:not(.Mui-disabled)': {
          '&:before': {
            borderBottomColor: Colors.purple,
          },
        },
      },
    },
  },
};
