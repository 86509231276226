import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { GetTrainingResourceLink } from '../../../../../utils/LinkUtils';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { BrancherDivider } from '../../../../../Components/General/BrancherDivider';
import { SaveMoDSituationalMentorshipMentorData } from '../../../../../store/actions';

export const MoDSituationalMentorshipMentorStepSix = () => {
  const dispatch = useDispatch();
  const modName = EMoDTrainingModules.MOD_SITUATIONAL_MENTORSHIP_MENTOR;

  const updateValue = () => {
    dispatch(
      SaveMoDSituationalMentorshipMentorData({
        StepSix: {
          interacted: true,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Remember to adapt your mentoring style, depending on the situation.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">Here are some conversation starters for each style.</Text>
      </Grid>
      <Grid container item xs={11}>
        <Grid item container xs={4} direction="column">
          <Text variant="md" color="purple" fontWeight={700}>
            1. Directive:
          </Text>
          <Text variant="md">(Telling)</Text>
        </Grid>
        <Grid item container xs={8}>
          <BrancherList
            listItems={[
              'Have you considered...',
              'In my experience...',
              'I think that...',
              'I wonder if...',
              'I understand your concerns, can I suggest that...',
              "What I'm hearing is X and I think you need to do is Y",
            ]}
            variant="md"
            fontWeight={500}
          />
        </Grid>
      </Grid>
      <Grid item>
        <BrancherDivider />
      </Grid>
      <Grid container item xs={11}>
        <Grid item container xs={4} direction="column">
          <Text variant="md" color="purple" fontWeight={700}>
            2. Coach:
          </Text>
          <Text variant="md">(Questioning)</Text>
        </Grid>
        <Grid item container xs={8}>
          <BrancherList
            listItems={[
              'What are you looking to achieve?',
              "What is the problem you're looking to solve?",
              'How do you think you should approach it?',
              'What are the pros and cons?',
              'How could you progress this?',
            ]}
            variant="md"
            fontWeight={500}
          />
        </Grid>
      </Grid>
      <Grid item>
        <BrancherDivider />
      </Grid>
      <Grid container item xs={11}>
        <Grid item container xs={4} direction="column">
          <Text variant="md" color="purple" fontWeight={700}>
            3. Support:
          </Text>
          <Text variant="md">(Encouraging)</Text>
        </Grid>
        <Grid item container xs={8}>
          <BrancherList
            listItems={[
              'What I really like about your approach was X',
              'Amazing work, I can see that you pushed yourself out of your comfort zone',
              'You are doing a great job of using your strengths',
              'The impact of your behaviour is X',
            ]}
            variant="md"
            fontWeight={500}
          />
        </Grid>
      </Grid>
      <Grid item>
        <BrancherDivider />
      </Grid>
      <Grid container item xs={11}>
        <Grid item container xs={4} direction="column">
          <Text variant="md" color="purple" fontWeight={700}>
            4. Challenge:
          </Text>
          <Text variant="md">(Feedback)</Text>
        </Grid>
        <Grid item container xs={8}>
          <BrancherList
            listItems={[
              'Can I suggest some feedback?',
              "I'd love to give you some feedback, would that be OK?",
              'I noticed X, are you aware you do this?',
              'The impact of your behaviour is Y',
              'Can I share a difference perspective?',
            ]}
            variant="md"
            fontWeight={500}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline" fontWeight={700}>
          Download pdf{' '}
        </Text>
        <BrancherLinkText
          variant="md"
          underline="always"
          onClick={updateValue}
          fontWeight={700}
          target="_blank"
          display="inline"
          href={GetTrainingResourceLink(modName, 'mentoring_styles_conversation_starters.pdf')}
        >
          here
        </BrancherLinkText>
      </Grid>
    </Grid>
  );
};
