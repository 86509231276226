import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { IGeneralFormConfig, ValueTypes } from '../../Form/General/GeneralFormConfig';
import { EComponentType } from '../../store/reducers/UserInfoReducer';
import { BrancherSelect } from '../../Components/InputFields/BrancherSelect';
import { ResetButton, UpdateButton } from '../../Components/InputFields/BrancherButton';
import { BrancherDispatch, UtilGetFormConfiguration } from '../../store/actions';
import { MakeOptions } from '../../Form/MakeOptions';
import { useDispatch, useSelector } from 'react-redux';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { IStoreTypes } from '../../store/storeTypes';

enum ESpecialFilterAttributes {
  PAIR_AMOUNT = 'pairAmount',
  IS_MENTEE_NOT_FILTERABLE = 'isMenteeNotFilterable',
  IS_MENTOR_NOT_FILTERABLE = 'isMentorNotFilterable',
}

interface ISearchablePartnerFilters {
  updateSearchCriteria: (filters: any) => void;
}

export const SearchablePartnerFilters: React.FC<ISearchablePartnerFilters> = (props) => {
  const { updateSearchCriteria } = props;
  const sessionPosition = useSelector((state: IStoreTypes) => state.user?.sessionPosition);
  const [gettingFormConfiguration, setGettingFormConfiguration] = React.useState<boolean>(false);
  const [formConfiguration, setFormConfiguration] = React.useState<any[]>([]);
  const [filters, setFilters] = React.useState<any>({});
  const dispatch = useDispatch();
  const isMentee = sessionPosition === ProgramPositions.mentee;
  const otherPosition = isMentee ? ProgramPositions.mentor : ProgramPositions.mentee;

  const defaultConfiguration = (config: any[]) => {
    let defaultFilter = {};
    config.forEach((ff) => {
      defaultFilter = { ...defaultFilter, [ff.name]: [] };
    });
    return defaultFilter;
  };

  const cleanConfiguration = (formConfig: any[]) => {
    const onlyOtherPositionQuestions = formConfig.filter(
      (ff) => (isMentee && ff.isMentor) || (!isMentee && ff.isMentee),
    );
    const questions = onlyOtherPositionQuestions.map((fc) => {
      const opts = fc.options?.[otherPosition] ? fc.options?.[otherPosition] : fc.options;
      const isNotFilterableAttribute = isMentee
        ? ESpecialFilterAttributes.IS_MENTOR_NOT_FILTERABLE
        : ESpecialFilterAttributes.IS_MENTEE_NOT_FILTERABLE;
      if (
        fc?.[isNotFilterableAttribute] ||
        fc?.secondaryAttributeName ||
        fc?.tertiaryAttributeName ||
        fc?.quaternaryAttributeName ||
        fc?.conditionalOptions ||
        fc?.name === ESpecialFilterAttributes.PAIR_AMOUNT ||
        fc.componentType !== EComponentType.TOGGLE
      ) {
        return null;
      }
      const options = MakeOptions(opts);
      return {
        name: fc.name,
        options,
        inputLabel: fc.readableName,
        componentType: fc.componentType,
      };
    });
    const cleanedQuestions = questions.filter((f) => f !== null);
    setFilters(defaultConfiguration(cleanedQuestions));
    setFormConfiguration(cleanedQuestions);
    setGettingFormConfiguration(false);
  };

  React.useEffect(() => {
    setGettingFormConfiguration(true);
    BrancherDispatch(
      dispatch,
      UtilGetFormConfiguration('', true, ({ formConfig }) => {
        cleanConfiguration(formConfig.general);
      }),
    );
  }, []);

  const updateFilters = (attributeName: string, attributeValue: Omit<ValueTypes, 'boolean'>) => {
    setFilters({ ...filters, [attributeName]: attributeValue });
  };

  const updateSearch = () => {
    let cleanedFilters = {};
    Object.keys(filters).forEach((f) => {
      if (filters[f] !== null && filters[f]?.length > 0) {
        cleanedFilters = { ...cleanedFilters, [f]: filters[f] };
      }
    });
    updateSearchCriteria(cleanedFilters);
  };

  return (
    <Grid container item xs={12}>
      {!gettingFormConfiguration && formConfiguration && (
        <Grid container item justify="center" spacing={1} alignItems="center">
          {filters &&
            formConfiguration?.map((fc, i) => {
              const { componentType, ...other } = fc;
              return (
                <SearchToggleFilter
                  key={i}
                  updateFilters={updateFilters}
                  componentProps={other}
                  value={filters?.[other.name]}
                />
              );
            })}
        </Grid>
      )}

      <Box mt={2} display="flex" width="100%">
        <Grid item justify="flex-end" container xs="auto" spacing={1}>
          <Grid item>
            <ResetButton
              size="small"
              onClick={() => setFilters(defaultConfiguration(formConfiguration))}
            >
              Reset filters
            </ResetButton>
          </Grid>
          <Grid item>
            <UpdateButton size="small" onClick={updateSearch}>
              Update filters
            </UpdateButton>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

interface ISearchToggleFilter {
  value: string;
  updateFilters: (name: string, value: string | string[]) => void;
  componentProps: IGeneralFormConfig;
}

const SearchToggleFilter: React.FC<ISearchToggleFilter> = (props) => {
  const { updateFilters, value, componentProps } = props;
  return (
    <Grid item xs={12} sm={6}>
      <BrancherSelect
        {...componentProps}
        multiple
        decreaseMargins
        fullWidth
        value={value}
        updateValue={(val) => updateFilters(componentProps.name, val)}
      />
    </Grid>
  );
};
