import { ETrainingModules } from '../store/reducerTypes/TrainingReducer.types';

export const GetLinkBase = (): string => {
  return 'https://media.brancher.com.au/';
};

export const GetPoliciesLink = (fileName: string): string => {
  return `${GetLinkBase()}policies/${fileName}`;
};

export const GetIllustrationLink = (fileName: string): string => {
  return `${GetLinkBase()}illustrations/${fileName}`;
};

export const GetSharedResourceLink = (fileName: string): string => {
  return `${GetLinkBase()}shared/${fileName}`;
};

export const GetTrainingResourceLink = (module: ETrainingModules, fileName: string): string => {
  return `${GetLinkBase()}training/${module}/${fileName}`;
};

export const GetGroupResourceLink = (fileName: string): string => {
  return `${GetLinkBase()}groups/${fileName}`;
};

export const GetYouTubeResourceLink = (videoId: string): string => {
  return `https://youtu.be/${videoId}`;
};
