import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';

export const BeGreatMentorStepEighteen = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Congratulations! You now know the tips for a productive coaching session.
        </Text>
      </Grid>
      <Grid item container justify="center" xs={11} sm={12}>
        <BrancherTrainingImage
          src="https://media.brancher.com.au/training/beGreatMentor/growth.png"
          alt="growth"
        />
      </Grid>
    </Grid>
  );
};
