import * as React from 'react';
import { useSelector } from 'react-redux';
import { Text } from '../../Components/General/Text';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { IStoreTypes } from '../../store/storeTypes';

export const ProgramLeadContact: React.FC = () => {
  const supportEmail = useSelector((state: IStoreTypes) => state.user?.supportEmail);
  return supportEmail ? (
    <BrancherLinkText
      display="inline"
      variant="sm"
      underline="always"
      fontWeight={600}
      href={`mailto:${supportEmail}?subject=Support Question`}
    >
      {supportEmail}
    </BrancherLinkText>
  ) : (
    <Text variant="sm" fontWeight={600} display="inline">
      your Mentoring Lead
    </Text>
  );
};
