import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../Components/General/Text';
import { TileDescription } from '../../../../Components/General/TileDescriptor';

export const TacticalRelationshipClosureMentor = () => {
  const positiveTactics = [
    'Give as much advance notice as possible.',
    'Be clear about how you would like the relationship to work after the formal mentoring is completed.',
    'Acknowledge specific areas of progress and growth that you have observed in the mentee during the mentoring period.',
    'Give verbal recognition of challenges that your mentee has faced and/or overcome.',
    'Assist your mentee with setting goals for the future.',
    'Ask your mentee to identify the support system and resources that will support achievement of those goals in the future.',
    'Encourage your mentee to continue working on their development',
  ];
  const negativeTactics = [
    'Staying in the relationship out of obligation.',
    'Avoiding or delaying the conversation.',
    'Not being direct or clear in your communication.',
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="lg" color="purple" fontWeight={700} marginTop={30}>
          7 Tactics for Successful Relationship Closure:
        </Text>
      </Grid>
      {positiveTactics.map((tactic, key) => (
        <Grid item key={key}>
          <TileDescription tileKey={key + 1} description={tactic} type="positive" />
        </Grid>
      ))}
      <Grid item>
        <Text variant="lg" color="red" fontWeight={700} marginTop={30}>
          And here are some things to avoid:
        </Text>
      </Grid>
      {negativeTactics.map((tactic, key) => (
        <Grid item key={key}>
          <TileDescription tileKey={key + 1} description={tactic} type="negative" />
        </Grid>
      ))}
    </Grid>
  );
};
