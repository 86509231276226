import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { SaveUserInfo } from '../UserInfoActions';
import { IStoreTypes } from '../../storeTypes';
import { apiKey } from '../configs/keyConfs';

export const BrancherDispatch = (preDispatch, apiCall) => {
  preDispatch((dispatch, getState) => {
    const expired = isUserTokenValid(getState().user.tokenExp);
    if (expired) {
      dispatch(SaveUserInfo({ expiredToken: true, redirected: true }));
    } else {
      dispatch(apiCall);
    }
  });
};

export const isUserTokenValid = (token: number): boolean => {
  return token < new Date().getTime() / 1000;
};

export const BrancherAuthRequest = (
  axiosData: AxiosRequestConfig,
  state: IStoreTypes,
): AxiosPromise => {
  const location = window.location.hostname.split('.');
  const urlBase =
    location[0].indexOf('localhost') !== -1 || location[1].indexOf('localhost') !== -1
      ? 'http://localhost:3000'
      : 'https://api.brancher.com.au';
  const token = state.user.IDToken;
  return axios({
    ...axiosData,
    url: `${urlBase}/${axiosData.url}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const BrancherAPIKeyRequest = (axiosData: AxiosRequestConfig): AxiosPromise => {
  const location = window.location.hostname.split('.');
  const urlBase =
    location[0].indexOf('localhost') !== -1 || location[1].indexOf('localhost') !== -1
      ? 'http://localhost:3000'
      : 'https://api.brancher.com.au';
  return axios({
    ...axiosData,
    url: `${urlBase}/${axiosData.url}`,
    headers: {
      'X-Api-Key': apiKey,
      'Content-Type': 'application/json',
    },
  });
};

export enum EAuthEnvs {
  LOCAL = 'local',
  ADMIN = 'admin',
  MENTORING = 'mentoring',
  DEV_MENTORING = 'dev-mentoring',
}

export const getEnvironmentOrigin = (): EAuthEnvs => {
  return window.location.hostname.indexOf(EAuthEnvs.DEV_MENTORING) !== -1
    ? EAuthEnvs.DEV_MENTORING
    : window.location.hostname.indexOf(EAuthEnvs.MENTORING) !== -1
    ? EAuthEnvs.MENTORING
    : EAuthEnvs.LOCAL;
};
