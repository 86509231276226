import {
  SURVEY_ONE,
  SURVEY_TWO,
  TRAINING_BE_GREAT_MENTEE,
  TRAINING_BE_GREAT_MENTOR,
  TRAINING_BEYOND_PROGRAM_MENTEE,
  TRAINING_BEYOND_PROGRAM_MENTOR,
  TRAINING_CLEAR_DATA,
  TRAINING_DATA,
  TRAINING_EASY_ENGLISH_CC,
  TRAINING_EASY_ENGLISH_MENTEE,
  TRAINING_EASY_ENGLISH_MENTOR,
  TRAINING_GIVING_RECEIVING_FEEDBACK_MENTEE,
  TRAINING_GIVING_RECEIVING_FEEDBACK_MENTOR,
  TRAINING_INTRODUCTION_MENTEE,
  TRAINING_INTRODUCTION_MENTOR,
  TRAINING_MAKE_THE_MOST_MENTEE,
  TRAINING_MAKE_THE_MOST_MENTOR,
  TRAINING_MENTORING_AGREEMENT,
  TRAINING_MOD_BE_GREAT_MENTEE,
  TRAINING_MOD_BE_GREAT_MENTOR,
  TRAINING_MOD_GOAL_SETTING_MENTEE,
  TRAINING_MOD_GOAL_SETTING_MENTOR,
  TRAINING_MOD_GROWTH_COACHING_FRAMEWORK_MENTOR,
  TRAINING_MOD_SITUATIONAL_MENTORSHIP_MENTOR,
  TRAINING_POWER_OF_REFLECTION_MENTEE,
  TRAINING_POWER_OF_REFLECTION_MENTOR,
} from '../reducers/TrainingReducer';
import { IAPIResponseObject } from './actionTypes/apiTypes';
import { SaveUserInfo, UtilRemoveUserAction, UtilUpdateRoleProfileActivities } from './index';
import { BrancherAuthRequest, BrancherDispatch } from './utils/api-utils';
import { EProgressActivities } from '../reducers/ProfileFormReducer';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { EUserActions } from '../reducers/UserInfoReducer';

export const SaveTrainingData = (userData: object) => {
  return {
    type: TRAINING_DATA,
    payload: userData,
  };
};

export const ClearTrainingData = () => {
  return {
    type: TRAINING_CLEAR_DATA,
  };
};

export const SaveIntroductionMentee = (userData: object) => {
  return {
    type: TRAINING_INTRODUCTION_MENTEE,
    payload: userData,
  };
};

export const SaveIntroductionMentor = (userData: object) => {
  return {
    type: TRAINING_INTRODUCTION_MENTOR,
    payload: userData,
  };
};

export const SaveMentoringAgreementData = (userData: object) => {
  return {
    type: TRAINING_MENTORING_AGREEMENT,
    payload: userData,
  };
};

export const SaveBeGreatMenteeData = (userData: object) => {
  return {
    type: TRAINING_BE_GREAT_MENTEE,
    payload: userData,
  };
};

export const SaveBeGreatMentorData = (userData: object) => {
  return {
    type: TRAINING_BE_GREAT_MENTOR,
    payload: userData,
  };
};

export const SaveBeyondProgramMentorData = (userData: object) => {
  return {
    type: TRAINING_BEYOND_PROGRAM_MENTOR,
    payload: userData,
  };
};

export const SaveBeyondProgramMenteeData = (userData: object) => {
  return {
    type: TRAINING_BEYOND_PROGRAM_MENTEE,
    payload: userData,
  };
};

export const SaveSurveyOneData = (userData: object) => {
  return {
    type: SURVEY_ONE,
    payload: userData,
  };
};

export const SaveSurveyTwoData = (userData: object) => {
  return {
    type: SURVEY_TWO,
    payload: userData,
  };
};

export const SaveGivingReceivingFeedbackMentorData = (userData: object) => {
  return {
    type: TRAINING_GIVING_RECEIVING_FEEDBACK_MENTOR,
    payload: userData,
  };
};

export const SaveGivingReceivingFeedbackMenteeData = (userData: object) => {
  return {
    type: TRAINING_GIVING_RECEIVING_FEEDBACK_MENTEE,
    payload: userData,
  };
};

export const SaveMakeTheMostMentor = (userData: object) => {
  return {
    type: TRAINING_MAKE_THE_MOST_MENTOR,
    payload: userData,
  };
};

export const SaveMakeTheMostMentee = (userData: object) => {
  return {
    type: TRAINING_MAKE_THE_MOST_MENTEE,
    payload: userData,
  };
};

export const SaveMoDBeGreatMenteeData = (userData: object) => {
  return {
    type: TRAINING_MOD_BE_GREAT_MENTEE,
    payload: userData,
  };
};

export const SaveMoDBeGreatMentorData = (userData: object) => {
  return {
    type: TRAINING_MOD_BE_GREAT_MENTOR,
    payload: userData,
  };
};

export const SaveMoDGoalSettingMenteeData = (userData: object) => {
  return {
    type: TRAINING_MOD_GOAL_SETTING_MENTEE,
    payload: userData,
  };
};

export const SaveMoDGoalSettingMentorData = (userData: object) => {
  return {
    type: TRAINING_MOD_GOAL_SETTING_MENTOR,
    payload: userData,
  };
};

export const SaveMoDPowerOfReflectionMentorData = (userData: object) => {
  return {
    type: TRAINING_POWER_OF_REFLECTION_MENTOR,
    payload: userData,
  };
};

export const SaveMoDPowerOfReflectionMenteeData = (userData: object) => {
  return {
    type: TRAINING_POWER_OF_REFLECTION_MENTEE,
    payload: userData,
  };
};

export const SaveMoDSituationalMentorshipMentorData = (userData: object) => {
  return {
    type: TRAINING_MOD_SITUATIONAL_MENTORSHIP_MENTOR,
    payload: userData,
  };
};

export const SaveMoDGrowthCoachingFrameworkMentorData = (userData: object) => {
  return {
    type: TRAINING_MOD_GROWTH_COACHING_FRAMEWORK_MENTOR,
    payload: userData,
  };
};

export const SaveEZEnglishMentorData = (userData: object) => {
  return {
    type: TRAINING_EASY_ENGLISH_MENTOR,
    payload: userData,
  };
};

export const SaveEzEnglishMenteeData = (userData: object) => {
  return {
    type: TRAINING_EASY_ENGLISH_MENTEE,
    payload: userData,
  };
};

export const SaveEzEnglishCCData = (userData: object) => {
  return {
    type: TRAINING_EASY_ENGLISH_CC,
    payload: userData,
  };
};

export const UtilGetTrainingConfigData = (cb: (a: IAPIResponseObject) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().user.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/trainingconfiguration',
        params: JSON.stringify({
          programId,
        }),
      },
      getState(),
    )
      .then((response: any) => {
        const { config, modules, customModules } = response.data.data;
        dispatch(SaveTrainingData({ config, modules, customModules }));
        cb(response.success);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilGetTrainingData = (cb: (a: IAPIResponseObject) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().user.programId;
    const roleId = getState().user.sessionRoleId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/training',
        params: JSON.stringify({
          programId,
          roleId,
        }),
      },
      getState(),
    )
      .then((response: any) => {
        dispatch(SaveTrainingData({ ...response.data.data }));
        cb(response.success);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilSaveModuleData = (
  moduleName,
  cb: (a: IAPIResponseObject) => void,
  requiresScore: boolean,
  overrideCompletedStatus: boolean,
) => {
  return (dispatch: any, getState: any) => {
    const roleId = getState().user.sessionRoleId;
    const programId = getState().user.programId;
    let moduleData = getState().training?.[moduleName] || {};
    const prevProgress = getState().training.progress;
    if (requiresScore) {
      let correctAmt = 0;
      let incorrectAmt = 0;
      let totalAmt = 0;
      Object.values(moduleData).forEach((a: any, v) => {
        if (a?.correct !== undefined) {
          totalAmt++;
          if (a.correct) {
            correctAmt++;
          } else {
            incorrectAmt++;
          }
        }
      });
      moduleData = { ...moduleData, correctAmt, incorrectAmt, totalAmt };
    }
    const progress = {
      ...prevProgress,
      [moduleName]: {
        completed: overrideCompletedStatus,
        submittedTime: new Date().getTime(),
      },
    };
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/training',
        data: {
          roleId,
          programId,
          moduleData,
          moduleName,
          progress,
        },
      },
      getState(),
    )
      .then((resp: any) => {
        dispatch(SaveTrainingData({ progress }));
        if (overrideCompletedStatus) {
          BrancherDispatch(
            dispatch,
            UtilUpdateRoleProfileActivities(EProgressActivities.HAS_COMPLETED_TRAINING),
          );
          const isMentee = getState().user.sessionPosition === ProgramPositions.mentee;
          const progressAttribute = isMentee
            ? 'menteeGetStartedProgress'
            : 'mentorGetStartedProgress';
          if (!getState().user?.[progressAttribute]?.hasCompletedTraining) {
            dispatch(
              SaveUserInfo({
                [progressAttribute]: {
                  ...getState().user[progressAttribute],
                  hasCompletedTraining: true,
                },
              }),
            );
          }
          const trainingActions = getState().user.actions?.filter(
            (t) => t.type === EUserActions.COMPLETE_TRAINING,
          );
          if (trainingActions?.length > 0) {
            BrancherDispatch(
              dispatch,
              UtilRemoveUserAction(trainingActions[0].userActionId, () => {}),
            );
          }
        }
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export enum ESurveyStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  CLOSED = 'closed',
}

interface IGetSurveyConfiguration extends IAPIResponseObject {
  data: {
    configuration: any[];
    surveyDueDate?: string;
    surveyStatus?: ESurveyStatus;
  };
}

export const UtilGetSurveyConfiguration = (
  surveyId: string,
  cb: (a: IGetSurveyConfiguration) => void,
) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/surveyconfiguration',
        params: JSON.stringify({
          surveyId,
        }),
      },
      getState(),
    )
      .then((response: any) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilSaveSurvey = (
  surveyId: string,
  surveyData: any,
  userActionId: string,
  cb: (a: IAPIResponseObject) => void,
) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/survey',
        data: {
          surveyId,
          surveyData,
        },
      },
      getState(),
    )
      .then((resp: any) => {
        if (resp.data?.success) {
          BrancherDispatch(
            dispatch,
            UtilUpdateRoleProfileActivities(EProgressActivities.HAS_COMPLETED_SURVEY),
          );
          BrancherDispatch(
            dispatch,
            UtilRemoveUserAction(userActionId, () => {
              cb(resp.data);
            }),
          );
        } else {
          cb(resp.data);
        }
      })
      .catch((error) => {
        cb(error);
      });
  };
};
