import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { SuggestionCard } from '../../SuggestionCard';
import { CreateMentoringGuideLink } from '../../helpers/LinkBuilder';

export const IntroductionMenteeStepOneCorrect = () => {
  const mentoringGuideLink = CreateMentoringGuideLink();
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          Great choice. The best options are{' '}
        </Text>
        <Text variant="md" fontWeight={700} color="purple" display="inline">
          B{' '}
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          and{' '}
        </Text>
        <Text variant="md" fontWeight={700} color="purple" display="inline">
          C
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          .
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Reviewing the{' '}
        </Text>
        <BrancherLinkText
          underline="always"
          fontWeight={700}
          variant="md"
          href={mentoringGuideLink}
          display="inline"
          target="_blank"
        >
          Mentoring Guide
        </BrancherLinkText>
        <Text variant="md" display="inline">
          {' '}
          will provide you with clarity and confidence on your role as a mentee. As the mentee, you
          should be driving the mentoring relationship, including setting up meetings, setting up
          the agenda, keeping your mentor updated with your progress and how they can help you.
        </Text>
        <Text variant="md" display="inline">
          Having an open discussion with Alex about how you are feeling will allow Alex to
          understand why you would like to take the lead, and ensure Alex gives you the time to do
          so.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          You could say things like:
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard>
          “I’m keen to own my development, I’ll send through an invite for our next catch up and
          prepare an agenda.”
        </SuggestionCard>
        <SuggestionCard>
          “There are a few things I’d love to talk aloud with you and it would be great to take
          advantage of your listening skills.”
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="md">
          Ensure you promptly follow through with scheduling your next catch up and owning the next
          agenda so Alex is confident you’re following through.
        </Text>
      </Grid>
    </Grid>
  );
};
