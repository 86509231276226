import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { SaveSurveyOneData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const SurveyStepSix = () => {
  const surveyOneValue = useSelector(
    (state: IStoreTypes) => state.training?.[EStructuredTrainingModules.SURVEY_ONE],
  );
  const dispatch = useDispatch();

  const updateValue = (feedbackValue: string) => {
    dispatch(
      SaveSurveyOneData({
        StepSix: {
          interacted: !!feedbackValue,
          feedback: feedbackValue,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={600}>
          Do you have any initial feedback on the program experience so far?
        </Text>
      </Grid>
      <Grid item>
        <BrancherTextField
          name="feedback"
          placeholder="Enter here"
          value={surveyOneValue?.StepSix?.feedback}
          updateValue={updateValue}
          rowsMax={5}
          fullWidth
          multiline
        />
      </Grid>
    </Grid>
  );
};
