import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { CheckAnswerSection } from '../../../../../Components/General/CheckAnswerSection';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const BeGreatMenteeStepFourCorrect = () => {
  const stepFourValue = useSelector((state: IStoreTypes) => state.training.beGreatMentee.StepFour);
  return (
    <Grid container direction="column" spacing={4}>
      <CheckAnswerSection correct={stepFourValue.correct}>
        <Text variant="sm" fontWeight={700} marginBottom={20}>
          {stepFourValue.correct
            ? 'Correct'
            : stepFourValue?.selected?.includes('1') || stepFourValue?.selected?.includes('3')
            ? 'Almost there'
            : 'Not quite'}
          .
        </Text>
        {stepFourValue.correct ? (
          <Text variant="sm">
            At its core, having a growth mindset involves believing in learning and development and
            thinking everyone can grow. You may want to consider more than one option.
          </Text>
        ) : stepFourValue?.selected?.includes('1') || stepFourValue?.selected?.includes('3') ? (
          <Text variant="sm">
            At its core, having a growth mindset involves believing in learning and development and
            thinking everyone can grow. You may want to consider more than one option.
          </Text>
        ) : (
          <Text variant="sm">
            Whilst being open minded and being positive have their benefits, these are not core
            elements of having a growth mindset.
          </Text>
        )}
      </CheckAnswerSection>
    </Grid>
  );
};
