import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { CharlieAvatar } from '../../avatars/Charlie';
import { BrancherList } from '../../../../../Components/General/BrancherList';

export const BeGreatMentorStepSixteenCorrect = () => {
  const stepSixteenValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentor.StepSixteen,
  );
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard
          custom
          success={stepSixteenValue.correct}
          incorrect={!stepSixteenValue.correct}
        >
          <Text variant="md" fontWeight={700}>
            {stepSixteenValue.correct ? "That's right" : 'Almost there'}.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          <Text variant="sm">
            Scaling is a versatile way to see problems on a continuum instead of something that is
            fixed. You can use scaling to:
          </Text>
          <BrancherList
            fontWeight={400}
            listItems={[
              'Keep them motivated by tracking their progress',
              'Focus on next action steps (small and achievable)',
              'Assess confidence and motivation in reaching goal',
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
