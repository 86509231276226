import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { Youtube } from '../../../../../Components/General/YouTube';
import { YouTubeMasterFeedbackVideoId } from '../../../../../consts/YouTubeVideoIds';
import {
  SaveGivingReceivingFeedbackMenteeData,
  SaveGivingReceivingFeedbackMentorData,
} from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { GetTrainingResourceLink, GetYouTubeResourceLink } from '../../../../../utils/LinkUtils';

export const GivingReceivingFeedbackMenteeStepTwo = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const sessionSaveFunction =
    position === ProgramPositions.mentee
      ? SaveGivingReceivingFeedbackMenteeData
      : SaveGivingReceivingFeedbackMentorData;
  const dispatch = useDispatch();

  const updateValue = () => {
    dispatch(
      sessionSaveFunction({
        StepTwo: {
          interacted: true,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" display="inline">
          At Brancher, we've taken the best parts of many feedback models to give you a framework
          that is easy and effective. We call it the{' '}
        </Text>
        <Text variant="md" fontWeight={700} display="inline">
          MASTER
        </Text>
        <Text variant="md" display="inline">
          {' '}
          model.
        </Text>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item xs={12} md={4}>
          <Box marginBottom={5}>
            <Text variant="md" fontWeight={700} display="inline">
              See the{' '}
            </Text>
            <BrancherLinkText
              onClick={updateValue}
              variant="md"
              display="inline"
              target="_blank"
              href={GetTrainingResourceLink(
                EStructuredTrainingModules.GIVING_RECEIVING_FEEDBACK_MENTEE,
                'Master_feedback_model.pdf',
              )}
              underline="always"
              fontWeight={700}
            >
              MASTER model infographic
            </BrancherLinkText>
          </Box>
        </Grid>
        <Grid item container justify="center" xs={12} md={8}>
          <BrancherTrainingImage
            src={GetTrainingResourceLink(
              EStructuredTrainingModules.GIVING_RECEIVING_FEEDBACK_MENTEE,
              'master_model.png',
            )}
            alt="master-model"
            width="100%"
          />
        </Grid>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item xs={12} md={4}>
          <Text variant="md" fontWeight={700} marginBottom={40} marginTop={40}>
            Watch the MASTER model in action
          </Text>
        </Grid>
        <Grid item xs={12} md={8}>
          <Youtube src={GetYouTubeResourceLink(YouTubeMasterFeedbackVideoId)} width="100%" />
        </Grid>
      </Grid>
    </Grid>
  );
};
