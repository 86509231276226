import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveMoDSituationalMentorshipMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { CharlieAvatar } from '../../avatars/Charlie';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';

export const MoDSituationalMentorshipMentorStepFour = () => {
  const modName = EMoDTrainingModules.MOD_SITUATIONAL_MENTORSHIP_MENTOR;
  const stepFourValue = useSelector((state: IStoreTypes) => state.training?.[modName]?.StepFour);
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepFourCorrectVal = '1';
    dispatch(
      SaveMoDSituationalMentorshipMentorData({
        StepFour: {
          selected: val,
          correct: StepFourCorrectVal === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Charlie was very frustrated at a colleague...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          In another mentoring session, Charlie says, "I'm really frustrated at this colleague at
          work. They keep missing my deadlines and not responding to my emails. I don't understand
          why. I've tried following up with them. I've tried everything. What should I do?"
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          What mentoring style should you adopt?
        </Text>
      </Grid>
      <Grid item container justify="center">
        <SelectionControl
          question=""
          halfWidth
          name="stepFourValue"
          value={stepFourValue?.selected}
          updateValue={updateValue}
          noQuestion
          exclusive
          options={MakeOptions([
            'Director (telling)',
            'Coach (questioning)',
            'Support (encouraging)',
            'Challenge (feedback)',
          ])}
        />
      </Grid>
    </Grid>
  );
};
