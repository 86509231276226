import { BrancherAuthRequest } from './utils/api-utils';
import { IAPIResponseObject } from './actionTypes/apiTypes';
import { IGroup } from '../../PostMatching/Groups/Groups';
import { IExtendedGroupData } from '../../PostMatching/Groups/Group';

interface IUtilGetProgramGroups extends IAPIResponseObject {
  data: IGroup[];
}

export const UtilGetUserGroups = (cb: (a: IUtilGetProgramGroups) => void) => {
  return (dispatch: any, getState: any) => {
    const groups = getState().user.groups;
    const position = getState().user.sessionPosition;
    const roleId = getState().user.sessionRoleId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/usergroups',
        params: JSON.stringify({
          roleId,
          position,
          groups,
        }),
      },
      getState(),
    )
      .then((response: { data: IUtilGetProgramGroups }) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetProgramGroup extends IAPIResponseObject {
  data: IExtendedGroupData;
}

export const UtilGetProgramGroup = (groupId: string, cb: (a: IUtilGetProgramGroup) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().user.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/group',
        params: JSON.stringify({
          programId,
          groupId,
        }),
      },
      getState(),
    )
      .then((response: { data: IUtilGetProgramGroup }) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};
