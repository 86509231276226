import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { EContentType, IPrivateNote } from '../../../../../store/reducers/PrivateNotesReducer';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import {
  BrancherDispatch,
  SaveBeGreatMenteeData,
  UtilSaveUserPrivateNotesData,
  UtilSaveUserSharedNotesData,
} from '../../../../../store/actions/';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveButton } from '../../../../../Components/InputFields/BrancherButton';
import { SuggestionCard } from '../../SuggestionCard';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { SmartGoalsInfo } from '../../sharedComps/SmartGoalsInfo';
import { FormWithStepFieldLayout } from '../../../../../Components/InputFields/FormWithStepFieldLayout';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { ScaleText } from '../../../../../Components/General/ScaleText';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const BeGreatMenteeStepTwo = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const beGreatMenteeValue = useSelector((state: IStoreTypes) => state.training.beGreatMentee);
  const dispatch = useDispatch();
  const [openDialog, setDialog] = React.useState(false);
  const isMentee = position === ProgramPositions.mentee;
  const partnerRole = isMentee ? ProgramPositions.mentor : ProgramPositions.mentee;

  const saveNote = () => {
    const apiCall =
      beGreatMenteeValue?.StepTwo?.sharePublic === '0'
        ? UtilSaveUserPrivateNotesData
        : UtilSaveUserSharedNotesData;
    const noteData: IPrivateNote = {
      contents: [
        {
          content: [
            beGreatMenteeValue?.StepTwo?.stoppingYou,
            beGreatMenteeValue?.StepTwo?.ensureRegular,
          ] as string[] & string,
          type: EContentType.LIST,
        },
      ],
      title: 'Be great - Engagement and understanding',
      lastModified: Date.now(),
    };
    BrancherDispatch(
      dispatch,
      apiCall(noteData, () => {
        updateValue('savedNote', true);
      }),
    );
  };

  const updateValue = (attributeName: string, val: string | boolean) => {
    const newStepTwoVals = { ...beGreatMenteeValue.StepTwo, [attributeName]: val };
    dispatch(
      SaveBeGreatMenteeData({
        StepTwo: {
          ...beGreatMenteeValue.StepTwo,
          [attributeName]: val,
          interacted: Boolean(
            newStepTwoVals?.ensureRegular &&
              newStepTwoVals?.stoppingYou &&
              newStepTwoVals?.savedNote &&
              newStepTwoVals?.sharePublic &&
              newStepTwoVals?.authentic !== null &&
              newStepTwoVals?.driveRelationship !== null &&
              newStepTwoVals?.engageOpenly !== null &&
              newStepTwoVals?.helpMentorUnderstand !== null &&
              newStepTwoVals?.growthMindset !== null &&
              newStepTwoVals?.ownDevelopment !== null,
          ),
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <SmartGoalsInfo controlDialog={setDialog} open={openDialog} />
      <Grid item>
        <Text variant="md" fontWeight={700} marginTop={20}>
          Reflecting on your mentoring experience so far, how regularly have you been doing the
          behaviours listed below?
        </Text>
      </Grid>
      <Grid item container>
        <ScaleText scale={5} description="Always" />
        <ScaleText scale={4} description="Often" />
        <ScaleText scale={3} description="Sometimes" />
        <ScaleText scale={2} description="Rarely" />
        <ScaleText scale={1} description="Rarely" />
      </Grid>
      <Grid item>
        <FormWithStepFieldLayout step={1} question="Engage openly">
          <SegmentedControlGroup
            updateValue={(a: string) => updateValue('engageOpenly', a)}
            valueLength={5}
            value={beGreatMenteeValue?.StepTwo?.engageOpenly}
            fullWidth
          />
        </FormWithStepFieldLayout>
      </Grid>
      <Grid item>
        <FormWithStepFieldLayout
          step={2}
          question="Help your mentor understand you, your work and your goals"
        >
          <SegmentedControlGroup
            updateValue={(a: string) => updateValue('helpMentorUnderstand', a)}
            valueLength={5}
            value={beGreatMenteeValue?.StepTwo?.helpMentorUnderstand}
            fullWidth
          />
        </FormWithStepFieldLayout>
      </Grid>
      <Grid item>
        <FormWithStepFieldLayout
          step={3}
          question="Own your development: establish and track your goals"
        >
          <SegmentedControlGroup
            updateValue={(a: string) => updateValue('ownDevelopment', a)}
            valueLength={5}
            value={beGreatMenteeValue?.StepTwo?.ownDevelopment}
            fullWidth
          />
        </FormWithStepFieldLayout>
      </Grid>
      <Grid item>
        <FormWithStepFieldLayout
          step={4}
          question="Drive the mentoring relationship; organise meetings and agendas"
        >
          <SegmentedControlGroup
            updateValue={(a: string) => updateValue('driveRelationship', a)}
            valueLength={5}
            value={beGreatMenteeValue?.StepTwo?.driveRelationship}
            fullWidth
          />
        </FormWithStepFieldLayout>
      </Grid>
      <Grid item>
        <FormWithStepFieldLayout step={5} question="Take a growth mindset">
          <SegmentedControlGroup
            updateValue={(a: string) => updateValue('growthMindset', a)}
            valueLength={5}
            value={beGreatMenteeValue?.StepTwo?.growthMindset}
            fullWidth
          />
        </FormWithStepFieldLayout>
      </Grid>
      <Grid item>
        <FormWithStepFieldLayout step={6} question="Be authentic and grateful">
          <SegmentedControlGroup
            updateValue={(a: string) => updateValue('authentic', a)}
            valueLength={5}
            value={beGreatMenteeValue?.StepTwo?.authentic}
            fullWidth
          />
        </FormWithStepFieldLayout>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          For the mentoring behaviours that you have been doing less regularly, what is stopping you
          from doing these more often?
        </Text>
      </Grid>
      <Grid item>
        <BrancherTextField
          value={beGreatMenteeValue?.StepTwo?.stoppingYou}
          updateValue={(a: string) => updateValue('stoppingYou', a)}
          multiline
          fullWidth
          placeholder="Enter here"
          maxChars={400}
        />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700} display="inline">
          As you may recall, a popular strategy for goal setting is the{' '}
        </Text>
        <BrancherLinkText
          variant="md"
          fontWeight={700}
          display="inline"
          onClick={() => setDialog(true)}
          underline="always"
        >
          SMART goal principles
        </BrancherLinkText>
        <Text variant="md" fontWeight={700} display="inline">
          . Using the SMART model:
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          How could you ensure you do the behaviours above more regularly?
        </Text>
      </Grid>
      <Grid item>
        <BrancherTextField
          value={beGreatMenteeValue?.StepTwo?.ensureRegular}
          updateValue={(a: string) => updateValue('ensureRegular', a)}
          multiline
          fullWidth
          placeholder="Enter here"
          maxChars={400}
        />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Would you like to keep this content private or share with your {partnerRole} in your
          shared notes?
        </Text>
      </Grid>
      <Grid item>
        <SuggestionCard>
          Whilst sharing this information is optional, we do encourage it. Sharing this information
          can help you and your mentor to reflect, evaluate and plan.
        </SuggestionCard>
      </Grid>
      <Grid item>
        <SelectionControl
          noQuestion
          question=""
          name="sharePublic"
          value={beGreatMenteeValue?.StepTwo?.sharePublic}
          exclusive
          options={MakeOptions(['Private', 'Partner only'])}
          updateValue={(a: string) => updateValue('sharePublic', a)}
        />
        <SaveButton
          onClick={saveNote}
          disabled={
            !beGreatMenteeValue?.StepTwo?.sharePublic ||
            beGreatMenteeValue?.StepTwo?.savedNote ||
            !(
              beGreatMenteeValue?.StepTwo?.stoppingYou && beGreatMenteeValue?.StepTwo?.ensureRegular
            )
          }
        >
          Create note
        </SaveButton>
      </Grid>
    </Grid>
  );
};
