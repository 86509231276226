import { IReducerType } from './ReducerType';
import { ECalendars, IMeetingAgendas } from '../../PostMatching/Meetings/Scheduler';

export const MEETINGS_CLEAR_DATA = 'MEETINGS_CLEAR_DATA';
export const MEETINGS_ADD_ALL = 'MEETINGS_ADD_ALL';
export const MEETINGS_ADD = 'MEETINGS_ADD';
export const FOCUS_AREAS_ALL = 'FOCUS_AREAS_ALL';
export const MEETINGS_REMOVE_DATA = 'MEETINGS_REMOVE_DATA';

export const MeetingsReducer = (state = MeetingsInitialState, action: IReducerType) => {
  switch (action.type) {
    case MEETINGS_CLEAR_DATA:
      return MeetingsInitialState;
    case MEETINGS_ADD_ALL:
      return { ...state, meetings: action.payload };
    case MEETINGS_ADD:
      return { ...state, meetings: [...state.meetings, action.payload] };
    case FOCUS_AREAS_ALL:
      return { ...state, focusAreas: action.payload };
    case MEETINGS_REMOVE_DATA:
      return {
        ...state,
        meetings: state.meetings.filter((meet) => meet.meetingId !== action.payload),
      };
    default:
      return state;
  }
};

export enum IMeetingParticipantStatus {
  ACCEPTED = 'accepted',
  TENTATIVE = 'tentative',
  DECLINED = 'declined',
  NO_RESPONSE = 'no-response',
}

export interface IMeetingParticipant {
  roleId: string;
  status: IMeetingParticipantStatus;
}

export interface IMeetingAction {
  meetingActionId: string;
  actionTitle: string;
  completed: boolean;
  assignedRoleId?: string;
  dueDate?: string;
  completedMeeting?: string;
}

export interface IMeetingData {
  meetingId: string;
  programId: string;
  datetimeStart: Date;
  datetimeEnd: Date;
  location: string;
  title: string;
  description: string;
  participants: IMeetingParticipant[];
  organiser: string; // roleId
  calendar?: ECalendars;
  groupId?: string;
  topicId?: string;
  agendas?: IMeetingAgendas[];
  actions?: IMeetingAction[];
  notes?: string;
}

export interface IAgenda {
  agendaId: string;
  agendaTitle: string;
}

export interface IFocusArea {
  topicId: string;
  topicTitle: string;
  focusAreaTitle: string;
  agendas: IAgenda[];
  programId?: string; // if this doesn't exist, it's Brancher owned and is universal
}

export interface IMeetingsReducer {
  meetings?: IMeetingData[];
  focusAreas?: IFocusArea[];
}

export const MeetingsInitialState: IMeetingsReducer = {
  meetings: [],
  focusAreas: [],
};
