import * as React from 'react';
import { ScaleText } from '../../../../Components/General/ScaleText';

export const NPSScale = () => {
  return (
    <>
      <ScaleText scale={10} description="Extremely likely" />
      <ScaleText scale={0} description="Not at all likely" />
    </>
  );
};
