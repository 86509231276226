import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch } from 'react-redux';
import { Text } from '../../Components/General/Text';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { cognitoClientId, signupRedirectUri } from '../../consts/cognito';
import { IUtilSignUpUserResponse } from '../../store/actions/UserInfoActions';
import { UtilSSOSignUp } from '../../store/actions';
import { ESessionAttributes, getSessionAttribute } from '../../utils/cookies';
import { GetSharedResourceLink } from '../../utils/LinkUtils';
import { SSOTypes } from '../Login/SSOSignIn';

interface ISSOSignUp {
  controlSSOUser: (res: IUtilSignUpUserResponse) => void;
  type: SSOTypes;
}

export const SSOSignUp: React.FC<ISSOSignUp> = ({ controlSSOUser, type }) => {
  const [gettingToken, setGettingToken] = React.useState<boolean>(false);
  const params = new URLSearchParams(document.location.search.substring(1));
  const code = params.get('code');
  const programId = getSessionAttribute(ESessionAttributes.SIGNUP_PROGRAM_ID);
  const dispatch = useDispatch();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  React.useEffect(() => {
    if (code && !gettingToken) {
      signupSSO();
    }
  }, [code]);

  const signupSSO = () => {
    setGettingToken(true);
    dispatch(
      UtilSSOSignUp(code, programId, (a) => {
        setGettingToken(false);
        controlSSOUser(a);
      }),
    );
  };

  // TODO: OKTA should follow the SAML approach of retrieving the identity_provider name url, but can still follow the response_type=code
  const epLink: string =
    type === SSOTypes.GOOGLE
      ? `https://brancher.auth.ap-southeast-2.amazoncognito.com/authorize?response_type=code&client_id=${cognitoClientId}&redirect_uri=${signupRedirectUri}&identity_provider=Google&scope: aws.cognito.signin.user.admin email openid phone profile`
      : `https://brancher.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize?identity_provider=Okta-SSO&response_type=code&client_id=${cognitoClientId}&redirect_uri=${signupRedirectUri}&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile`;

  const icon: string = type === SSOTypes.GOOGLE ? 'googleIcon.png' : 'oktaIcon.png';
  const ssoTypeName = type === SSOTypes.GOOGLE ? 'Google' : 'Okta';

  return (
    <BrancherButton onClick={() => window.location.assign(epLink)} disabled={!programId}>
      <img height={mobile ? 20 : 30} alt="SSO-sign-up-logo" src={GetSharedResourceLink(icon)} />
      <Text variant="xs" color="tertiaryGrey" marginLeft={15} fontWeight={600}>
        Sign up with {ssoTypeName}
      </Text>
    </BrancherButton>
  );
};
