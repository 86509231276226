import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { SaveEzEnglishCCData } from '../../../../../store/actions';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const EasyEnglishCCStepThree = () => {
  const stepThreeValue = useSelector(
    (state: IStoreTypes) => state.training.easyEnglishCC?.StepThree,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const correctVal = '0';
    dispatch(
      SaveEzEnglishCCData({
        StepThree: {
          selected: val,
          correct: val === correctVal,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md">
          You need to change your meeting as you will be on holiday.
          <br />
          What do you do?
        </Text>
      </Grid>
      <Grid item>
        <Text variant="sm" marginBottom={-30}>
          Choose one option.
        </Text>
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepThreeValue"
          value={stepThreeValue?.selected}
          updateValue={updateValue}
          noQuestion
          exclusive
          options={MakeOptions([
            'Tell your partner. Make a new time to meet',
            'Tell your friend',
            'Stay silent',
          ])}
        />
      </Grid>
    </Grid>
  );
};
