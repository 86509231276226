import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { BrancherTrainingImage } from '../../../../../Components/General/BrancherTrainingImage';
import { Text } from '../../../../../Components/General/Text';
import {
  YouTubeFeedbackGoneAwryVideoId,
  YouTubeTestimonialsVideoId,
} from '../../../../../consts/YouTubeVideoIds';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import {
  SaveGivingReceivingFeedbackMenteeData,
  SaveGivingReceivingFeedbackMentorData,
} from '../../../../../store/actions';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { GetTrainingResourceLink, GetYouTubeResourceLink } from '../../../../../utils/LinkUtils';

export const GivingReceivingFeedbackMenteeStepOne = () => {
  const dispatch = useDispatch();
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const sessionSaveFunction =
    position === ProgramPositions.mentee
      ? SaveGivingReceivingFeedbackMenteeData
      : SaveGivingReceivingFeedbackMentorData;

  const updateValue = () => {
    dispatch(
      sessionSaveFunction({
        StepOne: {
          interacted: true,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Feedback can be extremely helpful or extremely destructive.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          Watch{' '}
        </Text>
        <BrancherLinkText
          onClick={updateValue}
          variant="md"
          display="inline"
          target="_blank"
          href={GetYouTubeResourceLink(YouTubeFeedbackGoneAwryVideoId)}
          underline="always"
        >
          this
        </BrancherLinkText>
        <Text variant="md" display="inline">
          {' '}
          video to get started with the Giving and Receiving Feedback module.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          This module will help you avoid poor feedback experiences, like the ones you just saw, by
          applying the Brancher feedback model,{' '}
        </Text>
        <Text variant="md" display="inline" color="purple" fontWeight={700}>
          MASTER
        </Text>
        <Text variant="md" display="inline">
          .
        </Text>
      </Grid>
      <Grid item container justify="center">
        <BrancherTrainingImage
          src={GetTrainingResourceLink(
            EStructuredTrainingModules.GIVING_RECEIVING_FEEDBACK_MENTEE,
            'master_model.png',
          )}
          alt="master-model"
        />
      </Grid>
      <Grid item>
        <Text variant="md" display="inline">
          If you need convincing that feedback is helpful, check out these{' '}
        </Text>
        <BrancherLinkText
          variant="md"
          display="inline"
          target="_blank"
          href={GetYouTubeResourceLink(YouTubeTestimonialsVideoId)}
          underline="always"
        >
          testimonials
        </BrancherLinkText>
        <Text variant="md" display="inline">
          .
        </Text>
      </Grid>
    </Grid>
  );
};
