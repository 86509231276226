import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { SallyAvatar } from '../../avatars/Sally';

export const BeGreatMenteeStepEightCorrect = () => {
  const stepEightValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentee.StepEight,
  );
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepEightValue.correct} incorrect={!stepEightValue.correct}>
          <Text variant="md" fontWeight={700}>
            {stepEightValue.correct ? 'Correct!' : 'Not quite.'}
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <SallyAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          {stepEightValue.correct ? (
            <Text variant="sm">
              Sometimes it's helpful to reflect on our strengths and how they can help us. In this
              example you are clearly outlining a strength and how it could help you through the
              situation.
            </Text>
          ) : stepEightValue.selected === '0' ? (
            <Text variant="sm">
              It's OK if you don't know what strengths you have. You might like to ask friends and
              family members, 'what am I good at?' or 'what would you say my superpower is?' to help
              you reflect on what you excel at.
            </Text>
          ) : (
            <Text variant="sm">
              Whilst you do have lots of strengths, responding this way is not specific enough for
              you or your mentor to reflect on a specific strength of yours and how it could help
              the situation.
            </Text>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
