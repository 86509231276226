import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { format, parse } from 'date-fns';
import { calenderEventFormat } from './CalendarEvents';
import { IMeetingAgendas } from './Scheduler';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { CalendarButton } from '../../Components/InputFields/BrancherButton';
import { IStoreTypes } from '../../store/storeTypes';
import { Text } from '../../Components/General/Text';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { Colors } from '../../consts/colors';
import { IMeetingAction, IMeetingData } from '../../store/reducers/MeetingsReducer';
import { BrancherCheckbox } from '../../Components/InputFields/BrancherCheckbox';
import { BrancherSelect } from '../../Components/InputFields/BrancherSelect';
import { AddMeetingItem } from './UpdateCalendarEvent';
import {
  BrancherDispatch,
  UtilGetProgramCohortProfiles,
  UtilUpdateMeetingMinutes,
} from '../../store/actions';
import { EProfileAttributes } from '../../store/reducers/ProfileFormReducer';
import { MeetingMinutesDescription } from './MeetingMinutesDescription';

const useStyles = makeStyles({
  parentContainer: {
    marginBottom: 100,
  },
  fixedActionPane: {
    position: 'absolute',
    bottom: 0,
    height: 100,
    marginLeft: 30,
    backgroundColor: Colors.backgroundLightPurple,
    width: '100vw',
    zIndex: 100,
    boxShadow: `inset 0px 2px 2px -2px ${Colors.darkGrey}`,
  },
});

interface IMeetingMinutes {
  meetingData: IMeetingData;
  open: boolean;
  setClose: () => void;
}

export const MeetingMinutes: React.FC<IMeetingMinutes> = ({ meetingData, open, setClose }) => {
  const focusAreas = useSelector((state: IStoreTypes) => state.meetings?.focusAreas);
  const cohort = useSelector((state: IStoreTypes) => state.cohort);
  const [topicId, setTopicId] = React.useState<string>(meetingData?.topicId ?? '');
  const [agendas, setAgendas] = React.useState<IMeetingAgendas[]>(meetingData?.agendas ?? []);
  const [actions, setActions] = React.useState<IMeetingAction[]>(meetingData?.actions ?? []);
  const [fullParticipantProfiles, setFullParticipantProfiles] = React.useState<
    Array<{ value: string; label: string }>
  >([]);
  const [notes, setNotes] = React.useState<string>('');
  const [actionItem, setActionItem] = React.useState<string>('');
  const [agendaItem, setAgendaItem] = React.useState<string>('');
  const [updatingMinutes, setUpdatingMinutes] = React.useState<boolean>(false);
  const [gettingProfiles, setGettingProfiles] = React.useState<boolean>(false);
  const [startDate] = React.useState<string>(
    format(new Date(meetingData.datetimeStart?.toString()), calenderEventFormat),
  );
  const styles = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!(cohort?.profiles?.length > 1)) {
      setGettingProfiles(true);
      BrancherDispatch(
        dispatch,
        UtilGetProgramCohortProfiles(() => {
          setGettingProfiles(false);
        }),
      );
    }
  }, []);

  React.useEffect(() => {
    if (cohort.profiles?.length > 1 && !!meetingData) {
      const meetingParticipantOptions = meetingData.participants?.map((p) => {
        const profile = cohort?.profiles?.find((cp) => cp.roleId === p.roleId);
        const participantName = `${profile?.[EProfileAttributes.FIRST_NAME]} ${
          profile?.[EProfileAttributes.LAST_NAME]
        } `;
        return { value: p.roleId, label: participantName };
      });
      setFullParticipantProfiles(meetingParticipantOptions);
    }
  }, [cohort]);

  const controlUpdateMeetingMinutes = () => {
    const meetingMinutes = {
      notes,
      topicId,
      agendas,
      actions,
    };
    setUpdatingMinutes(true);
    BrancherDispatch(
      dispatch,
      UtilUpdateMeetingMinutes(meetingData.meetingId, meetingMinutes, () => {
        setUpdatingMinutes(false);
        setClose();
      }),
    );
  };

  const controlSelectAgenda = (selectedTopic: string) => {
    setTopicId(selectedTopic);
    const topic = focusAreas.find((fa) => fa.topicId === selectedTopic);
    setAgendas(topic.agendas.map((ta) => ({ ...ta, completed: false })));
  };

  const updateAgendaStatus = (agendaId: string, completedAgenda: boolean) => {
    const updatedAgendas = agendas.map((agenda) => {
      if (agenda.agendaId === agendaId) {
        return { ...agenda, completed: completedAgenda };
      } else {
        return agenda;
      }
    });
    setAgendas(updatedAgendas);
  };

  const updateActionStatus = (actionId: string, completedAction: boolean) => {
    const updatedActions = actions.map((action) => {
      if (action.meetingActionId === actionId) {
        return { ...action, completed: completedAction };
      } else {
        return action;
      }
    });
    setActions(updatedActions);
  };

  const updateActionDueDate = (actionId: string, newDate: string) => {
    const updatedActions = actions.map((action) => {
      if (action.meetingActionId === actionId) {
        return { ...action, dueDate: format(new Date(newDate), 'dd/MM/yyyy') };
      } else {
        return action;
      }
    });
    setActions(updatedActions);
  };

  const updateActionOwner = (actionId: string, owner: string) => {
    const updatedActions = actions.map((action) => {
      if (action.meetingActionId === actionId) {
        return { ...action, assignedRoleId: owner };
      } else {
        return action;
      }
    });
    setActions(updatedActions);
  };

  const setRemoveAction = (actionIndex: number) => {
    setActions(actions.filter((m, i) => i !== actionIndex));
  };

  const setRemoveAgendaItem = (agendaIndex: number) => {
    setAgendas(agendas.filter((m, i) => i !== agendaIndex));
  };

  const controlAddActionItem = () => {
    setActions([
      ...actions,
      { meetingActionId: uuidv4(), actionTitle: actionItem, completed: false },
    ]);
    setActionItem('');
  };

  const controlAddAgendaItem = () => {
    setAgendas([...agendas, { agendaId: uuidv4(), agendaTitle: agendaItem, completed: false }]);
    setAgendaItem('');
  };

  const futureMeetingActions: IMeetingAction[] = actions?.filter((a) => !a.completed);

  return (
    <BrancherDialog
      setClose={setClose}
      open={open}
      labelledBy="meeting-minutes"
      title="Log your meeting minutes"
      fitLargeScreen
    >
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justify="center"
        spacing={3}
        className={styles.parentContainer}
      >
        <Grid item xs={12} container justify="center">
          <Text variant="md" fontWeight={600}>
            {meetingData.title} @ {startDate}
          </Text>
        </Grid>
        <Grid item xs={12}>
          <MeetingMinutesDescription creatingMeeting={false} />
        </Grid>
        <Grid item xs={12}>
          <BrancherSelect
            options={focusAreas.map((focusArea) => ({
              label: `${focusArea.topicTitle}`,
              value: focusArea.topicId,
            }))}
            value={topicId}
            inputLabel="Suggested Topics"
            name="agendaTopic"
            id="agendaTopic"
            fullWidth
            updateValue={(topic: string) => controlSelectAgenda(topic)}
            helpText="Choose a template for the meeting - this will provide a list of talking points for current and future meetings"
          />
        </Grid>
        <Grid item xs={12}>
          <Text variant="sm" fontWeight={600} marginBottom={15}>
            Talking points (please tick off points you've completed)
          </Text>
          {agendas?.length > 0 ? (
            agendas?.map((agenda, agendaIndex) => (
              <Grid item key={agenda.agendaId}>
                <BrancherCheckbox
                  name={agenda.agendaTitle}
                  value={agenda.completed}
                  updateValue={(comp: boolean) => updateAgendaStatus(agenda.agendaId, comp)}
                  label={
                    <>
                      <Text variant="sm" display="inline" marginRight={15}>
                        {agenda.agendaTitle}
                      </Text>
                      <IconButton onClick={() => setRemoveAgendaItem(agendaIndex)}>
                        <DeleteForever color="error" />
                      </IconButton>
                    </>
                  }
                />
              </Grid>
            ))
          ) : (
            <Text variant="sm">
              You don't have any talking points assigned to this meeting yet.
            </Text>
          )}
          <AddMeetingItem
            addValue={controlAddAgendaItem}
            setValue={setAgendaItem}
            canUpdate={true}
            value={agendaItem}
            label="Add new talking point"
          />
        </Grid>
        <Grid item xs={12}>
          <Text variant="sm" fontWeight={600} marginBottom={15}>
            Action items (please tick off actions you've completed)
          </Text>
          {futureMeetingActions?.length > 0 ? (
            futureMeetingActions?.map((action, actionIndex) => (
              <Grid container spacing={3} justify="space-between" key={action.meetingActionId}>
                <Grid item xs={10} sm={6}>
                  <BrancherCheckbox
                    name={action.actionTitle}
                    value={action.completed}
                    updateValue={(comp: boolean) =>
                      updateActionStatus(action.meetingActionId, comp)
                    }
                    label={
                      <>
                        <Text variant="sm" display="inline" marginRight={15}>
                          {action.actionTitle}
                        </Text>
                        <IconButton onClick={() => setRemoveAction(actionIndex)}>
                          <DeleteForever color="error" />
                        </IconButton>
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={5} sm={2}>
                  <BrancherTextField
                    id="date"
                    type="date"
                    minDate={format(new Date(), 'yyyy-MM-dd')}
                    value={
                      action?.dueDate
                        ? format(parse(action.dueDate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
                        : ''
                    }
                    updateValue={(newDate) => updateActionDueDate(action.meetingActionId, newDate)}
                    label="Due date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  {!gettingProfiles && (
                    <BrancherSelect
                      fullWidth
                      value={action.assignedRoleId}
                      options={fullParticipantProfiles}
                      updateValue={(owner: string) =>
                        updateActionOwner(action.meetingActionId, owner)
                      }
                      inputLabel="Owner"
                      name="owner"
                    />
                  )}
                </Grid>
              </Grid>
            ))
          ) : (
            <Text variant="sm">You don't have any action items assigned to this meeting yet.</Text>
          )}
          <AddMeetingItem
            addValue={controlAddActionItem}
            setValue={setActionItem}
            canUpdate={true}
            value={actionItem}
            label="Add new action item"
          />
        </Grid>
        <Grid item xs={12}>
          <BrancherTextField
            fullWidth
            value={notes}
            updateValue={setNotes}
            placeholder="Put your meeting notes here..."
            name="notes"
            id="notes"
            label="Notes"
            rows={16}
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <Text variant="sm" fontWeight={500}>
            Meeting description:
          </Text>
          <Text variant="sm">
            {meetingData.description ?? 'There is no description for this meeting'}
          </Text>
        </Grid>

        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justify="flex-end"
          className={styles.fixedActionPane}
        >
          <Grid item>
            <CalendarButton onClick={controlUpdateMeetingMinutes} loading={updatingMinutes}>
              Update Meeting Minutes
            </CalendarButton>
          </Grid>
        </Grid>
      </Grid>
    </BrancherDialog>
  );
};
