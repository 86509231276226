import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { CharlieAvatar } from '../../avatars/Charlie';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const BeGreatMentorStepFourteen = () => {
  const stepFourteenValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentor.StepFourteen,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepFourteenIncorrectVal = ['1', '2'];
    dispatch(
      SaveBeGreatMentorData({
        StepFourteen: {
          selected: val,
          correct:
            val?.length > 0 &&
            !(
              val.includes(StepFourteenIncorrectVal[0]) || val.includes(StepFourteenIncorrectVal[1])
            ),
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Charlie keeps talking about their frustration...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          Charlie keeps talking about experiences in dealing with their manager. Charlie says, "So
          not only did my manager forget the meeting again, but she also forgot to meet with my
          colleague. That made it so much harder to finish the report which really stressed me out.
          She frustrates me so much." They seem more interested in ruminating on the problem than
          moving onto solving it.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          What should you do? You can choose more than one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="It sounds like you haven't found a way to communicate your need for feedback to your manager. It must be frustrating. I wonder what might help to communicate this to your manager?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="That's terrible, I had similar experiences at your level, back at XYZ company...."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="How else has this been impacting you?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="D."
          optionText="We discussed in today's agenda we wanted to focus on problem-solving, it sounds like there is a need to explore solutions that help with communicating your need for feedback to your manager. What do you think?"
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepFourteenValue"
          value={stepFourteenValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(['A', 'B', 'C', 'D'])}
        />
      </Grid>
    </Grid>
  );
};
