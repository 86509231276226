import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { CharlieAvatar } from '../../avatars/Charlie';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const BeGreatMentorStepSixteen = () => {
  const stepSixteenValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentor.StepSixteen,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepSixteenCorrectVal = ['0', '1', '2'];
    dispatch(
      SaveBeGreatMentorData({
        StepSixteen: {
          selected: val,
          correct:
            val?.length === 3 &&
            StepSixteenCorrectVal.includes(val[0]) &&
            StepSixteenCorrectVal.includes(val[1]) &&
            StepSixteenCorrectVal.includes(val[2]),
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Charlie rates their goal progress...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          You decide to ask Charlie about goal progress, "On a scale of 1 - 10 where 10 is goal
          achieved and 1 is no progress, how would you rate your goal progress?"
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Charlie says, "I think I'm a 6 out of 10. I guess I've made some sort of progress there as
          my manager has agreed to meeting more regularly - we just haven't established how often."
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          What should you do? You can choose more than one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="That's excellent. How would you rate your confidence, out of a 1 to 10 scale, in achieving this goal now?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="That's excellent. What will it take to get to a 7?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="That's excellent. In the last session, you rated yourself as a 3 in terms of progress. You now say it's a 6, so you are making good progress in developing your communication skills."
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepSixteenValue"
          value={stepSixteenValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
