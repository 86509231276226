import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import EventResizeDoneArg from '@fullcalendar/interaction';
import { addHours, format, parse, parseISO } from 'date-fns';
import { EventDropArg } from '@fullcalendar/core';
import './calendar.css';
import { Text } from '../../Components/General/Text';
import { ECalendars, EEventStatus, IEvent, INewEvent } from './Scheduler';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { Colors } from '../../consts/colors';
import { CreateCalendarEvent, ICreateCalendarEvent } from './CreateCalendarEvent';
import { ResizeCalendarEvent } from './ResizeCalendarEvent';
import { UpdateCalendarEvent } from './UpdateCalendarEvent';
import { IExtendedGroupData } from '../Groups/Group';

enum EventType {
  CREATE_EVENT = 'CREATE_EVENT',
  RESIZE_EVENT = 'RESIZE_EVENT',
  UPDATE_EVENT = 'UPDATE_EVENT',
}

export enum EUpdateDeleteRecurrenceType {
  SINGLE = 'SINGLE',
  ALL_FUTURE = 'ALL_FUTURE',
}

const eventTypeTitle = {
  [EventType.CREATE_EVENT]: 'Create Event',
  [EventType.RESIZE_EVENT]: 'Modify Event Time',
  [EventType.UPDATE_EVENT]: 'Update Event',
};

interface ICalendarEvents extends Pick<ICreateCalendarEvent, 'createNewEvent' | 'updatingEvent'> {
  events: IEvent[];
  deleteEvent: (meetingData: INewEvent) => void;
  updateEvent: (meetingData: INewEvent) => void;
  group?: IExtendedGroupData;
}

// This is because we are using the eventId as the primary event id, so FullCalendar only shows one of the events,
// as the ID is unique to the event. So we manage a suffix so the FullCalendar renders shared events between
// the current partners
export const specialMeetingIdSuffixForOrganiser = '--1';
export const getActualMeetingIdForOrganiser = (meetingId: string): string => {
  return `${meetingId.split(specialMeetingIdSuffixForOrganiser)[0]}`;
};

export const CalendarEvents: React.FC<ICalendarEvents> = ({
  events,
  createNewEvent,
  updatingEvent,
  deleteEvent,
  updateEvent,
  group,
}) => {
  const adjustedEvents =
    events?.length > 0 &&
    events?.map((event) => ({
      ...event,
      id: event?.isOrganizer ? `${event.id}${specialMeetingIdSuffixForOrganiser}` : event.id,
      backgroundColor:
        event?.allDay && event?.isOrganizer
          ? `rgba(80, 0, 255, 0.3)`
          : event?.isOrganizer
          ? Colors.purple
          : event?.allDay
          ? `rgba(6, 168, 70, 0.3)`
          : Colors.green,
      textColor: Colors.white,
      fullStart: parseISO(event.start),
      fullEnd: parseISO(event.end),
    }));
  const [selectedStartDate, setSelectedStartDate] = React.useState<string>('');
  const [selectedEndDate, setSelectedEndDate] = React.useState<string>('');
  const [openEventForm, setOpenEventForm] = React.useState<boolean>(false);
  const [eventType, setEventType] = React.useState<EventType>(null);
  const [selectedMeetingEvent, setSelectedMeetingEvent] = React.useState<INewEvent>(null);

  React.useEffect(() => {
    if (!updatingEvent) {
      closeEventForm();
    }
  }, [updatingEvent]);

  const handleConfirmResizeEvent = () => {
    updateEvent({
      ...selectedMeetingEvent,
      id: getActualMeetingIdForOrganiser(selectedMeetingEvent.id),
      start: formatISOStyleTimeZone(
        parse(selectedMeetingEvent.start, calenderEventFormat, new Date()),
      ),
      end: formatISOStyleTimeZone(parse(selectedMeetingEvent.end, calenderEventFormat, new Date())),
    });
  };

  const controlSelectedMeetingEvent = (info: EventDropArg) => {
    setSelectedMeetingEvent({
      attendees: info.event.extendedProps.attendees,
      start: format(new Date(info.event.start), calenderEventFormat),
      end: format(new Date(info.event.end), calenderEventFormat),
      recurringEventId: info.event.extendedProps?.recurringEventId,
      recurrence: info.event.extendedProps?.recurrence,
      timeZone: info.event.extendedProps.timeZone,
      id: info.event.id,
      isOrganizer: info.event.extendedProps.isOrganizer,
      title: info.event.title,
      location: info.event.extendedProps.location,
      description: info.event.extendedProps.description,
      calendar: info.event.extendedProps?.calendar,
      canUpdate: info.event.extendedProps?.canUpdate,
    });
  };

  // @ts-ignore
  const handleResizeEvent = (info: EventResizeDoneArg) => {
    const canUpdate = info.event.extendedProps.canUpdate;
    if (canUpdate) {
      setEventType(EventType.RESIZE_EVENT);
      controlSelectedMeetingEvent(info);
      setOpenEventForm(true);
    } else {
      info.revert();
    }
  };

  const handleSelectEvent = (info: EventDropArg) => {
    setEventType(EventType.UPDATE_EVENT);
    controlSelectedMeetingEvent(info);
    setOpenEventForm(true);
  };

  const handleAddEvent = (info: any) => {
    setEventType(EventType.CREATE_EVENT);
    const newStartDate = formatDateTimeZone(info.date);
    const newEndDate = formatDateTimeZone(addHours(new Date(info.date), 1));
    setSelectedStartDate(newStartDate);
    setSelectedEndDate(newEndDate);
    setOpenEventForm(true);
  };

  const closeEventForm = () => {
    setEventType(null);
    setSelectedStartDate('');
    setSelectedEndDate('');
    setOpenEventForm(false);
  };

  const deleteSelectedEvent = (updateDeleteRecurrenceType?: EUpdateDeleteRecurrenceType) => {
    const actualMeetingId = selectedMeetingEvent.isOrganizer
      ? getActualMeetingIdForOrganiser(selectedMeetingEvent.id)
      : selectedMeetingEvent.id;
    const deletedMeetingEvent = {
      ...selectedMeetingEvent,
      updateDeleteRecurrenceType,
      status: EEventStatus.CANCELLED,
      id: actualMeetingId,
      start: parse(selectedMeetingEvent.start, calenderEventFormat, new Date()).toISOString(),
      end: parse(selectedMeetingEvent.end, calenderEventFormat, new Date()).toISOString(),
    };
    if (
      !deletedMeetingEvent.recurringEventId ||
      updateDeleteRecurrenceType !== EUpdateDeleteRecurrenceType.SINGLE ||
      deletedMeetingEvent.calendar === ECalendars.AZURE
    ) {
      deleteEvent(deletedMeetingEvent);
    } else {
      updateEvent(deletedMeetingEvent);
    }
  };

  const createFormTitle = (): string => {
    return eventType === EventType.UPDATE_EVENT && !selectedMeetingEvent?.canUpdate
      ? 'Meeting Details'
      : eventTypeTitle[eventType];
  };

  return (
    <Grid container direction="column" item xs={12}>
      <Text variant="sm" marginBottom={30}>
        Click an event to update/delete/view more information about it, or single click on the
        calendar to select a time for a new meeting.
      </Text>
      <BrancherDialog
        setClose={closeEventForm}
        labelledBy="current-event"
        open={openEventForm}
        title={createFormTitle()}
        fitLargeScreen
      >
        {eventType === EventType.CREATE_EVENT ? (
          <CreateCalendarEvent
            createNewEvent={createNewEvent}
            updatingEvent={updatingEvent}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            group={group}
          />
        ) : eventType === EventType.UPDATE_EVENT ? (
          <UpdateCalendarEvent
            deleteEvent={deleteSelectedEvent}
            updateEvent={updateEvent}
            updatingEvent={updatingEvent}
            meetingData={selectedMeetingEvent}
            group={group}
          />
        ) : (
          eventType === EventType.RESIZE_EVENT && (
            <ResizeCalendarEvent
              updateEvent={handleConfirmResizeEvent}
              updatingEvent={updatingEvent}
              meetingData={selectedMeetingEvent}
              cancelUpdateEvent={closeEventForm}
            />
          )
        )}
      </BrancherDialog>
      <Grid item xs={12}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          eventResize={handleResizeEvent}
          eventDrop={handleResizeEvent}
          weekends={false}
          slotMinTime={'05:00'}
          slotMaxTime={'21:00'}
          editable={true}
          businessHours={{
            daysOfWeek: [1, 2, 3, 4, 5],
            startTime: '07:30',
            endTime: '18:00',
          }}
          // dayMaxEvents={true}
          events={adjustedEvents}
          eventBackgroundColor={Colors.informational}
          eventDisplay="block"
          eventClick={handleSelectEvent}
          dateClick={handleAddEvent}
        />
      </Grid>
    </Grid>
  );
};

export const calenderEventFormat = 'dd/MM/yyyy h:mm a';

export const formatDateTimeZone = (toFormatDate: Date): string => {
  return format(toFormatDate, calenderEventFormat);
};

// This converts the UTC format from fullcalendar to ISO8601 format with added timezone difference
export const convertUTCToISO = (date: Date) => {
  const tzo = -date.getTimezoneOffset();
  const diff = tzo >= 0 ? '+' : '-';
  const pad = (num) => {
    return (num < 10 ? '0' : '') + num;
  };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    diff +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  );
};

export const formatISOStyleTimeZone = (toFormatDate: Date): string => {
  return convertUTCToISO(toFormatDate).toString();
};
