import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../../Components/General/Text';
import { SuggestionCard } from '../../SuggestionCard';

export const BeyondProgramMentorStepFiveStopIncorrect = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom incorrect>
          <Text variant="md" fontWeight={700}>
            Not quite.
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item>
        <Text variant="sm" display="inline">
          Not quite. The correct answer was{' '}
        </Text>
        <Text variant="sm" display="inline" fontWeight={700}>
          E
        </Text>
        <Text variant="sm" display="inline">
          . We do not recommend staying in the relationship out of obligation because this is
          unlikely to end well for either party.
        </Text>
      </Grid>
    </Grid>
  );
};
