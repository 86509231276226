import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import capitalize from '@material-ui/core/utils/capitalize';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherSnackbar } from '../../../Components/General/BrancherSnackbar';
import { PageLayout } from '../../../Components/General/PageLayout';
import { Text } from '../../../Components/General/Text';
import { CreateButton } from '../../../Components/InputFields/BrancherButton';
import { BrancherTextField } from '../../../Components/InputFields/BrancherTextField';
import { FormWithStepFieldLayout } from '../../../Components/InputFields/FormWithStepFieldLayout';
import { ProgramPositions } from '../../../QualifyingForm/ProgramPositionOptions';
import { UtilSaveUserMentoringAgreementData } from '../../../store/actions/SharedNotesActions';
import { IUserInfo } from '../../../store/reducers/UserInfoReducer';
import { IStoreTypes } from '../../../store/storeTypes';

export enum EMentoringAgreementFields {
  MEET_OFTEN = 'meetOften',
  CONFIRM_MEETINGS = 'confirmMeetings',
  BETWEEN_MEETINGS = 'betweenMeetings',
  BOUNDARIES = 'boundaries',
  CHANGING_CIRCUMSTANCES = 'changingCircumstances',
  OWN_MEETING_AGENDA = 'ownMeetingAgenda',
  EXPECTATIONS = 'expectations',
  GIVE_RECEIVE_FEEDBACK = 'giveReceiveFeedback',
  MENTEES_GOALS = 'menteesGoals',
  MENTORS_GOALS = 'mentorsGoals',
  ACCOUNTABLE_FOR_GOALS = 'accountableForGoals',
}

// tslint:disable-next-line:max-line-length
const mentoringAgreementConfig = (
  roleLabels: IUserInfo['roleLabels'],
): { fields: Array<{ name: EMentoringAgreementFields; question: string; subText?: string }> } => ({
  fields: [
    {
      name: EMentoringAgreementFields.MEET_OFTEN,
      question: 'How often and when will you meet?',
    },
    {
      name: EMentoringAgreementFields.CONFIRM_MEETINGS,
      question: 'How will you lock in and confirm meetings?',
      subText: `We recommend the ${roleLabels[ProgramPositions.mentee]} drives this`,
    },
    {
      name: EMentoringAgreementFields.BETWEEN_MEETINGS,
      question: 'How will you stay in touch between meetings?',
    },
    {
      name: EMentoringAgreementFields.BOUNDARIES,
      question: 'How much contact is "too much" - what are your boundaries?',
    },
    {
      name: EMentoringAgreementFields.CHANGING_CIRCUMSTANCES,
      question: 'What happens if circumstances change (e.g. extended travel)?',
    },
    {
      name: EMentoringAgreementFields.OWN_MEETING_AGENDA,
      question: 'Who will own each meeting agenda?',
      subText: `We recommend the ${roleLabels[ProgramPositions.mentee]} drives this`,
    },
    {
      name: EMentoringAgreementFields.EXPECTATIONS,
      question: 'What do you expect from each other?',
    },
    {
      name: EMentoringAgreementFields.GIVE_RECEIVE_FEEDBACK,
      question: 'How and when will you give and receive feedback?',
    },
    {
      name: EMentoringAgreementFields.MENTEES_GOALS,
      question: `What are the ${
        roleLabels[ProgramPositions.mentee]
      }'s goals for the mentoring program?`,
    },
    {
      name: EMentoringAgreementFields.MENTORS_GOALS,
      question: `What are the ${
        roleLabels[ProgramPositions.mentor]
      }'s goals for the mentoring program?`,
    },
    {
      name: EMentoringAgreementFields.ACCOUNTABLE_FOR_GOALS,
      question: 'How will you both hold yourself accountable and smash your goals?',
    },
  ],
});

export const MentoringAgreement = () => {
  const sessionPosition = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const mentoringAgreementData = useSelector(
    (state: IStoreTypes) => state.sharedNotes?.mentoringAgreement,
  );
  const [intermediaryData, setIntermediaryData] = React.useState<{
    [EMentoringAgreementFields.MEET_OFTEN]?: string;
    [EMentoringAgreementFields.CONFIRM_MEETINGS]?: string;
    [EMentoringAgreementFields.BETWEEN_MEETINGS]?: string;
    [EMentoringAgreementFields.BOUNDARIES]?: string;
    [EMentoringAgreementFields.CHANGING_CIRCUMSTANCES]?: string;
    [EMentoringAgreementFields.OWN_MEETING_AGENDA]?: string;
    [EMentoringAgreementFields.EXPECTATIONS]?: string;
    [EMentoringAgreementFields.GIVE_RECEIVE_FEEDBACK]?: string;
    [EMentoringAgreementFields.MENTEES_GOALS]?: string;
    [EMentoringAgreementFields.MENTORS_GOALS]?: string;
    [EMentoringAgreementFields.ACCOUNTABLE_FOR_GOALS]?: string;
    completed?: boolean;
  }>(mentoringAgreementData);
  const [open, controlSnackbar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const partnerPosition: string =
    sessionPosition === ProgramPositions.mentee
      ? roleLabels[ProgramPositions.mentor]
      : roleLabels[ProgramPositions.mentee];

  const updateValue = (fieldName: string, newValue: string) => {
    setIntermediaryData({ ...intermediaryData, [fieldName]: newValue });
  };

  const saveMentoringAgreementData = () => {
    setLoading(true);
    const mentoringData = { ...intermediaryData, completed: validateAllFields() };
    dispatch(
      UtilSaveUserMentoringAgreementData(mentoringData, (resp) => {
        setLoading(false);
        if (resp.success) {
          controlSnackbar(true);
        }
      }),
    );
  };

  const mentoringAgreementComp = mentoringAgreementConfig(roleLabels);

  // All questions should have an answer!
  const validateAllFields = (): boolean => {
    const { completed, ...actualData } = intermediaryData;
    const vals = Object.values(actualData).map((a) => a !== null && a !== '');
    return vals.reduce((a, b) => a && b) && vals.length === mentoringAgreementComp.fields.length;
  };

  return (
    <PageLayout
      pageTitle="Mentoring Agreement"
      hasFeaturePageLayout={true}
      backButtonRoute="dashboard"
      backButtonLabel="Dashboard"
    >
      <BrancherSnackbar open={open} controlClose={controlSnackbar} message="Agreement saved" />
      <Grid container item xs={11} justify="center">
        <Grid item xs={11}>
          <Text variant="xl" fontWeight={600}>
            {capitalize(roleLabels.programStyle)} Agreement
          </Text>
          <Text variant="sm" color="tertiaryGrey" marginTop={20} marginBottom={50}>
            This Mentoring Agreement is shared with your {partnerPosition}. Please complete during
            your first meeting.
          </Text>
        </Grid>
        <Grid item xs={11}>
          {mentoringAgreementComp.fields.map((f, p) => (
            <FormWithStepFieldLayout step={p + 1} question={f.question} subText={f.subText} key={p}>
              <BrancherTextField
                value={intermediaryData?.[f.name]}
                updateValue={(textValue: string) => updateValue(f.name, textValue)}
                maxChars={400}
                placeholder="Enter here"
                multiline
                fullWidth
              />
            </FormWithStepFieldLayout>
          ))}
          <Grid container justify="flex-end" item xs={11}>
            <Box marginTop={3} marginBottom={5}>
              <CreateButton onClick={saveMentoringAgreementData} loading={loading}>
                Save
              </CreateButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </PageLayout>
  );
};
