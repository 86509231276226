import { Colors } from '../consts/colors';

export const MuiDialog = {
  root: {},
  paperWidthSm: {
    maxWidth: '100%',
  },
  paper: {
    border: `1px ${Colors.purple} solid`,
    borderRadius: 10,
  },
};
