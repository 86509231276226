import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../consts/colors';

const useStyles = makeStyles({
  fill: {
    fill: (props: { selected: boolean }) => (!props.selected ? Colors.white : Colors.purple),
  },
});

interface INotesIcon {
  selected: boolean;
}

export const NotesIcon: React.FC<INotesIcon> = ({ selected }) => {
  const styles = useStyles({ selected });
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 30">
      <g id="expanded">
        <g id="路径_17" data-name="路径 17">
          <path
            className={styles.fill}
            d="M39.68,2.76,35.77.12A.77.77,0,0,0,35.22,0a.72.72,0,0,0-.46.3L27.53,11.05v-.59A3.56,3.56,0,0,0,24,6.9h-2.6V6.5A3.57,3.57,0,0,0,17.8,2.93H14.25a3.64,3.64,0,0,0-7.14,0H3.57A3.59,3.59,0,0,0,0,6.5v16A3.58,3.58,0,0,0,3.57,26H6.16v.39A3.57,3.57,0,0,0,9.73,30H24a3.57,3.57,0,0,0,3.57-3.57V21l1.27-.9a.52.52,0,0,0,.18-.18L39.87,3.76A.71.71,0,0,0,39.68,2.76Zm-31.1.17a2.22,2.22,0,0,1,4.2,0,2.12,2.12,0,0,1,.12.71,2.18,2.18,0,0,1-.12.72,2.22,2.22,0,0,1-4.2,0,2.17,2.17,0,0,1-.11-.72A2.11,2.11,0,0,1,8.58,2.93ZM19.94,22.48a2.14,2.14,0,0,1-2.14,2.14H3.57a2.14,2.14,0,0,1-2.14-2.14V6.5A2.14,2.14,0,0,1,3.57,4.36H7.11A3.63,3.63,0,0,0,9.16,7a3.71,3.71,0,0,0,1.52.34,3.77,3.77,0,0,0,1.64-.39,3.64,3.64,0,0,0,1.93-2.54H17.8A2.14,2.14,0,0,1,19.94,6.5ZM27.85,19l-.32.23-3.44,2.44,1.05-4.5,2.39-3.55,8-11.91,2.72,1.84Z"
          />
        </g>
      </g>
    </svg>
  );
};
