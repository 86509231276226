import { FINALISING_FORM_DATA, FINALISING_CLEAR_FORM_DATA } from '../reducers/FinalisingReducer';
import { IAPIResponseObject } from './actionTypes/apiTypes';
import { SaveQualifyFormData } from './QualifyingActions';
import { SaveUserInfo, SetUserRedirect } from './UserInfoActions';
import { BrancherAuthRequest } from './utils/api-utils';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { SaveProfileFormData } from './ProfileFormActions';
import { removeEmptyStringElements } from '../../utils/sanitizeInput';

export const SaveFinalisingFormData = (userData: object) => {
  return {
    type: FINALISING_FORM_DATA,
    payload: userData,
  };
};

export const ClearFinalisingFormData = () => {
  return {
    type: FINALISING_CLEAR_FORM_DATA,
  };
};

// This does a lot of processing to get the data in a happy place for sending to the backend
export const UtilSaveFormData = (
  step: number,
  submit: boolean,
  editApplicationForm: boolean,
  cb: (a: IAPIResponseObject) => void,
) => {
  return (dispatch: any, getState: any) => {
    const username = getState().user.username;
    const programId = getState().user.programId;
    const qualifyingFormData = getState().qualify;
    const generalFormData = getState().general;
    const valuesFormData = getState().values;
    const personalityFormData = getState().personality;
    const finalisingFormData = getState().finalising;
    const position = qualifyingFormData.position ?? ProgramPositions.catchups;
    const roleId: ProgramPositions =
      position === ProgramPositions.mentee
        ? getState().user.menteeRoleId
        : position === ProgramPositions.mentor && getState().user.mentorRoleId;
    const expired = getState().user.tokenExp < new Date().getTime() / 1000;
    if (expired) {
      dispatch(SaveQualifyFormData({ step }));
      dispatch(
        SaveUserInfo({
          loggedIn: false,
          redirected: true,
          IDToken: '',
          refreshToken: '',
          accessToken: '',
        }),
      );
      cb({ success: false, redirectToLogin: true });
    } else {
      dispatch(SetUserRedirect(false));
      const formData = {
        qualifying: qualifyingFormData,
        general: generalFormData,
        values: valuesFormData,
        personality: personalityFormData,
        finalising: { ...finalisingFormData, submitted: submit },
      };
      BrancherAuthRequest(
        {
          method: 'post',
          url: 'v2/form',
          data: {
            roleId,
            programId,
            position,
            step,
            username,
            formData: removeEmptyStringElements(formData),
            editApplicationForm,
          },
        },
        getState(),
      )
        .then((response: any) => {
          if (editApplicationForm) {
            dispatch(SaveUserInfo({ editApplicationForm: !submit }));
          } else {
            const {
              hasNumerousRoles,
              mentees = [],
              mentors = [],
              positions,
              sessionPair,
              actions,
              isMatched,
              requiresAdhocPairing,
              profile,
            } = response.data?.data;
            dispatch(
              SaveUserInfo({
                sessionPosition: position,
                sessionRoleId: roleId,
                sessionPair,
                hasNumerousRoles,
                mentees,
                mentors,
                positions,
                editApplicationForm: !submit,
                actions,
                isMatched,
                requiresAdhocPairing,
              }),
            );
            dispatch(
              SaveProfileFormData({
                ...profile?.profileData,
                completedProfile: profile?.completedProfile ? profile.completedProfile : false,
                activityScore: profile?.activityScore,
              }),
            );
            dispatch(SaveFinalisingFormData({ submitted: submit }));
          }
          cb(response.data);
        })
        .catch((error) => {
          cb(error);
        });
    }
  };
};
