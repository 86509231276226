import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { SaveSurveyOneData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { EStructuredTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const SurveyStepTwoHaveNotMet = () => {
  const surveyOneValue = useSelector(
    (state: IStoreTypes) => state.training?.[EStructuredTrainingModules.SURVEY_ONE],
  );
  const dispatch = useDispatch();

  const updateValue = (hasStoppedYouValue: string) => {
    dispatch(
      SaveSurveyOneData({
        StepTwo: {
          interacted: !!hasStoppedYouValue,
          hasStoppedYou: hasStoppedYouValue,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={600}>
          What has stopped you from meeting your mentoring partner?
        </Text>
      </Grid>
      <Grid item>
        <BrancherTextField
          name="hasStoppedYou"
          placeholder="Enter here"
          value={surveyOneValue?.StepTwo?.hasStoppedYou}
          updateValue={updateValue}
          maxChars={400}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
