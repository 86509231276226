import * as React from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { YouTubeConfig } from 'react-player/youtube';

interface IYoutube extends ReactPlayerProps {
  src: string;
  configs?: YouTubeConfig;
}

export const Youtube = ({ src, controls = true, configs = {}, ...other }: IYoutube) => {
  const config = {
    playerVars: {
      cc_load_policy: 1,
      modestbranding: 1,
      rel: 0,
      ...configs,
    },
  };

  return <ReactPlayer {...other} config={{ youtube: config }} controls={controls} url={src} />;
};
