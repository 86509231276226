import * as React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import capitalize from '@material-ui/core/utils/capitalize';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { PageLayout } from '../../Components/General/PageLayout';
import { Text } from '../../Components/General/Text';
import { EditLinkButton, NextButton } from '../../Components/InputFields/BrancherButton';
import { GetRoute } from '../../Components/Routing';
import { Colors } from '../../consts/colors';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { BrancherDispatch, UtilGetUserRoleProfile } from '../../store/actions';
import { IStoreTypes } from '../../store/storeTypes';
import { ActionsSection } from './ActionsSection';
import { CheckMentoringPartnerRequests } from './CheckMentoringPartnerRequests';
import { GoalsSection } from './GoalsSection';
import { PairSection } from './PairSection';
import { RequestsSection } from './RequestsSection';
import { EUserActions, IUserInfo } from '../../store/reducers/UserInfoReducer';
import { GetMentoringPartnerView } from './GetMentoringPartnerView';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { AddCalendar } from '../Meetings/AddCalendar';
import { MeetingSurvey } from './MeetingSurvey';
import { Subscription } from '../../types/SubscriptionTypes';
import { GroupsSection } from './GroupsSection';
import { OnboardingSteps } from '../Actions/OnboardingSteps';

const useStyles = makeStyles({
  partner: {
    padding: `20px 30px !important`,
    borderRadius: 25,
    background: Colors.backgroundLightPurple,
  },
  marginTop: {
    marginTop: 30,
  },
});

export const Dashboard = () => {
  const dispatch = useDispatch();
  const [hasProfileData, setHasProfileData] = React.useState<boolean>(false);
  const sessionRoleId = useSelector((state: IStoreTypes) => state.user?.sessionRoleId);
  const userActions = useSelector((state: IStoreTypes) => state.user?.actions)?.filter(
    (a) => a.roleId === sessionRoleId,
  );
  const hasAdhocPairing = useSelector((state: IStoreTypes) => state.user?.hasAdhocPairing);
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const userEmail = useSelector((state: IStoreTypes) => state.user?.email);
  const hasGoogleCalendar = useSelector((state: IStoreTypes) => state.user?.hasGoogleCalendar);
  const hasAzureCalendar = useSelector((state: IStoreTypes) => state.user?.hasAzureCalendar);
  const isSSO = useSelector((state: IStoreTypes) => state.user?.isSSO);
  const hasMentoringPartner = !!useSelector(
    (state: IStoreTypes) => state.user?.sessionPair?.roleId,
  );
  const hasActiveGroup =
    useSelector((state: IStoreTypes) => state.user?.groups)?.filter(
      (g) => g.roleId === sessionRoleId,
    )?.length > 0;
  const completedProfile = useSelector(
    (state: IStoreTypes) => state?.profileForm?.profile?.completedProfile,
  );
  const sesPosition = useSelector((state: IStoreTypes) => state.user?.sessionPosition);
  const isMentee = sesPosition === ProgramPositions.mentee;
  const meetingSurvey = userActions?.find((action) => action.type === EUserActions.MEETING_SURVEY);
  const styles = useStyles();

  const onlyInGroup = !hasMentoringPartner && hasActiveGroup;

  const subscriptions = useSelector((state: IStoreTypes) => state.user?.subscription);
  const hasGmailAccount = userEmail.indexOf('gmail.com') !== -1;
  const hasOutlookAccount = userEmail.indexOf('outlook.com') !== -1;
  const allowsOutlookIntegration = isSSO
    ? subscriptions.includes(Subscription.MEETINGS_OUTLOOK)
    : true;
  const hasAssociatedCalendarAccount = hasGmailAccount || hasOutlookAccount;
  const [showMeetingSurvey, setShowMeetingSurvey] = React.useState(!!meetingSurvey);
  const [showCalendarOptions, setShowCalendarOptions] = React.useState(
    allowsOutlookIntegration &&
      (isSSO || hasAssociatedCalendarAccount) &&
      !(hasAzureCalendar || hasGoogleCalendar),
  );

  const getPairProfile = () => {
    BrancherDispatch(
      dispatch,
      UtilGetUserRoleProfile(() => {
        setHasProfileData(true);
      }),
    );
  };

  React.useEffect(() => {
    getPairProfile();
  }, []);

  return (
    <Grid container direction="column" alignItems="center" justify="center">
      {showMeetingSurvey && (
        <BrancherDialog
          setClose={() => setShowMeetingSurvey(false)}
          title={meetingSurvey.title}
          labelledBy="meeting-survey"
          open={showMeetingSurvey}
          fitLargeScreen
        >
          <MeetingSurvey action={meetingSurvey} closeDialog={() => setShowMeetingSurvey(false)} />
        </BrancherDialog>
      )}
      {showCalendarOptions && (
        <BrancherDialog
          setClose={() => setShowCalendarOptions(false)}
          title="Add Calendar"
          labelledBy="calendar-dialog-add"
          open={showCalendarOptions}
          fitLargeScreen
        >
          <AddCalendar redirectPath="dashboard" getEvents={() => setShowCalendarOptions(false)} />
        </BrancherDialog>
      )}
      {hasProfileData ? (
        completedProfile ? (
          hasMentoringPartner || onlyInGroup ? (
            <FullDashboardView
              isMentee={isMentee}
              styles={styles}
              hasAdhocPairing={hasAdhocPairing}
              onlyInGroup={onlyInGroup}
              roleLabels={roleLabels}
            />
          ) : (
            <GetMentoringPartnerView>
              <Grid container item className={styles.marginTop}>
                <CheckMentoringPartnerRequests />
              </Grid>
              <Grid container item className={styles.marginTop}>
                <OnboardingSteps />
              </Grid>
              <Box>
                <Grid item container justify="space-between" alignItems="center">
                  <Text variant="sm" fontWeight={700} color="purple" marginBottom={10}>
                    Your Tasks
                  </Text>
                  <Link to={GetRoute('actions').path}>
                    <NextButton aria-label="view-all-actions" size="small" variant="text">
                      View all
                    </NextButton>
                  </Link>
                </Grid>
                <ActionsSection scrollable />
              </Box>
              <Grid item>
                <BrancherDivider marginBottom={20} marginTop={10} height={1} />
              </Grid>
            </GetMentoringPartnerView>
          )
        ) : (
          <CompleteProfileView />
        )
      ) : (
        <CircularProgress color="secondary" size={64} />
      )}
    </Grid>
  );
};

const CompleteProfileView: React.FC = () => (
  <>
    <Grid item xs={10} md={7}>
      <Text variant="lg" fontWeight={700} color="purple" align="center" marginBottom={40}>
        Please complete your profile first to get access to your dashboard
      </Text>
    </Grid>
    <Grid item>
      <Link to={GetRoute('settings').path}>
        <EditLinkButton>Complete profile now</EditLinkButton>
      </Link>
    </Grid>
  </>
);

interface IFullDashboardView {
  isMentee: boolean;
  hasAdhocPairing: boolean;
  onlyInGroup: boolean;
  roleLabels: IUserInfo['roleLabels'];
  styles: { partner: string };
}

const FullDashboardView: React.FC<IFullDashboardView> = ({
  isMentee,
  hasAdhocPairing,
  roleLabels,
  onlyInGroup,
  styles,
}) => (
  <PageLayout pageTitle="Welcome to your dashboard" titleDivider={false}>
    <Grid container justify="space-between" spacing={5}>
      <Grid item container direction="column" xs={12} sm={6} md={4}>
        <Grid item container justify="space-between" alignItems="center">
          <Text variant="lg" fontWeight={700}>
            Goals
          </Text>
          <Link to={GetRoute('goals').path}>
            <NextButton aria-label="view-all-goals" size="small" variant="text">
              View all
            </NextButton>
          </Link>
        </Grid>
        <Grid item>
          <BrancherDivider marginBottom={20} marginTop={10} height={1} />
        </Grid>
        <GoalsSection onlyInGroup={onlyInGroup} />
      </Grid>

      <Grid item container direction="column" xs={12} sm={6} md={4} className={styles.partner}>
        {!onlyInGroup ? (
          <>
            <Grid item container justify="space-between" alignItems="center">
              <Grid item>
                <Text variant="lg" fontWeight={700}>
                  Your{' '}
                  {!isMentee
                    ? capitalize(roleLabels[ProgramPositions.mentee])
                    : capitalize(roleLabels[ProgramPositions.mentor])}
                  !
                </Text>
              </Grid>
              {/*<Grid item>*/}
              {/*  <Link to={GetRoute('onboardingSteps').path}>*/}
              {/*    <NextButton aria-label="view-all-actions" size="small" variant="text">*/}
              {/*      Next steps*/}
              {/*    </NextButton>*/}
              {/*  </Link>*/}
              {/*</Grid>*/}
            </Grid>
            <PairSection hasAdhocPairing={hasAdhocPairing} />
          </>
        ) : (
          <>
            <Text variant="lg" fontWeight={700} marginBottom={20}>
              Your {capitalize(roleLabels.programStyle)} Groups!
            </Text>
            <GroupsSection />
          </>
        )}
      </Grid>

      <Grid item container direction="column" xs={12} sm={6} md={4}>
        <OnboardingSteps />
        <Grid item container justify="space-between" alignItems="center">
          <Text variant="sm" fontWeight={700} color="purple">
            Your Tasks
          </Text>
          <Link to={GetRoute('actions').path}>
            <NextButton aria-label="view-all-actions" size="small" variant="text">
              View all
            </NextButton>
          </Link>
        </Grid>
        <ActionsSection scrollable />
        <Grid item>
          <BrancherDivider marginTop={10} height={1} />
        </Grid>
        {hasAdhocPairing && (
          <Box mt={2} width="100%">
            <Grid item container justify="space-between" alignItems="center">
              <Text variant="sm" fontWeight={700} color="purple">
                Requests
              </Text>
            </Grid>
            <RequestsSection />
            <Grid item>
              <BrancherDivider marginBottom={10} marginTop={20} height={1} />
            </Grid>
            <Grid item container justify="space-between" alignItems="center">
              <Text variant="sm" fontWeight={700} color="purple">
                Resources
              </Text>
              <Link to={GetRoute('resources').path}>
                <NextButton aria-label="view-all-resources" size="small" variant="text">
                  View all
                </NextButton>
              </Link>
            </Grid>
          </Box>
        )}
      </Grid>
    </Grid>
  </PageLayout>
);
