import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import {
  SaveGivingReceivingFeedbackMenteeData,
  SaveGivingReceivingFeedbackMentorData,
} from '../../../../../store/actions';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';
import { SonalAvatar } from '../../avatars/Sonal';
import { MasterModelCard } from '../../sharedComps/MasterModelCard';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';

export const GivingReceivingFeedbackMenteeStepThree = () => {
  const position = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const moduleName =
    position === ProgramPositions.mentee
      ? 'givingReceivingFeedbackMentee'
      : 'givingReceivingFeedbackMentor';
  const StepThreeValue = useSelector((state: IStoreTypes) => state.training[moduleName]);
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const sessionSaveFunction =
      position === ProgramPositions.mentee
        ? SaveGivingReceivingFeedbackMenteeData
        : SaveGivingReceivingFeedbackMentorData;
    const StepThreeCorrectVals = '2';
    dispatch(
      sessionSaveFunction({
        StepThree: {
          selected: val,
          correct: StepThreeCorrectVals === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Text variant="md">
          Regardless of whether you're a mentee or mentor, learning how to give feedback effectively
          is critical. The following scenario will help you practice.
        </Text>
      </Grid>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <SonalAvatar />
        </Grid>
        <Grid item container xs={8} direction="column">
          <Grid item>
            <Text variant="md" fontWeight={700} display="inline">
              Meet your new
            </Text>
            <Text variant="md" display="inline">
              {' '}
              (and hypothetical)
            </Text>
            <Text variant="md" fontWeight={700} display="inline">
              {' '}
              mentoring partner,{' '}
            </Text>
            <Text variant="md" fontWeight={700} display="inline" color="purple">
              Sonal
            </Text>
            <Text variant="md" fontWeight={700} display="inline">
              .
            </Text>
          </Grid>
          <Grid item>
            <Text variant="md" marginTop={20}>
              Sonal often reads and sends text messages during your catch ups.
            </Text>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Using the MASTER model, how could you give Sonal feedback? Choose one of the options
          below.
        </Text>
      </Grid>
      <Grid item>
        <MasterModelCard />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="Going on your phone is rude. It makes me feel like you don't value our time together."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="Please could you stay off your phone when we catch up."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="I've been enjoying our catch ups but I'd love for us both to keep our phones away so we can get the most of our time together. How do you feel about that?"
        />
      </Grid>
      <Grid item container justify="center">
        <SelectionControl
          question=""
          noQuestion
          name="StepThreeValue"
          value={StepThreeValue.StepThree?.selected}
          updateValue={updateValue}
          thirdWidth
          exclusive
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
