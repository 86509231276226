import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { SuggestionCard } from '../../SuggestionCard';
import { SallyAvatar } from '../../avatars/Sally';
import { BrancherList } from '../../../../../Components/General/BrancherList';

export const BeGreatMenteeStepTenCorrect = () => {
  const stepTenValue = useSelector((state: IStoreTypes) => state.training.beGreatMentee.StepTen);
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SuggestionCard custom success={stepTenValue.correct} incorrect={!stepTenValue.correct}>
          <Text variant="md" fontWeight={700}>
            {stepTenValue.correct ? 'Correct!' : 'Not quite.'}
          </Text>
        </SuggestionCard>
      </Grid>
      <Grid item container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={8} md={4} container justify="center">
          <SallyAvatar />
        </Grid>
        <Grid item xs={12} md={8} container direction="column" spacing={2}>
          {stepTenValue.correct ? (
            <Text variant="sm">
              All of these answers are correct because each of them demonstrates some of the
              behaviours below that make up a great mentee:
            </Text>
          ) : (
            <Text variant="sm">
              All of the answers were correct. Each answer demonstrated different behaviours below
              that make up a great mentee:
            </Text>
          )}
          <BrancherList
            fontWeight={400}
            listItems={[
              'Owning your development (proactively reflecting and tracking your goals)',
              'Driving the mentoring relationship (suggesting meeting topics)',
              'Taking a growth mindset (understanding your confidence and ability can improve)',
              'Being authentic and grateful (thanking your mentor)',
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
