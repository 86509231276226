import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { SaveMoDGoalSettingMenteeData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { MakeTheMostRatingArrow } from '../../MakeTheMostMentee/Steps/MakeTheMostRatingArrow';

export const MoDGoalSettingMenteeStepNine = () => {
  const stepNineValue = useSelector(
    (state: IStoreTypes) => state.training.modGoalSettingMentee?.StepNine,
  );
  const dispatch = useDispatch();

  const updateValue = (attr: string, val: string | string[] | boolean) => {
    const newStepNineVal = { ...stepNineValue, [attr]: val };
    dispatch(
      SaveMoDGoalSettingMenteeData({
        StepNine: {
          ...newStepNineVal,
          interacted: Boolean(
            newStepNineVal.smartGoalLevel != null &&
              newStepNineVal.preferredLevel != null &&
              newStepNineVal.whyLowScore &&
              newStepNineVal.whyHighScore,
          ),
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          To help you understand what success looks like, rate your current knowledge, skill and
          confidence in this area.
        </Text>
      </Grid>
      <Grid item>
        <MakeTheMostRatingArrow />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Thinking about your SMART goal, where are you now in regards to your level of experience,
          knowledge, skill and confidence?
        </Text>
      </Grid>
      <Grid item>
        <SegmentedControlGroup
          fullWidth
          value={stepNineValue?.smartGoalLevel}
          valueLength={10}
          updateValue={(a: string) => updateValue('smartGoalLevel', a)}
        />
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          Where would you prefer to be?
        </Text>
      </Grid>
      <Grid item>
        <SegmentedControlGroup
          fullWidth
          value={stepNineValue?.preferredLevel}
          valueLength={10}
          updateValue={(a: string) => updateValue('preferredLevel', a)}
        />
      </Grid>
      {stepNineValue?.smartGoalLevel != null && stepNineValue?.preferredLevel != null && (
        <>
          <Grid item>
            <Text variant="md" fontWeight={700} display="inline">
              Why did you score yourself a{' '}
            </Text>
            <Text variant="md" fontWeight={700} display="inline" color="purple">
              {stepNineValue?.smartGoalLevel + 1}
            </Text>
            <Text variant="md" fontWeight={700} display="inline">
              , rather than a 1?
            </Text>
            <Text variant="sm" marginTop={10}>
              This helps to give you an understanding of your motivating factors.
            </Text>
          </Grid>
          <Grid item>
            <BrancherTextField
              fullWidth
              name="whyLowScore"
              placeholder="Enter here"
              value={stepNineValue?.whyLowScore}
              updateValue={(a: string) => updateValue('whyLowScore', a)}
              maxChars={400}
              multiline
              rowsMax={6}
            />
          </Grid>
          <Grid item>
            <Text variant="md" fontWeight={700} display="inline">
              Why did you score yourself a{' '}
            </Text>
            <Text variant="md" fontWeight={700} display="inline" color="purple">
              {stepNineValue?.smartGoalLevel + 1}
            </Text>
            <Text variant="md" fontWeight={700} display="inline">
              , rather than a 10?
            </Text>
            <Text variant="sm" marginTop={10}>
              This helps to give you an understanding of the perceived barriers.
            </Text>
          </Grid>
          <Grid item>
            <BrancherTextField
              fullWidth
              name="whyHighScore"
              placeholder="Enter here"
              value={stepNineValue?.whyHighScore}
              updateValue={(a: string) => updateValue('whyHighScore', a)}
              maxChars={400}
              multiline
              rowsMax={6}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
