import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { CharlieAvatar } from '../../avatars/Charlie';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';

export const BeGreatMentorStepSeventeen = () => {
  const stepSeventeenValue = useSelector(
    (state: IStoreTypes) => state.training.beGreatMentor.StepSeventeen,
  );
  const dispatch = useDispatch();

  const updateValue = (val: string[]) => {
    const StepSeventeenCorrectVal = ['0', '1', '2', '3'];
    dispatch(
      SaveBeGreatMentorData({
        StepSeventeen: {
          selected: val,
          correct:
            val?.length === 4 &&
            StepSeventeenCorrectVal.includes(val[0]) &&
            StepSeventeenCorrectVal.includes(val[1]) &&
            StepSeventeenCorrectVal.includes(val[2]) &&
            StepSeventeenCorrectVal.includes(val[3]),
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <CharlieAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Charlie rates their confidence...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          After identifying the action steps to take, Charlie rates their confidence in addressing
          the problem a 3 out of 10.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          What should you say? You can choose more than one option.
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="What sort of strengths and support do you think could help boost that confidence?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="You've done a great job so far and made clear, logical steps that will pull you towards your goal."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="Have there been previous instances where you felt confident with addressing similar problems? What was different there?"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="D."
          optionText="What did you do that brought you to a 3 and not a 1? Can we build on that and make it a 5?"
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepSeventeenValue"
          value={stepSeventeenValue?.selected}
          updateValue={updateValue}
          noQuestion
          options={MakeOptions(['A', 'B', 'C', 'D'])}
        />
      </Grid>
    </Grid>
  );
};
