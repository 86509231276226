import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { DraggableGoalColumn } from '../../Components/General/DraggableGoalColumn';
import {
  CreateButton,
  NegativeButton,
  CancelButton,
} from '../../Components/InputFields/BrancherButton';
import { BrancherSelect } from '../../Components/InputFields/BrancherSelect';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import {
  EGoalsBoardColumns,
  EGoalsBoardColumnsHeadings,
  IGoal,
  IGoalsReducer,
} from '../../store/reducers/GoalsReducer';

const useStyles = makeStyles({
  board: {
    height: '100%',
    marginTop: 40,
  },
});

interface IGoalsBoard {
  setBoardItems: (goals: IGoalsReducer) => void;
  boardItems: IGoalsReducer;
}

interface IDroppableSource {
  index: number;
  droppableId: EGoalsBoardColumns;
}

export const GoalsBoard: React.FC<IGoalsBoard> = ({ setBoardItems, boardItems }) => {
  const styles = useStyles();
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = React.useState<boolean>(false);
  const [newGoalText, setNewGoalText] = React.useState<string>('');
  const [newGoalBoard, setNewGoalBoard] = React.useState<EGoalsBoardColumns>();
  const [updateGoalBoardText, setUpdateGoalBoardText] = React.useState<string>('');
  const [updateGoalId, setUpdateGoalId] = React.useState<string>('');
  const [updateEditGoalBoard, setUpdateEditGoalBoard] = React.useState<EGoalsBoardColumns>();
  const [updateEditMoveGoalBoard, setUpdateEditMoveGoalBoard] = React.useState<
    EGoalsBoardColumns
  >();

  const saveNewGoal = () => {
    const newItem: IGoal = { id: uuidv4(), goal: newGoalText, lastModified: Date.now() };
    const newBoardItems =
      boardItems?.[newGoalBoard]?.length > 0 ? [...boardItems[newGoalBoard], newItem] : [newItem];
    setBoardItems({ ...boardItems, [newGoalBoard]: newBoardItems });
    setOpenDialog(false);
    setNewGoalText('');
  };

  const editGoal = (goalBoard: EGoalsBoardColumns, goalId: string) => {
    setUpdateGoalBoardText(boardItems[goalBoard].find((item) => item.id === goalId).goal);
    setUpdateGoalId(goalId);
    // In case they also move the item to a different column
    setUpdateEditMoveGoalBoard(goalBoard);
    // The origin column
    setUpdateEditGoalBoard(goalBoard);
    setOpenEditDialog(true);
  };

  const removeGoal = () => {
    const updatedBoardItems = boardItems[updateEditGoalBoard].filter(
      (item) => item.id !== updateGoalId,
    );
    setBoardItems({ ...boardItems, [updateEditGoalBoard]: updatedBoardItems });
    setOpenEditDialog(false);
  };

  const saveEditedGoal = () => {
    const updatedBoardItems = boardItems[updateEditGoalBoard].map((item) => {
      if (item.id === updateGoalId) {
        return { ...item, goal: updateGoalBoardText, lastModified: Date.now() };
      } else {
        return item;
      }
    });
    if (
      boardItems[updateEditGoalBoard].find((item) => item.id === updateGoalId).goal !==
      updateGoalBoardText
    ) {
      setBoardItems({ ...boardItems, [updateEditGoalBoard]: updatedBoardItems });
    }
    if (updateEditGoalBoard !== updateEditMoveGoalBoard) {
      setBoardItems({
        ...boardItems,
        [updateEditGoalBoard]: updatedBoardItems.filter((item) => item.id !== updateGoalId),
        [updateEditMoveGoalBoard]: [
          ...boardItems[updateEditMoveGoalBoard],
          { id: updateGoalId, goal: updateGoalBoardText, lastModified: Date.now() },
        ],
      });
    }
    setOpenEditDialog(false);
  };

  const addGoal = (goalBoard: EGoalsBoardColumns) => {
    setNewGoalBoard(goalBoard);
    setOpenDialog(true);
  };

  const controlSettingItems = (items: IGoal[], board: EGoalsBoardColumns) => {
    setBoardItems({ ...boardItems, [board]: items });
  };

  const reorder = (list: IGoal[], startIndex: number, endIndex: number): IGoal[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const move = (
    source: IGoal[],
    destination: IGoal[],
    droppableSource: IDroppableSource,
    droppableDestination: IDroppableSource,
  ): any => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  const getList = (column: EGoalsBoardColumns): IGoal[] => boardItems?.[column] ?? [];

  // The main orchestrator for moving cards around
  const onDragEnd = (result: { source: IDroppableSource; destination: IDroppableSource }) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items = reorder(getList(source.droppableId), source.index, destination.index);
      controlSettingItems(items, source.droppableId);
    } else {
      const joinedResult = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination,
      );
      const destinationModifiedList = joinedResult[destination.droppableId].map(
        (a, movedGoalIndex) => {
          if (movedGoalIndex === destination.index) {
            return { ...a, lastModified: Date.now() };
          } else {
            return a;
          }
        },
      );
      setBoardItems({
        ...boardItems,
        [source.droppableId]: joinedResult[source.droppableId],
        [destination.droppableId]: destinationModifiedList,
      });
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      className={styles.board}
    >
      <BrancherDialog
        setClose={() => setOpenDialog(false)}
        labelledBy="add-goal"
        title="Add goal"
        open={openDialog}
        fitLargeScreen
      >
        <Grid container justify="center">
          <Grid item xs={12}>
            <BrancherTextField
              value={newGoalText}
              updateValue={setNewGoalText}
              fullWidth
              multiline
              aria-multiline={true}
              label="New goal"
              maxChars={400}
            />
          </Grid>
          <Grid container alignItems="center" justify="space-between" item xs={12} spacing={1}>
            <Grid item xs={5} md={4}>
              <NegativeButton onClick={() => setOpenDialog(false)}>Cancel</NegativeButton>
            </Grid>
            <Grid item xs={5} md={4}>
              <CreateButton onClick={saveNewGoal} aria-label="add-goal">
                Add
              </CreateButton>
            </Grid>
          </Grid>
        </Grid>
      </BrancherDialog>
      <BrancherDialog
        setClose={() => setOpenEditDialog(false)}
        labelledBy="edit-goal"
        title="Edit goal"
        open={openEditDialog}
        fitLargeScreen
      >
        <Grid container justify="space-around">
          <Grid item xs={12} md={5}>
            <BrancherTextField
              value={updateGoalBoardText}
              updateValue={setUpdateGoalBoardText}
              fullWidth
              multiline
              aria-multiline={true}
              label="Edit goal"
              maxChars={400}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <BrancherSelect
              options={[
                {
                  value: EGoalsBoardColumns.BACKLOG,
                  label: EGoalsBoardColumnsHeadings.BACKLOG,
                },
                {
                  value: EGoalsBoardColumns.IN_PROGRESS,
                  label: EGoalsBoardColumnsHeadings.IN_PROGRESS,
                },
                {
                  value: EGoalsBoardColumns.COMPLETED,
                  label: EGoalsBoardColumnsHeadings.COMPLETED,
                },
              ]}
              value={updateEditMoveGoalBoard}
              inputLabel="Choose goal board"
              id="goalBoard"
              name="chooseGoalBoard"
              updateValue={(boardUpdate: EGoalsBoardColumns) =>
                setUpdateEditMoveGoalBoard(boardUpdate)
              }
            />
          </Grid>
          <Grid container alignItems="center" justify="space-between" item xs={12} spacing={1}>
            <Grid item xs={2} md={4}>
              <IconButton onClick={removeGoal}>
                <DeleteForever color="error" />
              </IconButton>
            </Grid>
            <Grid item xs={10} sm={7} md={6} container justify="space-between">
              <Grid item>
                <CancelButton onClick={() => setOpenEditDialog(false)} />
              </Grid>
              <Grid item>
                <CreateButton onClick={saveEditedGoal} aria-label="save-goal" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BrancherDialog>
      <Grid container justify="space-between" spacing={5}>
        <DragDropContext onDragEnd={onDragEnd}>
          <DraggableGoalColumn
            columnItems={boardItems?.[EGoalsBoardColumns.BACKLOG]}
            columnId={EGoalsBoardColumns.BACKLOG}
            heading={EGoalsBoardColumnsHeadings.BACKLOG}
            addGoal={addGoal}
            editGoal={editGoal}
          />
          <DraggableGoalColumn
            columnItems={boardItems?.[EGoalsBoardColumns.IN_PROGRESS]}
            columnId={EGoalsBoardColumns.IN_PROGRESS}
            heading={EGoalsBoardColumnsHeadings.IN_PROGRESS}
            addGoal={addGoal}
            editGoal={editGoal}
          />
          <DraggableGoalColumn
            columnItems={boardItems?.[EGoalsBoardColumns.COMPLETED]}
            columnId={EGoalsBoardColumns.COMPLETED}
            heading={EGoalsBoardColumnsHeadings.COMPLETED}
            addGoal={addGoal}
            editGoal={editGoal}
          />
        </DragDropContext>
      </Grid>
    </Grid>
  );
};
