import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { ProgramPositions } from '../../../../../QualifyingForm/ProgramPositionOptions';
import { SaveMoDBeGreatMenteeData, SaveMoDBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { EMoDTrainingModules } from '../../../../../store/reducerTypes/TrainingReducer.types';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const MoDBeGreatMenteeStepEight = () => {
  const sesPos = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const isMentor = sesPos === ProgramPositions.mentor;
  const modName = isMentor
    ? EMoDTrainingModules.MOD_BE_GREAT_MENTOR
    : EMoDTrainingModules.MOD_BE_GREAT_MENTEE;
  const saveFunction = isMentor ? SaveMoDBeGreatMentorData : SaveMoDBeGreatMenteeData;
  const stepEightValue = useSelector((state: IStoreTypes) => state.training?.[modName]?.StepEight);
  const dispatch = useDispatch();

  const updateValue = (val: number) => {
    const StepEightCorrectVal = 4;
    dispatch(
      saveFunction({
        StepEight: {
          selected: val,
          correct: val > StepEightCorrectVal,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center">
        <Text variant="md" fontWeight={700}>
          You have almost finished the training.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">How confident are you to facilitate a great first meeting?</Text>
        <SegmentedControlGroup
          fullWidth
          value={stepEightValue?.selected}
          valueLength={10}
          updateValue={updateValue}
        />
      </Grid>
    </Grid>
  );
};
