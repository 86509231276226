import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import App from './App';
import configureStore from './store/configureStore';
import { PersistGate } from 'redux-persist/es/integration/react';

const { persistor, store } = configureStore();

// Just want to clear out some reducer info when someone leaves the page
// window.addEventListener('beforeunload', (e) => {
//   persistor.purge();
// });

const StoreProvider = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <App />
    </PersistGate>
  </Provider>
);

ReactDOM.render(<StoreProvider />, document.getElementById('root'));

serviceWorker.unregister();
