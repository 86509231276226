import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { SaveBeGreatMentorData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { BrancherDialog } from '../../../../../Components/General/BrancherDialog';
import { BrancherList } from '../../../../../Components/General/BrancherList';

export const BeGreatMentorStepTwelve = () => {
  const dispatch = useDispatch();
  const [openDialog, setDialog] = React.useState(false);

  const controlDialog = () => {
    setDialog(true);
    updateValue();
  };

  const updateValue = () => {
    dispatch(
      SaveBeGreatMentorData({
        StepTwelve: {
          interacted: true,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <BrancherDialog
        setClose={() => setDialog(false)}
        open={openDialog}
        labelledBy="Staying confident points"
      >
        <BrancherList
          listItems={[
            "Don't feel like you need to have all the answers; it is about asking the right questions. One of the most important things you can do is to listen. Let them talk.",
            'When using coaching models, remember that they are meant to be flexible and dependent on the flow of conversation. You do not have to follow the steps in a fixed linear order.',
            'Focus on the process when coaching your mentee - you do not have to complete all the steps in one sitting. For instance, people often do not know what they want to achieve, so you can focus on exploring that over multiple sessions. It is more important to get a good grip of the goal before moving on to the next steps.',
            'It might be challenging to refrain from giving your perspective. Remember that the most important insights are those shared by the mentee.',
            'Stay positive. A lot of the times mentees lack confidence and need to be reminded of their past successes and strengths to believe they can achieve their goals.',
            'Practice makes perfect. Have a go at using a coaching model. Reflect on what worked well and not so well, and what you will do differently next time. Commit to using a coaching model again.',
          ]}
        />
        <Text variant="md" fontWeight={700} marginTop={40}>
          You've got this!
        </Text>
      </BrancherDialog>
      <Grid item>
        <Text variant="md">
          The GROWTH coaching model requires you to ask questions. Using the GROWTH model requires
          you to put on your 'coaching' hat, put your expertise and problem solving aside, and ask
          curious, empathetic and collaborative questions without solving the problem yourself.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          This coaching role can be challenging. Most mentors (and managers) find 'being directive'
          more natural.
        </Text>
      </Grid>
      <Grid item>
        <BrancherLinkText
          variant="md"
          target="_blank"
          display="inline"
          onClick={controlDialog}
          underline="always"
          fontWeight={700}
        >
          Here
        </BrancherLinkText>
        <Text variant="md" display="inline">
          {' '}
          are a few tips for persisting with the coaching approach, even when it feels awkward. We
          will also go through some common scenarios you may face and provide some techniques to
          overcome them and facilitate productive conversations.
        </Text>
      </Grid>
    </Grid>
  );
};
