import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import EventNote from '@material-ui/icons/EventNote';
import cx from 'classnames';
import {
  addHours,
  addMinutes,
  format,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  isAfter,
  isPast,
} from 'date-fns';
import { CalendarOptions, GoogleCalendar, ICalendar, OutlookCalendar } from 'datebook';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import { RouteLeavingGuard } from '../../Components/Routing/RouteLeavingGuard';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { DatePicker } from '../../Components/General/DatePicker';
import { Text } from '../../Components/General/Text';
import { TimePicker } from '../../Components/General/TimePicker';
import {
  AcceptButton,
  ActionButton,
  CalendarButton,
  DeleteButton,
  RejectButton,
} from '../../Components/InputFields/BrancherButton';
import { BrancherInputLabel } from '../../Components/InputFields/BrancherInputLabel';
import { BrancherSelect } from '../../Components/InputFields/BrancherSelect';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { GetRoute } from '../../Components/Routing';
import { Colors } from '../../consts/colors';
import {
  BrancherDispatch,
  UtilDeleteMeeting,
  UtilSaveUserMeetingData,
  UtilUpdateMeetingStatusResponse,
} from '../../store/actions/';
import { IMeetingParticipantStatus } from '../../store/reducers/MeetingsReducer';
import { EProfileAttributes } from '../../store/reducers/ProfileFormReducer';
import { IStoreTypes } from '../../store/storeTypes';
import { MeetingParticipantCard } from './MeetingParticipantCard';
import { BrancherCheckbox } from '../../Components/InputFields/BrancherCheckbox';
import { ECalendars } from './Scheduler';
import { MeetingMinutes } from './MeetingMinutes';

interface IMeetingStyles {
  mobile: boolean;
}

const useStyles = makeStyles({
  layout: {
    minHeight: '100%',
    marginBottom: 30,
  },
  bottomSection: {
    marginTop: 40,
  },
  section: {
    borderRadius: 15,
    padding: (props: IMeetingStyles) => (props.mobile ? 20 : 40),
    background: Colors.backgroundGrey,
  },
  pastMeetingCheckbox: {
    marginTop: -20,
    marginBottom: 10,
  },
});

enum ECalendarOptions {
  GMAIL = 'gmail',
  OFFICE = 'office',
  OUTLOOK = 'outlook',
  iCAL = 'iCal',
}

interface ICalendarOptionType {
  label: string;
  value: ECalendarOptions;
}

const calendarOptions: ICalendarOptionType[] = [
  {
    label: 'Gmail',
    value: ECalendarOptions.GMAIL,
  },
  {
    label: 'Outlook/Office365 (work)',
    value: ECalendarOptions.OFFICE,
  },
  {
    label: 'Outlook (personal)',
    value: ECalendarOptions.OUTLOOK,
  },
  {
    label: 'iCal',
    value: ECalendarOptions.iCAL,
  },
];

export interface IBaseMeeting {
  hasFeaturePageLayout?: boolean;
  isGroup?: boolean;
}

export const BaseMeeting: React.FC<IBaseMeeting> = ({
  hasFeaturePageLayout = true,
  isGroup = false,
}) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const meetingId = new URLSearchParams(useLocation().search).get('m');
  const meeting = useSelector((state: IStoreTypes) => state.meetings?.meetings)?.find(
    (m) => m.meetingId === meetingId,
  );
  const meetingIsGroupMeeting = !!meeting?.groupId;
  const userEmail = useSelector((state: IStoreTypes) => state.user.email);
  const userFirstName = useSelector((state: IStoreTypes) => state.user.firstName);
  const userLastName = useSelector((state: IStoreTypes) => state.user.lastName);
  const partnerEmail = useSelector(
    (state: IStoreTypes) => state.profileForm.pairProfile?.[EProfileAttributes.CONTACT_EMAIL],
  );
  const sessionPair = useSelector((state: IStoreTypes) => state.user.sessionPair);
  const userRoleId = useSelector((state: IStoreTypes) => state.user.sessionRoleId);
  const [startDate, setStartDate] = React.useState<Date>(meeting?.datetimeStart ?? new Date());
  const [endDatetime, setEndDatetime] = React.useState<Date>(
    meeting?.datetimeEnd ?? addMinutes(new Date(), 60),
  );
  const [location, setLocation] = React.useState<string>(meeting?.location ?? '');
  const [description, setDescription] = React.useState<string>(meeting?.description ?? '');
  const [title, setTitle] = React.useState<string>(
    isGroup
      ? 'Group meeting'
      : meeting?.title ??
          `Mentoring meeting with ${sessionPair?.name} and ${userFirstName} ${userLastName}`,
  );
  const [savingMeeting, setSavingMeeting] = React.useState<boolean>(false);
  const [deletingMeeting, setDeletingMeeting] = React.useState<boolean>(false);
  const [redirect, setRedirect] = React.useState<boolean>(false);
  const [openMeetingMinutes, setOpenMeetingMinutes] = React.useState<boolean>(false);
  const [updatingMeetingResponse, setUpdatingMeetingResponse] = React.useState<boolean>(false);
  const [meetingInPast, setMeetingInPast] = React.useState<boolean>(false);
  const [calendarOption, setCalendarOption] = React.useState<ECalendarOptions>();
  const [error, setError] = React.useState<string>('');
  const allResponded: boolean = meeting?.participants
    .map((p) => p.status === IMeetingParticipantStatus.ACCEPTED)
    .reduce((a, b) => a && b);
  const [savedMeeting, setSavedMeeting] = React.useState<boolean>(Boolean(meetingId));
  const styles = useStyles({ mobile });
  const dispatch = useDispatch();
  const isBrancherOwnedMeeting = !(
    meeting?.calendar === ECalendars.AZURE || meeting?.calendar === ECalendars.GOOGLE
  );

  const deleteMeeting = () => {
    setDeletingMeeting(true);
    BrancherDispatch(
      dispatch,
      UtilDeleteMeeting(meetingId, () => {
        setRedirect(true);
      }),
    );
  };

  const validateAllAttributes = (): boolean => {
    return Boolean(!!startDate && !!endDatetime && !!title && (!!calendarOption || meetingInPast));
  };

  const respondToMeeting = (status: IMeetingParticipantStatus) => {
    setUpdatingMeetingResponse(true);
    BrancherDispatch(
      dispatch,
      UtilUpdateMeetingStatusResponse(meeting, status, () => {
        setUpdatingMeetingResponse(false);
        setRedirect(true);
      }),
    );
  };

  const setDate = (date: Date) => {
    if (!endDatetime) {
      setEndDatetime(new Date(getYear(date), getMonth(date), getDate(date), getHours(date)));
    } else {
      setEndDatetime(new Date(getYear(date), getMonth(date), getDate(date), getHours(endDatetime)));
    }
    setStartDate(date);
  };

  const saveMeeting = () => {
    if (isAfter(startDate, endDatetime)) {
      setError('Please enter a start time before the end time.');
    } else if (!validateAllAttributes()) {
      setError('Please enter all fields.');
    } else {
      setError('');
      setSavingMeeting(true);
      const dateStart = format(
        new Date(
          getYear(startDate),
          getMonth(startDate),
          getDate(startDate),
          getHours(startDate),
          getMinutes(startDate),
        ),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
      );
      const dateEnd = format(
        new Date(
          getYear(startDate),
          getMonth(startDate),
          getDate(startDate),
          getHours(endDatetime),
          getMinutes(startDate),
        ),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
      );
      const newMeeting = {
        datetimeStart: dateStart,
        datetimeEnd: dateEnd,
        location,
        description,
        title,
      };
      BrancherDispatch(
        dispatch,
        UtilSaveUserMeetingData(newMeeting, meetingInPast ?? false, (resp) => {
          setSavingMeeting(false);
          if (resp.success) {
            setSavedMeeting(true);
            if (!meetingInPast) {
              if (
                calendarOption === ECalendarOptions.GMAIL ||
                calendarOption === ECalendarOptions.OUTLOOK ||
                calendarOption === ECalendarOptions.OFFICE
              ) {
                const url: string =
                  calendarOption === ECalendarOptions.GMAIL
                    ? sendToGoogle()
                    : sendToOutlook(calendarOption);
                redirectToMeetings();
                window.open(url, '_blank');
              } else {
                // iCal only has an .ics file to download, so doesn't have a url to route to
                redirectToMeetings();
                sendToICal();
              }
            } else {
              redirectToMeetings();
            }
          }
        }),
      );
    }
  };

  const createCalendarOptions = (): CalendarOptions => {
    return {
      title,
      location,
      description,
      start: startDate,
      end: endDatetime,
      attendees: [
        {
          email: userEmail,
          name: `${userFirstName} ${userLastName}`,
        },
        {
          email: partnerEmail,
          name: sessionPair?.name,
        },
      ],
    };
  };

  const redirectToMeetings = () => {
    setTimeout(() => setRedirect(true), 2000);
  };

  const sendToGoogle = () => {
    const googleCalendar = new GoogleCalendar(createCalendarOptions());
    googleCalendar
      .setParam('crm', 'AVAILABLE')
      .setParam('trp', 'true')
      .setParam('src', userEmail);
    return googleCalendar.render();
  };

  const sendToICal = () => {
    const iCalendar = new ICalendar(createCalendarOptions());
    return iCalendar.download();
  };

  const sendToOutlook = (calendarOption: ECalendarOptions) => {
    const outlookCalendar = new OutlookCalendar(createCalendarOptions());
    const officeHost = calendarOption === ECalendarOptions.OFFICE ? 'office' : 'live';
    outlookCalendar.setHost(officeHost);
    outlookCalendar.setParam('online', 'true');
    return outlookCalendar.render();
  };

  const past = isPast(new Date(endDatetime));

  const isMeetingOrganiser: boolean = userRoleId === meeting?.organiser;
  const disableField = (past || !isMeetingOrganiser) && Boolean(meeting?.meetingId);

  return (
    <>
      <RouteLeavingGuard when={!savedMeeting} />
      {meetingIsGroupMeeting && <Redirect to={`${GetRoute('group').path}?g=${meeting.groupId}`} />}
      {redirect && <Redirect to={GetRoute('meetings').path} />}
      {openMeetingMinutes && (
        <MeetingMinutes
          meetingData={meeting}
          open={openMeetingMinutes}
          setClose={() => setOpenMeetingMinutes(false)}
        />
      )}
      <Grid item xs={11} lg={!hasFeaturePageLayout ? 12 : 9}>
        <BrancherTextField
          value={title}
          id="new-meeting"
          name="new-meeting"
          updateValue={setTitle}
          placeholder="Meeting title"
          fullWidth
          multiline
          disabled={disableField}
          maxChars={(isMeetingOrganiser || !meeting?.meetingId) && 400}
        />
      </Grid>
      <Grid item xs={11} lg={!hasFeaturePageLayout ? 12 : 9} container justify="space-between">
        <Grid item container xs={6} className={styles.section}>
          {!meetingId && (
            <Grid item className={styles.pastMeetingCheckbox}>
              <BrancherCheckbox
                name="meetingInPast"
                value={meetingInPast}
                updateValue={setMeetingInPast}
                label="Set past meeting"
              />
            </Grid>
          )}
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={3}>
              <BrancherInputLabel variant="sm" fontWeight={700} color="purple" for="date">
                Date
              </BrancherInputLabel>
            </Grid>
            <Grid item xs={8}>
              <DatePicker
                value={startDate}
                updateValue={setDate}
                disabled={disableField}
                minDate={!meetingInPast && !isPast(new Date(meeting?.datetimeStart)) && new Date()}
                id="date"
                name="meeting-date"
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={3}>
              <BrancherInputLabel variant="sm" fontWeight={700} color="purple" for="start-time">
                From
              </BrancherInputLabel>
            </Grid>
            <Grid item xs={8}>
              <TimePicker
                value={startDate}
                updateValue={setStartDate}
                disabled={disableField}
                id="start-time"
                name="start-time-picker"
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={3}>
              <BrancherInputLabel variant="sm" fontWeight={700} color="purple" for="end-time">
                To
              </BrancherInputLabel>
            </Grid>
            <Grid item xs={8}>
              <TimePicker
                disabled={disableField}
                value={endDatetime}
                updateValue={setEndDatetime}
                placeholder={format(addHours(new Date(), 1), 'h:mm a')}
                id="end-time"
                name="end-time-picker"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={5} className={styles.section} direction="column">
          <Grid container alignItems="center" justify="space-between">
            <BrancherInputLabel variant="sm" fontWeight={700} color="purple" for="location">
              Location
            </BrancherInputLabel>
            <BrancherTextField
              value={location}
              updateValue={setLocation}
              fullWidth
              multiline
              maxChars={(isMeetingOrganiser || !meeting?.meetingId) && 100}
              disabled={disableField}
              id="location"
              name="meeting-location"
            />
          </Grid>
          {meeting?.meetingId && (
            <Grid item>
              <Box mt={4}>
                <ActionButton
                  color="primary"
                  size="small"
                  endIcon={<EventNote color="action" />}
                  onClick={() => setOpenMeetingMinutes(true)}
                >
                  Meeting minutes
                </ActionButton>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item container justify="space-between" xs={11} lg={!hasFeaturePageLayout ? 12 : 9}>
        <Grid item xs={12} md={6}>
          <BrancherInputLabel
            variant="sm"
            fontWeight={700}
            color="purple"
            marginTop={30}
            for="description"
          >
            Description
          </BrancherInputLabel>
          <BrancherTextField
            value={description}
            updateValue={setDescription}
            placeholder="Meeting description"
            fullWidth
            rows={15}
            multiline
            disabled={disableField}
            maxChars={(isMeetingOrganiser || !meeting?.meetingId) && 500}
            id="description"
            name="meeting-description"
          />
        </Grid>
        {meeting?.meetingId && (
          <Grid
            item
            container
            xs={11}
            sm={7}
            md={5}
            className={cx(styles.bottomSection, styles.section)}
            direction="column"
          >
            <BrancherInputLabel
              variant="sm"
              fontWeight={700}
              color="purple"
              for="location"
              marginBottom={20}
            >
              Participants
            </BrancherInputLabel>
            <Grid container spacing={3}>
              {meeting.participants.map((p) => (
                <Grid item xs={12} key={p.roleId}>
                  <MeetingParticipantCard {...p} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
      {!!meeting?.meetingId && !isMeetingOrganiser && !allResponded && isBrancherOwnedMeeting && (
        <Grid
          item
          container
          xs={11}
          lg={!hasFeaturePageLayout ? 12 : 9}
          justify={mobile ? 'flex-start' : 'flex-end'}
          className={styles.bottomSection}
        >
          <Grid item container xs={11} sm={7} md={5} justify="space-between">
            <Grid item>
              <RejectButton
                onClick={() => respondToMeeting(IMeetingParticipantStatus.DECLINED)}
                loading={updatingMeetingResponse}
              />
            </Grid>
            <Grid item>
              <AcceptButton
                onClick={() => respondToMeeting(IMeetingParticipantStatus.ACCEPTED)}
                loading={updatingMeetingResponse}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {!meetingId && !meetingInPast && (
        <Grid item container xs={11} sm={5} className={styles.section} justify="center">
          <Text variant="xs" fontWeight={500} wordWrap>
            This will be shared with {isGroup ? 'your group' : sessionPair?.name}.
          </Text>
        </Grid>
      )}
      <Grid item xs={11} lg={9}>
        <BrancherDivider marginBottom={20} marginTop={80} />
      </Grid>
      {error && (
        <Grid item container justify="flex-end" xs={11} lg={!hasFeaturePageLayout ? 12 : 9}>
          <Grid item>
            <Text variant="sm" color="red" fontWeight={600} marginBottom={10}>
              {error}
            </Text>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        xs={11}
        lg={!hasFeaturePageLayout ? 12 : 9}
        container
        justify="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={3}>
          {isMeetingOrganiser && (
            <DeleteButton
              onClick={deleteMeeting}
              disabled={deletingMeeting}
              loading={deletingMeeting}
            />
          )}
        </Grid>
        {!meetingId && (!past || meetingInPast) && (
          <Grid
            container
            item
            xs={isMeetingOrganiser ? 8 : 11}
            md={6}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={5}>
              {!meetingInPast && !past && (
                <BrancherSelect
                  fullWidth
                  options={calendarOptions.map((c) => ({ ...c }))}
                  name="calendarOption"
                  value={calendarOption}
                  updateValue={(calendar: ECalendarOptions) => setCalendarOption(calendar)}
                  inputLabel="Calendar"
                />
              )}
            </Grid>
            <Grid item xs={5}>
              <CalendarButton onClick={saveMeeting} disabled={savingMeeting}>
                {savingMeeting ? (
                  <CircularProgress color="primary" size={48} />
                ) : meetingInPast ? (
                  'Save past meeting'
                ) : (
                  'Send to calendar'
                )}
              </CalendarButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
