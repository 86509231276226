import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Text } from '../../../../../Components/General/Text';
import { CheckAnswerSection } from '../../../../../Components/General/CheckAnswerSection';
import { IStoreTypes } from '../../../../../store/storeTypes';

export const EasyEnglishMenteeStepOneCorrect = () => {
  const stepOneValue = useSelector(
    (state: IStoreTypes) => state.training.easyEnglishMentee?.StepOne,
  );
  return (
    <Grid container direction="column" spacing={4}>
      <CheckAnswerSection correct={stepOneValue.correct}>
        <Text variant="sm" fontWeight={700} marginBottom={20}>
          {stepOneValue.correct ? 'Correct' : 'Not quite'}.
        </Text>
        <Text variant="sm">You meet 1 hour per month</Text>
      </CheckAnswerSection>
    </Grid>
  );
};
