import * as React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { Text } from './Text';
import { TextVariantValues } from '../../types/TextTypes';

type List = string | React.ReactElement;

interface IBrancherList {
  listItems: List[];
  color?: 'black' | 'purple';
  fontWeight?: number;
  variant?: TextVariantValues;
  listStyleType?: 'circle' | 'lower-alpha';
  wordWrap?: boolean;
}

const useStyles = makeStyles({
  listItem: {
    marginLeft: -20,
    listStyleType: (props: { listStyleType?: IBrancherList['listStyleType'] }) =>
      props?.listStyleType,
  },
});

export const BrancherList = (props: IBrancherList) => {
  const styles = useStyles(props);
  const { listItems, wordWrap, fontWeight = 600, variant = 'sm', color = 'black' } = props;
  return (
    <ul>
      {listItems.map((l, i) => (
        <li className={styles.listItem} key={i}>
          {typeof l === 'string' ? (
            <Text
              variant={variant}
              fontWeight={fontWeight}
              marginBottom={10}
              color={color}
              wordWrap={wordWrap}
            >
              {l}
            </Text>
          ) : (
            l
          )}
        </li>
      ))}
    </ul>
  );
};
