import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherLinkText } from '../../../../../Components/General/BrancherLinkText';
import { BrancherQuestionMarker } from '../../../../../Components/General/BrancherQuestionMarker';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { Text } from '../../../../../Components/General/Text';
import { SaveMoDGoalSettingMentorData } from '../../../../../store/actions';
import { SegmentedControlGroup } from '../../../../../Form/FormFieldTypes/SegmentedControl';
import { BrancherList } from '../../../../../Components/General/BrancherList';
import { CompetencePopover } from '../../sharedComps/CompetencePopover';
import { SuggestionCard } from '../../SuggestionCard';
import { CompetenceScale } from '../../sharedComps/CompetenceScale';
import { ConfidenceScale } from '../../sharedComps/ConfidenceScale';
import { NPSScale } from '../../sharedComps/NPSScale';
import { BrancherTextField } from '../../../../../Components/InputFields/BrancherTextField';

export const MoDGoalSettingMentorStepEleven = () => {
  const StepElevenValue = useSelector((state: IStoreTypes) => state.training.modGoalSettingMentor);
  const dispatch = useDispatch();
  const [anchorEl, setAnchor] = React.useState(null);
  const open = Boolean(anchorEl);

  const controlValidator = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!Boolean(anchorEl)) {
      setAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(anchorEl)) {
      setAnchor(null);
    }
  };

  const updateCompetence = (val: number) => {
    dispatch(
      SaveMoDGoalSettingMentorData({
        StepEleven: {
          ...StepElevenValue?.StepEleven,
          competenceFinal: val,
          interacted:
            StepElevenValue?.rating != null &&
            StepElevenValue?.confidenceFinal != null &&
            val != null,
        },
        competenceFinal: val,
      }),
    );
  };

  const updateConfidence = (val: number) => {
    dispatch(
      SaveMoDGoalSettingMentorData({
        StepEleven: {
          ...StepElevenValue?.StepEleven,
          confidenceFinal: val,
          interacted:
            StepElevenValue?.rating != null &&
            StepElevenValue?.competenceFinal != null &&
            val != null,
        },
        confidenceFinal: val,
      }),
    );
  };

  const updateRating = (val: number) => {
    dispatch(
      SaveMoDGoalSettingMentorData({
        StepEleven: {
          ...StepElevenValue?.StepEleven,
          interacted:
            StepElevenValue?.competenceFinal != null &&
            StepElevenValue?.confidenceFinal != null &&
            val != null,
        },
        rating: val,
      }),
    );
  };

  const updateFeedback = (val: string) => {
    dispatch(
      SaveMoDGoalSettingMentorData({
        StepEleven: {
          ...StepElevenValue?.StepEleven,
          interacted:
            StepElevenValue?.rating != null &&
            StepElevenValue?.competenceFinal != null &&
            StepElevenValue?.confidenceFinal != null,
        },
        feedback: val,
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={600}>
          Well done on completing this training!
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="sm" fontWeight={600} display="inline">
          Now that you have completed this training, please rate your current level of competence
          and confidence to support your mentee to set and achieve SMART goals.{' '}
        </Text>
        <BrancherQuestionMarker onClick={controlValidator} />
      </Grid>
      <CompetencePopover
        open={open}
        setClose={closeValidator}
        anchor={anchorEl}
        labelledBy="Competence and confidence"
      />
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your competence rating now?
        </Text>
        <CompetenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepElevenValue?.StepEleven?.competenceFinal}
          valueLength={7}
          updateValue={updateCompetence}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Using the scale below, what is your confidence rating now?
        </Text>
        <ConfidenceScale />
        <SegmentedControlGroup
          fullWidth
          value={StepElevenValue?.StepEleven?.confidenceFinal}
          valueLength={7}
          updateValue={updateConfidence}
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          On a scale of 0 - 10, how likely are you to recommend this training to another colleague
          within this mentoring program?
        </Text>
        <NPSScale />
        <SegmentedControlGroup
          fullWidth
          value={StepElevenValue?.rating}
          valueLength={11}
          updateValue={updateRating}
          startFromZero
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700} marginBottom={10}>
          Do you have any other feedback for us?
        </Text>
        <BrancherTextField
          value={StepElevenValue?.feedback}
          updateValue={(a: string) => updateFeedback(a)}
          placeholder="Enter here"
          maxChars={400}
          multiline
          rowsMax={6}
          id="feedback"
          name="feedback"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={700}>
          Next steps:
        </Text>
      </Grid>
      <Box marginLeft={2}>
        <BrancherList
          variant="sm"
          listItems={[
            'Schedule another meeting with your mentee and help them to create and achieve a SMART goal',
            <>
              <Text variant="md" display="inline">
                Set your own goal for this mentoring relationship. Write it down in the Brancher
                platform (
              </Text>
              <BrancherLinkText
                variant="md"
                underline="always"
                target="_blank"
                display="inline"
                href="http://www.goalband.co.uk/uploads/1/0/6/5/10653372/gail_matthews_research_summary.pdf"
              >
                research
              </BrancherLinkText>
              <Text variant="md" display="inline">
                {' '}
                shows you'll be 42% more likely to achieve it!)
              </Text>
            </>,
          ]}
        />
      </Box>
      <Grid item xs={12}>
        <SuggestionCard>
          Make sure you click the "✓" button to complete this training.
        </SuggestionCard>
      </Grid>
    </Grid>
  );
};
