import * as React from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import ContactSupport from '@material-ui/icons/ContactSupport';
import Assistant from '@material-ui/icons/Assistant';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { BrancherSnackbar } from '../../Components/General/BrancherSnackbar';
import { SupportRequestForm } from './SupportRequestForm';

enum ESupportDialOptions {
  SUPPORT_REQUEST = 'SUPPORT_REQUEST',
}

export const SupportDial: React.FC = () => {
  const [openDial, setOpenDial] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dialOption, setDialOption] = React.useState<ESupportDialOptions>(null);
  const [openSnack, setOpenSnack] = React.useState<boolean>(false);
  const [snackMessage, setSnackMessage] = React.useState<string>('');

  const actions: Array<{
    name: string;
    icon: React.ReactNode;
    onClick: () => void;
    type: ESupportDialOptions;
  }> = [
    {
      name: 'Support Request',
      icon: <ContactSupport />,
      onClick: () => setOpenDialog(true),
      type: ESupportDialOptions.SUPPORT_REQUEST,
    },
  ];

  const handleCloseDial = () => {
    setOpenDial(false);
  };

  const handleOpenDial = () => {
    setOpenDial(true);
  };

  const controlDialogClose = (showSnack: boolean) => {
    setOpenDialog(false);
    handleCloseDial();
    if (showSnack) {
      if (dialOption === ESupportDialOptions.SUPPORT_REQUEST) {
        setSnackMessage('Support request sent!');
      }
      setOpenSnack(true);
    }
    setDialOption(null);
  };

  return (
    <>
      <BrancherSnackbar
        open={openSnack}
        controlClose={() => setOpenSnack(false)}
        message={snackMessage}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
      <BrancherDialog
        setClose={() => controlDialogClose(false)}
        labelledBy="support-dial-request"
        open={openDialog}
        fitLargeScreen
        title="Make a support request"
      >
        {dialOption && <SupportRequestForm handleClose={() => controlDialogClose(true)} />}
      </BrancherDialog>
      <SpeedDial
        ariaLabel="support-dial"
        icon={<Assistant />}
        direction="up"
        open={openDial}
        onClose={handleCloseDial}
        onClick={() => {
          setDialOption(actions[0].type);
          actions[0].onClick();
          handleCloseDial();
        }}
        onOpen={handleOpenDial}
        FabProps={{ color: 'default' }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            tooltipTitle={action.name}
            icon={action.icon}
            onClick={() => {
              setDialOption(action.type);
              action.onClick();
              handleCloseDial();
            }}
          />
        ))}
      </SpeedDial>
    </>
  );
};
