import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Done from '@material-ui/icons/Done';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { IStoreTypes } from '../../../store/storeTypes';
import { Text } from '../../../Components/General/Text';
import {
  BrancherButton,
  NextStepButton,
  PrevStepButton,
  SaveModuleButton,
} from '../../../Components/InputFields/BrancherButton';
import { BrancherDispatch, SetUserCelebrate, UtilSaveModuleData } from '../../../store/actions';
import { IModuleMapper } from './ModuleMapper';
import { GetRoute } from '../../../Components/Routing';
import { TrainingNav } from '../TrainingNav/TrainingNav';
import {
  desktopTrainingNavHeight,
  mobileMainNavHeight,
  mobileTrainingNavHeight,
  navSlideTrainingHeight,
} from '../../../consts/navHeights';
import { BrancherSnackbar } from '../../../Components/General/BrancherSnackbar';
import { BrancherDialog } from '../../../Components/General/BrancherDialog';
import { StepMapper } from './helpers/StepMapper';

const useStyles = makeStyles({
  width: {
    maxWidth: (props: { betweenMobileDesktop: boolean }) =>
      !props.betweenMobileDesktop ? 750 : 660,
    minWidth: '100%',
    marginBottom: (props: { mobile: boolean; openTrainingNav: boolean }) =>
      props.mobile && props.openTrainingNav
        ? `calc(${mobileMainNavHeight} + ${mobileTrainingNavHeight} + 50px)}`
        : props.mobile
        ? `calc(${mobileTrainingNavHeight} + ${navSlideTrainingHeight} + 50px)`
        : `calc(${desktopTrainingNavHeight} + 80px)`,
  },
  progress: {
    minWidth: '100%',
    maxWidth: (props: { notCompleted: boolean }) => (props.notCompleted ? 700 : 800),
  },
  maxWidth: {
    maxWidth: (props: { notCompleted: boolean }) => (props.notCompleted ? 700 : 800),
  },
});

interface IModuleWrapper {
  title: string;
  moduleName: string;
  moduleMap: IModuleMapper[];
  saveData?: (resp: any) => Promise<any>;
}

export const ModuleWrapper = (props: IModuleWrapper) => {
  const { moduleName, moduleMap } = props;
  const moduleValues = useSelector((state: IStoreTypes) => state.training?.[moduleName]);
  const progress = useSelector((state: IStoreTypes) => state.training.progress);
  const openTrainingNav = useSelector((state: IStoreTypes) => state.training?.openTrainingNav);
  const [answeredSteps, setAnsweredSteps] = React.useState<number>(0);
  const [currentComponentName, setCurrentComponentName] = React.useState<string>(moduleMap[0].name);
  const [redirect, setRedirect] = React.useState<boolean>(false);
  const [openSnack, setOpenSnack] = React.useState<boolean>(false);
  const [openSaveDialog, setOpenSaveDialog] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  // This is to cater for the offset of the training select options
  const betweenMobileDesktop = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const hasCompletedModule = (): boolean => {
    return progress[moduleName] && progress[moduleName]?.completed;
  };

  // TODO: Does this actually focus?
  React.useEffect(() => {
    focusOnStepHead();
  }, [currentComponentName]);

  const focusOnStepHead = () => {
    document.getElementById('training-step-header')?.focus();
  };

  const styles = useStyles({
    mobile,
    openTrainingNav,
    betweenMobileDesktop,
    notCompleted: !hasCompletedModule(),
  });

  const saveData = (completed: boolean) => {
    BrancherDispatch(
      dispatch,
      UtilSaveModuleData(
        moduleName,
        (resp) => {
          if (resp.success && completed) {
            dispatch(SetUserCelebrate(true));
            setRedirect(true);
          } else {
            setOpenSnack(true);
          }
        },
        true,
        completed,
      ),
    );
  };

  const controlPrevSteps = () => {
    const currComp = moduleMap[getIndexOfComponent(currentComponentName)];
    const prevComponent = moduleMap[getIndexOfComponent(currComp?.previousComponent)];
    if (prevComponent?.changeFlowStep || (currComp?.submit && currComp?.noValidation)) {
      setAnsweredSteps(answeredSteps - 1);
      setCurrentComponentName(currComp?.previousComponent);
    } else if (
      (currComp?.needsInteraction ||
        currComp?.contentScreen ||
        (currComp?.hasQuestion && answeredSteps > 0)) &&
      (prevComponent?.correctComponent === currentComponentName ||
        prevComponent?.nextComponent === currentComponentName)
    ) {
      setAnsweredSteps(answeredSteps - 1);
      if (prevComponent?.jumpToStep) {
        setCurrentComponentName(prevComponent?.previousComponent);
      } else {
        setCurrentComponentName(currComp?.previousComponent);
      }
    } else if (
      (currComp?.iterateStepper &&
        !currComp?.hasQuestion &&
        !currComp?.needsInteraction &&
        !currComp?.contentScreen) ||
      (!currComp?.iterateStepper && !currComp?.hasQuestion)
    ) {
      setCurrentComponentName(currComp?.previousComponent);
    } else {
      if (answeredSteps === 0) {
        setCurrentComponentName(currComp?.previousComponent);
      } else {
        if (moduleValues[stepMapper(answeredSteps - 1)]?.correct) {
          setCurrentComponentName(prevComponent?.correctComponent);
        } else {
          setCurrentComponentName(prevComponent?.incorrectComponent);
        }
        setAnsweredSteps(answeredSteps - 1);
      }
    }
  };

  const getIndexOfComponent = (nextModuleName: string) => {
    return moduleMap.findIndex((a) => a.name === nextModuleName);
  };

  const controlNextSteps = () => {
    const currComp = moduleMap[getIndexOfComponent(currentComponentName)];
    if (currComp.submit) {
      setOpenSaveDialog(true);
    } else if (currComp?.jumpToStep) {
      setCurrentComponentName(currComp.jumpToStep);
      setAnsweredSteps(currComp.overrideAnsweredSteps);
    } else if (currComp?.hasQuestion) {
      if (moduleValues[stepMapper()]?.correct) {
        setCurrentComponentName(currComp?.correctComponent);
      } else {
        setCurrentComponentName(currComp?.incorrectComponent);
      }
    } else {
      setCurrentComponentName(currComp?.nextComponent);
    }
    if (!currComp?.submit) {
      if (currComp?.changeFlowStep && currComp?.iterateStepper) {
        setAnsweredSteps(answeredSteps + 1);
      } else if (currComp?.iterateStepper && currComp?.hasQuestion) {
        if (
          (!moduleValues[stepMapper()]?.correct &&
            !moduleMap[getIndexOfComponent(currComp?.incorrectComponent)]?.iterateStepper) ||
          moduleValues[stepMapper()]?.correct
        ) {
          setAnsweredSteps(answeredSteps + 1);
        }
      } else if (
        currComp?.iterateStepper &&
        (!currComp?.hasQuestion || currComp?.needsInteraction)
      ) {
        setAnsweredSteps(answeredSteps + 1);
      }
    }
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 200);
  };

  const stepMapper = (injectedStep?: number): string => {
    return StepMapper(answeredSteps, injectedStep);
  };

  const validateValue = (): boolean => {
    const currCompConf = moduleMap[getIndexOfComponent(currentComponentName)];
    // If it's already been completed, disable the ability to submit again
    if (hasCompletedModule() && currCompConf?.submit) {
      return false;
    } else if (currCompConf?.noValidation) {
      return true;
    } else {
      const currModule = moduleValues?.[stepMapper()];
      const currMod = currModule?.selected;
      const interactCurrMod = currModule?.interacted;
      // If the step has attributes to answer - iterate through them all and check if they have a value, otherwise true
      if (currCompConf?.needsInteraction) {
        return interactCurrMod;
      } else if (
        (answeredSteps !== 0 || currCompConf?.hasQuestion) &&
        !currCompConf?.contentScreen
      ) {
        if (typeof currMod === 'object' && currMod !== null) {
          return currMod?.length > 0;
        } else {
          return (currMod != null && currMod !== '') || interactCurrMod;
        }
      } else {
        return true;
      }
    }
  };

  const countActionSteps = (): number => {
    const removeJumpSteps = moduleMap.filter((a) => !a.jumpToStep);
    return removeJumpSteps.filter((a) => a?.hasQuestion || a?.needsInteraction || a?.contentScreen)
      .length;
  };

  const formatStep = (step: number): string => {
    return step >= 10 ? step.toString() : `0${step}`;
  };

  return (
    <>
      {redirect ? (
        <Redirect to={GetRoute('training').path} />
      ) : (
        <>
          <Grid container alignItems="center" justify="center" className={styles.width}>
            <BrancherDialog
              setClose={() => setOpenSaveDialog(false)}
              open={openSaveDialog}
              noOverflow
              labelledBy="Submission confirmation"
              contentWidth
              fitLargeScreen
            >
              <Grid container alignItems="center" justify="center" spacing={4}>
                <Grid item>
                  <Text variant="xl" fontWeight={600} align="center">
                    Are you sure you want to submit?
                  </Text>
                </Grid>
                <Grid item>
                  <Text variant="sm" align="center">
                    After submitting, you can still come back and revisit this training if you like,
                    but you will not be able to resubmit.
                  </Text>
                </Grid>
                <Grid item xs={12}>
                  <BrancherButton
                    onClick={() => saveData(true)}
                    variant="outlined"
                    color="primary"
                    fullWidth
                  >
                    Submit and exit
                  </BrancherButton>
                </Grid>
                <Grid item xs={12}>
                  <BrancherButton
                    onClick={() => setOpenSaveDialog(false)}
                    color="secondary"
                    fullWidth
                  >
                    Not yet
                  </BrancherButton>
                </Grid>
              </Grid>
            </BrancherDialog>
            <BrancherSnackbar
              open={openSnack}
              controlClose={() => setOpenSnack(false)}
              message="Training response saved!"
            />
            <Grid item container xs={11}>
              <Grid item container justify="center">
                <Box width={!mobile ? 680 : '100%'} minWidth={300}>
                  <Text
                    variant="xxl"
                    marginTop={100}
                    marginBottom={24}
                    color="purple"
                    fontWeight={600}
                    id="training-step-header"
                    tabIndex={-1}
                  >
                    {formatStep(answeredSteps + 1)}.
                  </Text>
                </Box>
              </Grid>
              <Grid item container justify="center">
                <Box marginTop={4} marginBottom={4} width={!mobile ? 680 : '100%'} minWidth={300}>
                  {moduleMap[getIndexOfComponent(currentComponentName)].component}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <TrainingNav mobile={mobile}>
            <Grid container justify="center" item xs={12} spacing={hasCompletedModule() ? 4 : 2}>
              <Grid item xs="auto">
                <PrevStepButton
                  onClick={controlPrevSteps}
                  color="secondary"
                  mobile={mobile}
                  disabled={getIndexOfComponent(currentComponentName) === 0}
                >
                  <ArrowBack
                    fontSize={mobile ? 'small' : 'default'}
                    color={getIndexOfComponent(currentComponentName) === 0 ? 'action' : 'inherit'}
                  />
                </PrevStepButton>
              </Grid>
              <Grid
                item
                container
                direction="column"
                justify="center"
                alignItems="center"
                xs={hasCompletedModule() ? 6 : 5}
                sm={hasCompletedModule() ? 8 : 7}
                xl={hasCompletedModule() ? 6 : 5}
              >
                <Grid item>
                  <Text variant="md" marginBottom={8} fontWeight={400}>
                    {formatStep(answeredSteps + 1)}/{formatStep(countActionSteps())}
                  </Text>
                </Grid>
                <Grid item container className={styles.maxWidth}>
                  <LinearProgress
                    value={(answeredSteps + 1) * (100 / countActionSteps())}
                    valueBuffer={100}
                    color="primary"
                    variant="determinate"
                    className={styles.progress}
                  />
                </Grid>
              </Grid>
              {!hasCompletedModule() && (
                <Grid item>
                  <SaveModuleButton onClick={() => saveData(false)} mobile={mobile}>
                    Save
                  </SaveModuleButton>
                </Grid>
              )}
              <Grid item xs="auto">
                <NextStepButton
                  disabled={!validateValue()}
                  onClick={controlNextSteps}
                  mobile={mobile}
                >
                  {answeredSteps === countActionSteps() - 1 ? (
                    <Done color="action" fontSize={mobile ? 'small' : 'default'} />
                  ) : (
                    <ArrowForward color="action" fontSize={mobile ? 'small' : 'default'} />
                  )}
                </NextStepButton>
              </Grid>
            </Grid>
          </TrainingNav>
        </>
      )}
    </>
  );
};
