import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBeGreatMenteeData } from '../../../../../store/actions';
import { Text } from '../../../../../Components/General/Text';
import { SelectionControl } from '../../../../../Form/FormFieldTypes/SelectionControl';
import { MakeOptions } from '../../../../../Form/MakeOptions';
import { IStoreTypes } from '../../../../../store/storeTypes';
import { TrainingSelectOptions } from '../../TrainingSelectOptions';
import { SallyAvatar } from '../../avatars/Sally';

export const BeGreatMenteeStepSix = () => {
  const stepSixValue = useSelector((state: IStoreTypes) => state.training.beGreatMentee.StepSix);
  const dispatch = useDispatch();

  const updateValue = (val: string) => {
    const StepSixCorrectVal = '0';
    dispatch(
      SaveBeGreatMenteeData({
        StepSix: {
          selected: val,
          correct: StepSixCorrectVal === val,
        },
      }),
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container justify="center" alignItems="center">
        <Grid item xs={4} container justify="center">
          <SallyAvatar />
        </Grid>
        <Grid item xs={8}>
          <Text variant="md" fontWeight={700}>
            Sally responds saying...
          </Text>
        </Grid>
      </Grid>
      <Grid item>
        <Text variant="md">
          "It sounds like you aren't receiving feedback as often as you'd like. I wonder what might
          help to meet that need?"
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Using a growth mindset, you respond saying "I'd really like our meeting frequency and
          rhythm to change."
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md">
          Sally responds with "It sounds like there's an opportunity to improve how you receive
          feedback and direction from your manager. What is your main goal here? In an ideal world,
          what would be different?"
        </Text>
      </Grid>
      <Grid item>
        <Text variant="md" fontWeight={700}>
          How do you respond?
        </Text>
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="A."
          optionText="In an ideal world, I'd be meeting with my manager for a 1:1 once a week. This would enable me to use this time effectively to get clear direction and feedback."
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="B."
          optionText="Ideally I'd like my manager to be available whenever I like so I could call them whenever I have a question!"
        />
      </Grid>
      <Grid item>
        <TrainingSelectOptions
          optionHeader="C."
          optionText="In a dream situation I'd like a new manager because I just can't see this manager changing."
        />
      </Grid>
      <Grid item>
        <SelectionControl
          question=""
          name="stepSixValue"
          value={stepSixValue?.selected}
          updateValue={updateValue}
          noQuestion
          exclusive
          options={MakeOptions(['A', 'B', 'C'])}
        />
      </Grid>
    </Grid>
  );
};
